import "../product/Product.css";
import "../product/Product";
import "../../components/modals/Popup.css";
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { withRouter } from "react-router-dom";
import * as mime from 'react-native-mime-types';
import Parser from "html-react-parser";
import moment from "moment";

const FormData = require("form-data");

export class JobApply extends Component {
  constructor(props) {
    super(props);

    //dynamic validation for title field and content field
    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.filevalidator = new SimpleReactValidator({
      messages: {
          required: "The :attribute is required.",
          alpha: "The :attribute must be string.",
      },
      validators: {
        PDF:{
          message: 'The :attribute must be a valid file type. Valid files types are pdf,doc,docx,ppt,pptx',
          rule: (val, params, validator) => {
              let files = (val.files) ?? [];
              let ret = true;
              let ar = ["pdf","doc","docx","ppt","pptx"];
              for (let i = 0; i < files.length; i++) {
                  if(ar.includes(mime.extension(files[i].type)) === false){
                      ret = false;
                  };
              }
              return ret
          },
        },
      }
    })

    // ref code snippet
    this.resume = React.createRef();
    this.jobForm = React.createRef();

    this.state = {
      isShowMedia: "",
      resume: "",
      showUsers: {},
      createName: "",
    };
  }

  onFileChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });
  };

  submitContactSupplier = async (e) => {
    if (this.filevalidator.allValid()) {
      this.props.hideModal();
      e.preventDefault();
      const formData = new FormData(this.jobForm.current);

      try {
        const formres = await axios.post(
          `${process.env.REACT_APP_API_URL}job/apply/${this.props.jid}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (formres.status === 200) {
          if (formres.data.status === true)
          
            toast.success(formres.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        } else if (formres.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else {
          toast.error(formres.data.errors, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        }
      }

      this.filevalidator.hideMessages();
      this.forceUpdate();
    } else {
      this.filevalidator.showMessages();
      this.forceUpdate();
    }
  };

  showApi = async () => {

    const userData = localStorage.getItem("userData")
    const userDetails = JSON.parse(userData)
    
    const id = userDetails.userId;

    try {
      const show = await axios.get(
        `${process.env.REACT_APP_API_URL}show/type/${id}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (show.status === 200 && show.data.status === true) {
        this.setState({
          showUsers: show.data.datas,
          groupId: id,
        });

        this.setState({
          createName: this.state.showUsers.createdBy.name,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if(err?.response?.data?.error != '' ){
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  componentDidMount = async () => {
    await this.showApi();
  }

  render() {

    let createdData = Object.keys(this.state.showUsers).length
    ? this.state.showUsers.createdBy
    : "No Data Found";

    let personalInfo = Object.keys(this.state.showUsers).length
    ? this.state.showUsers.personal
    : "No Data Found";

    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        {/* apply job Model START */}

        <div className="creategroup-modal-container open applyjobmodal">
          <div className="creategroup-modal contactsuuplier-modal">
            <button
              type="button"
              className="close-button-modal"
              id="close-createGroup"
              onClick={this.props.hideModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-header">
              <h1 className="header">Apply for Job</h1>
            </div>

            <div className="createjob-content login-content-fields">

            <div className="chat-box-display show-job-container">
<div className="show-info">

  <div className="info-description">
    <h3 className="header created-name content">
      {createdData.name}
    </h3>
    <h3 className="header title content">{createdData.title}</h3>
    <h3 className="header add content">{createdData.address}</h3>
    <h3 className="header num content">{createdData.phone}</h3>
    <h3 className="header email">{createdData.email}</h3>
  </div>
</div>

<div className="show-info">
  <div className="info-header">
    <h2 className="header">Summary</h2>
  </div>
  <div className="info-description">
    <h3 className="header">
      {Parser(
        this.state.showUsers.summary
          ? this.state.showUsers.summary
          : "Summary haven't added yet"
      )}
    </h3>
  </div>
</div>

<div className="show-info">
  <div className="info-header">
    <h2 className="header">Experience</h2>
  </div>
  {Object.keys(this.state.showUsers).length &&
    (this.state.showUsers.experience.length ? (
      this.state.showUsers.experience.map((exp, key) => (
        <div className="experience-content" key={key}>
          <div className="exp-title">
            <h3 className="header name">{exp.expcompanyname}</h3>
            <h3 className="header location">{exp.explocation}</h3>
          </div>
          <div className="exp-title exp-title-info">
            <h3 className="header title">{exp.exptitle}</h3>
            <h3 className="header date">
              {moment.months(exp.expfrom_mon - 1) +
                " " +
                exp.expfrom_yr +
                " " +
                "-" +
                " " +
                (exp.expto_mon !== "" && exp.expto_yr !== ""
                  ? moment.months(exp.expto_mon - 1) +
                    " " +
                    exp.expto_yr
                  : "Till now")}
            </h3>
          </div>
          <div className="exp-description">
            <h3 className="header desc">Description</h3>
          </div>
          <div className="exp-desc-content">
            <h3 className="header">{exp.expdes}</h3>
          </div>
        </div>
      ))
    ) : (
      <div className="info-description">
        <h3 className="header">Experience haven't added yet</h3>
      </div>
    ))}
</div>

<div className="show-info">
  <div className="info-header">
    <h2 className="header">Education</h2>
  </div>
  {Object.keys(this.state.showUsers).length &&
    (this.state.showUsers.education.length ? (
      this.state.showUsers.education.map((edu, key) => (
        <div className="education-content" key={key}>
          <div className="education edu-title">
            <h3 className="header title">
              {edu.eduschoolDegree +
                " " +
                "(" +
                edu.eduschoolstudy +
                ")" +
                " - " +
                edu.eduschooltoyear +
                " [" +
                edu.eduschoolmode +
                "]"}
            </h3>
          </div>
          <div className="education edu-name">
            <h3 className="header name">{edu.edupschoolname}</h3>
          </div>
          <div className="education edu-grade">
            <h3 className="header grade">
              <span>Grade</span> : {edu.eduschoolgrade}
            </h3>
          </div>
          <div className="education activities">
            <h3 className="header activity education">
              Activities
            </h3>
            <h3 className="header activity-info education">
              {edu.eduschool_act}
            </h3>
          </div>
          <div className="edu-description activities">
            <h3 className="header activity education">
              Description
            </h3>
            <h3 className="header activity-info">
              {edu.eduexp_desc}
            </h3>
          </div>
        </div>
      ))
    ) : (
      <div className="info-description">
        <h3 className="header">Education haven't added yet</h3>
      </div>
    ))}
</div>

<div className="show-info">
  <div className="info-header">
    <h2 className="header">Honor</h2>
  </div>
  {Object.keys(this.state.showUsers).length &&
    (this.state.showUsers.honors.length ? (
      this.state.showUsers.honors.map((honor, key) => (
        <div className="info-description experience-content"  key={key}>
          <div className="exp-title">
            <h3 className="header name">{honor.hontitle}</h3>
            <h3 className="header location">
              {moment.months(honor.honmonth - 1) +
                " " +
                honor.honyear}
            </h3>
          </div>
          <div className="exp-description">
            <h3 className="header desc">Description</h3>
          </div>
          <div className="exp-desc-content">
            <h3 className="header">{honor.hondesc}</h3>
          </div>
        </div>
      ))
    ) : (
      <div className="info-description">
        <h3 className="header">Honor haven't added yet</h3>
      </div>
    ))}
</div>

<div className="show-info">
  <div className="info-header">
    <h2 className="header">Personal Details</h2>
  </div>
  <div className="info-description last-description">
    <h3 className="header personal-info">
      <span className="date">Date of Birth</span>
      <span className="colon">:</span>
      <span className="date-info">
        {moment(personalInfo.dob).format("DD-MM-YYYY")}
      </span>
    </h3>

    <h3 className="header personal-info">
      <span className="date">Gender</span>
      <span className="colon">:</span>
      <span className="date-info">{personalInfo.gender}</span>
    </h3>

    <h3 className="header personal-info">
      <span className="date">Location</span>
      <span className="colon">:</span>
      <span className="date-info">{personalInfo.location}</span>
    </h3>

    <h3 className="header personal-info">
      <span className="date">State</span>
      <span className="colon">:</span>
      <span className="date-info">{personalInfo.userstate}</span>
    </h3>

    <h3 className="header personal-info">
      <span className="date">Country</span>
      <span className="colon">:</span>
      <span className="date-info">{personalInfo.country}</span>
    </h3>
  </div>
</div>
</div>

<h2 className="ordiff">(OR)</h2>
              <form
                action=""
                className="login"
                method="post"
                ref={this.jobForm}
                encType="multipart/form-data"
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  type="file"
                  name="resume"
                  className="groupname cvjobapply"
                  onChange={(e) => {
                    this.setState({
                      new_japply: e.target
                    })
                    this.filevalidator.allValid()
                  }}
                  ref={this.resume}
                  accept=".pdf,.doc,.docx,.ppt,.pptx"
                />

                {this.filevalidator.message(
                  "resume",
                  this.state?.new_japply,
                  "required|PDF"
                )}

                <button
                  type="submit"
                  className="creategroup-button posting-button jobapplyBtn"
                  id="mySupplier"
                  onClick={this.submitContactSupplier}
                >
                  <h2>Apply</h2>
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* Apply job Model END */}
      </>
    );
  }
}

export default withRouter(JobApply);
