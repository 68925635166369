import "../../connectionpage/ConnectionPage.css";
import "../../userpage/UserPage.css";
import React, { Component } from "react";

import chatArrow from "../../../../assets/chat-arrow.png";
import personComponent from "../../../../assets/person-component.png";
import group from "../../../../assets/group.png";
import friends from "../../../../assets/friends.png";
import userBlock from "../../../../assets/user-block.png";
import dataPending from "../../../../assets/data-pending.png";
import { Link } from "react-router-dom";

export class GroupUsersLeftSideBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="sidenav">
          <Link to="/">
            <div className="chat-arrow">
              <img src={chatArrow} alt="chatArrow" />
            </div>
          </Link>
          <div className="sidenav-container">
            <Link to="/my-circle">
              <div className="sidenav-content">
                <img src={personComponent} alt="personComponent" />
                <h2 className="header inactive-block">User(s) you may know</h2>
              </div>
            </Link>

            <Link to="/groups">
              <div className="sidenav-content active">
                <img src={group} alt="group" />
                <h2 className="header active-block">Group</h2>
              </div>
            </Link>

            <Link to="/connections">
              <div className="sidenav-content">
                <img src={friends} alt="friends" />
                <h2 className="header inactive-block">Connection(s)</h2>
              </div>
            </Link>

            <Link to="/blocked-users">
              <div className="sidenav-content">
                <img src={userBlock} alt="userBlock" />
                <h2 className="header inactive-block">Blocked User(s)</h2>
              </div>
            </Link>

            <Link to="/pending-users">
              <div className="sidenav-content">
                <img src={dataPending} alt="dataPending" />
                <h2 className="header inactive-block">Pending List(s)</h2>
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default GroupUsersLeftSideBar;
