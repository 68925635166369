import "../Job.css";
import React, { Component } from "react";
import Select from "react-select";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import chatArrow from "../../../../assets/chat-arrow.png";
import ButtonLoader from '../../../components/loader/Loader'

export class JobLeftSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
      isLocationValue: "",
      filter: "",
      locationOptions: [],

      page: 0,
      perPage: 5,

      isReset:false,
      searchTerm:"",
      isButtonLoding: false,
    };
  }

  handleSelected = async (selectedOption) => {

    if(selectedOption.value !== "") {
      this.setState({
        isReset:true,
      });
    } else {
      this.setState({
        isReset:false,
      });
    }

    await this.setState({ selectedOption });

    await this.props.locationCallback(selectedOption.value);
  };

  handleInputChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });

    if (e.target.name === "filter") {

      if(e.target.value?.replace(/^\s+/g, '') !== "") {
        this.setState({
          isReset:true,
        });
      } else {
        this.setState({
          isReset:false,
        });
      }
      await this.props.filterCallback(e.target.value?.replace(/^\s+/g, ''));
    }
  };

  resetFilter = async (e) => {

    if(this.state.isReset){
      await this.setState({
        selectedOption: "",
        filter: "",
      });

      await this.props.myreset();
    }

  };

  componentDidMount = async () => {

    this.setState({
      isReset:this.props.isReset,
    });
  }

  componentWillReceiveProps(newProps) {
    
    if (this.state.locationOptions.length !== newProps.locationShow.length) {
      const arr = newProps.locationShow.map((val) => {
        return { value: val, label: val };
      });

      this.setState({
        locationOptions: arr,
        isButtonLoding: newProps.isButtonLoding,
      });
    }
  }

  shouldSearchTimer = null

  handleOnSearchInput = async (e) => {
    this.setState({ searchTerm: e })

    if (this.shouldSearchTimer) {
        clearTimeout(this.shouldSearchTimer)
    }

    this.shouldSearchTimer = setTimeout(() => {
        this.shouldSearchTimer = null

        if (e !== ""){
          this.setState({
            isButtonLoding: true,
          })
        
        } else {
          this.setState({
            isButtonLoding: this.props.isButtonLoding,
          })
        }
        this.props.searchLocation(e);
    }, 500)
}

  render() {
    return (
      <>
        <div className="left-sidebar">
          <Link to="/">
            <div className="chat-arrow">
              <img src={chatArrow} alt="chatArrow" />
            </div>
          </Link>
          <div className="sidebar-container">
            <div className="header-content">
              <h2 className="header">Filters</h2>
            </div>

            <div className="content-container">
              <div className="sidebar-content">
                <div className="header-content">
                  <h3 className="header">Search</h3>
                </div>
                <form className="form-field">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Search"
                    onChange={this.handleInputChange}
                    value={this.state.filter}
                    name="filter"
                  />
                </form>
              </div>

              <div className="sidebar-content">
                <div className="header-content">
                  <h3 className="header">Location</h3>
                </div>
                <div className="caret-content">
                  
                  <Select
                    className="form-select option-multi-select location-select"
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    styles={
                      ({
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      },
                      {
                        control: (base) => ({
                          ...base,
                          border: "0 !important",
                          // This line disable the blue border
                          boxShadow: "0 !important",
                          "&:hover": {
                            border: "0 !important",
                          },
                        }),
                      })
                    }
                    defaultValue
                    // isMulti
                    value={this.state.selectedOption}
                    inputValue={this.state.searchTerm}
                    onInputChange={this.handleOnSearchInput}
                    onChange={this.handleSelected}
                    options={this.state.locationOptions}
                    placeholder={'Location'}
                    isLoading={this.state.isButtonLoding}
                    
                  />
                </div>
              </div>

              <form className="sidebar-button" onClick={this.resetFilter}>
                <button type="button" className="reset-button">
                  Reset
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(JobLeftSidebar);
