import "../showpage/Show.css";
import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";

import ChatButton from "../../components/chatbutton/ChatButton";
import sample from "../../../assets/sample.png";
import Parser from "html-react-parser";

export class ShowPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showUsers: {},
      groupusers: [],
      page: 0,
      perPage: 5,
      groupId: "",
      value: this.props.location.state,
      usersData: [],
      userDataLength: "",
      userDataName: "",
      userDataId: "",
      allDatas: [],
      grpUsersarray:[]
    };
  }

  grpUsers = async (gid) => {
    const grpUsers = await axios.get(
        `${process.env.REACT_APP_API_URL}group/detail/${gid}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (grpUsers.status === 200 && grpUsers.data.status === true) {
        await this.setState({
            grpUsersarray: grpUsers.data.datas,
        })
      }

}

  componentDidMount = async () => {
    await this.showApi();

    let users = []
    await this.grpUsers(this.props.match.params.gid)
    
    if(this.props.location?.state.length < 1) {

      users = this.state.grpUsersarray
    } 
    else {
      users = JSON.parse(this.props.location?.state)
    }
   
    this.setState({
      usersData: users,
    });

    const data = this.state.usersData.map((datas) => {
      return datas.username;
    });

    const dataId = this.state.usersData.map((datas) => {
      return datas.id;
    });

    this.setState({
      userDataName: data,
      userDataId: dataId,
      userDataLength: this.state.usersData.length,
      allDatas: this.state.usersData,
    });
  };

  showApi = async () => {
    const gid = this.props.match.params.gid;

    try {
      const show = await axios.get(
        `${process.env.REACT_APP_API_URL}show/group/${gid}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (show.status === 200 && show.data.status === true) {
        this.setState({
          showUsers: show.data.datas,
          groupId: gid,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }
    }
  };

  render() {
    let createdData = Object.keys(this.state.showUsers).length
      ? this.state.showUsers.createdBy
      : "No Data Found";

    let personalInfo = Object.keys(this.state.showUsers).length
      ? this.state.showUsers.personal
      : "No Data Found";

    return (
      <>
        <section className="chat-page-section" id="user-detail-page">
          <div className="left-sidebar" id="user-detail-sidebar">
            <div className="showuser-container">
              <div className="showuser-profile">
                <img src={sample} alt="" />
              </div>

              <div className="show-members">
                <h2 className="header">
                  {this.state.userDataLength + " Members"}
                </h2>
              </div>

              <div className="messenger-container show-members-list">
                {this.state.usersData.map((obj, key) => {
                  return (
                    <div className="messenger-content show-members-content" key={key}>
                      <div className="user-profile show-profile">
                        <img src={sample} alt="" />
                      </div>

                      <div className="user-content users">
                        <Link to={`/profileshow/${obj.id}`}>
                          <div className="user-name">
                            <h3 className="header bold-header">
                              {obj.username}
                            </h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="group-container" id="user-container">
            <div className="chat-box-display show-group-container" id="user-detail-container">
              <div className="show-info">
                <div className="info-header">
                  <h2 className="header">Group Name</h2>
                </div>
                <div className="info-description">
                  <h3 className="header">{this.state.showUsers.gname}</h3>
                </div>
              </div>

              <div className="show-info">
                <div className="info-header">
                  <h2 className="header">Group Description</h2>
                </div>
                <div className="info-description">
                  <h3 className="header">
                    <span dangerouslySetInnerHTML={{ __html: this.state.showUsers.gdescription }} />
                  </h3>
                </div>
              </div>

              <div className="show-info">
                <div className="info-header">
                  <h2 className="header">Created By</h2>
                </div>

                <div className="info-description">
                  <h3 className="header created-name content">
                    {createdData.name}
                  </h3>
                  <h3 className="header title content">{createdData.title}</h3>
                  <h3 className="header add content">{createdData.address}</h3>
                  <h3 className="header num content">{createdData.phone}</h3>
                  <h3 className="header email">{createdData.email}</h3>
                </div>
              </div>

              <div className="show-info">
                <div className="info-header">
                  <h2 className="header">Summary</h2>
                </div>
                <div className="info-description">
                  <h3 className="header">
                    {Parser(
                      this.state.showUsers.summary
                        ? this.state.showUsers.summary
                        : "Summary haven't added yet"
                    )}
                  </h3>
                </div>
              </div>

              <div className="show-info">
                <div className="info-header">
                  <h2 className="header">Experience</h2>
                </div>
                {Object.keys(this.state.showUsers).length &&
                  (this.state.showUsers.experience.length ? (
                    this.state.showUsers.experience.map((exp, key) => (
                      <div className="experience-content" key={key}>
                        <div className="exp-title">
                          <h3 className="header name">{exp.expcompanyname}</h3>
                          <h3 className="header location">{exp.explocation}</h3>
                        </div>
                        <div className="exp-title exp-title-info">
                          <h3 className="header title">{exp.exptitle}</h3>
                          <h3 className="header date">
                            {moment.months(exp.expfrom_mon - 1) +
                              " " +
                              exp.expfrom_yr +
                              " " +
                              "-" +
                              " " +
                              (exp.expto_mon !== "" && exp.expto_yr !== ""
                                ? moment.months(exp.expto_mon - 1) +
                                " " +
                                exp.expto_yr
                                : "Till now")}
                          </h3>
                        </div>
                        <div className="exp-description">
                          <h3 className="header desc">Description</h3>
                        </div>
                        <div className="exp-desc-content">
                          <h3 className="header">{exp.expdes}</h3>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="info-description">
                      <h3 className="header">Experience haven't added yet</h3>
                    </div>
                  ))}
              </div>

              <div className="show-info">
                <div className="info-header">
                  <h2 className="header">Education</h2>
                </div>
                {Object.keys(this.state.showUsers).length &&
                  (this.state.showUsers.education.length ? (
                    this.state.showUsers.education.map((edu, key) => (
                      <div className="education-content" key={key}>
                        <div className="education edu-title">
                          <h3 className="header title">
                            {edu.eduschoolDegree +
                              " " +
                              "(" +
                              edu.eduschoolstudy +
                              ")" +
                              " - " +
                              edu.eduschooltoyear +
                              " [" +
                              edu.eduschoolmode +
                              "]"}
                          </h3>
                        </div>
                        <div className="education edu-name">
                          <h3 className="header name">{edu.edupschoolname}</h3>
                        </div>
                        <div className="education edu-grade">
                          <h3 className="header grade">
                            <span>Grade</span> : {edu.eduschoolgrade}
                          </h3>
                        </div>
                        <div className="education activities">
                          <h3 className="header activity education">
                            Activities
                          </h3>
                          <h3 className="header activity-info education">
                            {edu.eduschool_act}
                          </h3>
                        </div>
                        <div className="edu-description activities">
                          <h3 className="header activity education">
                            Description
                          </h3>
                          <h3 className="header activity-info">
                            {edu.eduexp_desc}
                          </h3>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="info-description">
                      <h3 className="header">Education haven't added yet</h3>
                    </div>
                  ))}
              </div>

              <div className="show-info">
                <div className="info-header">
                  <h2 className="header">Honor</h2>
                </div>
                {Object.keys(this.state.showUsers).length &&
                  (this.state.showUsers.honors.length ? (
                    this.state.showUsers.honors.map((honor, key) => (
                      <div className="info-description experience-content" key={key}>
                        <div className="exp-title">
                          <h3 className="header name">{honor.hontitle}</h3>
                          <h3 className="header location">
                            {moment.months(honor.honmonth - 1) +
                              " " +
                              honor.honyear}
                          </h3>
                        </div>
                        <div className="exp-description">
                          <h3 className="header desc">Description</h3>
                        </div>
                        <div className="exp-desc-content">
                          <h3 className="header">{honor.hondesc}</h3>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="info-description">
                      <h3 className="header">Honor haven't added yet</h3>
                    </div>
                  ))}
              </div>

              <div className="show-info">
                <div className="info-header">
                  <h2 className="header">Personal Details</h2>
                </div>
                <div className="info-description last-description">
                  <h3 className="header personal-info">
                    <span className="date">Date of Birth</span>
                    <span className="colon">:</span>
                    <span className="date-info">
                      {moment(personalInfo.dob).format("DD-MM-YYYY")}
                    </span>
                  </h3>

                  <h3 className="header personal-info">
                    <span className="date">Gender</span>
                    <span className="colon">:</span>
                    <span className="date-info">{personalInfo.gender}</span>
                  </h3>

                  <h3 className="header personal-info">
                    <span className="date">Location</span>
                    <span className="colon">:</span>
                    <span className="date-info">{personalInfo.location}</span>
                  </h3>

                  <h3 className="header personal-info">
                    <span className="date">State</span>
                    <span className="colon">:</span>
                    <span className="date-info">{personalInfo.userstate}</span>
                  </h3>

                  <h3 className="header personal-info">
                    <span className="date">Country</span>
                    <span className="colon">:</span>
                    <span className="date-info">{personalInfo.country}</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <ChatButton />
        </section>
      </>
    );
  }
}

export default withRouter(ShowPage);
