import "../../chatpage/ChatPage.css";
import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import chatArrow from "../../../../assets/chat-arrow.png";

export class ChatSideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModal: false,
      deletePostId: "",
      isActive: "",
      openChat: "",
      openChatId: "",
      openListing: [],
      chatMuid: "",
      listUserName: "",
      listUserImage: "",

      activeChatId: "",
      activeChatName: "",

      putMsg: "direct",
      chatType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (
      !this.state.activeChatId &&
      props.chatListing.length &&
      props.chatListing[0].pimage.length
    ) {
      this.setState({
        activeChatId: props.chatListing[0].pimage[0].muid,
        activeChatName: props.chatListing[0].pimage[0].uname,
      });

      this.props.chatShow(
        props.chatListing[0].pimage[0].muid,
        props.chatListing[0].pimage[0].uname,
        props.chatListing[0].pimage[0].pimage
      );
    }
  }

  deleteModal = async (fid, type) => {
    const deleteModal = this.state.deleteModal === false ? true : false;

    await this.setState({
      deleteModal: deleteModal,
      deleteFId: fid,
      deleteFType: type === 1 ? "direct" : "group",
    });
  };

  hideDeleteModal = async () => {
    await this.setState({
      deleteModal: false,
    });
  };

  chatShow = async () => {
    try {
      const chat = await axios.get(
        `${process.env.REACT_APP_API_URL}message/chat/${this.state.openChatId}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (chat.status === 200 && chat.data.status === true) {
        await this.setState({
          openListing: chat.data.datas,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if(err?.response?.data?.error != '' ){
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    window.scroll(0, 0);
    this.props.scrollBoxBottom();
  };

  // delete conversation
  deleteConversation = async () => {
    const mtype = this.state.deleteFType;
    const fid = this.state.deleteFId;

    try {
      const deleteData = await axios.delete(
        `${process.env.REACT_APP_API_URL}message/${mtype}/${fid}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (deleteData.status === 200 && deleteData.data.status === true) {
        toast.success(deleteData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        await this.props.chatList()
        var c = this.props.chatListing[0];
        if(c){
          let chatId = c.pimage.map((dataid) => {
            return dataid.muid;
          })[0];

          let chatUserName = c.pimage.map((dataid) => {
            return dataid.uname;
          });

          let chatUserImage = c.pimage.map((dataid) => {
            return dataid.pimage;
          });
          this.props.chatShow(chatId, chatUserName, chatUserImage)
        }
      } else {
        toast.error(deleteData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if(err?.response?.data?.error != '' ){
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    this.props.chatList();
    this.hideDeleteModal();
  };

  handleClick(chatId, chatUserName, chatUserImage) {
    this.props.chatShow(chatId, chatUserName, chatUserImage);

    this.setState({
      activeChatId: chatId,
      listUserName: chatUserName,
      listUserImage: chatUserImage,
    });

    window.scroll(0, 0);
    this.props.scrollBoxBottom();
  }

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        <div className="left-sidebar">
          
          <Link to={'/'}>
            <div className="chat-arrow chat-page-arrow">
                <img src={chatArrow} alt="chatArrow" />
            </div>
          </Link>

          <div className="messenger-container">
            {this.props.chatListing.map((chat) => {
              let chatId = chat.pimage.map((dataid) => {
                return dataid.muid;
              })[0];

              let chatUserName = (chat.pimage.length > 1) ? chat.pimage[0].uname + ` and - ${chat.pimage.length -1 } more` : chat?.pimage[0]?.uname; 
              
              let chatUserImage = (chat.pimage.length > 1) ? chat.pimage[0].pimage : chat?.pimage[0]?.pimage; 
              
              if(typeof chatUserImage === "undefined"){
                chatUserImage = process.env.PUBLIC_URL+'/images/un.jpg'
              }
              return (
                <div
                  className={`messenger-content ${
                    this.state.activeChatId === chatId ? "selectedChat" : ""
                  }`}
                  onClick={(e) =>
                    this.handleClick(chatId, chatUserName, chatUserImage)
                  }
                >
                  <div
                    className="delete-msg"
                    onClick={() => this.deleteModal(chat.toid, chat.type)}
                  >
                    <i className="far fa-trash-alt" />
                  </div>

                  <div className="mail-icon">
                    <i className="far fa-envelope" />
                  </div>

                  <div className="user-profile">
                    <img src={chatUserImage} alt="" className="image-round" />
                    {chat.unreadChatCount > 0 && (
                      <span className="notiCount">{chat.unreadChatCount}</span>
                    )}
                    
                  </div>

                  <div className="user-content users">
                    <div className="user-name">
                      <h3 className="header bold-header">{chatUserName}</h3>
                    </div>
                    <div className="user-chat">
                      <h3 className="header grey-header" >{chat.lastMessage}</h3>
                    </div>
                  </div>

                  <div className="active-time">
                    <h3 className="header bold-header">
                      {moment(chat.messageDate).diff(moment(), "days") === 0
                        ? moment(chat.messageDate).format("HH:mm a")
                        : moment(chat.messageDate).diff(moment(), "days") === 1
                        ? "Yesterday"
                        : moment(chat.messageDate).format("DD-MM-YY")}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* delete modal start */}
        <div
          className={
            "creategroup-modal-container report-modal-container" +
            (this.state.deleteModal ? " open" : "")
          }
        >
          <div className="creategroup-modal report-modal delete-modal">
            <div className="forgot-header">
              <h1 className="header">
                Sure you want to delete your entire conversation history?
              </h1>
            </div>

            <div className="creategroup-content delete-buttons-container">
              <button
                type="button"
                className="login-button creategroup-button forgot-content cancel-button"
                id="mySupplier"
                onClick={this.hideDeleteModal}
              >
                <h2>Cancel</h2>
              </button>

              <button
                type="button"
                className="login-button creategroup-button forgot-content delete-button"
                id="mySupplier"
                onClick={this.deleteConversation}
              >
                <h2>Delete</h2>
              </button>
            </div>
          </div>
        </div>
        {/* delete modal end */}
      </>
    );
  }
}

export default withRouter(ChatSideBar);
