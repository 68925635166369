import "../product/Product.css";
import "../supplier/Supplier.css";
import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import queryString from "query-string";
import { SupplierLeftSideBar } from "./supplierleftsidebar/SupplierLeftSideBar";
import ContactSupplier from "../contactsupplier/ContactSupplier";
import ChatButton from "../../components/chatbutton/ChatButton";
import Loader from "../loader/Loader";
import productEmail from "../../../assets/product-email.png";

export class SupplierPage extends Component {
  constructor(props) {
    super(props);

    this.ProductsElement = React.createRef();

    this.state = {
      searchKeyword: "",
      searchLocation: "",
      searchCondition: "",
      filter: "",
      allProduct: [],
      locationData: [],
      listing: [],
      allIds: [],
      idData: [],
      total: 0,
      page: 0,
      nextAvailable: true,
      perPage: 12,
      contactSupplierModel: false,
      loading: false,
      supplierName: "",
      supplierId: "",
      supplierLength: "",

      userName: "",
      userEmail: "",
      userMobile: "",
      isSearchResult: false,
      isLoaded: false,

      isSupplierLeftSideBar: false,
      openSupplierLeftSideBar: false,
    };
  }

  // set ternery condition on the create post modal for showing the modal
  showModal = async (sid, sname) => {
    const contactSupplierModel =
      this.state.contactSupplierModel === false ? true : false;
    await this.setState({
      contactSupplierModel: contactSupplierModel,
      supplierId: sid,
      supplierName: sname,
    });
  };

  hideContactModal = async () => {
    await this.setState({
      contactSupplierModel: false,
    });
  };

  supplierApi = async () => {
    if (this.state.nextAvailable) {
      await this.setState({
        loading: true,
        page: this.state.page + 1,
        perPage: this.state.perPage,
      });

      try {
        var suppliers;
        if (this.state.isSearchResult === true) {
          suppliers = await axios.post(
            `${process.env.REACT_APP_API_URL}search-web`,
            {
              key: this.state.searchKeyword,
              page: this.state.page,
              records: this.state.perPage,
              type: 4,
            },
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
        } else {
          suppliers = await axios.get(
            `${process.env.REACT_APP_API_URL}product/getallsupplier?page=${this.state.page
            }&records=${this.state.perPage}&searchField=${this.state.filter}&location=${this.state?.searchLocation}&productCondition=${this.state.searchCondition}`,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
        }

        if (suppliers.status === 200 && suppliers.data.status === true) {

          this.setState({
            isLoaded: true,
          })

          if (suppliers.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }

          let oldArray = this.state.listing;
          oldArray = oldArray.concat(suppliers.data.datas);

          await this.setState({
            allProduct: oldArray,
            allIds: oldArray,
            total: suppliers.data.total
          });

          const allProducts = [];

          this.state.allProduct.forEach((data) => {
            allProducts.push(data.country);
          });

          let unique = [...new Set(allProducts)];

          await this.setState({
            locationData: unique,
            listing: oldArray,
          });
        } else {
          this.setState({
            isLoaded: true,
          })
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        }
      }

      await this.setState({
        loading: false,
      });
    }
  };

  componentDidMount = async () => {
    await this.handleResize();

    const queries = queryString.parse(this.props.location.search);

    if ("key" in queries && queries.key !== null && queries.key !== "") {
      this.setState({
        isSearchResult: true,
        searchKeyword: queries.key,
        page: 0,
        nextAvailable: true,
      });
    }
    const userData = localStorage.getItem("userData");

    const userDetails = JSON.parse(userData);

    this.setState({
      userName: userDetails.userName,
      userEmail: userDetails.userEmail,
      userMobile: userDetails.userMobile,
    });

    this.setState({
      loading: true,
    });

    await this.supplierApi();

    window.addEventListener("scroll", this.listenToScroll);

    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isSupplierLeftSideBar: true,
        openSupplierLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isSupplierLeftSideBar: false,
      });
    }
  };

  listenToScroll = async () => {
    if (this.state.loading === false) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight - 5) {
        await this.supplierApi();
      }
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.time !== this.props.location.time) {
      const queries = queryString.parse(this.props.location.search);

      if ("key" in queries && queries.key !== null && queries.key !== "") {
        await this.setState({
          isSearchResult: true,
          searchKeyword: queries.key,
          page: 0,
          nextAvailable: true,
          loading: true,
        });

        await this.supplierApi();
      }
    }

    if (prevState.searchLocation !== this.state.searchLocation) {
      await this.supplierApi();

      await this.setState({
        loading: false,
      });
    }

    if (prevState.searchCondition !== this.state.searchCondition) {
      await this.supplierApi();

      await this.setState({
        loading: false,
      });
    }

    if (prevState.filter !== this.state.filter) {
      await this.supplierApi();

      await this.setState({
        loading: false,
      });
    }
  }

  handleLocation = async (optionData) => {
    await this.setState({
      searchLocation: optionData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  handleCondition = async (conditionData) => {
    await this.setState({
      searchCondition: conditionData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  handleFilter = async (filterData) => {
    await this.setState({
      filter: filterData,
      nextAvailable: true,
      loading: true,
      page: 0,
      perPage: 12,
      listing: [],
    });
  };

  resetFilter = async () => {
    await this.setState({
      filter: "",
      searchLocation: "",
      searchCondition: "",
      nextAvailable: true,
      loading: true,
      page: 0,
      perPage: 12,
      listing: [],
    });
  }

  goToProducts = async () => {
    setTimeout(() => {
      this.props.history.push("/product");
    }, 500);
  };

  showSupplierLeftSideBar = async () => {
    if (this.state.openSupplierLeftSideBar) {
      await this.setState({
        openSupplierLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openSupplierLeftSideBar: true,
      });
    }
  };

  render() {
    return (
      <>
        {this.state.contactSupplierModel ? (
          <ContactSupplier
            hideModal={this.hideContactModal}
            supplierRecipient={this.state.supplierName}
            supplierId={this.state.supplierId}
            senderName={this.state.userName}
            senderEmail={this.state.userEmail}
            senderPhone={this.state.userMobile}
          />
        ) : (
          ""
        )}

        <section className="product-section">
          {(this.state.isSupplierLeftSideBar || this.state.openSupplierLeftSideBar) &&
            <SupplierLeftSideBar
              locationShow={this.state.locationData}
              locationCallback={this.handleLocation}
              conditionCallback={this.handleCondition}
              filterCallback={this.handleFilter}
              resetFilter={this.resetFilter}
            />
          }

          <div className="product-container">
            {(!this.state.openSupplierLeftSideBar && !this.state.isSupplierLeftSideBar) &&
              <div className="Supplier-hamburger-menu">
                <i class="fa fa-bars" aria-hidden="true" onClick={this.showSupplierLeftSideBar}></i>
              </div>
            }
            {(this.state.openSupplierLeftSideBar && !this.state.isSupplierLeftSideBar) &&
              <div className="Supplier-hamburger-menu">
                <i class="fa fa-times" aria-hidden="true" onClick={this.showSupplierLeftSideBar}></i>
              </div>
            }
            <div className="product-suppliers">
              <input type="checkbox" id="html" />
              <label
                htmlFor="html"
                className="suppliers-label"
                onClick={this.goToProducts}
              >
                Product
              </label>
            </div>

            <div className="product-box-container" ref={this.ProductsElement}>
              <div className="product-boxes supplier-boxes">
                <div className="product-header">
                  <h1 className="header">Suppliers</h1>
                  {this.state.isLoaded &&
                    <h2>{this.state.total} Supplier(s) found</h2>
                  }
                </div>

                {this.state.isLoaded &&
                  <div className="supplier-container">
                    {this.state.listing.map((lists, index) => (
                      <div className="supplier-box-container" key={index}>
                        <div className="product-email">
                          <button
                            type="button"
                            className="product-email-button"
                            onClick={() => this.showModal(lists.sid, lists.sname)}
                          >
                            <img src={productEmail} alt="productEmail" />
                          </button>
                        </div>

                        <div className="supplier-info">
                          <div className="supplier-name">
                            <h1 className="header"><Link to={`/supplier-detail/${lists.sid}`}>{lists.sname}</Link></h1>
                          </div>
                          <div className="supplier-region">
                            <h2 className="header-text">Country/Region</h2>
                          </div>
                          <div className="supplier-location">
                            <h2 className="header-text grey-header">
                              {lists.country}
                            </h2>
                          </div>
                        </div>

                        <div className="supplier-boxes-content">
                          <div className="content-header">
                            <h2 className="header">Products</h2>
                          </div>
                          <div className="supplier-content">
                            {lists.productList.map((products) => {
                              return products.pList.map((images, key) => {
                                return (
                                  <div className="product-boxes-content suppliers-boxes-content" key={key}>
                                    <Link to={`/supplier-detail/${lists.sid}`}>
                                      <div className="product-image supplier-image">
                                        <img src={images.image} alt="" />
                                      </div>
                                    </Link>
                                    <div className="product-info supplier-info-name">
                                      <div className="product-name">
                                        <h2 className="header">{images.name}</h2>
                                      </div>
                                    </div>
                                  </div>
                                );
                              });
                            })}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                }
              </div>
            </div>
            {this.state.loading && <Loader />}
          </div>
        </section>

        <ChatButton />
      </>
    );
  }
}

export default withRouter(SupplierPage);
