import "./Homepage.css";
import React, { Component } from "react";
import PostPage from "../postPage/Post-page";

export class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
    };
  }

  componentDidMount = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      if (token !== "") {
        await this.setState({
          isLoggedIn: true,
        });
      }
    }
  };

  render() {
    return (
      <>
        {this.state.isLoggedIn ? (
          <PostPage />
        ) : (
          <section className="header-section">
            <div className="header-section-content">
              <h1 className="header">
                PEXit<sup>TM</sup> - The Integrated Multi-Lingual Media
              </h1>
            </div>
            <div className="header-section-image" />
          </section>
        )}
      </>
    );
  }
}

export default Homepage;
