
import axios from "axios";
import Parser from "html-react-parser";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import sample from "../../../assets/sample.png";
import ChatButton from "../../components/chatbutton/ChatButton";
import "../showpage/Show.css";


export class ProfileShow extends Component {

  constructor(props) {
    super(props);

    //dynamic validation for title field and content field
    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.state = {
      showUsers: {},
      groupusers: [],
      connections: [],
      page: 0,
      perPage: 5,
      groupId: this.props.match.params.id,
      value: parseInt(this.props.location.state),
      friendStatus: "",
      friendLists: [],
      sendFriendRequest: false,
      showDropdown: false,
      createName: "",
      messageModal: false,
      message: "",
      isMember: false,
      friend_id: this.props.match.params.id,
      isLoading:false,
      isProfileLeftSideBar: true,
      openProfileLeftSideBar: false,
    };
  }


  componentDidMount = async () => {
    await this.setState({
      friendStatus: this.state.value,
    });



    await this.showApi();

    await this.connectionsApi();
    await this.handleResize();
    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      await this.setState({
        isProfileLeftSideBar: true,
        openProfileLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        isProfileLeftSideBar: false,
      });
    }
  };

  userLink = async (id) => {
    await this.setState({
      groupId: id,
    });

    await this.showApi();

    await this.connectionsApi();
  };

  showApi = async () => {
    const id = this.state.groupId;

    try {
      const show = await axios.get(
        `${process.env.REACT_APP_API_URL}show/type/${id}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (show.status === 200 && show.data.status === true) {
        this.setState({
          showUsers: show.data.datas,
          groupId: id,
        });

        this.setState({
          createName: this.state.showUsers.createdBy.name,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  // connection
  connectionsApi = async () => {
    try {
      const conn = await axios.get(
        `${process.env.REACT_APP_API_URL}connections-web/${Number(
          this.state.groupId
        )}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (conn.status === 200 && conn.data.status === true) {

        let isMember = this.state.isMember;
        conn.data.datas.map((d) => {
          if (d.id == localStorage.getItem('userId')) {
            isMember = true
          }
        })

        this.setState({
          connections: conn.data.datas,
          isMember: isMember
        });
      } else if (conn.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  sentRequest = async () => {
    const friendData = {
      option: "aa",
    };

    try {
      const friendListData = await axios.post(
        `${process.env.REACT_APP_API_URL}friend/request/${this.state.groupId}`,
        friendData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (friendListData.status === 200) {
        if (friendListData.data.status === true) {
          const sendFriendRequest =
            this.state.sendFriendRequest === false ? true : false;

          this.setState({
            sendFriendRequest: sendFriendRequest,
          });

          toast.success(friendListData.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(
            friendListData.data.message !== ""
              ? friendListData.data.message
              : friendListData.data.errors,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }

        this.setState({
          friendLists: friendListData.data.datas,
        });
      } else if (friendListData.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else {
        toast.error(
          friendListData.data.message !== ""
            ? friendListData.data.message
            : friendListData.data.errors,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  dropdown = () => {
    const showDropdown = this.state.showDropdown === false ? true : false;

    this.setState({
      showDropdown: showDropdown,
    });
  };

  showMessageModal = async () => {
    const messageModal = this.state.messageModal === false ? true : false;

    this.setState({
      messageModal: messageModal,
    });
  };

  hideMessageModal = async () => {
    this.setState({
      messageModal: false,
    });
  };

  handleInputChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });
  };

  sendMessage = async () => {
    if (this.validator.allValid()) {
      const userData = localStorage.getItem("userData");

      const userDetails = JSON.parse(userData);

      const msgData = {
        chatterIds: this.state.groupId,
        toIds: userDetails.userId,
        chatterMessage: this.state.message,
      };

      try {
        const message = await axios.post(
          `${process.env.REACT_APP_API_URL}message/send-web`,
          msgData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (message.status === 200 && message.data.status === true) {
          toast.success(message.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(message.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        this.hideMessageModal();

        this.setState({
          message: "",
        });
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  showProfileLeftSideBar = async () => {
    if (this.state.openProfileLeftSideBar) {
      await this.setState({
        openProfileLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openProfileLeftSideBar: true,
      });
    }
  };


  friendRemove = async () => {
    if (this.state.isLoading === false) {

    await this.setState({
      isLoading: true,
    })

    try {

      const removeData = await axios.delete(
        `${process.env.REACT_APP_API_URL}friend/remove/${this.state.friend_id}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (removeData.status === 200 && removeData.data.status === true) {
        toast.success(removeData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(removeData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    setTimeout(async () => {
      await this.setState({
        isLoading: false,
      })
      this.props.history.push('/connections');
    }, 5000)


  }
  }



  render() {
    let createdData = Object.keys(this.state.showUsers).length
      ? this.state.showUsers.createdBy
      : "No Data Found";

    let personalInfo = Object.keys(this.state.showUsers).length
      ? this.state.showUsers.personal
      : "No Data Found";

    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        <section className="chat-page-section" id="user-detail-page">

          <div className="left-sidebar" id="user-detail-sidebar">
            <div className="showuser-container">
              <div className="user-friend-status">





                <div className="showuser-profile">
                  {this.state.showUsers.profileImage ? (
                    <img src={this.state.showUsers.profileImage} alt="" />
                  ) : (
                    <img src={sample} alt="" />
                  )}
                </div>


                <div className="user-status-container">
                  {this.state.showUsers?.friendStatus === 1 ? 
                  ((this.state.isMember == true) ? (
                    <div className="user-status-one-container">
                      <h1 className="header">Member</h1>
                    </div>
                  ) : (<div className="user-status-one-container">
                    <h1 className="header">Invited</h1>
                    <i className="fas fa-chevron-down" onClick={this.dropdown} />
                  </div>)
                  ) : 
                  (
                    <div className="user-status-one-container user-status-zero-container">
                      {this.state.sendFriendRequest ? (
                        <h1 className="header">Sent Invite</h1>
                      ) : (
                        <h1 className="header" onClick={this.sentRequest}>
                          Add Friend
                        </h1>
                      )}


                      <i className="fas fa-chevron-down" onClick={this.dropdown} />
                    </div>
                  )}
                  {this.state.showDropdown && (
                    <div className="friend-dropdown">
                      <h2 className="header" onClick={this.showMessageModal}>
                        Message
                      </h2>
                    </div>
                  )}
                </div>
                {
                  (this.state.isMember == true)?<div className="user-status-container my-5">
                  <div className="user-status-one-container">
                    <h1 className="header mt-2"
                    onClick={this.friendRemove}
                    
                    >Remove friend</h1>
                  </div>
                </div>
                :""
                }
                

              
              </div>
              <br/>


              <div className="show-members ">
                <h2 className="header">
                  {this.state.connections.length + " Connections"}
                </h2>
              </div>

              <div className="messenger-container show-members-list">
                {this.state.connections.map((connec, key) => {
                  return (
                    <div className="messenger-content show-members-content" key={key}>
                      <div className="user-profile show-profile">
                        <img src={connec.pimage} alt="" />
                      </div>

                      <div className="user-content users">
                        <div
                          className="user-name"
                          onClick={() => this.userLink(connec.id)}
                        >
                          <h3 className="header bold-header">
                            {connec.name}
                          </h3>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>


            </div>
          </div>

          <div className="group-container" id="user-container">
            <div className="chat-box-display show-group-container" id="user-detail-container">
              <div className="show-info">

                <div className="info-description">
                  <h3 className="header created-name content">
                    {createdData.name}
                  </h3>
                  <h3 className="header title content">{createdData.title}</h3>
                  <h3 className="header add content">{createdData.address}</h3>
                  <h3 className="header num content">{createdData.phone}</h3>
                  <h3 className="header email">{createdData.email}</h3>
                </div>
              </div>

              <div className="show-info">
                <div className="info-header">
                  <h2 className="header">Summary</h2>
                </div>
                <div className="info-description">
                  <h3 className="header">
                    {Parser(
                      this.state.showUsers.summary
                        ? this.state.showUsers.summary
                        : "Summary haven't added yet"
                    )}
                  </h3>
                </div>
              </div>

              <div className="show-info">
                <div className="info-header">
                  <h2 className="header">Experience</h2>
                </div>
                {Object.keys(this.state.showUsers).length &&
                  (this.state.showUsers.experience.length ? (
                    this.state.showUsers.experience.map((exp, key) => (
                      <div className="experience-content" key={key}>
                        <div className="exp-title">
                          <h3 className="header name">{exp.expcompanyname}</h3>
                          <h3 className="header location">{exp.explocation}</h3>
                        </div>
                        <div className="exp-title exp-title-info">
                          <h3 className="header title">{exp.exptitle}</h3>
                          <h3 className="header date">
                            {moment.months(exp.expfrom_mon - 1) +
                              " " +
                              exp.expfrom_yr +
                              " " +
                              "-" +
                              " " +
                              (exp.expto_mon !== "" && exp.expto_yr !== ""
                                ? moment.months(exp.expto_mon - 1) +
                                " " +
                                exp.expto_yr
                                : "Till now")}
                          </h3>
                        </div>
                        <div className="exp-description">
                          <h3 className="header desc">Description</h3>
                        </div>
                        <div className="exp-desc-content">
                          <h3 className="header">{exp.expdes}</h3>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="info-description">
                      <h3 className="header">Experience haven't added yet</h3>
                    </div>
                  ))}
              </div>

              <div className="show-info">
                <div className="info-header">
                  <h2 className="header">Education</h2>
                </div>
                {Object.keys(this.state.showUsers).length &&
                  (this.state.showUsers.education.length ? (
                    this.state.showUsers.education.map((edu, key) => (
                      <div className="education-content" key={key}>
                        <div className="education edu-title">
                          <h3 className="header title">
                            {edu.eduschoolDegree +
                              " " +
                              "(" +
                              edu.eduschoolstudy +
                              ")" +
                              " - " +
                              edu.eduschooltoyear +
                              " [" +
                              edu.eduschoolmode +
                              "]"}
                          </h3>
                        </div>
                        <div className="education edu-name">
                          <h3 className="header name">{edu.edupschoolname}</h3>
                        </div>
                        <div className="education edu-grade">
                          <h3 className="header grade">
                            <span>Grade</span> : {edu.eduschoolgrade}
                          </h3>
                        </div>
                        <div className="education activities">
                          <h3 className="header activity education">
                            Activities
                          </h3>
                          <h3 className="header activity-info education">
                            {edu.eduschool_act}
                          </h3>
                        </div>
                        <div className="edu-description activities">
                          <h3 className="header activity education">
                            Description
                          </h3>
                          <h3 className="header activity-info">
                            {edu.eduexp_desc}
                          </h3>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="info-description">
                      <h3 className="header">Education haven't added yet</h3>
                    </div>
                  ))}
              </div>

              <div className="show-info">
                <div className="info-header">
                  <h2 className="header">Honor</h2>
                </div>
                {Object.keys(this.state.showUsers).length &&
                  (this.state.showUsers.honors.length ? (
                    this.state.showUsers.honors.map((honor, key) => (
                      <div className="info-description experience-content" key={key}>
                        <div className="exp-title">
                          <h3 className="header name">{honor.hontitle}</h3>
                          <h3 className="header location">
                            {moment.months(honor.honmonth - 1) +
                              " " +
                              honor.honyear}
                          </h3>
                        </div>
                        <div className="exp-description">
                          <h3 className="header desc">Description</h3>
                        </div>
                        <div className="exp-desc-content">
                          <h3 className="header">{honor.hondesc}</h3>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="info-description">
                      <h3 className="header">Honor haven't added yet</h3>
                    </div>
                  ))}
              </div>

              <div className="show-info">
                <div className="info-header">
                  <h2 className="header">Personal Details</h2>
                </div>
                <div className="info-description last-description">
                  <h3 className="header personal-info">
                    <span className="date">Date of Birth</span>
                    <span className="colon">:</span>
                    <span className="date-info">
                      {moment(personalInfo.dob).format("DD-MM-YYYY")}
                    </span>
                  </h3>

                  <h3 className="header personal-info">
                    <span className="date">Gender</span>
                    <span className="colon">:</span>
                    <span className="date-info">{personalInfo.gender}</span>
                  </h3>

                  <h3 className="header personal-info">
                    <span className="date">Location</span>
                    <span className="colon">:</span>
                    <span className="date-info">{personalInfo.location}</span>
                  </h3>

                  <h3 className="header personal-info">
                    <span className="date">State</span>
                    <span className="colon">:</span>
                    <span className="date-info">{personalInfo.userstate}</span>
                  </h3>

                  <h3 className="header personal-info">
                    <span className="date">Country</span>
                    <span className="colon">:</span>
                    <span className="date-info">{personalInfo.country}</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <ChatButton />
        </section>

        {/* message modal start */}
        <div
          className={
            "like-modal-container message-modal-container" +
            (this.state.messageModal ? " open" : "")
          }
        >


          <div className="like-modal message-modal" id="messagemodal">
            <button
              type="button"
              className="close-button-modal dark-button"
              id="close-createPost"
              onClick={this.hideMessageModal}

            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-header">
              <h1 className="header">
                Message to <span>{this.state.createName}</span>
              </h1>
            </div>

            <div className="creategroup-content login-content-fields msg-content-fields">
              <form action="" className="login">
                <textarea
                  className="description msg-description"
                  cols="30"
                  rows="5"
                  placeholder="Message"
                  name="message"
                  value={this.state.message}
                  onChange={this.handleInputChange}
                />

                {this.validator.message(
                  "Message field is",
                  this.state.message,
                  "required|string"
                )}

                <button
                  type="button"
                  className="login-button msg-button"
                  id="mySupplier"
                  onClick={this.sendMessage}
                >
                  <h2>Send</h2>
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* message modal end */}
      </>
    );
  }
}

export default withRouter(ProfileShow);
