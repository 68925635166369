import React, { Component } from "react";
import {TailSpin} from 'react-loader-spinner';

export class Loader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      color: (typeof this.props.color !== 'undefined') ? this.props.color : '#FFF'
    }
  }

  render() {
    return (
      <>
        <TailSpin wrapperStyle={{justifyContent: "center"}} type="Circles" color={this.state.color} height={15} width={15}/>
      </>
    );
  }
}

export default Loader;
