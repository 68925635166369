import React, { Component } from 'react'
import "../job/Job.css";
import "../product/Product.css";
import "../product-add/AddProduct.css";
import "../addsoftware/AddSoftware.css";
import "./transactionHistory.css";
import DataTable from "react-data-table-component";
import axios from "axios";
import { withRouter } from "react-router-dom";

export class TransactionHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      PaymentList: [],
    }
  }

  columns = [
    {
      name: "Order Id",
      selector: (row) => row.orderid,
      sortable: true,
    },
    {
      name: "Service",
      selector: (row) => row.shortName,
      sortable: true,
      cell: (row) => {
        switch (row.shortName) {
          case "pr":
            return "Product";

          case "sw":
            return "Software";

          case "jb":
            return "Job";

          case "sc":
            return "Sponsored Content";

          case "ad":
            return "Ads";

          case "sv":
            return "Services";
          default:
            return "";
        }
      },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Tax",
      selector: (row) => row.tax,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Transaction Date",
      selector: (row) => row.trans_date,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.order_status,
      sortable: true,
    },
  ];

  paymentHistory = async () => {

    try {
      const payment = await axios.get(
        `${process.env.REACT_APP_API_URL}payments`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (payment.status === 200 && payment.data.status === true) {

        await this.setState({
          PaymentList: payment.data.datas,
        })

      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }
    }
  };

  componentDidMount = async () => {
    await this.paymentHistory();
  }

  render() {
    return (
      <>

        <section className="product-section">

          <div className="free-offer-container list-table">
            <div className="content-container transDiv">
              <div id="tranhis">
                <h2 className="transactionHist">Transaction History</h2>
                <div className="dataTable">
                  <DataTable
                    columns={this.columns}
                    data={this.state.PaymentList}
                    pagination
                  />
                </div>

                <span className="highLighttext"> Amount shown above in INR is inclusive of Taxes and Levies</span>
              </div>


              <div className="printBtn sidebar-content">
                <div className="sidebar-button">
                  <button type="button" className="reset-button" id='printbtn' onClick={() => window.print()}>Print</button>
                </div>
              </div>
            </div>
          </div>
        </section>

      </>
    );
  }
}

export default withRouter(TransactionHistory);
