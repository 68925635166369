import "../Product.css";
import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import { Link, withRouter } from "react-router-dom";
import chatArrow from "../../../../assets/chat-arrow.png";

export class ProductLeftSidebar extends Component {
  constructor(props) {
    super(props);

    this.ProductsForm = React.createRef();

    this.state = {
      keyword: [],
      products: [],
      selectedOption: "",
      selectedKeyword: "",
      isSelectedCondition: "",
      isKeywordValue: "",
      isLocationValue: "",
      filter: "",
      locationOptions: [],
      keywordOptions: [],

      page: 0,
      perPage: 5,

      isReset:false,
      selectedOptionValue: 'all',
      selectedKeywordValue: 'all',
    };
  }

  handleSelected = async (selectedOption) => {

    if (this.state.selectedOptionValue !== selectedOption.value) {

      await this.setState({
        selectedOptionValue:selectedOption.value
      })

      if(selectedOption.value !== "") {
        this .setState({
          isReset:true, 
        })
      } else {
        this .setState({
          isReset:false, 
        })
      }
      await this.setState({ selectedOption });
      await this.props.locationCallback(selectedOption.value);

    }

  };

  handleSelectedKeyword = async (selectedKeyword) => {

    if (this.state.selectedKeywordValue !== selectedKeyword.value) {

      await this.setState({
        selectedKeywordValue:selectedKeyword.value
      })

      if(selectedKeyword.value !== "") {
        this .setState({
          isReset:true, 
        })
      } else {
        this .setState({
          isReset:false, 
        })
      }
      await this.setState({ selectedKeyword });
      await this.props.parentCallback(selectedKeyword.value);

    }
    
  };

  handleInputChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });

    if (e.target.name === "filter") {
      if(e.target.value?.replace(/^\s+/g, '') !== "") {
        this .setState({
          isReset:true, 
        })
      } else {
        this .setState({
          isReset:false, 
        })
      }

      await this.props.filterCallback(e.target.value?.replace(/^\s+/g, ''));
    }

    if (e.target.name === "isSelectedCondition") {
      if(e.target.value?.replace(/^\s+/g, '') !== "") {
        this .setState({
          isReset:true, 
        })
      } else {
        this .setState({
          isReset:false, 
        })
      }
      await this.props.conditionCallback(e.target.value?.replace(/^\s+/g, ''));
    }
  };

  resetFilter = async (e) => {
    if(this.state.isReset){
    // e.preventDefault();
      await this.setState({
        selectedOption: "",
        selectedKeyword: "",
        isSelectedCondition: "",
        filter: "",
        keyword: [],
        products: [],
        isKeywordValue: "",
        isLocationValue: "",
        locationOptions: [],
        keywordOptions: [],
      });

      // this.ProductsForm.current.reset();
      await this.props.myreset();
      
          // keyword api
      await this.keywordApi();

      // location api
      await this.locationApi();
    
    }
   
  };

  keywordApi = async () => {
    try {
      const productKeyword = await axios.get(
        `${process.env.REACT_APP_API_URL}product/keyword`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (
        productKeyword.status === 200 &&
        productKeyword.data.status === true
      ) {
        const keywordArray = [];

        keywordArray.push({
          value: 'all',
          label: 'All Categories',
        })

        productKeyword.data.datas.map((keys) =>
        
          keywordArray.push({
            value: keys.category,
            label: keys.category,
          })
        );

        await this.setState({
          keyword: productKeyword.data.datas,
          keywordOptions: keywordArray,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }
    }
  }

  locationApi = async () => {
    var plkey = {
      plkey: 'all',
    }
        // location api
        try {
          const productLocation = await axios.post(
            `${process.env.REACT_APP_API_URL}product/location`,
            plkey,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
          if (
            productLocation.status === 200 &&
            productLocation.data.status === true
          ) {
            const locationArray = [];
    
            locationArray.push({
              value: 'all',
              label: 'All Locations',
            })

            productLocation.data.datas.map((keys) => {
                if(keys.name !== "") {
                locationArray.push({
                    value: keys.name,
                    label: keys.name,
                  })
                }
              }
            );
    
            await this.setState({
              // keyword: productLocation.data.datas,
              locationOptions: locationArray,
            });
          }
        } catch (err) {
          if (err?.response?.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          }
        }
  }

  componentDidMount = async () => {

    this.setState({
      isReset:this.props.isReset,
    });

    // keyword api
    await this.keywordApi();

    // location api
    await this.locationApi();
    
  };

  componentWillReceiveProps(newProps) {
    // if (this.state.locationOptions.length !== newProps.locationShow.length) {
    //   const arr = newProps.locationShow.map((val) => {
    //     return { value: val, label: val };
    //   });

    //   this.setState({
    //     locationOptions: arr,
    //   });
    // }

    // if (this.state.keywordOptions.length !== newProps.categoryShow.length) {
      
    //   const arr = newProps.categoryShow.map((val) => {
    //     console.log('vl', val)
    //     return { value: val, label: val };
    //   });

    //   this.setState({
    //     keywordOptions: arr,
    //   });
    // }
  }

  render() {
    return (
      <>
        <div className="left-sidebar">
          <Link to="/">
            <div className="chat-arrow">
              <img src={chatArrow} alt="chatArrow" />
            </div>
          </Link>

          <div className="sidebar-container">
          
            <div className="header-content">
              <h2 className="header">Filters</h2>
            </div>

            <div className="content-container">
            <form className="form-field" ref={this.ProductsForm}>
              <div className="sidebar-content">
                <div className="header-content">
                  <h3 className="header">Search</h3>
                </div>
                {/* <form className="form-field" > */}
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Search"
                    onChange={this.handleInputChange}
                    value={this.state.filter}
                    name="filter"
                  />
                {/* </form> */}
              </div>

              <div className="sidebar-content">
                <div className="header-content">
                  <h3 className="header">Keyword</h3>
                </div>
                <div className="caret-content">
                 
                  <Select
                    className="form-select option-multi-select location-select"
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    styles={
                      ({
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      },
                      {
                        control: (base) => ({
                          ...base,
                          border: "0 !important",
                          boxShadow: "0 !important",
                          "&:hover": {
                            border: "0 !important",
                          },
                        }),
                      })
                    }
                    value={this.state.selectedKeyword}
                    onChange={this.handleSelectedKeyword}
                    options={this.state.keywordOptions}
                    placeholder="All Categories"
                  />
                  
                </div>
              </div>

              <div className="sidebar-content">
                <div className="header-content">
                  <h3 className="header">Location</h3>
                </div>
                <div className="caret-content">
                 
                  <Select
                    className="form-select option-multi-select location-select"
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    styles={
                      ({
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      },
                      {
                        control: (base) => ({
                          ...base,
                          border: "0 !important",
                          boxShadow: "0 !important",
                          "&:hover": {
                            border: "0 !important",
                          },
                        }),
                      })
                    }
                    defaultValue
                    value={this.state.selectedOption}
                    onChange={this.handleSelected}
                    options={this.state.locationOptions}
                    placeholder="All Locations"
                  />
                </div>
              </div>

              <div className="sidebar-content">
                <div className="header-content">
                  <h3 className="header">Product Condition</h3>
                </div>
                <div className="caret-content">
                 
                  <select
                    id="ptype"
                    name="isSelectedCondition"
                    value={this.state.isSelectedCondition}
                    onChange={this.handleInputChange}
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option value="">All Types</option>
                    <option value="new">New</option>
                    <option value="used">Used-Not Refurbished</option>
                    <option value="refurbished">Used-Refurbished</option>
                  </select>
                </div>
              </div>
              </form>
              <form className="sidebar-button" onClick={this.resetFilter}>
                <button type="button" className="reset-button">
                  Reset
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ProductLeftSidebar);
