import "../modals/Popup.css"
import React, { Component } from 'react'
import { Link, Switch, withRouter } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar, { NavbarLogin } from "../navbar/Navbar";

import pexit from '../../../assets/pexit-logo.png'
import * as yup from 'yup';
import { connect } from 'react-redux'
import { login } from '../../../redux/slices/authentication'
import { Forgot } from "../../pages/forgot/Forgot";

import SimpleReactValidator from "simple-react-validator";
import FacebookLogin from 'react-facebook-login';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import GoogleLogin from 'react-google-login';
import Loader from '../loader/Loader'
const { OAuth2Client } = require('google-auth-library')
const client = new OAuth2Client(process.env.REACT_APP_GOOGLE_CLIENT_ID)

class Login extends Component {
    constructor(props) {
        super(props);


        this.validator = new SimpleReactValidator({
            messages: {
              required: "The :attribute required.",
              alpha: "The :attribute must be string.",
            },
        });

        this.state = {
            forgotPassword: false,
            type: 'password',
            score: 'null',
            socialLogin: false,

            socialUname: "",
            socialUemail:"",
            login:false,
            data:{},
            picture:"",
            socialCountry:"",
            GoogleisLoggedIn: false,

            creds: {
                username: '',
                password: ''
            },
            isLoading: false,
        };


        this.showHide = this.showHide.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();

        let schema = yup.object().shape({
            username: yup.string().required(),
            password: yup.string().required().replace(/^\s+/g, ''),
        });


        schema.validate(this.state.creds, {
            abortEarly: false
        }).then(async (valid) => {
            if(this.state.isLoading === false){
                await this.setState({
                    isLoading: true,
                })
                await this.DoLogin()
                await this.setState({
                    isLoading: false,
                })
            }

        }).catch(err => {
            err.errors.forEach((error) => {
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
        })
    }

    responseFacebook = async(response) => {
        
        // Login failed
        if (response.status === "unknown") {
        
          toast.error("Login failed!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

          this.setState({
            login:false,
          })
          
          return false;
        }

        if (response.accessToken) {
            
            this.setState({
                login:true,
            })
        } else {
            
            this.setState({
                login:false,
            })
        }

        await this.setState({
            socialReg:true,
        })


        if (this.state.login) {
            
            this.setState({
                socialUname:response.name,
            })

            var socialres = await axios.post(`${process.env.REACT_APP_API_URL}socialmedialogin`, {
                "username": this.state.socialUname,
            })

            if (socialres.status === 200) {
                if (socialres.data.status === true){
                    toast.success(socialres.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    localStorage.setItem("token", socialres.data.token);
                    localStorage.setItem("userData", JSON.stringify(socialres.data.datas));
                    localStorage.setItem("userName", socialres.data.datas.userName);
                }
                else {
                
                    toast.error(socialres.data.errors ? socialres.data.errors : socialres.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            } 

            setTimeout(() => {
                window.location = process.env.PUBLIC_URL
                this.props.loginAction(true)
            }, 3000);
        }

    }

    responseGoogleSuccess = async (response) => {

        if (response.accessToken) {
            this.setState({
                GoogleisLoggedIn:true,
            })
        } else {
            this.setState({
                GoogleisLoggedIn:false,
            })
        }

        if (this.state.GoogleisLoggedIn) {

            this.setState({
                socialUname:response.profileObj.name,
            })

            var socialres = await axios.post(`${process.env.REACT_APP_API_URL}socialmedialogin`, {
                "username": this.state.socialUname,
            })

            if (socialres.status === 200) {
                if (socialres.data.status === true){
                    toast.success(socialres.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    localStorage.setItem("token", socialres.data.token);
                    localStorage.setItem("userData", JSON.stringify(socialres.data.datas));
                    localStorage.setItem("userName", socialres.data.datas.userName);

                }
                else {
                
                    toast.error(socialres.data.errors ? socialres.data.errors : socialres.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            } 

            setTimeout(() => {
                window.location = process.env.PUBLIC_URL
                this.props.loginAction(true)
            }, 3000);
        }
    }

    // Error Handler
    responseGoogleError = async (response) => {
    };

    linkedinLogin = async (code) => {

        let linkedData = await axios.get(
            `${process.env.REACT_APP_API_URL}linkedin/${code}`,
            {
                headers: {
                    Auth: `${localStorage.getItem("token")}`
                },
            }
        );

        if (linkedData.status === 200) {
            
            const UserName = linkedData.data.firstName + " " + linkedData.data.lastName

            this.setState({
                socialUname:UserName,
            })

            var socialres = await axios.post(`${process.env.REACT_APP_API_URL}socialmedialogin`, {
                "username": this.state.socialUname,
            })

            if (socialres.status === 200) {
                if (socialres.data.status === true){
                    toast.success(socialres.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    localStorage.setItem("token", socialres.data.token);
                    localStorage.setItem("userData", JSON.stringify(socialres.data.datas));
                    localStorage.setItem("userName", socialres.data.datas.userName);

                }
                else {
                
                    toast.error(socialres.data.errors ? socialres.data.errors : socialres.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            } 

            setTimeout(() => {
                window.location = process.env.PUBLIC_URL
                this.props.loginAction(true)
            }, 3000);
        }
    }
       

    async DoLogin() {
        if (this.validator.allValid()) {
            if(this.state.isLoading === false){
                
                await this.setState({
                    isLoading: true,
                })
                try {
                    var res = await axios.post(`${process.env.REACT_APP_API_URL}login`, {
                        "username": this.state.creds.username,
                        "password": this.state.creds.password
                    })
                

                    if (res.data.status === false) {
                        toast.error(res.data.message, {
                            position: "top-right",
                            autoClose: 4000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.success(res.data.message, {
                            position: "top-right",
                            autoClose: 4000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        localStorage.setItem("token", res.data.token);
                        localStorage.setItem("userData", JSON.stringify(res.data.datas));
                        localStorage.setItem("userName", res.data.datas.userName);

                        await axios.get('https://ipapi.co/json/').then((response) => {
                            let data = response.data;
                            
                            let currencyDetails = {
                                country_name:data.country_name,
                                currency:data.currency,
                            }
                            localStorage.setItem("userLocation", JSON.stringify(currencyDetails));
                           
                        }).catch((error) => {
                            console.log(error);
                        });

                        setTimeout(() => {
                            window.location = process.env.PUBLIC_URL
                            this.props.loginAction(true)
                        }, 2000)
                    }

                } catch (error) {
                    toast.error(error.message ? error.message : error.message, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setTimeout(async () => {
                    await this.setState({
                        isLoading: false,
                    })
                },5000)
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    forgotPass = async () => {
        const forgotPassword =
            this.state.forgotPassword === false ? true : false

        await this.setState({
            forgotPassword: forgotPassword,
        })
    }

    hideForgotModal = async () => {
        await this.setState({
            forgotPassword: false,
        })
    }

    showHide(e) {
        this.setState({
            type: this.state.type === 'password' ? 'input' : 'password'
        })
    }

    socialLogin = async () => {
        await this.setState({
            socialLogin:true,
        })

        this.DoLogin();
    }

    componentDidMount = async() =>{
        if(localStorage.getItem("token")) {
            window.location = process.env.PUBLIC_URL
        }
    }

    render() {
        return (
            <>

                {this.state.forgotPassword ? <Forgot hideModal={this.hideForgotModal} /> : ""}

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    className="toast-container"
                    toastClassName="dark-toast" 
                />

                <section className="login-popup">
                    <div className="login-popup-container">

                        <button type="button" className="close-button-modal login-close-modal" id="close-login"
                            onClick={() => this.props.history.push('/')}>
                            <i className="fas fa-times"></i>
                        </button>

                        <div className="pexit-logo">
                            <img src={pexit} alt="pexit-logo" />
                        </div>

                        <div className="login-container">
                            <h1 className="header login-header">Login</h1>

                            <div className="login-content-fields">
                                <form action="" className="login" onSubmit={(e) => { 
                                    e.preventDefault(); this.DoLogin()
                                }}>

                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form_control login-field"
                                            name="username"
                                            value={this.state.creds.username}
                                            onChange={(e) => {
                                                this.setState({
                                                    creds: {
                                                        ...this.state.creds,
                                                        username:e.target.value?.replace(/^\s+/g, '')

                                                    }
                                                })
                                            }}
                                            placeholder="User Name"
                                        />
                                        {this.validator.message(
                                            "User name field is",
                                            this.state.creds.username,
                                            "required|string"
                                        )}
                                    </div>

                                    <div className="input-group">
                                        <input
                                            type={this.state.type}
                                            name="password"
                                            className="form_control login-field"
                                            value={this.state.creds.password}
                                            onChange={(e) => {
                                                this.setState({
                                                    creds: {
                                                        ...this.state.creds,
                                                        password:e.target.value?.replace(/^\s+/g, '')

                                                    }
                                                })
                                            }}
                                            placeholder="Password"
                                        />
                                        <span className="password__show" onClick={this.showHide}>{this.state.type === 'password' ?
                                            <i className="far fa-eye"></i> :
                                            <i className="far fa-eye-slash"></i>}</span>
                                        {this.validator.message(
                                            "Password field is",
                                            this.state.creds.password,
                                            "required|string"
                                        )}
                                    </div>

                                    <div className="forgot-pass-link"
                                        onClick={this.forgotPass}
                                    >
                                        <h2 className="header">Forgot Password ?</h2>
                                    </div>

                                    <button  type="submit" disabled={this.state.isLoading} className="login-button" id="myButton"
                                    >{this.state.isLoading ? <Loader /> : 'Login' }</button>

                                </form>

                                <div className="seperator">
                                    <div className="line line-left"></div>
                                    <div className="seperator-text">
                                        <h1 className="header">or</h1>
                                    </div>
                                    <div className="line line-right"></div>
                                </div>

                                <div className="social-buttons-content">
                                    <div className="social-button">
                                        
                                    <GoogleLogin
                                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                        // buttonText="Sign in with Google"
                                        render={renderProps => (
                                            <button onClick={renderProps.onClick}>Sign in with Google</button>
                                            )}
                                        onSuccess={this.responseGoogleSuccess}
                                        onFailure={this.responseGoogleError}
                                        className="google"
                                        icon={false}
                                        disabledStyle={false}
                                        // cookiePolicy={'single_host_origin'}
                                    />

                                    </div>
                                    <div className="social-button">
                                    <FacebookLogin
                                        appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                                        autoLoad={false}
                                        cssClass="facebook"
                                        fields="name,email,picture,location,hometown,locale,currency,first_name,last_name"
                                        scope="public_profile,email,user_location,user_hometown"
                                        callback={this.responseFacebook}
                                        textButton = "Sign In with Facebook"
                                        // icon="fa-facebook" 
                                    />
                                    </div>

                                    <LinkedIn
                                        clientId={process.env.REACT_APP_REACT_LINKEDIN_CLIENT_ID}
                                        redirectUri={`${window.location.origin}/linkedin`}
                                        onSuccess={(code) => {
                                            this.linkedinLogin(code);
                                        }}
                                        
                                        scope="r_liteprofile r_emailaddress"
                                        >
                                        {({ linkedInLogin }) => (
                                            <div className="social-button">
                                                <button type="button" className="linkedin" onClick={linkedInLogin}>
                                                    <h1>Sign in with Linkedin</h1>
                                                </button>
                                            </div>
                                           
                                        )}
                                    </LinkedIn>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

const mapDispatchToProps = {
    loginAction: login
}

export default
    connect(null, mapDispatchToProps)(withRouter(Login));




