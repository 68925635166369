import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import "../product/Product.css";
import "../supplier/Supplier.css";
import "../supplier-detail/SupplierDetailed.css";
import "../postPage/Post-page.css";
import Parser from "html-react-parser";

import ContactSupplier from "../contactsupplier/ContactSupplier";
import productEmail from "../../../assets/product-email.png";
import caepipeIcon from "../../../assets/caepipe-icon.png";

export class SupplierDetailed extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.myLinks = React.createRef();
    this.myAcc = React.createRef();
    this.mySilver = React.createRef();

    this.state = {
      contactSupplierModel: false,
      cmpyProfile: false,
      details: [],
      ispageShow: false,
      relatedDetails: [],
      supplierDetail: "",
      supplierName: "",
      supplierId: "",
      loggedUser: "",
      senderEmail: "",
      senderPhone: "",
      shownCategory: "All",
    };
  }

  // set ternery condition on the create post modal for showing the modal
  showModal = async (sname = '', id = 0, name = '', email = '', phone = '') => {
    const contactSupplierModel =
      this.state.contactSupplierModel === false ? true : false;
    await this.setState({
      contactSupplierModel: contactSupplierModel,
      supplierId: id,
      supplierName: sname,
      loggedUser: name,
      senderEmail: email,
      senderPhone: phone,
    });
  };

  hideContactModal = async () => {
    await this.setState({
      contactSupplierModel: false,
    });
  };

  showCmpyProfile = async () => {
    const cmpyProfile = this.state.cmpyProfile === false ? true : false;

    await this.setState({
      cmpyProfile: cmpyProfile,
    });
  };

  hideCmpyProfile = async () => {
    await this.setState({
      cmpyProfile: false,
    });
  };

  category = async (evt, cityName) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent-categories");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks-product");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
    document.getElementById("defaultOpen").click();
  };

  componentDidMount = async () => {
    const id = this.props.match.params.id;

    try {
      const products = await axios.get(
        `${process.env.REACT_APP_API_URL}supplier-web/${id}`,

        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (products.status === 200 && products.data.status === true) {
        this.setState({
          details: products.data.datas,
          ispageShow: true,
        });
      } else {
        this.props.history.goBack();
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }
    }
  };

  async showCategory(cat) {
    await this.setState({
      shownCategory: cat,
    });
  }

  render() {
    return (
      <>
        {this.state.contactSupplierModel ? (
          <ContactSupplier
            hideModal={this.hideContactModal}
            supplierRecipient={this.state.supplierName}
            supplierId={this.state.supplierId}
            senderName={this.state.loggedUser}
            senderEmail={this.state.senderEmail}
            senderPhone={this.state.senderPhone}
          />
        ) : (
          ""
        )}
        {this.state.ispageShow ? (
          <section className="detailed-software">
            <div className="detailed-container">
              {this.state.details.map((detail, index) => (
                <span key={index}>
                  <div className="download-button">
                    <button
                      type="button"
                      className="caepipe-download"
                      id="productEmail-navrathan"
                      onClick={(e) => {
                        e.preventDefault();
                        this.showModal(
                          detail?.sname,
                          detail?.id,
                          detail?.loggedUser?.name,
                          detail?.loggedUser?.email,
                          detail?.phone
                        )
                      }}
                    >
                      <img src={productEmail} alt="productEmail" />
                    </button>
                  </div>

                  <div className="detail-header">
                    <h1 className="header">{detail.sname}</h1>
                  </div>

                  <div className="content-container content-container-part">
                    <div className="caepipe-image">
                      <img src={detail.simage} alt="null" />
                    </div>

                    <div className="caepipe-info">
                      <div className="caepipe-location service-location">
                        <div className="location-icon">
                          <h1 className="header">Address</h1>
                          <div className="icon-content service-icon-content">
                            <h2 className="grey-header">{detail.address}</h2>
                          </div>
                        </div>
                      </div>

                      <div className="caepipe-location service-location service-location-two">
                        <div className="location-icon">
                          <h1 className="header">Location</h1>
                          <h2 className="grey-header grey-headers">
                            {detail.userstate !== ""
                              ? detail.userstate + ", "
                              : "" + detail.location !== ""
                                ? detail.location + ", "
                                : "" + detail.country}
                          </h2>
                        </div>
                      </div>

                      <div className="caepipe-location service-location">
                        <div className="location-icon">
                          <h1 className="header">Contact Number</h1>
                          <h2 className="grey-header grey-headers">
                            {detail.phone}
                          </h2>
                        </div>
                      </div>

                      <div className="caepipe-location service-location categories-button">
                        <div className="location-icon company-profile-button icon-content">
                          <button
                            type="button"
                            className="company-profile"
                            onClick={this.showCmpyProfile}
                          >
                            <h2 className="header">Company Profile</h2>
                            <div type="button" className="icon-button">
                              <img src={caepipeIcon} alt="caepipeIcon" />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-description">
                    <div className="service-description-content">
                      <div className="service-header">
                        <h1 className="header">About Us</h1>
                      </div>
                    </div>

                    <div className="caepipe-forum caepipe-forum-part">
                      <h2 className="header truncate">
                        <ShowMoreText
                          /* Default options */
                          lines={3}
                          more="Read more"
                          less="Read less"
                          className="content-css"
                          anchorClass="my-anchor-css-class"
                          onClick={this.executeOnClick}
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          {Parser(detail.summary)}
                        </ShowMoreText>
                      </h2>
                    </div>
                  </div>
                </span>
              ))}

              <div className="responsive-map">
                <iframe
                  src=" https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d62207.00138070588!2d77.56935849529742!3d12.97584379893453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bae16873fa54e2d%3A0xc74f6b3e042835a9!2sNAVRATHAN%20JEWELLERS%2C%20Mahatma%20Gandhi%20Road%2C%20Haridevpur%2C%20Shanthala%20Nagar%2C%20Ashok%20Nagar%2C%20Bengaluru%2C%20Karnataka!3m2!1d12.9757625!2d77.60437809999999!5e0!3m2!1sen!2sin!4v1640154024136!5m2!1sen!2sin"
                  width="600"
                  styles="border:0; height:80vh"
                  allowFullScreen=""
                  loading="lazy"
                  title="1"
                />
              </div>
            </div>

            {/* company profile modal start */}
            <div
              className={
                "like-modal-container cmpy-profile-modal" +
                (this.state.cmpyProfile ? " open" : "")
              }
            >
              <div className="company-profile-container">
                <button
                  type="button"
                  className="close-button-modal"
                  id="close-createPost"
                  onClick={this.hideCmpyProfile}
                >
                  <i className="fas fa-times" />
                </button>

                {this.state.details.map((detail, key) => (
                  <span key={key}>
                    {detail.companyProfile !== "" ? (
                      <div className="like-modal cmpy-modal">
                        <iframe
                          src={detail.companyProfile}
                          width="600"
                          style={{ border: 0, MaxHeight: '70vh' }}
                          allowFullScreen=""
                          loading="lazy"
                          title="2"
                        />
                      </div>
                    ) : (
                      <div className="like-modal cmpy-modal">
                        <div className="user-name">
                          <h1 className="header">No Company Profile Found</h1>
                        </div>
                      </div>
                    )}
                  </span>
                ))}
              </div>
            </div>
            {/* company profile modal end */}

            <div className="related-software-container related-product-categories">
              <div className="container-header">
                <h1 className="header">Product Categories</h1>
              </div>

              <div className="product-categories-filter">
                <div className="tab-product-categories">
                  <button
                    className={
                      this.state.shownCategory === "All"
                        ? "tablinks-product active"
                        : "tablinks-product"
                    }
                    onClick={() => {
                      this.showCategory("All");
                    }}
                  >
                    All
                  </button>
                  {this.state.details[0]?.categories.map((cat, key) => {
                    return (
                      <button
                        key={key}
                        className={
                          this.state.shownCategory === cat.category
                            ? "tablinks-product active"
                            : "tablinks-product"
                        }
                        onClick={() => {
                          this.showCategory(cat.category);
                        }}
                      >
                        {cat.category}
                      </button>
                    );
                  })}
                </div>

                {this.state.details[0]?.productList.map((cat, key) => {
                  return (
                    <div
                      className={
                        this.state.shownCategory === cat.cName
                          ? "tabcontent-categories product-boxes open " +
                          cat.cName
                          : "tabcontent-categories product-boxes " + cat.cName
                      }

                      key={key}
                    >
                      <div className="boxes-container categories-box-container">
                        {cat.pList.map((p, key) => {
                          return (
                            <Link to={`/product-detail/${p.id}`} key={key}>
                              <div className="product-boxes-content">
                                <a href="detailed-product.php">
                                  <div className="product-image categories-image">
                                    <img src={p.image} alt={p.name} />
                                  </div>
                                </a>
                                <div className="product-info">
                                  <div className="product-name">
                                    <h2 className="header">{p.name}</h2>
                                  </div>
                                  
                                  <div className="product-address">
                                    <h2 className="header grey-header truncate">
                                      <ShowMoreText
                                        /* Default options */
                                        lines={3}
                                        more="Read more"
                                        less="Read less"
                                        className="content-css"
                                        anchorClass="my-anchor-css-class"
                                        onClick={this.executeOnClick}
                                        expanded={false}
                                        truncatedEndingComponent={"... "}
                                      >
                                        {Parser(p.descrip)}
                                      </ShowMoreText>
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default withRouter(SupplierDetailed);
