import "../job/Job.css";
import "./Service.css";
import "../product/Product.css";
import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import ChatButton from "../../components/chatbutton/ChatButton";
import Loader from "../loader/Loader";
import { ServiceLeftSidebar } from "./serviceleftsidebar/ServiceLeftSidebar";
import queryString from "query-string";

export class ServicePage extends Component {
  constructor(props) {
    super(props);

    this.ProductsElement = React.createRef();

    this.state = {
      searchKeyword: "",
      isSearchResult: false,
      searchLocation: "",
      filter: "",
      locationData: [],
      listing: [],
      page: 0,
      nextAvailable: true,
      perPage: 12,
      loading: false,
      allService: [],

      isReset: false,
      isLoaded: false,

      isServiceLeftSideBar: false,
      openServiceLeftSideBar: false,

      total:0,

      isButtonLoding: false,
    };
  }

  serviceApi = async () => {
    if (this.state.nextAvailable) {
      await this.setState({
        loading: true,
        page: this.state.page + 1,
      });

      const serviceData = {
        page: this.state.page,
        records: this.state.perPage,
        keyword: this.state.searchKeyword,
        searchField: this.state.filter,
        location: this.state.searchLocation,
      };

      try {
        var services;
        if (this.state.isSearchResult === true) {
          services = await axios.post(
            `${process.env.REACT_APP_API_URL}search-web`,
            {
              key: this.state.searchKeyword,
              page: this.state.page,
              records: this.state.perPage,
              type: 6,
            },
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
        } else {
          services = await axios.post(
            `${process.env.REACT_APP_API_URL}getallservices`,
            serviceData,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
        }

        if (services.status === 200 && services.data.status === true) {

          this.setState({
            isLoaded: true,
          })

          if (services.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }

          let oldArray = this.state.listing;
          oldArray = (this.state.page === 1) ? services.data.datas : oldArray.concat(services.data.datas);

          await this.setState({
            allService: oldArray,
            total:services.data.total,
          });

          await this.setState({
            listing: oldArray,
          });
        } else {
          await this.setState({
            isLoaded: true,
          })
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        }
      }

      await this.setState({
        loading: false,
      });
    }
  };

  componentDidMount = async () => {
    await this.handleResize();
    const queries = queryString.parse(this.props.location.search);

    if ("key" in queries && queries.key !== null && queries.key !== "") {

      await this.setState({
        isSearchResult: true,
        searchKeyword: queries.key,
        page: 0,
        nextAvailable: true,
      });
    }

    this.setState({
      loading: true,
    });

    await this.serviceApi();

    window.addEventListener("scroll", this.listenToScroll);

    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isServiceLeftSideBar: true,
        openServiceLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isServiceLeftSideBar: false,
      });
    }
  };

  listenToScroll = async () => {
    if (this.state.loading === false && this.state.nextAvailable) {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight - 5) {
        await this.serviceApi();
      }
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.time !== this.props.location.time) {
      const queries = queryString.parse(this.props.location.search);

      if ("key" in queries && queries.key !== null && queries.key !== "") {
        await this.setState({
          isSearchResult: true,
          searchKeyword: queries.key,
          page: 0,
          nextAvailable: true,
          loading: true,
        });

        await this.serviceApi();
      } else {
        await this.setState({
          searchKeyword: "",
          page: 0,
          nextAvailable: true,
          loading: true,
        });

        await this.serviceApi();
      }
    }

    if (prevState.searchLocation !== this.state.searchLocation) {
      await this.serviceApi();

      await this.setState({
        loading: false,
      });
    }

    if (prevState.filter !== this.state.filter) {
      await this.serviceApi();

      await this.setState({
        loading: false,
      });
    }
  }

  handleLocation = async (optionData) => {

    this.setState({
      isLoaded: false,
    })

    if (optionData !== "") {
      this.setState({
        isReset: true,
      })
    } else {
      this.setState({
        isReset: false,
      })
    }

    await this.setState({
      searchLocation: optionData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });

    await this.serviceApi();
  };

  handleFilter = async (filterData) => {

    this.setState({
      isLoaded: false,
    })

    if (filterData !== "") {
      this.setState({
        isReset: true,
      })
    } else {
      this.setState({
        isReset: false,
      })
    }

    await this.setState({
      filter: filterData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });

    await this.serviceApi();
  };

  myreset = async () => {
    if (this.state.isReset) {
      await this.setState({
        searchKeyword: "",
        searchLocation: "",
        filter: "",
        nextAvailable: true,
        loading: false,
        page: 0,
        listing: [],
        isReset: false,
        isLoaded: false,
      });

      await this.serviceApi();
    }
  };

  showServiceLeftSideBar = async () => {
    if (this.state.openServiceLeftSideBar) {
      await this.setState({
        openServiceLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openServiceLeftSideBar: true,
      });
    }
  };

  searchLocation = async (key) => {
    
    if(key === '') {
      await this.setState({
        locationData: [],
        isButtonLoding: false,
      });

      return;
    }

    this.setState({
      isButtonLoding: true
    })

    const searchLocation = await axios.get(
      `${process.env.REACT_APP_API_URL}job/getalllocations/${key}`,
      {
        headers: {
          Auth: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (searchLocation.status === 200 && searchLocation.data.status === true) {
      
      let unique = searchLocation.data.datas;

      await this.setState({
        locationData: unique.sort(),
        isButtonLoding: false,
        
      });

    } else {
      await this.setState({
        locationData: [],
      });
    }

  }

  render() {
    return (
      <>
        <section className="product-section">
          {(this.state.isServiceLeftSideBar || this.state.openServiceLeftSideBar) &&
            <ServiceLeftSidebar
              myreset={this.myreset}
              locationShow={this.state.locationData}
              locationCallback={this.handleLocation}
              filterCallback={this.handleFilter}
              isReset={this.state.isReset}
              searchLocation={this.searchLocation}
              isButtonLoding={this.state.isButtonLoding}
            />
          }

          <div className="product-container serviceContainer">
            {(!this.state.openServiceLeftSideBar && !this.state.isServiceLeftSideBar) &&
              <div className="Service-hamburger-menu" id="listing-hamburger">
                <i class="fa fa-bars" aria-hidden="true" onClick={this.showServiceLeftSideBar}></i>
              </div>
            }
            {(this.state.openServiceLeftSideBar && !this.state.isServiceLeftSideBar) &&
              <div className="Service-hamburger-menu" id="listing-hamburger">
                <i class="fa fa-times" aria-hidden="true" onClick={this.showServiceLeftSideBar}></i>
              </div>
            }
            <div className="product-box-container" ref={this.ProductsElement}>
              <div className="product-boxes">
                <div className="product-header">
                  <h1 className="header">Services</h1>
                  {this.state.isLoaded &&
                    <h2>{this.state.total} Results</h2>
                  }
                </div>

                {this.state.isLoaded &&
                  <div className="boxes-container">
                    {this.state.listing.length > 0 ? (
                      this.state.listing.map((lists, index) => (
                        <Link to={`/service-detail/${lists.id}`}>
                          <div className="product-boxes-content" key={index}>

                            <div className="product-image">
                              <img src={lists.logo} alt="" />
                            </div>


                            <div className="product-info">
                              <div className="product-name">
                                <h2 className="header">{lists.company}</h2>
                              </div>
                              <div className="product-place">
                                <h2 className="header grey-header">
                                  {lists.industry}
                                </h2>
                              </div>
                              <div className="product-address">
                                <h2 className="header grey-header">
                                  {lists.city +
                                    ", " +
                                    lists.states +
                                    ", " +
                                    lists.country}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))
                    ) : (
                      <h2>No Service Available</h2>
                    )}
                  </div>
                }
              </div>
            </div>
            {this.state.loading && <Loader />}
          </div>
        </section>

        <ChatButton />
      </>
    );
  }
}

export default withRouter(ServicePage);
