import "./Post-page.css";
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import ShowMoreText from "react-show-more-text";
import ReactReadMoreReadLess from "react-read-more-read-less";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import queryString from "query-string";
import Loader from "../loader/Loader";
import moment from "moment";
import PostLeftSidebar from "../../pages/postPage/postleftsidebar/PostLeftSidebar";
import PostRightSidebar from "../../pages/postPage/postrightbar/PostRightSidebar";

import addLink from "../../../assets/add-link.png";
import threeDots from "../../../assets/3-dots.png";
import cameraIcon from "../../../assets/camera-icon.png";
import comment from "../../../assets/comment-icon.png";
import files from "../../../assets/files.png";

import sendMail from "../../../assets/send-mail.png";
import shareIcon from "../../../assets/share-icon.png";
import videoIcon from "../../../assets/video-icon.png";
import youtube from "../../../assets/youtube.png";
import postGlobe from "../../../assets/post-globe.png";
import { Report } from "../report/Report";
import { saveAs } from "file-saver";
import { Editor } from "@tinymce/tinymce-react";
import * as mime from "react-native-mime-types";
import Select from "react-select";
import ButtonLoader from "../../components/loader/Loader";
import Layout01 from "./layout1/Layout01";
import Layout02 from "./layout2/Layout02";
import Layout03 from "./layout3/Layout03";
import SponsorLayout from "./sponsorLayout/SponsorLayout";

export class PostPage extends Component {
  constructor(props) {
    super(props);

    //dynamic validation for title field and content field
    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.CreatePostvalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleLinks: {
          // name the rule
          message: "The :attribute must be a valid links",
          rule: (val, params, validator) => {
            var reg = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, "");
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        multipleYoutubeLinks: {
          // name the rule
          message: "The :attribute must be a valid youtube links",
          rule: (val, params, validator) => {
            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, "");
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        PostFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, Invalid file types are " +
            process.env.REACT_APP_POST_FILES.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let retfiles = true;
            let arfiles = process.env.REACT_APP_POST_FILES.split(",");
            for (let i = 0; i < files.length; i++) {
              if (arfiles.includes(files[i].name.split(".").pop()) !== false) {
                retfiles = false;
              }
            }
            return retfiles;
          },
        },
        Photos: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_PHOTOS.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_PHOTOS.split(",");
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              }
            }
            return ret;
          },
        },
        VideoFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_VIDEOS.split(","),
          rule: (val, params, validator) => {
            let Videofiles = val.files ?? [];
            let Videoret = true;
            let ar = process.env.REACT_APP_POST_VIDEOS.split(",");
            for (let i = 0; i < Videofiles.length; i++) {
              if (ar.includes(mime.extension(Videofiles[i].type)) === false) {
                Videoret = false;
              }
            }
            return Videoret;
          },
        },
      },
    });

    this.emailvalidator = new SimpleReactValidator({
      validators: {
        multipleEmail: {
          // name the rule
          message: "The :attribute must be a valid email address",
          rule: (val, params, validator) => {
            
            // var reg = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
            var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            var emails = val.split(",");
            let ine = [];
            for (let index = 0; index < emails.length; index++) {
              let e = emails[index];
              e = e.replace(/^\s+/g, "");
              if (validator.helpers.testRegex(e, reg) === true) {
              } else {
                ine.push(e);
              }
            }

            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true, // optional
        },
      },
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.sharevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleLinks: {
          // name the rule
          message: "The :attribute must be a valid links",
          rule: (val, params, validator) => {
            var reg = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, "");
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        multipleYoutubeLinks: {
          // name the rule
          message: "The :attribute must be a valid youtube links",
          rule: (val, params, validator) => {
            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, "");
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        PostFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, Invalid file types are " +
            process.env.REACT_APP_POST_FILES.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let retfiles = true;
            let arfiles = process.env.REACT_APP_POST_FILES.split(",");
            for (let i = 0; i < files.length; i++) {
              if (arfiles.includes(files[i].name.split(".").pop()) !== false) {
                retfiles = false;
              }
            }
            return retfiles;
          },
        },
        Photos: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_PHOTOS.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_PHOTOS.split(",");
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              }
            }
            return ret;
          },
        },
        VideoFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_VIDEOS.split(","),
          rule: (val, params, validator) => {
            let Videofiles = val.files ?? [];
            let Videoret = true;
            let ar = process.env.REACT_APP_POST_VIDEOS.split(",");
            for (let i = 0; i < Videofiles.length; i++) {
              if (ar.includes(mime.extension(Videofiles[i].type)) === false) {
                Videoret = false;
              }
            }
            return Videoret;
          },
        },
      },
    });

    this.editPostValidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleLinks: {
          // name the rule
          message: "The :attribute must be a valid links",
          rule: (val, params, validator) => {
            var reg = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, "");
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        multipleYoutubeLinks: {
          // name the rule
          message: "The :attribute must be a valid youtube links",
          rule: (val, params, validator) => {
            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, "");
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        PostFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, Invalid file types are " +
            process.env.REACT_APP_POST_FILES.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let retfiles = true;
            let arfiles = process.env.REACT_APP_POST_FILES.split(",");
            for (let i = 0; i < files.length; i++) {
              if (arfiles.includes(files[i].name.split(".").pop()) !== false) {
                retfiles = false;
              }
            }
            return retfiles;
          },
        },
        Photos: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are jpeg, jpg, png, gif",
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let ret = true;
            let ar = ["jpeg", "jpg", "png", "gif"];
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              }
            }
            return ret;
          },
        },
        VideoFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_VIDEOS.split(","),
          rule: (val, params, validator) => {
            let Videofiles = val.files ?? [];
            let Videoret = true;
            let ar = process.env.REACT_APP_POST_VIDEOS.split(",");
            for (let i = 0; i < Videofiles.length; i++) {
              if (ar.includes(mime.extension(Videofiles[i].type)) === false) {
                Videoret = false;
              }
            }
            return Videoret;
          },
        },
      },
    });

    this.MsgValidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    // ref code snippet
    this.podocu = React.createRef();
    this.poimage = React.createRef();
    this.pprest = React.createRef();
    this.video = React.createRef();
    this.polink = React.createRef();
    this.povideo = React.createRef();
    this.mediaForm = React.createRef();
    this.contentForm = React.createRef();
    this.shareForm = React.createRef();
    this.shareContentForm = React.createRef();

    this.shareEmailForm = React.createRef();

    this.editPostDetails = React.createRef();
    this.editPostMedia = React.createRef();

    this.comment = React.createRef();
    this.commentFile = React.createRef();
    this.commentReplayFile = React.createRef();
    this.commentForm = React.createRef();
    this.commentReplayForm = React.createRef();
    // edit post
    this.postImg = React.createRef();
    this.editMediaForm = React.createRef();
    this.editContentForm = React.createRef();

    this.editPost_podocu = React.createRef();
    this.editPost_poimage = React.createRef();
    this.editPost_video = React.createRef();

    this.editPostImage = React.createRef();

    // Share Model
    this.share_docu = React.createRef();
    this.share_image = React.createRef();
    this.share_ppt = React.createRef();
    this.share_video = React.createRef();
    this.share_povideo = React.createRef();
    this.share_polink = React.createRef();

    this.state = {
      createPostModelOpen: false,
      likeModal: false,
      shareModal: false,
      profileListing: {},
      profileImage: "",
      userName: "",
      userTitle: "",

      showMenuListing: "",
      deleteModal: false,
      deletePostId: "",
      postData: "",
      showMenuId: "",
      showFriendMenu: "",
      showFriendPostId: "",
      loggedUserId: "",
      blockModal: false,
      blockUserId: "",
      blockListData: "",
      reportModal: false,
      reportModule: "post",
      message: "",
      messageModal: false,
      messageSendTo: "",

      commentContainer: "",
      replyContainer: "",
      replyComment: "",
      connections: [],
      individualUser: [],
      selectedIndividualUser: [],
      posts: [],
      images: [],
      likes: [],
      comments: [],
      getLikes: [],
      getComments: [],
      commentCid: [],
      isIndivisual: false,
      isShowMedia: "",
      isComment: "",
      isCommentReply: "",
      isShowCheckbox: "true",
      likestatus: "like",
      replyCounts: "",

      isIndivisualvalue: "",
      popost: "",
      potitle: "",
      postId: "",
      postTit: "",
      postCon: "",
      postImg: "",
      postImage: "",
      links: "",
      youtubeLinks: "",

      photoIndex: 0,
      isOpen: false,
      isOpenValidation: false,
      lightBoxImages: [],

      // send mail
      sendEmail: false,
      senderEmail: "",
      sendEmailPostId: "",
      sendEmailTitle: "",
      sendEmailDesc: "",
      supplierRecipient: "",

      attachments: [],
      attachmentsList: [],
      ischecked: true,

      editModal: false,
      editPosts: [],

      poimage: "",
      podocu: "",
      video: "",
      polink: "",
      povideo: "",

      createPost: {
        poshareWith: "friends",
        potitle: "",
        popost: "",
        indmail: "",
        pohideId: 0,
      },

      isSearchResult: false,
      searchKeyword: "",
      perPage: 12,
      page: 0,
      loading: false,
      nextAvailable: true,
      flag: null,
      sharePost: null,
      isSharePostEdited: null,

      editPost: {},

      filterPost: this.props.location?.state ? this.props.location?.state : "",
      isLoading: false,
      isLoaded: false,
      isButtonLoding: false,
      isCommentButtonLoding: false,
      isCommentReplayButtonLoding: false,

      sendMessageId: 0,
      ReportPostId: 0,

      postTime: 0,

      groups: [],

      isReadMore:false,
      readMorepost:'',
    };
  }

  // set checkbox condition and set all the previous data including hideid by setState
  setHideId = async (e) => {
    const data = this.state.createPost;
    if (e.target.checked) {
      data.pohideId = 1;
      const hideid = data.pohideId;
      data.pohideId = hideid;

      await this.setState({
        createPost: data,
      });
    } else {
      data.pohideId = 0;
      const hideid = data.pohideId;
      data.pohideId = hideid;
      await this.setState({
        createPost: data,
      });
    }
  };

  // set condition for commentbox open
  showCommentBox = async (id, oid) => {
    const commentContainer = this.state.commentContainer === id ? "" : id;
    await this.setState({
      commentContainer: commentContainer,
      isShowCommentSelectedFile: false,
      CommentFileName: "",
      isShowCommentReplySelectedFile: false,
      CommentReplyFileName: "",
      isComment: "",
      isCommentReply: "",
    });

    await this.getCommentApi(id);

    this.commentForm?.current?.reset();
    this.commentReplayForm?.current?.reset();
  };

  showReplyBox = async (e, id, oid, commentid) => {
    const replyComment = this.state.replyComment === commentid ? "" : commentid;

    const replyContainer = this.state.replyContainer === id ? replyComment : "";

    await this.setState({
      replyContainer: replyContainer,
      replyComment: replyComment,
      isShowCommentSelectedFile: false,
      CommentFileName: "",
      isShowCommentReplySelectedFile: false,
      CommentReplyFileName: "",
      isComment: "",
      isCommentReply: "",
    });

    if (e.type !== "click") {
      this.commentApi(id, oid, commentid);
    }

    this.commentForm.current.reset();
    this.commentReplayForm.current.reset();
  };

  // set ternery condition on the create post modal for showing the modal
  showModal = async () => {
    const createPostModelOpen =
      this.state.createPostModelOpen === false ? true : false;

    this.contentForm.current.reset();
    this.mediaForm.current.reset();
    await this.setState({
      isShowMedia: "",
      createPostModelOpen: createPostModelOpen,
      potitle: "",
      popost: "",
    });
  };

  showShareModal = async (pid, posTit, posCon, pphot, sharePost) => {
    const shareModal = this.state.shareModal === false ? true : false;

    sharePost["old_docu"] = Array.isArray(sharePost?.docu)
      ? sharePost?.docu
          .map((doc) => {
            return doc.split("/").pop();
          })
          .join(",")
      : "";

    sharePost["old_image"] = Array.isArray(sharePost?.image)
      ? sharePost?.image
          .map((img, key) => {
            return img.split("/").pop();
          })
          .join(",")
      : "";

    sharePost["old_video"] = Array.isArray(sharePost?.allvid)
      ? sharePost?.allvid
          .map((vid) => {
            return vid.split("/").pop();
          })
          .join(",")
      : "";

    await this.setState({
      isShowMedia: "",
      shareModal: shareModal,
      postId: pid,
      potitle: posTit,
      popost: posCon,
      postImg: pphot,
      sharepostHideID: 0,
      sharePost: sharePost,
      share_polink: sharePost?.link,
      share_povideo: sharePost?.video,
      isSharePostEdited: false,
    });
  };

  hideShareModal = async () => {
    await this.setState({
      shareModal: false,
      isIndivisual: false,
      createPost: {
        poshareWith: "friends",
        potitle: "",
        popost: "",
        indmail: "",
        pohideId: 0,
      },
      isSharePostEdited: false,
    });
  };

  // make a function for close the modal on clicking the cross button
  hideModal = async () => {
    let hidecheckbox = document.querySelector("#html");

    if (hidecheckbox !== null) {
      hidecheckbox.checked = false;
    }

    await this.setState({
      createPostModelOpen: false,
      shareModal: false,
      isOpenValidation: false,
      isIndivisual: false,
      isSharePostEdited: false,
      createPost: {
        poshareWith: "friends",
        potitle: "",
        popost: "",
        indmail: "",
        pohideId: 0,
      },
    });

    this.CreatePostvalidator.hideMessages();
  };

  // function for dynamic select box value
  handleChange = async (e) => {
    const data = this.state.createPost;
    data.poshareWith = e.target.value?.replace(/^\s+/g, "");
    let isIndivisual = this.state.isIndivisual;

    let isShowCheckbox = this.state.isShowCheckbox;

    if (e.target.value?.replace(/^\s+/g, "") === "friends") {
      isShowCheckbox = true;
    } else {
      isShowCheckbox = false;
    }

    if (e.target.value?.replace(/^\s+/g, "") === "eid:") {
      isIndivisual = true;
    } else {
      isIndivisual = false;
    }

    await this.setState({
      createPost: data,
      isIndivisual: isIndivisual,
      isShowCheckbox: isShowCheckbox,
    });
  };

  handleInputChange = async (e) => {
    if (typeof e === "string") {
      this.setState({
        popost: e,
      });
    } else {
      // var val = e.target.value?.replace(/^\s+/g, '');
      // val = val.replace(/<[^>]+>/g, '');

      this.setState({
        [e.target.name]: e.target.value?.replace(/^\s+/g, ""),
      });
    }
  };

  handlecheckboxChange = async (e, file) => {
    // this.setState({
    //     ischecked:false,
    // })
    // const attachLists = this.state.attachmentsList;
    // if (e.target.checked) {
    //     attachLists.push(file)
    // } else {
    //   if (attachLists.indexOf(file) !== -1) {
    //     delete attachLists[attachLists.indexOf(file)]
    //   }
    // }
    // let files = attachLists.filter(function (el) {
    //   return el != null;
    // });
    // await this.setState({
    //     attachments: files
    // })
  };

  handleSubmit = async (e, pid, oid, commentid) => {
    e.preventDefault();

    await this.commentApi(pid, oid, commentid);
    await this.setState({
      isComment: "",
      isCommentReply: "",
      isShowCommentSelectedFile: false,
      CommentFileName: "",
      isShowCommentReplySelectedFile: false,
      CommentReplyFileName: "",
      isCommentButtonLoding: false,
      isCommentReplayButtonLoding: false,
    });
    this.commentForm.current.reset();
    await this.postApi();
  };

  individualApi = async () => {
    try {
      const individual = await axios.get(
        `${process.env.REACT_APP_API_URL}individual`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (individual.status === 200 && individual.data.status === true) {
        this.setState({
          individualUser: individual.data.datas,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != "") {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  componentDidMount = async () => {

    try {
      const conn = await axios.get(
        `${process.env.REACT_APP_API_URL}connections`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (conn.status === 200 && conn.data.status === true) {
        this.setState({
          connections: conn.data.datas,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        this.props.history.push("/");
      } else if (err?.response?.data?.error != "") {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    const userData = localStorage.getItem("userData");

    const userDetails = JSON.parse(userData);

    this.setState({
      userName: userDetails.userName,
      userTitle: userDetails.profileTitle,
    });

    await this.individualApi();

    const queries = queryString.parse(this.props.location.search);
    if ("key" in queries && queries.key !== null && queries.key !== "") {
      await this.setState({
        isSearchResult: true,
        searchKeyword: queries.key,
        page: 0,
        nextAvailable: true,
      });

      await this.getSearchData();
    } else {
      await this.postApi();
    }

    await this.ProfileApi();
    await this.groupListing();

    window.addEventListener("scroll", this.listenToScroll);
  };

  listenToScroll = async () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (this.state.isSearchResult === true) {
        await this.getSearchData();
      } else {
        await this.postApi();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.location.time !== this.props.location.time) {
      const queries = queryString.parse(this.props.location.search);

      if ("key" in queries && queries.key !== null && queries.key !== "") {
        await this.setState({
          isSearchResult: true,
          searchKeyword: queries.key,
          page: 0,
          nextAvailable: true,
          flag: queries.flag ? queries.flag : null,
          loading: true,
          posts: [],
        });

        await this.getSearchData();
      }
    }
  };
  // get all post api
  postApi = async () => {
    if (this.state.nextAvailable && this.state.loading === false) {
      await this.setState({
        loading: true,
        page: this.state.page + 1,
      });

      try {
        const userData = localStorage.getItem("userData");
        const userDetails = JSON.parse(userData);
        const loggedUserId = userDetails.userId;
        
        var url = `${process.env.REACT_APP_API_URL}profile/getallposts?page=${
          this.state.page
        }&records=${this.state.perPage}`;
        
        if (this.state.filterPost !== "") {          
          
          url = `${process.env.REACT_APP_API_URL}profile/getallposts?page=${
            this.state.page
          }&records=${this.state.perPage}&inid=${this.state.filterPost}`;
          
        }
        var post = await axios.get(url, {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        });

        if (post.status === 200) {
          if (post.data.status === true) {
            await this.setState({
              posts: this.state.posts.concat(post.data.datas),
              loggedUserId: loggedUserId,
            });

            if (post.data.datas.length < this.state.perPage) {
              await this.setState({
                nextAvailable: false,
              });
            }
          } else {
            await this.setState({
              nextAvailable: true,
            });
          }
        } else {
          if (post.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          this.props.history.push("/");
        } else if (err?.response?.data?.error != "") {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      await this.setState({
        loading: false,
        isLoaded: true,
      });
    }
  };

  getSearchData = async () => {
    if (this.state.nextAvailable) {
      await this.setState({
        loading: true,
        // isLoaded:false,
        page: this.state.page + 1,
      });

      try {
        var post = await axios.post(
          `${process.env.REACT_APP_API_URL}search-web`,
          {
            key: this.state.searchKeyword,
            page: this.state.page,
            records: this.state.perPage,
            type: 7,
            pads: "",
            psponsor: "",
            flag: this.state.flag ? this.state.flag : undefined,
          },
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (post.status === 200 && post.data.status === true) {
          await this.setState({
            posts: this.state.posts.concat(post.data.datas),
          });
        }

        if (post.data.datas.length < this.state.perPage) {
          await this.setState({
            nextAvailable: false,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          this.props.history.push("/");
        } else if (err?.response?.data?.error != "") {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      await this.setState({
        loading: false,
        isLoaded: true,
      });
    }
  };

  // post like
  likeApi = async (status, pid, oid) => {
    if (this.state.isLoading === false) {
      await this.setState({
        isLoading: true,
      });
      const likes = {
        oid: oid,
        pid: pid, //post id
        type: status,
      };

      try {
        const like = await axios.post(
          `${process.env.REACT_APP_API_URL}post/like`,
          likes,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (like.status === 200 && like.data.status === true) {
          this.setState({
            likes: like.data.datas,
          });

          const data = [];
          await this.state.posts.map((p) => {
            if (p.id === pid) {
              if (status === "like") {
                p.likeCount = p.likeCount + 1;
                p.likeStatus = 1;
              } else {
                p.likeCount = p.likeCount - 1;
                p.likeStatus = 0;
              }
            }

            data.push(p);
          });

          await this.setState({
            posts: data,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          this.props.history.push("/");
        } else if (err?.response?.data?.error != "") {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      await this.setState({
        isLoading: false,
      });
    }
  };

  // get like api
  getLikeApi = async (pid) => {
    try {
      const getLike = await axios.get(
        `${process.env.REACT_APP_API_URL}post/like/${pid}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (getLike.status === 200) {
        await this.setState({
          getLikes: getLike.data.datas ? getLike.data.datas : [],
        });
      }

      const likeModal = this.state.likeModal === false ? true : false;

      await this.setState({
        likeModal: likeModal,
      });
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        this.props.history.push("/");
      } else if (err?.response?.data?.error != "") {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  // post comment api
  commentApi = async (pid, oid, commentid) => {
    const comments = {
      type: commentid ? "reply" : "add",
      comntid: commentid ? commentid : undefined,
      comment: commentid ? this.state.isCommentReply : this.state.isComment,
      pid: pid,
      oid: oid,
    };

    if (commentid) {
      var dataForm = new FormData(this.commentReplayForm.current);
    } else {
      var dataForm = new FormData(this.commentForm.current);
    }

    dataForm.append("type", commentid ? "reply" : "add");
    dataForm.append("comntid", commentid ? parseInt(commentid) : 0);
    dataForm.append(
      "comment",
      commentid ? this.state.isCommentReply : this.state.isComment
    );
    dataForm.append("pid", pid);
    dataForm.append("oid", oid);

    try {
      const comment = await axios.post(
        `${process.env.REACT_APP_API_URL}post/comments`,
        dataForm,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (comment.status === 200 && comment.data.status === true) {
        this.setState({
          comments: comment.data.datas,
        });
        await this.getCommentApi(pid);

        const data = [];
        await this.state.posts.map((p) => {
          if (p.id === pid && !commentid) {
            p.commentCount = p.commentCount + 1;
          }

          data.push(p);
        });

        await this.setState({
          posts: data,
        });
        // call get comment api here because when we add the comment it will refresh that box and display that new comment
      }

      this.commentForm.current.reset();
      this.commentReplayForm.current.reset();
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        this.props.history.push("/");
      } else if (err?.response?.data?.error != "") {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  // get comment api
  getCommentApi = async (pid) => {
    try {
      const getComment = await axios.get(
        `${process.env.REACT_APP_API_URL}post/comments-web/${pid}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (getComment.status === 200) {
        this.setState({
          getComments: getComment.data.datas,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        this.props.history.push("/");
      } else if (err?.response?.data?.error != "") {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  hideLikeModal = async () => {
    await this.setState({
      likeModal: false,
    });
  };

  selectpodocu = async () => {
    await this.setState({
      isShowMedia: "podocu",
    });
    // this.podocu.current.click();
  };

  selectpoimage = async () => {
    await this.setState({
      isShowMedia: "poimage",
    });
    // this.poimage.current.click();
  };

  selectVideo = async () => {
    await this.setState({
      isShowMedia: "video",
    });
    // this.video.current.click();
  };

  selectLink = async () => {
    await this.setState({
      isShowMedia: "polink",
    });
    this.polink.current.focus();
  };

  selectYoutube = async () => {
    await this.setState({
      isShowMedia: "povideo",
    });
    this.povideo.current.focus();
  };

  // create post api
  submitPost = async () => {
    if (this.CreatePostvalidator.allValid()) {
      this.setState({
        createPostModelOpen: false,
        isOpenValidation: false,
        isButtonLoding: true,
        isLoaded: false,
      });

      const formData = new FormData(await this.mediaForm.current);
      const content = new FormData(await this.contentForm.current);

      for (var pair of content.entries()) {
        formData.append(pair[0], pair[1]);
      }

      formData.append("popost", this.state.popost);
      formData.append("poshareWith", this.state.createPost["poshareWith"]);
      formData.append(
        "indmail",
        this.state.selectedIndividualUser.map((u) => u.value).join(",")
      );
      formData.append("pohideId", this.state.createPost.pohideId);

      try {
        const postRes = await axios.post(
          `${process.env.REACT_APP_API_URL}post/`,
          formData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (postRes.status === 200 && postRes.data.status === true) {
          toast.success(postRes.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          this.setState({
            links: this.state.polink,
            youtubeLinks: this.state.povideo,
          });
        } else {
          toast.error(
            postRes.data.errors ? postRes.data.errors : postRes.data.message,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          this.props.history.push("/");
        } else if (err?.response?.data?.error != "") {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      this.setState({
        potitle: "",
        popost: "",
        poimage: "",
        podocu: "",
        video: "",
        polink: "",
        povideo: "",
        isButtonLoding: false,
      });

      this.hideModal();

      this.mediaForm.current.reset();
      this.contentForm.current.reset();

      this.CreatePostvalidator.hideMessages();
      this.forceUpdate();

      await this.setState({
        nextAvailable: true,
        page: 0,
        posts: [],
        loading: false,
      });

      this.postApi();
    } else {
      this.setState({
        isOpenValidation: true,
      });

      this.CreatePostvalidator.showMessages();
      // this.validator.showMessages();
      this.forceUpdate();
    }
  };

  // hit share api
  submitShare = async () => {
    if (this.sharevalidator.allValid()) {
      const isSharedPostEdited = this.state.isSharePostEdited;

      await this.setState({
        shareModal: false,
        isOpenValidation: false,
        isSharePostEdited: false,
        isLoaded: false,
        loading: true,
      });

      const shareFormData = new FormData(await this.shareContentForm.current);
      const shareContent = new FormData(await this.shareForm.current);

      for (var shared of shareContent.entries()) {
        shareFormData.append(shared[0], shared[1]);
      }

      shareFormData.append("edited", isSharedPostEdited ? 1 : 0);

      shareFormData.append("potitle", this.state.potitle);

      shareFormData.append("popost", this.state.popost);
      shareFormData.append("poshareWith", this.state.createPost["poshareWith"]);
      shareFormData.append(
        "indmail",
        this.state.selectedIndividualUser.map((u) => u.value).join(",")
      );
      shareFormData.append(
        "pohideId",
        this.state.sharepostHideID === true ? 1 : 0
      );

      try {
        const shareRes = await axios.post(
          `${process.env.REACT_APP_API_URL}post/share/${this.state.postId}`,
          shareFormData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (shareRes.status === 200 && shareRes.data.status === true) {
          toast.success(shareRes.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          this.sharevalidator.hideMessages();
          this.forceUpdate();
        } else {
          toast.error(
            shareRes.data.errors ? shareRes.data.errors : shareRes.data.message,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          this.props.history.push("/");
        } else if (err?.response?.data?.error != "") {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      await this.setState({
        nextAvailable: true,
        page: 0,
        posts: [],
        loading: false,
        selectedIndividualUser: [],
        createPost: {
          poshareWith: "friends",
          potitle: "",
          popost: "",
          indmail: "",
          pohideId: 0,
        },
      });

      this.postApi();

      this.hideShareModal();
      this.sharevalidator.hideMessages();
      this.shareContentForm.current.reset();
      this.shareForm.current.reset();
    } else {
      this.setState({
        isOpenValidation: true,
      });

      this.sharevalidator.showMessages();
      this.forceUpdate();
    }
  };

  selectpfle = async () => {
    await this.setState({
      isShowMedia: "pfle",
    });
  };

  selectpphot = async () => {
    await this.setState({
      isShowMedia: "pphot",
    });
  };

  // truncate text start
  executeOnClick(isExpanded) {}
  // truncate text end

  // menulisting
  showMenu = (id, postUid) => {
    const showMenuListing = this.state.showMenuListing === id ? "" : id;

    this.setState({
      showMenuListing: showMenuListing,
      showMenuId: id,

      showFriendPostId: postUid,
    });
  };

  deleteModal = async (pid) => {
    const deleteModal = this.state.deleteModal === false ? true : false;

    await this.setState({
      showFriendPostId: "",
      showMenuListing: "",
      deleteModal: deleteModal,
      deletePostId: pid,
    });
  };

  hideDeleteModal = async () => {
    await this.setState({
      deleteModal: false,
    });
  };

  deletePost = async (pid) => {
    this.setState({
      isLoaded: false,
    });
    try {
      const deleteData = await axios.delete(
        `${process.env.REACT_APP_API_URL}post/${pid}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (deleteData.status === 200 && deleteData.data.status === true) {
        await this.handlePost(this.state.posts);
        toast.success(deleteData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(deleteData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        this.props.history.push("/");
      } else if (err?.response?.data?.error != "") {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    this.hideDeleteModal();

    await this.setState({
      nextAvailable: true,
      page: 0,
      posts: [],
    });

    if (this.state.isSearchResult === true) {
      await this.getSearchData();
    } else {
      await this.postApi();
    }
  };

  handlePost = async (allData) => {
    await this.setState({
      postData: allData,
      posts: [],
    });
  };

  // block modal
  blockModal = async (uid) => {
    const blockModal = this.state.blockModal === false ? true : false;

    await this.setState({
      showFriendPostId: "",
      showMenuListing: "",
      blockModal: blockModal,
      blockUserId: uid,
    });
  };

  hideBlockModal = async () => {
    await this.setState({
      blockModal: false,
    });
  };

  blockUser = async (blockType) => {
    this.setState({
      isLoaded: false,
    });

    const data = {
      fid: this.state.blockUserId,
      type: blockType,
    };

    try {
      const blockData = await axios.post(
        `${process.env.REACT_APP_API_URL}block`,
        data,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (blockData.status === 200 && blockData.data.status === true) {
        await this.handleBlockData(this.state.posts);
        toast.success(blockData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        await this.handleBlockData(this.state.posts);
        toast.error(blockData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        this.props.history.push("/");
      } else if (err?.response?.data?.error != "") {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    this.hideBlockModal();
  };

  handleBlockData = async (datas) => {
    await this.setState({
      blockListData: datas,
      posts: [],
    });
  };

  // report module
  reportModal = async (post, uid) => {
    const reportModal = this.state.reportModal === false ? true : false;
    await this.setState({
      showFriendPostId: "",
      showMenuListing: "",
      reportModal: reportModal,
      ReportPostId: uid,
    });
  };

  hideReportModal = async () => {
    await this.setState({
      reportModal: false,
    });
  };

  // message modal
  showMessageModal = async (name, uid) => {
    const messageModal = this.state.messageModal === false ? true : false;
    await this.setState({
      showFriendPostId: "",
      showMenuListing: "",
      messageModal: messageModal,
      messageSendTo: name,
      sendMessageId: uid,
    });
  };

  hideMessageModal = async () => {
    await this.setState({
      messageModal: false,
    });
    this.MsgValidator.hideMessages();
  };

  sendMessage = async () => {
    if (this.MsgValidator.allValid()) {
      const userData = localStorage.getItem("userData");
      const userDetails = JSON.parse(userData);
      const msgData = {
        chatterIds: this.state.sendMessageId,
        toIds: userDetails.userId,
        chatterMessage: this.state.message,
      };

      try {
        const message = await axios.post(
          `${process.env.REACT_APP_API_URL}message/send-web`,
          msgData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );
        if (message.status === 200 && message.data.status === true) {
          toast.success(message.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(message.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          this.props.history.push("/");
        } else if (err?.response?.data?.error != "") {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      this.setState({
        message: "",
      });
      this.hideMessageModal();
    } else {
      this.MsgValidator.showMessages();
      this.forceUpdate();
    }
  };

  openLightBox(postIndex) {
    const images = this.state.posts[postIndex].image;
    this.setState({ lightBoxImages: images, isOpen: true });
  }

  // send mail
  showSendMailModal = async (id, title, descrip, post, type) => {
    
    let attachmentsList = this.state.attachmentsList;
    let mailHTMLContent = `<p>${descrip}</p>`;

    if(type === 'post'){
      if (post.allvid.length > 0) {
        // mailHTMLContent += `
        // <p>Videos:</p>
        // <p>${post.allvid}</p>`

        post.allvid.map((allvid) => {
          attachmentsList.push(allvid);
        });
      }

      if (post.docu.length > 0) {
        // mailHTMLContent += `
        // <p>Documents:</p>
        // <p>${post.docu}</p>`

        post.docu.map((docu) => {
          attachmentsList.push(docu);
        });
      }

      if (post.image.length > 0) {
        // mailHTMLContent += `
        // <p>Images:</p>
        // <p>${post.image}</p>`

        post.image.map((image) => {
          attachmentsList.push(image);
        });
      }

      if (post.link) {
        mailHTMLContent += `
              <p>Links:</p>
              <p>${post.link}</p>`;

        // let links = post.link.split(",");

        // links.map( (link) => {
        //     attachmentsList.push(link)
        // })
      }

      if (post.video) {
        mailHTMLContent += `
              <p>Youtube Links:</p>
              <p>${post.video}</p>`;

        // let YTlinks = post.video.split(",");

        // YTlinks.map( (YTvideo) => {
        //     attachmentsList.push(YTvideo)
        // })
      }
    }
    
    const sendEmail = this.state.sendEmail === false ? true : false;

    const userData = localStorage.getItem("userData");

    const userDetails = JSON.parse(userData);

    await this.setState({
      showFriendPostId: "",
      showMenuListing: "",
      sendEmail: sendEmail,
      senderEmail: userDetails.userEmail,
      sendEmailPostId: id,
      sendEmailTitle: title,
      sendEmailDesc: mailHTMLContent,
    });
  };

  hideSendMailModal = async () => {
    await this.setState({
      sendEmail: false,
      attachmentsList: [],
    });

    this.emailvalidator.hideMessages();
  };

  sendEmailTo = async () => {
    if (this.emailvalidator.allValid()) {
      this.setState({
        isButtonLoding: true,
      });
      const userData = localStorage.getItem("userName");

      const formData = new FormData(await this.shareEmailForm.current);

      formData.append("uname", userData);
      formData.append("uemail", this.state.senderEmail);
      formData.append("subject", this.state.sendEmailTitle);
      formData.append("friendsEmail", this.state.supplierRecipient);
      formData.append("mailContent", this.state.sendEmailDesc);

      const allAttachlist = [];
      for (var value of formData.entries()) {
        if (value[0] === "attachments") {
          allAttachlist.push(value[1]);
        }
      }

      formData.append("mailAtachments", allAttachlist);

      // const supplierData = {
      //     uname: userData,
      //     uemail: this.state.senderEmail,
      //     subject: this.state.sendEmailTitle,
      //     friendsEmail: this.state.supplierRecipient,
      //     mailContent: this.state.sendEmailDesc,
      //     mailAtachments: this.state.ischecked ? this.state.attachmentsList : this.state.attachments,
      // };

      try {
        const formres = await axios.post(
          `${process.env.REACT_APP_API_URL}invitefriends`,
          formData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (formres.status === 200) {
          if (formres.data.status === true) {
            this.setState({
              attachmentsList: [],
              attachments: [],
            });

            toast.success(formres.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(formres.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          this.emailvalidator.hideMessages();
          this.forceUpdate();
        } else {
          toast.error(formres.data.errors, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          this.props.history.push("/");
        } else if (err?.response?.data?.error != "") {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      this.setState({
        supplierRecipient: "",
        isButtonLoding: false,
      });

      this.hideSendMailModal();
    } else {
      this.emailvalidator.showMessages();
      this.forceUpdate();
    }
  };

  ProfileApi = async () => {
    this.setState({
      profileImage: window.sessionStorage.getItem("profileImage"),
    });
  };

  // edit modal and api
  showEditModal = async (post) => {
    const editModal = this.state.editModal === false ? true : false;

    let selectedIndividualUser = [];
    let isIndivisual = false;
    if (
      post?.shareWithKey === "eid:" &&
      post?.shareWithValue === "Individuals"
    ) {
      let selUsers = post?.indmail?.map((u) => {
        return { value: u.email, label: u.name };
      });
      selectedIndividualUser = selUsers;
      isIndivisual = true;
    }

    await this.setState({
      showFriendPostId: "",
      showMenuListing: "",
      isShowMedia: "",
      editModal: editModal,
      editPost: post,
      isIndivisualvalue: post?.hide_id,
      isCheckedHideid: post?.hide_id === 0 ? false : true,
      selectedIndividualUser: selectedIndividualUser,
      isIndivisual: isIndivisual,
      createPost: {
        ...this.state.createPost,
        poshareWith: post?.shareWithKey,
      },
    });
  };

  hideEditModal = async () => {
    await this.setState({
      editModal: false,
      isOpenValidation: false,
      selectedIndividualUser: [],
      isIndivisual: false,
      createPost: {
        poshareWith: "friends",
        potitle: "",
        popost: "",
        indmail: "",
        pohideId: 0,
      },
    });
  };

  // create post api
  editPost = async () => {
    if (this.editPostValidator.allValid()) {
      this.setState({
        editModal: false,
        isOpenValidation: false,
        isLoaded: false,
        loading: true,
      });

      const formData = new FormData(this.editPostMedia.current);
      const content = new FormData(this.editPostDetails.current);

      for (var pair of content.entries()) {
        formData.append(pair[0], pair[1]);
      }

      formData.append("popost", this.state.editPost?.post);
      formData.append(
        "poshareWith",
        this.state.editPost?.shareWithKey === ""
          ? "friends"
          : this.state.editPost?.shareWithKey
      );
      formData.append(
        "indmail",
        this.state.selectedIndividualUser.map((u) => u.value).join(",")
      );
      formData.append("pohideId", this.state.isCheckedHideid === true ? 1 : 0);

      try {
        const postRes = await axios.post(
          `${process.env.REACT_APP_API_URL}post/update/${
            this.state.editPost?.id
          }`,
          formData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (postRes.status === 200) {
          this.editPostMedia.current.reset();
          this.editPostDetails.current.reset();

          if (postRes.data.status === true) {
            toast.success(postRes.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(
              postRes.data.errors ? postRes.data.errors : postRes.data.message,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        } else {
          toast.error(postRes.data.errors, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          this.props.history.push("/");
        } else if (err?.response?.data?.error != "") {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      this.editPostMedia.current.reset();
      this.editPostDetails.current.reset();

      this.hideEditModal();

      await this.setState({
        nextAvailable: true,
        page: 0,
        posts: [],
        loading: false,
      });

      if (this.state.isSearchResult === true) {
        await this.getSearchData();
      } else {
        await this.postApi();
      }
    } else {
      this.setState({
        isOpenValidation: true,
      });

      this.editPostValidator.showMessages();
      this.forceUpdate();
    }
  };

  handleAllPost = async (datas) => {
    this.setState({
      editPosts: datas,
      posts: [],
    });
  };

  filterPosts = async (viewPostTo) => {
    
    await this.setState({
      nextAvailable: true,
      page: 0,
      filterPost: viewPostTo,
      posts: [],
      isSearchResult: false,
      isLoaded: false,
    });

    if (this.state.isSearchResult === true) {
      await this.getSearchData();
    } else {
      await this.postApi();
    }
  };

  deletePostFile = async (uid = 0, pid = 0, folder = "", file = "", key) => {
    try {
      let fullpath = file;
      file = file.split("/").pop();
      const resDeletePostFile = await axios.delete(
        `${process.env.REACT_APP_API_URL}post/file/${pid}/${folder}/${btoa(
          file
        )}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
      if (
        resDeletePostFile.status === 200 &&
        resDeletePostFile.data.status === true
      ) {
        document.getElementById(fullpath).style.display = "none";
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location.href = process.env.PUBLIC_URL;
      } else if (
        err?.response?.data?.error != "" ||
        err?.response?.data?.message != ""
      ) {
        toast.error(
          err?.response?.data?.error === ""
            ? err?.response?.data?.message
            : err?.response?.data?.error,
          {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  handleIndividualUser = async (e) => {
    await this.setState({
      selectedIndividualUser: e,
    });
  };

  deleteDataPostFile = async (file = "", type = "") => {
    let fullpath = file;

    let name = "";
    if (type === "video") {
      name = "old_video";
    } else if (type === "document") {
      name = "old_docu";
    } else if (type === "image") {
      name = "old_image";
    }

    if (name !== "") {
      let val = this.state.sharePost[name];
      val = val.split(",");
      let newVal = [];
      for (let index = 0; index < val.length; index++) {
        const v = val[index];
        if (v !== fullpath.split("/").pop()) {
          newVal.push(v);
        }
      }

      this.setState({
        sharePost: {
          ...this.state.sharePost,
          [name]: newVal.join(","),
        },
      });
    }

    document.getElementById(fullpath).remove();
  };

  groupListing = async () => {
    try {
      const group = await axios.get(
        `${process.env.REACT_APP_API_URL}sharewith`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      const userData = localStorage.getItem("userData");
      const userName = localStorage.getItem("userName");

      const userDetails = JSON.parse(userData);

      if (group.status === 200 && group.data.status === true) {
        const userGroup = group.data.datas.filter((v) => {
          return v.uid === userDetails.userID;
        });
        this.setState({
          groups: userGroup,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != "") {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  ReadMore = (children) => {
    
    const text = children;

    this.setState({
      isReadMore:true,
    })
   
  };

  setShowMore = async (pid, index) => {

    const readMorepost = this.state.readMorepost === pid ? '' : pid;
    
    this.setState({
      readMorepost:readMorepost,
    })
   
  };

  render() {
    const { photoIndex, isOpen, lightBoxImages } = this.state;

    return (
      <>
        {this.state.reportModal ? (
          <Report
            hideReportModal={this.hideReportModal}
            softwareId={this.state.showMenuId}
            softwareUid={this.state.ReportPostId}
            module={this.state.reportModule}
          />
        ) : (
          ""
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />
        <section className="feed-section">
          <PostLeftSidebar
            filterPosts={this.filterPosts}
            viewPostTo={this.state.filterPost}
            individualUser={this.state.individualUser}
            groupListing={this.groupListing}
          />

          {this.state.isLoaded && (
            <div className="post-section">
              <div className="post-section-container">
                <div
                  className="new-post"
                  id="create-post"
                  onClick={this.showModal}
                >
                  <div className="post-field">
                    <div className="field-profile">
                      <img src={this.state.profileImage} alt="" />
                    </div>
                    <form className="form-field">
                      <input
                        readOnly
                        type="text"
                        className="field-input"
                        placeholder="What's on your mind ..."
                      />
                    </form>
                  </div>

                  <div className="post-pannel">
                    <div className="post-pannel-content">
                      <div className="pannel-icon">
                        <img src={files} alt="files" />
                      </div>
                      <div className="pannel-content">
                        <p className="text">files</p>
                      </div>
                    </div>

                    <div className="post-pannel-content">
                      <div className="pannel-icon">
                        <img
                          src={cameraIcon}
                          alt="cameraIcon"
                          className="img-big"
                        />
                      </div>
                      <div className="pannel-content">
                        <p className="text">photo</p>
                      </div>
                    </div>

                    <div className="post-pannel-content">
                      <div className="pannel-icon">
                        <img src={videoIcon} alt="videoIcon" />
                      </div>
                      <div className="pannel-content">
                        <p className="text">video</p>
                      </div>
                    </div>

                    <div className="post-pannel-content">
                      <div className="pannel-icon">
                        <img src={addLink} alt="addLink" />
                      </div>
                      <div className="pannel-content">
                        <p className="text">links</p>
                      </div>
                    </div>

                    <div className="post-pannel-content">
                      <div className="pannel-icon">
                        <img src={youtube} alt="youtube" className="img-big" />
                      </div>
                      <div className="pannel-content">
                        <p className="text">youtube</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`posting-container ${
                    this.state.loading ? "loader-loading" : ""
                  }`}
                >
                  {this.state.posts.length > 0 ? (
                    <>
                      {this.state.posts.map((post, postIndex) => {
                        
                        if (post.isPost) {

                          var postContent = post.post;
                          //red color
                          if(postContent.includes("*hrs*") && postContent.includes("*hre*") ) {
                            postContent = postContent.replace(/\*hrs\*/g, "<span style='color:red'>").replace(/\*hre\*/g, "</span>")
                          }

                          //yellow color
                          if(postContent.includes("*hys*") && postContent.includes("*hye*") ) {
                            postContent = postContent.replace(/\*hys\*/g, "<span style='color:yellow'>").replace(/\*hye\*/g, "</span>")
                          }

                          //blue color
                          if(postContent.includes("*hbs*") && postContent.includes("*hbe*") ) {
                            postContent = postContent.replace(/\*hbs\*/g, "<span style='color:blue'>").replace(/\*hbe\*/g, "</span>")
                          }
                          
                          //green color
                          if(postContent.includes("*hgs*") && postContent.includes("*hge*") ) {
                            postContent = postContent.replace(/\*hgs\*/g, "<span style='color:green'>").replace(/\*hge\*/g, "</span>")
                          }

                          //orange color
                          if(postContent.includes("*hos*") && postContent.includes("*hoe*") ) {
                            postContent = postContent.replace(/\*hos\*/g, "<span style='color:orange'>").replace(/\*hoe\*/g, "</span>")
                          }

                          //purple color
                          if(postContent.includes("*hps*") && postContent.includes("*hpe*") ) {
                            postContent = postContent.replace(/\*hps\*/g, "<span style='color:purple'>").replace(/\*hpe\*/g, "</span>")
                          }

                          //bold text
                          if(postContent.includes("*bs*") && postContent.includes("*be*") ) {
                            postContent = postContent.replace(/\*bs\*/g, "<b>").replace(/\*be\*/g, "</b>")
                          }

                          //bold text
                          if(postContent.includes("*bs*") && postContent.includes("*be*") ) {
                            postContent = postContent.replace(/\*bs\*/g, "<b>").replace(/\*be\*/g, "</b>")
                          }

                          //strike through text
                          if(postContent.includes("*ss*") && postContent.includes("*se*") ) {
                            postContent = postContent.replace(/\*ss\*/g, "<s>").replace(/\*se\*/g, "</s>")
                          }

                          //Underline text
                          if(postContent.includes("*us*") && postContent.includes("*ue*") ) {
                            postContent = postContent.replace(/\*us\*/g, "<u>").replace(/\*ue\*/g, "</u>")
                          }

                          //Italic text
                          if(postContent.includes("*is*") && postContent.includes("*ie*") ) {
                            postContent = postContent.replace(/\*is\*/g, "<i>").replace(/\*ie\*/g, "</i>")
                          }

                          if(postContent.includes('&amp;')) {
                            postContent = postContent.replaceAll('&amp;', '&')
                          }
                          if(postContent.includes('&lt;')) {
                            postContent = postContent.replaceAll('&lt;', '<')
                          }
                          if(postContent.includes('&gt;')) {
                            postContent = postContent.replaceAll('&gt;', '>')
                          }
                          if(postContent.includes('&amp;')) {
                            postContent = postContent.replaceAll('&quot;', '"')
                          }
                          if(postContent.includes('&amp;')) {
                            postContent = postContent.replaceAll('&apos;', "'")
                          }

                          const newpostContent = `${postContent}`

                          if (newpostContent.length > 150) {
                            var isReadMore = true;
                          } else {
                            var isReadMore = false;
                          }

                          return (
                            <div className="posting-content" key={postIndex}>
                              <div className="profile-content">
                                <div className="post-info">
                                  <div className="post-profile">
                                    <img
                                      src={
                                        post.hide_id === 0
                                          ? post.profileImage
                                          : "./images/no-image.png"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="post-content">
                                    <div className="post-header">
                                      <h2 className="header">
                                        {post.hide_id === 0
                                          ? post.name
                                          : "Unknown"}
                                      </h2>
                                    </div>
                                    <div className="posted">
                                      <h3 className="header">
                                        {post.spid === 0 &&
                                        post.suid === 0 &&
                                        post.edited === 0 ? "Posted" : post.edited !== 0 ? `Edited & Shared ${post.suname}'s Post` : post.suid !== 0 ? `Shared ${post.suname}'s Post` : post.spid !== 0 ? `Shared ${post.spname}` : ""
                                        }

                                        {//Added by PBK to Append the Group Name, if the content is posted to a Group
                                        post?.shareWithKey.includes("NGroup") === true
                                                  ? " to " + post?.shareWithValue 
                                                  : ""
                                        }
                                      </h3>                                    
                                    </div>
                                    <div className="posted posted-time">
                                      <h3 className="header">
                                      {
                                        `${moment.utc(post.checkTime).local().format('YYYY-MM-DD hh:mm A')}`
                                      }
                                      </h3>
                                    </div>
                                  </div>
                                </div>

                                <div className="menu-container">
                                  <div className="menu-dots">
                                    <img
                                      src={threeDots}
                                      alt="threeDots"
                                      onClick={() =>
                                        this.showMenu(post.id, post.uid)
                                      }
                                    />
                                  </div>

                                  {this.state.loggedUserId ===
                                  this.state.showFriendPostId ? (
                                    <div
                                      className={
                                        "user-menu-content" +
                                        (this.state.showMenuListing === post.id
                                          ? " open"
                                          : "")
                                      }
                                    >
                                      <h2
                                        className="header edit"
                                        onClick={() => this.showEditModal(post)}
                                      >
                                        Edit
                                      </h2>
                                      <h2
                                        className="header"
                                        onClick={() =>
                                          this.deleteModal(post.id)
                                        }
                                      >
                                        Delete
                                      </h2>
                                    </div>
                                  ) : (
                                    <div
                                      className={
                                        "user-menu-content others-menu-content" +
                                        (this.state.showMenuListing === post.id
                                          ? " open"
                                          : "")
                                      }
                                    >
                                      <h2
                                        className="header edit"
                                        onClick={() =>
                                          this.showMessageModal(
                                            post.name,
                                            post.uid
                                          )
                                        }
                                      >
                                        Message
                                      </h2>
                                      <h2
                                        className="header edit"
                                        onClick={() =>
                                          this.blockModal(post.uid)
                                        }
                                      >
                                        Block user
                                      </h2>
                                      <h2
                                        className="header"
                                        onClick={() =>
                                          this.reportModal("post", post.uid)
                                        }
                                      >
                                        Report Abuse
                                      </h2>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="profile-text">
                                <div className="profile-subtext">
                                  <h2 className="header bold-header">
                                    {post.title}
                                  </h2>
                                </div>
                                
                                <div className="profile-subtext">
                                  <h2 className="header truncate" style={{whiteSpace:'pre-line'}}>
                                   
                                    <p
                                      dangerouslySetInnerHTML={{
                                          __html: `${!this.state.isReadMore && this.state.readMorepost === post.id ? newpostContent : newpostContent.substring(0, 150)}`
                                      }}
                                    />

                                    <p>
                                     {isReadMore ? 
                                      <span onClick={() => this.setShowMore(post.id, postIndex)} className="read-or-hide">
                                        {!this.state.isReadMore && this.state.readMorepost === post.id ? " ...Read less" : "...Read more"}
                                      </span>
                                       : ''}
                                    </p>

                                  </h2>
                                </div>
                                {post.link && (
                                  <div className="profile-subtext">
                                    {post.link.split(",").map((link, key) => (
                                      <a
                                        key={key}
                                        href={
                                          /^((http|https|ftp):\/\/)/.test(link)
                                            ? link
                                            : `//${link}`
                                        }
                                        target="_blank"
                                        className="link-tag"
                                      >
                                        <h2 className="header link-header">
                                          {link}
                                        </h2>
                                      </a>
                                    ))}
                                  </div>
                                )}

                                {post.video &&
                                  post.video.split(",").map((vidLinks, key) => {
                                    if (vidLinks && vidLinks.indexOf(".") > 0) {
                                      return (
                                        <iframe
                                          key={key}
                                          src={vidLinks.replace(
                                            "watch?v=",
                                            "embed/"
                                          )}
                                          height="300"
                                          width="100%"
                                        />
                                      );
                                    }
                                  })}
                              </div>

                              <div className="feeds-container">
                                {Array.isArray(post.allvid)
                                  ? post.allvid.map((vid, key) => {
                                      if (vid.split(".").length > 0) {
                                        return (
                                          <video
                                            key={key}
                                            width="100%"
                                            height="300"
                                            controls
                                          >
                                            <source
                                              src={vid}
                                              type="video/mp4"
                                            />
                                            Your browser does not support the
                                            video tag.
                                          </video>
                                        );

                                        // <iframe key={key} src={vid} height="300" width="100%"></iframe>
                                      }
                                    })
                                  : ""}
                              </div>

                              <div className="feeds-container">
                                {Array.isArray(post.image) ? (
                                  <div
                                    className={`feeds-content ${
                                      post.image.length > 1
                                        ? ""
                                        : "single-feed-img"
                                    }`}
                                  >
                                    {post.image.map((images, key) => {
                                      return (
                                        <img
                                          key={key}
                                          src={images}
                                          alt=""
                                          onClick={() => {
                                            this.openLightBox(postIndex);
                                          }}
                                        />
                                      );
                                    })}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div
                                className="feeds-container"
                                style={{
                                  display: "inline-flex",
                                }}
                              >
                                {Array.isArray(post.ppt)
                                  ? post.ppt.map((docu, key) => {
                                      return (
                                        <div
                                          key={key}
                                          className="feeds-content"
                                        >
                                          <a
                                            href={docu}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              //.split is added by PBK to show the original file name while downloading the file
                                              saveAs(docu, docu.split("/").pop());
                                            }}
                                          >
                                            <img
                                              src="./images/ppt.jpg"
                                              className="presentation_image"
                                            />
                                          </a>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </div>

                              <div
                                className="feeds-container"
                                style={{
                                  display: "inline-flex",
                                }}
                              >
                                {Array.isArray(post.docu)
                                  ? post.docu.map((docu, key) => {
                                      return (
                                        <div
                                          key={key}
                                          className="feeds-content"
                                        >
                                          <a
                                            href={docu}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              //.split is added by PBK to show the original file name while downloading the file
                                              saveAs(docu,docu.split("/").pop());
                                            }}
                                          >
                                            <img
                                              src="./images/docu.png"
                                              className="document_image"
                                            />
                                          </a>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </div>

                              <div className="like-pannel">
                                <div className="like-pannel-container">
                                  <div className="like-pannel-content like-contents">
                                    <div
                                      className="like-icon"
                                      onClick={() =>
                                        post.hide_id === 0
                                          ? this.likeApi(
                                              post.likeStatus === 0
                                                ? "like"
                                                : "unlike",
                                              post.id,
                                              post.uid
                                            )
                                          : ""
                                      }
                                    >
                                      {post.likeStatus === 0 ? (
                                        <i
                                          className={`far fa-thumbs-up ${
                                            post.hide_id === 0
                                              ? ""
                                              : "cross-icon-header"
                                          }`}
                                        />
                                      ) : (
                                        <i
                                          className={`fa fa-thumbs-up ${
                                            post.hide_id === 0
                                              ? ""
                                              : "cross-icon-header"
                                          }`}
                                        />
                                      )}
                                      <div className="icon-content">
                                        <h2
                                          className={`header ${
                                            post.hide_id === 0
                                              ? ""
                                              : "cross-icon-header"
                                          }`}
                                        >
                                          Like
                                        </h2>
                                      </div>
                                    </div>

                                    <div className="icon-content like-number">
                                      <h2
                                        className={`header ${
                                          post.hide_id === 0
                                            ? ""
                                            : "cross-icon-header"
                                        }`}
                                        onClick={() =>
                                          post.hide_id === 0
                                            ? this.getLikeApi(post.id)
                                            : ""
                                        }
                                      >
                                        ({post.likeCount})
                                      </h2>
                                    </div>
                                  </div>

                                  <div className="like-pannel-content comment-pannel-content">
                                    <div
                                      className="like-icon"
                                      onClick={() =>
                                        this.showCommentBox(post.id, post.uid)
                                      }
                                    >
                                      <img src={comment} alt="comment" />

                                      <div className="icon-content">
                                        <h2 className="header">Comment(s)</h2>
                                      </div>

                                      <div className="icon-content like-number">
                                        <h2
                                          className="header"
                                          onClick={() =>
                                            this.getCommentApi(post.id)
                                          }
                                        >
                                          ({post.commentCount})
                                        </h2>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="like-pannel-content"
                                    onClick={() =>
                                      post.hide_id === 0
                                        ? this.showShareModal(
                                            post.id,
                                            post.title,
                                            post.post,
                                            post.image,
                                            post
                                          )
                                        : ""
                                    }
                                  >
                                    <div className="like-icon">
                                      <img
                                        className={`${
                                          post.hide_id === 0
                                            ? ""
                                            : "cross-icon-header"
                                        }`}
                                        src={shareIcon}
                                        alt="shareIcon"
                                      />
                                    </div>
                                    <div className="icon-content">
                                      <h2
                                        className={`header ${
                                          post.hide_id === 0
                                            ? ""
                                            : "cross-icon-header"
                                        }`}
                                      >
                                        Share
                                      </h2>
                                    </div>
                                  </div>

                                  <div
                                    className="email-icon-button"
                                    onClick={() =>
                                      this.showSendMailModal(
                                        post.id,
                                        post.title,
                                        post.post,
                                        post,
                                        'post'
                                      )
                                    }
                                  >
                                    <button className="email-icon">
                                      <img src={sendMail} alt="sendMail" />
                                    </button>
                                    <div className="email-text">
                                      <h3 className="header">Email</h3>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className={
                                  "comment-container" +
                                  (this.state.commentContainer === post.id
                                    ? " open"
                                    : "")
                                }
                              >
                                <div className="comments-section">
                                  <div className="comments-text">
                                    <h2 className="header">Comment(s)</h2>
                                  </div>
                                  {this.state.getComments.map(
                                    (getComment, index) => (
                                      <div className="comment-box" key={index}>
                                        <div className="comment-giving-box">
                                          <div className="user-comment">
                                            <div className="comment-profile">
                                              {/* <img src={getComment.uimage} alt="laxmanProfile" /> */}
                                              <img
                                                src={
                                                  post.hide_id === 0
                                                    ? getComment.uimage
                                                    : "./images/no-image.png"
                                                }
                                                alt=""
                                              />
                                            </div>

                                            <div className="comment-giving-container">
                                              <div className="comment-giving">
                                                <div className="user CommentCont">
                                                  <div className="user-name userCont">
                                                    <h3 className="header">
                                                      {post.hide_id === 0
                                                        ? getComment.uname
                                                        : "Unknown"}
                                                    </h3>
                                                    <p className="header">
                                                     

                                      {
                                        `${moment.utc(getComment.timeago).local().format('YYYY-MM-DD hh:mm A')}`
                                      }
                                                    </p>
                                                  </div>
                                                  <div className="user-reply">

                                                  {(() => {

                                                  //red color
                                                  if(getComment.comment.includes("*hrs*") && getComment.comment.includes("*hre*") ) {
                                                    getComment.comment = getComment.comment.replace(/\*hrs\*/g, "<span style='color:red'>").replace(/\*hre\*/g, "</span>")
                                                  }

                                                  //yellow color
                                                  if(getComment.comment.includes("*hys*") && getComment.comment.includes("*hye*") ) {
                                                    getComment.comment = getComment.comment.replace(/\*hys\*/g, "<span style='color:yellow'>").replace(/\*hye\*/g, "</span>")
                                                  }

                                                  //blue color
                                                  if(getComment.comment.includes("*hbs*") && getComment.comment.includes("*hbe*") ) {
                                                    getComment.comment = getComment.comment.replace(/\*hbs\*/g, "<span style='color:blue'>").replace(/\*hbe\*/g, "</span>")
                                                  }
                                                  
                                                  //green color
                                                  if(getComment.comment.includes("*hgs*") && getComment.comment.includes("*hge*") ) {
                                                    getComment.comment = getComment.comment.replace(/\*hgs\*/g, "<span style='color:green'>").replace(/\*hge\*/g, "</span>")
                                                  }

                                                  //orange color
                                                  if(getComment.comment.includes("*hos*") && getComment.comment.includes("*hoe*") ) {
                                                    getComment.comment = getComment.comment.replace(/\*hos\*/g, "<span style='color:orange'>").replace(/\*hoe\*/g, "</span>")
                                                  }

                                                  //purple color
                                                  if(getComment.comment.includes("*hps*") && getComment.comment.includes("*hpe*") ) {
                                                    getComment.comment = getComment.comment.replace(/\*hps\*/g, "<span style='color:purple'>").replace(/\*hpe\*/g, "</span>")
                                                  }

                                                  //bold text
                                                  if(getComment.comment.includes("*bs*") && getComment.comment.includes("*be*") ) {
                                                    getComment.comment = getComment.comment.replace(/\*bs\*/g, "<b>").replace(/\*be\*/g, "</b>")
                                                  }

                                                  //bold text
                                                  if(getComment.comment.includes("*bs*") && getComment.comment.includes("*be*") ) {
                                                    getComment.comment = getComment.comment.replace(/\*bs\*/g, "<b>").replace(/\*be\*/g, "</b>")
                                                  }

                                                  //strike through text
                                                  if(getComment.comment.includes("*ss*") && getComment.comment.includes("*se*") ) {
                                                    getComment.comment = getComment.comment.replace(/\*ss\*/g, "<s>").replace(/\*se\*/g, "</s>")
                                                  }

                                                  //Underline text
                                                  if(getComment.comment.includes("*us*") && getComment.comment.includes("*ue*") ) {
                                                    getComment.comment = getComment.comment.replace(/\*us\*/g, "<u>").replace(/\*ue\*/g, "</u>")
                                                  }

                                                  //Italic text
                                                  if(getComment.comment.includes("*is*") && getComment.comment.includes("*ie*") ) {
                                                    getComment.comment = getComment.comment.replace(/\*is\*/g, "<i>").replace(/\*ie\*/g, "</i>")
                                                  }

                                                  })()}

                                                  <h3 className="header" dangerouslySetInnerHTML={{ __html: getComment.comment.replace(/<br\s*\/?>/g, '').replaceAll("/(<([^>]+)>)/gi", "") }}
                                                  />

                                                    {Array.isArray(
                                                      getComment.attach
                                                    )
                                                      ? getComment.attach.map(
                                                          (attach, key) => {
                                                            return (
                                                              <a
                                                                key={key}
                                                                href={attach}
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.preventDefault();
                                                                  //.split is added by PBK to show the original file name while downloading the file
                                                                  saveAs(
                                                                    attach,
                                                                    attach.split("/").pop()
                                                                  );
                                                                }}
                                                              >
                                                                <img
                                                                  src={`${
                                                                    process.env
                                                                      .PUBLIC_URL
                                                                  }/images/docu.png`}
                                                                  className="document_image"
                                                                />
                                                              </a>
                                                            );
                                                          }
                                                        )
                                                      : ""}
                                                  </div>
                                                </div>

                                                
                                              </div>
                                            </div>

                                            <div
                                              className="email-icon-button"
                                              onClick={() =>
                                                this.showSendMailModal(
                                                  post.id,
                                                  'FW: Comment Content',
                                                  getComment.comment,
                                                  post,
                                                  'comment'

                                                )
                                              }
                                            >
                                              <button className="email-icon">
                                                <img src={sendMail} alt="sendMail" />
                                              </button>
                                              <div className="email-text">
                                                <h3 className="header">Email</h3>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="reply-container">
                                          <div
                                            className="display-reply"
                                            onClick={(e) =>
                                              this.showReplyBox(
                                                e,
                                                post.id,
                                                post.uid,
                                                getComment.cid
                                              )
                                            }
                                          >
                                            <p className="text">Reply</p>
                                          </div>
                                          <div className="reply-count">
                                            <p className="text">
                                              ({getComment.rlyComments.length})
                                            </p>
                                          </div>
                                        </div>

                                        <ul
                                          className={
                                            "give-reply-box" +
                                            (this.state.replyContainer ===
                                            post.id
                                              ? " open"
                                              : "") +
                                            (this.state.replyComment ===
                                            getComment.cid
                                              ? " open"
                                              : "")
                                          }
                                        >
                                          {getComment.rlyComments.map(
                                            (replyComment, index) => {
                                              return (
                                                <li
                                                  key={index}
                                                  className="reply-list"
                                                >
                                                  <div className="comment-giving-box reply-giving-box">
                                                    <div className="user-comment reply-comment">
                                                      <div className="comment-profile reply-profile">
                                                        <img
                                                          src={
                                                            post.hide_id === 0
                                                              ? replyComment.uimage
                                                              : "./images/no-image.png"
                                                          }
                                                          alt="profile-img"
                                                        />
                                                      </div>

                                                      <div className="comment-giving reply-giving">
                                                        <div className="user CommentCont">
                                                          <div className="user-name userCont">
                                                            <h3 className="header">
                                                              {post.hide_id ===
                                                              0
                                                                ? replyComment.uname
                                                                : "Unknown"}
                                                            </h3>
                                                            <p className="header">
                                                           

                                                            {`${moment.utc(replyComment.timeago).local().format('YYYY-MM-DD hh:mm A')}`}
                                                            </p>
                                                          </div>
                                                          <div className="user-reply">


                                                          {(() => {

                                                          //red color
                                                          if(replyComment.comment.includes("*hrs*") && replyComment.comment.includes("*hre*") ) {
                                                            replyComment.comment = replyComment.comment.replace(/\*hrs\*/g, "<span style='color:red'>").replace(/\*hre\*/g, "</span>")
                                                          }

                                                          //yellow color
                                                          if(replyComment.comment.includes("*hys*") && replyComment.comment.includes("*hye*") ) {
                                                            replyComment.comment = replyComment.comment.replace(/\*hys\*/g, "<span style='color:yellow'>").replace(/\*hye\*/g, "</span>")
                                                          }

                                                          //blue color
                                                          if(replyComment.comment.includes("*hbs*") && replyComment.comment.includes("*hbe*") ) {
                                                            replyComment.comment = replyComment.comment.replace(/\*hbs\*/g, "<span style='color:blue'>").replace(/\*hbe\*/g, "</span>")
                                                          }

                                                          //green color
                                                          if(replyComment.comment.includes("*hgs*") && replyComment.comment.includes("*hge*") ) {
                                                            replyComment.comment = replyComment.comment.replace(/\*hgs\*/g, "<span style='color:green'>").replace(/\*hge\*/g, "</span>")
                                                          }

                                                          //orange color
                                                          if(replyComment.comment.includes("*hos*") && replyComment.comment.includes("*hoe*") ) {
                                                            replyComment.comment = replyComment.comment.replace(/\*hos\*/g, "<span style='color:orange'>").replace(/\*hoe\*/g, "</span>")
                                                          }

                                                          //purple color
                                                          if(replyComment.comment.includes("*hps*") && replyComment.comment.includes("*hpe*") ) {
                                                            replyComment.comment = replyComment.comment.replace(/\*hps\*/g, "<span style='color:purple'>").replace(/\*hpe\*/g, "</span>")
                                                          }

                                                          //bold text
                                                          if(replyComment.comment.includes("*bs*") && replyComment.comment.includes("*be*") ) {
                                                            replyComment.comment = replyComment.comment.replace(/\*bs\*/g, "<b>").replace(/\*be\*/g, "</b>")
                                                          }

                                                          //bold text
                                                          if(replyComment.comment.includes("*bs*") && replyComment.comment.includes("*be*") ) {
                                                            replyComment.comment = replyComment.comment.replace(/\*bs\*/g, "<b>").replace(/\*be\*/g, "</b>")
                                                          }

                                                          //strike through text
                                                          if(replyComment.comment.includes("*ss*") && replyComment.comment.includes("*se*") ) {
                                                            replyComment.comment = replyComment.comment.replace(/\*ss\*/g, "<s>").replace(/\*se\*/g, "</s>")
                                                          }

                                                          //Underline text
                                                          if(replyComment.comment.includes("*us*") && replyComment.comment.includes("*ue*") ) {
                                                            replyComment.comment = replyComment.comment.replace(/\*us\*/g, "<u>").replace(/\*ue\*/g, "</u>")
                                                          }

                                                          //Italic text
                                                          if(replyComment.comment.includes("*is*") && replyComment.comment.includes("*ie*") ) {
                                                            replyComment.comment = replyComment.comment.replace(/\*is\*/g, "<i>").replace(/\*ie\*/g, "</i>")
                                                          }

                                                          })()}

                                                          <h3 className="header" dangerouslySetInnerHTML={{ __html: replyComment.comment.replace(/<br\s*\/?>/g, '').replaceAll("/(<([^>]+)>)/gi", "") }}
                                                          />

                                                            {Array.isArray(
                                                              replyComment.attach
                                                            )
                                                              ? replyComment.attach.map(
                                                                  (
                                                                    attach,
                                                                    key
                                                                  ) => {
                                                                    return (
                                                                      <a
                                                                        key={
                                                                          key
                                                                        }
                                                                        href={
                                                                          attach
                                                                        }
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.preventDefault();
                                                                          //.split is added by PBK to show the original file name while downloading the file
                                                                          saveAs(
                                                                            attach,
                                                                            attach.split("/").pop()
                                                                          );
                                                                        }}
                                                                      >
                                                                        <img
                                                                          src={`${
                                                                            process
                                                                              .env
                                                                              .PUBLIC_URL
                                                                          }/images/docu.png`}
                                                                          className="document_image"
                                                                        />
                                                                      </a>
                                                                    );
                                                                  }
                                                                )
                                                              : ""}
                                                          </div>
                                                        </div>

                                                        {/* <div className="reply-timing">
                                                                                                    <p className="text">1 minute ago</p>
                                                                                                </div> */}
                                                      </div>
                                                    </div>

                                                    {/* <div
                                                      className="email-icon-button"
                                                      onClick={() =>
                                                        this.showSendMailModal(
                                                          post.id,
                                                          "Reply content",
                                                          replyComment.comment
                                                        )
                                                      }
                                                    >
                                                      <button className="email-icon">
                                                        <img
                                                          src={sendMail}
                                                          alt="sendMail"
                                                        />
                                                      </button>
                                                      <div className="email-text">
                                                        <h3 className="header">
                                                          Email
                                                        </h3>
                                                      </div>
                                                    </div> */}
                                                  </div>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>

                                        <div
                                          className={
                                            "add-comment-msg add-comment-reply" +
                                            (this.state.replyContainer ===
                                            post.id
                                              ? " open"
                                              : "") +
                                            (this.state.replyComment ===
                                            getComment.cid
                                              ? " open"
                                              : "")
                                          }
                                        >
                                          <form
                                            className="msg-field comment_box"
                                            ref={this.commentReplayForm}
                                            encType="multipart/form-data"
                                            onSubmit={(e) => {
                                              e.preventDefault();
                                              this.handleSubmit(
                                                e,
                                                post.id,
                                                post.uid,
                                                getComment.cid
                                              );
                                            }}
                                          >
                                            <img
                                              src={this.state.profileImage}
                                              alt=""
                                              className="profile-pic reply-pic"
                                            />
                                           
                                            <textarea
                                              type="text"
                                              cols="30"
                                              rows="2"
                                              className="input-msg textComment"
                                              placeholder="Reply to this comment"
                                              id={`CommentReply_${
                                                getComment.cid
                                              }`}
                                              onChange={this.handleInputChange}
                                              value={this.state.isCommentReply.replace(/<[^>]+>/g, "")}
                                              name="isCommentReply"
                                            />

                                            <div className="input-file">
                                              <input
                                                type="file"
                                                name="attach"
                                                multiple="multiple"
                                                ref={this.commentReplayFile}
                                                className="input_file_element"
                                                onChange={(e) => {
                                                  if (
                                                    this.commentReplayFile
                                                      .current?.files.length > 0
                                                  ) {
                                                        const newFiles = []
                                                        for(let i = 0; i < e.target.files.length; i++){
                                                          newFiles.push(e.target.files[i].name)
                                                        }
                                                        const docfilename = newFiles.map((u) => u.toString()).join(",")
                                                        //above lines were added by PBK to upload multiple files to Reply for comments
                                                        this.setState({
                                                        isShowCommentReplySelectedFile: true,
                                                        CommentReplyFileName: docfilename,
                                                        //this.commentReplayFile.current?.files[0].name,
                                                    });
                                                  }
                                                }}
                                              />
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/images/upload.png"
                                                }
                                                onClick={() => {
                                                  this.commentReplayFile.current.click();
                                                }}
                                              />
                                            </div>

                                            <button
                                              type="submit"
                                              onClick={(e) => {
                                                if (
                                                  this.state.isCommentReply !==
                                                  ""
                                                ) {
                                                  this.setState({
                                                    isCommentReplayButtonLoding: true,
                                                  });
                                                  this.handleSubmit(
                                                    e,
                                                    post.id,
                                                    post.uid,
                                                    getComment.cid
                                                  );
                                                } else {
                                                  e.preventDefault();
                                                  document
                                                    .getElementById(
                                                      `CommentReply_${
                                                        getComment.cid
                                                      }`
                                                    )
                                                    .focus();
                                                }
                                              }}
                                              className="send-button"
                                              disabled={
                                                this.state
                                                  .isCommentReplayButtonLoding
                                              }
                                            >
                                              {this.state
                                                .isCommentReplayButtonLoding ? (
                                                <ButtonLoader color="#8a8989" />
                                              ) : (
                                                "Post"
                                              )}
                                            </button>
                                          </form>
                                          {this.state
                                            ?.isShowCommentReplySelectedFile ? (
                                            <p className="selected_media_file">
                                              Selected file {" "}
                                              {this.state?.CommentReplyFileName}{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>

                                <div className="add-comment-msg">
                                  <form
                                    className="msg-field comment_box"
                                    ref={this.commentForm}
                                    encType="multipart/form-data"
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      this.handleSubmit(e, post.id, post.uid);
                                    }}
                                  >
                                    <img
                                      src={this.state.profileImage}
                                      alt=""
                                      className="profile-pic cmnt-pic"
                                    />
                                    <textarea
                                      type="text"
                                      cols="30"
                                      rows="2"
                                      className="input-msg textComment"
                                      placeholder="Add a comment"
                                      id={`Comment_${post.id}`}
                                      onChange={this.handleInputChange}
                                      value={this.state.isComment.replace(/<[^>]+>/g, "")}
                                      name="isComment"
                                    />

                                    <div className="input-file">
                                      <input
                                        type="file"
                                        name="attach"
                                        multiple="multiple"
                                        ref={this.commentFile}
                                        className="input_file_element"
                                        onChange={(e) => {
                                          if (
                                            this.commentFile.current?.files
                                              .length > 0
                                          ) {
                                            const newFiles = []
                                            for(let i = 0; i < e.target.files.length; i++){
                                               newFiles.push(e.target.files[i].name)
                                            }
                                            const docfilename = newFiles.map((u) => u.toString()).join(",")
                                            //above lines were added by PBK to upload multiple files to Comments
                                            this.setState({
                                              isShowCommentSelectedFile: true,
                                              CommentFileName: docfilename,
                                              //this.commentFile.current?.files[0].name,
                                            });
                                          }
                                        }}
                                      />
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/images/upload.png"
                                        }
                                        onClick={() => {
                                          this.commentFile.current.click();
                                        }}
                                      />
                                    </div>

                                    <button
                                      type="submit"
                                      onClick={(e) => {
                                        if (this.state.isComment !== "") {
                                          this.setState({
                                            isCommentButtonLoding: true,
                                          });
                                          this.handleSubmit(
                                            e,
                                            post.id,
                                            post.uid
                                          );
                                        } else {
                                          e.preventDefault();
                                          document
                                            .getElementById(
                                              `Comment_${post.id}`
                                            )
                                            .focus();
                                        }
                                      }}
                                      className="send-button"
                                      disabled={
                                        this.state.isCommentButtonLoding
                                      }
                                    >
                                      {this.state.isCommentButtonLoding ? (
                                        <ButtonLoader color="#8a8989" />
                                      ) : (
                                        "Post"
                                      )}
                                    </button>
                                  </form>
                                  {this.state?.isShowCommentSelectedFile ? (
                                    <p className="selected_media_file">
                                      Selected file{" "}
                                      {this.state?.CommentFileName}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        } else if (post.isAds) {
                          if (post.layout === 1) {
                            return <Layout01 singleAd={post} />;
                          } else if (post.layout === 2) {
                            return <Layout02 singleAd={post} />;
                          } else {
                            return <Layout03 singleAd={post} />;
                          }
                        } else {
                          return <SponsorLayout singleSponsor={post} />;
                        }
                      })}
                    </>
                  ) : (
                    !this.state.loading && "No results found for your search."
                  )}
                  {this.state.loading && <Loader />}
                </div>
              </div>
            </div>
          )}

          {this.state.isLoaded === false && (
            <div className="post-section">
              <div className="post-section-container">
                <div
                  className="new-post"
                  id="create-post"
                  onClick={this.showModal}
                >
                  <div className="post-field">
                    <div className="field-profile">
                      <img src={this.state.profileImage} alt="" />
                    </div>
                    <form className="form-field">
                      <input
                        readOnly
                        type="text"
                        className="field-input"
                        placeholder="What's on your mind ..."
                      />
                    </form>
                  </div>

                  <div className="post-pannel">
                    <div className="post-pannel-content">
                      <div className="pannel-icon">
                        <img src={files} alt="files" />
                      </div>
                      <div className="pannel-content">
                        <p className="text">files</p>
                      </div>
                    </div>

                    <div className="post-pannel-content">
                      <div className="pannel-icon">
                        <img
                          src={cameraIcon}
                          alt="cameraIcon"
                          className="img-big"
                        />
                      </div>
                      <div className="pannel-content">
                        <p className="text">photo</p>
                      </div>
                    </div>

                    <div className="post-pannel-content">
                      <div className="pannel-icon">
                        <img src={videoIcon} alt="videoIcon" />
                      </div>
                      <div className="pannel-content">
                        <p className="text">video</p>
                      </div>
                    </div>

                    <div className="post-pannel-content">
                      <div className="pannel-icon">
                        <img src={addLink} alt="addLink" />
                      </div>
                      <div className="pannel-content">
                        <p className="text">links</p>
                      </div>
                    </div>

                    <div className="post-pannel-content">
                      <div className="pannel-icon">
                        <img src={youtube} alt="youtube" className="img-big" />
                      </div>
                      <div className="pannel-content">
                        <p className="text">youtube</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <Loader />
            </div>
          )}
          <PostRightSidebar />
        </section>
        {/* Create Post Model START */}
        <div
          className={
            "creategroup-modal-container add " +
            (this.state.createPostModelOpen ? "open" : "") +
            (this.state.isOpenValidation ? " isOpenValidation" : "")
          }
          id="create-post-modal"
        >
          <div
            className="creategroup-modal send-message-modal postcreate-modal"
            id="modaloverflow"
          >
            <button
              type="button"
              className="close-button-modal"
              id="close-createPost"
              onClick={this.hideModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-header">
              <h1 className="header">Create Post</h1>
            </div>

            <div className="creategroup-content login-content-fields sendmessage-content createpost-container">
              <div className="create-post-content">
                <div className="send-message-profile create-post-profile">
                  <div className="profile post-profile">
                    <img src={this.state.profileImage} alt="profile-img" />
                  </div>
                  <div className="profile-name post-name">
                    <h2 className="header">{this.state.userName}</h2>
                    <div className="input-flex">
                      <div className="input-group my-group">
                        <img
                          src={postGlobe}
                          alt="postGlobe"
                          className="post-globe"
                        />
                        <select
                          id="lunch"
                          className="selectpicker form-control"
                          data-live-search="true"
                          value={this.state.createPost["poshareWith"]}
                          onChange={this.handleChange}
                        >
                          {/*<option value="friends">My Connections</option>
                          <option value="all">All PEXit Users</option>
                          <option value="eid:">Individuals</option>
                          <option value="eid:support@sstusa.com">
                            Support @ SSTUSA
                          </option>
                          <option value="eid:support@luboilconsole.com">
                            SLPL SUPPORT
                          </option>*/}

                          {//added by PBK. Group name was not shown in posting as the option was hardcoded as listed above
                          this.state.groups.map((g, key) => {
                            return (
                              <option key={key} value={encodeURI(g.skey)}>
                                {g.svalue}
                              </option>
                            );
                          })}
                        </select>
                        <span className="input-group-btn">
                          <button className="btn btn-default" type="button">
                            <i className="fas fa-caret-down" />
                          </button>
                        </span>
                      </div>
                      <div className="input-group my-group individual-group">
                        {this.state.isIndivisual ? (
                          <Select
                            className="selectpicker form-control individual-select"
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 99999,
                              }),
                            }}
                            isMulti
                            placeholder="Type a name or multiple names..."
                            value={this.state.selectedIndividualUser}
                            onChange={this.handleIndividualUser}
                            options={this.state.individualUser.map((u) => {
                              return {
                                value: u.email,
                                label: u.username,
                              };
                            })}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.isShowCheckbox ? (
                  <div className="show-user">
                    <div
                      className="color-box createpost-colorbox"
                      id="createpostmodal createcolorbox"
                    />
                    <div className="show-user-form-group show-user-post-group">
                      <input
                        type="checkbox"
                        id="html"
                        defaultChecked={false}
                        value={this.state.createPost.pohideId}
                        onChange={this.setHideId}
                      />
                      <label
                        htmlFor="html"
                        className="show-user-text post-user-text"
                        id="createlabelbox"
                      >
                        Hide My Identity
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <form
                action=""
                className="login sendmessage-form createpost-form"
                ref={this.contentForm}
                encType="multipart/form-data"
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  onChange={this.handleInputChange}
                  name="potitle"
                  placeholder="Title"
                  value={this.state.potitle}
                />
                {/**********   This is where the magic happens     ***********/}
                {this.CreatePostvalidator.message(
                  "post title",
                  this.state.potitle,
                  "required|string"
                )}

                <textarea
                  name="popost"
                  id=""
                  cols="30"
                  rows="5"
                  placeholder="What's on you mind?"
                  onChange={this.handleInputChange}
                  value={this.state.popost.replace(/<[^>]+>/g, "")}
                />

                

                {/**********   This is where the magic happens     ***********/}
                {this.CreatePostvalidator.message(
                  "post content",
                  this.state.popost,
                  "required|min:8"
                )}

                <div className="postmodal-pannel">
                  <div
                    className="postmodal-content"
                    onClick={this.selectpodocu}
                  >
                    <div className="postmodal-content-image">
                      <img src={files} alt="files" />
                    </div>
                    <h2 className="header">files</h2>
                  </div>

                  <div
                    className="postmodal-content"
                    onClick={this.selectpoimage}
                  >
                    <div className="postmodal-content-image">
                      <img src={cameraIcon} alt="cameraIcon" />
                    </div>
                    <h2 className="header">photos</h2>
                  </div>

                  <div className="postmodal-content" onClick={this.selectVideo}>
                    <div className="postmodal-content-image">
                      <img src={videoIcon} alt="videoIcon" />
                    </div>
                    <h2 className="header">video</h2>
                  </div>

                  <div className="postmodal-content" onClick={this.selectLink}>
                    <div className="postmodal-content-image">
                      <img src={addLink} alt="addLink" />
                    </div>
                    <h2 className="header">links</h2>
                  </div>

                  <div
                    className="postmodal-content"
                    onClick={this.selectYoutube}
                  >
                    <div className="postmodal-content-image">
                      <img src={youtube} alt="youtube" />
                    </div>
                    <h2 className="header">youtube</h2>
                  </div>

                  <div className="postmodal-content">
                    <button
                      type="button"
                      className="posting-button"
                      id="post-feeds"
                      disabled={this.state.isButtonLoding}
                      onClick={this.submitPost}
                    >
                      <h1 className="grey-header">
                        {this.state.isButtonLoding ? (
                          <ButtonLoader color="#8a8989" />
                        ) : (
                          "Post"
                        )}
                      </h1>
                    </button>
                  </div>
                </div>
              </form>

              <form
                className="login sendmessage-form createpost-form post-media-section"
                ref={this.mediaForm}
                encType="multipart/form-data"
              >
                <div
                  className={
                    this.state.isShowMedia === "podocu" ? "" : "d-none"
                  }
                >
                  <input
                    type="file"
                    name="podocu"
                    multiple="multiple"
                    ref={this.podocu}
                    accept="audio/*,video/*,image/*,.doc,.docx,.pdf,.ppt,.pptx"
                    onChange={(e) => {
                      this.setState({
                        podocu: e.target,
                      });
                      this.CreatePostvalidator.allValid();
                    }}
                  />

                  {this.CreatePostvalidator.message(
                    "File ",
                    this.state?.podocu,
                    "PostFiles"
                  )}
                </div>

                <div
                  className={
                    this.state.isShowMedia === "poimage" ? "" : "d-none"
                  }
                >
                  <input
                    type="file"
                    name="poimage"
                    multiple="multiple"
                    ref={this.poimage}
                    accept="image/*"
                    onChange={(e) => {
                      this.setState({
                        poimage: e.target,
                      });
                      this.CreatePostvalidator.allValid();
                    }}
                  />

                  {this.CreatePostvalidator.message(
                    "Images ",
                    this.state?.poimage,
                    "Photos"
                  )}
                </div>

                <div
                  className={this.state.isShowMedia === "video" ? "" : "d-none"}
                >
                  <input
                    type="file"
                    name="video"
                    multiple="multiple"
                    ref={this.video}
                    accept="video/*"
                    onChange={(e) => {
                      this.setState({
                        video: e.target,
                      });
                      this.CreatePostvalidator.allValid();
                    }}
                  />
                  {this.CreatePostvalidator.message(
                    "Videos ",
                    this.state?.video,
                    "VideoFiles"
                  )}
                </div>

                <div
                  className={
                    this.state.isShowMedia === "polink" ? "" : "d-none"
                  }
                >
                  <input
                    type="text"
                    name="polink"
                    ref={this.polink}
                    onChange={this.handleInputChange}
                    value={this.state.polink}
                  />
                  {this.CreatePostvalidator.message(
                    "Links ",
                    this.state.polink,
                    "multipleLinks"
                  )}
                  <label>
                    You can add multiple links separated by comma(,)
                  </label>
                </div>

                <div
                  className={
                    this.state.isShowMedia === "povideo" ? "" : "d-none"
                  }
                >
                  <input
                    type="text"
                    name="povideo"
                    ref={this.povideo}
                    onChange={this.handleInputChange}
                  />
                  {this.CreatePostvalidator.message(
                    "Links ",
                    this.state.povideo,
                    "multipleYoutubeLinks"
                  )}
                  <label>
                    You can add multiple links separated by comma(,)
                  </label>
                </div>
              </form>

              {/* <div className="input-box"></div> */}
            </div>
          </div>
        </div>
        {/* Create Post Model END */}
        {/* like model start */}
        <div
          className={
            "creategroup-modal-container " +
            (this.state.likeModal ? "open" : "")
          }
          id="create-post-modal"
        >
          <div
            className="creategroup-modal send-message-modal postcreate-modal like-adjust-modal"
            id="profileviewmodal"
          >
            <button
              type="button"
              className="close-button-modal"
              id="close-createPost"
              onClick={this.hideLikeModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-content login-content-fields sendmessage-content createpost-container count-container">
              {this.state.getLikes.length ? (
                this.state.getLikes.map((likes, key) => (
                  <div className="like-modal" key={key}>
                    <div className="user-profile">
                      <img src={likes.pimage} alt="" />
                    </div>
                    <div className="user-name">
                      <h1 className="header">{likes.username}</h1>
                    </div>
                  </div>
                ))
              ) : (
                <div className="like-modal">
                  <div className="user-name">
                    <h1 className="header">No Records Found</h1>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* like model end */}
        {/* delete modal start */}
        <div
          className={
            "creategroup-modal-container report-modal-container" +
            (this.state.deleteModal ? " open" : "")
          }
        >
          <div className="creategroup-modal report-modal delete-modal">
            <div className="forgot-header">
              <h1 className="header">
                Are you sure you want to delete your post?
              </h1>
            </div>

            <div className="creategroup-content delete-buttons-container">
              <button
                type="button"
                className="login-button creategroup-button forgot-content cancel-button"
                id="mySupplier"
                onClick={this.hideDeleteModal}
              >
                <h2>Cancel</h2>
              </button>

              <button
                type="button"
                className="login-button creategroup-button forgot-content delete-button"
                id="mySupplier"
                onClick={() => this.deletePost(this.state.deletePostId)}
              >
                <h2>Delete</h2>
              </button>
            </div>
          </div>
        </div>
        {/* delete modal end */}
        {/* block modal start */}
        <div
          className={
            "creategroup-modal-container report-modal-container" +
            (this.state.blockModal ? " open" : "")
          }
        >
          <div className="creategroup-modal report-modal delete-modal">
            <div className="forgot-header">
              <h1 className="header">
                Are you sure you want to block this service user?
              </h1>
            </div>

            <div className="creategroup-content delete-buttons-container">
              <button
                type="button"
                className="login-button creategroup-button forgot-content cancel-button"
                id="mySupplier"
                onClick={this.hideBlockModal}
              >
                <h2>Cancel</h2>
              </button>

              <button
                type="button"
                className="login-button creategroup-button forgot-content delete-button"
                id="mySupplier"
                onClick={() => this.blockUser("block")}
              >
                <h2>Block</h2>
              </button>
            </div>
          </div>
        </div>
        {/* block modal end */}
        {/* message modal start */}
        <div
          className={
            "like-modal-container message-modal-container" +
            (this.state.messageModal ? " open" : "")
          }
        >
          <div className="like-modal message-modal" id="messagemodal">
            <button
              type="button"
              className="close-button-modal dark-button"
              id="close-createPost"
              onClick={this.hideMessageModal}
            >
              <i className="fas fa-times" />
            </button>
            <div className="creategroup-header">
              <h1 className="header">
                Message to <span>{this.state.messageSendTo}</span>
              </h1>
            </div>

            <div className="creategroup-content login-content-fields msg-content-fields">
              <form action="" className="login">
                <Editor
                  apiKey="textarea"
                  name="message"
                  value={this.state.message}
                  init={{
                    height: 140,
                    menubar: false,
                    plugins: [],
                    toolbar: [],
                    placeholder: "Message",
                  }}
                  onEditorChange={(e) => {
                    this.setState({
                      message:
                        e.replace(/^\s+/g, "") === "<p>&nbsp;&nbsp;</p>"
                          ? ""
                          : e.replace(/^\s+/g, ""),
                    });
                  }}
                />

                {this.MsgValidator.message(
                  "Message field is",
                  this.state.message,
                  "required|string"
                )}

                <button
                  type="button"
                  className="login-button msg-button"
                  id="mySupplier"
                  onClick={this.sendMessage}
                  disabled={this.state.isLoading}
                >
                  <h2>{this.state.isLoading ? <Loader /> : "Send"}</h2>
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* message modal end */}

        {/* Share Model START */}
        <div
          className={
            "creategroup-modal-container add " +
            (this.state.shareModal ? "open" : "") +
            (this.state.isOpenValidation ? " isOpenValidation" : "")
          }
          id="create-post-modal"
        >
          <div
            className="creategroup-modal send-message-modal postcreate-modal"
            id="modaloverflow"
          >
            <button
              type="button"
              className="close-button-modal"
              id="close-createPost"
              onClick={this.hideShareModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-header">
              <h1 className="header">Share Post</h1>
            </div>

            <div className="creategroup-content login-content-fields sendmessage-content createpost-container">
              <div className="create-post-content">
                <div className="send-message-profile create-post-profile">
                  <div className="profile post-profile">
                    <img
                      src={this.state.profileImage}
                      alt={this.state.userName}
                    />
                  </div>
                  <div className="profile-name post-name">
                    <h2 className="header">{this.state.userName}</h2>
                    <div className="input-flex">
                      <div className="input-group my-group">
                        <img
                          src={postGlobe}
                          alt="postGlobe"
                          className="post-globe"
                        />
                        <select
                          id="lunch"
                          className="selectpicker form-control"
                          data-live-search="true"
                          value={this.state.createPost.poshareWith}
                          onChange={this.handleChange}
                        >
                          
                          {this.state.groups.map((g, key) => {
                            return (
                              <option key={key} value={encodeURI(g.skey)}>
                                {g.svalue}
                              </option>
                            );
                          })}
                        </select>
                        <span className="input-group-btn">
                          <button className="btn btn-default" type="button">
                            <i className="fas fa-caret-down" />
                          </button>
                        </span>
                      </div>
                      <div className="input-group my-group individual-group">
                        {this.state.isIndivisual ? (
                          <Select
                            className="selectpicker form-control individual-select"
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 99999,
                              }),
                            }}
                            isMulti
                            placeholder="Type a name or multiple names..."
                            value={this.state.selectedIndividualUser}
                            onChange={this.handleIndividualUser}
                            options={this.state.individualUser.map((u) => {
                              return {
                                value: u.email,
                                label: u.username,
                              };
                            })}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                          />
                        ) : (
                         
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.createPost.poshareWith === "friends" ? (
                  <div className="show-user">
                    <div
                      className="color-box createpost-colorbox"
                      id="sharepostmodal sharecolorbox"
                    />
                    <div className="show-user-form-group show-user-post-group">
                      <input
                        type="checkbox"
                        id="html"
                        value="1"
                        checked={this.state.sharepostHideID}
                      />
                      <label
                        htmlFor="html"
                        className="show-user-text post-user-text"
                        id="sharelabelbox"
                        onClick={() => {
                          let chk =
                            this.state.sharepostHideID === true ? false : true;
                          this.setState({
                            sharepostHideID: chk,
                          });
                        }}
                      >
                        Hide My Identity
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <form
                action=""
                className="login sendmessage-form createpost-form"
                ref={this.shareContentForm}
                encType="multipart/form-data"
              >
                {this.state.isSharePostEdited ? (
                  <>
                    <input
                      placeholder="Title"
                      value={this.state.potitle}
                      onChange={this.handleInputChange}
                      name="potitle"
                    />

                    {this.sharevalidator.message(
                      "post title field is",
                      this.state.potitle,
                      "required|string"
                    )}

                    <Editor
                      apiKey="textarea"
                      name="popost"
                      value={this.state.popost}
                      init={{
                        height: 140,
                        menubar: false,
                        plugins: [],
                        toolbar: [],
                        placeholder: "What's on you mind?",
                      }}
                      onEditorChange={(e) => {
                        this.setState({
                          popost:
                            e.replace(/^\s+/g, "") === "<p>&nbsp;&nbsp;</p>"
                              ? ""
                              : e.replace(/^\s+/g, ""),
                        });
                      }}
                    />

                    {this.sharevalidator.message(
                      "post content field is",
                      this.state.popost,
                      "required|string"
                    )}
                  </>
                ) : (
                  <div className="staticEdit_outerdiv">
                    <div className="staticEdit_innerdiv">
                      <ShowMoreText
                        /* Default options */
                        lines={3}
                        more="Read more"
                        less="Read less"
                        className="content-css staticEditTitle"
                        anchorClass="my-anchor-css-class"
                        expanded={false}
                        truncatedEndingComponent={"... "}
                        color="red"
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.potitle,
                          }}
                        />
                      </ShowMoreText>
                      <ShowMoreText
                        /* Default options */
                        lines={3}
                        more="Read more"
                        less="Read less"
                        className="content-css staticEditDesc"
                        anchorClass="my-anchor-css-class"
                        expanded={false}
                        truncatedEndingComponent={"... "}
                        color="red"
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.popost,
                          }}
                        />
                      </ShowMoreText>
                    </div>
                    <button
                      onClick={() => {
                        this.setState(() => {
                          return {
                            isSharePostEdited: true,
                          };
                        });
                      }}
                    >
                      Edit
                    </button>
                  </div>
                )}
                <div>
                  {/* Media */}
                  <input
                    type="hidden"
                    value={this.state?.sharePost?.old_docu}
                    name="old_docu"
                    id="old_docu"
                  />

                  <input
                    type="hidden"
                    value={this.state?.sharePost?.old_image}
                    name="old_image"
                    id="old_image"
                  />

                  <input
                    type="hidden"
                    value={this.state?.sharePost?.old_video}
                    name="old_video"
                    id="old_video"
                  />

                  {this.state.shareModal && (
                    <div className="d-flex d_preview_images">
                      {Array.isArray(this.state.sharePost?.docu)
                        ? this.state.sharePost?.docu.map((doc) => {
                            return (
                              <div className="image-area" id={doc}>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/images/docu.png"
                                  }
                                  target={"_blank"}
                                  alt=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    saveAs(doc);
                                  }}
                                />
                                <a
                                  className="remove-image"
                                  href="#"
                                  onClick={() => {
                                    this.deleteDataPostFile(doc, "document");
                                  }}
                                >
                                  &#215;
                                </a>
                              </div>
                            );
                          })
                        : ""}

                      {Array.isArray(this.state.sharePost?.image)
                        ? this.state.sharePost?.image.map((img, key) => {
                            return (
                              <div className="image-area" key={key} id={img}>
                                <img
                                  src={img}
                                  alt=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    saveAs(img);
                                  }}
                                />
                                <a
                                  className="remove-image"
                                  href="#"
                                  onClick={() => {
                                    this.deleteDataPostFile(img, "image");
                                  }}
                                >
                                  &#215;
                                </a>
                              </div>
                            );
                          })
                        : ""}

                      {Array.isArray(this.state.sharePost?.allvid)
                        ? this.state.sharePost?.allvid.map((vid) => {
                            return (
                              <div className="image-area" id={vid}>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/images/film.png"
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    saveAs(vid);
                                  }}
                                />
                                <a
                                  className="remove-image"
                                  href="#"
                                  onClick={() => {
                                    this.deleteDataPostFile(vid, "video");
                                  }}
                                >
                                  &#215;
                                </a>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  )}
                </div>
              </form>

              <div className="postmodal-pannel">
                <div className="postmodal-content" onClick={this.selectpfle}>
                  <div className="postmodal-content-image">
                    <img src={files} alt="files" />
                  </div>
                  <h2 className="header">files</h2>
                </div>

                <div className="postmodal-content" onClick={this.selectpphot}>
                  <div className="postmodal-content-image">
                    <img src={cameraIcon} alt="cameraIcon" />
                  </div>
                  <h2 className="header">photos</h2>
                </div>

                <div className="postmodal-content" onClick={this.selectVideo}>
                  <div className="postmodal-content-image">
                    <img src={videoIcon} alt="videoIcon" />
                  </div>
                  <h2 className="header">video</h2>
                </div>

                <div className="postmodal-content" onClick={this.selectLink}>
                  <div className="postmodal-content-image">
                    <img src={addLink} alt="addLink" />
                  </div>
                  <h2 className="header">links</h2>
                </div>

                <div className="postmodal-content" onClick={this.selectYoutube}>
                  <div className="postmodal-content-image">
                    <img src={youtube} alt="youtube" />
                  </div>
                  <h2 className="header">youtube</h2>
                </div>

                <div className="postmodal-content">
                  <button
                    type="submit"
                    className="posting-button"
                    id="post-feeds"
                    onClick={this.submitShare}
                  >
                    <h1 className="grey-header">Share</h1>
                  </button>
                </div>
              </div>

              <form
                className="login sendmessage-form createpost-form post-media-section"
                ref={this.shareForm}
                encType="multipart/form-data"
              >
                <div
                  className={this.state.isShowMedia === "pfle" ? "" : "d-none"}
                >
                  <input
                    type="file"
                    name="docu"
                    ref={this.share_docu}
                    multiple="multiple"
                    accept="audio/*,video/*,image/*,.doc,.docx,.pdf,.ppt,.pptx"
                    onChange={(e) => {
                      this.setState({
                        share_docu: e.target,
                      });
                      this.sharevalidator.allValid();
                    }}
                  />

                  {this.sharevalidator.message(
                    "File ",
                    this.state?.share_docu,
                    "PostFiles"
                  )}
                </div>

                <div
                  className={this.state.isShowMedia === "pphot" ? "" : "d-none"}
                >
                  <input
                    type="file"
                    name="image"
                    multiple="multiple"
                    ref={this.share_image}
                    accept="image/*"
                    onChange={(e) => {
                      this.setState({
                        share_image: e.target,
                      });
                      this.sharevalidator.allValid();
                    }}
                  />

                  {this.sharevalidator.message(
                    "Images ",
                    this.state?.share_image,
                    "Photos"
                  )}
                </div>

                <div
                  className={this.state.isShowMedia === "video" ? "" : "d-none"}
                >
                  <input
                    type="file"
                    name="video"
                    multiple="multiple"
                    ref={this.share_video}
                    accept="video/*"
                    onChange={(e) => {
                      this.setState({
                        share_video: e.target,
                      });
                      this.sharevalidator.allValid();
                    }}
                  />
                  {this.sharevalidator.message(
                    "Videos ",
                    this.state?.share_video,
                    "VideoFiles"
                  )}
                </div>

                <div
                  className={
                    this.state.isShowMedia === "polink" ? "" : "d-none"
                  }
                >
                  <input
                    type="text"
                    name="polink"
                    ref={this.share_polink}
                    value={this.state?.share_polink}
                    onChange={(e) => {
                      this.setState({
                        share_polink: e.target.value?.replace(/^\s+/g, ""),
                      });
                    }}
                  />
                  {this.sharevalidator.message(
                    "Links ",
                    this.state?.share_polink,
                    "multipleLinks"
                  )}
                  <label>
                    You can add multiple links separated by comma(,)
                  </label>
                </div>

                <div
                  className={
                    this.state.isShowMedia === "povideo" ? "" : "d-none"
                  }
                >
                  <input
                    type="text"
                    name="povideo"
                    value={this.state?.share_povideo}
                    onChange={(e) => {
                      this.setState({
                        share_povideo: e.target.value?.replace(/^\s+/g, ""),
                      });
                    }}
                  />
                  {this.sharevalidator.message(
                    "Links ",
                    this.state?.share_povideo,
                    "multipleYoutubeLinks"
                  )}
                  <label>
                    You can add multiple links separated by comma(,)
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* share  Model END */}

        {/* Contact Supplier Model START */}
        <div
          className={
            "creategroup-modal-container topSpace" +
            (this.state.sendEmail ? " open" : "")
          }
        >
          <div className="creategroup-modal contactsuuplier-modal invite-modal overflowModal">
            <button
              type="button"
              className="close-button-modal"
              id="close-createGroup"
              onClick={this.hideSendMailModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-header sendModalHeader">
              <h1 className="header">Send Email</h1>
              <h2 className="header noteleft">
                (<strong>Note:</strong> Recipients to check Inbox / Spam folder.
                Multiple email addresses should be separated by comma.)
              </h2>
            </div>

            <div className="creategroup-content login-content-fields invite-friends-modal">
              <form
                action=""
                className="login sendEmailCont"
                ref={this.shareEmailForm}
                encType="multipart/form-data"
              >
                <div className="contact-container">
                  <label htmlFor="senderName" className="name">
                    Your Email
                  </label>

                  <input
                    type="text"
                    className="groupname"
                    placeholder="Sender Email"
                    name="senderEmail"
                    value={this.state.senderEmail}
                    onChange={this.handleInputChange}
                  />
                </div>

                {this.emailvalidator.message(
                  "Sender Email field is",
                  this.state.senderEmail,
                  "required|email"
                )}

                <div className="contact-container">
                  <label htmlFor="supplierRecipient" className="name">
                    Recipient Email
                  </label>

                  <input
                    type="text"
                    className="groupname"
                    placeholder="Separate email ids by , (comma)"
                    name="supplierRecipient"
                    value={this.state.supplierRecipient}
                    onChange={this.handleInputChange}
                  />
                </div>

                {this.emailvalidator.message(
                  "Email field is",
                  this.state.supplierRecipient,
                  "required|string|multipleEmail"
                )}

                <div className="contact-container">
                  <label htmlFor="subject" className="name">
                    Title
                  </label>

                  <input
                    type="text"
                    className="groupname"
                    placeholder="Subject"
                    name="sendEmailTitle"
                    value={this.state.sendEmailTitle}
                    onChange={this.handleInputChange}
                  />
                </div>

                {this.emailvalidator.message(
                  "Title field is",
                  this.state.sendEmailTitle,
                  "required|string"
                )}

                <div className="contact-container">
                  <label htmlFor="sendEmailDesc" className="name">
                    Message
                  </label>

                  <Editor
                    apiKey="textarea"
                    name="sendEmailDesc"
                    value={this.state.sendEmailDesc}
                    init={{
                      height: 140,
                      menubar: false,
                      plugins: [],
                      toolbar: [],
                      placeholder: "Message",
                    }}
                    onEditorChange={(e) => {
                      this.setState({
                        sendEmailDesc:
                          e.replace(/^\s+/g, "") === "<p>&nbsp;&nbsp;</p>"
                            ? ""
                            : e.replace(/^\s+/g, ""),
                      });
                    }}
                  />
                </div>

                {this.emailvalidator.message(
                  "Message field is",
                  this.state.sendEmailDesc,
                  "required|string"
                )}

                {this.state.attachmentsList.length > 0 ? (
                  <div className="contact-container">
                    <label className="name attachmentTag">Attachments</label>

                    <div className="attachmentListDiv">
                      {this.state.attachmentsList.map((files) => (
                        <div>
                          <input
                            type="checkbox"
                            className="attachmentList"
                            name="attachments"
                            value={files}
                            defaultChecked={true}
                            onChange={(e) => {
                              this.handlecheckboxChange(e, files);
                            }}
                          />
                          <label>{files.split("/").pop()}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  className="login-button creategroup-button invite-button"
                  id="mySupplier"
                  onClick={this.sendEmailTo}
                  disabled={this.state.isButtonLoding}
                >
                  <h2>
                    {this.state.isButtonLoding ? (
                      <ButtonLoader color="#8a8989" />
                    ) : (
                      "Submit"
                    )}
                  </h2>
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* Contact Supplier Model END */}
        {/* edit Post Model START */}
        <div
          className={
            "creategroup-modal-container add " +
            (this.state.editModal ? "open" : "") +
            (this.state.isOpenValidation ? " isOpenValidation" : "")
          }
          id="create-post-modal"
        >
          <div
            className="creategroup-modal send-message-modal postcreate-modal"
            id="modaloverflow"
          >
            <button
              type="button"
              className="close-button-modal"
              id="close-createPost"
              onClick={this.hideEditModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-header">
              <h1 className="header">Update Post</h1>
            </div>

            <div className="creategroup-content login-content-fields sendmessage-content createpost-container">
              <div className="create-post-content">
                <div className="send-message-profile create-post-profile">
                  <div className="profile post-profile">
                    <img src={this.state?.profileImage} alt="profile-img" />
                  </div>
                  <div className="profile-name post-name">
                    <h2 className="header">{this.state?.userName}</h2>
                    <div className="input-flex">
                      <div className="input-group my-group">
                        <img
                          src={postGlobe}
                          alt="postGlobe"
                          className="post-globe"
                        />
                        <select
                          id="lunch"
                          className="selectpicker form-control"
                          data-live-search="true"
                          value={this.state.editPost?.shareWithKey}
                          onChange={(e) => {
                            this.setState({
                              editPost: {
                                ...this.state.editPost,
                                shareWithKey: e.target.value,
                              },
                            });
                          }}
                        >
                          <option value="friends">My Connections</option>
                          <option value="all">All PEXit Users</option>
                          <option value="eid:">Individuals</option>
                          <option value="eid:support@sstusa.com">
                            Support @ SSTUSA
                          </option>
                          <option value="eid:support@luboilconsole.com">
                            SLPL SUPPORT
                          </option>
                        </select>
                        <span className="input-group-btn">
                          <button className="btn btn-default" type="button">
                            <i className="fas fa-caret-down" />
                          </button>
                        </span>
                      </div>
                      <div className="input-group my-group individual-group">
                        {this.state.editPost?.shareWithKey === "eid:" ? (
                          <Select
                            className="selectpicker form-control individual-select"
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            isMulti
                            placeholder="Type a name or multiple names..."
                            value={this.state.selectedIndividualUser}
                            onChange={this.handleIndividualUser}
                            options={this.state.individualUser.map((u) => {
                              return {
                                value: u.email,
                                label: u.username,
                              };
                            })}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.editPost?.shareWithKey === "friends" ||
                this.state.editPost?.shareWithKey === "" ? (
                  <div className="show-user">
                    <div
                      className="color-box createpost-colorbox"
                      id="updatepostmodal updatecolorbox"
                    />
                    <div className="show-user-form-group show-user-post-group">
                      <input
                        type="checkbox"
                        id="html"
                        value={1}
                        checked={this.state.isCheckedHideid}
                      />
                      <label
                        htmlFor="html"
                        className="show-user-text post-user-text"
                        id="sharelabelbox"
                        onClick={() => {
                          let chk =
                            this.state.isCheckedHideid === true ? false : true;
                          this.setState({
                            isCheckedHideid: chk,
                          });
                        }}
                      >
                        Hide My Identity
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <form
                action=""
                className="login sendmessage-form createpost-form"
                ref={this.editPostDetails}
                encType="multipart/form-data"
              >
                <input
                  name="potitle"
                  placeholder="Title"
                  value={this.state.editPost?.title}
                  onChange={(e) => {
                    this.setState({
                      editPost: {
                        ...this.state.editPost,
                        title: e.target.value
                          ?.replace(/^\s+/g, "")
                          ?.replace(/^\s+/g, ""),
                      },
                    });
                  }}
                />

                {this.editPostValidator.message(
                  "post title",
                  this.state.editPost?.title,
                  "required|string"
                )}


                <Editor
                  apiKey="textarea"
                  name="popost"
                  value={this.state.editPost?.post}
                  init={{
                    height: 140,
                    menubar: false,
                    plugins: [],
                    toolbar: [],
                    placeholder: "What's on you mind?",
                  }}
                  onEditorChange={(e) => {
                    this.setState({
                      editPost: {
                        ...this.state.editPost,
                        post:
                          e.replace(/^\s+/g, "") === "<p>&nbsp;&nbsp;</p>"
                            ? ""
                            : e.replace(/^\s+/g, ""),
                      },
                    });
                  }}
                />

                {this.editPostValidator.message(
                  "post content",
                  this.state.editPost?.post,
                  "required|min:8"
                )}
              </form>

              <div className="postmodal-pannel">
                <div className="postmodal-content" onClick={this.selectpodocu}>
                  <div className="postmodal-content-image">
                    <img src={files} alt="files" />
                  </div>
                  <h2 className="header">files</h2>
                </div>

                <div className="postmodal-content" onClick={this.selectpoimage}>
                  <div className="postmodal-content-image">
                    <img src={cameraIcon} alt="cameraIcon" />
                  </div>
                  <h2 className="header">photos</h2>
                </div>

                <div className="postmodal-content" onClick={this.selectVideo}>
                  <div className="postmodal-content-image">
                    <img src={videoIcon} alt="videoIcon" />
                  </div>
                  <h2 className="header">video</h2>
                </div>

                <div className="postmodal-content" onClick={this.selectLink}>
                  <div className="postmodal-content-image">
                    <img src={addLink} alt="addLink" />
                  </div>
                  <h2 className="header">links</h2>
                </div>

                <div className="postmodal-content" onClick={this.selectYoutube}>
                  <div className="postmodal-content-image">
                    <img src={youtube} alt="youtube" />
                  </div>
                  <h2 className="header">youtube</h2>
                </div>

                <div className="postmodal-content">
                  <button
                    type="submit"
                    className="posting-button"
                    id="post-feeds"
                    onClick={this.editPost}
                  >
                    <h1 className="grey-header">Update</h1>
                  </button>
                </div>
              </div>

              <form
                className="login sendmessage-form createpost-form post-media-section"
                ref={this.editPostMedia}
                encType="multipart/form-data"
              >
                <div
                  className={
                    this.state.isShowMedia === "podocu" ? "" : "d-none"
                  }
                >
                  <input
                    type="file"
                    name="podocu"
                    multiple="multiple"
                    ref={this.editPost_podocu}
                    accept="audio/*,video/*,image/*,.doc,.docx,.pdf,.ppt,.pptx"
                    onChange={(e) => {
                      this.setState({
                        editPost_podocu: e.target,
                      });
                      this.editPostValidator.allValid();
                    }}
                  />
                  {this.editPostValidator.message(
                    "File",
                    this.state?.editPost_podocu,
                    "PostFiles"
                  )}

                  <div className="d-flex d_preview_images">
                    {Array.isArray(this.state.editPost?.docu)
                      ? this.state.editPost?.docu.map((doc) => {
                          return (
                            <div className="image-area" id={doc}>
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/images/docu.png"
                                }
                                target={"_blank"}
                                alt=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  saveAs(doc);
                                }}
                              />
                              <a
                                className="remove-image"
                                href="#"
                                onClick={() => {
                                  this.deletePostFile(
                                    this.state.editPost?.uid,
                                    this.state.editPost?.id,
                                    "documents",
                                    doc
                                  );
                                }}
                              >
                                &#215;
                              </a>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>

                <div
                  className={
                    this.state.isShowMedia === "poimage" ? "" : "d-none"
                  }
                >
                  <input
                    type="file"
                    name="poimage"
                    multiple="multiple"
                    accept="image/*"
                    ref={this.editPost_poimage}
                    onChange={(e) => {
                      this.setState({
                        editPost_poimage: e.target,
                      });
                      this.editPostValidator.allValid();
                    }}
                  />

                  {this.editPostValidator.message(
                    "Photo",
                    this.state?.editPost_poimage,
                    "Photos"
                  )}

                  <div className="d-flex d_preview_images">
                    {Array.isArray(this.state.editPost?.image)
                      ? this.state.editPost?.image.map((img, key) => {
                          return (
                            <div className="image-area" key={key} id={img}>
                              <img src={img} alt="" />
                              <a
                                className="remove-image"
                                href="#"
                                onClick={() => {
                                  this.deletePostFile(
                                    this.state.editPost?.uid,
                                    this.state.editPost?.id,
                                    "photos",
                                    img
                                  );
                                }}
                              >
                                &#215;
                              </a>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>

                <div
                  className={this.state.isShowMedia === "video" ? "" : "d-none"}
                >
                  <input
                    type="file"
                    name="video"
                    multiple="multiple"
                    accept="video/*"
                    ref={this.editPost_video}
                    onChange={(e) => {
                      this.setState({
                        editPost_video: e.target,
                      });
                      this.editPostValidator.allValid();
                    }}
                  />

                  {this.editPostValidator.message(
                    "Video ",
                    this.state?.editPost_video,
                    "VideoFiles"
                  )}

                  <div className="d-flex d_preview_images">
                    {Array.isArray(this.state.editPost?.allvid)
                      ? this.state.editPost?.allvid.map((vid) => {
                          return (
                            <div className="image-area" id={vid}>
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/images/film.png"
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  saveAs(vid);
                                }}
                              />
                              <a
                                className="remove-image"
                                href="#"
                                onClick={() => {
                                  this.deletePostFile(
                                    this.state.editPost?.uid,
                                    this.state.editPost?.id,
                                    "video",
                                    vid
                                  );
                                }}
                              >
                                &#215;
                              </a>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>

                <div
                  className={
                    this.state.isShowMedia === "polink" ? "" : "d-none"
                  }
                >
                  <input
                    type="text"
                    name="polink"
                    ref={this.polink}
                    value={this.state.editPost?.link}
                    onChange={(e) => {
                      this.setState({
                        editPost: {
                          ...this.state.editPost,
                          link: e.target.value
                            ?.replace(/^\s+/g, "")
                            ?.replace(/^\s+/g, ""),
                        },
                      });
                    }}
                  />
                  {this.editPostValidator.message(
                    "Links ",
                    this.state.editPost?.link,
                    "multipleLinks"
                  )}
                  <label>
                    You can add multiple links separated by comma(,)
                  </label>
                </div>

                <div
                  className={
                    this.state.isShowMedia === "povideo" ? "" : "d-none"
                  }
                >
                  <input
                    type="text"
                    name="povideo"
                    value={this.state.editPost?.video}
                    onChange={(e) => {
                      this.setState({
                        editPost: {
                          ...this.state.editPost,
                          video: e.target.value
                            ?.replace(/^\s+/g, "")
                            ?.replace(/^\s+/g, ""),
                        },
                      });
                    }}
                  />

                  {this.editPostValidator.message(
                    "Youtube ",
                    this.state.editPost?.video,
                    "multipleYoutubeLinks"
                  )}

                  <label>
                    You can add multiple links separated by comma(,)
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* edit Post Model END */}
        {isOpen && (
          <Lightbox
            mainSrc={lightBoxImages[photoIndex]}
            nextSrc={lightBoxImages[(photoIndex + 1) % lightBoxImages.length]}
            prevSrc={
              lightBoxImages[
                (photoIndex + lightBoxImages.length - 1) % lightBoxImages.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + lightBoxImages.length - 1) %
                  lightBoxImages.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % lightBoxImages.length,
              })
            }
          />
        )}
      </>
    );
  }
}

export default withRouter(PostPage);
