import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import Layout from "./layouts/Layout";

import Homepage from "./pages/homepage/Homepage";
import Aboutus from "./pages/aboutus/Aboutus";
import Support from "./pages/support/Support";
import Terms from "./pages/terms/Terms";
import Pricing from "./pages/pricing/Pricing";
import Partners from "./pages/partners/Partners";
import AboutusLogin from "./pages/aboutus-login/Aboutus-login";

import ProductPage from "./pages/product/Product";
import { AddProductPage } from "./pages/product-add/AddProduct";
import UserPage from "./pages/userpage/UserPage";
import Notfound from "./pages/notfound/Notfound";

import JobPost from "./pages/job-post/jobPost";
import PostAd from "./pages/public-advertisement/postAd";

import Login from "./components/modals/Login";
import JoinNow from "./components/modals/Joinnow";
import ConnectionPage from "./pages/connectionpage/ConnectionPage";
import BlockedUserPage from "./pages/blockeduserpage/BlockedUserPage";
import PendingList from "./pages/pendinglistpage/PendingList";
import GroupUsers from "./pages/groupusers/GroupUsers";
import ProductDetailed from "./pages/product-detail/ProductDetailed";
import { JobPage } from "./pages/job/Job";
import { JobDetailed } from "./pages/job-detail/JobDetailed";
import { ServicePage } from "./pages/service/Service";
import { ServiceDetailed } from "./pages/service-detail/ServiceDetailed";
import { SupplierDetailed } from "./pages/supplier-detail/SupplierDetailed";
import { SoftwarePage } from "./pages/software/Software";
import SoftwareDetailed from "./pages/software-detail/SoftwareDetailed";
import { AddSoftwarePage } from "./pages/addsoftware/AddSoftware";
import { AddServicesPage } from "./pages/addservices/AddServices";
import { ShowPage } from "./pages/showpage/Show";
import { SupplierPage } from "./pages/supplier/Supplier";
import ChatButton from "./components/chatbutton/ChatButton";
import { ChatPage } from "./pages/chatpage/ChatPage";
import { PostSponsoredContent } from "./pages/postsponsoredcontent/PostSponsoredContent";
import { Profile } from "./pages/profile/Profile";
import { SupportTicket } from "./pages/support-ticket/supportTicket";
import { ReportsList } from "./pages/reports-list/reportsList";
import ProfileShow from "./pages/profileshow/ProfileShow";
import { Premium } from "./pages/try-premium/premium";
import TransactionHistory from "./pages/transaction-history/TransactionHistory";
import ScrollToTop from "./../scrollTop";
import Verify from "./pages/verify/verify";
import Success from "./pages/verify/success";
import Error from "./pages/verify/error";
import Linkedin from "./pages/linkedin/linkedin";
import axios from "axios";
import resetPassword from "./pages/resetPassword/resetPassword";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

export class Views extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = async () => {
    try {
      const comon = await axios.get(
        `${process.env.REACT_APP_API_URL}common`, {
        headers: {
          Auth: `${localStorage.getItem("token")}`,
        },
      }
      );
      if (comon.status === 200) {
        window.sessionStorage.setItem("profileImage", comon.data?.ProfileImage)
        window.sessionStorage.setItem("chatcount", comon.data?.ChatCount)
        window.sessionStorage.setItem("nCount", comon.data?.nCount)
      };
    } catch (err) {

    }
  }
  componentDidUpdate = async () => {

    try {
      const comon = await axios.get(
        `${process.env.REACT_APP_API_URL}common`, {
        headers: {
          Auth: `${localStorage.getItem("token")}`,
        },
      }
      );
      if (comon.status === 200) {
        window.sessionStorage.setItem("profileImage", comon.data?.ProfileImage)
        window.sessionStorage.setItem("chatcount", comon.data?.ChatCount)
        window.sessionStorage.setItem("nCount", comon.data?.nCount)
      };
    } catch (err) {

    }
  }

  render() {
    return (
      <ScrollToTop>
        <Layout>
          <Switch>
            <Route path="/" exact>
              <Homepage />
            </Route>
            <Route path="/about-us">
              <Aboutus />
            </Route>
            <Route path="/support">
              <Support />
            </Route>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route path="/pricing">
              <Pricing />
            </Route>
            <Route path="/partners">
              <Partners />
            </Route>
            <Route path="/aboutus-login">
              <AboutusLogin />
            </Route>
            <Route path="/product" component={ProductPage} />
            <Route path="/product-service" component={AddProductPage} />
            <Route path="/my-circle">
              <UserPage />
            </Route>
            <Route path="/connections">
              <ConnectionPage />
            </Route>
            <Route path="/blocked-users">
              <BlockedUserPage />
            </Route>
            <Route path="/pending-users">
              <PendingList />
            </Route>
            <Route path="/groups">
              <GroupUsers />
            </Route>
            <Route path="/product-detail/:id" component={ProductDetailed} />
            <Route path="/job-detail/:id" component={JobDetailed} />
            <Route path="/job" component={JobPage} />
            <Route path="/job-service" component={JobPost} />
            <Route path="/publish-advertisement" component={PostAd} />
            <Route path="/service" component={ServicePage} />
            <Route path="/software" component={SoftwarePage} />
            <Route path="/software-service" component={AddSoftwarePage} />
            <Route path="/service-service" component={AddServicesPage} />
            <Route path="/showpage/:gid" component={ShowPage} />
            <Route path="/profileshow/:id" component={ProfileShow} />
            <Route path="/supplier" component={SupplierPage} />
            <Route path="/supplier-detail/:id" component={SupplierDetailed} />
            <Route path="/sponsored-service" component={PostSponsoredContent} />
            <Route path="/profile"><Profile /></Route>

            <Route path="/supportticket" component={SupportTicket} />
            <Route path="/reportslist" component={ReportsList} />
            <Route path="/transaction-history" component={TransactionHistory} />

            <Route path="/service-detail/:id" component={ServiceDetailed} />
            <Route path="/software-detail/:id" component={SoftwareDetailed} />
            <Route path="/try-premium/:type" component={Premium} />
            <Route path="/chatbutton" component={ChatButton} />
            <Route path="/chat" component={ChatPage} />
            <Route path="/verifying" component={Verify} />
            <Route path="/success" component={Success} />
            <Route path="/cancelled" component={Error} />
            <Route path="/resetPassword" component={resetPassword} />
            
            <Route exact path="/linkedin" component={LinkedInCallback} />
            <Route exact path="/Login">
              <Login />
            </Route>
            <Route exact path="/register">
              <JoinNow />
            </Route>
            <Route path="*" exact={true}>
              <Notfound />
            </Route>
          </Switch>
        </Layout>
      </ScrollToTop>
    );
  }
}

export default withRouter(Views);
