import "../chatbutton/ChatButton.css";
import "../modals/Popup.css";
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import axios from "axios";
import { withRouter } from "react-router-dom";
import ChatBottom from "../../../assets/chat-bottom.png";
import ButtonLoader from '../../components/loader/Loader'

export class ChatButton extends Component {
    constructor(props) {
        super(props);

        //dynamic validation for title field and content field
        this.validator = new SimpleReactValidator({
            messages: {
                required: "The :attribute required.",
                alpha: "The :attribute must be string.",
            },
        });

        this.myRef = React.createRef();

        this.state = {
            messageModal: false,
            fsearch: "",
            searchConnections: [],
            fields: "",
            dropdown: "",
            friendId: "",
            message: "",
            loggedUserName: "",
            loggedUserAvtar: "./images/no-image.png",

            searchTerm: "",

            isSelects: true,
            selectedOption: [],
            options: [],
            searchFriendId: "",
            allIdShow: [],
            isButtonLoding: false,
        }
    }

    handleInputField = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
        });
    }

    showMsgModal = async () => {

        const userData = localStorage.getItem("userData")

        const userDetails = JSON.parse(userData)
        const messageModal = this.state.messageModal === false ? true : false;
        await this.setState({
            messageModal: messageModal,
            loggedUserName: userDetails.userName,
            loggedUserAvtar: (localStorage.getItem('profileImage') ? localStorage.getItem('profileImage') : this.state.loggedUserAvtar)
        })
    }

    hideMsgModal = async () => {

        await this.setState({
            messageModal: false,
            selectedOption: [],
        })

        this.validator = new SimpleReactValidator({
            messages: {
                required: "The :attribute required.",
                alpha: "The :attribute must be string.",
            },
        });


        this.validator.hideMessages();
        this.forceUpdate();
    }

    handleChange = async (e) => {
        let isSelects = this.state.isSelects;

        await this.setState({
            isSelects: isSelects
        });
    };

    handleSelected = (selectedOption) => {

        this.setState({ ...selectedOption, selectedOption });
    };

    searchFriend = async (term) => {
        const data = {
            nskey: term,
        };

        const formres = await axios.post(
            `${process.env.REACT_APP_API_URL}message/friends/search`,
            data,
            {
                headers: {
                    Auth: `${localStorage.getItem("token")}`
                },
            }
        );
        

        if (formres.status === 200 && formres.data.status === true) {

            await this.setState({
                searchConnections: formres.data.datas,
                options: formres.data.datas.map((form) => {
                    return { value: form.id, label: `${form.username}` }
                })
            })
            // await this.searchField(this.state.searchConnections)
        }
    }

    searchField = async (e, username, id) => {

        await this.setState({
            fsearch: username,
            friendId: id
        })

        var elem = document.querySelector('.dropdown-container')

        elem.style.display = "none";
    }

    preventForm = (e) => {
        e.preventDefault()
    }

    // send message
    sendMessage = async () => {
        if (this.validator.allValid()) {
            await this.setState({
                isButtonLoding: true
            })
            const userData = localStorage.getItem("userData")
            const userDetails = JSON.parse(userData)

            const msgData = {
                "chatterIds": this.state.selectedOption.reduce((prev, curr) => {
                    return prev !== "" ? prev + "," + curr.value : prev + curr.value
                }, ""),
                "toIds": userDetails.userId,
                "chatterMessage": this.state.message,
            }

            const message = await axios.post(
                `${process.env.REACT_APP_API_URL}message/send-web`,
                msgData,
                {
                    headers: {
                        Auth: `${localStorage.getItem("token")}`,
                    },
                }
            );

            if (message.status === 200 && message.data.status === true) {

                toast.success(message.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else {

                toast.error(message.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            this.hideMsgModal()

            this.setState({
                message: "",
                fsearch: "",
                isButtonLoding: false
            })
            this.props.history.push('/chat')

            this.validator.hideMessages();
            this.forceUpdate();
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    shouldSearchTimer = null

    handleOnSearchInput = async (e) => {
        this.setState({ searchTerm: e })

        if (this.shouldSearchTimer) {
            clearTimeout(this.shouldSearchTimer)
        }

        this.shouldSearchTimer = setTimeout(() => {
            this.shouldSearchTimer = null

            if (e !== "")
                this.searchFriend(e)
        }, 500)
    }

    render() {
        return (
            <>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    className="toast-container"
                    toastClassName="dark-toast"
                />

                <section className="chat-button" id="chatButton">

                    <div className="chat-button-content">
                        <img src={ChatBottom} alt="ChatBottom"
                            onClick={this.showMsgModal}
                        />
                    </div>

                </section>

                {/* message-popup start */}
                <div className={"creategroup-modal-container sending-modal" + (this.state.messageModal ? " open" : "")}>
                    <div className="creategroup-modal send-message-modal profile-count-modal">

                        <button type="button" className="close-button-modal"
                            id="close-createMessage"
                            onClick={this.hideMsgModal}
                        >
                            <i className="fas fa-times"></i>
                        </button>

                        <div className="creategroup-header">
                            <h1 className="header">Send Message</h1>
                        </div>

                        <div className="creategroup-content login-content-fields sendmessage-content">

                            <div className="send-message-profile">
                                <div className="profile">
                                    <img src={this.state.loggedUserAvtar} alt="" />
                                </div>
                                <div className="profile-name">
                                    <h1 className="header">{this.state.loggedUserName}</h1>
                                </div>
                            </div>

                            <form action="" className="login sendmessage-form" onSubmit={(e) => this.preventForm(e)}>
                                <div>
                                    {this.state.isSelects ?
                                        (
                                            <>
                                                <Select
                                                    className="form-select option-multi-select"
                                                    styles={{
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 99999
                                                        })
                                                    }}
                                                    isMulti
                                                    placeholder="Search for connection.."
                                                    name="selectedOption"
                                                    inputValue={this.state.searchTerm}
                                                    onInputChange={this.handleOnSearchInput}
                                                    value={this.state.selectedOption}
                                                    onChange={this.handleSelected}
                                                    options={this.state.options}
                                                    menuPortalTarget={document.body}
                                                    menuPosition={'fixed'}
                                                />

                                            </>
                                        ) : ("")}
                                </div>

                            </form>

                            {this.validator.message(
                                "select field is",
                                this.state.selectedOption,
                                "required"
                            )}

                            <form action="" className="login sendmessage-form send-message-type" onSubmit={(e) => e.preventDefault()}>
                                <input type="text"
                                    className="groupname sendmessage"
                                    placeholder="Type your message ..."
                                    value={this.state.message}
                                    name="message"
                                    onChange={this.handleInputField}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.sendMessage()
                                        }
                                    }}
                                />

                                <button type="button"
                                    className="send"
                                    id="messageButton"
                                    onClick={this.sendMessage}
                                    disabled={this.state.isButtonLoding}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.sendMessage()
                                        }
                                    }}
                                >
                                    <h1>{this.state.isButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Send'}</h1>
                                </button>

                            </form>

                            {this.validator.message(
                                "Message field is",
                                this.state.message,
                                "required|string"
                            )}

                        </div>

                    </div>
                </div>
                {/* message-popup end */}
            </>
        )
    }
}

export default withRouter(ChatButton)