import "./ConnectionPage.css";
import "../userpage/UserPage.css";
import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ChatButton from "../../components/chatbutton/ChatButton";

import ConnectionLeftSideBar from "./connectionleftsidebar/ConnectionLeftSideBar";
import Loader from "../loader/Loader";
import ButtonLoader from '../../components/loader/Loader'

export class ConnectionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connections: [],
      isLoaded: false,
      isLoading: false,
      isButtonLoding: false,
      uid: 0,

      isConnectionLeftSideBar: false,
      openConnectionLeftSideBar: false,
    };
  }

  connectionsApi = async () => {

    try {
      const conn = await axios.get(
        `${process.env.REACT_APP_API_URL}connections/`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (conn.status === 200 && conn.data.status === true) {
        this.setState({
          connections: conn.data.datas,
          isLoaded: true,
          isLoading: false,
        });
      } else if (conn.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else {
        this.setState({
          isLoaded: true,
          isLoading: false,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  blockUser = async (blockId, blockType) => {
    const data = {
      fid: blockId,
      type: blockType,
    };
    await this.setState({
      uid: blockId,
      isButtonLoding: true
    })


    try {
      const blockData = await axios.post(
        `${process.env.REACT_APP_API_URL}block`,
        data,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (blockData.status === 200 && blockData.data.status === true) {
        await this.handleBlockData(this.state.connections);
        toast.success(blockData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        await this.handleBlockData(this.state.connections);
        toast.error(blockData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    await this.setState({
      uid: 0,
      isButtonLoding: false
    })
    this.connectionsApi();
  };

  handleBlockData = async (datas) => {
    await this.setState({
      blockListData: datas,
      connections: [],
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.blockListData !== this.state.blockListData) {
      await this.connectionsApi();
    }
  }

  componentDidMount = async () => {
    await this.handleResize();

    await this.setState({
      isLoading: true,
    })

    this.connectionsApi();
    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isConnectionLeftSideBar: true,
        openConnectionLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isConnectionLeftSideBar: false,
      });
    }
  }

  showConnectionLeftSideBar = async () => {
    if (this.state.openConnectionLeftSideBar) {
      await this.setState({
        openConnectionLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openConnectionLeftSideBar: true,
      });
    }
  };

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        <section className="user-profile-section">
          {(this.state.isConnectionLeftSideBar || this.state.openConnectionLeftSideBar) &&
            <ConnectionLeftSideBar />
          }

          <div className="user-profile-container">
            {(!this.state.openConnectionLeftSideBar && !this.state.isConnectionLeftSideBar) &&
              <div className="connection-hamburger-menu" id="mycircle-hamburger">
                <i class="fa fa-bars" aria-hidden="true" onClick={this.showConnectionLeftSideBar}></i>
              </div>
            }
            {(this.state.openConnectionLeftSideBar && !this.state.isConnectionLeftSideBar) &&
              <div className="connection-hamburger-menu" id="mycircle-hamburger">
                <i class="fa fa-times" aria-hidden="true" onClick={this.showConnectionLeftSideBar}></i>
              </div>
            }
            {this.state.isLoaded &&
              <div className="user-profile">
                {this.state.connections.length ? (
                  this.state.connections.map((connection, key) => (
                    <div className="user-profiles" key={key}>
                      <div className="user-profile-image ">
                        <img src={connection.pimage} alt="" />
                      </div>
                      <div className="user-profile-info">
                        <Link to={`/profileshow/${connection.id}`}>
                          <h2 className="header">{connection.username}</h2>
                        </Link>
                        <h3 className="text">{connection.title}</h3>
                        <h3 className="text">{connection.location}</h3>
                      </div>
                      <div className="user-profile-connect">
                        <button
                          type="button"
                          className="connect"
                          onClick={() => {
                            this.blockUser(connection.id, "block");
                          }}
                          disabled={this.state.isButtonLoding && this.state.uid === connection.id}
                        >
                          {this.state.isButtonLoding && this.state.uid === connection.id ? <ButtonLoader /> : 'Block'}
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="blocked">
                    <h2>There are no connection.</h2>
                  </div>
                )}
              </div>
            }
            {this.state.isLoading && <Loader />}
          </div>
        </section>

        <ChatButton />
      </>
    );
  }
}

export default withRouter(ConnectionPage);
