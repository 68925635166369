import "../software/Software.css";
import "../product/Product.css";
import React, { Component } from "react";
import axios from "axios";
import ShowMoreText from "react-show-more-text";
import { Link, withRouter } from "react-router-dom";
import ChatButton from "../../components/chatbutton/ChatButton";
import Loader from "../loader/Loader";
import queryString from "query-string";

export class SoftwarePage extends Component {
  constructor(props) {
    super(props);

    this.ProductsElement = React.createRef();

    this.state = {
      listing: [],
      page: 0,
      nextAvailable: true,
      perPage: 12,
      loading: false,
      isOther: false,
      searchKeyword: "",
      isSearchResult: false,

      isLoading: false,
      checkBoxChanged: false,
      isLoaded: false,
    };
  }

  softwareApi = async () => {

    if (this.state.nextAvailable === true) {
      await this.setState({
        loading: true,
        page: this.state.page + 1,
      });

      const softwareData = {
        page: this.state.page,
        records: this.state.perPage,
        isOther: this.state.isOther,
        keyword: this.state.searchKeyword,
      };
      
      try {
        var softwares;
        if (this.state.isSearchResult === true) {
          softwares = await axios.post(
            `${process.env.REACT_APP_API_URL}search-web`,
            {
              key: this.state.searchKeyword,
              page: this.state.page,
              records: this.state.perPage,
              type: 5,
            },
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
        } else {
          softwares = await axios.post(
            `${process.env.REACT_APP_API_URL}getallsoftwares`,
            softwareData,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
        }

        if (softwares.status === 200 && softwares.data.status === true) {
          
          this.setState({
            isLoaded:true,
          })

          if (softwares.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }

          let oldArray = this.state.listing;
          oldArray = oldArray.concat(softwares.data.datas);

          await this.setState({
            listing: oldArray,
          });
        } else{
          await this.setState({
            isLoaded:true,
          })
        }

        await this.setState({
          loading: false,
        });
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        }

        await this.setState({
          loading: false,
        });
      }
    }
  };

  componentDidMount = async () => {
    const queries = queryString.parse(this.props.location.search);

    if ("key" in queries && queries.key !== null && queries.key !== "") {
      await this.setState({
        isSearchResult: true,
        searchKeyword: queries.key,
        page: 0,
        nextAvailable: true,
      });
    }

    this.setState({
      loading: true,
    });

    await this.softwareApi();

    window.addEventListener("scroll", this.listenToScroll);
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.time !== this.props.location.time) {
      const queries = queryString.parse(this.props.location.search);

      if ("key" in queries && queries.key !== null && queries.key !== "") {
        await this.setState({
          isSearchResult: true,
          searchKeyword: queries.key,
          page: 0,
          nextAvailable: true,
          loading: true,
        });

        await this.softwareApi();
      }
    }
  }

  listenToScroll = async () => {
    if (this.state.loading === false) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight - 5) {
        await this.softwareApi();
      }
    }
  };

  setOthers = async (e) => {

    if(this.state.isLoading === false){
      await this.setState({
        loading: true,
        isLoading: true,
        isLoaded:false,
      });

      const isOther = this.state.isOther === false ? true : false;

        await this.setState({
          isOther: isOther,
          listing: [],
          page: 0,
          nextAvailable: true,
          loading: false,
          checkBoxChanged: false
        });

      await this.softwareApi();

      await this.setState({
        isLoading: false
      });
    }
  };

  render() {
    return (
      <>
        <section className="product-section">
          <div className="product-container softwares-container">
            <div className="product-suppliers softwares-check">
              <input
                type="checkbox"
                id="html"
                onChange={(e) => this.setOthers(e)}
              />
              <label htmlFor="html" className="suppliers-label">
                Others Software
              </label>
            </div>

            <div
              className="product-box-container software-box-container"
              ref={this.ProductsElement}
            >
              <div className="product-boxes software-boxes">
                <div className="product-header">
                  <h1 className="header">Software</h1>
                </div>

              {this.state.isLoaded &&
                <div className="boxes-container software-container">
                  {this.state.listing.length > 0 ? (
                    this.state.listing.map((lists, index) => (
                      <div
                        className="product-boxes-content software-boxes-content"
                        key={index}
                      >
                        <Link to={`/software-detail/${lists.id}`}>
                          <div className="product-image software-image">
                            <img src={lists.logo} alt="" />
                          </div>
                        </Link>

                        <div className="product-info software-info">
                          <div className="product-name">
                            <h2 className="header">{lists.name}</h2>
                          </div>
                          <div className="product-place software-content">
                            <h2 className="header grey-header truncate">
                              <ShowMoreText
                                /* Default options */
                                lines={2}
                                more="Read more"
                                less="Read less"
                                className="content-css"
                                anchorClass="my-anchor-css-class"
                                onClick={this.executeOnClick}
                                expanded={false}
                                truncatedEndingComponent={"... "}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: lists.description,
                                  }}
                                />
                              </ShowMoreText>
                            </h2>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h2>No Software Available</h2>
                  )}
                </div>
              }
              </div>
            </div>
            {this.state.loading && <Loader />}
          </div>
        </section>

        <ChatButton />
      </>
    );
  }
}

export default withRouter(SoftwarePage);
