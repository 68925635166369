import React, { Component } from "react";
import queryString from "query-string";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import successImage from "../../../assets/green-tick.png"
import failedImage from "../../../assets/no-cross.png"
import "./verify.css";

export class Verify extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            token:"",
            status:"",
        };
      }
    
    componentDidMount = async () => {

        const queries = queryString.parse(this.props.location.search);
    
        await this.setState({
            token:queries.token,
        })

        const verify = await axios.get(
            `${process.env.REACT_APP_API_URL}verify?token=${this.state.token}`
        );


        if (verify.status === 200 && verify.data.status === true) {
            this.setState({
                status:verify.status,
            });

            toast.success(verify.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }
        else {
            toast.error(verify.data.errors ? verify.data.errors : verify.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }


    }

  render() {
    return (
      <>

        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
            toastClassName="dark-toast"
        />

        <section className="success-section">
          <div className="success-section-header">
              {this.state.status ? 
              <>
                <img src={successImage} alt="successImage" />
                <h1>Your Account is Activated</h1>
                </>
                :
            <>
                <img src={failedImage} alt="failedImage" />
                <h1>No user found.</h1>
            </>
                }
            
            
          </div>
          
        </section>
      </>
    );
  }
}

export default Verify;
