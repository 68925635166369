import "../loader/Loader.css";
import React, { Component } from "react";

export class Loader extends Component {
  render() {
    return (
      <>
        <div className="loader-container">
          <div className="loader" />
        </div>
      </>
    );
  }
}

export default Loader;
