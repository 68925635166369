import "./Product.css";
import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import ProductLeftSidebar from "./productleftsidebar/ProductLeftSidebar";
import ContactSupplier from "../contactsupplier/ContactSupplier";
import ChatButton from "../../components/chatbutton/ChatButton";
import Loader from "../loader/Loader";
import queryString from "query-string";

import productEmail from "../../../assets/product-email.png";

export class ProductPage extends Component {
  constructor(props) {
    super(props);

    this.ProductsElement = React.createRef();

    this.state = {
      searchKeyword: "",
      searchLocation: "",
      searchCondition: "",
      filter: "",
      allProduct: [],
      locationData: [],
      categoryData: [],
      listing: [],
      allIds: [],
      idData: [],
      total: 0,
      page: 0,
      nextAvailable: true,
      perPage: 12,
      contactSupplierModel: false,
      loading: false,
      supplierId: 0,
      supplierName: "",

      userName: "",
      userEmail: "",
      userMobile: "",
      // productDetail: false,

      isSearchResult: false,
      isSearchKeyword: "",
      isReset: false,
      isLoaded: false,

      isProductLeftSideBar: false,
      openProductLeftSideBar: false,
    };
  }

  // set ternery condition on the create post modal for showing the modal
  showModal = async (id, sname) => {
    const contactSupplierModel =
      this.state.contactSupplierModel === false ? true : false;
    await this.setState({
      contactSupplierModel: contactSupplierModel,
      supplierId: id,
      supplierName: sname,
    });
  };

  hideContactModal = async () => {
    await this.setState({
      contactSupplierModel: false,
    });
  };

  productApi = async () => {

    if (this.state.nextAvailable) {
      
      await this.setState({
        loading: true,
        page: this.state.page + 1,
      });
      let productData = {
        page: this.state.page,
        records: this.state.perPage,
        fptype: "product",
        searchField: this.state.filter,
        keyword: this.state.searchKeyword,
        location: this.state.searchLocation,
        productCondition: this.state.searchCondition,
      };

      try {
        var products
        if (this.state.isSearchResult === true) {
          
          products = await axios.post(
            `${process.env.REACT_APP_API_URL}search-web`,
            {
              key: this.state.isSearchKeyword,
              page: this.state.page,
              records: this.state.perPage,
              type: 3,
            },
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
        } else {
          
          products = await axios.post(
            `${process.env.REACT_APP_API_URL}getallproducts`,
            productData,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
        }

        if (products.status === 200 && products.data.status === true) {

          this.setState({
            isLoaded: true,
          })

          if (products.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }

          let oldArray = this.state.listing;
          oldArray = (this.state.page === 1) ? products.data.datas : oldArray.concat(products.data.datas);

          await this.setState({
            allProduct: oldArray,
            allIds: oldArray,
            total: products.data.datas.length === 0 ? products.data.datas.length :products.data.total
          });

          const allProducts = [];
          const allcategory = [];

          this.state.allProduct.forEach((data) => {
            allProducts.push(data.location);
          });

          let unique = [...new Set(allProducts)];

          this.state.allProduct.forEach((data) => {
            allcategory.push(data.category);
          });

          let uniqueCategory = [...new Set(allcategory)];

          await this.setState({
            locationData: unique,
            categoryData: uniqueCategory,
          });

          await this.setState({
            listing: oldArray,
          });
        } else {
          await this.setState({
            isLoaded: true,
          })
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        }
      }

      await this.setState({
        loading: false,
      });
    }
  };

  componentDidMount = async () => {

    await this.handleResize();
    const queries = queryString.parse(this.props.location.search);

    if ("key" in queries && queries.key !== null && queries.key !== "") {
      await this.setState({
        isSearchResult: true,
        isSearchKeyword: queries.key,
        page: 0,
        nextAvailable: true,
      });
    }

    const userData = localStorage.getItem("userData");
    const userDetails = JSON.parse(userData);
    this.setState({
      userName: userDetails.userName,
      userEmail: userDetails.userEmail,
      userMobile: userDetails.userMobile,
    });

    this.setState({
      loading: true,
    });

    await this.productApi();

    window.addEventListener("scroll", this.listenToScroll);

    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isProductLeftSideBar: true,
        openProductLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isProductLeftSideBar: false,
      });
    }
  };

  listenToScroll = async () => {
    if (this.state.loading === false) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight - 5) {
        await this.productApi();
      }
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.time !== this.props.location.time) {
      const queries = queryString.parse(this.props.location.search);

      if ("key" in queries && queries.key !== null && queries.key !== "") {
        await this.setState({
          isSearchResult: true,
          isSearchKeyword: queries.key,
          page: 0,
          nextAvailable: true,
          loading: true,
        });

        await this.productApi();
      }
    }
    if (prevState.searchKeyword !== this.state.searchKeyword) {
      await this.productApi();

      await this.setState({
        loading: false,
        isSearchResult: false,
        page: 0,
        nextAvailable: true,
      });
    }

    if (prevState.searchLocation !== this.state.searchLocation) {
      await this.productApi();

      await this.setState({
        loading: false,
        isSearchResult: false,
        page: 0,
        nextAvailable: true,
      });
    }

    if (prevState.searchCondition !== this.state.searchCondition) {
      await this.productApi();

      await this.setState({
        loading: false,
        isSearchResult: false,
        page: 0,
        nextAvailable: true,
      });
    }

    if (prevState.filter !== this.state.filter) {
      await this.productApi();

      await this.setState({
        loading: false,
        isSearchResult: false,
        page: 0,
        nextAvailable: true,
      });
    }
  }

  handleCallback = async (childData) => {
    this.setState({
      isLoaded: false,
    })

    if (childData !== "") {
      this.setState({
        isReset: true,
      })
    } else {
      this.setState({
        isReset: false,
      })
    }

    await this.setState({ nextAvailable: false });
    window.screenTop = 0;
    await this.setState({
      searchKeyword: childData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
      total:0,
    });
  };

  handleLocation = async (optionData) => {

    this.setState({
      isLoaded: false,
    })

    if (optionData !== "") {
      this.setState({
        isReset: true,
      })
    } else {
      this.setState({
        isReset: false,
      })
    }

    if (optionData === "all") {
      await this.myreset();
      return;
    }

    await this.setState({ nextAvailable: false });
    window.screenTop = 0;
    await this.setState({
      searchLocation: optionData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
      total:0,
    });
  };

  handleCondition = async (conditionData) => {

    this.setState({
      isLoaded: false,
    })

    if (conditionData !== "") {
      this.setState({
        isReset: true,
      })
    } else {
      this.setState({
        isReset: false,
      })
    }

    await this.setState({ nextAvailable: false });
    window.screenTop = 0;
    await this.setState({
      searchCondition: conditionData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  handleFilter = async (filterData) => {

    this.setState({
      isLoaded: false,
    })

    if (filterData !== "") {
      this.setState({
        isReset: true,
      })
    } else {
      this.setState({
        isReset: false,
      })
    }
    await this.setState({ nextAvailable: false });
    window.screenTop = 0;
    await this.setState({
      filter: filterData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  goToSuppliers = async () => {
    setTimeout(() => {
      this.props.history.push("/supplier");
    }, 500);
  };

  myreset = async () => {

    if (this.state.isReset) {
      window.screenTop = 0;
      await this.setState({
        searchCondition: "",
        filter: "",
        searchKeyword: "",
        searchLocation: "",
        nextAvailable: true,
        loading: false,
        page: 0,
        listing: [],
        isReset: false,
        isLoaded: false,

      });

      await this.productApi();

    }

  }

  showProductLeftSideBar = async () => {
    if (this.state.openProductLeftSideBar) {
      await this.setState({
        openProductLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openProductLeftSideBar: true,
      });
    }
  };

  render() {
    return (
      <>
        {this.state.contactSupplierModel ? (
          <ContactSupplier
            hideModal={this.hideContactModal}
            supplierRecipient={this.state.supplierName}
            supplierId={this.state.supplierId}
            senderName={this.state.userName}
            senderEmail={this.state.userEmail}
            senderPhone={this.state.userMobile}
          />
        ) : (
          ""
        )}

        <section className="product-section">
          {(this.state.isProductLeftSideBar || this.state.openProductLeftSideBar) &&
            <ProductLeftSidebar
              myreset={this.myreset}
              parentCallback={this.handleCallback}
              locationShow={this.state.locationData}
              categoryShow={this.state.categoryData}
              locationCallback={this.handleLocation}
              conditionCallback={this.handleCondition}
              filterCallback={this.handleFilter}
              isReset={this.state.isReset}
            />
          }

          <div className="product-container">
            {(!this.state.openProductLeftSideBar && !this.state.isProductLeftSideBar) &&
              <div className="Product-hamburger-menu" id="listing-hamburger">
                <i class="fa fa-bars" aria-hidden="true" onClick={this.showProductLeftSideBar}></i>
              </div>
            }
            {(this.state.openProductLeftSideBar && !this.state.isProductLeftSideBar) &&
              <div className="Product-hamburger-menu" id="listing-hamburger">
                <i class="fa fa-times" aria-hidden="true" onClick={this.showProductLeftSideBar}></i>
              </div>
            }
            <div className="product-suppliers">
              <input type="checkbox" id="html" />
              <label
                htmlFor="html"
                className="suppliers-label"
                onClick={this.goToSuppliers}
              >
                Suppliers
              </label>
            </div>

            <div className="product-box-container" ref={this.ProductsElement}>
              <div className="product-boxes">

                <div className="product-header">
                  <h1 className="header">Products</h1>
                  {this.state.isLoaded &&
                    <h2>{this.state.total} Product(s) found</h2>
                  }
                </div>


                {this.state.isLoaded &&
                  <div className="boxes-container">
                    {this.state.listing.length > 0 ? (
                      this.state.listing.map((lists, index) => (
                        <div className="product-boxes-content" key={index}>
                          <div className="product-email">
                            <button
                              type="button"
                              className="product-email-button"
                              onClick={() =>
                                this.showModal(lists.uid, lists.sname)
                              }
                            >
                              <img src={productEmail} alt="productEmail" />
                            </button>
                          </div>

                          <Link to={{
                            pathname: `/product-detail/${lists.id}`,
                            state: lists.uid
                          }}>
                            <div className="product-image">
                              <img src={lists.image} alt="" />
                            </div>
                          </Link>

                          <div className="product-info">
                            <div className="product-name">
                              <h2 className="header">{lists.name}</h2>
                            </div>
                            <div className="product-place">
                              <h2 className="header grey-header">
                                {lists.sname}
                              </h2>
                            </div>
                            <div className="product-address">
                              <h2 className="header grey-header">
                                {lists.location}
                              </h2>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <h2>No Product Available</h2>
                    )}
                  </div>
                }
              </div>
            </div>
            {this.state.loading && <Loader />}
          </div>
        </section>

        <ChatButton />
      </>
    );
  }
}

export default withRouter(ProductPage);
