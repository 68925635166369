import React, { Component } from "react";
import "./Partners.css";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";

export class Partners extends Component {
  constructor(props) {
    super(props);

    //dynamic validation for title field and content field
    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.state = {
      inviteFriends: false,
      supplierRecipient: "sales@pexit.net",
      name: "",
      email: "",
      country: "",
      sstate: "",
      city: "",
      subject: "PEXit Partner Program",
      message: `Hi,
            <br><br>
            Wish to join in PEXit Partner Program. Please send me the details.
            <br><br>
            Regards,            
            `,
      supplierId: "",
      isLoading: false
    };
  }

  inviteFriendsApi = async () => {
    if (this.validator.allValid()) {

      if (this.state.isLoading === false) {

        await this.setState({
          isLoading: true,
        })
        const supplierData = {
          name: this.state.name,
          email: this.state.email,
          subject: this.state.subject,
          rEmail: this.state.supplierRecipient,
          mailContent: this.state.message,
          country: this.state.country,
          state: this.state.sstate,
          city: this.state.city,
        };

        try {
          const formres = await axios.post(
            `${process.env.REACT_APP_API_URL}partners`,
            supplierData,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );

          if (formres.status === 200) {
            if (formres.data.status === true)
              toast.success(formres.data.message, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

            await this.setState({
              supplierRecipient: "sales@pexit.net",
              name: "",
              email: "",
              country: "",
              sstate: "",
              city: "",
              subject: "PEXit Partner Program",
            });
          } else {
            toast.error(formres.data.errors, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } catch (err) {
          if (err?.response?.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(err?.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }

        setTimeout(async () => {
          await this.setState({
            isLoading: false,
          })
        }, 5000)
      }

      this.validator.hideMessages();
      this.forceUpdate();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInputChange = async (e) => {
    if (e.target?.name && e.target?.value) {
      await this.setState({
        [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
      });
    }
  };

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />
        <section className={`about-section ${(localStorage.getItem('token') ? 'after-login-section' : '')}`}>
          <div className="about-section-header">
            <h1 className="header">
              PEXit<sup>TM </sup> Partner Program
            </h1>
          </div>
          <div className="about-section-content">
            <p className="text">Make easy money with PEXit.</p>
            <p className="text">
              Become a partner, promote PEXit and receive incentive; also, earn
              through PEXit services. For details, complete the form below and
              press "Send".
            </p>

            <div className="creategroup-content login-content-fields invite-friends-modal">
              <form action="" className="login">
                <div className="contact-container partner-container">
                  <label htmlFor="name" className="name">
                    Your Name
                  </label>

                  <div className="input_div">
                    <input
                      type="text"
                      className="groupname"
                      placeholder="First Name and Last Name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                    />
                    {this.validator.message(
                      "Name is",
                      this.state.name,
                      "required|string"
                    )}
                  </div>
                </div>



                <div className="contact-container partner-container">
                  <label htmlFor="email" className="name">
                    Your Email
                  </label>

                  <div className="input_div">
                    <input
                      type="text"
                      className="groupname"
                      placeholder="Your Email Id"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />

                    {this.validator.message(
                      "Email is",
                      this.state.email,
                      "required|email"
                    )}
                  </div>
                </div>



                <div className="contact-container partner-container">
                  <label htmlFor="country" className="name">
                    Country
                  </label>

                  <div className="input_div">
                    <input
                      type="text"
                      className="groupname"
                      placeholder="Your Country"
                      name="country"
                      value={this.state.country}
                      onChange={this.handleInputChange}
                    />

                    {this.validator.message(
                      "Country is",
                      this.state.country,
                      "required|string"
                    )}
                  </div>
                </div>



                <div className="contact-container partner-container">
                  <label htmlFor="sstate" className="name">
                    State
                  </label>

                  <div className="input_div">
                    <input
                      type="text"
                      className="groupname"
                      placeholder="Your State"
                      name="sstate"
                      value={this.state.sstate}
                      onChange={this.handleInputChange}
                    />

                    {this.validator.message(
                      "State is",
                      this.state.sstate,
                      "required|string"
                    )}
                  </div>
                </div>



                <div className="contact-container partner-container">
                  <label htmlFor="city" className="name">
                    City
                  </label>

                  <div className="input_div">
                    <input
                      type="text"
                      className="groupname"
                      placeholder="Your City"
                      name="city"
                      value={this.state.city}
                      onChange={this.handleInputChange}
                    />
                    {this.validator.message(
                      "City is",
                      this.state.city,
                      "required|string"
                    )}
                  </div>
                </div>

                <div className="contact-container partner-container">
                  <label htmlFor="subject" className="name">
                    Subject
                  </label>

                  <div className="input_div">
                    <input
                      type="text"
                      className="groupname"
                      placeholder="Subject"
                      name="subject"
                      value={this.state.subject}
                      onChange={this.handleInputChange}
                    />

                    {this.validator.message(
                      "Subject field is",
                      this.state.subject,
                      "required|string"
                    )}
                  </div>
                </div>



                <div className="contact-container partner-container">
                  <label htmlFor="supplierRecipient" className="name">
                    Recipient Email
                  </label>

                  <div className="input_div">
                    <input
                      type="text"
                      className="groupname"
                      name="supplierRecipient"
                      value={this.state.supplierRecipient}
                      disabled
                      readOnly
                    />
                    {this.validator.message(
                      "Email field is",
                      this.state.supplierRecipient,
                      "required|email"
                    )}
                  </div>
                </div>

                <div className="contact-container partner-container" id="partner-message">
                  <label htmlFor="message" className="name">
                    Message
                  </label>

                  <div className="input_div" id="partnersinput">
                    <Editor
                      apiKey="textarea"
                      name="message"
                      value={this.state.message}
                      init={{
                        height: 200,
                        menubar: false,
                      }}
                      onEditorChange={(e) => {
                        this.setState({
                          message: (e.replace(/^\s+/g, '') === '<p>&nbsp;&nbsp;</p>') ? '' : e.replace(/^\s+/g, '')
                        })
                      }}
                    />

                    {this.validator.message(
                      "Message field is",
                      this.state.message,
                      "required|string"
                    )}
                  </div>
                </div>


                <button
                  type="button"
                  className="login-button creategroup-button invite-button partner_btn"
                  id="mySupplier"
                  onClick={this.inviteFriendsApi}
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Partners;
