import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import "../job/Job.css";
import "../job-detail/JobDetailed.css";
import ChatButton from "../../components/chatbutton/ChatButton";
import JobDetail from "../../../assets/job-detail.png";
import { JobApply } from "../job-apply/JobApply";
import Parser from "html-react-parser";
import { Report } from "../report/Report";
import caepipeIcon from "../../../assets/caepipe-icon.png";
import moment from "moment";


export class JobDetailed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jobApplyModel: false,
      details: {},
      ispageShow: false,
      relatedDetails: [],
      reportModal: false,
      serialId: "",
      reportModule: "",
    };
  }

  // set ternery condition on the create post modal for showing the modal
  showModal = async () => {
    const jobApplyModel = this.state.jobApplyModel === false ? true : false;
    await this.setState({
      jobApplyModel: jobApplyModel,
    });
  };

  hideContactModal = async () => {
    await this.setState({
      jobApplyModel: false,
    });
  };

  reportModal = async (software) => {
    const reportModal = this.state.reportModal === false ? true : false;
    await this.setState({
      reportModal: reportModal,
      reportModule: software,
    });
  };

  hideReportModal = async () => {
    await this.setState({
      reportModal: false,
    });
  };

  reldatedJobs = async () => {
    const id = this.props.match.params.id;

    try {
      const jobs = await axios.get(
        `${process.env.REACT_APP_API_URL}job/view/${id}`,

        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (jobs.status === 200 && jobs.data.status === true) {

        this.setState({
          details: jobs.data.datas,
          ispageShow: true,
          serialId: id,
        });
      } else {
        this.props.history.goBack();
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }
    }

    // related jobs
    const relatedData = {
      cmpny: this.state.details["cmpny"],
      addr: this.state.details["addr"],
      country: this.state.details["country"],
      emptyp: this.state.details["emptyp"],
      indus: this.state.details["indus"],
      level: this.state.details["level"],
      states: this.state.details["states"],
      title: this.state.details["title"],
    };

    try {
      const relatedJobs = await axios.post(
        `${process.env.REACT_APP_API_URL}job/getrelatedjobs`,
        relatedData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (relatedJobs.status === 200 && relatedJobs.data.status === true) {
        this.setState({
          relatedDetails: relatedJobs.data.datas,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }
    }
  }

  componentDidMount = async () => {
    await this.reldatedJobs();
  };

  async componentDidUpdate(prevProps, prevState) {

    if (this.props.match.params.id !== prevProps.match.params.id) {
      await this.reldatedJobs();
    }
  }

  render() {
    const softUid = Object.keys(this.state.details).length
      ? this.state.details.uid
      : "";

    return (
      <>
        {this.state.jobApplyModel ? (
          <JobApply
            hideModal={this.hideContactModal}
            jid={this.state.details.id}
          />
        ) : (
          ""
        )}

        {this.state.reportModal ? (
          <Report
            hideReportModal={this.hideReportModal}
            softwareId={this.state.serialId}
            softwareUid={softUid}
            module={this.state.reportModule}
          />
        ) : (
          ""
        )}

        {this.state.ispageShow ? (
          <section className="detailed-software">
            <div className="detailed-container">
              <div className="report-button"
                onClick={() => this.reportModal("job")}
              >
                <button type="button" className="caepipe-download">
                  <i className="fas fa-chevron-down" />
                </button>
                <div className="report-container">
                  <button
                    type="button"
                    className="report-block"

                  >
                    <h2 className="header">Report Abuse</h2>
                  </button>
                </div>
              </div>

              <div className="download-button">
                {
                  (this.state.details?.website.trim() === '') ? (
                    <button
                      type="button"
                      className="caepipe-download"
                      onClick={this.showModal}
                    >
                      <img src={JobDetail} alt="JobDetail" />
                    </button>
                  ) : (
                    <a
                      type="button"
                      className="caepipe-download"
                      href={this.state.details?.website}
                      target="_blank"
                    >
                      <img src={JobDetail} alt="JobDetail" />
                    </a>
                  )
                }

              </div>

              <div className="detail-header">
                <h1 className="header">{this.state.details.title}</h1>
              </div>

              <div className="content-container content-container-part">
                <div className="caepipe-image">
                  <img src={this.state.details.logo} alt="" />
                </div>

                <div className="caepipe-info">
                  <div className="caepipe-location service-location">
                    <div className="location-icon">
                      <h1 className="header">{this.state.details.cmpny}</h1>
                      <div className="icon-content service-icon-content">
                        <h2 className="grey-header">
                          {this.state.details.addr +
                            ", " +
                            this.state.details.states +
                            ", " +
                            this.state.details.country}
                        </h2>
                      </div>
                    </div>
                    <Link to={`/supplier-detail/${this.state.details.uid}`}>
                      <button type="button" className="icon-button">
                        <img src={caepipeIcon} alt="caepipeIcon" />
                      </button>
                    </Link>
                  </div>

                  <div className="caepipe-location service-location service-location-two">
                    <div className="location-icon">
                      <h1 className="header">Industry</h1>
                      <h2 className="grey-header grey-headers">
                        {this.state.details.indus}
                      </h2>
                    </div>
                  </div>

                  <div className="caepipe-location service-location">
                    <div className="location-icon">
                      <h1 className="header">Employment Type</h1>
                      <h2 className="grey-header grey-headers">
                        {this.state.details.emptyp}
                      </h2>
                    </div>
                  </div>

                  <div className="caepipe-location service-location">
                    <div className="location-icon">
                      <h1 className="header">Experience</h1>
                      <h2 className="grey-header grey-headers">
                        {this.state.details.level}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-description">
                <div className="service-description-content">
                  <div className="service-header">
                    <h1 className="header">Job Description</h1>
                  </div>
                  <div className="month-time">
                    <h1 className="grey-header">({moment.utc(this.state.details?.postDate).fromNow()})</h1>
                  </div>
                </div>

                <div className="caepipe-forum caepipe-forum-part">
                  <h2 className="header truncate jobDesc">
                    {/* <ShowMoreText
                      lines={4}
                      more="Read more"
                      less="Read less"
                      className="content-css"
                      anchorClass="my-anchor-css-class"
                      onClick={this.executeOnClick}
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    > */}
                      {/* {Parser(this.state.details.jdesc)} */}
                      
                      <span dangerouslySetInnerHTML={{ __html: this.state?.details.jdesc.replaceAll('&amp;', '&').replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&quot;', '"').replaceAll('&apos;', "'") }} />
                    {/* </ShowMoreText> */}
                  </h2>
                </div>
              </div>

              <div className="sky-tabs sky-tabs-pos-top-left sky-tabs-anim-slide-right sky-tabs-response-to-icons">
                <input
                  type="radio"
                  name="sky-tabs"
                  defaultChecked
                  id="sky-tab1"
                  className="sky-tab-content-1"
                  />
                <label htmlFor="sky-tab1">
                  <span>
                    <span>Company Description</span>
                  </span>
                </label>

             
               
               <div id="showJobApply" onClick={this.showModal}>
                Apply Now
              </div>

                <ul>
                  <li className="sky-tab-content-1">
                    <div className="typography">
                      <div className="slider-box-container active ">
                        <div className="related-description" id="description">
                          <div className="content1-header">
                            <h2 className="header">Description</h2>
                          </div>
                          <div className="content1-para">
                            <h2 className="grey-header truncate jobDesc">
                              {/* <ShowMoreText
                                lines={2}
                                more="Read more"
                                less="Read less"
                                className="content-css"
                                anchorClass="my-anchor-css-class"
                                onClick={this.executeOnClick}
                                expanded={false}
                                truncatedEndingComponent={"... "}
                              > */}
                              <span dangerouslySetInnerHTML={{ __html: this.state?.details.cmpnydesc.replaceAll('&amp;', '&').replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&quot;', '"').replaceAll('&apos;', "'")}} />
                              
                                {/* {this.state.details.cmpnydesc} */}
                              {/* </ShowMoreText> */}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                 
                </ul>
              </div>



              <div className="related-software-container">
                <div className="container-header">
                  <h1 className="header">Related Jobs</h1>
                </div>

                <div className="related-container">
                  <div className="related-content related-jobs-container">
                    {this.state.relatedDetails.map((details) => (
                      <Link to={`/job-detail/${details.id}`}>
                        <div className="product-boxes-content software-boxes-content">
                          <div className="product-image related-image related-jobs-image">
                            <img src={details.logo} alt="" />
                          </div>
                          <div className="product-info software-info">
                            <div className="product-name">
                              <h2 className="header">{details.title}</h2>
                            </div>
                            <div className="product-place software-content related-jobs-content">
                              <h2 className="header grey-header">
                                {details.cmpny}
                              </h2>
                            </div>
                            <div className="product-place software-content related-jobs-content">
                              <h2 className="header grey-header">
                                {details.addr +
                                  ", " +
                                  details.states +
                                  ", " +
                                  details.country}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}

        <ChatButton />
      </>
    );
  }
}

export default withRouter(JobDetailed);
