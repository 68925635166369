import "../software/Software.css";
import "../product/Product.css";
import "../addsoftware/AddSoftware.css";
import "./editprofile.css";

import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import {Dropdown} from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";

import Files from "../../../assets/files-icons/files-popup.png";
import CameraIcon from "../../../assets/files-icons/photos-popup.png";
import VideoIcon from "../../../assets/files-icons/videos-popup.png";
import AddLink from "../../../assets/files-icons/links-popup.png";
import Youtube from "../../../assets/files-icons/youtube-pupup.png";
import * as mime from "react-native-mime-types";
import { saveAs } from "file-saver";
import Loader from "../loader/Loader";
import ButtonLoader from '../../components/loader/Loader'
import { cssNumber } from "jquery";

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.profilevalidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleLinks: {
          // name the rule
          message: "The :attribute must be a valid links",
          rule: (val, params, validator) => {
            var reg = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        multipleYoutubeLinks: {
          // name the rule
          message: "The :attribute must be a valid youtube links",
          rule: (val, params, validator) => {
            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        PostFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, Invalid file types are " +
            process.env.REACT_APP_POST_FILES.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let retfiles = true;
            let arfiles = process.env.REACT_APP_POST_FILES.split(",");
            for (let i = 0; i < files.length; i++) {
              if (arfiles.includes(files[i].name.split(".").pop()) !== false) {
                retfiles = false;
              }
            }
            return retfiles;
          },
        },
        Photos: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_PHOTOS.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_PHOTOS.split(",");
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              }
            }
            return ret;
          },
        },
        VideoFiles: {
          // name the rule 
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_VIDEOS.split(","),
          rule: (val, params, validator) => {
            let Videofiles = val.files ?? [];
            let Videoret = true;
            let ar = process.env.REACT_APP_POST_VIDEOS.split(",");
            for (let i = 0; i < Videofiles.length; i++) {
              if (ar.includes(mime.extension(Videofiles[i].type)) === false) {
                Videoret = false;
              }
            }
            return Videoret;
          },
        },
      },
    });

    this.UpdateEducation = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleLinks: {
          // name the rule
          message: "The :attribute must be a valid links",
          rule: (val, params, validator) => {
            var reg = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        multipleYoutubeLinks: {
          // name the rule
          message: "The :attribute must be a valid youtube links",
          rule: (val, params, validator) => {
            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        PostFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, Invalid file types are " +
            process.env.REACT_APP_POST_FILES.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let retfiles = true;
            let arfiles = process.env.REACT_APP_POST_FILES.split(",");
            for (let i = 0; i < files.length; i++) {
              if (arfiles.includes(files[i].name.split(".").pop()) !== false) {
                retfiles = false;
              }
            }
            return retfiles;
          },
        },
        Photos: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_PHOTOS.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_PHOTOS.split(",");
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              }
            }
            return ret;
          },
        },
        VideoFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_VIDEOS.split(","),
          rule: (val, params, validator) => {
            let Videofiles = val.files ?? [];
            let Videoret = true;
            let ar = process.env.REACT_APP_POST_VIDEOS.split(",");
            for (let i = 0; i < Videofiles.length; i++) {
              if (ar.includes(mime.extension(Videofiles[i].type)) === false) {
                Videoret = false;
              }
            }
            return Videoret;
          },
        },
      },
    });

    this.UpdateExperience = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleLinks: {
          // name the rule
          message: "The :attribute must be a valid links",
          rule: (val, params, validator) => {
            var reg = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        multipleYoutubeLinks: {
          // name the rule
          message: "The :attribute must be a valid youtube links",
          rule: (val, params, validator) => {
            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        PostFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, Invalid file types are " +
            process.env.REACT_APP_POST_FILES.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let retfiles = true;
            let arfiles = process.env.REACT_APP_POST_FILES.split(",");
            for (let i = 0; i < files.length; i++) {
              if (arfiles.includes(files[i].name.split(".").pop()) !== false) {
                retfiles = false;
              }
            }
            return retfiles;
          },
        },
        Photos: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_PHOTOS.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_PHOTOS.split(",");
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              }
            }
            return ret;
          },
        },
        VideoFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_VIDEOS.split(","),
          rule: (val, params, validator) => {
            let Videofiles = val.files ?? [];
            let Videoret = true;
            let ar = process.env.REACT_APP_POST_VIDEOS.split(",");
            for (let i = 0; i < Videofiles.length; i++) {
              if (ar.includes(mime.extension(Videofiles[i].type)) === false) {
                Videoret = false;
              }
            }
            return Videoret;
          },
        },
      },
    });

    this.Updatehonors = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleLinks: {
          // name the rule
          message: "The :attribute must be a valid links",
          rule: (val, params, validator) => {
            var reg = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        multipleYoutubeLinks: {
          // name the rule
          message: "The :attribute must be a valid youtube links",
          rule: (val, params, validator) => {
            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        PostFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, Invalid file types are " +
            process.env.REACT_APP_POST_FILES.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let retfiles = true;
            let arfiles = process.env.REACT_APP_POST_FILES.split(",");
            for (let i = 0; i < files.length; i++) {
              if (arfiles.includes(files[i].name.split(".").pop()) !== false) {
                retfiles = false;
              }
            }
            return retfiles;
          },
        },
        Photos: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_PHOTOS.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_PHOTOS.split(",");
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              }
            }
            return ret;
          },
        },
        VideoFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_VIDEOS.split(","),
          rule: (val, params, validator) => {
            let Videofiles = val.files ?? [];
            let Videoret = true;
            let ar = process.env.REACT_APP_POST_VIDEOS.split(",");
            for (let i = 0; i < Videofiles.length; i++) {
              if (ar.includes(mime.extension(Videofiles[i].type)) === false) {
                Videoret = false;
              }
            }
            return Videoret;
          },
        },
      },
    });

    this.UpdatePofile = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        ProfileFile: {
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_PROFILE_FILES.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let retfiles = true;
            let arfiles = process.env.REACT_APP_PROFILE_FILES.split(",");
            for (let i = 0; i < files.length; i++) {
              if (arfiles.includes(files[i].name.split(".").pop()) === false) {
                retfiles = false;
              }
            }
            return retfiles;
          },
        },
      },
    });

    this.personalDetails = React.createRef();
    this.ProfileElement = React.createRef();
    this.pimage = React.createRef();
    this.checkbox = React.createRef();

    this.pfle = React.createRef();
    this.pphot = React.createRef();
    this.svideo = React.createRef();
    this.plnk = React.createRef();
    this.pvdeo = React.createRef();
    this.mediaForm = React.createRef();

    this.pexpfle = React.createRef();
    this.pexpphot = React.createRef();
    this.pexpprest = React.createRef();
    this.pexplnk = React.createRef();
    this.pexpvdeo = React.createRef();

    this.editExpRef = React.createRef();
    this.newHon =  React.createRef();
    this.newEdu =  React.createRef();
    this.newExp =  React.createRef();
    this.newProfile = React.createRef();

    this.state = {
      education: [],
      newEdu: true,
      tmpeducation: {
        pedupid: 0,
        pedupuid: 0,
        pedupschoolname: "",
        peduschoolfromyear: "",
        peduschooltoyear: "",
        peduschoolDegree: "",
        peduschoolODegree: false,
        peduschool_act: "",
        peduexp_desc: "",
        peduschoolstudy: "",
        peduschoolgrade: "",
        peduschoolograde: "",
        ppedufle: "",
        peduphot: "",
        pedulnk: "",
        peduvdeo: "",
        peduprest: "",
      },
      experience: [],
      newExp: true,
      tmpexperience: {
        pexpid: 0,
        pexptitle: "",
        pexpcompanyname: "",
        pexplocation: "",
        pexpfrom_mon: "",
        pexpfrom_yr: "",
        pexpto_mon: "",
        pexpto_yr: "",
        pexpiscurrent: false,
        pexpdes: "",
        pexpuid: 0,
        pexpfle: "",
        pexpphot: "",
        pexplnk: "",
        pexpvdeo: "",
        pexpprest: "",
      },
      honors: [],
      newHon: true,
      tmphonor: {
        phondesc: "",
        phonfle: "",
        phonlnk: "",
        phonmonth: "",
        phonphot: "",
        phonprest: "",
        phontitle: "",
        phonvdeo: "",
        phonyear: "",
      },
      profileDetails: {
        uid: 0,
        pid: 0,

        uname: "",
        ptitle: "",
        puserType: "",

        uemail: "",
        pphone: "",
        pcode: "",

        paddress: "",
        pcountry: "",
        puserstate: "",
        plocation: "",
        ppostalcode: "",

        pdob: "0000-00-00",
        pgender: "",

        psummary: "",

        pfle: "",
        pimage: "",
        plnk: "",
        pvdeo: "",
        pphot: "",
        pprest: "",
      },

      profileDetail: "",

      countries: [],
      states: [],
      cities: [],

      years: [],
      isShowMedia: "",

      date: "",
      month: "",
      year: "",

      codesOption: [],

      tmpProfile: {
        comfle: "",
        prfcunt: "",
        prfstat: "",
        prfcit: "",
        comid: 0,
        comuid: 0
      },
      
      profile_contries: [],
      profile_states: [],
      profile_cities: [],

      loading:false,
      isCoLoading:false,
      isDelCoLoading:false,

      isHonorLoading:false,
      isDelHonorLoading:false,

      isExpLoading:false,
      isDelExpLoading:false,

      isEduLoading:false,
      isDelEduLoading:false,

      width:"300",
      allCountriesCodes:[],
      expChecked: true,

      delExp: false,
      delEdu: false,
      delHonor : false,
      delCom: false,
    };
  }

  componentDidMount = async () => {
    await this.startPage();
  };

  startPage = async () => {
    
    await this.ProfileDetailApi();

    const years = [];
    for (let index = 15; index <= 90; index++) {
      years.push(
        moment()
          .subtract(index, "years")
          .format("YYYY")
      );
    }

    await this.setState({
      years: years,
    });

    // country codes
    const allCountriesCodes = await axios.get(
      `${process.env.REACT_APP_API_URL}countrycodes`
    );
    
    if (
      allCountriesCodes.status === 200 &&
      allCountriesCodes.data.status === true
    ) {

      // const phoneCodes = [];
      // const codesArray = [];

      await this.setState({
        allCountriesCodes:allCountriesCodes.data.datas,
      })

      // allCountriesCodes.data.datas.map((keys) => {
      //     phoneCodes.push(keys.phonecode);
      //   }
      // );

      // for (let index = 0; index < [...new Set(phoneCodes)].length; index++) {
      //   const ele = [...new Set(phoneCodes)][index];

      //   if (ele !== '91') {
      //     codesArray.push({
      //       value: ele,
      //     })
      //   }
      // }

      // this.setState({
      //   codesOption: codesArray,
      // });
    }
  }

  ProfileDetailApi = async () => {
    try {
      const profile = await axios.get(
        `${process.env.REACT_APP_API_URL}profile`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (profile.status === 200 && profile.data.status === true) {
        const profileDetails = profile.data.datas.profile;

        localStorage.setItem("userName", profileDetails.uname);

        let company_details = {
          comfle: profile.data.datas.company?.pcomprofile,
          prfcunt: profile.data.datas.company?.pcomcountry,
          prfstat: profile.data.datas.company?.pcomstates,
          prfcit: profile.data.datas.company?.pcomcity,
          comid: profile.data.datas.company?.pcomid,
          comuid: profile.data.datas.company?.pcomuid
        };

        if (!Array.isArray(profile.data.datas.company)) {
          this.setState({
            delCom:true
          })
          
        } else {
          this.setState({
            delCom:false
          })
        }

        localStorage.setItem('profileImage',  profileDetails.pimage);
        window.sessionStorage.setItem("profileImage", profileDetails.pimage)

        await this.setState({
          education: profile.data.datas.education,
          experience: profile.data.datas.experience,
          honors: profile.data.datas.honors,
          newExp: profile.data.datas.experience.length > 0 ? false : true,
          newEdu: profile.data.datas.education.length > 0 ? false : true,
          newHon: profile.data.datas.honors.length > 0 ? false : true,
          profileDetails: {
            ...profileDetails,
            uid: profileDetails.uid,
            pid: profileDetails.pid,

            uname: profileDetails.uname,
            ptitle: profileDetails.ptitle,
            puserType: profileDetails.puserType,

            uemail: profileDetails.uemail,
            pphone: profileDetails.pphone,
            pcode: profileDetails.pcode,

            paddress: profileDetails.paddress,
            pcountry: profileDetails.pcountry,
            puserstate: profileDetails.puserstate,
            plocation: profileDetails.plocation,
            ppostalcode: profileDetails.ppostalcode,

            pdob: (profileDetails.pdob !== "0000-00-00") ? moment(profileDetails.pdob).format("YYYY-MM-DD") : "0000-00-00",
            pgender:
              profileDetails.pgender === "" ? "male" : profileDetails.pgender,

            psummary: profileDetails.psummary,

            pimage: profileDetails.pimage,
          }
          
        });

        await this.setState({
          date: this.state.profileDetails.pdob.split("-")[2],
          month: this.state.profileDetails.pdob.split("-")[1],
          year: this.state.profileDetails.pdob.split("-")[0],
        });

        // Get all country
        const countries = await axios.get(
          `${process.env.REACT_APP_API_URL}countries`,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (countries.status === 200 && countries.data.status === true) {
          await this.setState({
            countries: countries.data.datas,
            profile_contries: countries.data.datas
          });
        }

        await this.setState({
          tmpProfile: company_details,
        })

        await this.GetStates();
        await this.GetCities();

        await this.GetProfileStates();
        await this.GetProfileCities();
      } else if (profile.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }
    } catch (err) {
      if ("response" in err && err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else {
        console.log(err);
      }
    }
  };

  GetStates = async () => {
    // Get all country state
    if (this.state.profileDetails.pcountry !== "") {
      try {
        const states = await axios.get(
          `${process.env.REACT_APP_API_URL}state/${
            this.state.profileDetails.pcountry
          }`,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (states.status === 200 && states.data.status === true) {
          await this.setState({
            states: states.data.datas,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        }
      }
    }
  };

  GetCities = async () => {
    // Get all state cities
    if (this.state.profileDetails.puserstate !== "") {
      
      try {
        const cities = await axios.get(
          `${process.env.REACT_APP_API_URL}cities/${
            this.state.profileDetails.puserstate
          }`,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (cities.status === 200 && cities.data.status === true) {
          await this.setState({
            cities: cities.data.datas,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        }
      }
    }
  };

  GetProfileStates = async () => {
    // Get all country state
    if (this.state.tmpProfile.prfcunt !== "") {
      try {
        const states = await axios.get(
          `${process.env.REACT_APP_API_URL}state/${
            this.state.tmpProfile.prfcunt
          }`,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (states.status === 200 && states.data.status === true) {
          await this.setState({
            profile_states: states.data.datas,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        }
      }
    }
  };

  GetProfileCities = async () => {
    // Get all state cities
    if (this.state.tmpProfile.prfstat !== "") {
      try {
        const cities = await axios.get(
          `${process.env.REACT_APP_API_URL}cities/${
            this.state.tmpProfile.prfstat
          }`,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (cities.status === 200 && cities.data.status === true) {
          await this.setState({
            profile_cities: cities.data.datas,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        }
      }
    }
  };

  handleImage = (e) => {
    if (e.target.files) {
      var file = e.target.files[0];
      if (typeof file !== "undefined") {
        var reader = new FileReader();
        reader.onload = async function(event) {
          if (e.target.name === "pimage") {
            const profileDetails = this.state.profileDetails;
            profileDetails.pimage = event.target.result;
            await this.setState({
              profileDetails: profileDetails,
            });
          }
        }.bind(this);

        reader.readAsDataURL(file);
      }
    }
  };

  handleInputChange = (e) => {
    
    const profileDetail = this.state.profileDetails;

    profileDetail[e.target.name] = e.target.value?.replace(/^\s+/g, "");

    this.setState({
      profileDetail: profileDetail,
    });
  };

  handleExpInputChange = async (e) => {
    
    const experience = this.state.tmpexperience;

    if(e.target.name === 'iscurrent') {
      
      await this.setState({
        expChecked: e.target.checked,
      })

      experience[e.target.name] = 'isCurrent';
      
    }

    experience[e.target.name] = e.target.value?.replace(/^\s+/g, "");

    this.setState({
      tmpexperience: experience,
    });
  };

  handleEduInputChange = (e) => {
    const education = this.state.tmpeducation;

    education[e.target.name] = e.target.value?.replace(/^\s+/g, "");

    this.setState({
      tmpeducation: education,
    });
  };

  handleHonInputChange = (e) => {
    const honor = this.state.tmphonor;

    honor[e.target.name] = e.target.value?.replace(/^\s+/g, "");

    this.setState({
      tmphonor: honor,
    });
  };

  handleProfileInputChange = (e) => {
    const profile = this.state.tmpProfile;

    profile[e.target.name] = e.target.value?.replace(/^\s+/g, "");

    this.setState({
      tmpProfile: profile,
    });
  };

  handleSummary = (val) => {
    const profileDetail = this.state.profileDetails;

    profileDetail.psummary =
      val.replace(/^\s+/g, '') === "<p>&nbsp;&nbsp;</p>" ? "" : val.replace(/^\s+/g, '');

    this.setState({
      profileDetail: profileDetail,
    });
  };

  handleDateChange = (e) => {
    var profileDetail = moment(this.state.profileDetails).format("YYYY-MM-DD");

    var arraydate = profileDetail.split("-");

    if (e.target.name === "day") {
      arraydate[2] = e.target.value?.replace(/^\s+/g, "");
      this.setState({
        date: arraydate[2],
      });
    } else if (e.target.name === "month") {
      arraydate[1] = e.target.value?.replace(/^\s+/g, "");
      this.setState({
        month: arraydate[1],
      });
    } else {
      arraydate[0] = e.target.value?.replace(/^\s+/g, "");
      this.setState({
        year: arraydate[0],
      });
    }

    profileDetail = arraydate.join("-");
  };

  handleCountryChange = async (e) => {
    
    const profileDetail = this.state.profileDetails;

    profileDetail[e.target.name] = e.target.value?.replace(/^\s+/g, "");
    profileDetail.puserstate = "";
    profileDetail.plocation = "";
    await this.setState({
      profileDetail: profileDetail,
    });

    if (e.target.name === "pcountry") {
      await this.GetCities();
      await this.GetStates();
    }

    await this.setState({
      cities: [],
    })
  };

  handleStateChange = async (e) => {
    const profileDetail = this.state.profileDetails;

    profileDetail[e.target.name] = e.target.value?.replace(/^\s+/g, "");
    profileDetail.plocation = "";
    await this.setState({
      profileDetail: profileDetail,
    });

    if (e.target.name === "puserstate") {
      await this.GetCities();
    } 
    
    var cityArray = [];
    if(this.state.cities.length == 0){
      cityArray.push(
        {name: e.target.value?.replace(/^\s+/g, ""),}
      )
      await this.setState({
        cities: cityArray,
      })
    }

  };

  handleCityChange = async (e) => {
    const profileDetail = this.state.profileDetails;

    profileDetail[e.target.name] = e.target.value?.replace(/^\s+/g, "");
    await this.setState({
      profileDetail: profileDetail,
    });

    
  };

  ShowHideAttach = async (e) => {
    await this.setState({
      isShowMedia: e.target.name,
    });
  };

  handleAttachChange = (e) => {
    if (e.target.files) {
      var file = e.target.files[0];
      if (typeof file !== "undefined") {
        var reader = new FileReader();
        reader.onload = async function(event) {
          if (e.target.name === "pimage") {
            const profileDetails = this.state.profileDetails;
            profileDetails.pimage = event.target.result;
            await this.setState({
              profileDetails: profileDetails,
            });
          }
        }.bind(this);

        reader.readAsDataURL(file);
      }
    }
  };

  updatePersonalDetails = async () => {
    
    if (this.profilevalidator.allValid()) {

      this.setState({
        loading:true,
      })

      const profileDetail = this.state.profileDetails;

      const date = this.state.date ?? "00";
      const month = this.state.month ?? "00";
      const year = this.state.year ?? "0000";

      const pdob = year + "-" + month + "-" + date;

      var formdata = new FormData(this.personalDetails.current);
     

      formdata.delete("pdob");
      formdata.append("pdob", pdob);

      formdata.delete("pcode");
      formdata.append("pcode", this.state.profileDetails.pcode);

      formdata.delete("psummary");
      formdata.append("psummary", this.state.profileDetails.psummary);

      try {
        const profile = await axios.post(
          `${process.env.REACT_APP_API_URL}profile/update`,
          formdata,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (profile.status === 200 && profile.data.status === true) {
          await this.startPage();

          this.setState({
            loading:false,
          })

          toast.success(profile.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          this.setState({
            loading:false,
          })
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          window.location = process.env.PUBLIC_URL;
        } else {
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
      this.profilevalidator.hideMessages();
      this.forceUpdate();
    } else {
      this.profilevalidator.showMessages();
      this.forceUpdate();
    }
  };

  resetHonor = async () => {
    this.Updatehonors.hideMessages();
    this.forceUpdate();

    await this.setState({
      newHon: false,
      tmphonor: [],
    });
  };

  updateDetails = async (type) => {
    var isValid = false;
    var url, Detail;
    if (type === "experience") {
      if (this.UpdateExperience.allValid()) {

        this.setState({
          isExpLoading:true,
        })

        isValid = true;
        Detail = new FormData(this.newExp.current)

        if (this.state.expChecked){
          Detail.delete('iscurrent');
          Detail.append("iscurrent", 'isCurrent');
        } else{
          Detail.delete('iscurrent');
          Detail.append("iscurrent", '');
        }

        if (this.state.tmpexperience.expid > 0) {
          url = `${process.env.REACT_APP_API_URL}profile/update/${type}/${
            this.state.tmpexperience.expid
          }`;
        } else {
          url = `${process.env.REACT_APP_API_URL}profile/insert/${type}`;
        }
        this.UpdateExperience.hideMessages();
        this.forceUpdate();
      } else {
        this.UpdateExperience.showMessages();
        this.forceUpdate();
      }
    }

    if (type === "education") {
      if (this.UpdateEducation.allValid()) {

        this.setState({
          isEduLoading:true,
        })

        isValid = true;
        Detail = new FormData(this.newEdu.current)
        if (this.state.tmpeducation.edupid > 0) {
          url = `${process.env.REACT_APP_API_URL}profile/update/${type}/${
            this.state.tmpeducation.edupid
          }`;
        } else {
          url = `${process.env.REACT_APP_API_URL}profile/insert/${type}`;
        }

        this.UpdateEducation.hideMessages();
        this.forceUpdate();
      } else {
        this.UpdateEducation.showMessages();
        this.forceUpdate();
      }
    }

    if (type === "honors") {
      if (this.Updatehonors.allValid()) {
        
        this.setState({
          isHonorLoading:true,
        })

        isValid = true;
        Detail = new FormData(this.newHon.current)
        if (this.state.tmphonor.honid > 0) {
          url = `${process.env.REACT_APP_API_URL}profile/update/${type}/${
            this.state.tmphonor.honid
          }`;
        } else {
          url = `${process.env.REACT_APP_API_URL}profile/insert/${type}`;
        }

        this.Updatehonors.hideMessages();
        this.forceUpdate();
      } else {
        this.Updatehonors.showMessages();
        this.forceUpdate();
      }
    }

    if (type === "profile") {
      if (this.UpdatePofile.allValid()) {
        
        this.setState({
          isCoLoading:true,
        })

        isValid = true;
        Detail = new FormData(this.newProfile.current)
        if (this.state.tmpProfile.comid > 0) {
          url = `${process.env.REACT_APP_API_URL}profile/update/company/${
            this.state.tmpProfile.comid
          }`;
          
        } else {
          url = `${process.env.REACT_APP_API_URL}profile/insert/company`;

        }

        this.UpdatePofile.hideMessages();
        this.forceUpdate();
      } else {

        this.setState({
          isCoLoading:false
        })

        this.UpdatePofile.showMessages();
        this.forceUpdate();
      }
    }

    if (isValid) {
      var formdata = Detail;
      try {
        const profileUpdate = await axios.post(url, formdata, {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (
          profileUpdate.status === 200 &&
          profileUpdate.data.status === true
        ) {
          
          this.setState({
            loading:false,
            isCoLoading:false,
            isHonorLoading:false,
            isExpLoading:false,
            isEduLoading:false,
          })

          toast.success(profileUpdate.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {

          this.setState({
            loading:false,
            isCoLoading:false,
            isHonorLoading:false
          })

          toast.error(profileUpdate.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        this.newExp.current.reset();
        this.newEdu.current.reset();
        this.newHon.current.reset();
        this.newProfile.current.reset();

      } catch (err) {
        if (err?.response?.status === 403) {
          window.location = process.env.PUBLIC_URL;
        } else {
          toast.error(err.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
      await this.startPage();
    }

  };

  removeDetails = async (type) => {
    var isValid = false;
    var url;

    if (type === "experience") {
      
      this.setState({
        isDelExpLoading:true,
      })

      isValid = true;

      url = `${process.env.REACT_APP_API_URL}profile/delete/${type}/${
        this.state.tmpexperience.expid
      }`;
    }

    if (type === "education") {

      this.setState({
        isDelEduLoading:true,
      })

      isValid = true;

      url = `${process.env.REACT_APP_API_URL}profile/delete/${type}/${
        this.state.tmpeducation.edupid
      }`;
    }

    if (type === "honors") {
      this.setState({
        isDelHonorLoading:true,
      })

      isValid = true;

      url = `${process.env.REACT_APP_API_URL}profile/delete/${type}/${
        this.state.tmphonor.honid
      }`;
    }

    if (type === "company") {
      
      this.setState({
        isDelCoLoading:true,
      })

      isValid = true;

      url = `${process.env.REACT_APP_API_URL}profile/delete/${type}/${
        this.state.tmpProfile.comid
      }`;
    }

    if (isValid) {
      try {
        const profileDelete = await axios.delete(url, {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (
          profileDelete.status === 200 &&
          profileDelete.data.status === true
        ) {
          
          await this.resetHonor();

          this.setState({
            loading:false,
            isDelCoLoading:false,
            isDelHonorLoading:false,
            isDelExpLoading:false,
            isDelEduLoading:false,
            delHonor:false,
            delEdu:false,
            delExp:false,
            tmpexperience:[],
            tmpeducation: [],
          })

          toast.success(profileDelete.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {

          this.setState({
            loading:false,
            isDelCoLoading:false,
            isDelHonorLoading:false
          })

          toast.error(profileDelete.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        this.newExp.current.reset();
        this.newEdu.current.reset();
        this.newHon.current.reset();
        this.newProfile.current.reset();

      } catch (err) {
        if (err?.response?.status === 403) {
          window.location = process.env.PUBLIC_URL;
        } else {
          toast.error(err.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
      await this.startPage();
    
    }

  }

  EditExp = async (exp) => {
    
    if(exp) {
      this.setState({
        delExp:true,
      })
    } else {
      this.setState({
        delExp:false
      })
    }

      await this.setState({
      tmpexperience: {
        expid: exp?.pexpid,
        expuid: exp?.pexpuid,
        
        exp_title: exp?.pexptitle,
        companyname: exp?.pexpcompanyname,
        exp_location: exp?.pexplocation,
        startDateMonth: exp?.pexpfrom_mon,
        startDateYear: exp?.pexpfrom_yr,
        endDateMonth: exp?.pexpto_mon,
        endDateYear: exp?.pexpto_yr,
        iscurrent: exp?.pexpiscurrent,
        exp_desc: exp?.pexpdes,
        
        expfle: exp?.pexpfle,
        expphot: exp?.pexpphot,
        explnk: exp?.pexplnk,
        expvde: exp?.pexpvdeo,
        expprest: exp?.pexpprest,
      },
      newExp: true,
    });

    if (exp?.pexpiscurrent === 'isCurrent'){
      this.setState({
        expChecked:true,
      })
      this.checkbox.current.checked = true
    } else if (!exp){
      this.setState({
        expChecked:true,
      })
      this.checkbox.current.checked = true
    } else{
      this.setState({
        expChecked:false,
      })
      this.checkbox.current.checked = false
    }
  };

  EditEdu = async (edu) => {

    if (edu) {
      this.setState({
        delEdu: true,
      })
    } else {
      this.setState({
        delEdu: false,
      })
    }

    await this.setState({
      newEdu: true,
      tmpeducation: {
        schoolname: edu?.pedupschoolname,
        schoolfromyear: edu?.peduschoolfromyear,
        schooltoyear: edu?.peduschooltoyear,
        schoolDegree: edu?.peduschoolDegree,
        school_act: edu?.peduschool_act,
        exp_desc: edu?.peduexp_desc,
        schoolstudy: edu?.peduschoolstudy,
        schoolMode: edu?.peduschoolgrade,

        edufle: edu?.ppedufle,
        edupho: edu?.peduphot,
        edupho: edu?.peduphot,
        edulnk: edu?.pedulnk,
        eduvde: edu?.peduvdeo,
        edupres: edu?.peduprest,

        edupid: edu?.pedupid,
        edupuid: edu?.pedupuid,
      },
    })
  }

  EditHon = async (hon) => {

    if (hon) {
      this.setState({
        delHonor: true,
      })
    } else {
      this.setState({
        delHonor: false,
      })
    }
    
    await this.resetHonor();
    await this.setState({
      newHon: true,
      tmphonor: {
        hons_title: hon?.phontitle,
        hons_desc: hon?.phondesc,
        honrmnth: hon?.phonmonth,
        honryr: hon?.phonyear,
        honlnk: hon?.phonlnk,
        honvde: hon?.phonvdeo,
        honuid: hon?.phonuid,
        honid: hon?.phonid,

        honfle: hon?.phonfle,
        honphot: hon?.phonphot,
        honprest: hon?.phonprest,
      },
    });
  };

  deleteProfileFile = async (
    uid = 0,
    prid = 0,
    folder = "",
    file = "",
    ftype = ""
  ) => {
    try {
      let fullpath = file;
      file = file.split("/").pop();
      const resDeletePostFile = await axios.delete(
        `${
          process.env.REACT_APP_API_URL
        }profile/file/${prid}/${folder}/${ftype}/${btoa(file)}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
      if (
        resDeletePostFile.status === 200 &&
        resDeletePostFile.data.status === true
      ) {
        document.getElementById(fullpath).style.display = "none";
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location.href = process.env.PUBLIC_URL;
      } else if (
        err?.response?.data?.error != "" ||
        err?.response?.data?.message != ""
      ) {
        toast.error(
          err?.response?.data?.error === ""
            ? err?.response?.data?.message
            : err?.response?.data?.error,
          {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  test = async (option) => {

    const profileDetail = this.state.profileDetails;

    profileDetail['pcode'] = option.phonecode?.replace(/^\s+/g, "");

    this.setState({
      profileDetail: profileDetail,
    });
    
  }

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        <section className="product-section psection">
          <div className="profile-container">
            <div className="divBorder">
              <div>
                <h2>Edit Profile</h2>
              </div>

              <form
                method="post"
                encType="multipart/form-data"
                ref={this.personalDetails}
              >
                <div className="image-content" key={"1"}>
                  <div className="userImage">
                    <img
                      src={this.state.profileDetails.pimage}
                      alt="layout-image"
                      className="img-big"
                    />
                    <h3> Change Picture </h3>
                    <input
                      type="file"
                      ref={this.pimage}
                      className="userUpload"
                      accept="image/*"
                      onChange={this.handleImage}
                      name="pimage"
                    />

                    {this.profilevalidator.message(
                      "profile image",
                      this.state.profileDetails.pimage,
                      "required|string"
                    )}
                  </div>

                  <div className="userdetails">
                    <input
                      type="text"
                      id="edittext"
                      className="inpfield"
                      name="uname"
                      placeholder="Name"
                      onChange={this.handleInputChange}
                      value={this.state.profileDetails.uname}
                    />

                    {this.profilevalidator.message(
                      "user name",
                      this.state.profileDetails.uname,
                      "required|string"
                    )}

                    <input
                      type="text"
                      className="inpfield"
                      name="ptitle"
                      placeholder="Title"
                      onChange={this.handleInputChange}
                      value={this.state.profileDetails.ptitle}
                    />

                    {this.profilevalidator.message(
                      "post title",
                      this.state.profileDetails.ptitle,
                      "required|string"
                    )}

                    <div className="caret-content">
                      <select
                        className="form-select inpfield"
                        aria-label="Default select example"
                        name="puserType"
                        onChange={this.handleInputChange}
                        value={this.state.profileDetails.puserType}
                      >
                        <option value="" disabled={true}>
                          Types{" "}
                        </option>
                        <option value="Professional">Professional </option>
                        <option value="Student">Student </option>
                        <option value="Organization">Organization </option>
                        <option value="Others">Others </option>
                      </select>

                      {this.profilevalidator.message(
                        "user type",
                        this.state.profileDetails.puserType,
                        "required|string"
                      )}
                    </div>
                  </div>
                </div>
                <div className="prsnlDetails" key={"2"}>
                  <ul>
                    <li className="firstItem" key={"2.1"}>
                      <label>Email Id</label>
                      <p className="paraFont">
                        {this.state.profileDetails.uemail}
                      </p>
                    </li>

                    <li key={"2.3"} style={{'display': 'flex'}}>
                      <label>Phone</label>
                      <div className="gear">
                        <span
                          id="editphone"
                          className="datainfo"
                          data-value="phone"
                        >
                         
                          {/* <select
                            className="profileSelectCode inpfield"
                            name="pcode"
                            aria-label="Default select example"
                            onChange={this.handleInputChange}
                            value={this.state.profileDetails.pcode}
                            style={{
                              width: "50%",
                            }}
                          >
                            {this.state.allCountriesCodes.length && this.state.allCountriesCodes.map((option) => (
                              <option value={option.phonecode}>
                                {option.name}(+{option.phonecode})
                              </option>
                            ))}
                          </select> */}

                          <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <div className="profileSelectCode inpfield pcodeDrop">
                                + {this.state.profileDetails.pcode} 
                              </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-hover">

                            {this.state.allCountriesCodes.length && this.state.allCountriesCodes.map((option) => (
                              <Dropdown.Item onClick={() => this.test(option)}>
                                {option.name}(+{option.phonecode})
                              </Dropdown.Item>
                            ))}
                               
                            </Dropdown.Menu>
                        </Dropdown>
                          

                          <input
                            type="text"
                            className="inpfield"
                            name="pphone"
                            onChange={this.handleInputChange}
                            value={this.state.profileDetails.pphone}
                          />
                        </span>
                      </div>

                      {this.profilevalidator.message(
                        "phone no",
                        this.state.profileDetails.pphone,
                        "required|numeric"
                      )}
                    </li>

                    {(this.state.profileDetails.puserType === "Professional" || this.state.profileDetails.puserType === "Student") ? (
                      <>
                        <li className="edthide " key={"2.2"}>
                          <label>Date Of Birth</label>
                          <div className="gear">
                            <span
                              id="editdob"
                              className="datainfo"
                              data-value="dob"
                            >
                              <div className="caret-content" key={"2.2.1"}>
                                <select
                                  className="form-select inpfield fieldWidth"
                                  value={this.state.date}
                                  onChange={this.handleDateChange}
                                  name="day"
                                >
                                  <option key={"0"} value="">
                                    Select Date
                                  </option>
                                  
                                  {Array.from(Array(31).keys()).map((date, key) => {
                                    
                                    return (
                                      <option
                                        key={key}
                                        value={
                                          String(date + 1).length < 2
                                            ? "0" + (date + 1)
                                            : date + 1
                                        }
                                      >
                                        {String(date + 1).length < 2
                                          ? "0" + (date + 1)
                                          : date + 1}
                                      </option>
                                    );
                                  })}
                                </select>

                                {this.profilevalidator.message(
                                  "date",
                                  this.state.date,
                                  "required|numeric|max:2"
                                )}
                              </div>

                              <div className="caret-content" key={"2.2.2"}>
                                <select
                                  className="form-select inpfield fieldWidth"
                                  aria-label="Default select example"
                                  onChange={this.handleDateChange}
                                  value={this.state?.month}
                                  name="month"
                                >
                                  <option value="">Select Month</option>
                                  <option value="01">January</option>
                                  <option value="02">February</option>
                                  <option value="03">March</option>
                                  <option value="04">April</option>
                                  <option value="05">May</option>
                                  <option value="06">June</option>
                                  <option value="07">July</option>
                                  <option value="08">August</option>
                                  <option value="09">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>

                                {this.profilevalidator.message(
                                  "month",
                                  this.state.month,
                                  "required|string"
                                )}
                              </div>

                              <div className="caret-content" key={"2.2.3"}>
                                <select
                                  className="form-select inpfield fieldWidth"
                                  aria-label="Default select example"
                                  value={this.state?.year}
                                  onChange={this.handleDateChange}
                                  name="year"
                                >
                                  <option value="">Select Year</option>
                                  {this.state.years.map((year, index) => {
                                    return (
                                      <option key={index} value={year}>
                                        {year}
                                      </option>
                                    );
                                  })}
                                </select>

                                {this.profilevalidator.message(
                                  "year",
                                  this.state.year,
                                  "required|numeric"
                                )}
                              </div>
                            </span>
                          </div>
                        </li>

                        <li className="edthide">
                          <label>Gender</label>
                          <div className="gear">
                            <span
                              id="editgender"
                              className="datainfo"
                              data-value="gender"
                            >
                              <div className="caret-content">
                                <select
                                  className="form-select inpfield"
                                  aria-label="Default select example"
                                  name="pgender"
                                  onChange={this.handleInputChange}
                                  value={this.state.profileDetails.pgender}
                                >
                                  <option value="male">Male </option>
                                  <option value="female">Female </option>
                                  <option value="other">Other </option>
                                </select>

                                {this.profilevalidator.message(
                                  "gender",
                                  this.state.profileDetails.pgender,
                                  "required|string"
                                )}
                              </div>
                            </span>
                          </div>
                        </li>
                      </>
                    ) : "" }
                    
                    <li>
                      <label>Address</label>
                      <div className="gear">
                        <span
                          id="editaddress"
                          className="datainfo"
                          data-value="address"
                        >
                         
                          <textarea
                            type="text"
                            className="inpfield"
                            name="paddress"
                            rows="3" cols="50"
                            onChange={this.handleInputChange}
                            value={this.state.profileDetails.paddress}
                          > 
                          {this.state.profileDetails.paddress}
                          </textarea>
                        </span>

                        {this.profilevalidator.message(
                          "address",
                          this.state.profileDetails.paddress,
                          "required|string"
                        )}
                      </div>
                    </li>
                    <li>
                      <label>Country</label>
                      <div className="gear">
                        <span
                          id="editcountry"
                          className="datainfo"
                          data-value="country"
                        >

                          <select
                            className="form-select inpfield selectSpace"
                            aria-label="Default select example"
                            name="pcountry"
                            value={this.state.profileDetails.pcountry}
                            onChange={this.handleCountryChange}
                          >
                            {this.state.countries.map((option, index) => (
                              <option key={index} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </span>

                        {this.profilevalidator.message(
                          "country",
                          this.state.profileDetails.pcountry,
                          "required|string"
                        )}
                      </div>
                    </li>
                    <li>
                      <label>State</label>
                      <div className="gear">
                        <span
                          id="edituserstate"
                          className="datainfo"
                          data-value="userstate"
                        >
                          <select
                            className="form-select inpfield selectSpace"
                            aria-label="Default select example"
                            name="puserstate"
                            value={this.state.profileDetails.puserstate}
                            onChange={this.handleStateChange}
                          >
                            {this.state.states.map((option, index) => (
                              <option key={index} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </span>

                        {this.profilevalidator.message(
                          "state",
                          this.state.profileDetails.puserstate,
                          "required|string"
                        )}
                      </div>
                    </li>
                    <li>
                      <label>Location</label>
                      <div className="gear">
                        <span
                          id="editlocation"
                          className="datainfo"
                          data-value="location"
                        >
                          <select
                            className="form-select inpfield selectSpace"
                            aria-label="Default select example"
                            name="plocation"
                            value={this.state.profileDetails.plocation}
                            onChange={this.handleCityChange}
                          >
                            {this.state.cities.map((option, index) => (
                              <option key={index} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </span>

                        {this.profilevalidator.message(
                          "location",
                          this.state.profileDetails.plocation,
                          "required|string"
                        )}
                      </div>
                    </li>
                    <li>
                      <label>Postal Code</label>
                      <div className="gear">
                        <span
                          id="editpostalcode"
                          className="datainfo"
                          data-value="postalcode"
                        >
                          <input
                            type="text"
                            className="inpfield"
                            name="ppostalcode"
                            value={this.state.profileDetails.ppostalcode}
                            onChange={this.handleInputChange}
                          />
                        </span>

                        {this.profilevalidator.message(
                          "postal code",
                          this.state.profileDetails.ppostalcode,
                          "required|string"
                        )}
                      </div>
                    </li>
                    <li className="lastItem">
                      <label>Summary</label>
                      <div className="gear">
                        <span id="editsummary" data-value="summary">
                          <span className="initsum">
                            <Editor
                              apiKey="textarea"
                              name="psummary"
                              value={this.state.profileDetails.psummary}
                              init={{
                                height: 200,
                                menubar: false,
                              }}
                              onEditorChange={this.handleSummary}
                            />
                          </span>
                        </span>
                      </div>

                      {this.profilevalidator.message(
                        "summary",
                        this.state.profileDetails.psummary,
                        "required|string"
                      )}
                    </li>

                    <div className="sidebar-content iconContent">
                      <div className="header-content">
                        <h3 className="header attachText attachTextprofile">Attach:</h3>
                        <div className="post-pannel attachIconsDiv">
                          <div className="post-pannel-content attachIconsprofile">
                            <div className="pannel-icon tooltip">
                              <img
                                src={Files}
                                alt="files"
                                title="file"
                                className="img-big resImg"
                                onClick={this.ShowHideAttach}
                                name="pfle"
                              />
                              <span className="tooltiptext">File</span>
                            </div>
                          </div>

                          <div className="post-pannel-content attachIconsprofile">
                            <div className="pannel-icon tooltip">
                              <img
                                src={CameraIcon}
                                alt="camera-icon"
                                className="img-big resImg"
                                onClick={this.ShowHideAttach}
                                name="pphot"
                              />
                              <span className="tooltiptext">Photo</span>
                            </div>
                          </div>

                          <div className="post-pannel-content attachIconsprofile">
                            <div className="pannel-icon tooltip">
                              <img
                                src={AddLink}
                                alt="add-link"
                                name="plnk"
                                className="img-big resImg"
                                onClick={this.ShowHideAttach}
                              />
                              <span className="tooltiptext">Link</span>
                            </div>
                          </div>

                          <div className="post-pannel-content attachIconsprofile">
                            <div className="pannel-icon tooltip">
                              <img
                                src={Youtube}
                                alt="youtube"
                                className="img-big resImg"
                                onClick={this.ShowHideAttach}
                                name="pvdeo"
                              />
                              <span className="tooltiptext">Youtube</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          this.state.isShowMedia === "pfle" ? "" : "d-none"
                        }
                      >
                        <input
                          type="file"
                          name="pfle"
                          className="input-field field"
                          ref={this.pfle}
                          onChange={(e) => {
                            this.setState({
                              profile_docu: e.target,
                            });
                            this.profilevalidator.allValid();
                          }}
                          accept="audio/*,video/*,image/*,.doc,.docx,.pdf,.ppt,.pptx"
                        />

                        {this.profilevalidator.message(
                          "File ",
                          this.state?.profile_docu,
                          "PostFiles"
                        )}

                        <div className="d-flex d_preview_images">
                          {Array.isArray(this.state.profileDetails?.pfle)
                            ? this.state.profileDetails?.pfle.map((doc) => {
                                return (
                                  <div className="image-area" id={doc}>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/docu.png"
                                      }
                                      target={"_blank"}
                                      alt=""
                                      onClick={(e) => {
                                        e.preventDefault();
                                        saveAs(doc);
                                      }}
                                    />
                                    <a
                                      className="remove-image"
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.deleteProfileFile(
                                          this.state.profileDetails?.uid,
                                          this.state.profileDetails?.uid,
                                          "profile_file",
                                          doc,
                                          "file"
                                        );
                                      }}
                                    >
                                      &#215;
                                    </a>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </div>

                      <div
                        className={
                          this.state.isShowMedia === "pphot" ? "" : "d-none"
                        }
                      >
                        <input
                          type="file"
                          className="input-field field"
                          name="pphot"
                          ref={this.pphot}
                          accept="image/*"
                          onChange={(e) => {
                            this.setState({
                              profile_poimage: e.target,
                            });
                            this.profilevalidator.allValid();
                          }}
                        />

                        {this.profilevalidator.message(
                          "Photo",
                          this.state?.profile_poimage,
                          "Photos"
                        )}

                        <div className="d-flex d_preview_images">
                          {Array.isArray(this.state.profileDetails?.pphot)
                            ? this.state.profileDetails?.pphot.map(
                                (img, key) => {
                                  return (
                                    <div
                                      className="image-area"
                                      key={key}
                                      id={img}
                                    >
                                      <img src={img} alt="" />
                                      <a
                                        className="remove-image"
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.deleteProfileFile(
                                            this.state.profileDetails?.uid,
                                            this.state.profileDetails?.uid,
                                            "profile_photo",
                                            img,
                                            "photo"
                                          );
                                        }}
                                      >
                                        &#215;
                                      </a>
                                    </div>
                                  );
                                }
                              )
                            : ""}
                        </div>
                      </div>

                      <div
                        className={
                          this.state.isShowMedia === "svideo" ? "" : "d-none"
                        }
                      >
                        <input
                          type="file"
                          className="input-field field"
                          name="svideo"
                          ref={this.svideo}
                          accept="video/*"
                          onChange={(e) => {
                            this.setState({
                              profile_video: e.target,
                            });
                            this.profilevalidator.allValid();
                          }}
                        />

                        {this.profilevalidator.message(
                          "Video ",
                          this.state?.profile_video,
                          "VideoFiles"
                        )}

                        <div className="d-flex d_preview_images">
                          {Array.isArray(this.state.profileDetails?.vdeo)
                            ? this.state.profileDetails?.vdeo.map((vid) => {
                                return (
                                  <div className="image-area" id={vid}>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/film.png"
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        saveAs(vid);
                                      }}
                                    />
                                    <a
                                      className="remove-image"
                                      href="#"
                                      onClick={() => {
                                        this.deletePostFile(
                                          this.state.profileDetails?.uid,
                                          this.state.profileDetails?.pid,
                                          "video",
                                          vid
                                        );
                                      }}
                                    >
                                      &#215;
                                    </a>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </div>

                      <div
                        className={
                          this.state.isShowMedia === "plnk" ? "" : "d-none"
                        }
                      >
                        <input
                          type="text"
                          name="plnk"
                          className="input-field field"
                          ref={this.plnk}
                          onChange={this.handleInputChange}
                          value={this.state.profileDetails.plnk}
                          placeholder="Enter Your Link. Use comma to add more links"
                        />

                        {this.profilevalidator.message(
                          "Links ",
                          this.state.profileDetails.plnk,
                          "multipleLinks"
                        )}
                      </div>

                      <div
                        className={
                          this.state.isShowMedia === "pvdeo" ? "" : "d-none"
                        }
                      >
                        <input
                          type="text"
                          className="input-field field"
                          name="pvdeo"
                          ref={this.pvdeo}
                          onChange={this.handleInputChange}
                          value={this.state.profileDetails.pvdeo}
                          placeholder="Paste the youtube video link here"
                        />

                        {this.profilevalidator.message(
                          "Youtube ",
                          this.state.profileDetails.pvdeo,
                          "multipleYoutubeLinks"
                        )}
                      </div>
                    </div>
                    
                    <div className="expBtn btns updtBtn">
                    
                      <div className="expi-button">
                        <button
                          type="button"
                          id="expsavebtn"
                          className="reset-button"
                          onClick={this.updatePersonalDetails}
                          disabled={this.state.loading}
                        >
                          {this.state.loading ? <ButtonLoader color="#8a8989" /> : 'Save'}
                        </button>
                      </div>
                    </div>
                    
                  </ul>
                  
                </div>
                
              </form>
            </div>

            {/* {this.state.profileDetails.puserType === "Organization" ? (
              <></>
            ) : (
              <>
                
            )} */}

          {this.state.profileDetails.puserType === "Organization" || this.state.profileDetails.puserType === "Others" ? (
            <div className="expDiv">
              {/* Profile section */}
              <div className="exp_head">
                <h2>
                  { this.state.profileDetails.puserType === "Organization" ? 'Company' : '' } Profile</h2>
              </div>
              <>
                <form ref={this.newProfile} onSubmit={(e) => {e.preventDefault(); }} encType="multipart/form-data">
                  <div id="honaR" className="borBotm">
                    <div className="honorContent">
                      <div className="expDetails">
                        <h2>
                        { this.state.profileDetails.puserType === "Organization" ? 'Company' : '' } Profile <span className="requiredField">*</span>
                        </h2>
                        <input
                          type="file"
                          name="fle"
                          className="fieldWidth"
                          onChange={(e) => {
                            this.setState({
                              company_profile: e.target
                            })
                            this.UpdatePofile.allValid()
                        }}
                        />

                        {this.UpdatePofile.message(
                          "Profile",
                          this.state.company_profile,
                          "ProfileFile"
                        )}

                        <div className="d-flex d_preview_images">
                          {
                              (Array.isArray(this.state.tmpProfile?.comfle)) ? this.state.tmpProfile?.comfle.map(doc => {
                                  return <div className="image-area" id={doc}>
                                          <img src={process.env.PUBLIC_URL+'/images/docu.png'} target={'_blank'} alt="" onClick={(e) => {
                                              e.preventDefault()
                                              saveAs(doc)
                                          }} />
                                          <a className="remove-image" href="#" onClick={(e) => {
                                              e.preventDefault();
                                              this.deleteProfileFile(this.state.tmpProfile?.comuid, this.state.tmpProfile?.comid, 'company', doc, 'file' )
                                          }}>&#215;</a>
                                      </div>
                              }) : ""
                          }
                        </div>
                      </div>

                      <div className="expDetails">
                        <h2>
                          Country <span className="requiredField">*</span>
                        </h2>
                        <div className="SelectMonth">
                          <select
                            className="form-select inpfield selectSpace"
                            aria-label="Default select example"
                            name="prfcunt"
                            value={this.state.tmpProfile.prfcunt}
                            onChange={(e) => {
                              e.preventDefault()
                              this.handleProfileInputChange(e)
                              this.GetProfileStates()
                            }}
                          >
                            <option defaultValue value="">
                              Select country
                            </option>
                            {this.state.profile_contries.map((option, index) => (
                              <option key={index} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                            
                          </select>
                        </div>

                        {this.UpdatePofile.message(
                          "year",
                          this.state.tmpProfile.prfcunt,
                          "required"
                        )}
                      </div>
                      <div className="expDetails">
                        <h2>State <span className="requiredField">*</span></h2>
                        <select
                            className="form-select inpfield selectSpace"
                            aria-label="Default select example"
                            name="prfstat"
                            value={this.state.tmpProfile.prfstat}
                            onChange={(e) => {
                              e.preventDefault()
                              this.handleProfileInputChange(e)
                              this.GetProfileCities()
                            }}
                          >
                            <option defaultValue value="">
                              Select State
                            </option>
                            {this.state.profile_states?.map((option, index) => (
                              <option key={index} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </select>

                        {this.UpdatePofile.message(
                          "description",
                          this.state.tmpProfile.prfstat,
                          "required"
                        )}
                      </div>

                      <div className="expDetails">
                        <h2>City <span className="requiredField">*</span></h2>
                        <select
                            className="form-select inpfield selectSpace"
                            aria-label="Default select example"
                            name="prfcit"
                            value={this.state.tmpProfile.prfcit}
                            onChange={this.handleProfileInputChange}
                          >
                            <option defaultValue value="">
                              Select Ciy
                            </option>
                            {this.state.profile_cities.map((option, index) => (
                              <option key={index} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </select>

                        {this.UpdatePofile.message(
                          "description",
                          this.state.tmpProfile.prfcit,
                          "required"
                        )}
                      </div>
                      <div className="expBtn btns"> 
                      
                        <div className="expi-button">
                          <button
                            type="button"
                            id="savebtn"
                            className="reset-button"
                            onClick={() => {
                              this.updateDetails("profile");
                            }}
                            disabled={this.state.isCoLoading}
                          >
                            {this.state.isCoLoading ? <ButtonLoader color="#8a8989" /> : 'Save'}
                          </button>
                        </div>
                        <div className="expi-button">
                          <button
                            type="button"
                            id="cancelbtn"
                            className="reset-button"
                            onClick={(e) => {
                              e.preventDefault();
                              this.newProfile.current.reset();
                              this.ProfileDetailApi();
                            }}
                          >
                            Cancel
                          </button>
                        </div>

                        {this.state.delCom &&
                         <div className="expi-button">
                         <button
                           type="button"
                           id="savebtn"
                           className="reset-button removeBTN"
                           onClick={() => {
                             this.removeDetails("company");
                           }}
                           disabled={this.state.isDelCoLoading}
                         >
                           {this.state.isDelCoLoading ? <ButtonLoader color="#8a8989" /> : 'Remove this profile'}
                         </button>
                       </div>
                        }
                       
                      </div>
                    </div>
                  </div>
                </form>
              </>
            </div>
          ) : (
            <>
            </>
          )}

        {this.state.profileDetails.puserType === "Professional" || this.state.profileDetails.puserType === "Student" ? (
            <>
            <div className="expDiv">
              {/* Expriance section */}
                <div id="expi" className="borBotm">
                  <div className="exp_head">
                    <h2>Experience</h2>
                    <button
                      className="editButton"
                      type="button"
                      onClick={() => {
                        this.EditExp(null);
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true" />
                      <span className="addposi">Add Position</span>
                    </button>
                  </div>

                  {this.state.newExp === true ? (
                    <>
                      <form ref={this.newExp} onSubmit={(e) => {e.preventDefault()}}>
                        <div className="expContentDiv">
                          <div className="expDetails">
                            <h2>
                              Company Name
                              <span className="requiredField">*</span>
                            </h2>
                            <input
                              type="text"
                              className="inpfield"
                              placeholder="Company name"
                              name="companyname"
                              value={this.state.tmpexperience?.companyname}
                              onChange={this.handleExpInputChange}
                            />

                            {this.UpdateExperience.message(
                              "Company name",
                              this.state.tmpexperience.companyname,
                              "required|string"
                            )}
                          </div>

                          <div className="expDetails">
                            <h2>
                              Title<span className="requiredField">*</span>
                            </h2>
                            <input
                              type="text"
                              className="inpfield fieldWidth"
                              name="exp_title"
                              placeholder="Title"
                              value={this.state.tmpexperience?.exp_title}
                              onChange={this.handleExpInputChange}
                            />

                            {this.UpdateExperience.message(
                              "Title",
                              this.state.tmpexperience.exp_title,
                              "required|string"
                            )}
                          </div>

                          <div className="expDetails">
                            <h2>
                              Location<span className="requiredField">*</span>
                            </h2>
                            <input
                              type="text"
                              className="inpfield fieldWidth"
                              name="exp_location"
                              placeholder="Location"
                              value={this.state.tmpexperience.exp_location}
                              onChange={this.handleExpInputChange}
                            />

                            {this.UpdateExperience.message(
                              "Location",
                              this.state.tmpexperience.exp_location,
                              "required|string"
                            )}
                          </div>

                          <div className="expDetails">
                            <h2>
                              Time Period
                              <span className="requiredField">*</span>
                            </h2>
                            <div>
                              <div className="SelectMonth">
                                <p>From: </p>
                                <select
                                  className="form-select inpfield"
                                  aria-label="Default select example"
                                  name="startDateMonth"
                                  value={ moment().set('month', parseInt(this.state?.tmpexperience?.startDateMonth-1)).format('MM')}
                                  onChange={this.handleExpInputChange}
                                >
                                  <option value="">Select Month</option>
                                  <option value="01">January</option>
                                  <option value="02">February</option>
                                  <option value="03">March</option>
                                  <option value="04">April</option>
                                  <option value="05">May</option>
                                  <option value="06">June</option>
                                  <option value="07">July</option>
                                  <option value="08">August</option>
                                  <option value="09">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>
                                <input
                                  type="text"
                                  className="inpfield"
                                  placeholder="Year"
                                  name="startDateYear"
                                  value={this.state.tmpexperience.startDateYear}
                                  onChange={this.handleExpInputChange}
                                />
                              </div>

                              {this.UpdateExperience.message(
                                "from year",
                                this.state.tmpexperience.startDateYear,
                                "required|numeric"
                              )}

                              <div className="displat-flex">
                              <p>To: </p>
                              
                                {this.state.expChecked ?
                                <> 
                                  <p className="presentExp"> Present</p>
                                </>
                                : 
                              <>
                                
                                <select
                                  className="form-select inpfield"
                                  aria-label="Default select example"
                                  name="endDateMonth"
                                  value={ String(moment().set('month', parseInt(this.state.tmpexperience?.endDateMonth-1)).format('MM'))}
                                  onChange={this.handleExpInputChange}
                                >
                                  <option value="">Select Month</option>
                                  <option value="01">January</option>
                                  <option value="02">February</option>
                                  <option value="03">March</option>
                                  <option value="04">April</option>
                                  <option value="05">May</option>
                                  <option value="06">June</option>
                                  <option value="07">July</option>
                                  <option value="08">August</option>
                                  <option value="09">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>
                                <input
                                  type="text"
                                  className="inpfield"
                                  placeholder="Year"
                                  name="endDateYear"
                                  value={this.state.tmpexperience.endDateYear}
                                  onChange={this.handleExpInputChange}
                                />
                                </>
                                }

                              </div>
                              
                              {!this.state.expChecked && this.UpdateExperience.message(
                                "to year",
                                this.state.tmpexperience.endDateYear,
                                "required|numeric"
                                
                              )}
                              
                              <input
                                 type="checkbox"
                                 defaultChecked={true}
                                 className="inpfield checkClass"
                                 name="iscurrent"
                                 value={this.state.tmpexperience.iscurrent}
                                 onChange={this.handleExpInputChange}
                                 ref={this.checkbox}
                               />
                               I currently work here

                               {this.state.expChecked &&
                                <> 
                                  <input type="hidden" name="endDateMonth" value={0} />
                                  <input type="hidden" name="endDateYear" value={0} />
                                  
                                </>
                                }
                            </div>
                          </div>

                          <div className="expDetails">
                            <h2>Description</h2>
                            <textarea
                              className="inpfield fieldWidth"
                              placeholder="Description"
                              name="exp_desc"
                              value={this.state.tmpexperience.exp_desc}
                              onChange={this.handleExpInputChange}
                            >
                              {this.state.experience.exp_desc}
                            </textarea>
                          </div>

                          {this.UpdateExperience.message(
                            "description",
                            this.state.tmpexperience.exp_desc,
                            "required|string"
                          )}

                          <div className="sidebar-content iconContent">
                            <div className="header-content">
                              <h3 className="header attachText">Attach:</h3>
                              <div className="post-pannel attachIconsDiv">
                                <div className="post-pannel-content attachIconsprofile">
                                  <div className="pannel-icon tooltip">
                                    <img
                                      src={Files}
                                      alt="files"
                                      title="file"
                                      onClick={this.ShowHideAttach}
                                      name="pexpfle"
                                      className="img-big resImg"
                                    />
                                    <span className="tooltiptext">File</span>
                                  </div>
                                </div>

                                <div className="post-pannel-content attachIconsprofile">
                                  <div className="pannel-icon tooltip">
                                    <img
                                      src={CameraIcon}
                                      alt="camera-icon"
                                      className="img-big resImg"
                                      onClick={this.ShowHideAttach}
                                      name="pexpphot"
                                    />
                                    <span className="tooltiptext">Photo</span>
                                  </div>
                                </div>

                                  <div className="post-pannel-content attachIconsprofile">
                                <div className="pannel-icon tooltip">
                                  <img src={VideoIcon} 
                                    alt="video-icon"
                                    onClick={this.ShowHideAttach}
                                    name="pexpprest"
                                    className="img-big resImg"
                                  />
                                  <span className="tooltiptext">Video</span>
                                </div>
                              </div> 

                                <div className="post-pannel-content attachIconsprofile">
                                  <div className="pannel-icon tooltip">
                                    <img
                                      src={AddLink}
                                      alt="add-link"
                                      onClick={this.ShowHideAttach}
                                      name="pexplnk"
                                      className="img-big resImg"
                                    />
                                    <span className="tooltiptext">Link</span>
                                  </div>
                                </div>

                                <div className="post-pannel-content attachIconsprofile">
                                  <div className="pannel-icon tooltip">
                                    <img
                                      src={Youtube}
                                      alt="youtube"
                                      className="img-big resImg"
                                      onClick={this.ShowHideAttach}
                                      name="pexpvdeo"
                                    />
                                    <span className="tooltiptext">Youtube</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className={
                                this.state.isShowMedia === "pexpfle"
                                  ? ""
                                  : "d-none"
                              }
                            >
                              <input
                                type="file"
                                name="fle"
                                className="input-field field"
                                ref={this.pexpfle}
                                accept="audio/*,video/*,image/*,.doc,.docx,.pdf,.ppt,.pptx"
                                onChange={(e) => {
                                  this.setState({
                                    new_expfle: e.target,
                                  });
                                  this.UpdateExperience.allValid();
                                }}
                              />

                              {this.UpdateExperience.message(
                                "File ",
                                this.state?.new_expfle,
                                "PostFiles"
                              )}

                              <div className="d-flex d_preview_images">
                                {Array.isArray(
                                  this.state.tmpexperience?.expfle
                                )
                                  ? this.state.tmpexperience?.expfle.map(
                                      (doc) => {
                                        return (
                                          <div className="image-area" id={doc}>
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/images/docu.png"
                                              }
                                              target={"_blank"}
                                              alt=""
                                              onClick={(e) => {
                                                e.preventDefault();
                                                saveAs(doc);
                                              }}
                                            />
                                            <a
                                              className="remove-image"
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.deleteProfileFile(
                                                  this.state.tmpexperience
                                                    ?.expuid,
                                                  this.state.tmpexperience
                                                    ?.expid,
                                                  "experience",
                                                  doc,
                                                  "file"
                                                );
                                              }}
                                            >
                                              &#215;
                                            </a>
                                          </div>
                                        );
                                      }
                                    )
                                  : ""}
                              </div>
                            </div>

                            <div
                              className={
                                this.state.isShowMedia === "pexpphot"
                                  ? ""
                                  : "d-none"
                              }
                            >
                              <input
                                type="file"
                                className="input-field field"
                                name="pho"
                                ref={this.pexpphot}
                                accept="image/*"
                                onChange={(e) => {
                                  this.setState({
                                    new_exppho: e.target,
                                  });
                                  this.UpdateExperience.allValid();
                                }}
                              />

                              {this.UpdateExperience.message(
                                "Images ",
                                this.state?.new_exppho,
                                "Photos"
                              )}

                              <div className="d-flex d_preview_images">
                                {Array.isArray(
                                  this.state.tmpexperience?.expphot
                                )
                                  ? this.state.tmpexperience?.expphot.map(
                                      (img, key) => {
                                        return (
                                          <div
                                            className="image-area"
                                            key={key}
                                            id={img}
                                          >
                                            <img src={img} alt="" />
                                            <a
                                              className="remove-image"
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.deleteProfileFile(
                                                  this.state.tmpexperience
                                                    ?.expuid,
                                                  this.state.tmpexperience
                                                    ?.expid,
                                                  "experience",
                                                  img,
                                                  "photo"
                                                );
                                              }}
                                            >
                                              &#215;
                                            </a>
                                          </div>
                                        );
                                      }
                                    )
                                  : ""}
                              </div>
                            </div>

                            <div
                              className={
                                this.state.isShowMedia === "pexpprest"
                                  ? ""
                                  : "d-none"
                              }
                            >
                              <input
                                type="file"
                                className="input-field field"
                                name="pres"
                                ref={this.pexpprest}
                                accept="video/*"
                                onChange={(e) => {
                                  this.setState({
                                    new_exppres: e.target,
                                  });
                                  this.UpdateExperience.allValid();
                                }}
                              />

                              {this.UpdateExperience.message(
                                  "Videos ",
                                  this.state?.new_exppres,
                                  "VideoFiles"
                              )}

                              <div className="d-flex d_preview_images">
                              {
                                  (Array.isArray(this.state.tmpexperience?.expprest)) ? this.state.tmpexperience?.expprest.map(vid => {
                                      return <div className="image-area" id={vid}>
                                              <img src={process.env.PUBLIC_URL+'/images/film.png'}  onClick={(e) => {
                                                  e.preventDefault()
                                                  saveAs(vid)
                                              }} />
                                              <a className="remove-image" href="#" onClick={(e) => {
                                                  e.preventDefault();
                                                  this.deleteProfileFile(this.state.tmpexperience
                                                    ?.expuid,
                                                  this.state.tmpexperience
                                                    ?.expid, 'experience', vid, 'presentation' )
                                              }}>&#215;</a>
                                          </div>
                                  }) : ""
                              }
                              </div>
                            </div>



                            <div
                              className={
                                this.state.isShowMedia === "pexplnk"
                                  ? ""
                                  : "d-none"
                              }
                            >
                              <input
                                type="text"
                                name="explnk"
                                className="input-field field"
                                ref={this.pexplnk}
                                onChange={this.handleExpInputChange}
                                value={this.state.tmpexperience?.explnk}
                              />

                              {this.UpdateExperience.message(
                                "Links ",
                                this.state.tmpexperience?.explnk,
                                "multipleLinks"
                              )}
                            </div>

                            <div
                              className={
                                this.state.isShowMedia === "pexpvdeo"
                                  ? ""
                                  : "d-none"
                              }
                            >
                              <input
                                type="text"
                                className="input-field field"
                                name="expvde"
                                ref={this.pexpvdeo}
                                onChange={this.handleExpInputChange}
                                value={this.state.tmpexperience?.expvde}
                              />

                              {this.UpdateExperience.message(
                                "Links ",
                                this.state.tmpexperience?.expvde,
                                "multipleYoutubeLinks"
                              )}
                            </div>
                          </div>

                          <div className="expBtn btns">
                              <div className="expi-button">
                                <button
                                  type="button"
                                  id="savebtn"
                                  className="reset-button"
                                  onClick={() => {
                                    this.updateDetails("experience");
                                  }}
                                  disabled={this.state.isExpLoading}
                                >
                                  {this.state.isExpLoading ? <ButtonLoader color="#8a8989" /> : 'Save'}
                                </button>
                              </div>
                              <div className="expi-button">
                                <button
                                  type="button"
                                  id="cancelbtn"
                                  className="reset-button"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    this.newExp.current.reset();
                                    this.setState({
                                      newExp: false
                                    })
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>

                          {this.state.delExp && 
                            <div className="expi-button">
                                <button
                                  type="button"
                                  id="cancelbtn"
                                  className="reset-button removeBTN"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    this.newExp.current.reset();
                                    this.setState({
                                      newExp: false
                                    })
                                    this.removeDetails("experience");
                                  }}
                                  disabled={this.state.isDelExpLoading}
                                >
                                  {this.state.isDelExpLoading ? <ButtonLoader color="#8a8989" /> : 'Remove this position'}
                                  
                                </button>
                              </div>
                          } 
                              
                            </div>
                        </div>
                        
                      
                      </form>
                    </>
                  ) : (
                    <></>
                  )} 

                  {this.state.experience.map((exp, i) => (
                    <div
                      className="expContentDiv border-bottom margin-bottom-2"
                      key={i}
                    >
                      <div className="expDetails">
                        <label>
                          <b>Company Name:</b>{" "}
                          <span>{exp.pexpcompanyname}</span>
                        </label>
                        <button
                          className="editButton"
                          type="button"
                          onClick={() => {
                            this.EditExp(exp);
                          }}
                        >
                          <i className="fa fa-edit" aria-hidden="true" />
                          <span className="addposi">Edit</span>
                        </button>
                      </div>

                      <div className="expDetails">
                        <label>
                          <b>Title: </b> <span>{exp.pexptitle}</span>
                        </label>
                      </div>

                      <div className="expDetails">
                        <label>
                          <b>Location: </b>
                          <span>{exp.pexplocation}</span>
                        </label>
                      </div>

                      <div className="expDetails">
                          
                        <h2>
                          <label>
                            Time Period
                          </label>
                        </h2>
                        <div>
                          <label className="SelectMonth">
                            <span>
                              {moment()
                                .set("month", exp.pexpfrom_mon-1)
                                .format("MMMM")}
                            </span>
                            <span>
                              {moment()
                                .set("year", exp.pexpfrom_yr)
                                .format("YYYY")}
                            </span>
                            -
                            <span>
                              {/* Added by PBK to show Present when the option "I Currently work here is selected" */}
                              {(exp.pexpiscurrent === "") ?moment()
                                .set("month", exp.pexpto_mon-1)
                                .format("MMMM"):"Present"}
                            </span>
                            <span>
                              {(exp.pexpiscurrent === "") ?moment()
                                .set("year", exp.pexpto_yr)
                                .format("YYYY"):""}
                            </span>
                          </label>
                        </div>
                      </div>

                      <label className="expDetails">
                        <b>Description: </b>
                        <span>{exp.pexpdes}</span>
                      </label>
                    </div>
                  ))}
                </div>
             
            </div>


            {/* Education section */}
            <div className="expDiv" id="educt">
              <div id="educ" className="">
                <div className="exp_head">
                  <h2>Education</h2>
                  <button
                    className="editButton"
                    type="button"
                    onClick={() => {
                      this.EditEdu(null);
                    }}
                  >
                    <i className="fa fa-plus" aria-hidden="true" />
                    <span className="addposi">Add Qualification</span>
                  </button>
                </div>
              </div>

              {this.state.newEdu === true ? (
                <>
                  <form 
                    ref={this.newEdu}
                    encType="multipart/form-data"
                  >
                    <div className="expContentDiv">
                      <div className="expDetails">
                        <label>
                          School<span className="requiredField">*</span>
                        </label>
                        <input
                          type="text"
                          className="inpfield fieldWidth"
                          placeholder="School name"
                          name="schoolname"
                          value={this.state.tmpeducation.schoolname}
                          onChange={this.handleEduInputChange}
                        />

                        {this.UpdateEducation.message(
                          "School name",
                          this.state.tmpeducation.schoolname,
                          "required|string"
                        )}
                      </div>

                      <div className="expDetails">
                        <label>
                          Dates Attended<span className="requiredField">*</span>
                        </label>
                        <div className="SelectMonth selectYear">
                          <select
                            className="form-select inpfield"
                            aria-label="Default select example"
                            name="schoolfromyear"
                            value={this.state.tmpeducation.schoolfromyear}
                            onChange={this.handleEduInputChange}
                          >
                            {this.state.years.map((year, index) => {
                              return (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>

                          <select
                            className="form-select inpfield"
                            aria-label="Default select example"
                            name="schooltoyear"
                            value={this.state.tmpeducation.schooltoyear}
                            onChange={this.handleEduInputChange}
                          >
                            {this.state.years.map((year, index) => {
                              return (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>

                          <p>Or expected graduation year</p>

                          {this.UpdateEducation.message(
                            "school from year",
                            this.state.tmpeducation.schoolfromyear,
                            "required|numeric"
                          )}

                          {this.UpdateEducation.message(
                            "school to year",
                            this.state.tmpeducation.schooltoyear,
                            "required|numeric"
                          )}
                        </div>
                      </div>

                      <div className="expDetails">
                        <h2>Degree</h2>
                        <select
                          className="form-select inpfield"
                          aria-label="Default select example"
                          name="schoolDegree"
                          value={this.state.tmpeducation.schoolDegree}
                          onChange={this.handleEduInputChange}
                        >
                          <option defaultValue="" value="none">
                            -
                          </option>
                          <option value="High School">High School</option>
                          <option value="Associate's Degree">
                            Associate's Degree
                          </option>
                          <option value="Bachelor's Degree">
                            Bachelor's Degree
                          </option>
                          <option value="Master's Degree" defaultValue="">
                            Master's Degree
                          </option>
                          <option value="Master of Business Administration (M.B.A.)">
                            Master of Business Administration (M.B.A.)
                          </option>
                          <option value="Juris Doctor (J.D.)">
                            Juris Doctor (J.D.)
                          </option>
                          <option value="Doctor of Medicine (M.D.)">
                            Doctor of Medicine (M.D.)
                          </option>
                          <option value="Doctor of Philosophy (Ph.D.)">
                            Doctor of Philosophy (Ph.D.)
                          </option>
                          <option value="Engineer's Degree">
                            Engineer's Degree
                          </option>
                          <option value="other">Other</option>
                        </select>
                      </div>

                      {this.UpdateEducation.message(
                        "School degree",
                        this.state.tmpeducation.schoolDegree,
                        "required|string"
                      )}

                      <div className="expDetails">
                        <h2>Mode of study</h2>
                        <select
                          className="form-select inpfield"
                          aria-label="Default select example"
                          name="schoolMode"
                          value={this.state.tmpeducation.schoolMode}
                          onChange={this.handleEduInputChange}
                        >
                          <option defaultValue="" value="none">
                            -
                          </option>
                          <option value="fulltime" defaultValue="">
                            Fulltime
                          </option>
                          <option value="part time">Part Time</option>
                          <option value="distant education">
                            Distant Education
                          </option>
                          <option value="evening colleges">
                            Evening Colleges
                          </option>
                          <option value="other">Other</option>
                        </select>

                        {this.UpdateEducation.message(
                          "School grade",
                          this.state.tmpeducation.schoolMode,
                          "required|string"
                        )}
                      </div>

                      <div className="expDetails">
                        <h2>
                          Field of Study<span className="requiredField">*</span>
                        </h2>
                        <input
                          type="text"
                          className="inpfield fieldWidth"
                          name="schoolstudy"
                          value={this.state.tmpeducation.schoolstudy}
                          onChange={this.handleEduInputChange}
                        />

                        {this.UpdateEducation.message(
                          "School study",
                          this.state.tmpeducation.schoolstudy,
                          "required|string"
                        )}
                      </div>

                      <div className="expDetails">
                        <h2>Activities and Societies</h2>
                        <input
                          type="text"
                          className="inpfield fieldWidth"
                          name="school_act"
                          value={this.state.tmpeducation.school_act}
                          onChange={this.handleEduInputChange}
                        />

                        {this.UpdateEducation.message(
                          "School activity",
                          this.state.tmpeducation.school_act,
                          "required|string"
                        )}
                      </div>

                      <div className="expDetails">
                        <h2>Description</h2>
                        <textarea
                          className="inpfield fieldWidth"
                          placeholder="Description"
                          name="exp_desc"
                          value={this.state.tmpexperience.exp_desc}
                          onChange={this.handleEduInputChange}
                        >
                          {this.state.tmpeducation.exp_desc}
                        </textarea>

                        {this.UpdateEducation.message(
                          "description",
                          this.state.tmpeducation.exp_desc,
                          "required|string"
                        )}
                      </div>

                      <div className="sidebar-content iconContent">
                        <div className="header-content">
                          <h3 className="header attachText">Attach:</h3>
                          <div className="post-pannel attachIconsDiv">
                            <div className="post-pannel-content attachIconsprofile">
                              <div className="pannel-icon tooltip">
                                <img
                                  src={Files}
                                  alt="files"
                                  title="file"
                                  onClick={this.ShowHideAttach}
                                  name="edufle"
                                  className="img-big resImg"
                                />
                                <span className="tooltiptext">File</span>
                              </div>
                            </div>

                            <div className="post-pannel-content attachIconsprofile">
                              <div className="pannel-icon tooltip">
                                <img
                                  src={CameraIcon}
                                  alt="camera-icon"
                                  className="img-big resImg"
                                  onClick={this.ShowHideAttach}
                                  name="edupho"
                                />
                                <span className="tooltiptext">Photo</span>
                              </div>
                            </div>

                            <div className="post-pannel-content attachIconsprofile">
                              <div className="pannel-icon tooltip">
                                <img
                                  src={VideoIcon}
                                  alt="video-icon"
                                  onClick={this.ShowHideAttach}
                                  name="edupres"
                                  className="img-big resImg"
                                />
                                <span className="tooltiptext">Video</span>
                              </div>
                            </div>

                            <div className="post-pannel-content attachIconsprofile">
                              <div className="pannel-icon tooltip">
                                <img
                                  src={AddLink}
                                  alt="add-link"
                                  onClick={this.ShowHideAttach}
                                  name="edulnk"
                                  className="img-big resImg"
                                />
                                <span className="tooltiptext">Link</span>
                              </div>
                            </div>

                            <div className="post-pannel-content attachIconsprofile">
                              <div className="pannel-icon tooltip">
                                <img
                                  src={Youtube}
                                  alt="youtube"
                                  className="img-big resImg"
                                  onClick={this.ShowHideAttach}
                                  name="eduvde"
                                />
                                <span className="tooltiptext">Youtube</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={
                            this.state.isShowMedia === "edufle" ? "" : "d-none"
                          }
                        >
                          <input
                            type="file"
                            name="fle"
                            className="input-field field"
                            ref={this.edufle}
                            accept="audio/*,video/*,image/*,.doc,.docx,.pdf,.ppt,.pptx"
                            onChange={(e) => {
                              this.setState({
                                new_edufle: e.target
                              })
                              this.UpdateEducation.allValid()
                            }}
                          />

                          {this.UpdateEducation.message(
                            "File ",
                            this.state?.new_edufle,
                            "PostFiles"
                          )}

                          <div className="d-flex d_preview_images">
                            {
                                (Array.isArray(this.state.tmpeducation?.edufle)) ? this.state.tmpeducation?.edufle.map(doc => {
                                    return <div className="image-area" id={doc}>
                                            <img src={process.env.PUBLIC_URL+'/images/docu.png'} target={'_blank'} alt="" onClick={(e) => {
                                                e.preventDefault()
                                                saveAs(doc)
                                            }} />
                                            <a className="remove-image" href="#" onClick={(e) => {
                                                e.preventDefault();
                                                this.deleteProfileFile(this.state.tmpeducation?.edupuid, this.state.tmpeducation?.edupid, 'education', doc, 'file' )
                                            }}>&#215;</a>
                                        </div>
                                }) : ""
                            }
                          </div>
                        </div>

                        <div
                          className={
                            this.state.isShowMedia === "edupho" ? "" : "d-none"
                          }
                        >
                          <input
                            type="file"
                            className="input-field field"
                            name="pho"
                            ref={this.edupho}
                            accept="image/*"
                            onChange={(e) => {
                              this.setState({
                                new_edupho: e.target
                              })
                              this.UpdateEducation.allValid()
                            }}
                          />

                          {this.UpdateEducation.message(
                            "Photos ",
                            this.state?.new_edupho,
                            "Photos"
                          )}

                          <div className="d-flex d_preview_images">
                            {
                                (Array.isArray(this.state.tmpeducation?.edupho)) ? this.state.tmpeducation?.edupho.map(img => {
                                    return <div className="image-area" id={img}>
                                            <img src={img}  alt="" />
                                              <a className="remove-image" href="#" onClick={(e) => {
                                                  e.preventDefault();
                                                  this.deleteProfileFile(this.state.tmpeducation?.edupuid, this.state.tmpeducation?.edupid, 'education', img, 'photo' )
                                              }}>&#215;</a>
                                        </div>
                                }) : ""
                            }
                          </div>
                        </div>

                        <div
                          className={
                            this.state.isShowMedia === "edupres" ? "" : "d-none"
                          }
                        >
                          <input
                            type="file"
                            className="input-field field"
                            name="pres"
                            ref={this.edupres}
                            accept="video/*"
                            onChange={(e) => {
                              this.setState({
                                  new_edupres: e.target
                              })
                              this.UpdateEducation.allValid()
                          }}
                          />

                          {this.UpdateEducation.message(
                              "Videos ",
                              this.state?.new_edupres,
                              "VideoFiles"
                          )}

                          <div className="d-flex d_preview_images">
                          {
                              (Array.isArray(this.state.tmpeducation?.edupres)) ? this.state.tmpeducation?.edupres.map(vid => {
                                  return <div className="image-area" id={vid}>
                                          <img src={process.env.PUBLIC_URL+'/images/film.png'}  onClick={(e) => {
                                              e.preventDefault()
                                              saveAs(vid)
                                          }} />
                                          <a className="remove-image" href="#" onClick={(e) => {
                                              e.preventDefault();
                                              this.deleteProfileFile(this.state.tmpeducation?.edupuid, this.state.tmpeducation?.edupid, 'education', vid, 'presentation' )
                                          }}>&#215;</a>
                                      </div>
                              }) : ""
                          }
                          </div>
                        </div>

                        <div
                          className={
                            this.state.isShowMedia === "edulnk" ? "" : "d-none"
                          }
                        >
                          <input
                            type="text"
                            name="edulnk"
                            className="input-field field"
                            ref={this.edulnk}
                            onChange={this.handleEduInputChange}
                            value={this.state?.tmpeducation?.edulnk}
                          />
                           {this.UpdateEducation.message(
                              "Links ",
                              this.state?.tmpeducation?.edulnk,
                              "multipleLinks"
                            )}
                        </div>

                        <div
                          className={
                            this.state.isShowMedia === "eduvde" ? "" : "d-none"
                          }
                        >
                          <input
                            type="text"
                            className="input-field field"
                            name="eduvde"
                            ref={this.eduvde}
                            value={this.state?.tmpeducation?.eduvde}
                            onChange={this.handleEduInputChange}
                          />
                          {this.UpdateEducation.message(
                            "Links",
                            this.state?.tmpeducation?.eduvde,
                            "multipleYoutubeLinks"
                          )}
                        </div>
                      </div>
                      <div className="expBtn btns">
                        <div className="expi-button">
                          <button
                            type="button"
                            id="savebtn"
                            className="reset-button"
                            onClick={() => {
                              this.updateDetails("education");
                            }}
                            disabled={this.state.isEduLoading}
                          >
                            {this.state.isEduLoading ? <ButtonLoader color="#8a8989" /> : 'Save'}
                          </button>
                        </div>
                        <div className="expi-button">
                          <button
                            type="button"
                            id="cancelbtn"
                            className="reset-button"
                            onClick={(e) => {
                              e.preventDefault()
                              this.newEdu.current.reset();
                              this.setState({
                                newEdu: false
                              })
                            }}
                          >
                            Cancel
                          </button>
                        </div>

                        {this.state.delEdu && 
                        <div className="expi-button">
                          <button
                            type="button"
                            id="cancelbtn"
                            className="reset-button removeBTN"
                            onClick={(e) => {
                              e.preventDefault()
                              this.newEdu.current.reset();
                              this.setState({
                                newEdu: false
                              })
                              this.removeDetails("education");
                            }}
                            disabled={this.state.isDelEduLoading}
                          >
                            {this.state.isDelEduLoading ? <ButtonLoader color="#8a8989" /> : 'Remove this school'}
                            
                          </button>
                        </div>
                        }
                        
                      </div>
                    </div>
                    
                 
                  </form>
                </>
              ) : (
                <></>
              )}

              {this.state.education.map((edu, i) => (
                <div
                  className="expContentDiv border-bottom margin-bottom-2"
                  key={i}
                >
                  <div className="expDetails">
                    <label>
                      <b>School:</b> <span>{edu.pedupschoolname}</span>
                    </label>
                    <button
                      className="editButton"
                      type="button"
                      onClick={() => {
                        this.EditEdu(edu);
                      }}
                    >
                      <i className="fa fa-edit" aria-hidden="true" />
                      <span className="addposi">Edit</span>
                    </button>
                  </div>

                  <div className="expDetails">
                    <label>
                      <b>Dates Attended: </b>
                    </label>
                    <div>
                      <label className="SelectMonth">
                        <span>
                          {moment()
                            .set("year", edu.peduschoolfromyear)
                            .format("YYYY")}
                        </span>
                        -
                        <span>
                          {moment()
                            .set("year", edu.peduschooltoyear)
                            .format("YYYY")}
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="expDetails">
                    <label>
                      <b>Degree: </b>
                      <span>{edu.peduschoolDegree}</span>
                    </label>
                  </div>

                  <div className="expDetails">
                    <label>
                      <b>Mode of study: </b>
                      <span>{edu.peduschoolograde}</span>
                    </label>
                  </div>

                  <div className="expDetails">
                    <label>
                      <b>Field of Study: </b>
                      <span>{edu.peduschoolstudy}</span>
                    </label>
                  </div>

                  <div className="expDetails">
                    <label>
                      <b>Activities and Societies: </b>
                      <span>{edu.peduschool_act}</span>
                    </label>
                  </div>

                  <div className="expDetails">
                    <label>
                      <b>Description: </b>
                      <span>{edu.peduexp_desc}</span>
                    </label>
                  </div>
                </div>
              ))}
            </div> 
            </>
        ) : (
          <>
          </>
        )}

            {/* Honor section */}
            <div className="expDiv honDiv">
              <div className="exp_head">
                <h2>Honors & Awards</h2>
                <button
                  className="editButton"
                  type="button"
                  onClick={() => {
                    this.EditHon(null);
                  }}
                >
                  <i className="fa fa-plus" aria-hidden="true" />
                  <span className="addposi">Add Honor</span>
                </button>
              </div>
              {this.state.newHon === true ? (
                <>
                  <form ref={this.newHon} onSubmit={(e) => {e.preventDefault(); }} encType="multipart/form-data">
                    <div id="honaR" className="borBotm">
                      <div className="honorContent">
                        <div className="expDetails">
                          <h2>
                            Title<span className="requiredField">*</span>
                          </h2>
                          <input
                            type="text"
                            name="hons_title"
                            className="inpfield fieldWidth"
                            value={this.state.tmphonor.hons_title}
                            onChange={this.handleHonInputChange}
                          />

                          {this.Updatehonors.message(
                            "title",
                            this.state.tmphonor.hons_title,
                            "required|string"
                          )}
                        </div>

                        <div className="expDetails">
                          <h2>
                            Date<span className="requiredField">*</span>
                          </h2>
                          <div className="SelectMonth">
                            <select
                              className="form-select inpfield selectSpace"
                              aria-label="Default select example"
                              name="honrmnth"
                              value={this.state.tmphonor.honrmnth}
                              onChange={this.handleHonInputChange}
                            >
                              <option defaultValue value="">
                                Choose...
                              </option>
                              <option value="1">January</option>
                              <option value="2">February</option>
                              <option value="3">March</option>
                              <option value="4">April</option>
                              <option value="5">May</option>
                              <option value="6">June</option>
                              <option value="7">July</option>
                              <option value="8">August</option>
                              <option value="9">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </select>
                            <input
                              type="text"
                              className="inpfield"
                              placeholder="Year"
                              name="honryr"
                              value={this.state.tmphonor.honryr}
                              onChange={this.handleHonInputChange}
                            />
                          </div>

                          {this.Updatehonors.message(
                            "year",
                            this.state.tmphonor?.honryr,
                            "required|numeric"
                          )}
                        </div>
                        <div className="expDetails">
                          <h2>Description</h2>

                        <textarea
                          className="inpfield fieldWidth"
                          placeholder="Description"
                          name="hons_desc"
                          value={this.state.tmphonor.hons_desc}
                          onChange={this.handleHonInputChange}
                        >
                          {this.state.tmphonor.hons_desc}
                        </textarea>

                          {this.Updatehonors.message(
                            "description",
                            this.state.tmphonor.hons_desc,
                            "required|string"
                          )}
                        </div>

                        <div className="sidebar-content iconContent iconSpace">
                          <div className="header-content">
                            <h3 className="header attachText">Attach:</h3>
                            <div className="post-pannel attachIconsDiv">
                              <div className="post-pannel-content attachIconsprofile">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={Files}
                                    alt="files"
                                    title="file"
                                    name="honfle"
                                    onClick={this.ShowHideAttach}
                                    className="img-big resImg"
                                  />
                                  <span className="tooltiptext">File</span>
                                </div>
                              </div>

                              <div className="post-pannel-content attachIconsprofile">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={CameraIcon}
                                    alt="camera-icon"
                                    className="img-big resImg"
                                    onClick={this.ShowHideAttach}
                                    name="honpho"
                                  />
                                  <span className="tooltiptext">Photo</span>
                                </div>
                              </div>

                              <div className="post-pannel-content attachIconsprofile">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={VideoIcon}
                                    alt="video-icon"
                                    onClick={this.ShowHideAttach}
                                    name="honpres"
                                    className="img-big resImg"
                                  />
                                  <span className="tooltiptext">Video</span>
                                </div>
                              </div>

                              <div className="post-pannel-content attachIconsprofile">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={AddLink}
                                    alt="add-link"
                                    onClick={this.ShowHideAttach}
                                    name="honlnk"
                                    className="img-big resImg"
                                  />
                                  <span className="tooltiptext">Link</span>
                                </div>
                              </div>

                              <div className="post-pannel-content attachIconsprofile">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={Youtube}
                                    alt="youtube"
                                    className="img-big resImg"
                                    onClick={this.ShowHideAttach}
                                    name="honvde"
                                  />
                                  <span className="tooltiptext">Youtube</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className={
                              this.state.isShowMedia === "honfle" ? "" : "d-none"
                            }
                          >
                            <input
                              type="file"
                              name="fle"
                              className="input-field field"
                              ref={this.honfle}
                              accept="audio/*,video/*,image/*,.doc,.docx,.pdf,.ppt,.pptx"
                              onChange={(e) => {
                                this.setState({
                                  new_honfle: e.target
                                })
                                this.Updatehonors.allValid()
                              }}
                            />

                            {this.Updatehonors.message(
                              "File ",
                              this.state?.new_honfle,
                              "PostFiles"
                            )}

                            <div className="d-flex d_preview_images">
                              {
                                  (Array.isArray(this.state.tmphonor?.honfle)) ? this.state.tmphonor?.honfle.map(doc => {
                                      return <div className="image-area" id={doc}>
                                              <img src={process.env.PUBLIC_URL+'/images/docu.png'} target={'_blank'} alt="" onClick={(e) => {
                                                  e.preventDefault()
                                                  saveAs(doc)
                                              }} />
                                              <a className="remove-image" href="#" onClick={(e) => {
                                                  e.preventDefault();
                                                  this.deleteProfileFile(this.state.tmphonor?.honuid, this.state.tmphonor?.honid, 'honors', doc, 'file' )
                                              }}>&#215;</a>
                                          </div>
                                  }) : ""
                              }
                            </div>
                          </div>

                          <div
                            className={
                              this.state.isShowMedia === "honpho" ? "" : "d-none"
                            }
                          >
                            <input
                              type="file"
                              className="input-field field"
                              name="pho"
                              ref={this.honpho}
                              accept="image/*"
                              onChange={(e) => {
                                this.setState({
                                    new_honpho: e.target
                                })
                                this.Updatehonors.allValid()
                            }}
                            />

                            {this.Updatehonors.message(
                                "Images ",
                                this.state?.new_honpho,
                                "Photos"
                            )}

                            <div className="d-flex d_preview_images">
                                {
                                    (Array.isArray(this.state.tmphonor?.honphot)) ? this.state.tmphonor?.honphot.map((img, key) => {
                                        return <div className="image-area" key={key} id={img} >
                                                <img src={img}  alt="" />
                                                <a className="remove-image" href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.deleteProfileFile(this.state.tmphonor?.honuid, this.state.tmphonor?.honid, 'honors', img, 'photo' )
                                                }}>&#215;</a>
                                            </div>
                                    }) : ""
                                }
                            </div>
                          </div>

                          <div
                            className={
                              this.state.isShowMedia === "honpres" ? "" : "d-none"
                            }
                          >
                            <input
                              type="file"
                              className="input-field field"
                              name="pres"
                              ref={this.honpres}
                              accept="video/*"
                              onChange={(e) => {
                                this.setState({
                                    new_honpres: e.target
                                })
                                this.Updatehonors.allValid()
                            }}
                            />

                            {this.Updatehonors.message(
                                "Videos ",
                                this.state?.new_honpres,
                                "VideoFiles"
                            )}

                            <div className="d-flex d_preview_images">
                            {
                                (Array.isArray(this.state.tmphonor?.honprest)) ? this.state.tmphonor?.honprest.map(vid => {
                                    return <div className="image-area" id={vid}>
                                            <img src={process.env.PUBLIC_URL+'/images/film.png'}  onClick={(e) => {
                                                e.preventDefault()
                                                saveAs(vid)
                                            }} />
                                            <a className="remove-image" href="#" onClick={(e) => {
                                                e.preventDefault();
                                                this.deleteProfileFile(this.state.tmphonor?.honuid, this.state.tmphonor?.honid, 'honors', vid, 'presentation' )
                                            }}>&#215;</a>
                                        </div>
                                }) : ""
                            }
                            </div>
                          </div>

                          <div
                            className={
                              this.state.isShowMedia === "honlnk" ? "" : "d-none"
                            }
                          >
                            <input
                              type="text"
                              name="honlnk"
                              className="input-field field"
                              value={this.state?.tmphonor?.honlnk}
                              placeholder="Enter your link"
                              onChange={this.handleHonInputChange}
                            />

                            {this.Updatehonors.message(
                              "Links ",
                              this.state?.tmphonor?.honlnk,
                              "multipleLinks"
                            )}
                          </div>

                          <div
                            className={
                              this.state.isShowMedia === "honvde" ? "" : "d-none"
                            }
                          >
                            <input
                              type="text"
                              className="input-field field"
                              name="honvde"
                              value={this.state?.tmphonor?.honvde}
                              onChange={this.handleHonInputChange}
                              placeholder="Paste the youtube video link here"
                            />
                            {this.Updatehonors.message(
                              "Links ",
                              this.state?.tmphonor?.honvde,
                              "multipleYoutubeLinks"
                            )}
                          </div>
                          {/* </div> */}
                        </div>
                        <div className="expBtn btns">
                       
                          <div className="expi-button">
                            <button
                              type="button"
                              id="savebtn"
                              className="reset-button"
                              onClick={() => {
                                this.updateDetails("honors");
                              }}
                              disabled={this.state.isHonorLoading}
                            >
                              {this.state.isHonorLoading ? <ButtonLoader color="#8a8989" /> : 'Save'}
                            </button>
                          </div>
                          <div className="expi-button">
                            <button
                              type="button"
                              id="cancelbtn"
                              className="reset-button"
                              onClick={() => {
                                this.resetHonor();
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                          {this.state.delHonor && 
                            <div className="expi-button">
                              <button
                                type="button"
                                id="savebtn"
                                className="reset-button removeBTN"
                                onClick={() => {
                                  this.removeDetails("honors");
                                }}
                                disabled={this.state.isDelHonorLoading}
                              >
                                {this.state.isDelHonorLoading ? <ButtonLoader color="#8a8989" /> : 'Remove this honor'}
                              </button>
                            </div>
                          }
                          
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              ) : (
                <></>
              )}

              {this.state.honors.map((hon, i) => (
                <div
                  className="expContentDiv border-bottom margin-bottom-2"
                  key={i}
                >
                  <div className="expDetails">
                    <label>
                      <b>Title:</b> <span>{hon.phontitle}</span>
                    </label>
                    <button
                      className="editButton"
                      type="button"
                      onClick={() => {
                        this.EditHon(hon);
                      }}
                    >
                      <i className="fa fa-edit" aria-hidden="true" />
                      <span className="addposi">Edit</span>
                    </button>
                  </div>

                  <div className="expDetails">
                    <label>
                      <b>Dates: </b>
                    </label>
                    <div>
                      <label className="SelectMonth">
                        <span>
                          
                          {moment()
                            .set("month", hon.phonmonth-1)
                            .format("MMMM")}
                        </span>
                        -
                        <span>
                          {moment()
                            .set("year", hon.phonyear)
                            .format("YYYY")}
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="expDetails">
                    <label>
                      <b>Description: </b>
                      <span>{hon.phondesc}</span>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(Profile);
