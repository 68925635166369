import React, { Component } from "react";
import axios from "axios";
import ShowMoreText from "react-show-more-text";
import { Link, withRouter } from "react-router-dom";
import "../product/Product.css";
import "../product-detail/ProductDetailed.css";
import Parser from "html-react-parser";
import { Report } from "../report/Report";

import ChatButton from "../../components/chatbutton/ChatButton";
import ContactService from "../contactservice/ContactService";
import productEmail from "../../../assets/product-email.png";
import moment from "moment";

export class ServiceDetailed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactSupplierModel: false,
      details: {},
      ispageShow: false,
      relatedDetails: [],
      enquiry: "",

      userName: "",
      userEmail: "",
      userMobile: "",

      reportModal: false,
      serialId: "",
      reportModule: "",

      Totaldays: "",
    };
  }

  // set ternery condition on the create post modal for showing the modal
  showModal = async () => {
    const contactSupplierModel =
      this.state.contactSupplierModel === false ? true : false;
    await this.setState({
      contactSupplierModel: contactSupplierModel,
    });
  };

  hideContactModal = async () => {
    await this.setState({
      contactSupplierModel: false,
    });
  };

  reportModal = async (software) => {
    const reportModal = this.state.reportModal === false ? true : false;
    await this.setState({
      reportModal: reportModal,
      reportModule: software,
    });
  };

  hideReportModal = async () => {
    await this.setState({
      reportModal: false,
    });
  };

  relatedServices = async () => {
   
    const id = this.props.match.params.id;

    const products = await axios.get(
      `${process.env.REACT_APP_API_URL}service/view/${id}`,

      {
        headers: {
          Auth: `${localStorage.getItem("token")}`,
        },
      }
    );
      
    if (products.status === 200 && products.data.status === true) {
      this.setState({
        details: products.data.datas,
        ispageShow: true,
        serialId: id,
      });

      var result = moment().diff(this.state.details?.adddate, "year");
      if (result > 0) {
        this.setState({
          Totaldays: `${result} Years`,
        })
      }
      else if(moment().diff(this.state.details?.adddate, "month") > 0){
        var result = moment().diff(this.state.details?.adddate, "month");
        this.setState({
          Totaldays: `${result} Months`,
        })
      }
      else if(moment().diff(this.state.details?.adddate, "days") > 0){
        var result = moment().diff(this.state.details?.adddate, "days");
        this.setState({
          Totaldays: `${result} Days`,
        })
      }
      else{
        var result = moment().diff(this.state.details?.adddate, "hours");
        this.setState({
          Totaldays: `${result} Hours`,
        })
      }

    } else {
      this.props.history.back();
    }
    // related products
    const relatedData = {
      city: this.state.details.city,
      company: this.state.details.company,
      country: this.state.details.country,
      industry: this.state.details.industry,
      pincode: this.state.details.pincode,
      states: this.state.details.states,
    };

    try {
      const relatedProducts = await axios.post(
        `${process.env.REACT_APP_API_URL}service/getrelatedservices`,
        relatedData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (
        relatedProducts.status === 200 &&
        relatedProducts.data.status === true
      ) {
        this.setState({
          relatedDetails: relatedProducts.data.datas,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }
    }

  }

  componentDidMount = async () => {

    const userData = localStorage.getItem("userData");

    const userDetails = JSON.parse(userData);

    await this.setState({
      userName: userDetails.userName,
      userEmail: userDetails.userEmail,
      userMobile: userDetails.userMobile,
    });

    await this.relatedServices();
  };

  async componentDidUpdate(prevProps, prevState) {

    if (this.props.match.params.id !== prevProps.match.params.id) {
      await this.relatedServices();
    }
  }

  render() {
    const softUid = Object.keys(this.state.details).length
      ? this.state.details.uid
      : "";

    return (
      <>
        {this.state.contactSupplierModel ? (
          <ContactService
            hideModal={this.hideContactModal}
            recipientName={this.state.details.company}
            senderName={this.state.userName}
            senderEmail={this.state.userEmail}
            senderPhone={this.state.userMobile}
          />
        ) : (
          ""
        )}

        {this.state.reportModal ? (
          <Report
            hideReportModal={this.hideReportModal}
            softwareId={this.state.serialId}
            softwareUid={softUid}
            module={this.state.reportModule}
          />
        ) : (
          ""
        )}

        {this.state.ispageShow ? (
          <section className="detailed-software">
            <div className="detailed-container">
              <div className="report-button"
                onClick={() => this.reportModal("service")}
              >
                <button type="button" className="caepipe-download">
                  <i className="fas fa-chevron-down" />
                </button>
                <div className="report-container">
                  <button
                    type="button"
                    className="report-block"

                  >
                    <h2 className="header">Report Abuse</h2>
                  </button>
                </div>
              </div>

              <div className="download-button">
                <button
                  type="button"
                  className="caepipe-download"
                  id="service-enquiry"
                  onClick={this.showModal}
                >
                  <img src={productEmail} alt="productEmail" />
                </button>
              </div>

              <div className="detail-header">
                <h1 className="header">{this.state.details.industry}</h1>
              </div>

              <div className="content-container content-container-part">
                <div className="caepipe-image">
                  <img src={this.state.details.logo} alt="" />
                </div>

                <div className="caepipe-info">
                  <div className="caepipe-location service-location">
                    <div className="location-icon">
                      <h1 className="header">Company</h1>
                      <div className="icon-content service-icon-content">
                        <h2 className="grey-header">
                          {this.state.details.company}
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div className="caepipe-location service-location service-location-two">
                    <div className="location-icon">
                      <h1 className="header">Industry</h1>
                      <h2 className="grey-header grey-headers">
                        {this.state.details.industry}
                      </h2>
                    </div>
                  </div>

                  <div className="caepipe-location service-location">
                    <div className="location-icon">
                      <h1 className="header">Location</h1>
                      <h2 className="grey-header grey-headers">
                        {this.state.details.city +
                          ", " +
                          this.state.details.states +
                          ", " +
                          this.state.details.country}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-description">
                <div className="service-description-content">
                  <div className="service-header">
                    <h1 className="header">Service Description</h1>
                  </div>
                  <div className="month-time">
                    <h1 className="grey-header">
                      ({moment(this.state.details.adddate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")
                        ? this.state.Totaldays + " ago"
                        : "Today"}
                      )
                    </h1>
                  </div>
                </div>

                <div className="caepipe-forum caepipe-forum-part">
                  <h2 className="header truncate">
                    <ShowMoreText
                      /* Default options */
                      lines={4}
                      more="Read more"
                      less="Read less"
                      className="content-css"
                      anchorClass="my-anchor-css-class"
                      onClick={this.executeOnClick}
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    >
                      {Parser(this.state.details.descrip)}
                    </ShowMoreText>
                  </h2>
                </div>
              </div>

              <div className="related-software-container">
                <div className="container-header">
                  <h1 className="header">Related Services</h1>
                </div>

                <div className="related-container">
                  <div className="related-content related-jobs-container">
                    {this.state.relatedDetails.map((details) => (
                      <Link to={`/service-detail/${details.id}`}>
                        <div className="product-boxes-content software-boxes-content">
                          <div className="product-image related-image related-jobs-image">
                            <img src={details.logo} alt="" />
                          </div>
                          <div className="product-info software-info">
                            <div className="product-name">
                              <h2 className="header">{details.company}</h2>
                            </div>
                            <div className="product-place software-content related-jobs-content">
                              <h2 className="header grey-header">
                                {details.industry}
                              </h2>
                            </div>
                            <div className="product-place software-content related-jobs-content">
                              <h2 className="header grey-header">
                                {details.city +
                                  ", " +
                                  details.states +
                                  ", " +
                                  details.country}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}

        <ChatButton />
      </>
    );
  }
}

export default withRouter(ServiceDetailed);
