import React, { Component } from 'react'
import "../layout2/Layout02.css"
import "./SponsorLayout.css"
import defaultImg from "../../../../assets/un.png";
import ShowMoreText from "react-show-more-text";
import { saveAs } from "file-saver";
import threeDots from "../../../../assets/3-dots.png";
import { Report } from "../../report/Report";

export default class SponsorLayout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            imgsrc: defaultImg,
            description:"",
            Spuser:"",
            post:[],
            showMenuListing: "",
            reportModal: false,
            ReportPostId: 0,
            reportModule: "sponsor",
            showMenuId: "",
        };
    }

    componentDidMount = async () => {

        const getSponsor = this.props.singleSponsor;

        await this.setState({
            title: getSponsor.title,
            imgsrc: getSponsor.image ? getSponsor.image : false,
            description: getSponsor.post,
            Spuser: getSponsor.name,
            post:getSponsor,
        })

    }

      // menulisting
    showMenu = (id, postUid) => {
        const showMenuListing = this.state.showMenuListing === id ? "" : id;

        this.setState({
            showMenuListing: showMenuListing,
            showMenuId: id,

            showFriendPostId: postUid,
        });
    };

    // report module
    reportModal = async (post, uid) => {
        const reportModal = this.state.reportModal === false ? true : false;
        await this.setState({
            showFriendPostId: "",
            showMenuListing: "",
            reportModal: reportModal,
            ReportPostId: uid,
        });
      };
    
      hideReportModal = async () => {
        await this.setState({
          reportModal: false,
        });
      };

    render() {
        return (

            <>
            {this.state.reportModal ? (
                <Report
                  hideReportModal={this.hideReportModal}
                  softwareId={this.state.showMenuId}
                  softwareUid={this.state.ReportPostId}
                  module={this.state.reportModule}
                />
              ) : (
                ""
              )}

            <div className="layout02_outer_div sponsor_layout_outer_div">

                <div className="profile-content">
                    <div className="post-info">
                        <div className="post-profile">
                        <img
                            src={
                            this.state.post.profileImage
                                ? this.state.post.profileImage
                                : "./images/no-image.png"
                            }
                            alt=""
                        />
                        </div>
                        <div className="post-content">
                        <div className="post-header">
                            <h2 className="header">
                                {this.state.post.name
                                    ? 
                                    <>
                                    <h1><strong> {this.state.post.name}</strong> Sponsored</h1>
                                    </>
                                    : "Sponsored"}
                                </h2>
                        </div>
                        
                        
                        </div>
                    </div>

                    <div className="menu-container">
                        <div className="menu-dots">
                            <img
                                src={threeDots}
                                alt="threeDots"
                                onClick={() =>
                                this.showMenu(this.state.post.id, this.state.post.uid)
                                }
                            />
                        </div>


                    <div
                        className={
                        "user-menu-content others-menu-content" +
                        (this.state.showMenuListing === this.state.post.id
                            ? " open"
                            : "")
                        }
                    >
                        <h2
                        className="header"
                        onClick={() =>
                            this.reportModal("sponsor", this.state.post.uid)
                        }
                        >
                        Report Abuse
                        </h2>
                    </div>

                    </div>
                </div>

                <div className="sp_layout">
                    <div className="layout02-title">
                        <h3>{this.state.title}</h3>
                    </div>

                    {(() => {
                        if (!!this.state.post?.image?.length > 0) {
                            
                            var img = this.state.post.image;
                            return (

                            <div className="layout-image">
                                <a
                                    href={img[0]}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        saveAs(img[0], "image");
                                    }}
                                    >
                                    <img
                                        src={this.state.post.image}
                                        className=""
                                        alt="sponsored_image"
                                    />
                                </a>
                            </div>

                            )
                        }

                        else if (!!this.state.post?.video?.length > 0) {

                            var video = this.state.post.video;
                            return (

                                <div className="layout-image">

                                    <video
                                        width="100%"
                                        height="300"
                                        controls
                                        >
                                        <source
                                            src={video[0]}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the
                                        video tag.
                                    </video>
                                
                                </div>
                            )
                        }

                        else if (!!this.state.post?.docu?.length > 0) {
                            var docu = this.state.post?.docu;
                            return (
                                <div className="layout-docu">

                                <a
                                    href={docu[0]}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        saveAs(docu[0], "document");
                                    }}
                                    >
                                    {docu?.length > 0 ? 
                                    <img
                                        src={docu[0]}
                                        className="doc_image"
                                        alt="document"
                                    />
                                    :
                                    <img
                                        src="/images/docu.png"
                                        className="doc_doc"
                                        alt="document"
                                    />
                                    }
                                </a>

                                </div>
                            )
                        }

                        else if (!!this.state.post?.ppt?.length > 0) {
                            var ppt = this.state.post?.ppt;
                            return (
                                
                                <a
                                    href={ppt[0]}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        saveAs(ppt[0], "document");
                                    }}
                                    >
                                    <img
                                        src="/images/docu.png"
                                        className="doc_doc"
                                        alt="document"
                                    />
                                </a>

                            )
                        }

                        else if (this.state.post.allvid) {
                            let text = this.state.post.allvid.trim();
                            if (text !== '') {
                                let result = text.replace("watch?v=","embed/");

                                return (
                                    <div className="layout-image">
                                        <iframe width="500" height="300" src={result} allowFullScreen="allowfullscreen">
    
                                        </iframe>
                                    </div>
                                )
                            }
                            
                            
                        }

                        else {
                            return ("")
                        }
                        
                        
                    })()}
         
                    <div className="layout-desc" id="layout-desc"> 
                        <ShowMoreText
                            /* Default options */
                            lines={10}
                            more="Read more"
                            less="Read less"
                            className="content-css"
                            anchorClass="my-anchor-css-class"
                            expanded={false}
                            truncatedEndingComponent={"... "}
                            color="red"
                            >
                            
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: `${new DOMParser().parseFromString(this.state?.description, "text/html").documentElement.textContent}`,
                                }}
                            />
                            
                        </ShowMoreText>
                    </div>
                </div>
            </div>
            </>
        )
    }
}
