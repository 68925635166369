import React, { Component } from "react";
import "./Pricing.css";
import table from "../../../assets/table.png";

export class Pricing extends Component {
  render() {
    return (
      <>
        <section className={`pricing-section ${(localStorage.getItem('token') ? 'after-login' : '')}`}>
          <div className="about-section-content">
            <div className="pannel-icon centerImg">
              <img src={table} alt="table-image" className="img-big" />
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Pricing;
