import "./Navbar.css";
import {
    Route,
    Link,
    Switch,
    withRouter,
    Redirect
} from "react-router-dom";
import React, {Component} from 'react'
import {AccordionButton, Dropdown} from 'react-bootstrap';
import {connect} from 'react-redux'
import {useHistory} from "react-router-dom";

import PostPage from "../../pages/postPage/Post-page";

import {useEffect} from "react";
import axios from "axios";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import pexit from "../../../assets/pexit-logo.png"
import homeicon from "../../../assets/homeicon.png"
import homeicon_fill from "../../../assets/homeicon_fill.png"
import messageIcon from "../../../assets/newmessage.png"
import notificationIcon from "../../../assets/notification-bell.png"
import myCircle from "../../../assets/my-circle.png"
import software from "../../../assets/software.png"
import product from "../../../assets/product.png"
import job from "../../../assets/job.png"
import service from "../../../assets/free&paid.png"
import myCircle_fill from "../../../assets/my-circle_fill.png"
import software_fill from "../../../assets/software_fill.png"
import product_fill from "../../../assets/product_fill.png"
import job_fill from "../../../assets/job_fill.png"
import service_fill from "../../../assets/free&paid_fill.png"
import {ChangePassword} from "../../pages/changepassword/ChangePassword";
import moment from "moment";



export class Navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: '',
            registerUsername: '',
            name: "",
            registerPassword: '',
            email: '',
            phone: '',
            country: "",
            block: false,
            DoLogin: false,
            setTo: null,
            reRender: null,
            pathname:null,
            
        }
    }

    async DoRegister() {
        const response = await axios.post(`${
            process.env.REACT_APP_API_URL
        }register`, {
            "name": this.state.name,
            "username": this.state.username,
            "email": this.state.email,
            "password": this.state.password,
            "block": this.state.block,
            "country": this.state.country,
            "phone": this.state.phone
        })

        if (response.data.status === true) {
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            // this.DoRegister = true
            // this.props.history.push('/postPage')
        } else if (response.data.status === false) {
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    }

    componentDidMount = async () => {

      const token = localStorage.getItem('token');
      if (token) {
        if (token !== '') {
            await this.setState({DoLogin: true})
        }
    }
    }
    
    render() {
        return (
            <>
                <div> {
                    this.state.DoLogin ? <NavbarLogin/>: (
                        <>
                            <nav className="navbar">
                                <div className="navbar-logo">
                                    <Link to='/'>
                                        <img src={pexit}
                                            alt="pexit"/>
                                    </Link>
                                </div>
                                <div className="navbar-action-buttons">
                                    <a href='https://cse.google.com/cse?cx=011980235390681569789:kimhkibrs4k'
                                        target={'_blank'}
                                        className="login-button">
                                        <i className="fas fa-search google_serach_icon"></i>
                                    </a>
                                    <Link to='/Login' className="login-button">
                                        <button type="button" className="login">Login</button>
                                    </Link>

                                    <Link to='/register' className="join-button">
                                        <button type="button" className="join-now"
                                            onClick={
                                                () => this.DoRegister()
                                        }>Join Now</button>
                                    </Link>
                                </div>
                            </nav>
                            <ToastContainer position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                className="toast-container"
                                toastClassName="dark-toast"/>
                        </>
                    )
                } </div>
            </>
        );
    }
};


export class NavbarLogin extends Component {
  constructor(props) {
    super(props);

    this.searchInput = React.createRef();
    this.localStorageUpdated = this.localStorageUpdated.bind(this)

    this.state = {
      changePassword: false,
      listing: {},
      profileImage: "",
      search: "",
      searchPlaceHolder: "Search...",
      checkedCategory: "Posts",
      modelIsOpen: false,
      redirect: null,
      withinSerach: false,
      isWithinSearch: true,
      oldSearch: "",

      notificationList: [],

      supportUsersList: [],
      options: [],
      isSupportUser: false,

      notiCount: 0,
      chatcount: 0,

      uname:"",

      showRefresh:false,
      pathname:window.location.pathname.replace("/",""),
      isHome:true,
      myCircle:true,
      isSoftware:true,
      isProduct:true,
      isJob:true,
      isService:true,
      newPathname:"/",

      userDetails:"",
      uid:0,
    }

  }
  
  localStorageUpdated = () => {
  }

  logOut() {
    localStorage.removeItem('token')
    localStorage.removeItem('userName')
    localStorage.removeItem('profileImage')
    localStorage.removeItem('userData')
    localStorage.removeItem('userLocation')
    window.location = process.env.PUBLIC_URL
  }     
                 
  
  showChangePass = () => {
    const changePassword =
      this.state.changePassword === false ? true : false;

    this.setState({
      changePassword: changePassword
    })
  }

  hideChangePass = () => {
    this.setState({
      changePassword: false,
    })
  }

  componentDidMount = async () => {
    await this.ProfileApi();
    await this.notificationList();
    
    const userData = localStorage.getItem("userData");
    const userDetails = JSON.parse(userData);

    await this.setState({
      isSupportUser: userDetails.isSupportUser,
      userDetails: userDetails,
    });
  }

  componentDidUpdate = async(prevProps, prevState) => {
    
    if (prevState.chatcount !== this.state.chatcount){
        await this.ProfileApi();
    }

    if (prevState.notiCount !== this.state.notiCount){
        await this.ProfileApi();
    }
    
    if(this.state.newPathname != window.location.pathname.replace("/","") ){
        this.setState({
            newPathname:window.location.pathname.replace("/",""),
        });
    }
  }

  ProfileApi = async () => {

    try {
      const common = await axios.get(
        `${process.env.REACT_APP_API_URL}common`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        });


        await this.setState({
          profileImage: common.data?.ProfileImage,
          chatcount: common.data?.ChatCount,
          uid: common.data?.uid,
          uname: common.data?.uname,
          notiCount: common.data?.nCount,
        });

        localStorage.setItem('profileImage', this.state.profileImage)
        window.sessionStorage.setItem("profileImage", this.state.profileImage)
        window.sessionStorage.setItem("chatcount", this.state.chatcount)
        window.sessionStorage.setItem("nCount", common.data?.nCount)
        localStorage.setItem('userId', this.state.uid)
        
      
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token")
        window.location = process.env.PUBLIC_URL
      }
    }
  }

    showChangePass = () => {
        const changePassword = this.state.changePassword === false ? true : false;

        this.setState({changePassword: changePassword})
    }

    hideChangePass = () => {
        this.setState({changePassword: false})
    }

    enterkey = async (e) => {

        if (e.keyCode === 13) {
            e.preventDefault();
            this.handleSearch();
        }
    }

    SubmitSearchform = async (e) => {

        e.preventDefault();
        this.handleSearch();

    }

    updateInputValue = async (evt) => {

        const val = evt.target.value;
        await this.setState({
            search: val ?. replace(/^\s+/g, '')
        });
    }

    setCategory(event) {
        const val = event.target.value;
        this.setState({
          searchPlaceHolder: val, 
          checkedCategory: val
        })
    }

    handleModel = async () => {
        document.getElementsByClassName("navbar-action-search")[1].style.display = "block";
        this.setState({
            modelIsOpen: (this.state.modelIsOpen) ? false : true
        })
    }

    closeModel = async () => {
        this.setState({modelIsOpen: false})
    }

    handleSearch = async () => {
        if (this.state.search === '') {
            this.searchInput.current.focus();
            this.setState({isWithinSearch: false})
        } else {
            await this.closeModel();
            let redirectPage = "";
            
            if (this.state.checkedCategory === 'Products') {
                redirectPage = "/product";
            } else if (this.state.checkedCategory === 'Posts') {
                redirectPage = "/";
                this.setState({isWithinSearch: true})
            } else if (this.state.checkedCategory === 'Connections') {
                redirectPage = "/my-circle";
            } else if (this.state.checkedCategory === 'Jobs') {
                redirectPage = "/job";
            } else if (this.state.checkedCategory === 'Services') {
                redirectPage = "/service";
            } else if (this.state.checkedCategory === 'Software') {
                redirectPage = "/software";
            } else if (this.state.checkedCategory === 'Product Suppliers') {
                redirectPage = "/supplier";
            }

            await this.setState({
                redirect: redirectPage,
                showRefresh:true,
            })
        }
    }

    notificationList = async () => {
        
        const notificationList = await axios.get(`${
            process.env.REACT_APP_API_URL
        }notification/list`, {
            headers: {
                Auth: `${
                    localStorage.getItem("token")
                }`
            }
        });
       
        if (notificationList.status === 200 && notificationList.data.status === true) {
            ;
            let notiCountt = notificationList.data.datas.length;
            
            this.setState({
                notificationList: notificationList.data.datas, 
                notiCount: notiCountt
            });

        };

    }

    supportUsers = async () => {
        const supportUsers = await axios.get(`${
            process.env.REACT_APP_API_URL
        }support-users`, {
            headers: {
                Auth: `${
                    localStorage.getItem("token")
                }`
            }
        });

        if (supportUsers.status === 200 && supportUsers.data.status === true) {
            await this.setState({
                supportUsersList: supportUsers.data.datas,
                options: supportUsers.data.datas.map((conn) => {
                    return {value: conn.id, label: conn.email}
                })
            });

        }

    };

    friendRequest = async (response, fid) => {

        const data = {
            fid: fid,
            option: response
        }

        const responseToRequest = await axios.post(`${
            process.env.REACT_APP_API_URL
        }friend/request`, data, {
            headers: {
                Auth: `${
                    localStorage.getItem("token")
                }`
            }
        });

        if (responseToRequest.status === 200 && responseToRequest.data.status === true) {

            await this.notificationList();
            await this.ProfileApi();

        }
    }

    groupRequest = async (response, reqid, gid) => {

        var ReqID = reqid !== "" ? reqid : 0;

        const data = {
            gdid: gid,
            reqid: ReqID,
            option: response
        }

        const responseToRequest = await axios.post(`${
            process.env.REACT_APP_API_URL
        }group/request`, data, {
            headers: {
                Auth: `${
                    localStorage.getItem("token")
                }`
            }
        });

        if (responseToRequest.status === 200 && responseToRequest.data.status === true) {
            await this.notificationList();
            await this.ProfileApi();
        }
    }

    toggle_sidebar = async (value) => {
        var left_sidebar = document.getElementsByClassName("left-sidebar-div")[0];
        var right_sidebar = document.getElementsByClassName("right-sidebar-div")[0];
        if (value === "hamburger") {
            if (value === "hamburger" && left_sidebar.style.display === "block") {
                left_sidebar.style.display = "none";
            } else {
                left_sidebar.style.display = "block";
                right_sidebar.style.display = "none";
            }
        } else if (value === "add") {
            if (value === "add" && right_sidebar.style.display === "block") {
                right_sidebar.style.display = "none";
            } else {
                right_sidebar.style.display = "block";
                left_sidebar.style.display = "none";
            }
        }
    }
    
    

    render() {
        if (this.state.redirect) {
            const path = this.state.redirect;
            let search = this.state.search;


            if (this.state.withinSerach === true) {
                search = `${
                    this.state.oldSearch
                },${search}&flag=1`
            }

            this.setState({redirect: null, search: "", oldSearch: search, setTo: path})
            return <Redirect to={
                {
                    pathname: path,
                    search: '?key=' + search,
                    time: moment().valueOf()
                }
            }/>
        }
       
        return (
            <> {
                this.state.changePassword ? <ChangePassword hideModal={
                    this.hideChangePass
                }/> : ""
            }

                <nav className="navbar">
                    <div className="navbar-logo">
                        <Link to='/'>
                            <img src={pexit}
                                alt="pexit"/>
                        </Link>
                    </div>
                    <div className="navbar-action-search">
                        <form className="form"
                            onClick={
                                this.handleModel
                            }
                            onSubmit={
                                e => {
                                    e.preventDefault();
                                    this.handleSearch()
                                }
                        }>
                            <div className="caret">
                                <i className="fas fa-caret-down"></i>
                            </div>
                            <input type="search" className="search"
                                ref={
                                    this.searchInput
                                }
                                placeholder={
                                    this.state.searchPlaceHolder
                                }
                                value={
                                    this.state.search
                                }
                                onKeyUp={
                                    (e) => this.enterkey(e)
                                }
                                onChange={
                                    (e) => this.updateInputValue(e)
                                }/>
                            <div className="search-button"
                                onClick={
                                    this.handleSearch
                            }>
                                <button type="button" className="search-icon">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </form>
                        {this.state.showRefresh ? 
                        <div>
                            <button className="SearReset"
                                onClick={
                                    () => { 
                                        this.setState({
                                            showRefresh: false
                                        })
                                        window.location.href = window.location.origin + window.location.pathname;
                                    }
                            }>
                                <i class="fa fa-refresh" aria-hidden="true"></i>
                            </button>
                        </div>
                        :
                        ""}
                        {
                        (( this.state.checkedCategory === 'Posts' || this.state.checkedCategory === 'Services' || this.state.checkedCategory === 'Jobs' || this.state.checkedCategory === 'Products'|| this.state.checkedCategory === 'Connections' || this.state.checkedCategory === 'Software' || this.state.checkedCategory === 'Product Suppliers') && this.state.oldSearch !== '' && this.state.isWithinSearch === true) ? (
                            <>
                                <div className="within_serach">
                                    <input type="checkbox"
                                        value={true}
                                        checked={
                                            (this.state.withinSerach) ? true : false
                                        }
                                        onChange={
                                            () => {
                                                (this.state.withinSerach) ? this.setState({withinSerach: false}) : this.setState({withinSerach: true})
                                            }
                                        }/>
                                    <label>Search within results</label>
                                </div>
                            </>
                        ) : ""
                    } </div>
                    <div className="notification-action">
                        <i class="fa fa-search" aria-hidden="true"
                            onClick={
                                this.handleModel
                        }></i>
                        <Link to={'/chat'}>
                            <div className="message-notification">
                                {/* <img src={messageIcon}
                                    alt="messageIcon"/>  */}
                                    <i class="fa fa-commenting-o new-msg-logo" aria-hidden="true"></i>
                            {
                                window.sessionStorage.getItem("chatcount") > 0 && (
                                    <span className="notiCount">
                                        {
                                        window.sessionStorage.getItem("chatcount")
                                    }</span>
                                )
                            } </div>
                        </Link>

                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <div className="profile-display"
                                    style={
                                        {
                                            backgroundImage: 'url(' + notificationIcon + ')',
                                            backgroundSize: 'cover'
                                        }
                                }>
                                    {
                                    window.sessionStorage.getItem("nCount") > 0 && (
                                        <span className="notiCount">
                                            {
                                            parseInt(window.sessionStorage.getItem("nCount"))
                                        }</span>
                                    )
                                } </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-hover" id="notiDropdown">

                                {this.state.notificationList.length > 0 ? 
                                
                                this.state.notificationList.map((list, key) => (
                                    <>
                                    
                                        <div key={key}
                                            className="notifyList">

                                            <div>
                                                <img src={
                                                        (list ?. pimage) ? list ?. pimage : './images/no-image.png'
                                                    }
                                                    alt="profile"
                                                    className="user-image"/>
                                            </div>
                                            <div className="userNameMsg">
                                           
                                                <h2>
                                                    <Link to={
                                                        (list.template === 2) ? `/showpage/${
                                                            list.gid
                                                        }` : `/profileshow/${
                                                            list.fid
                                                        }`
                                                    }>
                                                        <span className="notiUserName">
                                                            {
                                                            (list.name) ? (list.name) : (list.username)
                                                        }</span>
                                                    </Link>

                                                    <span>
                                                        &nbsp;{
                                                        list.message
                                                    } </span>

                                                    {list.template === 2 && 
                                                    <Link to={{
                                                        pathname: `/showpage/${list.gid}`,
                                                        state: []
                                                      }}>
                                                     {/* <Link to={`/showpage/${list.gid}`}> */}
                                                        <span className="notiUserName">
                                                            {
                                                                (list.gname) ? (list.gname) : (list.username)
                                                            }
                                                        </span>
                                                    </Link>
                                                    }
                                                    
                                                </h2>

                                            </div>

                                        </div>
                                        {
                                        (list.template === 1 && list.type === 2) ? <div className="responseBtns">
                                            <button onClick={
                                                () => this.friendRequest('accepted', list.uid)
                                            }>Accept</button>
                                            <button onClick={
                                                () => this.friendRequest('rejected', list.uid)
                                            }>Reject</button>
                                            
                                        </div> : (list.template === 2 && list.type === 1) || (list.template === 2 && list.type === 2) ? <div className="responseBtns">
                                            
                                            <button onClick={
                                                () => this.groupRequest('accepted', list.reqid, list.gid)
                                            }>Accept</button>
                                            <button onClick={
                                                () => this.groupRequest('rejected', list.reqid, list.gid)
                                            }>Reject</button>
                                        </div> : ""
                                        }
                                   
                            </>
                          )) : <h1 class="noNotify">No notifications found</h1>}
                
                          </Dropdown.Menu>
                        </Dropdown>


                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <div className="profile-display"
                                    style={
                                        {
                                            backgroundImage: 'url(' + window.sessionStorage.getItem("profileImage") + ')',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center'
                                        }
                                }>
                                    {/* <img src={this.state.profileImage} alt="profile" className="user-image" /> */} </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-hover">
                                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                                <Dropdown.Item href=""
                                    onClick={
                                        this.showChangePass
                                }>Change Password</Dropdown.Item>
                                {
                                this.state.isSupportUser && <Dropdown.Item href="/supportticket">Manage Support</Dropdown.Item>
                            }
                                <Dropdown.Item href="/transaction-history">Transaction History</Dropdown.Item>
                                <Dropdown.Divider/>
                                <Dropdown.Item onClick={
                                    this.logOut
                                }>Log Out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Route exact path="/">
                            <i class="fa fa-bars" aria-hidden="true"
                                onClick={
                                    () => this.toggle_sidebar('hamburger')
                            }></i>
                            <i class="fa fa-plus-circle" aria-hidden="true"
                                onClick={
                                    () => this.toggle_sidebar('add')
                            }></i>
                        </Route>

                    </div>
                </nav>


                <div className="toolbar">
                    <div className="toolbar-container">
                        <Link to={'/'}
                            className="toolbar-content-a">
                            <div className="toolbar-content">
                                <img src={
                                    this.state.newPathname == "" || this.state.newPathname == "/"  ?
                                    homeicon :
                                    homeicon_fill
                                }   
                                    alt="home"/>
                                <p className="text">Home</p>
                            </div>
                        </Link>

                        <Link to='/my-circle' className="toolbar-content-a">
                            <div className="toolbar-content">
                                <img src={
                                     this.state.newPathname != "/my-circle"  ?
                                     myCircle :
                                     myCircle_fill
                                }
                                alt="myCircle"/>
                                <p className="text">My Circle</p>
                            </div>
                        </Link>
                        <Link to='/software' className="toolbar-content-a">
                            <div className="toolbar-content">
                                <img src={
                                this.state.newPathname != "/software"  ?
                                software :
                                software_fill
                                }
                                    alt="software"/>
                                <p className="text">Software</p>
                            </div>
                        </Link>
                        <Link to='/product' className="toolbar-content-a">
                            <div className="toolbar-content">
                                <img src={
                                this.state.newPathname != "/product"  ?
                                product :
                                product_fill
                                }
                                    alt="product"/>
                                <p className="text">Product</p>
                            </div>
                        </Link>
                        <Link to='/job' className="toolbar-content-a">
                            <div className="toolbar-content">
                                <img src={
                                this.state.newPathname != "/job"  ?
                                job :
                                job_fill
                                }
                                    alt="job"/>
                                <p className="text">Job</p>
                            </div>
                        </Link>
                        <Link to='/service' className="toolbar-content-a">
                            <div className="toolbar-content">
                                <img src={
                                    this.state.newPathname != "/service"  ?
                                    service :
                                    service_fill
                                }
                                    alt="service"/>
                                <p className="text">Service</p>
                            </div>
                        </Link>
                    </div>
                </div>

                {/* <!-- search-popup start --> */}
                <div className={
                        (this.state.modelIsOpen) ? 'creategroup-modal-container open' : 'creategroup-modal-container '
                    }
                    id="search-modal-container">
                    <div className="creategroup-modal send-message-modal">

                        <button type="button" className="close-button-modal" id="close-search"
                            onClick={
                                this.closeModel
                        }>
                            <i className="fas fa-times"></i>
                        </button>

                        <div className="search-field-container">
                            <div className="navbar-action-search">
                                <form className="form search-form-popup"
                                    onSubmit={
                                        (e) => this.SubmitSearchform(e)
                                }>
                                    <div className="caret">
                                        <i className="fas fa-caret-down"></i>
                                    </div>
                                    <input type="search" className="search" id="radio-search"
                                        placeholder={
                                            this.state.searchPlaceHolder
                                        }
                                        value={
                                            this.state.search
                                        }
                                        onChange={
                                            (e) => this.updateInputValue(e)
                                        }/>
                                    <div className="search-button"
                                        onClick={
                                            this.handleSearch
                                        
                                    }>
                                     
                                        <button type="button" className="search-icon">
                                            <i className="fas fa-search"></i>
                                        </button>
                                     
                                    </div>
                                </form>
                            </div>

                            <form action="#" className="search-radiobutton"
                                onChange={
                                    this.setCategory.bind(this)
                            }>
                                <h2>
                                    <input type="radio" id="test1" name="radio-group" value="Posts"
                                       
                                        defaultChecked={
                                           
                                            (this.state.checkedCategory === 'Posts') ? true : false
                                        }/>
                                    <label htmlFor="test1">Posts</label>
                                </h2>
                                <h2>
                                    <input type="radio" id="test2" name="radio-group" value="Connections"
                                    
                                        defaultChecked={
                                            (this.state.checkedCategory === 'Connections') ? true : false
                                        }/>
                                    <label htmlFor="test2">Connections</label>
                                </h2>
                                <h2>
                                    <input type="radio" id="test3" name="radio-group" value="Jobs"
                                      defaultChecked={
                                            (this.state.checkedCategory === 'Jobs') ? true : false 
                }
                                        />
                                    <label htmlFor="test3">Jobs</label>
                                </h2>
                                <h2>
                                    <input type="radio" id="test4" name="radio-group" value="Products"
                                        defaultChecked={
                                            (this.state.checkedCategory === 'Products') ? true : false
                                        }/>
                                    <label htmlFor="test4">Products</label>
                                </h2>
                                <h2>
                                    <input type="radio" id="test5" name="radio-group" value="Services"
                                        defaultChecked={
                                            (this.state.checkedCategory === 'Services') ? true : false
                                        }/>
                                    <label htmlFor="test5">Services</label>
                                </h2>
                                <h2>
                                    <input type="radio" id="test6" name="radio-group" value="Software"
                                        defaultChecked={
                                            (this.state.checkedCategory === 'Software') ? true : false
                                        }/>
                                    <label htmlFor="test6">Software</label>
                                </h2>
                                <h2>
                                    <input type="radio" id="test7" name="radio-group" value="Product Suppliers"
                                        defaultChecked={
                                            (this.state.checkedCategory === 'Product Suppliers') ? true : false
                                        }/>
                                    <label htmlFor="test7">Product Suppliers</label>
                                </h2>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <!-- search-popup end --> */} </>
        );
    }
}     


const mapStateToProps = (state, ownProps) => {
    return {isLoggedIn: state.login}
}


export default
connect(mapStateToProps)(withRouter(Navbar));
