import "../connectionpage/ConnectionPage.css";
import "../userpage/UserPage.css";
import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ChatButton from "../../components/chatbutton/ChatButton";

import BlockedLeftSideBar from "./blockeduserleftsidebar/BlockedLeftSideBar";
import Loader from "../loader/Loader";

export class BlockedUserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blockusers: [],

      showFriendMenu: "",
      showFriendPostId: "",
      loggedUserId: "",
      blockModal: false,
      blockUserId: "",
      blockListData: "",
      isLoading: false,
      isLoaded: false,

      isBlockedLeftSideBar: false,
      openBlockedLeftSideBar: false,
    };
  }

  componentDidMount = async () => {
    await this.handleResize();

    await this.setState({
      isLoading: true,
    })

    try {
      const block = await axios.get(
        `${process.env.REACT_APP_API_URL}block/list`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (block.status === 200 && block.data.status === true) {
        this.setState({
          blockusers: block.data.datas,
          isLoaded: true,
          isLoading: false,
        });
      } else if (block.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else {
        this.setState({
          isLoaded: true,
          isLoading: false,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isBlockedLeftSideBar: true,
        openBlockedLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isBlockedLeftSideBar: false,
      });
    }
  };

  // block modal
  blockModal = async (id) => {
    const blockModal = this.state.blockModal === false ? true : false;

    await this.setState({
      blockModal: blockModal,
      blockUserId: id,
    });
  };

  hideBlockModal = async () => {
    await this.setState({
      blockModal: false,
    });
  };

  blockUser = async (blockType) => {

    if (this.state.isLoading === false) {

      await this.setState({
        isLoading: true,
      })

      const data = {
        fid: this.state.blockUserId,
        type: blockType,
      };

      try {
        const blockData = await axios.post(
          `${process.env.REACT_APP_API_URL}block`,
          data,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (blockData.status === 200 && blockData.data.status === true) {

          let users = this.state.blockusers.filter((u) => {
            if (u.id != this.state.blockUserId) {
              return u;
            }
          })

          await this.setState({
            blockusers: users
          })

          toast.success(blockData.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(blockData.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      setTimeout(async () => {
        await this.setState({
          isLoading: false,
        })
      }, 5000)
    }

    this.hideBlockModal();
  };

  handleBlockData = async (datas) => {
    await this.setState({
      blockListData: datas,
      blockusers: [],
    });
  };

  showBlockedLeftSideBar = async () => {
    if (this.state.openBlockedLeftSideBar) {
      await this.setState({
        openBlockedLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openBlockedLeftSideBar: true,
      });
    }
  };

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        <section className="user-profile-section">
          {(this.state.isBlockedLeftSideBar || this.state.openBlockedLeftSideBar) &&
            <BlockedLeftSideBar />
          }

          <div className="user-profile-container">
            {(!this.state.openBlockedLeftSideBar && !this.state.isBlockedLeftSideBar) &&
              <div className="blockedUser-hamburger-menu" id="mycircle-hamburger">
                <i class="fa fa-bars" aria-hidden="true" onClick={this.showBlockedLeftSideBar}></i>
              </div>
            }
            {(this.state.openBlockedLeftSideBar && !this.state.isBlockedLeftSideBar) &&
              <div className="blockedUser-hamburger-menu" id="mycircle-hamburger">
                <i class="fa fa-times" aria-hidden="true" onClick={this.showBlockedLeftSideBar}></i>
              </div>
            }
            {this.state.isLoaded &&
              <div className="user-profile">
                {this.state.blockusers.length ? (
                  this.state.blockusers.map((blockuser, key) => (
                    <div className="user-profiles" key={key}>
                      <div className="user-profile-image ">
                        <img src={blockuser.pimage} alt="" />
                      </div>
                      <div className="user-profile-info">
                        <h2 className="header">{blockuser.username}</h2>
                        <h3 className="text">{blockuser.title}</h3>
                        <h3 className="text">{blockuser.location}</h3>
                      </div>
                      <div className="user-profile-connect">
                        <button
                          type="button"
                          className="connect"
                          onClick={() => this.blockModal(blockuser.id)}
                        >
                          Unblock
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="blocked">
                    <h2>There are no blocked users.</h2>
                  </div>
                )}
              </div>
            }
            {this.state.isLoading && <Loader />}
          </div>
        </section>

        <ChatButton />

        {/* block modal start */}
        <div
          className={
            "creategroup-modal-container report-modal-container create-modal" +
            (this.state.blockModal ? " open" : "")
          }
        >
          <div className="creategroup-modal report-modal delete-modal">
            <div className="forgot-header">
              <h1 className="header">
                Are you sure you want to unblock this service user?
              </h1>
            </div>

            <div className="creategroup-content delete-buttons-container">
              <button
                type="button"
                className="login-button creategroup-button forgot-content cancel-button"
                id="mySupplier"
                onClick={this.hideBlockModal}
              >
                <h2>Cancel</h2>
              </button>

              <button
                type="button"
                className="login-button creategroup-button forgot-content delete-button"
                id="mySupplier"
                onClick={() => this.blockUser("unblock")}
              >
                <h2>Unblock</h2>
              </button>
            </div>
          </div>
        </div>
        {/* block modal end */}
      </>
    );
  }
}

export default withRouter(BlockedUserPage);
