import "../connectionpage/ConnectionPage.css";
import "../userpage/UserPage.css";
import "../groupusers/GroupUsers.css";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import axios from "axios";
import ChatButton from "../../components/chatbutton/ChatButton";
import Parser from "html-react-parser";
import ShowMoreText from "react-show-more-text";

import GroupUsersLeftSideBar from "./groupusersleftsidebar/GroupUsersLeftSideBar";
import Loader from "../loader/Loader";
import component from "../../../assets/component.png";
import EditIcon from "../../../assets/edit-icon.png";
import Exit from "../../../assets/exit.png";
import { Link, withRouter } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import ButtonLoader from '../../components/loader/Loader'

export class GroupUsers extends Component {
  constructor(props) {
    super(props);

    //dynamic validation for title field and content field
    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.UpdateGroupValidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.my_group = React.createRef();

    this.state = {

      OwnGroups: [],
      allGroups: [],
      GroupNextAvailable: false,
      GroupPage: 0,

      groupusers: [],
      showUsers: [],
      lists: [],
      groupleaveusers: [],
      groupconnectusers: [],
      loggedUser: "",
      groupCreatedBy: [],
      allGroupCreatedBy: [],
      joinGroupList: [],
      buttonDisable: false,


      updateGroup: "",
      groupGid: "",
      leaveModal: false,
      updateGroupDetails: false,
      groupType: {
        typeUsers: "public",
      },
      groupTypeUser: "",
      groupName: "",
      groupDescrip: "",
      gUsers: [],
      selectedgUsers: [],
      selectGroupUsers: [],
      groupId: "",
      guserOptions: [],
      publicType: true,
      closedType: false,
      privateType: false,

      page: 0,
      nextAvailable: true,
      perPage: 12,
      loading: false,

      createGroupModelOpen: false,
      showPexitUsers: false,
      searchCondition: "",

      // create group
      connections: [],
      isSelects: true,
      selectedOption: "",
      createName: "",
      createDescri: "",
      options: [],
      selectedOptions: [],
      createGroup: {
        users:[{value:"public",label:"Public"}],
      },

      isLoading: false,
      isLoaded: false,
      isButtonLoding: false,
      uid: 0,

      isGroupLeftSideBar: false,
      openGroupLeftSideBar: false,

      searchKey: "private",
      searchKeyValue: "",
    };
  }

  // set ternery condition on the create post modal for showing the modal
  showModal = async () => {
    const createGroupModelOpen =
      this.state.createGroupModelOpen === false ? true : false;
    await this.setState({
      createGroupModelOpen: createGroupModelOpen,
    });
  };

  hideGroupModal = async () => {
    await this.setState({
      createGroupModelOpen: false,
    });

    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });
  };

  showLeaveModal = async (pid) => {
    const leaveModal = this.state.leaveModal === false ? true : false;

    await this.setState({
      leaveModal: leaveModal,
      groupGid: pid,
    });
  };

  hideLeaveModal = async () => {
    await this.setState({
      leaveModal: false,
    });
  };

  groupList = async (skey) => {
    
    if (this.state.nextAvailable) {
      
      this.setState({
        loading: true,
        page: this.state.page + 1,
      });

      const userData = localStorage.getItem("userData");
      const userDetails = JSON.parse(userData);

      const groupData = {
        page: this.state.page,
        records: this.state.perPage,
        key: this.state.searchKeyValue 
      };
      
      try {
        const group = await axios.post(
          `${process.env.REACT_APP_API_URL}group/list/${skey}`,
          groupData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );
        
        if (group.status === 200 && group.data.status === true) {
         
          await this.setState({
            loading: false,
            isLoaded: true,
          })

          if (group.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }

          var oldArray = this.state.OwnGroups;

          oldArray = (this.state.page === 1) ? group.data.datas : oldArray.concat(group.data.datas)

          this.setState({
            OwnGroups: oldArray,
            loggedUser: userDetails.userId,
          });

        } else if (group.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else {
          this.setState({
            isLoaded: true,
            loading: false,
            nextAvailable: false,
          })
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      await this.setState({
        loading: false,
      });
    }
  };

  AllgroupList = async () => {
    if (this.state.GroupNextAvailable) {
      await this.setState({
        loading: true,
        GroupPage: this.state.GroupPage + 1,
      });

      const userData = localStorage.getItem("userData");
      const userDetails = JSON.parse(userData);

      const groupData = {
        page: this.state.GroupPage,
        records: this.state.perPage
      };

      try {
        const group = await axios.post(
          `${process.env.REACT_APP_API_URL}group/list/all`,
          groupData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (group.status === 200 && group.data.status === true) {
          if (group.data.datas.length < this.state.perPage) {
            await this.setState({
              GroupNextAvailable: false,
            });
          }

          this.setState({
            allGroups: this.state.allGroups.concat(group.data.datas),
            loggedUser: userDetails.userId,
          });
        } else if (group.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      await this.setState({
        loading: false,
      });
    }
  };

  componentDidMount = async () => {
    await this.handleResize();
    this.setState({
      loading: true,
      page: this.state.page,
    });

    try {
      const userD = localStorage.getItem("userData");
      const ParseDet = JSON.parse(userD);
      const logUserId = ParseDet.userId;
      const conn = await axios.get(
        `${process.env.REACT_APP_API_URL}connections-web/${Number(logUserId)}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (conn.status === 200 && conn.data.status === true) {
        this.setState({
          connections: conn.data.datas,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token")
        window.location = process.env.PUBLIC_URL
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }


    // group list case
    await this.groupList(this.state.searchKey);
    
    window.addEventListener("scroll", this.listenToScroll);

    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isGroupLeftSideBar: true,
        openGroupLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isGroupLeftSideBar: false,
      });
    }
  }

  listenToScroll = async () => {
    const docHeight = document.documentElement.scrollHeight;
    const finalCalcHeight = docHeight - (docHeight * 10) / 100;

    if (
      this.state.loading === false &&
      finalCalcHeight <= document.documentElement.scrollTop + window.innerHeight
    ) {
      
      await this.groupList(this.state.searchKey);
    }
    
  };

  usersScroll = async () => {
    const docHeight = document.documentElement.scrollHeight;
    const finalCalcHeight = docHeight - (docHeight * 10) / 100;

    if (
      this.state.loading === false &&
      finalCalcHeight <= document.documentElement.scrollTop + window.innerHeight
    ) {
      await this.AllgroupList();
    }
  };

  groupLeave = async (gid, editKey) => {
    const groupLeaveData = {
      type: "0",
      gid: gid,
    };

    try {
      const groupLeave = await axios.post(
        `${process.env.REACT_APP_API_URL}group/leave`,
        groupLeaveData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );


      if (groupLeave.data.status === true) {
        this.setState({
          groupleaveusers: groupLeave.data.datas,
          leaveModal: false,
        });

        await this.handleCondition(this.state.groupusers);

        toast.success(groupLeave.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });


      } else {
        toast.error(groupLeave.data.errors, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err?.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  groupConnect = async (e, gid, uid, buttonIndex) => {
    const groupConnectData = {
      gid: gid,
      fid: uid,
    };

    if (this.state.isLoading === false) {

      await this.setState({
        isLoading: true,
        isButtonLoding: true
      })
      try {
        const groupConnect = await axios.post(
          `${process.env.REACT_APP_API_URL}group/connect`,
          groupConnectData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (groupConnect.status === 200) {

          if (typeof this.state.allGroups[buttonIndex] !== 'undefined') {
            this.state.allGroups[buttonIndex].joinKey = 0;
          }

          if (typeof this.state.OwnGroups[buttonIndex] !== 'undefined') {
            this.state.OwnGroups[buttonIndex].joinKey = 0;
          }

          if (groupConnect.data.status === true) {
           
            // await this.handleGroupList(this.state.groupusers);
            toast.success(groupConnect.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (groupConnect.data.status === false) {
            toast.error(groupConnect.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

        } else {
          toast.error(groupConnect.data.errors, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err?.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }


      await this.setState({
        isLoading: false,
        isButtonLoding: false
      })

    }
  };

  handleGroupList = async (allData) => {
    this.setState({
      joinGroupList: allData,
      nextAvailable: true,
      loading: true,
      page: 0,
      groupusers: [],
    });
  };

  // create group submit
  handleChange = async (e) => {
    const data = this.state.createGroup;
    data.users[0].label = e.value?.replace(/^\s+/g, '');
    data.users[0].value = e.value?.replace(/^\s+/g, '');

    if (data.users[0].value === "public") {
      this.setState({
        publicType: true,
        privateType: false,
        closedType: false,
      });
    }

    if (data.users[0].value === "closed") {
      this.setState({
        closedType: true,
        publicType: false,
        privateType: false,
      });
    }

    if (data.users[0].value === "private") {
      this.setState({
        privateType: true,
        publicType: false,
        closedType: false,
      });
    }

    const dataUsers = this.state.groupType;
    dataUsers.typeUsers = e.value?.replace(/^\s+/g, '');

    let isSelects = this.state.isSelects;

    await this.setState({
      createGroup: data,
      groupType: dataUsers,
      isSelects: isSelects,
    });
  };

  handleInputChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });
  };

  handleSelected = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleUsers = (gUsers) => {
    this.setState({ selectedgUsers: gUsers });
  };

  submitCreateGroup = async () => {
    if (this.validator.allValid()) {

      this.setState({
        isButtonLoding: true
      })

      const groupData = {
        gtype: this.state.createGroup.users[0].value,
        gname: this.state.createName,
        gdescription: this.state.createDescri,
        gusers: Array.isArray(this.state.selectedOption)
          ? this.state.selectedOption.map((option) => option.value).join(',')
          : "",
      };

      try {
        const groupres = await axios.post(
          `${process.env.REACT_APP_API_URL}group/create`,
          groupData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (groupres.status === 200) {
          if (groupres.data.status === true) {
            this.hideGroupModal();
            await this.handleCondition(this.state.groupusers);
          }

          toast.success(groupres.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });


        } else {
          toast.error(groupres.data.errors, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err?.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      this.hideGroupModal();

      this.setState({
        createName: "",
        createDescri: "",
        selectedOption: "",
        isButtonLoding: false
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  allUsers = async () => {
    const showPexitUsers = this.state.showPexitUsers === false ? true : false;
    await this.setState({
      showPexitUsers: showPexitUsers,
      GroupNextAvailable: true,
      GroupPage: 0,
      allGroups: []
    });

    if (this.state.showPexitUsers === true) {
      window.addEventListener("scroll", this.usersScroll);
      await this.AllgroupList();
    }
  };

  editModal = async (groupDetails, gid) => {

    
    const updateGroupDetails =
      this.state.updateGroupDetails === false ? true : false;
    await this.setState({
      updateGroupDetails: updateGroupDetails,

      groupTypeUser: groupDetails.gtype,
      groupName: groupDetails.gname,
      groupDescrip: groupDetails.gdescription,
      gUsers: groupDetails.gusers,
      groupId: gid,
      selectedgUsers: groupDetails.gusers.map((a) => { return { value: a.email, label: a.username } }),
      selectGroupUsers: groupDetails.gusers.map((obj) => {
        return { value: obj.email, label: obj.username };
      }).concat(this.state.connections.map((obj) => {
        return { value: obj.email, label: obj.username };
      })),
    });
  };

  hideEditModal = async () => {
    await this.setState({
      updateGroupDetails: false,
    });

    this.UpdateGroupValidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });
  };

  updateGroup = async () => {
    if (this.UpdateGroupValidator.allValid()) {
      const updateData = {
        gdid: this.state.groupId,
        gname: this.state.groupName,
        gdescription: this.state.groupDescrip,
        gtype: this.state.groupType.typeUsers
          ? this.state.groupType.typeUsers
          : "public",
        gusers: this.state.selectedgUsers.map((g) => {
          return g.value
        }).join(','),
      };

      try {
        const updateRes = await axios.post(
          `${process.env.REACT_APP_API_URL}group/update/${this.state.groupId}`,
          updateData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (updateRes.status === 200) {
          if (updateRes.data.status === true) {
            this.hideEditModal();
            await this.updateCondition(this.state.groupusers);
          }
          toast.success(updateRes.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (updateRes.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else {
          toast.error(updateRes.data.errors, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      this.hideEditModal();

      this.setState({
        groupName: "",
        groupDescrip: "",
        groupType: "",
        selectedOption: "",
      });
    } else {
      this.UpdateGroupValidator.showMessages();
      this.forceUpdate();
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.searchCondition !== this.state.searchCondition) {
      
      await this.groupList(this.state.searchKey);

      await this.setState({
        loading: false,
      });
    }

    // if (prevState.updateGroup !== this.state.updateGroup) {
    //   await this.groupList();

    //   await this.setState({
    //     loading: false,
    //   });
    // }

    // if (prevState.joinGroupList !== this.state.joinGroupList) {
    //   await this.groupList();

    //   await this.setState({
    //     loading: false,
    //   });
    // }
  }

  handleCondition = async (conditionData) => {
    
    await this.setState({
      // searchCondition: conditionData,
      nextAvailable: true,
      loading: true,
      isLoaded: false,
      page: 0,
      groupusers: [],
      OwnGroups: []
    });
    
    await this.groupList(this.state.searchKey);
  };

  updateCondition = async (data) => {
    await this.setState({
      updateGroup: data,
      nextAvailable: true,
      loading: true,
      isLoaded: false,
      page: 0,
      groupusers: [],
      OwnGroups: []
    });
    
    await this.groupList(this.state.searchKey);
  };

  checkUserJoint = (users, uid) => {
    let isJoint = false;
    for (let index = 0; index < users.length; index++) {
      const u = users[index];
      if (u.id === uid) {
        isJoint = true
      }
    }

    return isJoint
  }

  showGroupLeftSideBar = async () => {
    if (this.state.openGroupLeftSideBar) {
      await this.setState({
        openGroupLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openGroupLeftSideBar: true,
      });
    }
  };

  searchGrp = async (e) => {

    await this.setState({
      loading: true,
      isLoaded: false,
      nextAvailable:true,
      searchKeyValue: e.target.value,
      page: 0,
    });

    if (e.target.value !== "") {
      
      await this.setState({
        searchKey: "search",
      });

    } else {

      await this.setState({
        searchKey: "private",
      })

    }

      await this.groupList(this.state.searchKey);
   
  }



  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        <section className="user-profile-section">
          {(this.state.isGroupLeftSideBar || this.state.openGroupLeftSideBar) &&
            <GroupUsersLeftSideBar />
          }

          <div className="user-profile-container group-profile-container">
            <div className="view-post-container user-profile-container">
              <div>
                <div className="new-group-search-bar">
                  <div className="new-group-content">
                    <label>Search : </label>
                    <input 
                      type="search" 
                      className="grp-search" 
                      placeholder="Search for Groups"
                      onChange={this.searchGrp}
                    />
                   
                   
                  </div>
                </div>

                <div className="new-group" onClick={this.showModal}>
                  <div className="new-group-content">
                    <h2 className="text">New Group</h2>
                  </div>
                  <div className="new-group-button">
                    <button
                      type="button"
                      className="group-add-button"
                      id="newGroup"
                    >
                      <img src={component} alt="component" />
                    </button>
                  </div>
                </div>

              </div>
              {(!this.state.openGroupLeftSideBar && !this.state.isGroupLeftSideBar) &&
                <div className="group-hamburger-menu">
                  <i class="fa fa-bars" aria-hidden="true" onClick={this.showGroupLeftSideBar}></i>
                </div>
              }
              {(this.state.openGroupLeftSideBar && !this.state.isGroupLeftSideBar) &&
                <div className="group-hamburger-menu">
                  <i class="fa fa-times" aria-hidden="true" onClick={this.showGroupLeftSideBar}></i>
                </div>
              }

              {this.state.isLoaded &&
                <div className="view-post group-profile view-profile" ref={this.my_group}>
                  {this.state.OwnGroups.length ? (
                    this.state.OwnGroups.map((groupuser, index) => (
                      <div
                        className="view-post-profiles user-profiles group-profiles"
                        key={index}
                      >
                        {
                          (groupuser.editKey === 1) ?
                            <div
                              className="edit-image"
                              onClick={() => this.editModal(groupuser, groupuser.gid)}
                            >
                              <img src={EditIcon} alt="EditIcon" />
                            </div>
                            : ""
                        }
                        <div className="user-profile-image">
                          <img src={groupuser.gimage} alt="" />
                        </div>
                        <div className="user-profile-info view-info">
                          <Link
                            to={{
                              pathname: `/showpage/${groupuser.gid}`,
                              state: `${JSON.stringify(groupuser.gusers)}`,
                            }}
                          >
                            <h2 className="header">{groupuser.gname}</h2>
                          </Link>
                          <h3 className="text">
                            <ShowMoreText
                              lines={2}
                              more="Read more"
                              less="Read less"
                              className="text"
                              anchorClass="my-anchor-css-class"
                              onClick={this.executeOnClick}
                              expanded={false}
                              truncatedEndingComponent={"... "}
                            >
                              {Parser(groupuser.gdescription)}
                            </ShowMoreText>
                          </h3>

                          {groupuser.viewKey === 1 ?

                            <h3 className="text view-text">
                            
                            <Link
                            to={{
                              //added by PBK to show Posting related to a group
                              pathname: `/` ,
                              search: '?key=' + groupuser.gname,
                              //state:`${encodeURI(groupuser.gname)}`,
                            }}
                          >
                            View Post
                          </Link>
                            </h3>
                            : ""
                          }

                          <div className="user-profile-connect">
                            {groupuser.joinKey === 1 ? (


                              <button
                                type="button"
                                data-id={index}
                                onClick={(e) =>
                                  this.groupConnect(
                                    e,
                                    groupuser.gid,
                                    groupuser.uid,
                                    index
                                  )
                                }
                                disabled={this.state.isButtonLoding && this.state.gid === groupuser.gid}
                              >
                                {this.state.isButtonLoding && this.state.gid === groupuser.gid ? <ButtonLoader /> : 'Join'}
                              </button>
                            ) :
                              <div className="exit-icon">
                                <img
                                  src={Exit}
                                  alt="Exit"
                                  onClick={() => this.showLeaveModal(groupuser.gid)}
                                />
                              </div>
                            }
                          </div>
                        </div>

                      </div>
                    ))
                  ) : (
                    <div className="blocked">
                      <h2>There are no groups.</h2>
                    </div>
                  )}
                </div>
              }
              {this.state.loading && <Loader />}
            </div>

            <div className="seperator" />

            <div className="show-user">
              <div className="show-user-form-group show-group">
                <input type="checkbox" id="users" />
                <label
                  htmlFor="users"
                  className="show-user-text"
                  onClick={this.allUsers}
                >
                  Show all PEXit Group
                </label>
              </div>
            </div>

            <div className="user-profile group-profile">
              {this.state.showPexitUsers
                ? this.state.allGroups.map((groupuser, index) => (
                  <div
                    className="view-post-profiles user-profiles group-profiles"
                    key={index}
                  >
                    {
                      (groupuser.editKey === 1) ?
                        <div
                          className="edit-image"
                          onClick={() => this.editModal(groupuser, groupuser.gid)}
                        >
                          <img src={EditIcon} alt="EditIcon" />
                        </div>
                        : ""
                    }

                    <div className="user-profile-image">
                      <img src={groupuser.gimage} alt="" />
                    </div>
                    <div className="user-profile-info view-info">
                      <Link
                        to={{
                          pathname: `/showpage/${groupuser.gid}`,
                          state: `${JSON.stringify(groupuser.gusers)}`,
                        }}
                      >
                        <h2 className="header">{groupuser.gname}</h2>
                      </Link>
                      <h3 className="text">
                        <ShowMoreText
                          lines={2}
                          more="Read more"
                          less="Read less"
                          className="text"
                          anchorClass="my-anchor-css-class"
                          onClick={this.executeOnClick}
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          {Parser(groupuser.gdescription)}
                        </ShowMoreText>
                      </h3>

                      {groupuser.viewKey === 1 ?

                        <h3 className="text view-text">

                          <Link
                            to={{
                              //added by PBK to show Posting related to a group
                              pathname: `/` ,
                              search: '?key=' + groupuser.gname,
                              //state:`${encodeURI(groupuser.gname)}`,
                            }}
                          >
                            View Post
                          </Link>
                        </h3>
                        : ""
                      }

                      <div className="user-profile-connect">
                        {groupuser.joinKey === 1 ? (

                          <button
                            type="button"
                            data-id={index}
                            onClick={(e) =>
                              this.groupConnect(
                                e,
                                groupuser.gid,
                                groupuser.uid,
                                index
                              )
                            }
                            disabled={this.state.isButtonLoding && this.state.gid === groupuser.gid}
                          >
                            {this.state.isButtonLoding && this.state.gid === groupuser.gid ? <ButtonLoader /> : 'Join'}
                          </button>
                        ) :
                          ((groupuser.gtype !== 'closed') ? <div className="exit-icon">
                            <img
                              src={Exit}
                              alt="Exit"
                              onClick={() => this.showLeaveModal(groupuser.gid)}
                            />
                          </div> : ''
                          )
                        }

                      </div>

                    </div>

                  </div>
                ))
                : ""}
            </div>
          </div>
        </section>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        <div
          className={
            "creategroup-modal-container create-modal" +
            (this.state.createGroupModelOpen ? " open" : "")
          }
        >
          <div className="creategroup-modal">
            <button
              type="button"
              className="close-button-modal"
              id="close-createGroup"
              onClick={this.hideGroupModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-header">
              <h1 className="header">Create Group</h1>
            </div>

            <div className="creategroup-content login-content-fields">
              <form action="" className="login">
                <input
                  type="text"
                  className="groupname"
                  placeholder="Group name"
                  name="createName"
                  value={this.state.createName}
                  onChange={this.handleInputChange}
                />

                {this.validator.message(
                  "group name field is",
                  this.state.createName,
                  "required|string"
                )}

                <Editor
                  apiKey="textarea"
                  name="groupDescrip"
                  value={this.state.createDescri}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [],
                    toolbar: [],
                    placeholder: "Description"
                  }}
                  onEditorChange={(e) => {
                    this.setState({
                      createDescri: (e.replace(/^\s+/g, '') === '<p>&nbsp;&nbsp;</p>') ? '' : e.replace(/^\s+/g, '')
                    })
                  }}
                />

                {this.validator.message(
                  "description field is",
                  this.state.createDescri,
                  "required|min:8"
                )}

                {this.state.isSelects ? (
                  <Select
                    className="form-select option-multi-select"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    isMulti
                    value={this.state.selectedOption}
                    onChange={this.handleSelected}
                    options={this.state.connections.map((obj) => {
                      
                      return { value: obj.email, label: `${obj.name}` };
                    })}
                    placeholder="Assign to..."
                    menuPosition={"fixed"}
                  />
                ) : (
                  ""
                )}

                  <Select
                    placeholder="Select group type.."
                    className="form-select option-multi-select"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={this.state.createGroup.users}
                    onChange={this.handleChange}
                    options={[
                        {value:"public",label:"Public"}, 
                        {value:"closed",label:"Closed"}, 
                        {value:"private",label:"Private"}
                    ]}
                    menuPosition={"fixed"}
                  />

                {this.state.createGroup.users[0].value === 'public' ? (
                  <h2 className="header-text">
                    * Any PEXit user can find the group; see its members and
                    their posts
                  </h2>
                ) : ""}

                {this.state.createGroup.users[0].value === 'closed' ? (
                  <h2 className="header-text">
                    * Any PEXit user can find the group and see its members.
                    Only members can see posts.
                  </h2>
                ) : ""}

                {this.state.createGroup.users[0].value === 'private' ? (
                  <h2 className="header-text">
                    * Only Group members can see its members and their posts
                  </h2>
                ) : ""}

                <button
                  type="button"
                  className="login-button creategroup-button"
                  id="createGroup"
                  onClick={this.submitCreateGroup}
                  disabled={this.state.isButtonLoding}
                >
                  <h2>{this.state.isButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Create Group'}</h2>
                </button>
              </form>
            </div>
          </div>
        </div>

        <div
          className={
            "creategroup-modal-container report-modal-container create-modal" +
            (this.state.leaveModal ? " open" : "")
          }
        >
          <div className="creategroup-modal report-modal delete-modal">
            <div className="forgot-header">
              <h1 className="header">
                Leave and delete. Do you want to continue?
              </h1>
            </div>

            <div className="creategroup-content delete-buttons-container">
              <button
                type="button"
                className="login-button creategroup-button forgot-content cancel-button"
                id="mySupplier"
                onClick={this.hideLeaveModal}
              >
                <h2>Cancel</h2>
              </button>

              <button
                type="button"
                className="login-button creategroup-button forgot-content delete-button"
                id="mySupplier"
                grpid={this.state.groupGid}
                onClick={() => this.groupLeave(this.state.groupGid)}
              >
                <h2>Leave</h2>
              </button>
            </div>
          </div>
        </div>

        <div
          className={
            "creategroup-modal-container create-modal" +
            (this.state.updateGroupDetails ? " open" : "disnone")
          }
        >
          <div className="creategroup-modal">
            <button
              type="button"
              className="close-button-modal"
              id="close-createGroup"
              onClick={this.hideEditModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-header">
              <h1 className="header">Update Group</h1>
            </div>

            <div className="creategroup-content login-content-fields">
              <form action="" className="login">
                <input
                  type="text"
                  className="groupname"
                  placeholder="Group name"
                  name="groupName"
                  value={this.state.groupName}
                  onChange={this.handleInputChange}
                />

                {this.UpdateGroupValidator.message(
                  "Group name field is",
                  this.state.groupName,
                  "required|string"
                )}

                <Editor
                  apiKey="textarea"
                  name="groupDescrip"
                  value={this.state.groupDescrip}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [],
                    toolbar: [],
                    placeholder: "Description"
                  }}
                  onEditorChange={(e) => {
                    this.setState({
                      groupDescrip: (e.replace(/^\s+/g, '') === '<p>&nbsp;&nbsp;</p>') ? '' : e.replace(/^\s+/g, '')
                    })
                  }}
                />

                {this.UpdateGroupValidator.message(
                  "Group description field is",
                  this.state.groupDescrip,
                  "required|string"
                )}

                {this.state.isSelects ? (
                  <Select
                    className="form-select option-multi-select"
                    isMulti
                    value={this.state.selectedgUsers}
                    onChange={this.handleUsers}
                    options={this.state.selectGroupUsers}
                    placeholder="Assign to..."
                  />
                ) : (
                  ""
                )}

                <div className="public-select">
                  <div className="public-caret">
                    <i className="fas fa-caret-down" />
                  </div>
                  <select
                    className="form-select"
                    name="gtypes"
                    id="gtypes"
                    onChange={({ target }) =>
                      this.setState({ groupType: { typeUsers: target.value } })
                    }
                    value={this.state.groupType.typeUsers}
                    aria-label="Default select example"
                  >
                    <option value="public">Public</option>
                    <option value="closed">Closed</option>
                    <option value="private">Private</option>
                  </select>
                </div>

                {this.state.groupType.typeUsers === 'public' ? (
                  <h2 className="header-text">
                    * Any PEXit user can find the group; see its members and
                    their posts
                  </h2>
                ) : ""}

                {this.state.groupType.typeUsers === 'closed' ? (
                  <h2 className="header-text">
                    * Any PEXit user can find the group and see its members.
                    Only members can see posts.
                  </h2>
                ) : ""}

                {this.state.groupType.typeUsers === 'private' ? (
                  <h2 className="header-text">
                    * Only Group members can see its members and their posts
                  </h2>
                ) : ""}

                <button
                  type="button"
                  className="login-button creategroup-button"
                  id="createGroup"
                  onClick={this.updateGroup}
                >
                  <h2>Update Group</h2>
                </button>
              </form>
            </div>
          </div>
        </div>

        <ChatButton />
      </>
    );
  }
}

export default withRouter(GroupUsers);
