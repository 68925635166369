import "../../postPage/Post-page.css"
import "../Post-page"
import "../../../components/modals/Popup.css"

import React, { Component } from 'react'
import axios from "axios";
import ButtonLoader from '../../../components/loader/Loader'
import SimpleReactValidator from "simple-react-validator";

import CreateGroup from "../../creategroup/CreateGroup";

import component from "../../../../assets/component.png"
import { Link } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../loader/Loader";

export class PostRightSidebar extends Component {
    constructor(props) {
        super(props)

        //dynamic validation for title field and content field
        this.validator = new SimpleReactValidator({
            messages: {
                required: "The :attribute required.",
                alpha: "The :attribute must be string.",
            },
        });

        this.mesRef = React.createRef();

        this.state = {
            createGroupModelOpen: false,
            connections: [],
            connectionslength: 0,

            messageModal: false,
            loggedUserAvtar: "./images/no-image.png",
            isSelects: true,
            loggedUserName: "",
            openListing: {},
            userUid: "",
            activeChatId: "",

            chatListing: [],
            message: "",
            isLoading: false,
            isLoaded: false,
            page: 0,
            nextAvailable: true,
            perPage: 12,
            listing:[],
            atTop:false,
        }
    }

    connections = async () => {

        try {
          const userD = localStorage.getItem("userData");
          const ParseDet = JSON.parse(userD);
          const logUserId = ParseDet.userId;

            const conn = await axios.get(
                `${process.env.REACT_APP_API_URL}connections-web/${Number(logUserId)}`,
                {
                  headers: {
                    Auth: `${localStorage.getItem("token")}`,
                  },
                }
            );

            if (conn.status === 200 && conn.data.status === true) {

                this.setState({
                    connections: conn.data.datas,
                });

                this.setState({
                    connectionslength: this.state.connections.length,
                });

            } else if (conn.status === 403) {
                localStorage.removeItem("token")
                window.location = process.env.PUBLIC_URL
            }
        } catch (err) {
            if (err?.response?.status === 403) {
                localStorage.removeItem("token")
                window.location = process.env.PUBLIC_URL
            }
        }

    }

    // set ternery condition on the create post modal for showing the modal
    showModal = async () => {
        const createGroupModelOpen =
            this.state.createGroupModelOpen === false ? true : false;
        await this.setState({
            createGroupModelOpen: createGroupModelOpen,
        });
    };

    hideGroupModal = async () => {
        await this.setState({
            createGroupModelOpen: false,
        })
    }

    componentDidMount = async () => {
      await this.connections();

      if(!!this.mesRef.current) {
        this.mesRef.current.addEventListener("scroll", this.listenToScroll);
      }
    }

    showMsgModal = async (userDetails) => {
      
        const messageModal = this.state.messageModal === false ? true : false;

        await this.setState({
            messageModal: messageModal,
            loggedUserName: userDetails.name,
            loggedUserAvtar: (userDetails.pimage ? userDetails.pimage : this.state.loggedUserAvtar),
            isLoading: true,
            isLoaded: false,
            listing:[],
            page: 0,
            nextAvailable: true,
            perPage: 12,
            atTop:false,
        })

        await this.chatShow(userDetails.id, userDetails.name, userDetails.pimage)
    }

    hideMsgModal = async () => {

        await this.setState({
            messageModal: false,
        })


        this.validator = new SimpleReactValidator({
            messages: {
                required: "The :attribute required.",
                alpha: "The :attribute must be string.",
            },
        });


        this.validator.hideMessages();
        this.forceUpdate();

    }

    handleChange = async (e) => {
        let isSelects = this.state.isSelects;

        await this.setState({
            isSelects: isSelects
        });
    };

    scrollToBottom = () => {
      this.mesRef.current.scrollTop = this.mesRef.current.scrollHeight - 100;
    };

     // chat
  chatShow = async (chatId, chatName, chatUserImage) => {

    await this.setState({
      activeChatId: chatId,
      loggedUserName: chatName,
      loggedUserAvtar: chatUserImage,
  });


    if (this.state.nextAvailable) {
        
      await this.setState({
        isLoading: true,
        page: this.state.page + 1,
      });


    const userData = localStorage.getItem("userData");

    const userDetails = JSON.parse(userData);

    try {
      const chat = await axios.get(
        `${process.env.REACT_APP_API_URL}message/chat-web/${chatId}/${parseInt(this.state.page)}/${parseInt(this.state.perPage)}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      var reverseConvo = !!chat.data.datas.conversation ? chat.data.datas.conversation.reverse() : [];

      if (chat.status === 200 && chat.data.status === true) {

        let oldArray = this.state.listing;
        oldArray = (this.state.page === 1) ? reverseConvo : reverseConvo.concat(oldArray);

        if (chat.data.datas.length < this.state.perPage) {
          await this.setState({
            nextAvailable: false,
          });
        }

        await this.setState({
            openListing: chat.data.datas,
            
            isLoading: false,
            isLoaded: true,
            listing:oldArray,
        });

        for (let index = 0; index < this.state.openListing?.conversation?.length; index++) {
          const el = this.state.openListing?.conversation[index];

          if (el.leftStatus === 1 && el.fromid === userDetails.userId) {
            this.setState({
              isLeft: true,
            })
          } else {
            this.setState({
              isLeft: false,
            })
          }
         
        }

        this.chatList();
      } else {
        
      }
    } catch (err) {

      this.setState({
        openListing: {}
      })
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    const from = this.state?.openListing?.conversation?.map((id) => {
      return id.fromid;
    });
    const toId = this.state?.openListing?.conversation?.map((id) => {
      return id.toid;
    });
    this.setState({
      fromId: from,
      toId: toId,
      userUid: userDetails.userId,
      isForwordMessages: false
    });

    if (!this.state.atTop) {
      this.scrollToBottom();
    }
  }
  };

    // chat list
    chatList = async () => {
        try {
          const list = await axios.get(
            `${process.env.REACT_APP_API_URL}message/chat/list-web`,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
    
          if (list.status === 200 && list.data.status === true) {
            
            let d = list.data.datas.sort((a, b) => {
              return ((a.unreadChatCount < b.unreadChatCount) ? 1 : ((a.unreadChatCount > b.unreadChatCount) ? -1 : 0));
            })
    
            d = d.sort((a, b) => {
              return ((a.messageDate < b.messageDate) ? 1 : ((a.messageDate > b.messageDate) ? -1 : 0));
            })
    
            await this.setState({
              chatListing: d,
            });
          }
        } catch (err) {
          if (err?.response?.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != '') {
            toast.error(err?.response?.data?.error, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
    };

    // send message
    sendMessage = async () => {
        if (this.validator.allValid()) {
            await this.setState({
                isButtonLoding: true,
            })
            const userData = localStorage.getItem("userData")
            const userDetails = JSON.parse(userData)

            const msgData = {
                "chatterIds": this.state.activeChatId,
                "toIds": userDetails.userId,
                "chatterMessage": this.state.message,
            }

            const message = await axios.post(
                `${process.env.REACT_APP_API_URL}message/send-web`,
                msgData,
                {
                    headers: {
                        Auth: `${localStorage.getItem("token")}`,
                    },
                }
            );

            if (message.status === 200 && message.data.status === true) {

                toast.success(message.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else {

                toast.error(message.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            this.hideMsgModal()

            this.setState({
                message: "",
                isButtonLoding: false
            })
            // this.props.history.push('/chat')

            this.validator.hideMessages();
            this.forceUpdate();
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleInputField = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
        });
    }

    listenToScroll = async () => {
      if (this.mesRef.current.scrollTop === 0) {
  
        this.setState({
          atTop:true,
        })
  
      if (this.state.isLoading === false) {
  
          if (this.mesRef.current.scrollTop === 0){
          
            await this.chatShow(this.state.activeChatId, this.state.loggedUserName, this.state.loggedUserAvtar);
            
          }
        }
      }
    };

    componentDidUpdate = async () => {
      if(!!this.mesRef.current) {
        this.mesRef.current.addEventListener("scroll", this.listenToScroll);
      }
    }

    render() {
        return (
            <>
            <div className="right-sidebar-div">

                {this.state.createGroupModelOpen ? <CreateGroup hideModal={this.hideGroupModal} /> : ""}

                <div className="right-sidebar">
                    <div className="components">
                        <div className="components-content" onClick={this.showModal}>
                            <div className="component-image">
                                <img src={component} alt="component" />
                            </div>
                            <div className="component-info">
                                <h2 className="header grey-header active">Create New Group</h2>
                            </div>
                        </div>

                        <Link to="/product-service">
                            <div className="components-content">
                                <div className="component-image">
                                    <img src={component} alt="component" />
                                </div>
                                <div className="component-info">
                                    <h2 className="header grey-header">Add Product</h2>
                                </div>
                            </div>
                        </Link>

                        <Link to="/publish-advertisement">
                            <div className="components-content">
                                <div className="component-image">
                                    <img src={component} alt="component" />
                                </div>
                                <div className="component-info">
                                    <h2 className="header grey-header">Publish Advertisement</h2>
                                </div>
                            </div>
                        </Link>

                        <Link to="/sponsored-service">
                            <div className="components-content">
                                <div className="component-image">
                                    <img src={component} alt="component" />
                                </div>
                                <div className="component-info">
                                    <h2 className="header grey-header">Post Sponsored Content</h2>
                                </div>
                            </div>
                        </Link>

                        <Link to="/job-service">
                            <div className="components-content">
                                <div className="component-image">
                                    <img src={component} alt="component" />
                                </div>
                                <div className="component-info">
                                    <h2 className="header grey-header">Post Job</h2>
                                </div>
                            </div>
                        </Link>

                        <Link to="/software-service">
                            <div className="components-content">
                                <div className="component-image">
                                    <img src={component} alt="component" />
                                </div>
                                <div className="component-info">
                                    <h2 className="header grey-header">Add Software</h2>
                                </div>
                            </div>
                        </Link>

                        <Link to="/service-service">
                            <div className="components-content">
                                <div className="component-image">
                                    <img src={component} alt="component" />
                                </div>
                                <div className="component-info">
                                    <h2 className="header grey-header">Add Service</h2>
                                </div>
                            </div>
                        </Link>

                        <Link to="/pricing">
                            <div className="components-content">
                                <div className="component-image">
                                    <img src={component} alt="component" />
                                </div>
                                <div className="component-info">
                                    <h2 className="header grey-header">Free Offers & Pricing Schedule</h2>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="active-users">
                        <h2 className="header black-header">Active ({this.state.connectionslength})</h2>

                        {this.state.connections.map((user, key) => (

                            
                                <div className="active-users-content" key={key} >
                                    <Link
                                        to={{
                                            pathname: `/profileshow/${user.id}`,
                                            state: 1, // your data array of objects
                                        }}
                                    
                                    >
                                        <div className="user-image">
                                            <img src={user.pimage} alt="user-image" />
                                        </div>
                                    </Link>

                                    <div className="user-content active_user_list" onClick={() => this.showMsgModal(user)}>
                                        <h2 className="header black-header">{user.name}</h2>
                                    </div>
                                </div>
                           

                        ))}
                    </div>
                </div>

            </div>

            {/* message-popup start */}
             <div className={"creategroup-modal-container sending-modal" + (this.state.messageModal ? " open" : "")}>
             <div className="creategroup-modal send-message-modal profile-count-modal">

                 <button type="button" className="close-button-modal"
                     id="close-createMessage"
                     onClick={this.hideMsgModal}
                 >
                     <i className="fas fa-times"></i>
                 </button>

                 <div className="creategroup-header">
                     <h1 className="header">Send Message</h1>
                 </div>

                 <div className="creategroup-content login-content-fields sendmessage-content">
            
                <>

                <div className="send-message-profile">
                    <div className="profile">
                        <img src={this.state.loggedUserAvtar} alt="" />
                    </div>
                    <div className="profile-name">
                        <h1 className="header">{this.state.loggedUserName}</h1> 
                    
                    </div>
                </div>

                <div className="popup-chatbox homepageChatBox" ref={this.mesRef}>

                {Object.keys(this.state.openListing).length > 0 &&
                  this.state.listing.map(
                    (listing, listingIdx) => {
                      let time =
                        !this.state.listing[listingIdx - 1] ||
                          moment(listing.date)
                            .startOf("day")
                            .diff(
                              moment(
                                this.state.listing[listingIdx - 1]
                                  .date
                              ).startOf("day"),
                              "days"
                            ) > 0
                          ? moment(listing.date).diff(moment(), "days") === 0
                            ? "Today"
                            : moment(listing.date).diff(moment(), "days") === 1
                              ? ""
                              : moment(listing.date).format("LL")
                          : "";
  
                      // let isGroupChat = (this.state.selectedChat.toid.split(':').length > 1) ? true : false;
  
                      return (
                        <>
  
                          <div className="chat-date">
                            <h1 className="header">{time}</h1>
                          </div>
  
                          {
                            (listing.forward) ? (
                              <>
                                <div className="messegebox loggedUser">
                                  <div className="message-content">
                                    <div className="message">
                                      {listing.fDetails?.name} On {moment.utc(listing.fDetails?.date).local().format("hh:mm a")}
                                      <div>==============</div>
                                      <h1 className="header msgHeader">
                                        <span dangerouslySetInnerHTML={{ __html: listing.message }} />
                                      </h1>
                                    </div>
                                    <div className={"forward-message-chk " + (this.state.isForwordMessages ? '' : 'd-none')} >
                                      <input type="checkbox" onChange={(e) => { this.setForwardMessage(e, listing.mid) }} />
                                    </div>
                                  </div>
                                  <div className="chat-time">
                                    <h1 className="header">
                                      {moment.utc(listing.dateTimeStamp).local().format("hh:mm a")}
                                    </h1>
                                  </div>
                                </div>
                              </>
  
                            ) :
                              <>
                                {(this.state.userUid === listing.fromid && listing.leftStatus !== 1) ? (
                                  <div className="messegebox loggedUser">
                                    <div className="message-content">
                                      <div className="message">
                                      
                                        <h1 className="header msgHeader"><span dangerouslySetInnerHTML={{ __html: listing.message }} /></h1>
                                      </div>
                                      <div className={"forward-message-chk " + (this.state.isForwordMessages ? '' : 'd-none')} >
                                        <input type="checkbox" onChange={(e) => { this.setForwardMessage(e, listing.mid) }} />
                                      </div>
                                    </div>
                                    <div className="chat-time">
                                      <h1 className="header">
                                        {moment.utc(listing.dateTimeStamp).local().format("hh:mm a")}
                                      </h1>
                                    </div>
                                  </div>
                                ) : <>
                                  {listing.leftStatus !== 1 ?
                                    (
                                      <div className="messegebox notloggedUser">
                                        <div className="message-content">
                                          <div className={"forward-message-chk " + (this.state.isForwordMessages ? '' : 'd-none')}>
                                            <input type="checkbox" onChange={(e) => { this.setForwardMessage(e, listing.mid) }} />
                                          </div>
                                          <div className="message">
                                            
                                            <h1 className="header msgHeader"><span dangerouslySetInnerHTML={{ __html: listing.message }} /></h1>
                                          </div>
                                        </div>
                                        <div className="chat-time">
                                          <h1 className="header">
                                            {moment.utc(listing.dateTimeStamp).local().format("hh:mm a")}
                                          </h1>
                                        </div>
                                      </div>
                                    ) : <>
                                      <div className="leaveNoti">
                                        <i class="fa fa-sign-out" aria-hidden="true"></i> {listing.fpname} left the conversation at {moment.utc(listing.dateTimeStamp).local().format("hh:mm a")}
                                      </div>
                                    </>
                                  }
                                </>
                                }
                              </>
                          }
  
                        </>
                      );
                    }
                  )}
              </div>
  
                  <form action="" className="login sendmessage-form send-message-type" onSubmit={(e) => e.preventDefault()}>
                          <input type="text"
                              className="groupname sendmessage"
                              placeholder="Type your message ..."
                              value={this.state.message}
                              name="message"
                              onChange={this.handleInputField}
                              onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                      this.sendMessage()
                                  }
                              }}
                          />
  
                          <button type="button"
                              className="send"
                              id="messageButton"
                              onClick={this.sendMessage}
                              disabled={this.state.isButtonLoding}
                              onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                      this.sendMessage()
                                  }
                              }}
                          >
                              <h1>{this.state.isButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Send'}</h1>
                          </button>
  
                      </form>
  
                      {this.validator.message(
                          "Message field is",
                          this.state.message,
                          "required|string"
                      )}
                    </>
           

                </div>
                {/* {this.state.isLoading && <Loader />} */}
             </div>
         </div>
         {/* message-popup end */}
         </>
        )
    }
};

export default PostRightSidebar