import "../product/Product.css";
import "./AddProduct.css";
import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ContactSupplier from "../contactsupplier/ContactSupplier";
import Loader from "../loader/Loader";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";

import sampleCSV from "../../../assets/sample-csv/sample.csv";
import chatArrow from "../../../assets/chat-arrow.png";
import postEdit from "../../../assets/edit-icon.png";

import CameraIcon from "../../../assets/camera-icon.png";
import VideoIcon from "../../../assets/video-icon.png";
import Youtube from "../../../assets/youtube.png";
import * as mime from 'react-native-mime-types';
import { saveAs } from 'file-saver';
import ButtonLoader from '../../components/loader/Loader'
import {
  PayPalButtons,
} from "@paypal/react-paypal-js";
import moment from "moment";
import { cssNumber } from "jquery";
const nodeCCAvenue = require("node-ccavenue");
const fs = require('fs')
const ccav = new nodeCCAvenue.Configure({
  merchant_id: process.env.REACT_APP_CCAVENUE_MERCHANT_ID,
  working_key: process.env.REACT_APP_CCAVENUE_LIVE_WORKING_KEY,
});

export class AddProductPage extends Component {
  constructor(props) {
    super(props);

    this.Servicevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        serType: {  // name the rule
          message: 'Invalid :attribute',
          rule: (val, params, validator) => {

            var allowTypes = ['free', 'paid'];

            if (allowTypes.includes(val)) {
              return true
            } else {
              return false
            }
          },

        },
      }
    })

    this.filevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleYoutubeLinks: {  // name the rule
          message: 'The :attribute must be a valid youtube links',
          rule: (val, params, validator) => {

            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(',');
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e)
              }
            }
            if (ine.length === 0) {
              return true
            } else {
              return false
            }
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params))
        },

        Logo: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_LOGOS.split(','),
          rule: (val, params, validator) => {

            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_LOGOS.split(',')

            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },
        Photos: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_PHOTOS.split(','),
          rule: (val, params, validator) => {
            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_PHOTOS.split(',')
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },
        PDF: {
          message: 'The :attribute must be a valid PDF file',
          rule: (val, params, validator) => {
            let files = (val.files) ?? [];
            let ret = true;
            let ar = ["pdf"];
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },
        VideoFiles: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_VIDEOS.split(','),
          rule: (val, params, validator) => {
            let Videofiles = (val.files) ?? [];
            let Videoret = true;
            let ar = process.env.REACT_APP_POST_VIDEOS.split(',');
            for (let i = 0; i < Videofiles.length; i++) {
              if (ar.includes(mime.extension(Videofiles[i].type)) === false) {
                Videoret = false;
              };
            }
            return Videoret
          },
        },
      }
    })

    this.Updatefilevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleYoutubeLinks: {  // name the rule
          message: 'The :attribute must be a valid youtube links',
          rule: (val, params, validator) => {

            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(',');
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e)
              }
            }
            if (ine.length === 0) {
              return true
            } else {
              return false
            }
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params))
        },

        Logo: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_LOGOS.split(','),
          rule: (val, params, validator) => {

            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_LOGOS.split(',')

            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },
        Photos: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_PHOTOS.split(','),
          rule: (val, params, validator) => {
            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_PHOTOS.split(',')
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },
        PDF: {
          message: 'The :attribute must be a valid PDF file',
          rule: (val, params, validator) => {
            let files = (val.files) ?? [];
            let ret = true;
            let ar = ["pdf"];
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },
        VideoFiles: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_VIDEOS.split(','),
          rule: (val, params, validator) => {
            let Videofiles = (val.files) ?? [];
            let Videoret = true;
            let ar = process.env.REACT_APP_POST_VIDEOS.split(',');
            for (let i = 0; i < Videofiles.length; i++) {
              if (ar.includes(mime.extension(Videofiles[i].type)) === false) {
                Videoret = false;
              };
            }
            return Videoret
          },
        },
      }
    })

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.Updatevalidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.csvvalidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.pdfvalidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.durationValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.CSVMAXValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.promoCodeValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.InvalidValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.prodForm = React.createRef();
    this.CSVupload = React.createRef();
    this.companyProfile = React.createRef();
    this.plogo = React.createRef();
    this.pdetails = React.createRef();
    this.ptransaction = React.createRef();

    this.csv = React.createRef();
    this.fle = React.createRef();

    this.pgimage = React.createRef();
    this.pgvideo = React.createRef();
    this.mediaForm = React.createRef();
    this.duration = React.createRef();
    this.ccavenueForm = React.createRef();

    this.handleCallback = this.handleCallback.bind(this);

    this.state = {
      searchKeyword: "",
      searchLocation: "",
      searchCondition: "",
      productDet: [],
      allProduct: [],
      locationData: [],
      listing: [],
      allIds: [],
      idData: [],
      total: 0,
      page: 0,
      nextAvailable: true,
      perPage: 6,
      contactSupplierModel: false,
      loading: false,
      pduration: "",
      stype: "0",
      pname: "",
      plocation: "",
      pcategory: "",
      pdescription: "",
      pcondition: [],
      plogo: "",
      pdetails: "",
      ptransaction: "",
      updateProduct: false,
      proID: "",
      csv: "",
      fle: "",
      prodImg: "",
      prodDetImg: "",
      prodTrans: "",
      isShowMedia: "",

      supplierId: 0,

      pgimage: "",
      pgvideo: "",
      pgyoutube: "",

      isSubmitted: false,
      amt: "",
      duration: "",
      promoCode: "",
      referenceId: "",
      encRequest: "",
      access_code: "AVBZ71EF36BA67ZBAB",
      currency: "",

      formData: "",
      promoErrorMsg: "",
      isValidCode: false,

      proCount: 1,
      isCsv: false,
      CSVProducts: [],
      isLoaded: false,
      isButtonLoding: false,
      isCSVButtonLoding: false,
      isPDFButtonLoding: false,
      isPublishButtonLoding: false,
      
      isAddProductLeftSideBar: false,
      openAddProductLeftSideBar: false,

      isReset: false,
      isVerified: false,
      isRenew:false,

      freeCount:0,
      isCreated:false,

      isccavenuePayment: false,
    };
  }
  
  handleSelected = async (e) => {
    await this.setState({
      stype: e.value?.replace(/^\s+/g, ''),
    });
  };

  handleProductCondition = async (e) => {
    
    await this.setState({
      pcondition: e,
      // pcondition: e.value?.replace(/^\s+/g, ''),
    });
  };

  handleInputChange = async (e) => {

    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });

    if (e.target.name === "plogo") {
      await this.setState({
        prodImg: "",
      });
    }

    if (e.target.name === "pdetails") {
      await this.setState({
        prodDetImg: "",
      });
    }

    if (e.target.name === "ptransaction") {
      await this.setState({
        prodTrans: "",
      });
    }

    if (e.target.files) {
      var file = e.target.files[0];

      var reader = new FileReader();
      reader.onload = async function (event) {
        // The file's text will be printed here
        if (e.target.name === "plogo") {
          await this.setState({
            prodImg: event.target.result,
          });
        }

        if (e.target.name === "pdetails") {
          await this.setState({
            prodDetImg: event.target.result,
          });
        }

        if (e.target.name === "ptransaction") {
          await this.setState({
            prodTrans: event.target.result,
          });
        }
      }.bind(this);

      reader.readAsDataURL(file);
    }

    if (e.target.name === "duration") {

      var threeMonths;
      var sixMonths;
      var twelveMonths;

      var currency = this.state.currency;

      if (currency === "INR") {
        threeMonths = 4000;
        sixMonths = 6400;
        twelveMonths = 9600;
      }

      else if (currency === "USD") {
        threeMonths = 200;
        sixMonths = 320;
        twelveMonths = 480;
      }

      else if (currency === "EUR") {
        threeMonths = 200;
        sixMonths = 320;
        twelveMonths = 480;
      }

      else if (currency === "HKD") {
        threeMonths = 1000;
        sixMonths = 1600;
        twelveMonths = 2400;
      }

      else if (currency === "JPY") {
        threeMonths = 20000;
        sixMonths = 32000;
        twelveMonths = 48000;
      }

      else if (currency === "GBP") {
        threeMonths = 150;
        sixMonths = 240;
        twelveMonths = 360;
      }

      else if (currency == "AUD") {
        threeMonths = 200;
        sixMonths = 320;
        twelveMonths = 480;
      }

      else {
        threeMonths = 200;
        sixMonths = 320;
        twelveMonths = 480;
      }

      if (e.target.value?.replace(/^\s+/g, '') === "3") {

        await this.setState({
          amt: threeMonths * parseInt((this.state.proCount) ? this.state.proCount : 1), //parseFloat(4000 / convertAmt.data.USD_INR).toFixed(2),
        });

      } else if (e.target.value?.replace(/^\s+/g, '') === "6") {
        await this.setState({
          amt: sixMonths * parseInt((this.state.proCount) ? this.state.proCount : 1), //parseFloat(6400 / convertAmt.data.USD_INR).toFixed(2),
        });
      } else if (e.target.value?.replace(/^\s+/g, '') === "12") {
        await this.setState({
          amt: twelveMonths * parseInt((this.state.proCount) ? this.state.proCount : 1), //parseFloat(9600 / convertAmt.data.USD_INR).toFixed(2),
        });
      } else {
        await this.setState({
          amt: "",
        });
      }

      this.setState({
        promoCode:"",
        isVerified:false,
        isValidCode: false,
      })
    }

  };


  handleCatInputChange = async (e) => {
    await this.setState({
      [e.target.name]: (parseInt(e.target.value) > 0) ? parseInt(e.target.value) : 1
    })

    var threeMonths;
    var sixMonths;
    var twelveMonths;

    var currency = this.state.currency;

    if (currency === "INR") {
      threeMonths = 4000;
      sixMonths = 6400;
      twelveMonths = 9600;
    }

    else if (currency === "USD") {
      threeMonths = 200;
      sixMonths = 320;
      twelveMonths = 480;
    }

    else if (currency === "EUR") {
      threeMonths = 200;
      sixMonths = 320;
      twelveMonths = 480;
    }

    else if (currency === "HKD") {
      threeMonths = 1000;
      sixMonths = 1600;
      twelveMonths = 2400;
    }

    else if (currency === "JPY") {
      threeMonths = 20000;
      sixMonths = 32000;
      twelveMonths = 48000;
    }

    else if (currency === "GBP") {
      threeMonths = 150;
      sixMonths = 240;
      twelveMonths = 360;
    }

    else if (currency == "AUD") {
      threeMonths = 200;
      sixMonths = 320;
      twelveMonths = 480;
    }

    else {
      threeMonths = 200;
      sixMonths = 320;
      twelveMonths = 480;
    }


    if (e.target.value !== "" && this.state.duration != '') {

      const val = parseInt(e.target.value);
      const duration = parseInt(this.state.duration)

      if (duration === 3) {

        await this.setState({
          amt: threeMonths * val, //parseFloat(4000 / convertAmt.data.USD_INR).toFixed(2),
        });

      } else if (duration === 6) {
        await this.setState({
          amt: sixMonths * val, //parseFloat(6400 / convertAmt.data.USD_INR).toFixed(2),
        });
      } else if (duration === 12) {
        await this.setState({
          amt: twelveMonths * val, //parseFloat(9600 / convertAmt.data.USD_INR).toFixed(2),
        });
      } else {
        await this.setState({
          amt: 0,
        });
      }
    }
  }

  addService = async (oid, ostatus, curr, amt) => {

    if(!this.state.isccavenuePayment){
      this.setState({
      isPublishButtonLoding: true
      });
    }

    const formData = this.state.formData;

    if (this.state.stype === 'paid') {
      if (ostatus === 'COMPLETED') {
        ostatus = 'Success';
      }
    }

    formData.delete('pduration');
    formData.delete('ppaid');
    formData.delete('pcurrency');
    formData.delete('ptax');
    formData.delete('porderid');
    formData.delete('ppromocode');

    formData.append('pduration', this.state.duration);
    formData.append('ppaid', amt > 0 ? amt : 0);
    formData.append('pcurrency', curr ? curr : "");
    
    if (curr === 'INR' || curr === 'IN'){
      formData.append('ptax', "18");
    } else {
      formData.append('ptax', "0");
    }

    formData.append('porderid', oid ? oid : "");
    formData.append('ppromocode', this.state.promoCode);

    if (this.state.isCsv === false) {
      var createProduct;

      if (!this.state.isRenew) {
        createProduct = await axios.post(
          `${process.env.REACT_APP_API_URL}fpservices/product`,
          formData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );
      } else {
        createProduct = await axios.post(
          `${process.env.REACT_APP_API_URL}fpservices/product/${this.state.proID
          }`,
          formData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );
      }

      if (createProduct.status === 200 && createProduct.data.status === true) {
        
        if (this.state.stype === 'paid') {

          // await this.setState({
          //   isCreated:false,
          // })

          if (this.state.isRenew) {
            this.payment(oid, ostatus, curr, this.state.proID);
          } else {
            this.payment(oid, ostatus, curr, createProduct.data.datas);
          }

        } else {

          toast.success(createProduct.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        }

      if(!this.state.isccavenuePayment){
        this.setState({
          isCreated:true,
          pduration: "",
          stype: "",
          pname: "",
          plocation: "",
          pcategory: "",
          pdescription: "",
          pcondition: [],
          plogo: "",
          pdetails: "",
          ptransaction: "",
          prodImg: "",
          prodDetImg: "",
          prodTrans: "",
          isSubmitted: false,
          nextAvailable: true,
          page: 0,
          listing: [],
          pgimage: "",
          pgvideo: "",
          pgvideo: "",

          amt: "",
          duration: "",
          promoCode: "",
          referenceId: "",
          encRequest: "",
          currency: "",
          formData: "",
          updateProduct:false,
          productDet:[],
        });

        this.durationValidator.hideMessages();
        this.forceUpdate();

        await this.productApi();
      }
      } else {

        toast.error(createProduct.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      formData.delete('products');
      formData.delete('ostatus');
      formData.delete('ImportProduct')
      formData.delete('stype')

      formData.append('products', JSON.stringify(this.state.CSVProducts));
      formData.append('ostatus', (ostatus) ? ostatus : "")
      formData.append('ImportProduct', this.state.proCount)
      formData.append('stype', 'paid')

      var createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/product-web`,
        formData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      )
      if (createProduct.status === 200 && createProduct.data.status === true) {

        if (this.state.stype === 'paid') {
          toast.success(createProduct.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      if(!this.state.isccavenuePayment){
        this.setState({
          isCreated:true,
          pduration: "",
          stype: "",
          pname: "",
          plocation: "",
          pcategory: "",
          pdescription: "",
          pcondition: [],
          plogo: "",
          pdetails: "",
          ptransaction: "",
          prodImg: "",
          prodDetImg: "",
          prodTrans: "",
          isSubmitted: false,
          nextAvailable: true,
          page: 0,
          listing: [],
          pgimage: "",
          pgvideo: "",
          pgvideo: "",
          amt: "",
          duration: "",
          promoCode: "",
          referenceId: "",
          encRequest: "",
          currency: "",
          formData: "",
          isCsv: false,
          CSVProducts: [],
          updateProduct:false,
          productDet:[],
        });
        
        this.durationValidator.hideMessages();
        this.forceUpdate();

        await this.productApi();
      }
      } else {
        toast.error(createProduct.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    
    if(!this.state.isccavenuePayment){
      this.setState({
        isPublishButtonLoding: false
      });
    }
    
  }

  payment = async (oid, ostatus, curr, serID) => {

    const type = "pr";

    const data = {
      paymentType: "1",
      currency: curr,
      productType: type,
      orderStatus: ostatus,
      orderId: oid,
      serId: serID,
      promoCode: this.state.promoCode,
      renewType: this.state.isRenew ? "renew" : "new",
    };

    try {
      const payment = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/payment`,
        data,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (payment.status === 200 && payment.data.status === true) {

        this.setState({
          isCreated:true,
        })

        toast.success(payment.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      } else {

        toast.error(
          payment.data.errors ? payment.data.errors : payment.data.message,
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }

    } catch (err) {
      if (err.response.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  promoCodeAPI = async () => {

    if (!this.state.isVerified) {

    var data = {
      amount: this.state.amt,
      service: "pr",
      promocode: this.state.promoCode,
    };

    if (data.amount !== "") {
      this.durationValidator.hideMessages();
      this.forceUpdate();

      if (data.promocode !== "") {
        this.promoCodeValidator.hideMessages();

        try {
          const promoCode = await axios.post(
            `${process.env.REACT_APP_API_URL}fpservices/promocode`,
            data,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );

          if (promoCode.status === 200 && promoCode.data.status === true) {
            await this.setState({
              amt: promoCode.data.datas,
              isValidCode: false,
              isVerified:true,
            });

            toast.success(promoCode.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {

            await this.setState({
              isValidCode: true,
              promoErrorMsg: promoCode.data.errors ? promoCode.data.errors : promoCode.data.message,
              isVerified:false,
            });

          }
        } catch (err) {
          if (err.response.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != '') {
           
          } else {
            
          }
        }
      } else {
        this.promoCodeValidator.showMessages();
        this.forceUpdate();
      }
    } else {
      this.durationValidator.showMessages();
      this.forceUpdate();
    }
  } else {
      toast.error(
        "Promocode already applied",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  selectpoimage = async () => {
    await this.setState({
      isShowMedia: "pgimage",
    });
  };

  selectVideo = async () => {
    await this.setState({
      isShowMedia: "pgvideo",
    });
  };

  selectYoutube = async () => {
    await this.setState({
      isShowMedia: "pgyoutube",
    });
  };

  hideModal = async () => {
    await this.setState({
      createPostModelOpen: false,
    });
  };

  // set ternary condition on the create post modal for showing the modal
  showModal = async () => {
    const contactSupplierModel =
      this.state.contactSupplierModel === false ? true : false;
    await this.setState({
      contactSupplierModel: contactSupplierModel,
    });
  };

  hideContactModal = async () => {
    await this.setState({
      contactSupplierModel: false,
    });
  };

  editProduct = async (prodId, type) => {

    if (type === "renew") {
      this.setState({
        isRenew:true,
      })
    } else {
      this.setState({
        isRenew:false,
      })
    }

    this.setState({
      isReset:true,
    })

    this.Updatefilevalidator.hideMessages();
    this.filevalidator.hideMessages();
    this.Servicevalidator.hideMessages();
    this.validator.hideMessages();
    
    this.forceUpdate();

    await this.setState({
      plogo: "",
      pdetails: "",
      ptransaction: "",
      productDet:[],
    });

    if (typeof prodId !== "undefined") {
      const updateProduct =
        this.state.updateProduct === false ? true : this.state.updateProduct;

      await this.setState({
        updateProduct: updateProduct,
        proID: prodId,
      });
    }

    try {
      const particularProductDetail = await axios.get(
        `${process.env.REACT_APP_API_URL}product/${prodId}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (
        particularProductDetail.status === 200 &&
        particularProductDetail.data.status === true
      ) {
        this.setState({
          productDet: particularProductDetail.data.datas,
        });
        
        var published = {};
        
        if(this.state.productDet.publish === 'used'){
          published = {
            value: 'used', 
            label: 'Used-Not Refurbished'
          }
        }
        else if(this.state.productDet.publish === 'refurbished'){
          published = {
            value: 'refurbished', 
            label: 'Used Refurbished'
          }
        } else {
          published = {
            value: 'new', 
            label: 'New'
          }
        }

        // transhis
        this.setState({
          pname: this.state.productDet.name,
          plocation: this.state.productDet.location,
          pcategory: this.state.productDet.category,
          pdescription: JSON.stringify(this.state.productDet.descrip)
            .split("<")[0]
            .substring(1),
          pcondition: published,
          prodImg: this.state.productDet.image,
          prodDetImg: this.state.productDet.prodprf,
          prodTrans: this.state.productDet.transhis,
          stype: this.state.productDet.stype,
          amt: this.state.productDet.paid,
          pgyoutube: this.state.productDet.gallery.link.join(),

        });
      } else {
        this.state = {
          pduration: "",
          stype: "",
          pname: "",
          plocation: "",
          pcategory: "",
          pdescription: "",
          pcondition: [],
          plogo: "",
          pdetails: "",
          ptransaction: "",
          csv: "",
          fle: "",
          pgyoutube: "",
          productDet: "",
        };
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != "") {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    await this.setState({
      openAddProductLeftSideBar: true,
    });
  };

  productApi = async () => {

    if (this.state.nextAvailable) {

      await this.setState({
        loading: true,
        page: this.state.page + 1,
      });

      const productData = {
        page: this.state.page,
        records: this.state.perPage,
      };

      try {
        const products = await axios.post(
          `${process.env.REACT_APP_API_URL}getAddedProducts`,
          productData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (products.status === 200 && products.data.status === true) {
          
          const freeCount = products.data.counts['count(id)'];

          this.setState({
            isLoaded: true,
            freeCount: freeCount
          })

          if (products.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }

          let oldArray = this.state.listing;
          oldArray = oldArray.concat(products.data.datas);

          await this.setState({
            allProduct: oldArray,
            allIds: oldArray,
            total: products.data.total
          });

          const allProducts = [];

          this.state.allProduct.forEach((data) => {
            allProducts.push(data.location);
          });

          let unique = [...new Set(allProducts)];

          await this.setState({
            locationData: unique,
          });

          await this.setState({
            listing: oldArray,
          });
        } else if (products.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else {
          await this.setState({
            isLoaded: true,
          })
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != "") {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      await this.setState({
        loading: false,
      });
    }
  };

  createProdAPI = async () => {

    if(this.state.freeCount >= 40) {
      this.setState({
        stype:'paid',
      })
    }

    let createProduct;
    window.scrollTo(0,0);
    if (this.state.updateProduct && !this.state.isRenew) {
      if (this.Updatevalidator.allValid() && this.Updatefilevalidator.allValid()) {

        this.setState({
          isButtonLoding: true
        })
        const formData = new FormData(await this.prodForm.current);

        formData.append("pduration", "0 days");

        try {
          createProduct = await axios.post(
            `${process.env.REACT_APP_API_URL}fpservices/product/${this.state.proID
            }`,
            formData,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
        } catch (err) {
          if (err?.response?.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != "") {
            toast.error(err?.response?.data?.error, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }

        this.setState({
          isButtonLoding: false,
          isReset:false,
        })
        this.Updatefilevalidator.hideMessages();

        this.Updatevalidator.hideMessages();
        this.validator.hideMessages();
        this.durationValidator.hideMessages();
        this.forceUpdate();

        await this.setState({
          openAddProductLeftSideBar: false,
        });
      } else {


        this.Updatefilevalidator.showMessages();

        this.Updatevalidator.showMessages();
        this.forceUpdate();
      }

      if (typeof createProduct !== "undefined") {
        if (createProduct.status === 200 && createProduct.data.status === true) {
          this.prodForm?.current?.reset();

          this.setState({
            pduration: "",
            stype: "",
            pname: "",
            plocation: "",
            pcategory: "",
            pdescription: "",
            pcondition: [],
            plogo: "",
            pdetails: "",
            ptransaction: "",
            prodImg: "",
            prodDetImg: "",
            prodTrans: "",
            updateProduct: false,
            productDet: "",
          });

          const productData = {
            page: this.state.page,
            records: this.state.perPage,
          };

          try {
            const productsData = await axios.post(
              `${process.env.REACT_APP_API_URL}getAddedProducts`,
              productData,
              {
                headers: {
                  Auth: `${localStorage.getItem("token")}`,
                },
              }
            );

            if (
              productsData.status === 200 &&
              productsData.data.status === true
            ) {
              if (productsData.data.datas.length <= this.state.perPage) {
                let oldArray = this.state.listing;
                oldArray = oldArray.concat(productsData.data.datas);

                await this.setState({
                  allProduct: oldArray,
                });

                const allProducts = [];

                this.state.allProduct.forEach((data) => {
                  allProducts.push(data.location);
                });

                await this.setState({
                  listing: oldArray,
                });

                await this.handleCondition(this.state.listing);
              }
            }
          } catch (err) {
            if (err?.response?.status === 403) {
              localStorage.removeItem("token");
              window.location = process.env.PUBLIC_URL;
            } else if (err?.response?.data?.error != "") {
              toast.error(err?.response?.data?.error, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error(err.message, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }

          toast.success(createProduct.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(createProduct.data.errors, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      }

    } else {

      if ((this.validator.allValid() && this.Servicevalidator.allValid() && this.filevalidator.allValid()) || (this.state.isRenew || this.Updatevalidator.allValid() && this.Updatefilevalidator.allValid())) {

        if(this.state.isRenew) {
          this.setState({
            amt:"",
            stype:'paid',
          })
        }

        const formData = new FormData(await this.prodForm.current);

        const type = "pr";
        var CurrentDate = moment().unix();

        const userData = localStorage.getItem("userData")

        const userDetails = JSON.parse(userData)

        const userLoc = localStorage.getItem("userLocation");

        const userCountryDetails = JSON.parse(userLoc)

        var currency = userCountryDetails.currency;

        if (currency === "INR") {
          currency = "INR";
        } else if (currency === "EUR"){
          currency = "EUR";
        } else if (currency === "HKD"){
          currency = "HKD";
        } else if (currency === "JPY"){
          currency = "JPY";
        } else if (currency === "GBP"){
          currency = "GBP";
        } else if (currency === "AUD"){
          currency = "AUD";
        } else {
          currency = "USD";
        }

        await this.setState({
          currency: currency,
        })

        const uid = userDetails.userId;

        var referenceId = uid + "-" + type + "-" + CurrentDate;

        this.setState({
          isSubmitted: true,
          formData: formData,
          referenceId: referenceId,
        })

        this.Servicevalidator.hideMessages();
        this.filevalidator.hideMessages();

        this.validator.hideMessages();
        this.forceUpdate();

        await this.setState({
          openAddProductLeftSideBar: false,
        });

      } else {

        this.filevalidator.showMessages();
        this.Servicevalidator.showMessages();
        this.validator.showMessages();
        this.forceUpdate();
      }
    }

  };

  async getOptions(){
    const res = await axios.get('https://jsonplaceholder.typicode.com/users')
    const data = res.data

    const options = data.map(d => ({
      "value" : d.id,
      "label" : d.name

    }))

    this.setState({selectOptions: options})

  }

  paymentPage = async (pid) => {
    if (!this.state.updateProduct) {
      const passData = {
        serId: pid,
        stype: "pr",
        sname: "product",
      };

      this.props.history.push({
        pathname: `/try-premium/product/${pid}`,
        state: passData,
      });
    }
  };

  uploadCsv = async () => {
    if (this.csvvalidator.allValid()) {
      this.setState({
        isCSVButtonLoding: true
      })
      const formData = new FormData(await this.CSVupload.current);

      formData.append("pduration", "0 days");

      try {
        const uploadCSV = await axios.post(
          `${process.env.REACT_APP_API_URL}fpservices/product/csv-web`,
          formData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (uploadCSV.status === 200 && uploadCSV.data.status === true) {

          if (uploadCSV.data.datas.products.length > 0) {
            const userData = localStorage.getItem("userData")
            const userDetails = JSON.parse(userData)
            
            const userLoc = localStorage.getItem("userLocation");

            const userCountryDetails = JSON.parse(userLoc)

            var currency = userCountryDetails.currency;

            if (currency === "INR") {
              currency = "INR";
            } else if (currency === "EUR"){
              currency = "EUR";
            } else if (currency === "HKD"){
              currency = "HKD";
            } else if (currency === "JPY"){
              currency = "JPY";
            } else if (currency === "GBP"){
              currency = "GBP";
            } else if (currency === "AUD"){
              currency = "AUD";
            } else {
              currency = "USD";
            }

            await this.setState({
              currency: currency,
            })

            const uid = userDetails.userId;
            const type = "pr";
            var CurrentDate = moment().unix();
            var referenceId = uid + "-" + type + "-" + CurrentDate;
            this.setState({
              csv: "",
              isCsv: true,
              isSubmitted: true,
              stype: "paid",
              referenceId: referenceId,
              CSVProducts: uploadCSV.data.datas.products,
              formData: formData
            });
          } else {
            this.setState({
              isCsv: false,
              isSubmitted: false,
            });

            var msg = 'Not parse products to uploaded CSV, Please try again';
            toast.error(msg, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {

          this.setState({
            isCsv: false,
            isSubmitted: false,
          });

          var msg = uploadCSV.data.errors
            ? uploadCSV.data.errors
            : uploadCSV.data.message;
          toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != "") {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      this.hideModal();
      this.setState({
        isCSVButtonLoding: false
      })
      this.csvvalidator.hideMessages();
      this.durationValidator.hideMessages();
      this.forceUpdate();

      await this.setState({
        openAddProductLeftSideBar: false,
      });
    } else {
      this.csvvalidator.showMessages();
      this.forceUpdate();
    }
  };

  uploadpdf = async () => {
    if (this.pdfvalidator.allValid()) {
      this.setState({
        isPDFButtonLoding: true
      })

      const formData = new FormData(await this.companyProfile.current);

      try {
        const uploadPDF = await axios.post(
          `${process.env.REACT_APP_API_URL}fpservices/company`,
          formData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (uploadPDF.status === 200 && uploadPDF.data.status === true) {
          this.setState({
            fle: "",
          });
          toast.success(uploadPDF.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          var msg = uploadPDF.data.errors
            ? uploadPDF.data.errors
            : uploadPDF.data.message;
          toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != "") {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      this.hideModal();
      this.setState({
        isPDFButtonLoding: false
      })
      this.pdfvalidator.hideMessages();
      this.forceUpdate();

      await this.setState({
        openAddProductLeftSideBar: false,
      });
    } else {
      this.pdfvalidator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount = async () => {
    await this.handleResize();

    this.setState({
      loading: true,
    });

    await this.productApi();

    window.addEventListener("scroll", this.listenToScroll);

    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isAddProductLeftSideBar: true,
        openAddProductLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isAddProductLeftSideBar: false,
      });
    }
  };

  listenToScroll = async () => {
    if (this.state.loading === false) {
      const per = (document.documentElement.offsetHeight * 10) / 100;

      if (
        document.documentElement.offsetHeight - per <=
        document.documentElement.scrollTop + window.innerHeight
      ) {
        await this.productApi();
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
   
    if (prevState.searchKeyword !== this.state.searchKeyword) {
      await this.productApi();

      await this.setState({
        loading: false,
      });
    }

    if (prevState.searchLocation !== this.state.searchLocation) {
      await this.productApi();

      await this.setState({
        loading: false,
      });
    }

    if (prevState.searchCondition !== this.state.searchCondition) {
      await this.productApi();

      await this.setState({
        loading: false,
      });
    }
  }

  handleCallback = async (childData) => {
    await this.setState({
      searchKeyword: childData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  handleLocation = async (optionData) => {
    await this.setState({
      searchLocation: optionData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  handleCondition = async (conditionData) => {
    await this.setState({
      searchCondition: conditionData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  ccavBtn = async () => {
    if (this.state.duration === "") {
      toast.error(`Duration should not be empty`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false
    } else {

      const userName = localStorage.getItem("userName")
    
      const orderParams = {
        order_id: this.state.referenceId,
        currency: this.state.currency,
        amount: this.state.amt,
        redirect_url: encodeURIComponent(
          `https://pexit.net/node_api/api/ccavenue`,
        ),
        billing_name: userName,
      };
      
      const encryptedOrderData = ccav.getEncryptedOrder(orderParams);
    
      await this.setState({
        encRequest: encryptedOrderData,
        isccavenuePayment: true
      });

      await this.addService(this.state.referenceId, 'awaited', this.state.currency, this.state.amt)
      // if(this.state.isCreated) {
        this.ccavenueForm.current.submit();
      // }
      
    }
  }

  showAddProductLeftSideBar = async () => {
    if (this.state.openAddProductLeftSideBar) {
      await this.setState({
        openAddProductLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openAddProductLeftSideBar: true,
      });
    }
  };

  Mouseclick = async() =>{
    document.querySelector(".caret-content select").size = 2;
  }

  resetForm = async() => {
    this.setState({
      isReset:false,
      productDet:[],
      pname: '',
      plocation: '',
      pcategory: '',
      pcondition: '',
      pdescription:'',

      pduration: "",
      stype: "",
      plogo: "",
      pdetails: "",
      ptransaction: "",
      prodImg: "",
      prodDetImg: "",
      prodTrans: "",
      updateProduct: false,
    })

    
  }

  render() {

    if (!this.state.isSubmitted) {
      return (
        <>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
            toastClassName="dark-toast"
          />
          {this.state.contactSupplierModel ? (
            <ContactSupplier hideModal={this.hideContactModal} />
          ) : (
            ""
          )}

          <section className="product-section">
            {(this.state.isAddProductLeftSideBar || this.state.openAddProductLeftSideBar) &&
              <div className="left-sidebar">
                <Link to={"/"}>
                  <div className="chat-arrow">
                    <img src={chatArrow} alt="chat-arrow" />
                  </div>
                </Link>

                <div className="sidebar-container">
                  <div className="header-content">
                    <h2 className="header">Add Product</h2>
                  </div>

                  <form
                    method="post"
                    ref={this.prodForm}
                    encType="multipart/form-data"
                  >
                    <div className="content-container">

                      {!this.state.updateProduct ?
                        <>
                          <div className="sidebar-content">
                            <div className="header-content">
                              <h3 className="header">Offer Type</h3>
                            </div>
                            <div className="caret-content">

                              {this.state.freeCount >= 40 ? 
                                <Select 
                                  className="caret-content-select"
                                  placeholder="Select Offer type"
                                  options={[
                                    {value:"paid",label:"Paid"}
                                  ]}
                                  name="stype"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  }}
                                  
                                  onChange={this.handleSelected}
                                  menuPosition={"fixed"}
                                />

                                : 
                                <Select 

                                  className="caret-content-select"
                                  placeholder="Select Offer type"
                                  options={[
                                    {value:"free",label:"Free"},
                                    {value:"paid",label:"Paid"}
                                  ]}
                                  name="stype"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  }}
                                  // value={this.state.stype}
                                  onChange={this.handleSelected}
                                  menuPosition={"fixed"}
                                  />
                            
                                }

                            </div>

                            {this.Servicevalidator.message(
                              "Offer type",
                              this.state.stype,
                              "serType"
                            )}
                          </div>
                        </>
                        :
                        <>
                          <input
                            type="hidden"
                            name="stype"
                            className="input-field field"
                            value={this.state.stype}
                          />
                          <input
                            type="hidden"
                            name="ppaid"
                            className="input-field field"
                            value={this.state.amt}
                          />
                        </>
                      }

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Upload Image</h3>
                        </div>

                        <input
                          type="file"
                          name="plogo"
                          className="input-field field"
                          ref={this.plogo}
                          accept="image/*"
                          onChange={(e) => {
                            this.setState({
                              new_plogo: e.target
                            })
                            this.filevalidator.allValid()
                          }}
                        />

                        <div className="d-flex d_preview_images adjustImg">
                          {
                            (this.state.productDet?.image !== '' && typeof this.state.productDet?.image !== 'undefined') ?
                              <div className="image-area-content view_img" id={this.state.productDet?.image}>
                                <img src={this.state.productDet?.image ? this.state.productDet?.image : process.env.PUBLIC_URL + '/images/docu.png'} target={'_blank'} alt="" onClick={(e) => {
                                  e.preventDefault()
                                  // saveAs(this.state.productDet?.image)
                                }} />

                              </div>
                              : ""
                          }
                        </div>

                       
                        {this.filevalidator.message(
                          "product image",
                          this.state?.new_plogo,
                          "required|Logo"
                        )}

                        {this.Updatefilevalidator.message(
                          "product image",
                          this.state?.new_plogo,
                          "Logo"
                        )}


                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Name</h3>
                        </div>

                        <input
                          type="text"
                          name="pname"
                          className="input-field field"
                          placeholder="Name"
                          onChange={this.handleInputChange}
                          value={this.state.pname}
                        />

                        {this.validator.message(
                          "product name",
                          this.state.pname,
                          "required|string"
                        )}
                        {this.Updatevalidator.message(
                          "product name",
                          this.state.pname,
                          "required|string"
                        )}
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Category</h3>
                        </div>

                        <input
                          type="text"
                          name="pcategory"
                          className="input-field field"
                          placeholder="Category"
                          onChange={this.handleInputChange}
                          value={this.state.pcategory}
                        />

                        {this.validator.message(
                          "product category",
                          this.state.pcategory,
                          "required|string"
                        )}

                        {this.Updatevalidator.message(
                          "product category",
                          this.state.pcategory,
                          "required|string"
                        )}
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Location</h3>
                        </div>

                        <input
                          type="text"
                          name="plocation"
                          className="input-field field"
                          placeholder="Location"
                          onChange={this.handleInputChange}
                          value={this.state.plocation}
                        />

                        {this.validator.message(
                          "product location",
                          this.state.plocation,
                          "required|string"
                        )}
                        {this.Updatevalidator.message(
                          "product location",
                          this.state.plocation,
                          "required|string"
                        )}
                      </div>                      

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Product Details</h3>
                        </div>

                        <input
                          type="file"
                          className="input-field field"
                          name="pdetails"
                          ref={this.pdetails}
                          accept="application/pdf"
                          onChange={(e) => {
                            this.setState({
                              new_pdetails: e.target
                            })
                            this.filevalidator.allValid()
                          }}
                        // value={this.state.pdetails}
                        />

                        <div className="d-flex d_preview_images adjustImg">
                          
                          {
                            (this.state.productDet?.prodprf !== '' && typeof this.state.productDet?.prodprf !== 'undefined') ?
                              <div className="image-area-content" id={this.state.productDet?.prodprf}>
                                <img src={process.env.PUBLIC_URL + '/images/docu.png'} target={'_blank'} alt="" onClick={(e) => {
                                  e.preventDefault()
                                  saveAs(this.state.productDet?.prodprf, !!this.state.productDet?.prodprf ? this.state.productDet?.prodprf.split('/').pop().split('.')[0] : '')
                                }} />

                              </div>
                              : ""
                          }
                        </div>

                        {this.filevalidator.message(
                          "file",
                          this.state?.new_pdetails,
                          "required|PDF"
                        )}

                        {this.Updatefilevalidator.message(
                          "file",
                          this.state?.new_pdetails,
                          "PDF"
                        )}

                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Transaction History</h3>
                        </div>

                        <input
                          type="file"
                          className="input-field field"
                          name="ptransaction"
                          ref={this.ptransaction}
                          accept="application/pdf"
                          onChange={(e) => {
                            this.setState({
                              new_ptransaction: e.target
                            })
                            this.filevalidator.allValid()
                          }}
                        // value={this.state.ptransaction}
                        />

                        <div className="d-flex d_preview_images adjustImg">
                          {
                            (this.state.productDet?.transhis !== '' && typeof this.state.productDet?.transhis !== 'undefined') ?
                              <div className="image-area-content" id={this.state.productDet?.transhis}>
                                <img src={process.env.PUBLIC_URL + '/images/docu.png'} target={'_blank'} alt="" onClick={(e) => {
                                  e.preventDefault()
                                  saveAs(this.state.productDet?.transhis, !!this.state.productDet?.transhis ? this.state.productDet?.transhis.split('/').pop().split('.')[0] : '')
                                }} />

                              </div>
                              : ""
                          }
                        </div>

                        {this.filevalidator.message(
                          "file",
                          this.state?.new_ptransaction,
                          "PDF"
                        )}

                        {this.Updatefilevalidator.message(
                          "file",
                          this.state?.new_ptransaction,
                          "PDF"
                        )}

                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Product Condition</h3>
                        </div>

                          <Select 
                          className="caret-content"
                          options={[
                            {value:"new",label:"New"},
                            {value:"used",label:"Used-Not Refurbished"},
                            {value:"refurbished",label:"Used-Refurbished"}
                          ]}
                          name="pcondition"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          value={this.state.pcondition}
                          onChange={this.handleProductCondition}
                          menuPosition={"fixed"}
                          />
                          
                        {/* </div> */}

                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Description</h3>
                        </div>

                        {/* <input
                          type="text"
                          name="pdescription"
                          className="input-field field"
                          placeholder="Description"
                          onChange={this.handleInputChange}
                          value={this.state.pdescription}
                        /> */}
                        
                        <textarea
                          type="text"
                          cols="30"
                          rows="3"
                          className="input-field field"
                          onChange={this.handleInputChange}
                          value={this.state.pdescription}
                          // value={this.state.pdescription.replace(/<[^>]+>/g, "")}
                          name="pdescription"
                        />

                        {this.validator.message(
                          "product description",
                          this.state.pdescription,
                          "required|string"
                        )}

                        {this.Updatevalidator.message(
                          "product description",
                          this.state.pdescription,
                          "required|string"
                        )}
                      </div>

                      <div className="sidebar-content uploadSection">
                        <div className="header-content">
                          <h3 className="header UploadattachText">Upload:</h3>
                        </div>

                        <div className="post-pannel attachIconsDiv">
                          <div className="post-pannel-content attachIcons">
                            <div className="pannel-icon tooltip">
                              <img
                                src={CameraIcon}
                                alt="CameraIcon"
                                className="img-big"
                                onClick={this.selectpoimage}
                              />
                              <span className="tooltiptext">Photo</span>
                            </div>
                          </div>

                          <div className="post-pannel-content attachIcons">
                            <div className="pannel-icon tooltip">
                              <img
                                src={VideoIcon}
                                alt="VideoIcon"
                                onClick={this.selectVideo}
                              />
                              <span className="tooltiptext">Video</span>
                            </div>
                          </div>

                          <div className="post-pannel-content attachIcons">
                            <div className="pannel-icon tooltip">
                              <img
                                src={Youtube}
                                alt="Youtube"
                                className="img-big"
                                onClick={this.selectYoutube}
                              />
                              <span className="tooltiptext">Youtube</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          "uploadInputs " +
                          (this.state.isShowMedia === "pgimage" ? "" : "d-none")
                        }
                      >
                        <input
                          multiple
                          type="file"
                          className="input-field field"
                          name="pgimage"
                          ref={this.pgimage}
                          onChange={(e) => {
                            this.setState({
                              new_pgimage: e.target
                            })
                            this.filevalidator.allValid()
                          }}
                          accept="image/*"
                        // value={this.state.pgimage}
                        />

                        <div className="d-flex d_preview_images adjustImg">
                          {
                            (Array.isArray(this.state.productDet?.gallery?.image)) ? this.state.productDet?.gallery?.image.map(doc => {
                              return <div className="image-area-content" id={doc}>
                                <img src={process.env.PUBLIC_URL + '/images/docu.png'} target={'_blank'} alt="" onClick={(e) => {
                                  e.preventDefault()
                                  saveAs(doc)
                                }} />

                              </div>
                            }) : ""
                          }
                        </div>

                        {this.filevalidator.message(
                          "Photo",
                          this.state?.new_pgimage,
                          "Photos"
                        )}

                        {this.Updatefilevalidator.message(
                          "Photo",
                          this.state?.new_pgimage,
                          "Photos"
                        )}

                      </div>

                      <div
                        className={
                          "uploadInputs " +
                          (this.state.isShowMedia === "pgvideo" ? "" : "d-none")
                        }
                      >
                        <input
                          multiple
                          type="file"
                          className="input-field field"
                          name="pgvideo"
                          ref={this.pgvideo}
                          onChange={(e) => {
                            this.setState({
                              new_pgvideo: e.target
                            })
                            this.filevalidator.allValid()
                          }}
                          accept="video/*"
                        // value={this.state.pgvideo}
                        />

                        <div className="d-flex d_preview_images adjustImg">
                          {
                            (Array.isArray(this.state.productDet?.gallery?.video)) ? this.state.productDet?.gallery?.video.map(doc => {
                              return <div className="image-area-content" id={doc}>
                                <img src={process.env.PUBLIC_URL + '/images/docu.png'} target={'_blank'} alt="" onClick={(e) => {
                                  e.preventDefault()
                                  saveAs(doc)
                                }} />

                              </div>
                            }) : ""
                          }
                        </div>

                        {this.filevalidator.message(
                          "Video",
                          this.state?.new_pgvideo,
                          "VideoFiles"
                        )}

                        {this.Updatefilevalidator.message(
                          "Video",
                          this.state?.new_pgvideo,
                          "VideoFiles"
                        )}

                      </div>

                      <div
                        className={
                          "uploadInputs " +
                          (this.state.isShowMedia === "pgyoutube" ? "" : "d-none")
                        }
                      >
                        <input
                          type="text"
                          className="input-field field"
                          name="pgyoutube"
                          onChange={this.handleInputChange}
                          value={this.state.pgyoutube}
                        />

                        {this.filevalidator.message(
                          "Youtube Links ",
                          this.state?.pgyoutube,
                          "multipleYoutubeLinks"
                        )}

                        {this.Updatefilevalidator.message(
                          "Youtube Links ",
                          this.state?.pgyoutube,
                          "multipleYoutubeLinks"
                        )}

                        <label className="labelText">
                          You can add multiple youtube links separated by comma(,)
                        </label>
                      </div>
                      

                      <div className="sidebar-content submitBtnDiv">
                        
                        <div className="sidebar-button">
                        {this.state.isReset && <button
                            type="button"
                            className="reset-button resetSubmit"
                            onClick={this.resetForm}
                            
                          >
                            Reset
                          </button>
                        }
                        
                        {!this.state.isReset ? 
                        <button
                            type="button"
                            className="reset-button"
                            onClick={this.createProdAPI}
                            disabled={this.state.isButtonLoding}
                          >
                            {this.state.isButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Submit'}
                          </button> : 

                          <button
                            type="button"
                            className="reset-button"
                            onClick={this.createProdAPI}
                            disabled={this.state.isButtonLoding}
                            >
                            {this.state.isButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Update'}
                          </button>
                          }
                          
                        </div>
                      </div>

                      <div className="sidebar-content">
                        <p className="sidebar-or-text">(Or)</p>
                      </div>
                    </div>
                  </form>

                  <form
                    method="post"
                    ref={this.CSVupload}
                    encType="multipart/form-data"
                  >
                    <div className="content-container">
                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Upload Catalogue</h3>
                        </div>

                        <input
                          type="file"
                          name="csv"
                          className="input-field field"
                          ref={this.csv}
                          accept=".csv"
                          onChange={this.handleInputChange}
                          value={this.state.csv}
                        />

                        {this.csvvalidator.message(
                          "file is Invalid CSV file. Valid CSV.",
                          this.state.csv,
                          "required|string"
                        )}
                      </div>

                      <div className="sidebar-button">
                        <button
                          type="button"
                          className="reset-button"
                          onClick={this.uploadCsv}
                          disabled={this.state.isCSVButtonLoding}
                        >
                          {this.state.isCSVButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Upload'}
                        </button>
                      </div>

                      <div className="sidebar-content clickDiv">
                        <p>
                          <a href={sampleCSV} className="clickHere" download>
                            Click here
                          </a>{" "}
                          for sample CSV
                        </p>
                      </div>
                    </div>
                  </form>
                  <form
                    method="post"
                    ref={this.companyProfile}
                    encType="multipart/form-data"
                  >
                    <div className="content-container">
                      <div className="header-content">
                        <h2 className="header">Add Company Profile</h2>
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Company Profile</h3>
                        </div>

                        <input
                          type="file"
                          name="fle"
                          className="input-field field"
                          ref={this.fle}
                          accept=".pdf"
                          onChange={this.handleInputChange}
                          value={this.state.fle}
                        />

                        {this.pdfvalidator.message(
                          "file is Invalid PDF file. Valid PDF.",
                          this.state.fle,
                          "required|string"
                        )}
                      </div>

                      <div className="sidebar-button">
                        <button
                          type="button"
                          className="reset-button"
                          onClick={this.uploadpdf}
                          disabled={this.state.isPDFButtonLoding}
                        >
                          {this.state.isPDFButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Upload'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }

            <div className="product-container">
              <div className="product-box-container">
                {" "}
                <div className="product-boxes">
                  {(!this.state.openAddProductLeftSideBar && !this.state.isAddProductLeftSideBar) &&
                    <div className="Addproduct-hamburger-menu" id="mycircle-hamburger">
                      <i class="fa fa-bars" aria-hidden="true" onClick={this.showAddProductLeftSideBar}></i>
                    </div>
                  }
                  {(this.state.openAddProductLeftSideBar && !this.state.isAddProductLeftSideBar) &&
                    <div className="Addproduct-hamburger-menu" id="mycircle-hamburger">
                      <i class="fa fa-times" aria-hidden="true" onClick={this.showAddProductLeftSideBar}></i>
                    </div>
                  }
                  <div className="product-header">
                    <h1 className="header">Active / Expired Catalogues</h1>
                    {this.state.isLoaded &&
                      <h2>Total {this.state.total}</h2>
                    }
                  </div>

                  {this.state.isLoaded &&
                    <div className="boxes-container">
                      {this.state.listing.length > 0 ? (
                        this.state.listing.map((lists, key) => (
                          <div className="product-boxes-content" key={key}>
                            <Link
                              to={{
                                pathname: `/product-detail/${lists.id}`,
                                state: lists.uid,
                              }}
                            >
                              <div className="product-image">
                                <img src={lists.image} alt="" />
                              </div>
                            </Link>

                            <div className="product-info">
                              <div className="product-name">
                                <h2 className="header">{lists.name}</h2>
                              </div>
                              <div className="product-place">
                                <h2 className="header grey-header">
                                  {lists.sname}
                                </h2>
                              </div>
                              <div className="product-address">
                                <h2 className="header grey-header">
                                  {lists.location}
                                </h2>
                              </div>
                              <div className="product-address">
                                <h2 className="header grey-header">
                                  Status :{" "}
                                  {(lists.state === 0 && lists.stype === 'paid') ? "Expired" : "Active"}
                                </h2>
                              </div>
                            </div>


                            {(lists.state === 0 && lists.stype === 'paid') ? (
                              <div className="product-edit">
                              <button
                                type="button"
                                className="product-email-button renewPro"
                                onClick={() =>
                                  this.editProduct(lists.id, 'renew')
                                }
                              >
                                Renew
                              </button>
                            </div>
                          ): (lists.state === 1) ? (
                              <div className="product-edit">
                                <button
                                  type="button"
                                  className="product-email-button"
                                  onClick={() => this.editProduct(lists.id, 'edit')}
                                >
                                  <img src={postEdit} alt="product-edit" />
                                </button>
                              </div>
                            ): ""
                            }

                          </div>
                        ))
                      ) : (
                        <h2>You haven't added any product</h2>
                      )}
                    </div>
                  }
                </div>
              </div>
              {this.state.loading && <Loader />}
            </div>

            {/* <!-- creategroup-popup start --> */}
            <div className="creategroup-modal-container">
              <div className="creategroup-modal contactsuuplier-modal">
                <button
                  type="button"
                  className="close-button-modal"
                  id="close-createGroup"
                >
                  <i className="fas fa-times" />
                </button>

                <div className="creategroup-header">
                  <h1 className="header">Contact Supplier</h1>
                </div>

                <div className="creategroup-content login-content-fields">
                  <form action="" className="login">
                    <input
                      type="text"
                      className="groupname"
                      placeholder="Recipient"
                    />
                    <input
                      type="text"
                      className="groupname"
                      placeholder="Sender Name"
                    />
                    <input
                      type="text"
                      className="groupname"
                      placeholder="Sender Email"
                    />
                    <input
                      type="text"
                      className="groupname"
                      placeholder="Sender Phone"
                    />
                    <textarea
                      className="description"
                      cols="30"
                      rows="5"
                      placeholder="Message"
                    />

                    <button
                      type="button"
                      className="login-button creategroup-button"
                      id="mySupplier"
                    >
                      <h2>Send</h2>
                    </button>
                  </form>
                </div>
              </div>
            </div>

            {/* <!-- creategroup-popup end --> */}
          </section>
        </>
      );
    } else {
        
      return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
            toastClassName="dark-toast"
          />

          <section className="product-section">
            <div className="product-container softwares-container">
              <div className="premium-container">

                {(this.state.isCsv === true) ?
                  <div className="parentDiv">
                    <label>Number of product you want to import</label>
                    <span className="labinsep">:</span>
                    <div>
                      <input
                        type="number"
                        id="prmcde"
                        name="proCount"
                        value={this.state.proCount}
                        onChange={async (e) => {
                          e.preventDefault();
                          await this.handleCatInputChange(e);

                          this.CSVMAXValidator.showMessages();
                        }}
                      />
                      {this.CSVMAXValidator.message(
                        "Import product",
                        this.state.proCount,
                        `required|numeric|max:${this.state.CSVProducts.length},num`
                      )}
                    </div>
                  </div> : ""}

                <div className="parentDiv">
                  <label>Price</label>
                  <span className="labinsep">:</span>

                  {(this.state.stype === 'free') ? <span id="pays">Free</span> :

                    <>
                      <span>

                        {this.state.currency} <span id="pays">{this.state.amt}</span>

                      </span>
                    </>
                  }
                </div>

                <div className="parentDiv">
                  <label>Duration</label>
                  <span className="labinsep">:</span>
                  <div>
                    <select
                      id="sftdur"
                      name="duration"
                      value={this.state.duration}
                      onChange={(e) => {
                        e.preventDefault();
                        this.handleInputChange(e);
                        this.durationValidator.allValid();
                        this.durationValidator.showMessages();
                      }}
                    >
                      <option value="">Select Duration</option>
                      {(this.state.stype === 'free') ?

                        <option value="12 months">12 Months</option>

                        :
                        <>
                          <option value="3">3 Months</option>
                          <option value="6">6 Months</option>
                          <option value="12">12 Months</option>
                        </>

                      }
                    </select>
                    {this.durationValidator.message(
                      "Please Select Duration",
                      this.state.duration,
                      "required|string"
                    )}
                  </div>
                </div>

                {(this.state.stype === 'paid') ?
                  <>
                    <div className="parentDiv">
                      <label>Have a Promo Code?</label>
                      <span className="labinsep">:</span>
                      <input
                        type="text"
                        id="prmcde"
                        name="promoCode"
                        value={this.state.promoCode}
                        onChange={this.handleInputChange}
                      />
                      <a id="apprm" onClick={this.promoCodeAPI} href="javascript:void(0);">
                        Apply
                      </a>

                      {this.state.promoCode !== "" && this.state.isValidCode ?
                        <div class="srv-validation-message"> {this.state.promoErrorMsg}</div>
                        : ""}

                      {this.promoCodeValidator.message(
                        "Please Enter Promocode",
                        this.state.promoCode,
                        "required|string"
                      )}

                      {this.InvalidValidator.message(
                        "Invalid Promocode",
                        this.state.promoCode,
                        "required|string"
                      )}
                    </div>
                  </>
                  : ""
                }

                <div>

                  {(this.state.stype === 'free') ?
                    <button type="submit" className="checkoutBtn ccavaenueBtn" onClick={() => this.addService()}>
                      {this.state.isPublishButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Publish'}
                    </button>

                    : <>

                      {(this.state.currency !== "INR") ?
                      <>
                        <PayPalButtons
                          style={{ layout: "horizontal", color:"black", label:"checkout", tagline: false }}
                          className="paypalBtn"
                          id="paypalBtn"
                          createOrder={(data, actions) => {
                            return actions.order.create({
                              intent: "CAPTURE",
                              purchase_units: [
                                {
                                  reference_id: this.state.referenceId,
                                  amount: {
                                    value: this.state.amt,
                                    currency_code: this.state.currency,
                                  },
                                }],
                            });
                          }}
                          onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {
                              
                              this.setState({
                                loading: true,
                                listing: [],
                                isSubmitted: false,
                              });

                              this.addService(
                                this.state.referenceId,
                                details.status,
                                details.purchase_units[0].amount.currency_code,
                                details.purchase_units[0].amount.value,
                              )

                            });
                          }}
                          onCancel={(data) => {
                            // Show a cancel page, or return to cart
                            toast.error(`Transaction cancelled`, {
                              position: "top-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }}

                          onError={(err) => {
                            // if (this.state.currency === "INR") {
                            toast.error(err, {
                              position: "top-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                            // }
                          }
                          }
                        />
                      </>
                        : 
                      <>
                      {/* CC Avenue START */}
                      <form ref={this.ccavenueForm} method="post" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"> 
                        
                        <input type="hidden" name="encRequest" value={this.state.encRequest} />
                        <input type="hidden" name="access_code" value={this.state.access_code} />
                        
                      </form>
                        
                        <button type="button" onClick={() => this.ccavBtn()} className="checkoutBtn ccavaenueBtn" >
                          Checkout
                        </button>
                     
                      {/* CC Avenue END */}

                    </>
                    }

                    </>

                  }
                </div>

                {(this.state.stype === 'paid') ?
                  <>
                    <div id="chcknte">
                      <p>
                        <b>Note: </b>
                      </p>
                      <p>
                        Please note down the reference number{" "}
                        <b>{this.state.referenceId}</b> for this transaction.
                      </p>
                      <p>
                        If the payment process is unsuccessful, then check the status
                        of the payment through "Transaction History" available under
                        "Paid Services" menu. If the status field corresponding to
                        your transaction reference number is "Null" or "Empty", then
                        retry with checkout again.
                      </p>
                      <p>
                        <span className="noteHeading">
                          Price shown above is exclusive of applicable Taxes and
                          Levies.{" "}
                        </span>
                      </p>
                    </div>
                  </> : ""}

              </div>

            </div>
          </section>
        </>
      );
    }

  }
}

export default withRouter(AddProductPage);
