import "../job/Job.css";
import "../product/Product.css";
import "../product-add/AddProduct.css";
import "./jobpost.css";

import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";

import Loader from "../loader/Loader";

import moment from "moment";
import chatArrow from "../../../assets/chat-arrow.png";
import postEdit from "../../../assets/edit-icon.png";
import * as mime from 'react-native-mime-types';
import { saveAs } from 'file-saver';
import ButtonLoader from '../../components/loader/Loader'
import {
  PayPalButtons,
} from "@paypal/react-paypal-js";

const nodeCCAvenue = require("node-ccavenue");
const fs = require('fs')
const ccav = new nodeCCAvenue.Configure({
  merchant_id: process.env.REACT_APP_CCAVENUE_MERCHANT_ID,
  working_key: process.env.REACT_APP_CCAVENUE_LIVE_WORKING_KEY,
});

export class JobPost extends Component {
  constructor(props) {
    super(props);

    this.Servicevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        serType: {  // name the rule
          message: 'Invalid :attribute',
          rule: (val, params, validator) => {

            var allowTypes = ['free', 'paid'];

            if (allowTypes.includes(val)) {
              return true
            } else {
              return false
            }
          },

        },
      }
    })

    this.filevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        Logo: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_LOGOS.split(','),
          rule: (val, params, validator) => {

            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_LOGOS.split(',')

            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },

      }
    })

    this.Updatefilevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        Logo: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_LOGOS.split(','),
          rule: (val, params, validator) => {

            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_LOGOS.split(',')

            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },

      }
    })

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.Updatevalidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.durationValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.promoCodeValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.InvalidValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.JobsElement = React.createRef();
    this.jlogo = React.createRef();

    this.ccavenueForm = React.createRef();

    this.state = {
      searchCondition: "",
      searchLocation: "",
      locationData: [],
      listing: [],
      total: 0,
      page: 0,
      nextAvailable: true,
      perPage: 6,
      loading: false,
      allJob: [],
      updateJob: false,
      jlogo: "",
      jtitle: "",
      jcompany: "",
      jcountry: [],
      jstate: [],
      jcity: [],
      jpostalcode: "",
      jfunction: "",
      jemptype: [],
      jindustry: "",
      jlevel: [],
      jdescription: "",
      jcdescription: "",
      japplytype: "profile",
      jemail: "",
      japplysite: "",
      jview: "",
      stype: "0",
      jpaid: "",
      jduration: "",
      jobID: "",
      jobDet: [],
      JobLogo: "",
      keyword: "",
      Options: [],
      states: "",
      stateOptions: [],
      cityOptions: [],
      city: "",
      userID: "",
      uemail: "",
      selectedOption: "",
      applytype: "",

      isSubmitted: false,
      amt: "",
      duration: "",
      promoCode: "",
      referenceId: "",
      encRequest: "",
      access_code: "AVBZ71EF36BA67ZBAB",
      currency: "",

      formData: "",

      isLoaded: false,
      isButtonLoding: false,
      isPublishButtonLoding: false,

      isJobServiceLeftSideBar: false,
      openJobServiceLeftSideBar: false,

      isValidCode: false,
      isVerified: false,
      promoErrorMsg: "",

      isRenew:false,
      freeCount:0,

      isCreated:false,
      isccavenuePayment: false,
    };
  }

  handleCondition = async (conditionData) => {
    await this.setState({
      searchCondition: conditionData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  userData = async () => {
    const userData = JSON.parse(`${localStorage.getItem("userData")}`);
    await this.setState({
      jemail: userData.userEmail,
    });
  };

  jobApi = async () => {
    if (this.state.nextAvailable) {
      await this.setState({
        loading: true,
        page: this.state.page + 1,
      });

      const jobData = {
        page: this.state.page,
        records: this.state.perPage,
      };

      try {
        const jobs = await axios.post(
          `${process.env.REACT_APP_API_URL}get-own-jobs`,
          jobData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (jobs.status === 200 && jobs.data.status === true) {

          const freeCount = jobs.data.counts['count(id)'];

          this.setState({
            isLoaded: true,
            freeCount:freeCount,
          })

          if (jobs.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }

          let oldArray = this.state.listing;
          oldArray = oldArray.concat(jobs.data.datas);

          await this.setState({
            allJob: oldArray,
            total: jobs.data.total
          });

          const allJobs = [];

          this.state.allJob.forEach((data) => {
            const location =
              data.addr + ", " + data.states + ", " + data.country;
            allJobs.push(location);
          });

          let unique = [...new Set(allJobs)];

          await this.setState({
            locationData: unique,
          });

          await this.setState({
            listing: oldArray,
          });

        } else if (jobs.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else {
          this.setState({
            isLoaded: true,
          })
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      await this.setState({
        loading: false,
      });
    }
  };

  createJob = async () => {

    if(this.state.freeCount >= 10) {
      this.setState({
        stype:'paid',
      })
    }

    let CreateJob;
    window.scrollTo(0,0);
    this.setState({
      isButtonLoding: true
    })
    if (this.state.updateJob && !this.state.isRenew) {
      if (this.Updatevalidator.allValid() && this.Updatefilevalidator.allValid()) {
        const formData = new FormData(await this.JobsElement.current);

        formData.append("jduration", "0 days");
        formData.append("status", "1");
        formData.append("jdescription", this.state.jdescription);
        formData.append("jcdescription", this.state.jcdescription);
        
        if (this.state.japplytype === "profile") {
          if (formData.has("jemail")) {
            formData.append("japplysite", " ");
          }
        } else {
          if (formData.has("japplysite")) {
            formData.append("jemail", " ");
          }
        }

        try {
          CreateJob = await axios.post(
            `${process.env.REACT_APP_API_URL}fpservices/job/${this.state.jobID
            }`,
            formData,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
        } catch (err) {
          if (err?.response?.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != '') {
            toast.error(err?.response?.data?.error, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }


        if (typeof CreateJob !== "undefined") {
          if (CreateJob.status === 200 && CreateJob.data.status === true) {

            this.JobsElement?.current?.reset();

            this.setState({
              jlogo: "",
              jtitle: "",
              jcompany: "",
              jcountry: [],
              jstate: [],
              jcity: [],
              jpostalcode: "",
              jfunction: "",
              jemptype: [],
              jindustry: "",
              jlevel: [],
              jdescription: "",
              jcdescription: "",
              japplytype: "profile",
              jemail: "",
              japplysite: "",
              jview: "",
              stype: "",
              jpaid: "",
              jduration: "",
              JobLogo: "",
              updateJob: false,
              jobDet: "",
            });

            const jobData = {
              page: this.state.page,
              records: this.state.perPage,
            };

            const jobs = await axios.post(
              `${process.env.REACT_APP_API_URL}getalljobs`,
              jobData,
              {
                headers: {
                  Auth: `${localStorage.getItem("token")}`,
                },
              }
            );

            if (jobs.status === 200 && jobs.data.status === true) {
              if (jobs.data.datas.length < this.state.perPage) {
                await this.setState({
                  nextAvailable: false,
                });
              }

              let oldArray = this.state.listing;
              oldArray = oldArray.concat(jobs.data.datas);

              await this.setState({
                allJob: oldArray,
              });

              const allJobs = [];

              this.state.allJob.forEach((data) => {
                const location =
                  data.addr + ", " + data.states + ", " + data.country;
                allJobs.push(location);
              });

              let unique = [...new Set(allJobs)];

              await this.setState({
                locationData: unique,
              });

              await this.setState({
                listing: oldArray,
              });

              await this.handleCondition(this.state.listing);
            } else if (jobs.status === 403) {
              localStorage.removeItem("token");
              window.location = process.env.PUBLIC_URL;
            }
            await this.setState({
              loading: false,
            });

            toast.success(CreateJob.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            var msg = CreateJob.data.errors
              ? CreateJob.data.errors
              : CreateJob.data.message;
            toast.error(msg, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }

        this.Updatefilevalidator.hideMessages();
        this.Updatevalidator.hideMessages();
        this.validator.hideMessages();
        this.forceUpdate();

        await this.setState({
          openJobServiceLeftSideBar: false,
        });

      } else {
        this.Updatefilevalidator.showMessages();
        this.Updatevalidator.showMessages();
        this.forceUpdate();
      }
    } else {
      if ((this.validator.allValid() && this.Servicevalidator.allValid()) || (this.state.isRenew && this.Updatevalidator.allValid() && this.Updatefilevalidator.allValid())) {
        const formData = new FormData(await this.JobsElement.current);
        formData.append("jdescription", this.state.jdescription);
        formData.append("jcdescription", this.state.jcdescription);
        
        if(this.state.isRenew) {
          this.setState({
            amt:"",
            stype:'paid',
          })
        }

        if (this.state.japplytype === "profile") {
          formData.append("japplysite", " ");
        } else {
          formData.append("jemail", " ");
        }

        const type = "jb";
        var CurrentDate = moment().unix();

        const userData = localStorage.getItem("userData")
        const userDetails = JSON.parse(userData)

        const userLoc = localStorage.getItem("userLocation");
        const userCountryDetails = JSON.parse(userLoc)

        var currency = userCountryDetails.currency;

        if (currency === "INR") {
          currency = "INR";
        } else if (currency === "EUR"){
          currency = "EUR";
        } else if (currency === "HKD"){
          currency = "HKD";
        } else if (currency === "JPY"){
          currency = "JPY";
        } else if (currency === "GBP"){
          currency = "GBP";
        } else if (currency === "AUD"){
          currency = "AUD";
        } else {
          currency = "USD";
        }


        await this.setState({
          currency: currency,
        })

        const uid = userDetails.userId;

        var referenceId = uid + "-" + type + "-" + CurrentDate;

        this.setState({
          isSubmitted: true,
          formData: formData,
          referenceId: referenceId,
        })

        if (this.filevalidator.allValid()) {
          this.filevalidator.hideMessages();
          this.forceUpdate();
        }

        this.Servicevalidator.hideMessages();
        this.validator.hideMessages();
        this.forceUpdate();

        await this.setState({
          openJobServiceLeftSideBar: false,
        });

      } else {

        if (!this.filevalidator.allValid()) {
          this.filevalidator.showMessages();
          this.forceUpdate();
        }

        this.validator.showMessages();
        this.Servicevalidator.showMessages();
        this.forceUpdate();
      }
    }

    this.setState({
      isButtonLoding: false
    })
  };

  editJob = async (jobId, country, state, type) => {

    if (type === "renew") {
      this.setState({
        isRenew:true,
      })
    } else {
      this.setState({
        isRenew:false,
      })
    }

    this.Servicevalidator.hideMessages();
    this.Updatefilevalidator.hideMessages();
    this.filevalidator.hideMessages();
    this.Updatevalidator.hideMessages();
    this.validator.hideMessages();
    this.forceUpdate();

    await this.setState({
      jlogo: "",
    });

    if (typeof jobId !== "undefined") {
      const updateJob =
        this.state.updateJob === false ? true : this.state.updateJob;

      await this.setState({
        updateJob: updateJob,
        jobID: jobId,
      });
    }

    try {
      // all states API call
      
      const allstates = await axios.get(
        `${process.env.REACT_APP_API_URL}state/${country}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (allstates.status === 200 && allstates.data.status === true) {
        const stateArray = [];

        allstates.data.datas.map((keys) =>
        
          stateArray.push({
            value: keys.name,
          })
        );

        this.setState({
          states: allstates.data.datas,
          stateOptions: stateArray,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    try {
      // all cities API call
      
      const allcity = await axios.get(
        `${process.env.REACT_APP_API_URL}cities/${state}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (allcity.status === 200 && allcity.data.status === true) {
        const cityArray = [];

        allcity.data.datas.map((keys) =>
          cityArray.push({
            value: keys.name,
          })
        );

        this.setState({
          city: allcity.data.datas,
          cityOptions: cityArray,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    try {
      const particularJobDetail = await axios.get(
        `${process.env.REACT_APP_API_URL}job/own-view/${jobId}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (
        particularJobDetail.status === 200 &&
        particularJobDetail.data.status === true
      ) {
        this.setState({
          jobDet: particularJobDetail.data.datas,
        });

        // transhis
        this.setState({
          jtitle: this.state.jobDet.title,
          jcompany: this.state.jobDet.cmpny,
          jcountry: {value: this.state.jobDet.country, label: this.state.jobDet.country }, //this.state.jobDet.country,
          jstate: {value: this.state.jobDet.states, label: this.state.jobDet.states }, //this.state.jobDet.states,
          jcity: {value: this.state.jobDet.addr, label: this.state.jobDet.addr }, //this.state.jobDet.addr,
          jpostalcode: this.state.jobDet.pincod,
          jfunction: this.state.jobDet.funct,
          jemptype: {value: this.state.jobDet.emptyp.toLowerCase(), label: this.state.jobDet.emptyp.charAt(0).toUpperCase() + this.state.jobDet.emptyp.slice(1) }, //this.state.jobDet.emptyp,
          jindustry: this.state.jobDet.indus,
          jlevel: {value: this.state.jobDet.level, label: this.state.jobDet.level }, //this.state.jobDet.level,
          jdescription: this.state.jobDet.jdesc,
          jcdescription: this.state.jobDet.cmpnydesc,
          japplysite: this.state.jobDet.website,
          jview: this.state.jobDet.prsnl,
          JobLogo: this.state.jobDet.logo,
          userID: this.state.jobDet.uid,
          stype: this.state.jobDet.stype,
          amt: this.state.jobDet.amount,
        });

      } else {
        this.state = {
          jlogo: "",
          jtitle: "",
          jcompany: "",
          jcountry: [],
          jstate: [],
          jcity: [],
          jpostalcode: "",
          jfunction: "",
          jemptype: [],
          jindustry: "",
          jlevel: [],
          jdescription: "",
          jcdescription: "",
          japplytype: "",
          jemail: "",
          japplysite: "",
          jview: "",
          stype: "",
          jpaid: "",
          jduration: "",
        };
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }


    await this.setState({
      openJobServiceLeftSideBar: true,
    });

  };

  componentDidMount = async () => {
    await this.handleResize();

    this.setState({
      loading: true,
    });

    try {
      const allCountries = await axios.get(
        `${process.env.REACT_APP_API_URL}countries`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (allCountries.status === 200 && allCountries.data.status === true) {
        const keywordArray = [];

        allCountries.data.datas.map((keys) =>
          keywordArray.push({
            value: keys.name,
          })
        );

        this.setState({
          keyword: allCountries.data.datas,
          Options: keywordArray,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    await this.jobApi();
    await this.userData();
    // window.addEventListener("scroll", this.listenToScroll);

    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isJobServiceLeftSideBar: true,
        openJobServiceLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isJobServiceLeftSideBar: false,
      });
    }
  };

  paymentPage = async (jid) => {
    if (!this.state.updateJob) {
      const passData = {
        serId: jid,
        stype: "jb",
        sname: "job",
      };

      this.props.history.push({
        pathname: `/try-premium/job/${jid}`,
        state: passData,
      });
    }
  }

  listenToScroll = async () => {
    if (this.state.loading === false) {
      const per = (document.documentElement.offsetHeight * 10) / 100;
      if (
        document.documentElement.offsetHeight - per <=
        document.documentElement.scrollTop + window.innerHeight
      ) {
        await this.jobApi();
      }
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.searchCondition !== this.state.searchCondition) {
      await this.jobApi();

      await this.setState({
        loading: false,
      });
    }
  }

  handleSelected = async (e) => {
    await this.setState({
      stype: e.value?.replace(/^\s+/g, ''),
    });
  };

  handleCountry = async (e) => {
    await this.setState({
      jcountry: e, //.value?.replace(/^\s+/g, '')
      jstate: [],
      jcity:[],
      stateOptions: [],
      cityOptions: [] 
    });

    const allstates = await axios.get(
      `${process.env.REACT_APP_API_URL}state/${e.value?.replace(/^\s+/g, '')}`,
      {
        headers: {
          Auth: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (allstates.status === 200 && allstates.data.status === true) {
      const stateArray = [];
      
      allstates.data.datas.map((keys) =>
        stateArray.push({
          value: keys.name,
        })
      );

      this.setState({
        states: allstates.data.datas,
        stateOptions: stateArray,
      });
    }
  };

  handleStates = async (e) => {
    await this.setState({
      jstate: e, //.value?.replace(/^\s+/g, '')
      jcity:[],
      cityOptions: [],
    });

    try {
      
      const allcity = await axios.get(
        `${process.env.REACT_APP_API_URL}cities/${e.value?.replace(/^\s+/g, '')}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
      
      if (allcity.status === 200 && allcity.data.status === true) {
        const cityArray = [];

        allcity.data.datas.map((keys) =>
          cityArray.push({
            value: keys.name,
          })
        );

        this.setState({
          city: allcity.data.datas,
          cityOptions: cityArray,
        });
        if(cityArray == 0){
          const cityoptionsare = [
            {value:this.state.jstate}
          ]; 
            await this.setState({
              cityOptions: [{value:this.state.jstate.value}],
            })
          }
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  
  handleCity = async (e) => {
    await this.setState({
      jcity: e, //.value?.replace(/^\s+/g, ''),
    });
  };
  
  handleEmployee = async (e) => {
    await this.setState({
      jemptype: e, //.value?.replace(/^\s+/g, '')
    });
  };
  
  handleSeniority = async (e) => {
    await this.setState({
      jlevel: e, //.value?.replace(/^\s+/g, ''),
    });
  };

  handleInputChange = async (e) => {
    if (typeof e === "string") {
      
      this.setState({
        jdescription: (e.replace(/^\s+/g, '') === '<p>&nbsp;&nbsp;</p>') ? '' : e.replace(/^\s+/g, ''),
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
      });

      if (e.target.name === "jview") {
        this.setState({
          jview: e.target.checked ? "show" : "no",
        });
      }

      if (e.target.name === "jlogo") {
        await this.setState({
          JobLogo: "",
        });
      }

      if (e.target.files) {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = async function (event) {
          // The file's text will be printed here
          if (e.target.name === "jlogo") {
            await this.setState({
              JobLogo: event.target.result,
            });
          }
        }.bind(this);

        reader.readAsDataURL(file);
      }

      if (e.target.name === "duration") {

        var threeMonths;
        var sixMonths;
        var twelveMonths;

        var currency = this.state.currency;

        if (currency === "INR") {
          threeMonths = 3200;
          sixMonths = 4800;
          twelveMonths = 7200;
        }

        else if (currency === "USD") {
          threeMonths = 160;
          sixMonths = 240;
          twelveMonths = 360;
        }

        else if (currency === "EUR") {
          threeMonths = 160;
          sixMonths = 240;
          twelveMonths = 360;
        }

        else if (currency === "HKD") {
          threeMonths = 800;
          sixMonths = 1200;
          twelveMonths = 1800;
        }

        else if (currency === "JPY") {
          threeMonths = 16000;
          sixMonths = 24000;
          twelveMonths = 36000;
        }

        else if (currency === "GBP") {
          threeMonths = 120;
          sixMonths = 180;
          twelveMonths = 270;
        }

        else if (currency == "AUD") {
          threeMonths = 160;
          sixMonths = 240;
          twelveMonths = 360;
        }

        else {
          threeMonths = 160;
          sixMonths = 240;
          twelveMonths = 360;
        }

        if (e.target.value?.replace(/^\s+/g, '') === "3") {
          await this.setState({
            amt: threeMonths, //parseFloat(4000 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else if (e.target.value?.replace(/^\s+/g, '') === "6") {
          await this.setState({
            amt: sixMonths, //parseFloat(6400 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else if (e.target.value?.replace(/^\s+/g, '') === "12") {
          await this.setState({
            amt: twelveMonths, //parseFloat(9600 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else {
          await this.setState({
            amt: "",
          });
        }

        this.setState({
          promoCode:"",
          isVerified:false,
          isValidCode: false,
        })
      }
    }
  };

  handleCompanyDesc = async (e) => {
    this.setState({
      jcdescription: (e.replace(/^\s+/g, '') === '<p>&nbsp;&nbsp;</p>') ? '' : e.replace(/^\s+/g, ''),
    });
  }

  addService = async (oid, ostatus, curr, amt) => {

    if(!this.state.isccavenuePayment){
      this.setState({
      isPublishButtonLoding: true
      });
    }
   
    const formData = this.state.formData;

    if (this.state.stype === 'paid') {
      if (ostatus === 'COMPLETED') {
        ostatus = 'Success';
      }
    }

    formData.delete('jduration');
    formData.delete('jpaid');
    formData.delete('jcurrency');
    formData.delete('jtax');
    formData.delete('jorderid');
    formData.delete('jpromocode');

    formData.append('jduration', this.state.duration);
    formData.append('jpaid', amt > 0 ? amt : 0);
    formData.append('jcurrency', curr ? curr : "");

    if (curr === 'INR' || curr === 'IN'){
      formData.append('jtax', "18");
    } else {
      formData.append('jtax', "0");
    }
    
    formData.append('jorderid', oid ? oid : "");
    formData.append('jpromocode', this.state.promoCode);

    var createProduct;
    if (!this.state.isRenew) {
      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/job`,
        formData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    } else {
      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/job/${this.state.jobID
        }`,
        formData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    }

    if (createProduct.status === 200 && createProduct.data.status === true) {

      if (this.state.stype === 'paid') {
        this.setState({
          isCreated:false,
        })

        if (!this.state.isRenew) {
          this.payment(oid, ostatus, curr, this.state.jobID);
        } else{
          this.payment(oid, ostatus, curr, createProduct.data.datas);
        }
        

      } else {

        toast.success(createProduct.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      }

    if(!this.state.isccavenuePayment){
      this.setState({
        isCreated:true,
        jlogo: "",
        jtitle: "",
        jcompany: "",
        jcountry: [],
        jstate: [],
        jcity: [],
        jpostalcode: "",
        jfunction: "",
        jemptype: [],
        jindustry: "",
        jlevel: [],
        jdescription: "",
        jcdescription: "",
        japplytype: "profile",
        jemail: "",
        japplysite: "",
        jview: "",
        stype: "",
        jpaid: "",
        jduration: "",
        JobLogo: "",
        isSubmitted: false,
        nextAvailable: true,
        page: 0,
        listing: [],
        pgimage: "",
        pgvideo: "",
        pgvideo: "",

        amt: "",
        duration: "",
        promoCode: "",
        referenceId: "",
        encRequest: "",
        currency: "",
        formData: "",

        jobDet:[],
        updateJob:false,
      });

      await this.jobApi();
    }
    } else {

      toast.error(createProduct.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if(!this.state.isccavenuePayment){
      this.setState({
        isPublishButtonLoding: false
      });
    }

  }

  payment = async (oid, ostatus, curr, serID) => {

    const type = "jb";

    const data = {
      paymentType: "1",
      currency: curr,
      productType: type,
      orderStatus: ostatus,
      orderId: oid,
      serId: serID,
      promoCode: this.state.promoCode,
      renewType: this.state.isRenew ? "renew" : "new",
    };

    try {
      const payment = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/payment`,
        data,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (payment.status === 200 && payment.data.status === true) {
          this.setState({
            isCreated:true,
          })
        toast.success(payment.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      } else {

        toast.error(
          payment.data.errors ? payment.data.errors : payment.data.message,
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }

    } catch (err) {
      if (err.response.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  promoCodeAPI = async () => {

    if (!this.state.isVerified) {

    var data = {
      amount: this.state.amt,
      service: "jb",
      promocode: this.state.promoCode,
    };

    if (data.amount !== "") {
      this.durationValidator.hideMessages();
      this.forceUpdate();

      if (data.promocode !== "") {
        this.promoCodeValidator.hideMessages();

        try {
          const promoCode = await axios.post(
            `${process.env.REACT_APP_API_URL}fpservices/promocode`,
            data,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );

          if (promoCode.status === 200 && promoCode.data.status === true) {
            await this.setState({
              amt: promoCode.data.datas,
              isVerified:true,
              isValidCode: false,
            });

            toast.success(promoCode.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.setState({
              isVerified:false,
              isValidCode: true,
              promoErrorMsg: promoCode.data.errors ? promoCode.data.errors : promoCode.data.message,
            })

            // toast.error(
            //   promoCode.data.errors
            //     ? promoCode.data.errors
            //     : promoCode.data.message,
            //   {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //   }
            // );
          }
        } catch (err) {
          if (err.response.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != '') {
            // toast.error(err?.response?.data?.error, {
            //   position: "top-right",
            //   autoClose: 4000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          } else {
            // toast.error(err.message, {
            //   position: "top-right",
            //   autoClose: 4000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          }
        }
      } else {
        this.promoCodeValidator.showMessages();
        this.forceUpdate();
      }
    } else {
      this.durationValidator.showMessages();
      this.forceUpdate();
    }
  } else {
    toast.error(
      "Promocode already applied",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
  };

  hideModal = async () => {
    await this.setState({
      createPostModelOpen: false,
    });
  };

  ccavBtn = async () => {
    if (this.state.duration === "") {
      toast.error(`Duration should not be empty`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false
    } else {
      const userName = localStorage.getItem("userName")
    
      const orderParams = {
        order_id: this.state.referenceId,
        currency: this.state.currency,
        amount: this.state.amt,
        redirect_url: encodeURIComponent(
          `https://pexit.net/node_api/api/ccavenue`,
        ),
        billing_name: userName,
      };
      const encryptedOrderData = ccav.getEncryptedOrder(orderParams);
    
      await this.setState({
        encRequest: encryptedOrderData,
        isccavenuePayment: true
      });

      await this.addService(this.state.referenceId, 'awaited', this.state.currency, this.state.amt)
      
      this.ccavenueForm.current.submit();
      
    }
  }

  showJobServiceLeftSideBar = async () => {
    if (this.state.openJobServiceLeftSideBar) {
      await this.setState({
        openJobServiceLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openJobServiceLeftSideBar: true,
      });
    }
  };
  render() {

    if (!this.state.isSubmitted) {
      return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
            toastClassName="dark-toast"
          />

          <section className="product-section">
            {(this.state.isJobServiceLeftSideBar || this.state.openJobServiceLeftSideBar) &&
              <div className="left-sidebar">
                <Link to={"/"}>
                  <div className="chat-arrow">
                    <img src={chatArrow} alt="chat-arrow" />
                  </div>
                </Link>

                <div className="sidebar-container">
                  <div className="header-content">
                    <h2 className="header">Post a Job</h2>
                  </div>

                  <form
                    method="post"
                    ref={this.JobsElement}
                    encType="multipart/form-data"
                  >
                    <div className="content-container">

                      {!this.state.updateJob ?
                        <>
                          <div className="sidebar-content">
                            <div className="header-content">
                              <h3 className="header">Offer Type</h3>
                            </div>
                            <div className="caret-content">
                              {/* <div className="select-caret">
                                <i className="fas fa-caret-down" />
                              </div>
                              <select
                                className="form-select"
                                name="stype"
                                aria-label="Default select example"
                                value={this.state.stype}
                                onChange={this.handleInputChange}
                              >
                                <option value="0" defaultValue>
                                  Select Offer Type
                                </option>
                                <option value="free">Free </option>
                                <option value="paid">Paid </option>
                              </select> */}

                            {this.state.freeCount >= 10 ? 
                              <Select 
                              className="caret-content-select"
                              placeholder="Select Offer type"
                              options={[
                                {value:"paid",label:"Paid"}
                              ]}
                              name="stype"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              }}
                              
                              onChange={this.handleSelected}
                              menuPosition={"fixed"}
                            /> :

                            <Select 
                              className="caret-content-select"
                              placeholder="Select Offer type"
                              options={[
                                {value:"free",label:"Free "},
                                {value:"paid",label:"Paid "}
                              ]}
                              name="stype"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              }}
                              // value={this.state.stype}
                              onChange={this.handleSelected}
                              menuPosition={"fixed"}
                            />
                          }
                            </div>

                            {/* {this.Servicevalidator.message(
                              "Offer type",
                              this.state.stype,
                              "serType"
                            )} */}
                          </div>
                        </> :
                        <>
                          <input
                            type="hidden"
                            name="stype"
                            className="input-field field"
                            value={this.state.stype}
                          />

                          <input
                            type="hidden"
                            name="jpaid"
                            className="input-field field"
                            value={this.state.amt}
                          />
                        </>
                      }

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Logo</h3>
                        </div>

                        <input
                          type="file"
                          className="input-field field"
                          name="jlogo"
                          ref={this.jlogo}
                          accept="image/*"
                          onChange={(e) => {
                            this.setState({
                              new_jlogo: e.target
                            })
                            this.filevalidator.allValid()
                          }}
                        // value={this.state.jlogo}
                        />
                        {this.filevalidator.message(
                          "logo",
                          this.state?.new_jlogo,
                          "Logo"
                        )}

                        {this.Updatefilevalidator.message(
                          "logo",
                          this.state?.new_jlogo,
                          "Logo"
                        )}

                        <div className="d-flex d_preview_images">
                          {(this.state?.jobDet?.logo) ?
                            <div className="image-area" id={this.state.jobDet.logo} style={{ width: "20%", marginTop: "10%" }} >
                              <img src={this.state.jobDet.logo} target={'_blank'} alt="" onClick={(e) => {
                                e.preventDefault()
                                saveAs(this.state.jobDet.logo)
                              }} />
                            </div>
                            : ""}

                        </div>

                        {/* <div
                        className={this.state.JobLogo ? "editImgDiv" : "dnone"}
                      >
                        <img
                          className={this.state.JobLogo ? "editImg" : "dnone"}
                          src={this.state.JobLogo}
                          alt="product-img"
                        />
                      </div> */}
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Job Posting<span className="requiredField">*</span></h3>
                        </div>

                        <input
                          type="text"
                          className="input-field field"
                          placeholder="Job Title"
                          name="jtitle"
                          onChange={this.handleInputChange}
                          value={this.state.jtitle}
                        />

                        {this.validator.message(
                          "job title",
                          this.state.jtitle,
                          "required|string"
                        )}

                        {this.Updatevalidator.message(
                          "job title",
                          this.state.jtitle,
                          "required|string"
                        )}

                        <input
                          type="text"
                          className="input-field field"
                          name="jcompany"
                          placeholder="Company"
                          onChange={this.handleInputChange}
                          value={this.state.jcompany}
                        />

                        {this.validator.message(
                          "job company",
                          this.state.jcompany,
                          "required|string"
                        )}

                        {this.Updatevalidator.message(
                          "job company",
                          this.state.jcompany,
                          "required|string"
                        )}

                        <div className="caret-content">
                          {/* <div className="select-caret">
                            <i className="fas fa-caret-down" />
                          </div> */}
                          {/* <select
                            className="form-select"
                            name="jcountry"
                            aria-label="Default select example"
                            onChange={this.handleInputChange}
                            value={this.state.jcountry}
                          >
                            <option defaultValue>Select Country</option>

                            {this.state.Options.map((option, key) => (
                              <option value={option.value} key={key}>{option.value}</option>
                            ))}
                          </select> */}
                          <Select 
                          // className="caret-content"
                          placeholder="Select Country"
                          options={
                            this.state.Options.map((option) => (
                              {value:option.value,label:option.value}
                            ))
                          }
                          name="jcountry"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          value={this.state.jcountry}
                          onChange={this.handleCountry}
                          menuPosition={"fixed"}
                          />
                        </div>
                        <div className="caret-content">
                          {/* <div className="select-caret">
                            <i className="fas fa-caret-down" />
                          </div>
                          <select
                            className="form-select"
                            name="jstate"
                            aria-label="Default select example"
                            onChange={this.handleInputChange}
                            value={this.state.jstate}
                          >
                            <option defaultValue>Select State</option>

                            {this.state.stateOptions.map((option, key) => (
                              <option value={option.value} key={key}>{option.value}</option>
                            ))}
                          </select> */}
                          <Select 
                          // className="caret-content"
                          placeholder="Select State"
                          options={
                            this.state.stateOptions.map((option) => (
                              {value:option.value,label:option.value}
                            ))
                          }
                          name="jstate"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          value={this.state.jstate}
                          onChange={this.handleStates}
                          menuPosition={"fixed"}
                          />
                        </div>
                        <div className="caret-content">
                          {/* <div className="select-caret">
                            <i className="fas fa-caret-down" />
                          </div>
                          <select
                            className="form-select"
                            name="jcity"
                            aria-label="Default select example"
                            onChange={this.handleInputChange}
                            value={this.state.jcity}
                          >
                            <option defaultValue>Select City</option>
                            {this.state.cityOptions.map((option, key) => (
                              <option value={option.value} key={key}>{option.value}</option>
                            ))}
                          </select> */}
                          <Select 
                          placeholder="Select City"
                          options={
                            this.state.cityOptions.map((option) => (
                              {value:option.value,label:option.value}
                            ))
                          }
                          name="jcity"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          value={this.state.jcity}
                          onChange={this.handleCity}
                          menuPosition={"fixed"}
                          />
                        </div>
                        {this.validator.message(
                          "city",
                          this.state.jcity,
                          "required"
                        )}

                        {this.Updatevalidator.message(
                          "city",
                          this.state.jcity,
                          "required"
                        )}
                        <input
                          type="text"
                          name="jpostalcode"
                          className="input-field field"
                          placeholder="Postal Code"
                          onChange={this.handleInputChange}
                          value={this.state.jpostalcode}
                        />
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">
                            Job Function<span className="requiredField">*</span>
                          </h3>
                        </div>

                        <input
                          type="text"
                          name="jfunction"
                          className="input-field field"
                          placeholder="Choose Function..."
                          onChange={this.handleInputChange}
                          value={this.state.jfunction}
                        />

                        {this.validator.message(
                          "job function",
                          this.state.jfunction,
                          "required|string"
                        )}

                        {this.Updatevalidator.message(
                          "job function",
                          this.state.jfunction,
                          "required|string"
                        )}
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">
                            Employee Type<span className="requiredField">*</span>
                          </h3>
                        </div>
                        <div className="caret-content">
                          {/* <div className="select-caret">
                            <i className="fas fa-caret-down" />
                          </div>
                          <select
                            className="form-select"
                            name="jemptype"
                            aria-label="Default select example"
                            onChange={this.handleInputChange}
                            value={this.state.jemptype}
                          >
                            <option defaultValue>Choose One...</option>
                            <option value="part-time">Part-Time</option>
                            <option value="full-time">Full-Time</option>
                            <option value="contract">Contract</option>
                            <option value="temporary">Temporary</option>
                            <option value="volunteer">Volunteer</option>
                            <option value="other">Other</option>
                          </select> */}
                          <Select 
                          placeholder="Choose One..."
                          options={[
                            {value:"part-time",label:"Part-time"},
                            {value:"full-time",label:"Full-time"},
                            {value:"contract",label:"Contract"},
                            {value:"temporary",label:"Temporary"},
                            {value:"volunteer",label:"Volunteer"},
                            {value:"other",label:"Other"},
                          ]}
                          name="jemptype"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          value={this.state.jemptype}
                          onChange={this.handleEmployee}
                          menuPosition={"fixed"}
                          />
                        </div>
                        {this.validator.message(
                          "employee type",
                          this.state.jemptype,
                          "required"
                        )}

                        {this.Updatevalidator.message(
                          "employee type",
                          this.state.jemptype,
                          "required"
                        )}
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">
                            Company Industry<span className="requiredField">*</span>
                          </h3>
                        </div>

                        <input
                          type="text"
                          name="jindustry"
                          className="input-field field"
                          placeholder="Choose an industry..."
                          onChange={this.handleInputChange}
                          value={this.state.jindustry}
                        />

                        {this.validator.message(
                          "company industry",
                          this.state.jindustry,
                          "required|string"
                        )}

                        {this.Updatevalidator.message(
                          "company industry",
                          this.state.jindustry,
                          "required|string"
                        )}
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">
                            Seniority level<span className="requiredField">*</span>
                          </h3>
                        </div>
                        <div className="caret-content">
                          {/* <div className="select-caret">
                            <i className="fas fa-caret-down" />
                          </div>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            name="jlevel"
                            onChange={this.handleInputChange}
                            value={this.state.jlevel}
                          >
                            <option value="">Choose One...</option>
                            <option value="Not Applicable">Not Applicable</option>
                            <option value="Internship">Internship</option>
                            <option value="Entry level">Entry level</option>
                            <option value="Associate">Associate</option>
                            <option value="Mid-Senior level">
                              Mid-Senior level
                            </option>
                            <option value="Director">Director</option>
                            <option value="Executive">Executive</option>
                          </select> */}
                          <Select 
                          placeholder="Choose One..."
                          options={[
                            {value:"Not Applicable",label:"Not Applicable"},
                            {value:"Internship",label:"Internship"},
                            {value:"Entry level",label:"Entry level"},
                            {value:"Associate",label:"Associate"},
                            {value:"Mid-Senior level",label:"Mid-Senior level"},
                            {value:"Director",label:"Director"},
                            {value:"Executive",label:"Executive"},
                          ]}
                          name="jlevel"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          value={this.state.jlevel}
                          onChange={this.handleSeniority}
                          menuPosition={"fixed"}
                          />
                        </div>
                        {this.validator.message(
                          "seniority level",
                          this.state.jlevel,
                          "required"
                        )}

                        {this.Updatevalidator.message(
                          "seniority level",
                          this.state.jlevel,
                          "required"
                        )}
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">
                            Job Description<span className="requiredField">*</span>
                          </h3>
                        </div>

                        {/* <input
                          type="text"
                          className="input-field field"
                          name="jdescription"
                          onChange={this.handleInputChange}
                          value={this.state.jdescription}
                        /> */}

                        {/* <textarea
                          type="text"
                          cols="30"
                          rows="3"
                          className="input-field field"
                          onChange={this.handleInputChange}
                          value={this.state.jdescription}
                          // value={this.state.jdescription.replace(/<[^>]+>/g, "")}
                          name="jdescription"
                        /> */}
                        <Editor
                            apiKey="textarea"
                            name="jdescription"
                            value={this.state.jdescription}
                            init={{
                              height: 200,
                              menubar: false,
                            }}
                            onEditorChange={this.handleInputChange}
                          />

                        {this.validator.message(
                          "job description",
                          this.state.jdescription,
                          "required|string"
                        )}

                        {this.Updatevalidator.message(
                          "job description",
                          this.state.jdescription,
                          "required|string"
                        )}
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Company Description</h3>
                        </div>

                        {/* <input
                          type="text"
                          className="input-field field"
                          name="jcdescription"
                          onChange={this.handleInputChange}
                          value={this.state.jcdescription}
                        /> */}

                        {/* <textarea
                          type="text"
                          cols="30"
                          rows="3"
                          className="input-field field"
                          onChange={this.handleInputChange}
                          value={this.state.jcdescription}
                          name="jcdescription"
                        /> */}

                          <Editor
                            apiKey="textarea"
                            name="jcdescription"
                            value={this.state.jcdescription}
                            init={{
                              height: 200,
                              menubar: false,
                            }}
                            onEditorChange={this.handleCompanyDesc}
                          />

                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">
                            How would you like people to apply?
                          </h3>
                        </div>
                        <div>
                          <p>
                            <input
                              type="radio"
                              name="japplytype"
                              className="radiobtn"
                              value="profile"
                              defaultChecked
                              onChange={this.handleInputChange}
                            />
                            <span>Let candidates apply with their profile</span>
                            <input
                              type="text"
                              name="jemail"
                              disabled={this.state.japplytype !== "profile"}
                              id="mymail"
                              className="jbinp"
                              placeholder={this.state.jemail}
                              onChange={this.handleInputChange}
                              value={this.state.jemail}
                            />
                          </p>
                          <p>
                            <input
                              type="radio"
                              name="japplytype"
                              className="radiobtn"
                              value="website"
                              onChange={this.handleInputChange}
                            />
                            <span>
                              Direct applicants to an external site to apply
                            </span>
                            <input
                              type="text"
                              name="japplysite"
                              id="mywebst"
                              disabled={this.state.japplytype !== "website"}
                              className="jbinp"
                              placeholder="http://yourcompany.com/job123"
                              onChange={this.handleInputChange}
                              value={this.state.japplysite}
                            />
                          </p>
                        </div>
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Want to add a personal touch?</h3>
                        </div>

                        <input
                          type="checkbox"
                          name="jview"
                          className="checkboxbtn"
                          onChange={this.handleInputChange}
                          value="show"
                          defaultChecked={
                            !!this.state.jview && this.state.jview === "show"
                          }
                        />
                        <label className="prsnlLabel" htmlFor="personalTouch">
                          {" "}
                          Show my profile on the job post
                        </label>

                        {this.validator.message(
                          "job view",
                          this.state.jview,
                          "string"
                        )}

                        {this.Updatevalidator.message(
                          "job view",
                          this.state.jview,
                          "string"
                        )}
                      </div>

                      <div className="sidebar-button">
                        {!this.state.updateJob ? 
                          <button
                            type="button"
                            className="reset-button"
                            onClick={this.createJob}
                            disabled={this.state.isButtonLoding}
                          >
                            {this.state.isButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Save'}
                          </button>
                          :
                          <button
                            type="button"
                            className="reset-button"
                            onClick={this.createJob}
                            disabled={this.state.isButtonLoding}
                          >
                            {this.state.isButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Update'}
                          </button>
                          }
                        
                        
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }


            <div className="product-container">
              <div className="product-box-container">
                <div className="product-boxes">
                  {(!this.state.openJobServiceLeftSideBar && !this.state.isJobServiceLeftSideBar) &&
                    <div className="JobService-hamburger-menu" id="mycircle-hamburger">
                      <i class="fa fa-bars" aria-hidden="true" onClick={this.showJobServiceLeftSideBar}></i>
                    </div>
                  }
                  {(this.state.openJobServiceLeftSideBar && !this.state.isJobServiceLeftSideBar) &&
                    <div className="JobService-hamburger-menu" id="mycircle-hamburger">
                      <i class="fa fa-times" aria-hidden="true" onClick={this.showJobServiceLeftSideBar}></i>
                    </div>
                  }
                  <div className="product-header">
                    <h1 className="header">Active / Expired Jobs</h1>
                    {this.state.isLoaded &&
                      <h2>Total {this.state.total}</h2>
                    }
                  </div>

                  {this.state.isLoaded &&
                    <div className="boxes-container">
                      {this.state.listing.length > 0 ? (
                        this.state.listing.map((lists, key) => (
                          <div className="product-boxes-content" key={key}>
                            <Link to={`/job-detail/${lists.id}`}>
                              <div className="product-image">
                                <img src={lists.logo} alt="" />
                              </div>
                            </Link>

                            <div className="product-info">
                              <div className="product-name">
                                <h2 className="header">{lists.title}</h2>
                              </div>
                              <div className="product-place">
                                <h2 className="header grey-header">
                                  {lists.cmpny}
                                </h2>
                              </div>
                              <div className="product-address">
                                <h2 className="header grey-header">
                                  {lists.addr +
                                    ", " +
                                    lists.states +
                                    ", " +
                                    lists.country}
                                </h2>
                              </div>
                              <div className="product-place">
                                <h2 className="header grey-header">
                                  Status : {lists.state === 0 ? "Expired" : "Active"}
                                </h2>
                              </div>



                              {(lists.state === 0 && lists.amount !== 0) ? (
                                <div className="product-edit">
                                  <button
                                    type="button"
                                    className="product-email-button renewPro"
                                    onClick={() =>
                                      this.editJob(lists.id,
                                        lists.country,
                                        lists.states,'renew')
                                    }
                                  >
                                    Renew
                                  </button>
                                </div>
                                ): (lists.state === 1) ? (

                                <div className="product-edit">
                                  <button
                                    type="button"
                                    className="product-email-button"
                                    onClick={() =>
                                      this.editJob(
                                        lists.id,
                                        lists.country,
                                        lists.states, 'edit'
                                      )
                                    }
                                  >
                                    <img src={postEdit} alt="product-edit" />
                                  </button>
                                </div>

                              ) : ""
                              }

                            </div>
                          </div>
                        ))
                      ) : (
                        <h2>You haven't added post job</h2>
                      )}
                    </div>
                  }
                </div>
              </div>
              {this.state.loading && <Loader />}
            </div>
          </section>
        </>
      );
    } else {
      return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
            toastClassName="dark-toast"
          />

          <section className="product-section">
            <div className="product-container softwares-container">
              <div className="premium-container">
                <div className="parentDiv">
                  <label>Price</label>
                  <span className="labinsep">:</span>

                  {(this.state.stype === 'free') ? <span id="pays">Free</span> :

                    <>
                      <span>

                        {this.state.currency} <span id="pays">{this.state.amt}</span>

                      </span>
                    </>
                  }
                </div>

                <div className="parentDiv">
                  <label>Duration</label>
                  <span className="labinsep">:</span>
                  <select
                    id="sftdur"
                    name="duration"
                    value={this.state.duration}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Select Duration</option>

                    {(this.state.stype === 'free') ?

                      <option value="30 days">30 Days</option>

                      :
                      <>
                        <option value="3">15 days</option>
                        <option value="6">30 days</option>
                        <option value="12">60 days</option>
                      </>

                    }
                  </select>

                  {this.durationValidator.message(
                    "Please Select Duration",
                    this.state.duration,
                    "required|string"
                  )}
                </div>

                {(this.state.stype === 'paid') ?
                  <>
                    <div className="parentDiv">
                      <label>Have a Promo Code? : </label>
                      <input
                        type="text"
                        id="prmcde"
                        name="promoCode"
                        value={this.state.promoCode}
                        onChange={this.handleInputChange}
                      />
                      <a id="apprm" onClick={this.promoCodeAPI} href="javascript:void(0);">
                        Apply
                      </a>

                      {this.state.promoCode !== "" && this.state.isValidCode ?
                        <div class="srv-validation-message"> {this.state.promoErrorMsg}</div>
                        : ""}

                      {this.promoCodeValidator.message(
                        "Please Enter Promocode",
                        this.state.promoCode,
                        "required|string"
                      )}

                      {this.InvalidValidator.message(
                        "Invalid Promocode",
                        this.state.promoCode,
                        "required|string"
                      )}
                    </div>
                  </>
                  : ""}

                <div>
                  {(this.state.stype === 'free') ?
                    <button type="submit" className="checkoutBtn ccavaenueBtn" onClick={() => this.addService()}>
                       {this.state.isPublishButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Publish'}
                    </button>

                    :
                    <>
                      {(this.state.currency !== "INR") ?
                      <>
                        <PayPalButtons
                          style={{ layout: "horizontal", color:"black", label:"checkout", tagline: false }}
                          className="paypalBtn"
                          createOrder={(data, actions) => {
                            return actions.order.create({
                              intent: "CAPTURE",
                              purchase_units: [
                                {
                                  reference_id: this.state.referenceId,
                                  amount: {
                                    value: this.state.amt,
                                    currency_code: this.state.currency,
                                  },
                                },
                              ],
                            });
                          }}

                          onClick={() => {
                            if (this.state.duration === "") {
                              toast.error(`Duration should not be empty`, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                              return false
                            }
                          }}

                          onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {

                              this.setState({
                                loading: true,
                                listing: [],
                                isSubmitted: false,
                              });

                              this.addService(
                                this.state.referenceId,
                                details.status,
                                details.purchase_units[0].amount.currency_code,
                                details.purchase_units[0].amount.value,
                              )

                            });
                          }}
                          onCancel={(data) => {

                            // Show a cancel page, or return to cart
                            toast.error(`Transaction cancelled`, {
                              position: "top-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }}

                          onError={(err) => {
                            if (this.state.currency === "INR") {
                              toast.error(`Paypal won't accept ${this.state.currency} please change the currency`, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                            }
                          }
                          }
                        />
                        </> :
                        <>

                       {/* CC Avenue START */}
                       <form ref={this.ccavenueForm} method="post" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"> 
                        
                        <input type="hidden" name="encRequest" value={this.state.encRequest} />
                        <input type="hidden" name="access_code" value={this.state.access_code} />
                        
                      </form>
                        
                      <button type="button" onClick={() => this.ccavBtn()} className="checkoutBtn ccavaenueBtn" >
                        Checkout
                      </button>
                     
                      {/* CC Avenue END */}

                        </>
                      }

                    </>
                  }
                </div>

                {(this.state.stype === 'paid') ?
                  <>
                    <div id="chcknte">
                      <p>
                        <b>Note: </b>
                      </p>
                      <p>
                        Please note down the reference number{" "}
                        <b>{this.state.referenceId}</b> for this transaction.
                      </p>
                      <p>
                        If the payment process is unsuccessful, then check the status
                        of the payment through "Transaction History" available under
                        "Paid Services" menu. If the status field corresponding to
                        your transaction reference number is "Null" or "Empty", then
                        retry with checkout again.
                      </p>
                      <p>
                        <span className="noteHeading">
                          Price shown above is exclusive of applicable Taxes and
                          Levies.{" "}
                        </span>
                      </p>
                    </div>
                  </> : ""}
              </div>
            </div>
          </section>

        </>
      );
    }
  }
}

export default withRouter(JobPost);
