import { Route, Switch } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./scrollTop";
import { BrowserRouter as Router } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import Views from "./views";

function App() {
  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          "AauBQqvtwzeaoSZwXKfAqBkHJ-j29Oag9ncwwZvvHCi581YciTYdo3cl0fNwvFL0lThcg9vMzhnk--JE&currency=USD",
      }}
    >
      <ScrollToTop>
        <Router basename={'/'}>
          <Switch>
            <Route path="/" component={Views} />
          </Switch>
        </Router>
      </ScrollToTop>
    </PayPalScriptProvider>
  );
}

export default App;
