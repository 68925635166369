import "./Notfound.css";

const Notfound = () => {
  return (
    <div className="not-found full-height flexbox flexbox-centered">
      <h3>404 | Page not found</h3>
    </div>
  );
};
export default Notfound;
