import "../postPage/Post-page.css";
import "../postPage/Post-page";
import "../../components/modals/Popup.css";
import "../creategroup/CreateGroup.css";

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import { withRouter } from "react-router-dom";

export class CreateGroup extends Component {
  constructor(props) {
    super(props);

    //dynamic validation for title field and content field
    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });
    
    this.state = {
      connections: [],
      isSelects: true,
      selectedOption: "",
      createName: "",
      createDescri: "",
      options: [],
      selectedOptions: [],
      createGroup: {
        users: [{value:"public",label:"Public"}],
      },
    };
  }

  handleChange = async (e) => {
    const data = this.state.createGroup;
    data.users[0].label = e.value?.replace(/^\s+/g, '');
    data.users[0].value = e.value?.replace(/^\s+/g, '');

    let isSelects = this.state.isSelects;
    await this.setState({
      createGroup: data,
      isSelects: isSelects,
    });
  };

  handleInputChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });
  };

  componentDidMount = async () => {
    try {
      const userD = localStorage.getItem("userData");
      const ParseDet = JSON.parse(userD);
      const logUserId = ParseDet.userId;

      const conn = await axios.get(
        `${process.env.REACT_APP_API_URL}connections-web/${Number(logUserId)}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (conn.status === 200 && conn.data.status === true) {
        this.setState({
          connections: conn.data.datas,
          options: conn.data.datas.map((conn) => {
            return { value: conn.username, label: `${conn.name}` };
          }),
        });
      } else if (conn.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if(err?.response?.data?.error != '' ){
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  handleSelected = (selectedOption) => {
    this.setState({ selectedOption });
  };

  submitCreateGroup = async () => {
    if (this.validator.allValid()) {
      const groupData = {
        gtype: this.state.createGroup.users[0].value,
        gname: this.state.createName,
        gdescription: this.state.createDescri,
        gusers: Array.isArray(this.state.selectedOption)
        ? this.state.selectedOption.map((option) => option.value).join(',')
        : "",
      };
      
      
      try {
        const groupres = await axios.post(
          `${process.env.REACT_APP_API_URL}group/create`,
          groupData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );
        
        if (groupres.status === 200) {
          if (groupres.data.status === true)
            toast.success(groupres.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        } else if (groupres.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else {
          toast.error(groupres.data.errors, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if(err?.response?.data?.error != '' ){
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      this.props.hideModal();
      this.props.history.push("/groups");
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />
        {/* Create Group Model START */}
        <div className="creategroup-modal-container create-model open">
          <div className="creategroup-modal">
            <button
              type="button"
              className="close-button-modal"
              id="close-createGroup"
              onClick={this.props.hideModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-header">
              <h1 className="header">Create Group</h1>
            </div>

            <div className="creategroup-content login-content-fields">
              <form action="" className="login">
                <input
                  type="text"
                  className="groupname"
                  placeholder="Group name"
                  name="createName"
                  value={this.state.createName}
                  onChange={this.handleInputChange}
                />

                {this.validator.message(
                  "group name field is",
                  this.state.createName,
                  "required|string"
                )}

                <textarea
                  className="description"
                  cols="30"
                  rows="5"
                  placeholder="Description"
                  name="createDescri"
                  value={this.state.createDescri}
                  onChange={this.handleInputChange}
                />

                {this.validator.message(
                  "description field is",
                  this.state.createDescri,
                  "required|min:8"
                )}

                {this.state.isSelects ? (
                  <Select
                    className="form-select option-multi-select"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    isMulti
                    value={this.state.selectedOption}
                    onChange={this.handleSelected}
                    options={this.state.options}
                    menuPosition={"fixed"}
                    placeholder="Assign to..."
                  />
                ) : (
                  ""
                )}

                {this.validator.message(
                  "select field is",
                  this.state.selectedOption,
                  "required"
                )}


                  <Select
                  placeholder="Select group type.."
                    className="form-select option-multi-select"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={this.state.createGroup.users}
                    onChange={this.handleChange}
                    options={[
                        {value:"public",label:"Public"}, 
                        {value:"closed",label:"Closed"}, 
                        {value:"private",label:"Private"}
                    ]}
                    menuPosition={"fixed"}
                  />

                
                {this.state.createGroup.users[0].value === 'public' ? (
                  <h2 className="header-text">
                    * Any PEXit user can find the group; see its members and
                    their posts
                  </h2>
                ) : ""}

                {this.state.createGroup.users[0].value === 'closed' ? (
                  <h2 className="header-text">
                    * Any PEXit user can find the group and see its members.
                    Only members can see posts.
                  </h2>
                ) : ""}

                {this.state.createGroup.users[0].value === 'private' ? (
                  <h2 className="header-text">
                    * Only Group members can see its members and their posts
                  </h2>
                ) : ""}

                <button
                  type="button"
                  className="login-button creategroup-button"
                  id="createGroup"
                  onClick={this.submitCreateGroup}
                >
                  <h2>Create Group</h2>
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* Create Group Model END */}
      </>
    );
  }
}

export default withRouter(CreateGroup);
