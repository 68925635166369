import "../software/Software.css";
import "../product/Product.css";
import "../addsoftware/AddSoftware.css";
import "./addService.css";

import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import ChatButton from "../../components/chatbutton/ChatButton";
import Loader from "../loader/Loader";
import moment from "moment";
import Select from 'react-select'
import chatArrow from "../../../assets/chat-arrow.png";
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import * as mime from 'react-native-mime-types';
import Files from "../../../assets/files.png";
import CameraIcon from "../../../assets/camera-icon.png";
import VideoIcon from "../../../assets/video-icon.png";
import AddLink from "../../../assets/add-link.png";
import Youtube from "../../../assets/youtube.png";
import { saveAs } from 'file-saver';
import ButtonLoader from '../../components/loader/Loader'
import EditIcon from "../../../assets/edit-icon.png";


import {
  PayPalButtons,
} from "@paypal/react-paypal-js";

const nodeCCAvenue = require("node-ccavenue");
const fs = require('fs')
const ccav = new nodeCCAvenue.Configure({
  merchant_id: process.env.REACT_APP_CCAVENUE_MERCHANT_ID,
  working_key: process.env.REACT_APP_CCAVENUE_LIVE_WORKING_KEY,
});


export class AddServicesPage extends Component {
  constructor(props) {
    super(props);

    this.Servicevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        serType: {  // name the rule
          message: 'Invalid :attribute',
          rule: (val, params, validator) => {

            var allowTypes = ['free', 'paid'];

            if (allowTypes.includes(val)) {
              return true
            } else {
              return false
            }
          },

        },
      }
    })

    this.filevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleLinks: {  // name the rule
          message: 'The :attribute must be a valid links',
          rule: (val, params, validator) => {

            var reg = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
            var links = val.split(',');
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e)
              }
            }
            if (ine.length === 0) {
              return true
            } else {
              return false
            }
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params))
        },
        multipleYoutubeLinks: {  // name the rule
          message: 'The :attribute must be a valid youtube links',
          rule: (val, params, validator) => {

            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(',');
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e)
              }
            }
            if (ine.length === 0) {
              return true
            } else {
              return false
            }
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params))
        },
        PostFiles: {  // name the rule
          message: 'The :attribute must be a valid file types, Invalid file types are ' + process.env.REACT_APP_POST_FILES.split(','),
          rule: (val, params, validator) => {
            let files = (val.files) ?? [];
            let retfiles = true;
            let arfiles = process.env.REACT_APP_POST_FILES.split(',')
            for (let i = 0; i < files.length; i++) {
              if (arfiles.includes(files[i].name.split('.').pop()) !== false) {
                retfiles = false;
              }
            }
            return retfiles
          }
        },
        Photos: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_PHOTOS.split(','),
          rule: (val, params, validator) => {
            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_PHOTOS.split(',')
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },
        VideoFiles: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_VIDEOS.split(','),
          rule: (val, params, validator) => {
            let Videofiles = (val.files) ?? [];
            let Videoret = true;
            let ar = process.env.REACT_APP_POST_VIDEOS.split(',');
            for (let i = 0; i < Videofiles.length; i++) {
              if (ar.includes(mime.extension(Videofiles[i].type)) === false) {
                Videoret = false;
              };
            }
            return Videoret
          },
        },
        Logo: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_LOGOS.split(','),
          rule: (val, params, validator) => {

            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_LOGOS.split(',')

            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },
      }
    })

    this.Updatefilevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleLinks: {  // name the rule
          message: 'The :attribute must be a valid links',
          rule: (val, params, validator) => {

            var reg = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
            var links = val.split(',');
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e)
              }
            }
            if (ine.length === 0) {
              return true
            } else {
              return false
            }
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params))
        },
        multipleYoutubeLinks: {  // name the rule
          message: 'The :attribute must be a valid youtube links',
          rule: (val, params, validator) => {

            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(',');
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e)
              }
            }
            if (ine.length === 0) {
              return true
            } else {
              return false
            }
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params))
        },
        PostFiles: {  // name the rule
          message: 'The :attribute must be a valid file types, Invalid file types are ' + process.env.REACT_APP_POST_FILES.split(','),
          rule: (val, params, validator) => {
            let files = (val.files) ?? [];
            let retfiles = true;
            let arfiles = process.env.REACT_APP_POST_FILES.split(',')
            for (let i = 0; i < files.length; i++) {
              if (arfiles.includes(files[i].name.split('.').pop()) !== false) {
                retfiles = false;
              }
            }
            return retfiles
          }
        },
        Photos: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_PHOTOS.split(','),
          rule: (val, params, validator) => {
            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_PHOTOS.split(',')
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },
        VideoFiles: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_VIDEOS.split(','),
          rule: (val, params, validator) => {
            let Videofiles = (val.files) ?? [];
            let Videoret = true;
            let ar = process.env.REACT_APP_POST_VIDEOS.split(',');
            for (let i = 0; i < Videofiles.length; i++) {
              if (ar.includes(mime.extension(Videofiles[i].type)) === false) {
                Videoret = false;
              };
            }
            return Videoret
          },
        },
        Logo: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_LOGOS.split(','),
          rule: (val, params, validator) => {

            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_LOGOS.split(',')

            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },
      }
    })

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.Updatevalidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.durationValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.promoCodeValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.InvalidValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.ServicesElement = React.createRef();
    this.slogo = React.createRef();
    this.sfile = React.createRef();
    this.simage = React.createRef();
    this.svideo = React.createRef();
    this.slink = React.createRef();
    this.stube = React.createRef();
    this.mediaForm = React.createRef();
    this.ccavenueForm = React.createRef();

    this.state = {
      searchCondition: "",
      locationData: [],
      listing: [],
      total: 0,
      page: 0,
      nextAvailable: true,
      perPage: 6,
      loading: false,
      allService: [],
      slogo: "",
      scompany: "",
      sindustry: "",
      scountry: [],
      sstate: [],
      scity: [],
      spostalcode: "",
      scemail: "",
      sdescription: "",
      SerLogo: "",
      updateService: false,
      servID: "",
      servDet: [],
      keyword: "",
      Options: [],
      states: "",
      stateOptions: [],
      cityOptions: [],
      city: "",
      isShowMedia: "",
      simage: "",
      sfile: "",
      svideo: "",
      slink: "",
      stube: "",

      stype: "0",
      isSubmitted: false,
      amt: "",
      duration: "",
      promoCode: "",
      referenceId: "",
      encRequest: "",
      access_code: "AVBZ71EF36BA67ZBAB",
      currency: "",

      formData: "",
      isLoaded: false,
      isButtonLoding: false,
      isPublishButtonLoding: false,

      isAddServiceLeftSideBar: false,
      openAddServiceLeftSideBar: false,

      isValidCode: false,
      isVerified: false,
      promoErrorMsg: "",
      
      isRenew: false,
      freeCount:0,
      isCreated: false,

      isccavenuePayment: false,
    };
  }

  serviceApi = async () => {
    if (this.state.nextAvailable) {
      await this.setState({
        loading: true,
        page: this.state.page + 1,
      });

      const serviceData = {
        page: this.state.page,
        records: this.state.perPage,
      };

      try {
        const services = await axios.post(
          `${process.env.REACT_APP_API_URL}getaddedservices`,
          serviceData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (services.status === 200 && services.data.status === true) {

          const freeCount = services.data.counts['count(id)'];

          this.setState({
            isLoaded: true,
            freeCount:freeCount,
          })

          if (services.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }

          let oldArray = this.state.listing;
          oldArray = oldArray.concat(services.data.datas);

          await this.setState({
            allService: oldArray,
            total: services.data.total
          });

          const allServices = [];

          this.state.allService.forEach((data) => {
            const location =
              data.city + ", " + data.states + ", " + data.country;
            allServices.push(location);
          });

          let unique = [...new Set(allServices)];

          await this.setState({
            locationData: unique,
          });

          await this.setState({
            listing: oldArray,
          });
        } else if (services.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else {
          this.setState({
            isLoaded: true,
          })
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      await this.setState({
        loading: false,
      });
    }
  };

  postService = async () => {
    
    if(this.state.freeCount >= 10) {
      this.setState({
        stype:'paid',
      })
    }

    let createServ;
    window.scrollTo(0,0);
    this.setState({
      isButtonLoding: true
    })

    if (this.state.updateService && !this.state.isRenew) {
      
      if (this.Updatevalidator.allValid() && this.Updatefilevalidator.allValid()) {
        
        const formData = new FormData(await this.ServicesElement.current);
        const mediaData = new FormData(await this.mediaForm.current);

        formData.append("sdescription", this.state.sdescription);
        formData.append("sduration", "0 days");
        formData.append("stax", "0");

        for (var value of mediaData.entries()) {
          formData.append(value[0], value[1]);
        }
        try {
          createServ = await axios.post(
            `${process.env.REACT_APP_API_URL}fpservices/service/${this.state.servID
            }`,
            formData,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
        } catch (err) {
          if (err?.response?.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != '') {
            toast.error(err?.response?.data?.error, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }

        this.Updatefilevalidator.hideMessages();
        this.Updatevalidator.hideMessages();
        this.validator.hideMessages();
        this.forceUpdate();

        await this.setState({
          openSoftwareServiceLeftSideBar: false,
        });

      } else {
        this.Updatevalidator.showMessages();
        this.Updatefilevalidator.showMessages();
        this.forceUpdate();
      }
    } else {
      if ((this.validator.allValid() && this.Servicevalidator.allValid() && this.filevalidator.allValid()) || (this.state.isRenew && this.Updatevalidator.allValid() && this.Updatefilevalidator.allValid())) {

        if(this.state.isRenew) {
          this.setState({
            amt:"",
            stype:'paid',
          })
        }

        const formData = new FormData(await this.ServicesElement.current);
        const mediaData = new FormData(await this.mediaForm.current);
        formData.append("sdescription", this.state.sdescription);

        for (var value of mediaData.entries()) {
          formData.append(value[0], value[1]);
        }

        const type = "sv";
        var CurrentDate = moment().unix();

        const userData = localStorage.getItem("userData")
        const userDetails = JSON.parse(userData)
        
        const userLoc = localStorage.getItem("userLocation");

        const userCountryDetails = JSON.parse(userLoc)

        var currency = userCountryDetails.currency;

        if (currency === "INR") {
          currency = "INR";
        } else if (currency === "EUR"){
          currency = "EUR";
        } else if (currency === "HKD"){
          currency = "HKD";
        } else if (currency === "JPY"){
          currency = "JPY";
        } else if (currency === "GBP"){
          currency = "GBP";
        } else if (currency === "AUD"){
          currency = "AUD";
        } else {
          currency = "USD";
        }


        await this.setState({
          currency: currency,
        })

        const uid = userDetails.userId;

        var referenceId = uid + "-" + type + "-" + CurrentDate;
        
        this.setState({
          isSubmitted: true,
          formData: formData,
          referenceId: referenceId,
        })

        this.filevalidator.hideMessages();
        this.Servicevalidator.hideMessages();
        this.validator.hideMessages();
        this.forceUpdate();


        await this.setState({
          openSoftwareServiceLeftSideBar: false,
        });
      } else {

        this.filevalidator.showMessages();
        this.validator.showMessages();
        this.Servicevalidator.showMessages();
        this.forceUpdate();
      }
    }

    if (typeof createServ !== "undefined") {
      if (createServ.status === 200 && createServ.data.status === true) {

        this.ServicesElement?.current?.reset();
        this.mediaForm?.current?.reset();

        this.setState({
          slogo: "",
          scompany: "",
          sindustry: "",
          scountry: [],
          sstate: [],
          scity: [],
          spostalcode: "",
          scemail: "",
          sdescription: "",
          SerLogo: "",
          simage: "",
          sfile: "",
          svideo: "",
          slink: "",
          stube: "",
          updateService: false,
        });

        await this.handleCondition(this.state.listing);

        toast.success(createServ.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        var msg = createServ.data.errors
          ? createServ.data.errors
          : createServ.data.message;
        toast.error(msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    this.setState({
      isButtonLoding: false
    })

    await this.setState({
      openAddServiceLeftSideBar: false,
    });
  };

  paymentPage = async (sid) => {
    if (!this.state.updateService) {
      const passData = {
        serId: sid,
        stype: "sv",
        sname: "service",
      };

      this.props.history.push({
        pathname: `/try-premium/service/${sid}`,
        state: passData,
      });
    }
  }

  editService = async (servId, country, state, type) => {

    if (type === "renew") {
      this.setState({
        isRenew:true,
      })
    } else {
      this.setState({
        isRenew:false,
      })
    }

    this.Servicevalidator.hideMessages();
    this.Updatefilevalidator.hideMessages();
    this.filevalidator.hideMessages();
    this.Updatevalidator.hideMessages();
    this.validator.hideMessages();
    this.forceUpdate();

    this.setState({
      updateService: true,
      slogo: "",
      simage: "",
      sfile: "",
      svideo: "",
      slink: "",
      stube: "",
    });

    if (typeof servId !== "undefined") {
      const updService =
        this.state.updateService === false ? true : this.state.updateService;

      this.setState({
        updateService: updService,
        servID: servId,
      });
    }

    try {
      // all states API call
      const allstates = await axios.get(
        `${process.env.REACT_APP_API_URL}state/${country}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (allstates.status === 200 && allstates.data.status === true) {
        const stateArray = [];

        allstates.data.datas.map((keys) =>
          stateArray.push({
            value: keys.name,
          })
        );

        this.setState({
          states: allstates.data.datas,
          stateOptions: stateArray,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    try {
      // all cities API call
      const allcity = await axios.get(
        `${process.env.REACT_APP_API_URL}cities/${state}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (allcity.status === 200 && allcity.data.status === true) {
        const cityArray = [];

        allcity.data.datas.map((keys) =>
          cityArray.push({
            value: keys.name,
          })
        );

        this.setState({
          city: allcity.data.datas,
          cityOptions: cityArray,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    try {
      const particularserviceDetail = await axios.get(
        `${process.env.REACT_APP_API_URL}service/view/${servId}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (
        particularserviceDetail.status === 200 &&
        particularserviceDetail.data.status === true
      ) {
        this.setState({
          servDet: particularserviceDetail.data.datas,
        });

        this.setState({
          scompany: this.state.servDet.company,
          sindustry: this.state.servDet.industry,
          scountry: {value: this.state.servDet.country, label: this.state.servDet.country}, //this.state.servDet.country,
          sstate: {value:this.state.servDet.states, label:this.state.servDet.states},
          scity: {value:this.state.servDet.city,label:this.state.servDet.city,},//this.state.servDet.city,
          spostalcode: this.state.servDet.pincode,
          scemail: this.state.servDet.email,
          sdescription: this.state.servDet.descrip,
          SerLogo: this.state.servDet.logo,
          amt: this.state.servDet.amount,
          slink: this.state.servDet.links,
          stube: this.state.servDet.utube,
        });
      } else {
        this.state = {
          slogo: "",
          scompany: "",
          sindustry: "",
          scountry: [],
          sstate: [],
          scity: [],
          spostalcode: "",
          scemail: "",
          sdescription: "",
          SerLogo: "",
          simage: "",
          sfile: "",
          svideo: "",
          slink: "",
          stube: "",
        };
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    await this.setState({
      openAddServiceLeftSideBar: true,
    });
  };

  componentDidMount = async () => {
    await this.handleResize();

    this.setState({
      loading: true,
    });

    try {
      const allCountries = await axios.get(
        `${process.env.REACT_APP_API_URL}countries`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (allCountries.status === 200 && allCountries.data.status === true) {
        const keywordArray = [];

        allCountries.data.datas.map((keys) =>
          keywordArray.push({
            value: keys.name,
          })
        );

        this.setState({
          keyword: allCountries.data.datas,
          Options: keywordArray,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    await this.serviceApi();

    window.addEventListener("scroll", this.listenToScroll);

    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isAddServiceLeftSideBar: true,
        openAddServiceLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isAddServiceLeftSideBar: false,
      });
    }
  };

  listenToScroll = async () => {
    if (this.state.loading === false) {
      const per = (document.documentElement.offsetHeight * 10) / 100;
      if (
        document.documentElement.offsetHeight - per <=
        document.documentElement.scrollTop + window.innerHeight
      ) {
        await this.serviceApi();
      }
    }
  };

  handleCondition = async (conditionData) => {
    await this.setState({
      searchCondition: conditionData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  handleSelected = async (e) => {
    await this.setState({
      stype: e.value?.replace(/^\s+/g, ''),
    });
  };

  handleCountry = async (e) => {
    await this.setState({
      scountry: e, //.value?.replace(/^\s+/g, ''),
      sstate:[],
      scity:[],
    });

    const allstates = await axios.get(
      `${process.env.REACT_APP_API_URL}state/${e.value?.replace(/^\s+/g, '')}`,
      {
        headers: {
          Auth: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (allstates.status === 200 && allstates.data.status === true) {
      const stateArray = [];

      allstates.data.datas.map((keys) =>
        stateArray.push({
          value: keys.name,
        })
      );

      this.setState({
        states: allstates.data.datas,
        stateOptions: stateArray,
      });
    }
  };

  handleStates = async (e) => {
    await this.setState({
      sstate: e, //.value?.replace(/^\s+/g, ''),
      scity:[],
    });

    try {
      const allcity = await axios.get(
        `${process.env.REACT_APP_API_URL}cities/${e.value?.replace(/^\s+/g, '')}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (allcity.status === 200 && allcity.data.status === true) {
        const cityArray = [];

        allcity.data.datas.map((keys) =>
          cityArray.push({
            value: keys.name,
          })
        );

        this.setState({
          cityOptions: cityArray,
        });
       
        if(cityArray == 0){
          cityArray.push(
            {value: this.state.sstate.value,}
          )
          await this.setState({
            cityOptions: cityArray,
          })
        }
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  
  handleCity = async (e) => {
    await this.setState({
      scity: e//.value?.replace(/^\s+/g, ''),
    });
  };
  
  handleInputChange = async (e) => {
    if (typeof e === "string") {
      this.setState({
        sdescription: (e.replace(/^\s+/g, '') === '<p>&nbsp;&nbsp;</p>') ? '' : e.replace(/^\s+/g, ''),
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
      });

      if (e.target.name === "slogo") {
        this.setState({
          SerLogo: "",
        });
      }

      if (e.target.name === "scountry") {
        try {
          const allstates = await axios.get(
            `${process.env.REACT_APP_API_URL}state/${e.target.value?.replace(/^\s+/g, '')}`,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );

          if (allstates.status === 200 && allstates.data.status === true) {
            const stateArray = [];

            allstates.data.datas.map((keys) =>
              stateArray.push({
                value: keys.name,
              })
            );

            this.setState({
              states: allstates.data.datas,
              stateOptions: stateArray,
            });
          }
        } catch (err) {
          if (err?.response?.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != '') {
            toast.error(err?.response?.data?.error, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      }

      if (e.target.name === "sstate") {
        try {
          const allcity = await axios.get(
            `${process.env.REACT_APP_API_URL}cities/${e.target.value?.replace(/^\s+/g, '')}`,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );

          if (allcity.status === 200 && allcity.data.status === true) {
            const cityArray = [];

            allcity.data.datas.map((keys) =>
              cityArray.push({
                value: keys.name,
              })
            );

            this.setState({
              city: allcity.data.datas,
              cityOptions: cityArray,
            });
          }
        } catch (err) {
          if (err?.response?.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != '') {
            toast.error(err?.response?.data?.error, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      }

      if (e.target.files) {
        var file = e.target.files[0];
        if (typeof file !== "undefined") {
          var reader = new FileReader();
          reader.onload = async function (event) {
            // The file's text will be printed here
            if (e.target.name === "slogo") {
              await this.setState({
                SerLogo: event.target.result,
              });
            }
          }.bind(this);

          reader.readAsDataURL(file);
        }
      }

      if (e.target.name === "duration") {

        var threeMonths;
        var sixMonths;
        var twelveMonths;

        var currency = this.state.currency;

        if (currency === "INR") {
          threeMonths = 6000;
          sixMonths = 9600;
          twelveMonths = 14400;
        }

        else if (currency === "USD") {
          threeMonths = 300;
          sixMonths = 480;
          twelveMonths = 720;
        }

        else if (currency === "EUR") {
          threeMonths = 300;
          sixMonths = 480;
          twelveMonths = 720;
        }

        else if (currency === "HKD") {
          threeMonths = 1500;
          sixMonths = 2400;
          twelveMonths = 3600;
        }

        else if (currency === "JPY") {
          threeMonths = 30000;
          sixMonths = 48000;
          twelveMonths = 72000;
        }

        else if (currency === "GBP") {
          threeMonths = 225;
          sixMonths = 360;
          twelveMonths = 540;
        }

        else if (currency == "AUD") {
          threeMonths = 300;
          sixMonths = 480;
          twelveMonths = 720;
        }

        else {
          threeMonths = 300;
          sixMonths = 480;
          twelveMonths = 720;
        }

        if (e.target.value?.replace(/^\s+/g, '') === "3") {
          await this.setState({
            amt: threeMonths, //parseFloat(4000 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else if (e.target.value?.replace(/^\s+/g, '') === "6") {
          await this.setState({
            amt: sixMonths, //parseFloat(6400 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else if (e.target.value?.replace(/^\s+/g, '') === "12") {
          await this.setState({
            amt: twelveMonths, //parseFloat(9600 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else {
          await this.setState({
            amt: "",
          });
        }

        this.setState({
          promoCode:"",
          isVerified:false,
          isValidCode: false,
        })
      }
    }
  };

  addService = async (oid, ostatus, curr, amt) => {

    if(!this.state.isccavenuePayment){
      this.setState({
      isPublishButtonLoding: true
      });
    }

    const formData = this.state.formData;

    
    if (this.state.stype === 'paid') {
      if (ostatus === 'COMPLETED') {
        ostatus = 'Success';
      }
    }

    formData.delete('sduration');
    formData.delete('spaid');
    formData.delete('scurrency');
    formData.delete('stax');
    formData.delete('sorderid');
    formData.delete('spromocode');

    formData.append('sduration', this.state.duration);
    formData.append('spaid', amt > 0 ? amt : 0);
    formData.append('scurrency', curr ? curr : "");

    if (curr === 'INR' || curr === 'IN'){
      formData.append('stax', "18");
    } else {
      formData.append('stax', "0");
    }
    
    formData.append('sorderid', oid ? oid : "");
    formData.append('spromocode', this.state.promoCode);

    var createProduct;
    if (!this.state.isRenew) {
      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/service`,
        formData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    } else {
      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/service/${this.state.servID
        }`,
        formData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    }

    if (createProduct.status === 200 && createProduct.data.status === true) {

      if (this.state.stype === 'paid') {

        this.setState({
          isCreated:false,
        })

        if (this.state.isRenew) {
          this.payment(oid, ostatus, curr, this.state.servID);
        } else {
          this.payment(oid, ostatus, curr, createProduct.data.datas);
        }
        

      } else {

        toast.success(createProduct.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      }

    if(!this.state.isccavenuePayment){
      this.setState({
        isCreated:true,
        slogo: "",
        scompany: "",
        sindustry: "",
        scountry: [],
        sstate: [],
        scity: [],
        spostalcode: "",
        scemail: "",
        sdescription: "",
        SerLogo: "",
        simage: "",
        sfile: "",
        svideo: "",
        slink: "",
        stube: "",
        isSubmitted: false,
        nextAvailable: true,
        page: 0,
        listing: [],
        pgimage: "",
        pgvideo: "",
        pgvideo: "",

        stype: "",
        amt: "",
        duration: "",
        promoCode: "",
        referenceId: "",
        encRequest: "",
        currency: "",
        formData: "",
        updateService: false,
        servDet:[],
      });

      await this.serviceApi();
    }
    } else {

      toast.error(createProduct.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    
    if(!this.state.isccavenuePayment){
      this.setState({
        isPublishButtonLoding: false
      });
    }
    
  }

  payment = async (oid, ostatus, curr, serID) => {

    const type = "sv";

    const data = {
      paymentType: "1",
      currency: curr,
      productType: type,
      orderStatus: ostatus,
      orderId: oid,
      serId: serID,
      promoCode: this.state.promoCode,
      renewType: this.state.isRenew ? "renew" : "new",
    };

    try {
      const payment = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/payment`,
        data,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (payment.status === 200 && payment.data.status === true) {
        
        this.setState({
          isCreated:true,
        })

        toast.success(payment.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      } else {

        toast.error(
          payment.data.errors ? payment.data.errors : payment.data.message,
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }

    } catch (err) {
      if (err.response.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  promoCodeAPI = async () => {

    if (!this.state.isVerified) {

    var data = {
      amount: this.state.amt,
      service: "sv",
      promocode: this.state.promoCode,
    };

    if (data.amount !== "") {
      this.durationValidator.hideMessages();
      this.forceUpdate();

      if (data.promocode !== "") {
        this.promoCodeValidator.hideMessages();

        try {
          const promoCode = await axios.post(
            `${process.env.REACT_APP_API_URL}fpservices/promocode`,
            data,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );

          if (promoCode.status === 200 && promoCode.data.status === true) {
            await this.setState({
              amt: promoCode.data.datas,
              isVerified:true,
              isValidCode: false,
            });

            toast.success(promoCode.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {

            this.setState({
              isVerified:false,
              isValidCode: true,
              promoErrorMsg: promoCode.data.errors ? promoCode.data.errors : promoCode.data.message,
            })
            // toast.error(
            //   promoCode.data.errors
            //     ? promoCode.data.errors
            //     : promoCode.data.message,
            //   {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //   }
            // );
          }
        } catch (err) {
          if (err.response.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != '') {
            // toast.error(err?.response?.data?.error, {
            //   position: "top-right",
            //   autoClose: 4000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          } else {
            // toast.error(err.message, {
            //   position: "top-right",
            //   autoClose: 4000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          }
        }
      } else {
        this.promoCodeValidator.showMessages();
        this.forceUpdate();
      }
    } else {
      this.durationValidator.showMessages();
      this.forceUpdate();
    }
  } else {
    toast.error(
      "Promocode already applied",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
  };

  selectpodocu = async () => {
    await this.setState({
      isShowMedia: "sfile",
    });
  };

  selectpoimage = async () => {
    await this.setState({
      isShowMedia: "simage",
    });
  };

  selectVideo = async () => {
    await this.setState({
      isShowMedia: "svideo",
    });
  };

  selectLink = async () => {
    await this.setState({
      isShowMedia: "slink",
    });
    this.slink.current.focus();
  };

  selectYoutube = async () => {
    await this.setState({
      isShowMedia: "stube",
    });
    this.stube.current.focus();
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.searchCondition !== this.state.searchCondition) {
      await this.serviceApi();

      await this.setState({
        loading: false,
      });
    }
  }

  deleteFile = async (file = '', folder = '') => {
    try {
      let fullpath = file;
      file = file.split('/').pop();
      const resDeletePostFile = await axios.delete(
        `${process.env.REACT_APP_API_URL}fpservices/file/${this.state.servDet?.id}/service/${folder}/${btoa(file)}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
      if (resDeletePostFile.status === 200 && resDeletePostFile.data.status === true) {
        document.getElementById(fullpath).style.display = 'none'
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token")
        window.location.href = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '' || err?.response?.data?.message != '') {
        toast.error((err?.response?.data?.error === '') ? err?.response?.data?.message : err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  ccavBtn = async () => {
    if (this.state.duration === "") {
      toast.error(`Duration should not be empty`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false
    } else {
      const userName = localStorage.getItem("userName")
    
      const orderParams = {
        order_id: this.state.referenceId,
        currency: this.state.currency,
        amount: this.state.amt,
        redirect_url: encodeURIComponent(
          `https://pexit.net/node_api/api/ccavenue`,
        ),
        billing_name: userName,
      };
      
      const encryptedOrderData = ccav.getEncryptedOrder(orderParams);
    
      await this.setState({
        encRequest: encryptedOrderData,
        isccavenuePayment: true
      });

      await this.addService(this.state.referenceId, 'awaited', this.state.currency, this.state.amt)
      // if(this.state.isCreated) {
        this.ccavenueForm.current.submit();
      // }
    }
  }

  showAddServiceLeftSideBar = async () => {
    if (this.state.openAddServiceLeftSideBar) {
      await this.setState({
        openAddServiceLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openAddServiceLeftSideBar: true,
      });
    }
  };

  render() {
    if (!this.state.isSubmitted) {
      return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
            toastClassName="dark-toast"
          />

          <section className="product-section">
            {(this.state.isAddServiceLeftSideBar || this.state.openAddServiceLeftSideBar) &&

              <div className="left-sidebar">
                <Link to={"/"}>
                  <div className="chat-arrow">
                    <img src={chatArrow} alt="chat-arrow" />
                  </div>
                </Link>

                <div className="sidebar-container">
                  <div className="header-content">
                    <h2 className="header">Post your Services</h2>
                  </div>

                  <div className="content-container">
                    <form
                      method="post"
                      ref={this.ServicesElement}
                      encType="multipart/form-data"
                    >
                      <div>

                        {!this.state.updateService ?

                          <>
                            <div className="sidebar-content">
                              <div className="header-content">
                                <h3 className="header">Offer Type</h3>
                              </div>
                              <div className="caret-content">
                               

                              {this.state.freeCount >= 10 ? 
                                <Select 
                                  className="caret-content-select"
                                  placeholder="Select Offer type"
                                  options={[
                                    {value:"paid",label:"Paid "}
                                  ]}
                                  name="stype"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  }}
                                  // value={this.state.stype}
                                  onChange={this.handleSelected}
                                  menuPosition={"fixed"}
                                /> :

                                <Select 
                                  className="caret-content-select"
                                  placeholder="Select Offer type"
                                  options={[
                                    {value:"free",label:"Free "},
                                    {value:"paid",label:"Paid "}
                                  ]}
                                  name="stype"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  }}
                                  // value={this.state.stype}
                                  onChange={this.handleSelected}
                                  menuPosition={"fixed"}
                                />
                                }
                              </div>

                              {this.Servicevalidator.message(
                                "Offer type",
                                this.state.stype,
                                "serType"
                              )}

                            </div>
                          </> :
                          <input
                            type="hidden"
                            name="spaid"
                            className="input-field field"
                            value={this.state.amt}
                          />
                        }

                        <div className="sidebar-content">
                          <div className="sidebar-content">
                            <div className="header-content">
                              <h3 className="header">
                                Logo<span className="requiredField">*</span>
                              </h3>
                            </div>

                            <input
                              type="file"
                              className="input-field field"
                              name="slogo"
                              ref={this.slogo}
                              accept="image/*"
                              onChange={(e) => {
                                this.setState({
                                  new_slogo: e.target
                                })
                                this.filevalidator.allValid()
                              }}
                            // value={this.state.slogo}
                            />

                            {this.filevalidator.message(
                              "logo",
                              this.state?.new_slogo,
                              "required|Logo"
                            )}

                            {this.Updatefilevalidator.message(
                              "logo",
                              this.state?.new_slogo,
                              "Logo"
                            )}
                          
                          </div>
                        </div>

                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">
                              Company<span className="requiredField">*</span>
                            </h3>
                          </div>

                          <input
                            type="text"
                            className="input-field field"
                            placeholder="Company"
                            name="scompany"
                            onChange={this.handleInputChange}
                            value={this.state.scompany}
                          />

                          {this.validator.message(
                            "company",
                            this.state.scompany,
                            "required|string"
                          )}

                          {this.Updatevalidator.message(
                            "company",
                            this.state.scompany,
                            "required|string"
                          )}
                        </div>

                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">
                              Industry<span className="requiredField">*</span>
                            </h3>
                          </div>

                          <input
                            type="text"
                            className="input-field field"
                            placeholder="Industry"
                            name="sindustry"
                            ref={this.sindustry}
                            onChange={this.handleInputChange}
                            value={this.state.sindustry}
                          />

                          {this.validator.message(
                            "industry",
                            this.state.sindustry,
                            "required|string"
                          )}

                          {this.Updatevalidator.message(
                            "industry",
                            this.state.sindustry,
                            "required|string"
                          )}
                        </div>

                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">
                              Country<span className="requiredField">*</span>
                            </h3>
                          </div>
                          <div className="caret-content">
                           
                            <Select 
                            placeholder="Select Country"
                            options={
                              this.state.Options.map((option) => (
                                {value:option.value,label:option.value}
                              ))
                            }
                            name="scountry"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            value={this.state.scountry}
                            onChange={this.handleCountry}
                            menuPosition={"fixed"}
                            />

                          </div>
                          {this.validator.message(
                            "country",
                            this.state.scountry,
                            "required"
                          )}

                          {this.Updatevalidator.message(
                            "country",
                            this.state.scountry,
                            "required"
                          )}
                        </div>

                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">
                              State<span className="requiredField">*</span>
                            </h3>
                          </div>
                          <div className="caret-content">
                           
                            <Select 
                            placeholder="Select State"
                            options={
                              this.state.stateOptions.map((option) => (
                                {value:option.value,label:option.value}
                              ))
                            }
                            name="sstate"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            value={this.state.sstate}
                            onChange={this.handleStates}
                            menuPosition={"fixed"}
                            />
                          </div>

                          {this.validator.message(
                            "state",
                            this.state.sstate,
                            "required"
                          )}

                          {this.Updatevalidator.message(
                            "state",
                            this.state.sstate,
                            "required"
                          )}
                        </div>

                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">
                              City<span className="requiredField">*</span>
                            </h3>
                          </div>
                          <div className="caret-content">
                            
                            <Select 
                            placeholder="Select City"
                            options={
                              this.state.cityOptions.map((option) => (
                                {value:option.value,label:option.value}
                              ))
                            }
                            name="scity"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            value={this.state.scity}
                            onChange={this.handleCity}
                            menuPosition={"fixed"}
                            />
                          </div>

                          {this.validator.message(
                            "city",
                            this.state.scity,
                            "required"
                          )}

                          {this.Updatevalidator.message(
                            "city",
                            this.state.scity,
                            "required"
                          )}
                        </div>

                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">Postal Code<span className="requiredField">*</span></h3>
                          </div>

                          <input
                            type="text"
                            className="input-field field"
                            placeholder="Postal Code"
                            name="spostalcode"
                            onChange={this.handleInputChange}
                            value={this.state.spostalcode}
                          />

                          {this.validator.message(
                            "postalcode",
                            this.state.spostalcode,
                            "required|string"
                          )}

                          {this.Updatevalidator.message(
                            "postalcode",
                            this.state.spostalcode,
                            "required|string"
                          )}
                        </div>

                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">
                              Contact Email<span className="requiredField">*</span>
                            </h3>
                          </div>

                          <input
                            type="text"
                            className="input-field field"
                            placeholder="Contact Email"
                            name="scemail"
                            onChange={this.handleInputChange}
                            value={this.state.scemail}
                          />

                          {this.validator.message(
                            "email",
                            this.state.scemail,
                            "required|email"
                          )}

                          {this.Updatevalidator.message(
                            "email",
                            this.state.scemail,
                            "required|email"
                          )}
                        </div>

                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">
                              Description<span className="requiredField">*</span>
                            </h3>
                          </div>

                          <Editor
                            apiKey="textarea"
                            name="sdescription"
                            value={this.state.sdescription}
                            init={{
                              height: 200,
                              menubar: false,
                            }}
                            onEditorChange={this.handleInputChange}
                          />

                          {this.validator.message(
                            "description",
                            this.state.sdescription,
                            "required|string"
                          )}

                          {this.Updatevalidator.message(
                            "description",
                            this.state.sdescription,
                            "required|string"
                          )}
                        </div>

                        <div className="sidebar-content">
                          <div className="sidebar-button">
                          {!this.state.updateService ?
                            <button
                              type="button"
                              className="reset-button"
                              onClick={this.postService}
                              disabled={this.state.isButtonLoding}
                            >
                              {this.state.isButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Submit'}
                            </button>
                            :
                            <button
                              type="button"
                              className="reset-button"
                              onClick={this.postService}
                              disabled={this.state.isButtonLoding}
                            >
                              {this.state.isButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Update'}
                            </button>
                            }
                          </div>
                          
                        </div>
                      </div>
                    </form>
                    <div>
                      <div className="sidebar-content">
                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header attachText">Attach:</h3>
                            <div className="post-pannel attachIconsDiv">
                              <div className="post-pannel-content attachIcons">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={Files}
                                    alt="Files"
                                    title="file"
                                    onClick={this.selectpodocu}
                                  />
                                  <span className="tooltiptext">File</span>
                                </div>
                              </div>

                              <div className="post-pannel-content attachIcons">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={CameraIcon}
                                    alt="CameraIcon"
                                    className="img-big"
                                    onClick={this.selectpoimage}
                                  />
                                  <span className="tooltiptext">Photo</span>
                                </div>
                              </div>

                              <div className="post-pannel-content attachIcons">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={VideoIcon}
                                    alt="VideoIcon"
                                    onClick={this.selectVideo}
                                  />
                                  <span className="tooltiptext">Video</span>
                                </div>
                              </div>

                              <div className="post-pannel-content attachIcons">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={AddLink}
                                    alt="AddLink"
                                    onClick={this.selectLink}
                                  />
                                  <span className="tooltiptext">Link</span>
                                </div>
                              </div>

                              <div className="post-pannel-content attachIcons">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={Youtube}
                                    alt="Youtube"
                                    className="img-big"
                                    onClick={this.selectYoutube}
                                  />
                                  <span className="tooltiptext">Youtube</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <form
                            method="post"
                            ref={this.mediaForm}
                            encType="multipart/form-data"
                          >
                            <div
                              className={
                                this.state.isShowMedia === "sfile" ? "" : "d-none"
                              }
                            >
                              <input
                                type="file"
                                name="sfile"
                                className="input-field field"
                                ref={this.sfile}
                                onChange={this.handleInputChange}
                                accept="audio/*,video/*,image/*,.doc,.docx,.pdf,.ppt,.pptx"
                                value={this.state.sfile}
                              />

                              <div className="d-flex d_preview_images">
                                {
                                  (Array.isArray(this.state.servDet?.files)) ? this.state.servDet?.files.map((doc, key) => {
                                    return <div className="image-area" key={key} id={doc} style={{ width: "20%", marginTop: "10%" }} >
                                      <img src={process.env.PUBLIC_URL + '/images/docu.png'} target={'_blank'} alt="" onClick={(e) => {
                                        e.preventDefault()
                                        saveAs(doc)
                                      }} />
                                      <a className="remove-image" href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.deleteFile(doc, 'files')
                                      }}>&#215;</a>
                                    </div>
                                  }) : ""
                                }
                              </div>
                            </div>

                            <div
                              className={
                                this.state.isShowMedia === "simage" ? "" : "d-none"
                              }
                            >
                              <input
                                type="file"
                                className="input-field field"
                                name="simage"
                                ref={this.simage}
                                onChange={(e) => {
                                  this.setState({
                                    new_simage: e.target
                                  })
                                  this.filevalidator.allValid()
                                }}
                                accept="image/*"
                              // value={this.state.simage}
                              />

                              <div className="d-flex d_preview_images">
                                {
                                  (Array.isArray(this.state.servDet?.photos)) ? this.state.servDet?.photos.map((doc, key) => {
                                    return <div className="image-area" key={key} id={doc} style={{ width: "20%", marginTop: "10%" }} >
                                      <img src={doc} target={'_blank'} alt="" onClick={(e) => {
                                        e.preventDefault()
                                        saveAs(doc)
                                      }} />
                                      <a className="remove-image" href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.deleteFile(doc, 'photos')
                                      }}>&#215;</a>
                                    </div>
                                  }) : ""
                                }
                              </div>

                            </div>

                            <div
                              className={
                                this.state.isShowMedia === "svideo" ? "" : "d-none"
                              }
                            >
                              <input
                                type="file"
                                className="input-field field"
                                name="svideo"
                                ref={this.svideo}
                                onChange={(e) => {
                                  this.setState({
                                    new_svideo: e.target
                                  })
                                  this.filevalidator.allValid()
                                }}
                                accept="video/*"
                              // value={this.state.svideo}
                              />

                              <div className="d-flex d_preview_images">
                                {
                                  (Array.isArray(this.state.servDet?.videos)) ? this.state.servDet?.videos.map((doc, key) => {
                                    return <div className="image-area" key={key} id={doc} style={{ width: "20%", marginTop: "10%" }} >
                                      <img src={process.env.PUBLIC_URL + '/images/film.png'} target={'_blank'} alt="" onClick={(e) => {
                                        e.preventDefault()
                                        saveAs(doc)
                                      }} />
                                      <a className="remove-image" href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.deleteFile(doc, 'videos')
                                      }}>&#215;</a>
                                    </div>
                                  }) : ""
                                }
                              </div>

                            </div>

                            <div
                              className={
                                this.state.isShowMedia === "slink" ? "" : "d-none"
                              }
                            >
                              <input
                                type="text"
                                name="slink"
                                className="input-field field"
                                ref={this.slink}
                                onChange={this.handleInputChange}
                                value={this.state?.slink}
                              />

                              <label className="labelText">
                                You can add multiple link separated by comma(,)
                              </label>
                            </div>

                            <div
                              className={
                                this.state.isShowMedia === "stube" ? "" : "d-none"
                              }
                            >
                              <input
                                type="text"
                                className="input-field field"
                                name="stube"
                                ref={this.stube}
                                onChange={this.handleInputChange}
                                value={this.state.stube}
                              />

                              <label className="labelText">
                                You can add multiple youtube link separated by comma(,)
                              </label>
                            </div>

                            {this.filevalidator.message(
                              "Photo",
                              this.state?.new_simage,
                              "Photos"
                            )}
                            {this.Updatefilevalidator.message(
                              "Photo",
                              this.state?.new_simage,
                              "Photos"
                            )}
                            {this.filevalidator.message(
                              "Video",
                              this.state?.new_svideo,
                              "VideoFiles"
                            )}
                            {this.Updatefilevalidator.message(
                              "Video",
                              this.state?.new_svideo,
                              "VideoFiles"
                            )}
                            {this.filevalidator.message(
                              "Link",
                              this.state?.slink,
                              "multipleLinks"
                            )}
                            {this.Updatefilevalidator.message(
                              "Link",
                              this.state?.slink,
                              "multipleLinks"
                            )}
                            {this.filevalidator.message(
                              "Youtube Link",
                              this.state?.stube,
                              "multipleYoutubeLinks"
                            )}
                            {this.Updatefilevalidator.message(
                              "Youtube Link",
                              this.state?.stube,
                              "multipleYoutubeLinks"
                            )}


                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="product-container">
              <div className="product-box-container">
                <div className="product-boxes">
                  {(!this.state.openAddServiceLeftSideBar && !this.state.isAddServiceLeftSideBar) &&
                    <div className="AddService-hamburger-menu" id="mycircle-hamburger">
                      <i class="fa fa-bars" aria-hidden="true" onClick={this.showAddServiceLeftSideBar}></i>
                    </div>
                  }
                  {(this.state.openAddServiceLeftSideBar && !this.state.isAddServiceLeftSideBar) &&
                    <div className="AddService-hamburger-menu" id="mycircle-hamburger">
                      <i class="fa fa-times" aria-hidden="true" onClick={this.showAddServiceLeftSideBar}></i>
                    </div>
                  }
                  <div className="product-header">
                    <h1 className="header">Active / Expired Services</h1>
                    {this.state.isLoaded &&
                      <h2>Total {this.state.total}</h2>
                    }
                  </div>

                  {this.state.isLoaded &&
                    <div className="boxes-container">
                      {this.state.listing.length > 0 ? (
                        this.state.listing.map((lists, key) => (
                          <div className="product-boxes-content" key={key}>
                            <Link to={`/service-detail/${lists.id}`}>
                              <div className="product-image">
                                <img src={lists.logo} alt="" />
                              </div>
                            </Link>

                            <div className="product-info">
                              <div className="product-name">
                                <h2 className="header">{lists.company}</h2>
                              </div>
                              <div className="product-place">
                                <h2 className="header grey-header">
                                  {lists.industry}
                                </h2>
                              </div>
                              <div className="product-address">
                                <h2 className="header grey-header">
                                  {lists.city +
                                    ", " +
                                    lists.states +
                                    ", " +
                                    lists.country}
                                </h2>
                              </div>
                              <div className="product-place">
                                <h2 className="header grey-header">
                                  Status : {lists.state === 0 && lists.amount !== 0 ? "Expired" : "Active"}
                                </h2>
                              </div>

                            </div>

                            {(lists.state === 0 && lists.amount !== 0) ? (
                              <div className="product-edit">
                                <button
                                  type="button"
                                  className="product-email-button renewPro"
                                  onClick={() =>
                                    this.editService(
                                      lists.id,
                                      lists.country,
                                      lists.states,
                                      'renew'
                                    )
                                  }
                                >
                                  Renew
                                </button>
                              </div>
                              ): (lists.state === 1) ? (
                              <div className="product-edit">
                                <button
                                  type="button"
                                  className="product-email-button"
                                  onClick={() =>
                                    this.editService(
                                      lists.id,
                                      lists.country,
                                      lists.states,
                                      'edit'
                                    )
                                  }
                                >
                                  <img src={EditIcon} alt="EditIcon" />
                                </button>
                              </div>
                              ) : ""
                            }

                          </div>
                        ))
                      ) : (
                        <h2>You haven't added your service</h2>
                      )}
                    </div>
                  }
                </div>
                {this.state.loading && <Loader />}
              </div>
            </div>
          </section>

          <ChatButton />
        </>
      );
    } else {
      return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
            toastClassName="dark-toast"
          />

          <section className="product-section">
            <div className="product-container softwares-container">
              <div className="premium-container">
                <div className="parentDiv">
                  <label>Price</label>
                  <span className="labinsep">:</span>

                  {(this.state.stype === 'free') ? <span id="pays">Free</span> :

                    <>
                      <span>

                        {this.state.currency} <span id="pays">{this.state.amt}</span>

                      </span>
                    </>
                  }
                </div>

                <div className="parentDiv">
                  <label>Duration</label>
                  <span className="labinsep">:</span>
                  <select
                    id="sftdur"
                    name="duration"
                    value={this.state.duration}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Select Duration</option>

                    {(this.state.stype === 'free') ?

                      <option value="12 months">12 Months</option>

                      :
                      <>
                        <option value="3">3 Months</option>
                        <option value="6">6 Months</option>
                        <option value="12">12 Months</option>
                      </>

                    }
                  </select>

                  {this.durationValidator.message(
                    "Please Select Duration",
                    this.state.duration,
                    "required|string"
                  )}
                </div>

                {(this.state.stype === 'paid') ?
                  <>
                    <div className="parentDiv">
                      <label>Have a Promo Code? : </label>
                      <input
                        type="text"
                        id="prmcde"
                        name="promoCode"
                        value={this.state.promoCode}
                        onChange={this.handleInputChange}
                      />
                      <a id="apprm" onClick={this.promoCodeAPI} href="javascript:void(0);">
                        Apply
                      </a>

                      {this.state.promoCode !== "" && this.state.isValidCode ?
                        <div class="srv-validation-message"> {this.state.promoErrorMsg}</div>
                        : ""}

                      {this.promoCodeValidator.message(
                        "Please Enter Promocode",
                        this.state.promoCode,
                        "required|string"
                      )}

                      {this.InvalidValidator.message(
                        "Invalid Promocode",
                        this.state.promoCode,
                        "required|string"
                      )}
                    </div>
                  </>
                  : ""}

                <div>
                  {(this.state.stype === 'free') ?
                    <button type="submit" className="checkoutBtn ccavaenueBtn" onClick={() => this.addService()}>
                       {this.state.isPublishButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Publish'}
                    </button>

                    :
                    <>
                      {(this.state.currency !== "INR") ?
                      <>
                        <PayPalButtons
                          style={{ layout: "horizontal", color:"black", label:"checkout", tagline: false }}
                          className="paypalBtn"
                          createOrder={(data, actions) => {
                            return actions.order.create({
                              intent: "CAPTURE",
                              purchase_units: [
                                {
                                  reference_id: this.state.referenceId,
                                  amount: {
                                    value: this.state.amt,
                                    currency_code: this.state.currency,
                                  },
                                },
                              ],
                            });
                          }}
                          onClick={() => {
                            if (this.state.duration === "") {
                              toast.error(`Duration should not be empty`, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                              return false
                            }
                          }}
                          onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {

                              this.setState({
                                loading: true,
                                listing: [],
                                isSubmitted: false,
                              });

                              this.addService(
                                this.state.referenceId,
                                details.status,
                                details.purchase_units[0].amount.currency_code,
                                details.purchase_units[0].amount.value,
                              )

                            });
                          }}
                          onCancel={(data) => {

                            // Show a cancel page, or return to cart
                            toast.error(`Transaction cancelled`, {
                              position: "top-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }}

                          onError={(err) => {
                            if (this.state.currency === "INR") {
                              toast.error(`Paypal won't accept ${this.state.currency} please change the currency`, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                            }
                          }
                          }
                        />
                      </> :
                      <>
                      {/* CC Avenue START */}
                      <form ref={this.ccavenueForm} method="post" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"> 
                        
                        <input type="hidden" name="encRequest" value={this.state.encRequest} />
                        <input type="hidden" name="access_code" value={this.state.access_code} />
                        
                      </form>
                        
                        <button type="button" onClick={() => this.ccavBtn()} className="checkoutBtn ccavaenueBtn" >
                          Checkout
                        </button>
                     
                      {/* CC Avenue END */}

                      </>
                      }
                    </>
                  }
                </div>

                {(this.state.stype === 'paid') ?
                  <>
                    <div id="chcknte">
                      <p>
                        <b>Note: </b>
                      </p>
                      <p>
                        Please note down the reference number{" "}
                        <b>{this.state.referenceId}</b> for this transaction.
                      </p>
                      <p>
                        If the payment process is unsuccessful, then check the status
                        of the payment through "Transaction History" available under
                        "Paid Services" menu. If the status field corresponding to
                        your transaction reference number is "Null" or "Empty", then
                        retry with checkout again.
                      </p>
                      <p>
                        <span className="noteHeading">
                          Price shown above is exclusive of applicable Taxes and
                          Levies.{" "}
                        </span>
                      </p>
                    </div>
                  </> : ""}
              </div>
            </div>
          </section>
        </>
      );
    }
  }
}

export default withRouter(AddServicesPage);
