import "../../postPage/Post-page.css"
import "../../../components/modals/Popup.css";
import React, { Component } from 'react'
import axios from "axios";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import dataPending from "../../../../assets/data-pending.png"
import editIcon from "../../../../assets/edit-icon.png"
import service from "../../../../assets/free&paid.png"
import group from "../../../../assets/group.png"
import inviteIcon from "../../../../assets/invite-icon.png"
import job from "../../../../assets/job.png"
import newPost from "../../../../assets/new-post.png"
import product from "../../../../assets/product.png"
import profileIcon from "../../../../assets/profile-icon.png"
import software from "../../../../assets/software.png"

export class PostLeftSidebar extends Component {
    constructor(props) {
        super(props)

        //dynamic validation for title field and content field
        this.validator = new SimpleReactValidator({
            messages: {
                required: "The :attribute required.",
                alpha: "The :attribute must be string.",
            },
        });

        this.emailvalidator = new SimpleReactValidator({
            validators: {
                multipleEmail: {  // name the rule
                  message: 'The :attribute must be a valid email address',
                  rule: (val, params, validator) => {
                    
                    // var reg = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
                    var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                    var emails = val.split(',');
                    let ine = [];
                    for (let index = 0; index < emails.length; index++) {
                        let e = emails[index];
                        e = e.replace(/^\s+/g, '');
                        if(validator.helpers.testRegex(e, reg) === true){
                          
                        } else {
                            ine.push(e)
                        }
                    }

                    if(ine.length === 0){
                        return true
                    } else {
                        return false
                    }
                  },
                  messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                  required: true  // optional
                }
              },
            messages: {
                required: "The :attribute is required.",
                alpha: "The :attribute must be string.",
            },
        });

        this.state = {
            pendinglists: [],
            listlength: 0,
            listing: {},
            profileImage: "",
            userName: "",
            userTitle: "",

            profileCount: false,
            allProfileCounts: [],
            countLength: 0,

            isIndivisual: false,
            viewPostTo: this.props?.viewPostTo,
            connections: this.props?.individualUser,
            posts: [],
            loggedUserId: "",
            filterData: "",
            loading: false,
            groups: [],
            userID:0,

            inviteFriends: false,
            supplierRecipient: "",
            senderName: "",
            senderEmail: "",
            subject: "Invite to Join PEXit",
            message: `Hi,
            <br><br>
            PEXit integrates the best features of Professional Media and e-Commerce Facilitator. It helps to communicate, share, post, search, manage teams and projects, buy and sell goods, services and software. In effect, PEXit, the Professional EXchange for industries and technologies, caters to the requirements of professionals, entrepreneurs, groups, retailers, suppliers, manufacturers, industrial plants, corporations and governmental agencies. Even students, teachers and communities can use PEXit to carry out assignments and projects.
            <br><br>
            You can download PEXit using the link https://play.google.com/store/apps/details?id=com.recrosoft.pexit
            <br><br>
            You can also access PEXit using the link https://www.pexit.net from any browser on any device.
            <br><br>
            Regards,
            
            `,
            supplierId: "",
        }
    }


    // function for dynamic select box value
    handleChange = async (e) => {

        await this.setState({
            viewPostTo: e.target.value?.replace(/^\s+/g, ''),
        });

        let isIndivisual = this.state.isIndivisual;

        if (e.target.value?.replace(/^\s+/g, '') === "individuals") {
            isIndivisual = true;
        } else {
            isIndivisual = false;
        }

        await this.setState({
            isIndivisual: isIndivisual,
        });

        if (this.state.isIndivisual === false) {
            await this.props.filterPosts(this.state.viewPostTo)
        }
    };

    handleIndivisualChange = async (e) => {

        await this.setState({
            viewPostTo: e.target.value?.replace(/^\s+/g, ''),
            isIndivisual: true
        });
        
        await this.props.filterPosts(this.state.viewPostTo)

    }

    individualApi = async () => {
        try {
            const individual = await axios.get(
                `${process.env.REACT_APP_API_URL}individual`,
                {
                    headers: {
                        Auth: `${localStorage.getItem("token")}`,
                    },
                }
            )

            if (individual.status === 200 && individual.data.status === true) {
                this.setState({
                    connections: individual.data.datas,
                });
            }
        } catch (err) {
            if (err?.response?.status === 403) {
                localStorage.removeItem("token")
                window.location = process.env.PUBLIC_URL
            } else if(err?.response?.data?.error != '' ){
                toast.error(err?.response?.data?.error, {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else {
                toast.error(err.message, {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
        }
    }

    filterPosts = async (allDatas) => {
        await this.setState({
            filterData: allDatas,
            posts: [],
        });
    }

    handleInputChange = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
        });
        await this.filterPosts(this.state.posts)
    };



    componentDidMount = async () => {

        try {
            const pending = await axios.get(
                `${process.env.REACT_APP_API_URL}pendings-web`,
                {
                    headers: {
                        Auth: `${localStorage.getItem("token")}`,
                    },
                }
            );

            if (pending.status === 200 && pending.data.status === true) {
                this.setState({
                    pendinglists: pending.data.datas,
                    listlength: pending.data.datas.length,
                });

            } else if (pending.status === 403) {
                localStorage.removeItem("token")
                window.location = process.env.PUBLIC_URL
            }
        } catch (err) {
            if (err?.response?.status === 403) {
                localStorage.removeItem("token")
                window.location = process.env.PUBLIC_URL
            } else if(err?.response?.data?.error != '' ){
                toast.error(err?.response?.data?.error, {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else {
                toast.error(err.message, {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
        }

        const userData = localStorage.getItem("userData")
        const userName = localStorage.getItem("userName")

        const userDetails = JSON.parse(userData)

        this.setState({
            userName: userName,
            userTitle: userDetails.profileTitle,
            viewPostTo: this.props?.viewPostTo,
            userID:userDetails.userId,
        })

        // individual
        await this.individualApi()

        await this.getProfileCount()

        await this.ProfileApi()

        await this.groupListing()

    }

    //group list
    groupListing = async () => {
        
        const groupData = {
            "page": 1,
            "records": 100
        }

        try {
            const group = await axios.post(
                `${process.env.REACT_APP_API_URL}group/list/all`,
                groupData,
                {
                    headers: {
                        Auth: `${localStorage.getItem("token")}`,
                    },
                }
            );

            if (group.status === 200 && group.data.status === true) {
                const userGroup = group.data.datas.filter((v) => {
                    return v.uid === this.state.userID
                })
                this.setState({
                    groups: userGroup
                })
                
            }
        } catch (err) {
            if (err?.response?.status === 403) {
                localStorage.removeItem("token")
                window.location = process.env.PUBLIC_URL
            } else if(err?.response?.data?.error != '' ){
                toast.error(err?.response?.data?.error, {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else {
                toast.error(err.message, {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
        }
    }

    // profile count
    showCountModal = async () => {
        const profileCount =
            this.state.profileCount === false ? true : false;
        await this.setState({
            profileCount: profileCount,
        });

        await this.getProfileCount()
    }

    hideCountModal = async () => {
        await this.setState({
            profileCount: false,
        });
    }

    // get like api
    getProfileCount = async () => {

        try {
            const profileCount = await axios.get(
                `${process.env.REACT_APP_API_URL}profile/counts/0`,
                {
                    headers: {
                        Auth: `${localStorage.getItem('token')}`,
                    },
                }
            );


            if (profileCount.status === 200) {
                await this.setState({
                    allProfileCounts: profileCount.data.datas ? profileCount.data.datas : [],
                    countLength: profileCount.data.datas.length,
                });
            }
        } catch (err) {
            if (err?.response?.status === 403) {
                localStorage.removeItem("token")
                window.location = process.env.PUBLIC_URL
            } else if(err?.response?.data?.error != '' ){
                toast.error(err?.response?.data?.error, {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else {
                toast.error(err.message, {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
        }

    }

    // invite friends
    showInviteModal = async () => {
        const inviteFriends =
            this.state.inviteFriends === false ? true : false;

        const userData = localStorage.getItem("userData")

        const userDetails = JSON.parse(userData)

        await this.setState({
            inviteFriends: inviteFriends,
            senderName: userDetails.userName,
            senderEmail: userDetails.userEmail,
        });
    }

    hideInviteModal = async () => {

        this.validator.hideMessages();
        this.emailvalidator.hideMessages();
        
        this.forceUpdate();

        await this.setState({
            inviteFriends: false,
        })
    }


    inviteFriendsApi = async () => {
        if (this.validator.allValid() && this.emailvalidator.allValid()) {

            const supplierData = {
                uname: this.state.senderName,
                uemail: this.state.senderEmail,
                subject: this.state.subject,
                friendsEmail: this.state.supplierRecipient,
                mailContent: this.state.message,
                //sid: this.props.supplierId,
            };

            try {
                const formres = await axios.post(
                    `${process.env.REACT_APP_API_URL}invitefriends`,
                    supplierData,
                    {
                        headers: {
                            Auth: `${localStorage.getItem("token")}`
                        },
                    }
                );

                if (formres.status === 200) {
                    if (formres.data.status === true){
                        toast.success(formres.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error(formres.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                } else {
                    toast.error(formres.data.errors, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } catch (err) {
                if (err?.response?.status === 403) {
                    localStorage.removeItem("token")
                    window.location = process.env.PUBLIC_URL
                } else if(err?.response?.data?.error != '' ){
                    toast.error(err?.response?.data?.error, {
                      position: "top-right",
                      autoClose: 4000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else {
                    toast.error(err.message, {
                      position: "top-right",
                      autoClose: 4000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
            }

            this.hideInviteModal()

            this.setState({
                supplierRecipient: "",
            })

            this.validator.hideMessages();
            this.emailvalidator.hideMessages();
            
            this.forceUpdate();
        } else {
            this.validator.showMessages();
            this.emailvalidator.showMessages();
            this.forceUpdate();
        }
    }

    ProfileApi = async () => {
    
        this.setState({
            profileImage: window.sessionStorage.getItem("profileImage")
        });
    }

    render() {

        return (
            <div className="left-sidebar-div">

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    className="toast-container"
                    toastClassName="dark-toast"
                />

                <div className="left-sidebar">

                    <div className="left-sidebar-container">
                        <div className="edit-profile-content">
                            <div className="edit-profile">
                                <div className="profile-content">
                                    <img src={this.state.profileImage} alt="" />
                                </div>
                                <div className="profile-name">
                                    <div className="name-edit">
                                        <h1 className="header">{this.state.userName}</h1>
                                        <Link to={'/profile'}>
                                            <img src={editIcon} alt="editIcon" />
                                        </Link>
                                    </div>
                                    <h1 className="header">{this.state.userTitle}</h1>
                                </div>
                            </div>

                            <Link to="/pending-users">
                                <div className="profile-contacts">
                                    <div className="profile-contact-icon">
                                        <img src={dataPending} alt="dataPending" />
                                    </div>
                                    <div className="profile-contact-info">
                                        <h2 className="header contact-info">Pending Invites</h2>
                                    </div>
                                    <div className="profile-contact-number">
                                        <h2 className="header contact-info">({this.state.listlength})</h2>
                                    </div>
                                </div>
                            </Link>

                            <div className="profile-contacts"
                                onClick={this.showCountModal}
                            >
                                <div className="profile-contact-icon">
                                    <img src={profileIcon} alt="profileIcon" />
                                </div>
                                <div className="profile-contact-info">
                                    <h2 className="header contact-info">Profile View</h2>
                                </div>
                                <div className="profile-contact-number profile-number">
                                    <h2 className="header contact-info">({this.state.countLength})</h2>
                                </div>
                            </div>

                            <div className="profile-contacts" onClick={this.showInviteModal}>
                                <div className="profile-contact-icon">
                                    <img src={inviteIcon} alt="inviteIcon" />
                                </div>
                                <div className="profile-contact-info">
                                    <h2 className="header contact-info">Invite Contacts</h2>
                                </div>
                            </div>
                        </div>

                        <div className="your-posts-container">
                            <div className="your-post-accordion">
                                <div className="view-icon">
                                    <img src={newPost} alt="newPost" />
                                </div>

                                <div className="accordion view-accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                View your posts to
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body view-accordion-content">
                                                <div className="custom-select fa-caret-down">
                                                    <div className="select-caret">
                                                        <i className="fas fa-caret-down"></i>
                                                    </div>
                                                    <select
                                                        data-live-search="true"
                                                        value={this.state.viewPostTo}
                                                        onChange={this.handleChange}
                                                    >
                                                        <option value="">Group / Individuals</option>
                                                        <option value="individuals">Individuals</option>
                                                        {
                                                            this.state.groups.map((g, key) => {
                                                                return <option key={key} value={encodeURI(g.gname)}>{g.gname}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                {this.state.isIndivisual ? (
                                                    <div className="custom-select fa-caret-down">
                                                        <div className="select-caret">
                                                            <i className="fas fa-search"></i>
                                                        </div>

                                                        <select
                                                            data-live-search="true"
                                                            value={this.state.isIndivisualvalue}
                                                            name="isIndivisualvalue"
                                                            onChange={this.handleIndivisualChange}
                                                        >
                                                            <option value="">Select from list</option>
                                                            {this.state.connections.map((conn, key) => (
                                                                <option key={key} value={conn.name}>
                                                                    {conn.username}
                                                                </option>
                                                            
                                                            ))}
                                                        </select>
                                                    </div>
                                                ) : ""}


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="pannel-container">
                                <Link to={'/my-circle'}>
                                    <div className="pannel-content">
                                        <div className="pannel-icon">
                                            <img src={group} alt="group" />
                                        </div>
                                        <div className="pannel-name">
                                            <h2 className="header">My Circle</h2>
                                        </div>
                                    </div>
                                </Link>

                                <Link to={'/software'}>
                                    <div className="pannel-content">
                                        <div className="pannel-icon">
                                            <img src={software} alt="software" />
                                        </div>
                                        <div className="pannel-name">
                                            <h2 className="header">Software</h2>
                                        </div>
                                    </div>
                                </Link>

                                <Link to={'/product'}>
                                    <div className="pannel-content">
                                        <div className="pannel-icon">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="pannel-name">
                                            <h2 className="header">Product</h2>
                                        </div>
                                    </div>
                                </Link>

                                <Link to={'/job'}>
                                    <div className="pannel-content">
                                        <div className="pannel-icon">
                                            <img src={job} alt="job" />
                                        </div>
                                        <div className="pannel-name">
                                            <h2 className="header">Job</h2>
                                        </div>
                                    </div>
                                </Link>

                                <Link to={'/service'}>
                                    <div className="pannel-content">
                                        <div className="pannel-icon">
                                            <img src={service} alt="service" />
                                        </div>
                                        <div className="pannel-name">
                                            <h2 className="header">Services</h2>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* profile count model start */}
                <div
                    className={
                        "creategroup-modal-container " +
                        (this.state.profileCount ? "open" : "")
                    }
                    id="create-post-modal"
                >
                    <div className="creategroup-modal send-message-modal postcreate-modal profile-count-modal" id="profileviewmodal">
                        <button
                            type="button"
                            className="close-button-modal"
                            id="close-createPost"
                            onClick={this.hideCountModal}
                        >
                            <i className="fas fa-times" />
                        </button>

                        <div className="creategroup-content login-content-fields sendmessage-content createpost-container count-container">

                            {this.state.allProfileCounts.length ? this.state.allProfileCounts.map((counts, key) => (
                            <Link
                                to={{
                                    pathname: `/profileshow/${counts.id}`,
                                    state: 1, // your data array of objects
                                }}
                            key={key}    
                            >
                                <div className="like-modal" key={key}>
                                    <div className="user-profile">
                                        <img src={counts.pimage} alt="" />
                                    </div>
                                    <div className="user-name">
                                        <h1 className="header">{counts.username}</h1>
                                    </div>
                                </div>
                            </Link>
                            )) : <div className="like-modal">
                                <div className="user-name">
                                    <h1 className="header">No Records Found</h1>
                                </div>
                            </div>}

                        </div>
                    </div>
                </div>
                {/* profile count model end */}

                {/* Contact Supplier Model START */}
                <div className={"creategroup-modal-container contact-modal" + (this.state.inviteFriends ? " open" : "")}>
                    <div className="creategroup-modal contactsuuplier-modal invite-modal">

                        <button
                            type="button"
                            className="close-button-modal"
                            id="close-createGroup"
                            onClick={this.hideInviteModal}
                        >
                            <i className="fas fa-times"></i>
                        </button>

                        <div className="creategroup-header">
                            <h1 className="header">Contact</h1>
                        </div>

                        <div className="creategroup-content login-content-fields invite-friends-modal">
                            <form action="" className="login sendEmailCont">

                                <div className="contact-container">

                                    <label htmlFor="senderName" className="name">Your Name</label>

                                    <input
                                        type="text"
                                        className="groupname"
                                        placeholder="Sender Name"
                                        name="senderName"
                                        value={this.state.senderName}
                                        onChange={this.handleInputChange}
                                    />

                                </div>

                                {this.validator.message(
                                    "Sender Name field is",
                                    (this.state.senderName),
                                    "required|string"
                                )}

                                <div className="contact-container">

                                    <label htmlFor="senderName" className="name">Your Email</label>

                                    <input
                                        type="text"
                                        className="groupname"
                                        placeholder="Sender Email"
                                        name="senderEmail"
                                        value={this.state.senderEmail}
                                        onChange={this.handleInputChange}
                                    />

                                </div>

                                {this.validator.message(
                                    "Sender Email field is",
                                    (this.state.senderEmail),
                                    "required|email"
                                )}

                                <div className="contact-container">

                                    <label htmlFor="subject" className="name">Subject</label>

                                    <input
                                        type="text"
                                        className="groupname"
                                        placeholder="Subject"
                                        name="subject"
                                        value={this.state.subject}
                                        onChange={this.handleInputChange}
                                    />

                                </div>

                                {this.validator.message(
                                    "Subject field is",
                                    this.state.subject,
                                    "required|string"
                                )}

                                <div className="contact-container">

                                    <label htmlFor="supplierRecipient" className="name">Recipient Email</label>

                                    <input
                                        type="text"
                                        className="groupname"
                                        placeholder="Separate email ids by , (comma)"
                                        name="supplierRecipient"
                                        value={this.state.supplierRecipient}
                                        onChange={this.handleInputChange}
                                    />

                                </div>

                                {this.emailvalidator.message(
                                    "Email field is",
                                    (this.state.supplierRecipient),
                                    "required|string|multipleEmail"
                                )}

                                <div className="contact-container">

                                    <label htmlFor="message" className="name">Message</label>

                                    <textarea type="text"
                                        placeholder="Message"
                                        id="textarea"
                                        className="input-field field"
                                        name="message"
                                        value={this.state.message}
                                        onChange={this.handleInputChange}
                                    ></textarea>

                                </div>

                                {this.validator.message(
                                    "Message field is",
                                    this.state.message,
                                    "required|string"
                                )}

                                <button
                                    type="button"
                                    className="login-button creategroup-button invite-button"
                                    id="mySupplier"
                                    onClick={this.inviteFriendsApi}
                                >
                                    <h2>Send</h2>
                                </button>
                            </form>
                        </div>

                    </div>
                </div>
                {/* Contact Supplier Model END */}

            </div>
        )
    }
};

export default withRouter(PostLeftSidebar)