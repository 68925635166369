import "../modals/Popup.css"
import React, { Component } from 'react'
import { Link, Switch, withRouter } from "react-router-dom";

import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import axios from "axios";

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import Loader from '../loader/Loader';
const { OAuth2Client } = require('google-auth-library')
const client = new OAuth2Client(process.env.REACT_APP_GOOGLE_CLIENT_ID)
var qs = require('qs');

export class JoinNow extends Component {

    constructor(props) {
        super(props)

        //dynamic validation for title field and content field
        this.validator = new SimpleReactValidator({
            messages: {
                required: "The :attribute required.",
                alpha: "The :attribute must be string.",
            },
        });

        this.state = {
            displayName: "",
            userName: "",
            password: "",
            email: "",
            phone: "",
            type: 'password',
            socialReg: false,
            socialUname: "",
            socialUemail: "",
            login: false,
            data: {},
            picture: "",
            socialCountry: "",
            GoogleisLoggedIn: false,
            validGoogle: false,

            // country
            jcountry: "",
            countryCode: "",
            keyword: [],
            Options: [],
            codesOption: [],
            codes: [],

            allCountriesCodes:[],

            isLoading: false,

            urlToGetLinkedInAccessToken: 'https://www.linkedin.com/oauth/v2/accessToken',

            urlToGetUserProfile :'https://api.linkedin.com/v2/me?projection=(id,localizedFirstName,localizedLastName,profilePicture(displayImage~digitalmediaAsset:playableStreams))',

            urlToGetUserEmail : 'https://api.linkedin.com/v2/clientAwareMemberHandles?q=members&projection=(elements*(primary,type,handle~))',

        }
        this.showHide = this.showHide.bind(this);

    }

    componentDidMount = async () => {
        if (localStorage.getItem("token")) {
            window.location = process.env.PUBLIC_URL
        }

        const allCountries = await axios.get(
            `${process.env.REACT_APP_API_URL}countries`

        );

        if (allCountries.status === 200 && allCountries.data.status === true) {
            const keywordArray = [];

            allCountries.data.datas.map((keys) =>
                keywordArray.push({
                    value: keys.name,
                })
            );

            this.setState({
                keyword: allCountries.data.datas,
                Options: keywordArray,
            });
        }

        // country codes
        const allCountriesCodes = await axios.get(
            `${process.env.REACT_APP_API_URL}phonecodes`
        );

        if (allCountriesCodes.status === 200 && allCountriesCodes.data.status === true) {
            const codesArray = [];
            const phoneCodes = [];

            await this.setState({
                allCountriesCodes:allCountriesCodes,
            })

            allCountriesCodes.data.datas.map((keys) => {
                phoneCodes.push(keys.phonecode);
            }
            );
      
            for (let index = 0; index < [...new Set(phoneCodes)].length; index++) {
              const ele = [...new Set(phoneCodes)][index];
      
              if (ele !== '91') {
                codesArray.push({
                  value: ele,
                })
              }
            }

            this.setState({
                codes: allCountries.data.datas,
                codesOption: codesArray,
            });
        }
    }

    handleInputChange = async (e) => {
        if(e.target.name === 'jcountry') {

            this.state.allCountriesCodes.data.datas.map((keys) => {

                if (keys.name === e.target.value) {
                    this.setState({
                        countryCode: '+ ' +keys.phonecode
                    })
                }
            });

          
        }

        await this.setState({
            [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
        });
    };

    joinNow = async () => {

        if (this.validator.allValid()) {

            this.validator.hideMessages();
            this.forceUpdate();

            if (this.state.isLoading === false) {

                await this.setState({
                    isLoading: true,
                })

                const supplierData = {
                    name: this.state.displayName,
                    username: this.state.userName,
                    password: this.state.password,
                    email: this.state.email,
                    phone: this.state.phone,
                    country: this.state.jcountry,
                    block: 1,
                };

                try {
                    const formres = await axios.post(
                        `${process.env.REACT_APP_API_URL}register`,
                        supplierData,
                        {
                            headers: {
                                Auth: `${localStorage.getItem("token")}`
                            },
                        }
                    );

                    if (formres.data.status === true) {
                        toast.success(formres.data.message, {
                            position: "top-right",
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error(formres.data.message, {
                            position: "top-right",
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                } catch (err) {
                    if (err?.response?.status === 403) {
                        localStorage.removeItem("token");
                        window.location = process.env.PUBLIC_URL;
                    } else if (err?.response?.data?.error !== '') {
                        toast.error(err?.response?.data?.error, {
                            position: "top-right",
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error(err.message, {
                            position: "top-right",
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }

                setTimeout(async () => {
                    await this.setState({
                        isLoading: false,
                    })
                }, 5000)
            }

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    showHide(e) {
        //e.preventDefault();
        //e.stopPropagation();
        this.setState({
            type: this.state.type === 'password' ? 'input' : 'password'
        })
    }


    socialRegister = async () => {

        await this.setState({
            socialReg: true,
        })

        if (this.state.socialReg) {

            var socialdata = {
                "name": this.state.socialUname,
                "username": this.state.socialUname,
                "email": this.state.socialUemail,
                "password": "12345678",
                "block": false,
                "country": "india",
                "phone": "9876543210"
            }

            var socialres = await axios.post(
                `${process.env.REACT_APP_API_URL}socialmediasignup`,
                socialdata,
                {
                    headers: {
                        Auth: `${localStorage.getItem("token")}`
                    },
                }
            );

            if (socialres.status === 200) {
                if (socialres.data.status === true) {
                    toast.success(socialres.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                else {

                    toast.error(socialres.data.errors ? socialres.data.errors : socialres.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            }

            setTimeout(() => {
                this.props.history.push('/')
            }, 3000);
        }
    }


    responseFacebook = async (response) => {
        
        // Login failed
        if (response.status === "unknown") {
            
            toast.error("Login failed!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            this.setState({
                login: false,
            })

            return false;
        }

        if (response.accessToken) {
            
            this.setState({
                login: true,
            })
        } else {
            
            this.setState({
                login: false,
            })
        }

        await this.setState({
            socialReg: true,
        })

        var result = "";
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < 7; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        
        if (this.state.login) {
            
            var country;
            await axios.get('https://ipapi.co/json/').then((response) => {
                let data = response.data;
                
                country = data.country_name;
               
            }).catch((error) => {
                console.log(error);
            });

            

            this.setState({
                socialUname: response.name,
                socialUemail: response.email,
                socialCountry: country,
                data: response,
                picture: response.picture.data.url,
            })
            
            var socialdata = {
                "name": this.state.socialUname,
                "username": this.state.socialUname,
                "email": this.state.socialUemail,
                "password": result,
                "block": 1,
                "country": this.state.socialCountry.replace(/^\s+/g, ''),
                "phone": "999999999",
            }

            var socialres = await axios.post(
                `${process.env.REACT_APP_API_URL}socialmediasignup`,
                socialdata,
                {
                    headers: {
                        Auth: `${localStorage.getItem("token")}`
                    },
                }
            );

            if (socialres.status === 200) {
                if (socialres.data.status === true) {
                    toast.success(socialres.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                else {

                    toast.error(socialres.data.errors ? socialres.data.errors : socialres.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            }

            setTimeout(() => {
                this.props.history.push('/')
            }, 3000);
        }

    }

    responseGoogleSuccess = async (response) => {
        
        if (response.accessToken) {
            this.setState({
                GoogleisLoggedIn: true,
                validGoogle: true,
            })
        } else {
            this.setState({
                GoogleisLoggedIn: false,
                validGoogle: false,
            })
        }

        var result = "";
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < 7; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }

        if (this.state.GoogleisLoggedIn && this.state.validGoogle) {

            this.setState({
                socialUname: response.profileObj.name,
                socialUemail: response.profileObj.email,
                // socialCountry:country, 
            })

            var socialdata = {
                "name": this.state.socialUname,
                "username": this.state.socialUname,
                "email": this.state.socialUemail,
                "password": result,
                "block": 1,
                "country": 'india',
                "phone": "999999999",
            }

            var socialres = await axios.post(
                `${process.env.REACT_APP_API_URL}socialmediasignup`,
                socialdata,
                {
                    headers: {
                        Auth: `${localStorage.getItem("token")}`
                    },
                }
            );

            if (socialres.status === 200) {
                if (socialres.data.status === true) {
                    toast.success(socialres.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                else {

                    toast.error(socialres.data.errors ? socialres.data.errors : socialres.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            }

            setTimeout(() => {
                this.props.history.push('/')
            }, 3000);
        }
    }

    // Error Handler
    responseGoogleError = async (response) => {
        this.setState({
            validGoogle:false
        })
    };

    linkedinLogin = async (code) => {

        let linkedData = await axios.get(
            `${process.env.REACT_APP_API_URL}linkedin/${code}`,
            {
                headers: {
                    Auth: `${localStorage.getItem("token")}`
                },
            }
        );

        if (linkedData.status === 200) {
            
            const Linkedemail = linkedData.data.email.emailAddress
            const UserName = linkedData.data.firstName + " " + linkedData.data.lastName
            
            var result = "";
            var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            var charactersLength = characters.length;
            for (var i = 0; i < 7; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                );
            }
    
                this.setState({
                    socialUname: UserName,
                    socialUemail: Linkedemail,
                    // socialCountry:country, 
                })
    
                var socialdata = {
                    "name": this.state.socialUname,
                    "username": this.state.socialUname,
                    "email": this.state.socialUemail,
                    "password": result,
                    "block": 1,
                    "country": 'india',
                    "phone": "999999999",
                }
    
                var socialres = await axios.post(
                    `${process.env.REACT_APP_API_URL}socialmediasignup`,
                    socialdata,
                    {
                        headers: {
                            Auth: `${localStorage.getItem("token")}`
                        },
                    }
                );
    
                if (socialres.status === 200) {
                    if (socialres.data.status === true) {
                        toast.success(socialres.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    else {
    
                        toast.error(socialres.data.errors ? socialres.data.errors : socialres.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
    
                }
    
                setTimeout(() => {
                    this.props.history.push('/')
                }, 3000);
        }
    }

    render() {
        return (
            <>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    className="toast-container"
                    toastClassName="dark-toast"
                />

                <section className="login-popup joinnow-popup">
                    <div className="login-popup-container">

                        <button type="button" className="close-button close-button-modal login-close-modal" id="close-joinNow"
                            onClick={() => this.props.history.push('/')}>
                            <i className="fas fa-times"></i>
                        </button>

                        <div className="login-container joinnow-container" id="joinnowmodal">
                            <div className="social-buttons-content joinnow-social-buttons">

                                <div className="social-header">
                                    <h1 className="header">Social Sign up</h1>

                                </div>

                                <div className="social-button">
                                    <GoogleLogin
                                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                        // buttonText="Sign in with Google"
                                        render={renderProps => (
                                            <button onClick={renderProps.onClick}>Sign in with Google</button>
                                        )}
                                        onSuccess={this.responseGoogleSuccess}
                                        onFailure={this.responseGoogleError}
                                        className="google"
                                        icon={false}
                                        disabledStyle={false}
                                    // cookiePolicy={'single_host_origin'}
                                    />
                                   
                                </div>
                                <div className="social-button">

                                    <FacebookLogin
                                        appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                                        autoLoad={false}
                                        cssClass="facebook"
                                        fields="name,email,picture,location,hometown,locale,currency,first_name,last_name"
                                        scope="public_profile,email,user_location,user_hometown"
                                        callback={this.responseFacebook}
                                        textButton="Sign In with Facebook"
                                        // icon="fa-facebook" 
                                    />

                                   
                                </div>
                                <div className="social-button">
                                    <LinkedIn
                                            clientId={process.env.REACT_APP_REACT_LINKEDIN_CLIENT_ID}
                                            redirectUri={`${window.location.origin}/linkedin`}
                                            onSuccess={(code) => {
                                                this.linkedinLogin(code);
                                            }}
                                            
                                            
                                            scope="r_liteprofile r_emailaddress"
                                            >
                                            {({ linkedInLogin }) => (
                                                <div className="social-button">
                                                    <button type="button" className="linkedin" onClick={linkedInLogin}>
                                                        <h1>Sign in with Linkedin</h1>
                                                    </button>
                                                </div>
                                            
                                            )}
                                        </LinkedIn>
                                   
                                </div>
                            </div>

                            <div className="login-content-fields joinnow-content-fields" id="joinnowcontent">
                                <div className="joinnow-header">
                                    <h1 className="header">Create New Account</h1>
                                </div>

                                <form action="" className="login" onSubmit={(e) => e.preventDefault()} encType="multipart/form-data">
                                    <input
                                        type="text"
                                        className="login-field"
                                        placeholder="Display name"
                                        name="displayName"
                                        value={this.state.displayName}
                                        onChange={this.handleInputChange}
                                    />

                                    {this.validator.message(
                                        "Display Name field is",
                                        (this.state.displayName),
                                        "required|string"
                                    )}

                                    <input
                                        type="text"
                                        className="login-field"
                                        placeholder="User name"
                                        name="userName"
                                        value={this.state.userName}
                                        onChange={this.handleInputChange}

                                    />

                                    {this.validator.message(
                                        "User Name field is",
                                        (this.state.userName),
                                        "required|string"
                                    )}
                                    <div className="input-group">
                                        <input
                                            type={this.state.type}
                                            className="login-field"
                                            placeholder="Password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleInputChange}
                                        />

                                        <span className="password__show" onClick={this.showHide}>{this.state.type === 'password' ?
                                            <i className="far fa-eye"></i> :
                                            <i className="far fa-eye-slash"></i>}</span>
                                    </div>
                                    {this.validator.message(
                                        "Password field is",
                                        (this.state.password),
                                        "required|string"
                                    )}

                                    <input
                                        type="email"
                                        className="login-field"
                                        placeholder="Email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleInputChange}
                                    />

                                    {this.validator.message(
                                        "Email field is",
                                        (this.state.email),
                                        "required|email"
                                    )}

                                    <div className="caret-content">
                                        <div className="select-caret">
                                            <i className="fas fa-caret-down" />
                                        </div>
                                        <select
                                            className="form-select country-select"
                                            name="jcountry"
                                            aria-label="Default select example"
                                            onChange={this.handleInputChange}
                                            value={this.state.jcountry}
                                        >
                                            <option defaultValue>Select Country</option>

                                            {this.state.Options.map((option) => (
                                                <option value={option.value}>{option.value}</option>
                                            ))}
                                        </select>

                                    </div>

                                    {this.validator.message(
                                        "Country field is",
                                        (this.state.jcountry),
                                        "required|string"
                                    )}

                                    <div className="mobile-number">
                                        <div className="caret-content" id="joinnowcaret">

                                        <input
                                            type="text"
                                            className="form-select code-select"
                                            placeholder="+ 91"
                                            name="countryCode"
                                            value={this.state.countryCode}
                                            onChange={this.handleInputChange}
                                            disabled={true}
                                        />

                                        </div>

                                        <input
                                            type="tel"
                                            className="login-field"
                                            placeholder="Phone"
                                            pattern="\d{10,14}"
                                            maxLength="14"
                                            name="phone"
                                            value={this.state.phone}
                                            onChange={this.handleInputChange}
                                        />

                                    </div>

                                    {this.validator.message(
                                        "Phone field is",
                                        (this.state.phone),
                                        "required|string"
                                    )}

                                    <h1 className="header joinnow-text">By clicking Join Now or Log In, you agree to PEXit's Terms.</h1>

                                    <button
                                        type="submit"
                                        className="login-button submit-join-button"
                                        id="myButton"
                                        onClick={this.joinNow}
                                        disabled={this.state.isLoading}
                                    > {this.state.isLoading ? <Loader /> : 'Join Now'}</button>
                                    {/* onClick={(e) => {
                                            e.preventDefault()
                                            this.props.history.push('/postPage')
                                        }} */}
                                </form>

                               

                            </div>
                        </div>
                    </div>
                </section >
            </>
        )
    }
}


export default withRouter(JoinNow);




