import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import "../product/Product.css";
import "./ProductDetailed.css";
import Parser from "html-react-parser";

import ChatButton from "../../components/chatbutton/ChatButton";
import ContactSupplier from "../contactsupplier/ContactSupplier";
import productEmail from "../../../assets/product-email.png";
import caepipeIcon from "../../../assets/caepipe-icon.png";

export class ProductDetailed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactSupplierModel: false,
      details: [],
      ispageShow: false,
      relatedDetails: [],

      supplierId: 0,

      userName: "",
      userEmail: "",
      userMobile: "",

    };
  }

  // set turnory condition on the create post modal for showing the modal
  showModal = async () => {
    const contactSupplierModel =
      this.state.contactSupplierModel === false ? true : false;
    await this.setState({
      contactSupplierModel: contactSupplierModel,
    });
  };

  hideContactModal = async () => {
    await this.setState({
      contactSupplierModel: false,
    });
  };

  relatedProducts = async() => {

    const id = this.props.match.params.id;

    try {
      const products = await axios.get(
        `${process.env.REACT_APP_API_URL}product/${id}`,

        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (products.status === 200 && products.data.status === true) {
        this.setState({

          details: products.data.datas,
          ispageShow: true,

        });

      } else {
        this.props.history.goBack();
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }
    }

    // related products
    const relatedData = {
      category: this.state.details["category"],
      location: this.state.details["location"],
      sname: this.state.details["sname"],
      name: this.state.details["name"],
    };

    try {
      const relatedProducts = await axios.post(
        `${process.env.REACT_APP_API_URL}product/getrelatedproducts`,
        relatedData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (
        relatedProducts.status === 200 &&
        relatedProducts.data.status === true
      ) {
        this.setState({
          relatedDetails: relatedProducts.data.datas,
        });

      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }
    }
  }

  componentDidMount = async () => {
        
    this.setState({
      supplierId: this.props.location.state,
    })

    const userData = localStorage.getItem("userData");

    const userDetails = JSON.parse(userData);

    await this.setState({
      userName: userDetails.userName,
      userEmail: userDetails.userEmail,
      userMobile: userDetails.userMobile,
    });

    await this.relatedProducts();
  };

  async componentDidUpdate(prevProps, prevState) {

    if (this.props.match.params.id !== prevProps.match.params.id) {
      await this.relatedProducts();
    }
  }
  
  render() {
    return (
      <>
        {this.state.contactSupplierModel ? (
          <ContactSupplier
            hideModal={this.hideContactModal}
            supplierRecipient={this.state.details.sname}
            supplierId={this.state.supplierId}
            senderName={this.state.userName}
            senderEmail={this.state.userEmail}
            senderPhone={this.state.userMobile}
          />
        ) : (
          ""
        )}
        {this.state.ispageShow ? (
          <section className="detailed-software" id="productdetail">


            <div className="detailed-container">
              <div className="download-button">
                <button
                  type="button"
                  className="caepipe-download"
                  id="productEmail"
                  onClick={this.showModal}
                >
                  <img src={productEmail} alt="productEmail" />
                </button>
              </div>

              <div className="detail-header">
                <h1 className="header">{this.state.details.name}</h1>
              </div>

              <div className="content-container content-container-part">
                <div className="caepipe-image">
                  <img src={this.state.details.image} alt="" />
                </div>

                <div className="caepipe-info">
                  <div className="caepipe-location service-location">
                    <div className="location-icon">
                      <h1 className="header">Supplier</h1>
                      <div className="icon-content service-icon-content">
                        <h2 className="grey-header">
                          {this.state.details.sname}
                        </h2>

                        <Link to={`/supplier-detail/${this.state.details.sid}`}>
                          <button type="button" className="icon-button">
                            <img src={caepipeIcon} alt="caepipeIcon" />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="caepipe-location service-location service-location-two">
                    <div className="location-icon">
                      <h1 className="header">Location</h1>
                      <h2 className="grey-header grey-headers">
                        {this.state.details.location}
                      </h2>
                    </div>
                  </div>

                  <div className="caepipe-location service-location">
                    <div className="location-icon">
                      <h1 className="header">Category</h1>
                      <h2 className="grey-header grey-headers">
                        {this.state.details.category}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-description">
                <div className="service-description-content">
                  <div className="service-header">
                    <h1 className="header">Description</h1>
                  </div>
                </div>

                <div className="caepipe-forum caepipe-forum-part">
                  <h2 className="header truncate prodDesc">
                   
                    <span>{Parser(this.state.details.descrip.replace('\\n', ''))}</span>
                    
                  </h2>
                </div>
              </div>

              <div className="sky-tabs sky-tabs-pos-top-left sky-tabs-anim-slide-right sky-tabs-response-to-icons">
                <input
                  type="radio"
                  name="sky-tabs"
                  id="sky-tab1"
                  className="sky-tab-content-1"
                />
                <label htmlFor="sky-tab1">
                  <span>
                    <span>Product Details</span>
                  </span>
                </label>

                <input
                  type="radio"
                  name="sky-tabs"
                  id="sky-tab2"
                  className="sky-tab-content-2"
                />
                <label htmlFor="sky-tab2">
                  <span>
                    <span>Company Profile</span>
                  </span>
                </label>

                <input
                  type="radio"
                  name="sky-tabs"
                  id="sky-tab3"
                  className="sky-tab-content-3"
                />
                <label htmlFor="sky-tab3">
                  <span>
                    <span>Transaction History</span>
                  </span>
                </label>

                <input
                  type="radio"
                  name="sky-tabs"
                  defaultChecked
                  id="sky-tab4"
                  className="sky-tab-content-4"
                />
                <label htmlFor="sky-tab4">
                  <span>
                    <span>Gallery</span>
                  </span>
                </label>

                <ul>
                  <li className="sky-tab-content-1">
                    <div className="typography">
                      <div className="slider-box-container active ">
                        <div className="related-description">
                          <div className="content1-header">
                            <h2 className="header">Product Details</h2>
                          </div>
                          <div className="image-gallery-container">
                            <iframe
                              src={this.state.details.prodprf}
                              frameBorder="0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="sky-tab-content-2">
                    <div className="typography">
                      <div className="slider-box-container">
                        <div className="related-description">
                          <div className="content1-header">
                            <h2 className="header">Company Profile</h2>
                          </div>
                          <div className="image-gallery-container">
                            <iframe
                              src={this.state.details.companyProfile}
                              title="companyprofile"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="sky-tab-content-3">
                    <div className="typography">
                      <div className="slider-box-container">
                        <div className="related-description">
                          <div className="content1-header">
                            <h2 className="header">Transaction History</h2>
                          </div>
                          <div className="image-gallery-container">
                            <iframe
                              src={this.state.details.transhis}
                              title="companyprofile"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="sky-tab-content-4">
                    <div className="typography">
                      <div className="slider-box-container">
                        <div className="related-description">
                          <div className="content1-header">
                            <h2 className="header">Images</h2>
                          </div>
                          <div className="image-gallery-container">
                            {this.state.details.gallery.image.map((url) => (

                              <div className="image-gallery-content">
                                <div className="gallery-image">
                                  <img src={url} alt="diamond-idols" />
                                </div>
                                <div className="gallery-content">
                                  {/* <h2 class="header">Diamond Idols</h2> */}
                                </div>
                              </div>

                            ))
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

              </div>

              <div className="related-software-container">
                <div className="container-header">
                  <h1 className="header">Related Products</h1>
                </div>

                <div className="related-container">
                  <div className="related-content related-jobs-container">
                    {this.state.relatedDetails.map((details, key) => (
                      <Link to={{
                        pathname: `/product-detail/${details.id}`,
                        state: details.uid
                      }} key={key}>
                        <div className="product-boxes-content software-boxes-content">
                          <div className="product-image related-image related-jobs-image">
                            <img src={details.image} alt="" />
                          </div>
                          <div className="product-info software-info">
                            <div className="product-name">
                              <h2 className="header">{details.name}</h2>
                            </div>
                            <div className="product-place software-content related-jobs-content">
                              <h2 className="header grey-header">
                                {details.sname}
                              </h2>
                            </div>
                            <div className="product-place software-content related-jobs-content">
                              <h2 className="header grey-header">
                                {details.location}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

            </div>

          </section>
        ) : (
          ""
        )}

        <ChatButton />
      </>
    );
  }
}

export default withRouter(ProductDetailed);
