import "../software/Software.css";
import "../product/Product.css";
import "../addsoftware/AddSoftware.css";

import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import ChatButton from "../../components/chatbutton/ChatButton";
import Loader from "../loader/Loader";
import DOMPurify from 'dompurify';

import chatArrow from "../../../assets/chat-arrow.png";
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import Parser from "html-react-parser";
import moment from "moment";
import * as mime from "react-native-mime-types";
import { saveAs } from "file-saver";
import Files from "../../../assets/files.png";
import CameraIcon from "../../../assets/camera-icon.png";
import VideoIcon from "../../../assets/video-icon.png";
import AddLink from "../../../assets/add-link.png";
import Youtube from "../../../assets/youtube.png";

import EditIcon from "../../../assets/edit-icon.png";
import ButtonLoader from "../../components/loader/Loader";
import defaultImg from "../../../assets/un.png";
import ShowMoreText from "react-show-more-text";

import { PayPalButtons } from "@paypal/react-paypal-js";

const nodeCCAvenue = require("node-ccavenue");
const fs = require('fs')
const ccav = new nodeCCAvenue.Configure({
  merchant_id: process.env.REACT_APP_CCAVENUE_MERCHANT_ID,
  working_key: process.env.REACT_APP_CCAVENUE_LIVE_WORKING_KEY,
});

export class PostSponsoredContent extends Component {
  constructor(props) {
    super(props);

    this.Servicevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        serType: {
          // name the rule
          message: "Invalid :attribute",
          rule: (val, params, validator) => {
            var allowTypes = ["free", "paid"];

            if (allowTypes.includes(val)) {
              return true;
            } else {
              return false;
            }
          },
        },
      },
    });

    this.filevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleLinks: {
          // name the rule
          message: "The :attribute must be a valid links",
          rule: (val, params, validator) => {
            var reg = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, "");
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        multipleYoutubeLinks: {
          // name the rule
          message: "The :attribute must be a valid youtube links",
          rule: (val, params, validator) => {
            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, "");
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },

        Photos: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_PHOTOS.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_PHOTOS.split(",");
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              }
            }
            return ret;
          },
        },
        VideoFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_VIDEOS.split(","),
          rule: (val, params, validator) => {
            let Videofiles = val.files ?? [];
            let Videoret = true;
            let ar = process.env.REACT_APP_POST_VIDEOS.split(",");
            for (let i = 0; i < Videofiles.length; i++) {
              if (ar.includes(mime.extension(Videofiles[i].type)) === false) {
                Videoret = false;
              }
            }
            return Videoret;
          },
        },
      },
    });

    this.Updatefilevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleLinks: {
          // name the rule
          message: "The :attribute must be a valid links",
          rule: (val, params, validator) => {
            var reg = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, "");
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
        multipleYoutubeLinks: {
          // name the rule
          message: "The :attribute must be a valid youtube links",
          rule: (val, params, validator) => {
            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(",");
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, "");
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e);
              }
            }
            if (ine.length === 0) {
              return true;
            } else {
              return false;
            }
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },

        Photos: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_PHOTOS.split(","),
          rule: (val, params, validator) => {
            let files = val.files ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_PHOTOS.split(",");
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              }
            }
            return ret;
          },
        },
        VideoFiles: {
          // name the rule
          message:
            "The :attribute must be a valid file types, valid file types are " +
            process.env.REACT_APP_POST_VIDEOS.split(","),
          rule: (val, params, validator) => {
            let Videofiles = val.files ?? [];
            let Videoret = true;
            let ar = process.env.REACT_APP_POST_VIDEOS.split(",");
            for (let i = 0; i < Videofiles.length; i++) {
              if (ar.includes(mime.extension(Videofiles[i].type)) === false) {
                Videoret = false;
              }
            }
            return Videoret;
          },
        },
      },
    });

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.Updatevalidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.durationValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.promoCodeValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.InvalidValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.Spcontents = React.createRef();
    this.slogo = React.createRef();

    this.scfile = React.createRef();
    this.scimage = React.createRef();
    this.scvideo = React.createRef();
    this.scppt = React.createRef();
    this.scppt = React.createRef();
    this.mediaForm = React.createRef();

    this.spanRef = React.createRef();
    this.ccavenueForm = React.createRef();

    this.state = {
      searchCondition: "",
      locationData: [],
      listing: [],
      total: 0,
      page: 0,
      nextAvailable: true,
      perPage: 6,
      loading: false,
      allService: [],
      sctitle: "",
      scdescription: "",
      updateService: false,
      servID: "",
      servDet: [],
      isShowMedia: "",
      scimage: "",
      scfile: "",
      scvideo: "",
      sclink: "",
      scylink: "",
      scppt: "",
      stype: "0",

      isSubmitted: false,
      amt: "",
      duration: "",
      promoCode: "",
      referenceId: "",
      encRequest: "",
      access_code: "AVBZ71EF36BA67ZBAB",
      tripleMonths: false,
      singleMonths: false,
      days: false,
      currency: "",

      formData: "",
      isLoaded: false,
      isButtonLoding: false,
      isPublishButtonLoding: false,

      isSponsorLeftSideBar: false,
      openSponsorLeftSideBar: false,

      spYears: [],
      spMonths: [],
      uid: "",
      valid: true,
      first: [],
      second: [],
      third: [],
      fourth: [],
      specialStruct: {},

      isValidCode: false,
      isVerified: false,
      promoErrorMsg: "",
      isRenew:false,
      freeCount:0,
      
      isCreated:false,

      isccavenuePayment: false,
    };
  }

  spcontentAPI = async () => {

    if (this.state.nextAvailable) {
      await this.setState({
        loading: true,
        page: this.state.page + 1,
      });

      const serviceData = {
        page: this.state.page,
        records: this.state.perPage,
      };

      try {
        const services = await axios.post(
          `${process.env.REACT_APP_API_URL}getallsponsoredcontent`,
          serviceData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (services.status === 200 && services.data.status === true) {

          const freeCount = services.data.counts['count(id)'];

          this.setState({
            isLoaded: true,
            freeCount:freeCount,
          });

          if (services.data.datas.length>0) {
            await this.yearsSponsor();
          } 

          if (services.data.datas.length < this.state.perPage) {
            
            await this.setState({
              nextAvailable: false,
            });
          }

          let oldArray = this.state.listing;
          oldArray = oldArray.concat(services.data.datas);

          await this.setState({
            listing: oldArray,
            total: services.data.total,
          });
        } else if (services.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else {
          await this.setState({
            isLoaded: true,
            nextAvailable: false,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != "") {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      await this.setState({
        loading: false,
      });
    }
  };

  submitSpcontent = async () => {
    
    if(this.state.freeCount >= 1) {
      this.setState({
        stype:'paid',
      })
    }

    let createspcontent;
    window.scrollTo(0, 0);
    if (this.state.updateService && !this.state.isRenew) {
      if (
        this.Updatevalidator.allValid() &&
        this.Updatefilevalidator.allValid()
      ) {
        this.setState({
          isButtonLoding: true,
        });
        const formData = new FormData(await this.Spcontents.current);
        const mediaData = new FormData(await this.mediaForm.current);

        formData.append("scdescription", this.state.scdescription);
        formData.append("scduration", "0 days");

        for (var value of mediaData.entries()) {
          formData.append(value[0], value[1]);
        }

        try {
          createspcontent = await axios.post(
            `${process.env.REACT_APP_API_URL}fpservices/sponsor/${
              this.state.servID
            }`,
            formData,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
        } catch (err) {
          if (err?.response?.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != "") {
            toast.error(err?.response?.data?.error, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }

        this.setState({
          isButtonLoding: false,
        });
        this.Updatefilevalidator.hideMessages();
        this.filevalidator.hideMessages();
        this.validator.hideMessages();
        this.Updatevalidator.hideMessages();
        this.forceUpdate();

        await this.setState({
          openSponsorLeftSideBar: false,
        });
      } else {
        this.Updatevalidator.showMessages();
        this.filevalidator.showMessages();
        this.Updatefilevalidator.showMessages();
        this.forceUpdate();
      }
    } else {
      
      if (
        (this.validator.allValid() &&
        this.Servicevalidator.allValid() &&
        this.filevalidator.allValid()) || (
          this.state.isRenew &&
          this.Updatevalidator.allValid() &&
          this.Updatefilevalidator.allValid()
        )
      ) {

        if(this.state.isRenew) {
          this.setState({
            amt:"",
            stype:'paid',
          })
        }
        
        this.setState({
          isButtonLoding: true,
        });
        const formData = new FormData(await this.Spcontents.current);
        const mediaData = new FormData(await this.mediaForm.current);

        formData.append("scdescription", this.state.scdescription);
        if(this.state.isRenew) {
          formData.append("scduration", this.state.duration);
        }

        for (let value of mediaData.entries()) {
          formData.append(value[0], value[1]);
        }

        this.setState({
          isSubmitted: true,
          formData: formData,
        });

        const userData = localStorage.getItem("userData");
        const userDetails = JSON.parse(userData);

        const userLoc = localStorage.getItem("userLocation");
        const userCountryDetails = JSON.parse(userLoc);

        var currency = userCountryDetails.currency;

        if (currency === "INR") {
          currency = "INR";
        } else if (currency === "EUR") {
          currency = "EUR";
        } else if (currency === "HKD") {
          currency = "HKD";
        } else if (currency === "JPY") {
          currency = "JPY";
        } else if (currency === "GBP") {
          currency = "GBP";
        } else if (currency === "AUD") {
          currency = "AUD";
        } else {
          currency = "USD";
        }

        await this.setState({
          currency: currency,
        });

        const uid = userDetails.userId;

        const type = "sc";

        var CurrentDate = moment().unix();

        var referenceId = uid + "-" + type + "-" + CurrentDate;

        await this.setState({
          referenceId: referenceId,
        });

        this.filevalidator.hideMessages();
        this.validator.hideMessages();
        this.Servicevalidator.hideMessages();
        this.forceUpdate();

        await this.setState({
          openSponsorLeftSideBar: false,
        });
      } else {
        this.filevalidator.showMessages();

        this.validator.showMessages();
        this.Servicevalidator.showMessages();
        this.forceUpdate();
      }
    }

    if (typeof createspcontent !== "undefined") {
      if (
        createspcontent.status === 200 &&
        createspcontent.data.status === true
      ) {
        this.setState({
          sctitle: "",
          scdescription: "",
          SerLogo: "",
          scimage: "",
          scfile: "",
          scvideo: "",
          sclink: "",
          scylink: "",
          scppt: "",
          updateService: false,
          servDet: "",
        });

        await this.handleCondition(this.state.listing);

        toast.success(createspcontent.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        var msg = createspcontent.data.errors
          ? createspcontent.data.errors
          : createspcontent.data.message;
        toast.error(msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    this.setState({
      isButtonLoding: false,
    });
  };

  addService = async (oid, ostatus, curr, amt) => {
    
    if(!this.state.isccavenuePayment){
      this.setState({
      isPublishButtonLoding: true
      });
    }

    const formData = this.state.formData;

    if (this.state.stype === "paid") {
      if (ostatus === "COMPLETED") {
        ostatus = "Success";
      } 
    }

    formData.delete("scpaid");
    formData.delete("sccurrency");
    formData.delete("sctax");
    formData.delete("scorderid");
    formData.delete("scpromocode");
    formData.delete('scduration');

    formData.append("scpaid", amt > 0 ? amt : 0);
    formData.append("sccurrency", curr ? curr : "");
    
    if (curr === 'INR' || curr === 'IN'){
      formData.append('sctax', "18");
    } else {
      formData.append('sctax', "0");
    }

    formData.append("scorderid", oid ? oid : "");
    formData.append("scpromocode", this.state.promoCode);
    formData.append('scduration', this.state.duration);

    var createProduct;
    if (!this.state.isRenew) {
      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/sponsor`,
        formData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    } else {
      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/sponsor/${
          this.state.servID
        }`,
        formData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    }

    if (createProduct.status === 200 && createProduct.data.status === true) {
      if (this.state.stype === "paid") {

          this.setState({
            isCreated:false,
          })

          if (this.state.isRenew) {
            this.payment(oid, ostatus, curr, this.state.servID);
          } else {
            this.payment(oid, ostatus, curr, createProduct.data.datas);
          }
        
      } else {
        toast.success(createProduct.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

    if(!this.state.isccavenuePayment){
      this.setState({
        isCreated:true,
        sctitle: "",
        scdescription: "",
        SerLogo: "",
        scimage: "",
        scfile: "",
        scvideo: "",
        sclink: "",
        scylink: "",
        scppt: "",

        nextAvailable: true,
        page: 0,
        listing: [],
        isSubmitted: false,
        stype: "",
        amt: "",
        duration: "",
        promoCode: "",
        referenceId: "",
        encRequest: "",
        currency: "",
        formData: "",
        updateService: false,
      });

      await this.spcontentAPI();
      
      await this.yearsSponsor();
    }

    } else {
      toast.error(createProduct.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if(!this.state.isccavenuePayment){
      this.setState({
        isPublishButtonLoding: false
      });
    }
  };

  payment = async (oid, ostatus, curr, serID) => {
    const type = "sc";

    const data = {
      paymentType: "1",
      currency: curr,
      productType: type,
      orderStatus: ostatus,
      orderId: oid,
      serId: serID,
      promoCode: this.state.promoCode,
      renewType: this.state.isRenew ? "renew" : "new",
    };

    try {
      const payment = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/payment`,
        data,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (payment.status === 200 && payment.data.status === true) {

        this.setState({
          isCreated:true,
        })

        toast.success(payment.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(
          payment.data.errors ? payment.data.errors : payment.data.message,
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (err) {
      if (err.response.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != "") {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  promoCodeAPI = async () => {

    if (!this.state.isVerified) {

    var data = {
      amount: this.state.amt,
      service: "sc",
      promocode: this.state.promoCode,
    };

    if (data.amount !== "") {
      this.durationValidator.hideMessages();
      this.forceUpdate();

      if (data.promocode !== "") {
        this.promoCodeValidator.hideMessages();

        try {
          const promoCode = await axios.post(
            `${process.env.REACT_APP_API_URL}fpservices/promocode`,
            data,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );

          if (promoCode.status === 200 && promoCode.data.status === true) {
            await this.setState({
              amt: promoCode.data.datas,
              isVerified:true,
              isValidCode: false,
            });

            toast.success(promoCode.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {

            this.setState({
              isVerified:false,
              isValidCode: true,
              promoErrorMsg: promoCode.data.errors ? promoCode.data.errors : promoCode.data.message,
            })

            // toast.error(
            //   promoCode.data.errors
            //     ? promoCode.data.errors
            //     : promoCode.data.message,
            //   {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //   }
            // );
          }
        } catch (err) {
          if (err.response.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != "") {
            // toast.error(err?.response?.data?.error, {
            //   position: "top-right",
            //   autoClose: 4000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          } else {
            // toast.error(err.message, {
            //   position: "top-right",
            //   autoClose: 4000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          }
        }
      } else {
        this.promoCodeValidator.showMessages();
        this.forceUpdate();
      }
    } else {
      this.durationValidator.showMessages();
      this.forceUpdate();
    }
   } else {
    toast.error(
      "Promocode already applied",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
  };

  paymentPage = async (scid) => {
    if (!this.state.updateService) {
      const passData = {
        serId: scid,
        stype: "sc",
        sname: "sponsored",
      };

      this.props.history.push({
        pathname: `/try-premium/sponsor-content/${scid}`,
        state: passData,
      });
    }
  };

  editSpContent = async (Spid, type) => {

    if (type === "renew") {
      this.setState({
        isRenew:true,
      })
    } else {
      this.setState({
        isRenew:false,
      })
    }

    this.Servicevalidator.hideMessages();
    this.Updatefilevalidator.hideMessages();
    this.filevalidator.hideMessages();
    this.Updatevalidator.hideMessages();
    this.validator.hideMessages();
    this.forceUpdate();

    this.setState({
      updateService: true,
      scimage: "",
      scfile: "",
      scvideo: "",
      sclink: "",
      scylink: "",
      scppt: "",
    });

    if (typeof Spid !== "undefined") {
      const updService =
        this.state.updateService === false ? true : this.state.updateService;

      this.setState({
        updateService: updService,
        servID: Spid,
      });
    }

    try {
      const particularserviceDetail = await axios.get(
        `${process.env.REACT_APP_API_URL}viewsponsoredcontent/${Spid}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (
        particularserviceDetail.status === 200 &&
        particularserviceDetail.data.status === true
      ) {
        this.setState({
          servDet: particularserviceDetail.data.datas,
        });

        this.setState({
          sctitle: this.state.servDet.title,
          scdescription: this.state.servDet.post,
          amt: this.state.servDet.amount,
          sclink: this.state.servDet.link,
          scylink: this.state.servDet.allvid,
        });
      } else {
        this.state = {
          sctitle: "",
          scdescription: "",
          SerLogo: "",
          scimage: "",
          scfile: "",
          scvideo: "",
          sclink: "",
          scylink: "",
          scppt: "",
        };
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != "") {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    await this.setState({
      openSponsorLeftSideBar: true,
    });
  };

  componentDidMount = async () => {
    
    await this.handleResize();

    this.setState({
      loading: true,
    });

    const userData = localStorage.getItem("userData");
    const userDetails = JSON.parse(userData);

    await this.setState({
      uid: userDetails.userId,
    });

    await this.spcontentAPI();
    
    await this.yearsSponsor();
    

    window.addEventListener("scroll", this.listenToScroll);

    await window.addEventListener("resize", this.handleResize);
    
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isSponsorLeftSideBar: true,
        openSponsorLeftSideBar: false,
      });
    } else {
      this.setState({
        isSponsorLeftSideBar: false,
      });
    }
  };

  listenToScroll = async () => {
    if (this.state.loading === false) {
      const per = (document.documentElement.offsetHeight * 10) / 100;
      if (
        document.documentElement.offsetHeight - per <=
        document.documentElement.scrollTop + window.innerHeight
      ) {
        await this.spcontentAPI();
        
        await this.yearsSponsor();
        
      }
    }
  };

  handleCondition = async (conditionData) => {
    await this.setState({
      searchCondition: conditionData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
      
    });
  };

  handleSelected = async (e) => {
    await this.setState({
      stype: e.value?.replace(/^\s+/g, ""),
    });
  };

  handleInputChange = async (e) => {
    if (typeof e === "string") {
      this.setState({
        scdescription:
          e.replace(/^\s+/g, "") === "<p>&nbsp;&nbsp;</p>"
            ? ""
            : e.replace(/^\s+/g, ""),
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value?.replace(/^\s+/g, ""),
      });

      if (e.target.name === "duration") {
        
        var threeMonths;
        var sixMonths;
        var twelveMonths;

        var currency = this.state.currency;

        if (currency === "INR") {
          threeMonths = 2000;
          sixMonths = 4000;
          twelveMonths = 6000;
        } else if (currency === "USD") {
          threeMonths = 100;
          sixMonths = 200;
          twelveMonths = 300;
        } else if (currency === "EUR") {
          threeMonths = 100;
          sixMonths = 200;
          twelveMonths = 300;
        } else if (currency === "HKD") {
          threeMonths = 500;
          sixMonths = 1000;
          twelveMonths = 1500;
        } else if (currency === "JPY") {
          threeMonths = 10000;
          sixMonths = 20000;
          twelveMonths = 30000;
        } else if (currency === "GBP") {
          threeMonths = 75;
          sixMonths = 150;
          twelveMonths = 225;
        } else if (currency == "AUD") {
          threeMonths = 100;
          sixMonths = 200;
          twelveMonths = 300;
        } else {
          threeMonths = 100;
          sixMonths = 200;
          twelveMonths = 300;
        }

        if (e.target.value?.replace(/^\s+/g, "") === "3") {
          await this.setState({
            amt: threeMonths, //parseFloat(4000 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else if (e.target.value?.replace(/^\s+/g, "") === "6") {
          await this.setState({
            amt: sixMonths, //parseFloat(6400 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else if (e.target.value?.replace(/^\s+/g, "") === "12") {
          await this.setState({
            amt: twelveMonths, //parseFloat(9600 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else {
          await this.setState({
            amt: "",
          });
        }

        this.setState({
          promoCode:"",
          isVerified:false,
          isValidCode: false,
        })
      }

    }
  };

  selectpodocu = async () => {
    await this.setState({
      isShowMedia: "scfile",
    });
  };

  selectpoimage = async () => {
    await this.setState({
      isShowMedia: "scimage",
    });
  };

  selectVideo = async () => {
    await this.setState({
      isShowMedia: "scvideo",
    });
  };

  selectLink = async () => {
    await this.setState({
      isShowMedia: "sclink",
    });
  };

  selectYoutube = async () => {
    await this.setState({
      isShowMedia: "scylink",
    });
  };

  selectPresentations = async () => {
    await this.setState({
      isShowMedia: "scppt",
    });
  };

  deleteSponsorFile = async (fpid, fpfolder, fptype, file) => {
    try {
      let fullpath = file;
      file = file.split("/").pop();

      const resDeletePostFile = await axios.delete(
        `${
          process.env.REACT_APP_API_URL
        }fpservices/file/${fpid}/${fpfolder}/${fptype}/${btoa(file)}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
      if (
        resDeletePostFile.status === 200 &&
        resDeletePostFile.data.status === true
      ) {
        document.getElementById(fullpath).style.display = "none";
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location.href = process.env.PUBLIC_URL;
      } else if (
        err?.response?.data?.error != "" ||
        err?.response?.data?.message != ""
      ) {
        toast.error(
          err?.response?.data?.error === ""
            ? err?.response?.data?.message
            : err?.response?.data?.error,
          {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.searchCondition !== this.state.searchCondition) {
      await this.spcontentAPI();
      
      await this.yearsSponsor();
      

      await this.setState({
        loading: false,
      });
    }
    
  }

  ccavBtn = async () => {
    if (this.state.duration === "") {
      toast.error(`Duration should not be empty`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    } else {
      const userName = localStorage.getItem("userName")
    
      const orderParams = {
        order_id: this.state.referenceId,
        currency: this.state.currency,
        amount: this.state.amt,
        redirect_url: encodeURIComponent(
          `https://pexit.net/node_api/api/ccavenue`,
        ),
        billing_name: userName,
      };
      const encryptedOrderData = ccav.getEncryptedOrder(orderParams);
    
      await this.setState({
        encRequest: encryptedOrderData,
        isccavenuePayment: true,
      });

      await this.addService(this.state.referenceId, 'awaited', this.state.currency, this.state.amt)
      // if(this.state.isCreated) {
        this.ccavenueForm.current.submit();
      // }
    }
  };

  showSponsorLeftSideBar = async () => {
    if (this.state.openSponsorLeftSideBar) {
      await this.setState({
        openSponsorLeftSideBar: false,
      });
    } else {
      await this.setState({
        openSponsorLeftSideBar: true,
      });
    }
  };

  yearsSponsor = async () => {
    if(this.state.nextAvailable) {
      
      await this.setState({
        loading: true,
        isLoaded: false,
      });

      try {
        const yearsSponsor = await axios.get(
          `${process.env.REACT_APP_API_URL}getyearssponsor`,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (yearsSponsor.status === 200 && yearsSponsor.data.status === true) {
          await this.setState({
            spYears: yearsSponsor.data.datas,
          });

          if (yearsSponsor.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }

          var first = [];
          var second = [];
          var third = [];
          var fourth = [];

          const specialStruct = {};

          if (this.state.spYears.length > 0) {
            this.state.spYears.map(async (year) => {
              first = await this.getMonthSponsor(year.year, 1, 3);
              second = await this.getMonthSponsor(year.year, 4, 6);
              third = await this.getMonthSponsor(year.year, 7, 9);
              fourth = await this.getMonthSponsor(year.year, 10, 12);

              specialStruct[year.year] = { first, second, third, fourth };


              this.setState({
                specialStruct,
              });
            });
          }
        } else {
          this.setState({
            spYears: [],
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != "") {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };

  getMonthSponsor = async (year, smon, emon) => {
    const data = {
      year: year,
      strMon: smon,
      endMon: emon,
    };

    try {
      const monthsSponsor = await axios.post(
        `${process.env.REACT_APP_API_URL}getmonthssponsor`,
        data,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (monthsSponsor.status === 200 && monthsSponsor.data.status === true) {
        await this.setState({
          spMonths: monthsSponsor.data.datas,
          valid: false,
          loading: false,
          isLoaded: true,
        });

        return monthsSponsor.data.datas;
      } else {
        this.setState({
          // spMonths: [],
          valid: false,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != "") {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  htmlDecode= async(content)=>{
    let e = document.createElement('div');
    e.onload = _ => console.log('after load');
    e.innerHTML = content;

    await this.spcontentAPI();
    
    await this.yearsSponsor();
    
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    
  }

  render() {
    
    if (!this.state.isSubmitted) {
      return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
            toastClassName="dark-toast"
          />

          <section className="product-section">
            {(this.state.isSponsorLeftSideBar ||
              this.state.openSponsorLeftSideBar) && (
              <div className="left-sidebar">
                <Link to={"/"}>
                  <div className="chat-arrow">
                    <img src={chatArrow} alt="chat-arrow" />
                  </div>
                </Link>

                <div className="sidebar-container">
                  <div className="header-content">
                    <h2 className="header">Post your Sponsored Content</h2>
                  </div>

                  <div className="content-container">
                    <form
                      method="post"
                      ref={this.Spcontents}
                      encType="multipart/form-data"
                    >
                      <div>
                        {!this.state.updateService ? (
                          <>
                            <div className="sidebar-content">
                              <div className="header-content">
                                <h3 className="header">Offer Type</h3>
                              </div>
                              <div className="caret-content">
                               

                              {this.state.freeCount >= 1 ? 
                                <Select
                                  className="caret-content-select"
                                  placeholder="Select offer type"
                                  options={[
                                    {value:"paid",label:"Paid"}
                                  ]}
                                  name="stype"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  
                                  onChange={this.handleSelected}
                                  menuPosition={"fixed"}
                                /> :
                                <Select
                                  className="caret-content-select"
                                  placeholder="Select offer type"
                                  options={[
                                    { value: "free", label: "Free" },
                                    { value: "paid", label: "Paid" },
                                  ]}
                                  name="stype"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  // value={this.state.stype}
                                  onChange={this.handleSelected}
                                  menuPosition={"fixed"}
                                /> 
                                }
                              </div>

                              {this.Servicevalidator.message(
                                "Offer type",
                                this.state.stype,
                                "serType"
                              )}
                            </div>
                          </>
                        ) : (
                          <input
                            type="hidden"
                            name="scpaid"
                            className="input-field field"
                            value={this.state.amt}
                          />
                        )}

                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">
                              Title<span className="requiredField">*</span>
                            </h3>
                          </div>

                          <input
                            type="text"
                            className="input-field field"
                            placeholder="Title"
                            name="sctitle"
                            onChange={this.handleInputChange}
                            value={this.state.sctitle}
                          />

                          {this.validator.message(
                            "title",
                            this.state.sctitle,
                            "required|string"
                          )}

                          {this.Updatevalidator.message(
                            "title",
                            this.state.sctitle,
                            "required|string"
                          )}
                        </div>

                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">
                              Description
                              <span className="requiredField">*</span>
                            </h3>
                          </div>

                          <Editor
                            apiKey="textarea"
                            name="scdescription"
                            value={this.state.scdescription}
                            init={{
                              height: 200,
                              menubar: false,
                            }}
                            onEditorChange={this.handleInputChange}
                          />

                          {this.validator.message(
                            "description",
                            this.state.scdescription,
                            "required|string"
                          )}

                          {this.Updatevalidator.message(
                            "description",
                            this.state.scdescription,
                            "required|string"
                          )}
                        </div>

                        <div className="sidebar-content">
                          <div className="sidebar-button">
                            <button
                              type="button"
                              className="reset-button"
                              onClick={this.submitSpcontent}
                              disabled={this.state.isButtonLoding}
                            >
                              {this.state.isButtonLoding ? (
                                <ButtonLoader color="#8a8989" />
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div>
                      <div className="sidebar-content">
                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header attachText">Add:</h3>
                            <div className="post-pannel attachIconsDiv">
                              <div className="post-pannel-content attachIcons">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={Files}
                                    alt="Files"
                                    title="file"
                                    onClick={this.selectpodocu}
                                  />
                                  <span className="tooltiptext">File</span>
                                </div>
                              </div>

                              <div className="post-pannel-content attachIcons">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={CameraIcon}
                                    alt="CameraIcon"
                                    className="img-big"
                                    onClick={this.selectpoimage}
                                  />
                                  <span className="tooltiptext">Photo</span>
                                </div>
                              </div>

                              <div className="post-pannel-content attachIcons">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={VideoIcon}
                                    alt="VideoIcon"
                                    onClick={this.selectVideo}
                                  />
                                  <span className="tooltiptext">Video</span>
                                </div>
                              </div>

                              <div className="post-pannel-content attachIcons">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={AddLink}
                                    alt="AddLink"
                                    onClick={this.selectLink}
                                  />
                                  <span className="tooltiptext">Link</span>
                                </div>
                              </div>

                              <div className="post-pannel-content attachIcons">
                                <div className="pannel-icon tooltip">
                                  <img
                                    src={Youtube}
                                    alt="Youtube"
                                    className="img-big"
                                    onClick={this.selectYoutube}
                                  />
                                  <span className="tooltiptext">Youtube</span>
                                </div>
                              </div>

                              {/* <div className="post-pannel-content attachIcons">
                              <div className="pannel-icon tooltip">
                                <img
                                  src={CameraIcon}
                                  alt="CameraIcon"
                                  className="img-big"
                                  onClick={this.selectPresentations}
                                />
                                <span className="tooltiptext">Presentation</span>
                              </div>
                            </div> */}
                            </div>
                          </div>

                          <form
                            method="post"
                            ref={this.mediaForm}
                            encType="multipart/form-data"
                          >
                            <div
                              className={
                                this.state.isShowMedia === "scfile"
                                  ? ""
                                  : "d-none"
                              }
                            >
                              <input
                                type="file"
                                name="scfile"
                                className="input-field field"
                                ref={this.scfile}
                                onChange={this.handleInputChange}
                                accept="audio/*,video/*,image/*,.doc,.docx,.pdf,.ppt,.pptx"
                                value={this.state.scfile}
                              />

                              <div className="d-flex d_preview_images">
                                {Array.isArray(this.state.servDet?.docu)
                                  ? this.state.servDet?.docu?.map(
                                      (doc, key) => {
                                        return (
                                          <div
                                            className="image-area-content"
                                            key={key}
                                            id={doc}
                                          >
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/images/docu.png"
                                              }
                                              target={"_blank"}
                                              alt=""
                                              onClick={(e) => {
                                                e.preventDefault();
                                                saveAs(doc);
                                              }}
                                            />
                                            <a
                                              className="remove-image"
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.deleteSponsorFile(
                                                  this.state.servDet?.id,
                                                  "sponsor",
                                                  "files",
                                                  doc
                                                );
                                              }}
                                            >
                                              &#215;
                                            </a>
                                          </div>
                                        );
                                      }
                                    )
                                  : ""}
                              </div>
                            </div>

                            <div
                              className={
                                this.state.isShowMedia === "scimage"
                                  ? ""
                                  : "d-none"
                              }
                            >
                              <input
                                type="file"
                                className="input-field field"
                                name="scimage"
                                ref={this.scimage}
                                onChange={(e) => {
                                  this.setState({
                                    new_scimage: e.target,
                                  });
                                  this.filevalidator.allValid();
                                }}
                                accept="image/*"
                                // value={this.state.scimage}
                              />

                              <div className="d-flex d_preview_images">
                                {Array.isArray(this.state.servDet?.image)
                                  ? this.state.servDet?.image?.map(
                                      (doc, key) => {
                                        return (
                                          <div
                                            className="image-area-content"
                                            key={key}
                                            id={doc}
                                          >
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/images/docu.png"
                                              }
                                              target={"_blank"}
                                              alt=""
                                              onClick={(e) => {
                                                e.preventDefault();
                                                saveAs(doc);
                                              }}
                                            />
                                            <a
                                              className="remove-image"
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.deleteSponsorFile(
                                                  this.state.servDet?.id,
                                                  "sponsor",
                                                  "photos",
                                                  doc
                                                );
                                              }}
                                            >
                                              &#215;
                                            </a>
                                          </div>
                                        );
                                      }
                                    )
                                  : ""}
                              </div>

                              {this.filevalidator.message(
                                "Photo",
                                this.state?.new_scimage,
                                "Photos"
                              )}

                              {this.Updatefilevalidator.message(
                                "Photo",
                                this.state?.new_scimage,
                                "Photos"
                              )}
                            </div>

                            <div
                              className={
                                this.state.isShowMedia === "scvideo"
                                  ? ""
                                  : "d-none"
                              }
                            >
                              <input
                                type="file"
                                className="input-field field"
                                name="scvideo"
                                ref={this.scvideo}
                                onChange={(e) => {
                                  this.setState({
                                    new_scvideo: e.target,
                                  });
                                  this.filevalidator.allValid();
                                }}
                                accept="video/*"
                                // value={this.state.scvideo}
                              />

                              <div className="d-flex d_preview_images">
                                {Array.isArray(this.state.servDet?.video)
                                  ? this.state.servDet?.video?.map(
                                      (doc, key) => {
                                        return (
                                          <div
                                            className="image-area-content"
                                            key={key}
                                            id={doc}
                                          >
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/images/film.png"
                                              }
                                              target={"_blank"}
                                              alt=""
                                              onClick={(e) => {
                                                e.preventDefault();
                                                saveAs(doc);
                                              }}
                                            />
                                            <a
                                              className="remove-image"
                                              href="#"
                                              onClick={() => {
                                                this.deleteSponsorFile(
                                                  this.state.servDet?.id,
                                                  "sponsor",
                                                  "videos",
                                                  doc
                                                );
                                              }}
                                            >
                                              &#215;
                                            </a>
                                          </div>
                                        );
                                      }
                                    )
                                  : ""}
                              </div>

                              {this.filevalidator.message(
                                "Video",
                                this.state?.new_scvideo,
                                "VideoFiles"
                              )}

                              {this.Updatefilevalidator.message(
                                "Video",
                                this.state?.new_scvideo,
                                "VideoFiles"
                              )}
                            </div>

                            <div
                              className={
                                this.state.isShowMedia === "sclink"
                                  ? ""
                                  : "d-none"
                              }
                            >
                              <input
                                type="text"
                                name="sclink"
                                className="input-field field"
                                // ref={this.sclink}
                                onChange={this.handleInputChange}
                                value={this.state.sclink}
                              />

                              {this.filevalidator.message(
                                "Link",
                                this.state.sclink,
                                "multipleLinks"
                              )}

                              {this.Updatefilevalidator.message(
                                "Link",
                                this.state.sclink,
                                "multipleLinks"
                              )}

                              <label className="labelText">
                                You can add multiple link separated by comma(,)
                              </label>
                            </div>

                            <div
                              className={
                                this.state.isShowMedia === "scylink"
                                  ? ""
                                  : "d-none"
                              }
                            >
                              <input
                                type="text"
                                className="input-field field"
                                name="scylink"
                                // ref={this.scylink}
                                onChange={this.handleInputChange}
                                value={this.state.scylink}
                              />

                              {this.filevalidator.message(
                                "YouTube Link",
                                this.state.scylink,
                                "multipleYoutubeLinks"
                              )}

                              {this.Updatefilevalidator.message(
                                "YouTube Link",
                                this.state.scylink,
                                "multipleYoutubeLinks"
                              )}

                              <label className="labelText">
                                You can add multiple youtube separated by
                                comma(,)
                              </label>
                            </div>

                            <div
                              className={
                                this.state.isShowMedia === "scppt"
                                  ? ""
                                  : "d-none"
                              }
                            >
                              <input
                                type="file"
                                className="input-field field"
                                name="scppt"
                                ref={this.scppt}
                                onChange={this.handleInputChange}
                                value={this.state.scppt}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="product-container">
              <div className="product-box-container">
                <div className="product-boxes">
                  {!this.state.openSponsorLeftSideBar &&
                    !this.state.isSponsorLeftSideBar && (
                      <div
                        className="Sponsor-hamburger-menu"
                        id="mycircle-hamburger"
                      >
                        <i
                          class="fa fa-bars"
                          aria-hidden="true"
                          onClick={this.showSponsorLeftSideBar}
                        />
                      </div>
                    )}
                  {this.state.openSponsorLeftSideBar &&
                    !this.state.isSponsorLeftSideBar && (
                      <div
                        className="Sponsor-hamburger-menu"
                        id="mycircle-hamburger"
                      >
                        <i
                          class="fa fa-times"
                          aria-hidden="true"
                          onClick={this.showSponsorLeftSideBar}
                        />
                      </div>
                    )}
                  <div className="product-header">
                    <h1 className="header">
                      Active / Expired Sponsored Content
                    </h1>
                    {this.state.isLoaded && <h2>Total {this.state.total}</h2>}
                  </div>

                  {this.state.isLoaded && Object.keys(this.state.specialStruct).map((year) => {
                    
                    const data = this.state.specialStruct[year]
                    const fourth = data["fourth"]
                    const third = data["third"]
                    const second = data["second"]
                    const first = data["first"]

                    return (
                      <>
                        {fourth.length > 0 ? (
                          <>
                            <div className="product-header quarterly">
                              <h1 className="header">Oct-Dec {year}</h1>
                            </div>

                            <div className="boxes-container">
                              {fourth.length > 0 ? (
                                fourth.map((lists, key) => (
                                  <>
                                    <div
                                      className="product-boxes-content"
                                      key={key}
                                    >
                                      {/* <Link to={`/service-detail/${lists.id}`}> */}
                                      <div className="product-image">
                                        <img
                                          src={
                                            lists.image.length < 1
                                              ? defaultImg
                                              : lists.image
                                          }
                                          alt=""
                                        />
                                      </div>
                                      {/* </Link> */}

                                      <div className="product-info">
                                        <div className="product-name">
                                          <h2 className="header">
                                            {lists.title}
                                          </h2>
                                        </div>
                                        <div className="product-place">
                                          <h2 className="header grey-header">
                                            {/* {Parser(lists.post)} */}
                                            <ShowMoreText
                                              /* Default options */
                                              lines={3}
                                              more="Read more"
                                              less="Read less"
                                              className="content-css"
                                              anchorClass="my-anchor-css-class"
                                              expanded={false}
                                              truncatedEndingComponent={"... "}
                                              color="red"
                                            >
                                               <h1
                                                dangerouslySetInnerHTML={{
                                                  __html: `${new DOMParser().parseFromString(lists?.post, "text/html").documentElement.textContent}`,
                                                }}
                                              />
                                            </ShowMoreText>
                                          </h2>
                                        </div>
                                        <div className="product-place">
                                          <h2 className="header grey-header">
                                            Status :{" "}
                                            
                                            {lists.state === 0
                                              ? "Expired"
                                              : "Active"}
                                          </h2>
                                        </div>

                                        {Array.isArray(lists.docu)
                                          ? lists.docu.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorAssets"
                                                  id={doc}
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/docu.png"
                                                    }
                                                    target={"_blank"}
                                                    alt=""
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      saveAs(doc);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.image)
                                          ? lists.image.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorAssets"
                                                  id={doc}
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/docu.png"
                                                    }
                                                    target={"_blank"}
                                                    alt=""
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      saveAs(doc);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.video)
                                          ? lists.video.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorAssets"
                                                  id={doc}
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/film.png"
                                                    }
                                                    target={"_blank"}
                                                    alt=""
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      saveAs(doc);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.link)
                                          ? lists.link.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorLinks"
                                                  id={doc}
                                                >
                                                  <a
                                                    href={doc}
                                                    target={"_blank"}
                                                  >
                                                    {doc}
                                                  </a>
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.allvid)
                                          ? lists.allvid.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorLinks"
                                                  id={doc}
                                                >
                                                  <a
                                                    href={doc}
                                                    target={"_blank"}
                                                  >
                                                    {doc}
                                                  </a>
                                                </div>
                                              );
                                            })
                                          : ""}
                                      </div>

                                      {(lists.state === 0 && lists.amount !== 0) ? (
                                        <div className="product-edit">
                                          <button
                                            type="button"
                                            className="product-email-button renewPro"
                                            onClick={() =>
                                              this.editSpContent(lists.id, 'renew')
                                            }
                                          >
                                            Renew
                                          </button>
                                        </div>
                                      ): (lists.state === 1) ? (
                                        <div className="product-edit">
                                        <button
                                          type="button"
                                          className="product-email-button"
                                          onClick={() =>
                                            this.editSpContent(lists.id, 'edit')
                                          }
                                        >
                                          <img
                                            src={EditIcon}
                                            alt="EditIcon"
                                          />
                                        </button>
                                      </div>
                                       ) : ""
                                    }
                                    </div>
                                  </>
                                ))
                              ) : (
                                <h2>You haven't added any sponsor content</h2>
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {third.length > 0 ? (
                          <>
                            <div className="product-header quarterly">
                              <h1 className="header">Jul-Sep {year}</h1>
                            </div>

                            <div className="boxes-container">
                              {third.length > 0 ? (
                                third.map((lists, key) => (
                                  <>
                                    <div
                                      className="product-boxes-content"
                                      key={key}
                                    >
                                      {/* <Link to={`/service-detail/${lists.id}`}> */}
                                      <div className="product-image">
                                        <img
                                          src={
                                            lists.image.length < 1
                                              ? defaultImg
                                              : lists.image
                                          }
                                          alt=""
                                        />
                                      </div>
                                      {/* </Link> */}

                                      <div className="product-info">
                                        <div className="product-name">
                                          <h2 className="header">
                                            {lists.title}
                                          </h2>
                                        </div>
                                        <div className="product-place">
                                          <h2 className="header grey-header">
                                            {/* {Parser(lists.post)} */}
                                            <ShowMoreText
                                              /* Default options */
                                              lines={3}
                                              more="Read more"
                                              less="Read less"
                                              className="content-css"
                                              anchorClass="my-anchor-css-class"
                                              expanded={false}
                                              truncatedEndingComponent={"... "}
                                              color="red"
                                            >
                                               <h1
                                                dangerouslySetInnerHTML={{
                                                  __html: `${new DOMParser().parseFromString(lists?.post, "text/html").documentElement.textContent}`,
                                                }}
                                              />
                                            </ShowMoreText>
                                          </h2>
                                        </div>
                                        <div className="product-place">
                                          <h2 className="header grey-header">
                                            Status :{" "}
                                            {lists.state === 0
                                              ? "Expired"
                                              : "Active"}
                                          </h2>
                                        </div>

                                        {Array.isArray(lists.docu)
                                          ? lists.docu.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorAssets"
                                                  id={doc}
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/docu.png"
                                                    }
                                                    target={"_blank"}
                                                    alt=""
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      saveAs(doc);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.image)
                                          ? lists.image.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorAssets"
                                                  id={doc}
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/docu.png"
                                                    }
                                                    target={"_blank"}
                                                    alt=""
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      saveAs(doc);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.video)
                                          ? lists.video.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorAssets"
                                                  id={doc}
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/film.png"
                                                    }
                                                    target={"_blank"}
                                                    alt=""
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      saveAs(doc);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.link)
                                          ? lists.link.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorLinks"
                                                  id={doc}
                                                >
                                                  <a
                                                    href={doc}
                                                    target={"_blank"}
                                                  >
                                                    {doc}
                                                  </a>
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.allvid)
                                          ? lists.allvid.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorLinks"
                                                  id={doc}
                                                >
                                                  <a
                                                    href={doc}
                                                    target={"_blank"}
                                                  >
                                                    {doc}
                                                  </a>
                                                </div>
                                              );
                                            })
                                          : ""}
                                      </div>

                                      {(lists.state === 0 && lists.amount !== 0) ? (
                                        <div className="product-edit">
                                          <button
                                            type="button"
                                            className="product-email-button renewPro"
                                            onClick={() =>
                                              this.editSpContent(lists.id, 'renew')
                                            }
                                          >
                                            Renew
                                          </button>
                                        </div>
                                       ): (lists.state === 1) ? (
                                        <div className="product-edit">
                                        <button
                                          type="button"
                                          className="product-email-button"
                                          onClick={() =>
                                            this.editSpContent(lists.id, 'edit')
                                          }
                                        >
                                          <img
                                            src={EditIcon}
                                            alt="EditIcon"
                                          />
                                        </button>
                                      </div>
                                       ) : ""
                                    }
                                    </div>
                                  </>
                                ))
                              ) : (
                                <h2>You haven't added any sponsor content</h2>
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {second.length > 0 ? (
                          <>
                            <div className="product-header quarterly">
                              <h1 className="header">Apr-Jun {year}</h1>
                            </div>

                            <div className="boxes-container">
                              {second.length > 0 ? (
                                second.map((lists, key) => (
                                  <>
                                    <div
                                      className="product-boxes-content"
                                      key={key}
                                    >
                                      {/* <Link to={`/service-detail/${lists.id}`}> */}
                                      <div className="product-image">
                                        <img
                                          src={
                                            lists.image.length < 1
                                              ? defaultImg
                                              : lists.image
                                          }
                                          alt=""
                                        />
                                      </div>
                                      {/* </Link> */}

                                      <div className="product-info">
                                        <div className="product-name">
                                          <h2 className="header">
                                            {lists.title}
                                          </h2>
                                        </div>
                                        <div className="product-place">
                                          <h2 className="header grey-header">
                                            {/* {Parser(lists.post)} */}
                                            <ShowMoreText
                                              lines={3}
                                              more="Read more"
                                              less="Read less"
                                              className="content-css"
                                              anchorClass="my-anchor-css-class"
                                              expanded={false}
                                              truncatedEndingComponent={"... "}
                                              color="red"
                                              
                                            >
                                              <h1
                                                dangerouslySetInnerHTML={{
                                                  __html: `${new DOMParser().parseFromString(lists?.post, "text/html").documentElement.textContent}`,
                                                }}
                                              />

                                            </ShowMoreText>
                                          </h2>
                                        </div>
                                        <div className="product-place">
                                          <h2 className="header grey-header">
                                            Status :{" "}
                                            {lists.state === 0
                                              ? "Expired"
                                              : "Active"}
                                          </h2>
                                        </div>

                                        {Array.isArray(lists.docu)
                                          ? lists.docu.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorAssets"
                                                  id={doc}
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/docu.png"
                                                    }
                                                    target={"_blank"}
                                                    alt=""
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      saveAs(doc);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.image)
                                          ? lists.image.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorAssets"
                                                  id={doc}
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/docu.png"
                                                    }
                                                    target={"_blank"}
                                                    alt=""
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      saveAs(doc);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.video)
                                          ? lists.video.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorAssets"
                                                  id={doc}
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/film.png"
                                                    }
                                                    target={"_blank"}
                                                    alt=""
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      saveAs(doc);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.link)
                                          ? lists.link.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorLinks"
                                                  id={doc}
                                                >
                                                  <a
                                                    href={doc}
                                                    target={"_blank"}
                                                  >
                                                    {doc}
                                                  </a>
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.allvid)
                                          ? lists.allvid.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorLinks"
                                                  id={doc}
                                                >
                                                  <a
                                                    href={doc}
                                                    target={"_blank"}
                                                  >
                                                    {doc}
                                                  </a>
                                                </div>
                                              );
                                            })
                                          : ""}
                                      </div>

                                      {(lists.state === 0 && lists.amount !== 0) ? (
                                        <div className="product-edit">
                                          <button
                                            type="button"
                                            className="product-email-button renewPro"
                                            onClick={() =>
                                              this.editSpContent(lists.id, 'renew')
                                            }
                                          >
                                            Renew
                                          </button>
                                        </div>
                                       ): (lists.state === 1) ? (
                                        <div className="product-edit">
                                        <button
                                          type="button"
                                          className="product-email-button"
                                          onClick={() =>
                                            this.editSpContent(lists.id, 'edit')
                                          }
                                        >
                                          <img
                                            src={EditIcon}
                                            alt="EditIcon"
                                          />
                                        </button>
                                      </div>
                                       ) : ""
                                    }
                                    </div>
                                  </>
                                ))
                              ) : (
                                <h2>You haven't added any sponsor content</h2>
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {first.length > 0 ? (
                          <>
                            <div className="product-header quarterly">
                              <h1 className="header">Jan-Mar {year}</h1>
                            </div>

                            <div className="boxes-container">
                              {first.length > 0 ? (
                                first.map((lists, key) => (
                                  <>
                                    <div
                                      className="product-boxes-content"
                                      key={key}
                                    >
                                      {/* <Link to={`/service-detail/${lists.id}`}> */}
                                      <div className="product-image">
                                        <img
                                          src={
                                            lists.image.length < 1
                                              ? defaultImg
                                              : lists.image
                                          }
                                          alt=""
                                        />
                                      </div>
                                      {/* </Link> */}

                                      <div className="product-info">
                                        <div className="product-name">
                                          <h2 className="header">
                                            {lists.title}
                                          </h2>
                                        </div>
                                        <div className="product-place">
                                          <h2 className="header grey-header">
                                            {/* {Parser(lists.post)} */}
                                            <ShowMoreText
                                              /* Default options */
                                              lines={3}
                                              more="Read more"
                                              less="Read less"
                                              className="content-css"
                                              anchorClass="my-anchor-css-class"
                                              expanded={false}
                                              truncatedEndingComponent={"... "}
                                              color="red"
                                            >
                                               <h1
                                                dangerouslySetInnerHTML={{
                                                  __html: `${new DOMParser().parseFromString(lists?.post, "text/html").documentElement.textContent}`,
                                                }}
                                              />
                                            </ShowMoreText>
                                          </h2>
                                        </div>
                                        <div className="product-place">
                                          <h2 className="header grey-header">
                                            Status :{" "}
                                            {lists.state === 0
                                              ? "Expired"
                                              : "Active"}
                                          </h2>
                                        </div>

                                        {Array.isArray(lists.docu)
                                          ? lists.docu.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorAssets"
                                                  id={doc}
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/docu.png"
                                                    }
                                                    target={"_blank"}
                                                    alt=""
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      saveAs(doc);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.image)
                                          ? lists.image.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorAssets"
                                                  id={doc}
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/docu.png"
                                                    }
                                                    target={"_blank"}
                                                    alt=""
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      saveAs(doc);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.video)
                                          ? lists.video.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorAssets"
                                                  id={doc}
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/film.png"
                                                    }
                                                    target={"_blank"}
                                                    alt=""
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      saveAs(doc);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.link)
                                          ? lists.link.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorLinks"
                                                  id={doc}
                                                >
                                                  <a
                                                    href={doc}
                                                    target={"_blank"}
                                                  >
                                                    {doc}
                                                  </a>
                                                </div>
                                              );
                                            })
                                          : ""}

                                        {Array.isArray(lists.allvid)
                                          ? lists.allvid.map((doc) => {
                                              return (
                                                <div
                                                  className="image-area sponsorLinks"
                                                  id={doc}
                                                >
                                                  <a
                                                    href={doc}
                                                    target={"_blank"}
                                                  >
                                                    {doc}
                                                  </a>
                                                </div>
                                              );
                                            })
                                          : ""}
                                      </div>

                                      {(lists.state === 0 && lists.amount !== 0) ? (
                                        <div className="product-edit">
                                          <button
                                            type="button"
                                            className="product-email-button renewPro"
                                            onClick={() =>
                                              this.editSpContent(lists.id, 'renew')
                                            }
                                          >
                                            Renew
                                          </button>
                                        </div>
                                       ): (lists.state === 1) ? (
                                        <div className="product-edit">
                                        <button
                                          type="button"
                                          className="product-email-button"
                                          onClick={() =>
                                            this.editSpContent(lists.id, 'edit')
                                          }
                                        >
                                          <img
                                            src={EditIcon}
                                            alt="EditIcon"
                                          />
                                        </button>
                                      </div>
                                       ) : ""
                                    }
                                    </div>
                                  </>
                                ))
                              ) : (
                                <h2>You haven't added any sponsor content</h2>
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </div>
                {this.state.loading && <Loader />}
              </div>
            </div>
          </section>

          <ChatButton />
        </>
      );
    } else {
      return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
            toastClassName="dark-toast"
          />

          <section className="product-section">
            <div className="product-container softwares-container">
              <div className="premium-container">
                <div className="parentDiv">
                  <label>Price</label>
                  <span className="labinsep">:</span>

                  {this.state.stype === "free" ? (
                    <span id="pays">Free</span>
                  ) : (
                    <>
                      <span>
                        {this.state.currency}{" "}
                        <span id="pays">{this.state.amt}</span>
                      </span>
                    </>
                  )}
                </div>

                <div className="parentDiv">
                  <label>Duration</label>
                  <span className="labinsep">:</span>
                  <select
                    id="sftdur"
                    name="duration"
                    value={this.state.duration}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Select Duration</option>

                    {this.state.stype === "free" ? (
                      <option value="3 months">Quarterly</option>
                    ) : (
                      <>
                        <option value="3">1 Month</option>
                        <option value="6">2 Month</option>
                        <option value="12">3 Months</option>
                      </>
                    )}
                  </select>

                  {this.durationValidator.message(
                    "Please Select Duration",
                    this.state.duration,
                    "required|string"
                  )}
                </div>

                {this.state.stype === "paid" ? (
                  <>
                    <div className="parentDiv">
                      <label>Have a Promo Code? : </label>
                      <input
                        type="text"
                        id="prmcde"
                        name="promoCode"
                        value={this.state.promoCode}
                        onChange={this.handleInputChange}
                      />
                      <a
                        id="apprm"
                        onClick={this.promoCodeAPI}
                        href="javascript:void(0);"
                      >
                        Apply
                      </a>

                      {this.state.promoCode !== "" && this.state.isValidCode ?
                        <div class="srv-validation-message"> {this.state.promoErrorMsg}</div>
                        : ""}

                      {this.promoCodeValidator.message(
                        "Please Enter Promocode",
                        this.state.promoCode,
                        "required|string"
                      )}

                      {this.InvalidValidator.message(
                        "Invalid Promocode",
                        this.state.promoCode,
                        "required|string"
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div>
                  {this.state.stype === "free" ? (
                    <button
                      type="submit"
                      className="checkoutBtn ccavaenueBtn"
                      onClick={() => this.addService()}
                    >
                      {this.state.isPublishButtonLoding ? (
                        <ButtonLoader color="#8a8989" />
                      ) : (
                        "Publish"
                      )}
                    </button>
                  ) : (
                    <>
                      {this.state.currency !== "INR" ?
                      <> 
                      (
                        <PayPalButtons
                          style={{ layout: "horizontal", color:"black", label:"checkout", tagline: false }}
                          className="paypalBtn"
                          createOrder={(data, actions) => {
                            return actions.order.create({
                              intent: "CAPTURE",
                              purchase_units: [
                                {
                                  reference_id: this.state.referenceId,
                                  amount: {
                                    value: this.state.amt,
                                    currency_code: this.state.currency,
                                  },
                                },
                              ],
                            });
                          }}
                          onClick={() => {
                            if (this.state.duration === "") {
                              toast.error(`Duration should not be empty`, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                              return false;
                            }
                          }}
                          onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {
                              this.setState({
                                loading: true,
                                listing: [],
                                isSubmitted: false,
                              });

                              this.addService(
                                this.state.referenceId,
                                details.status,
                                details.purchase_units[0].amount.currency_code,
                                details.purchase_units[0].amount.value
                              );
                            });
                          }}
                          onCancel={(data) => {
                            // Show a cancel page, or return to cart
                            toast.error(`Transaction cancelled`, {
                              position: "top-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }}
                          onError={(err) => {
                            if (this.state.currency === "INR") {
                              toast.error(
                                `Paypal won't accept ${
                                  this.state.currency
                                } please change the currency`,
                                {
                                  position: "top-right",
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                }
                              );
                            }
                          }}
                        />
                      )
                      </> :
                      <>

                       {/* CC Avenue START */}
                       <form ref={this.ccavenueForm} method="post" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"> 
                        
                        <input type="hidden" name="encRequest" value={this.state.encRequest} />
                        <input type="hidden" name="access_code" value={this.state.access_code} />
                        
                      </form>
                        
                        <button type="button" onClick={() => this.ccavBtn()} className="checkoutBtn ccavaenueBtn" >
                          Checkout
                        </button>
                     
                      {/* CC Avenue END */}

                      </>
                      }
                      
                    </>
                  )}
                </div>

                {this.state.stype === "paid" ? (
                  <>
                    <div id="chcknte">
                      <p>
                        <b>Note: </b>
                      </p>
                      <p>
                        Please note down the reference number{" "}
                        <b>{this.state.referenceId}</b> for this transaction.
                      </p>
                      <p>
                        If the payment process is unsuccessful, then check the
                        status of the payment through "Transaction History"
                        available under "Paid Services" menu. If the status
                        field corresponding to your transaction reference number
                        is "Null" or "Empty", then retry with checkout again.
                      </p>
                      <p>
                        <span className="noteHeading">
                          Price shown above is exclusive of applicable Taxes and
                          Levies.{" "}
                        </span>
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </>
      );
    }
  }
}

export default withRouter(PostSponsoredContent);
