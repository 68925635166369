import "../../components/modals/Popup.css";
import "../report/Report.css";
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { withRouter } from "react-router-dom";

export class Forgot extends Component {
  constructor(props) {
    super(props);

    //dynamic validation for title field and content field
    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.state = {
      sendEmail: "",
      details: "",
      isLoading: false,
    };
  }

  handleInputChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });
  };

  sendMail = async () => {
    if (this.validator.allValid()) {

      this.validator.hideMessages();
      this.forceUpdate();

      const forgotDetail = {
        email: this.state.sendEmail,
      };

      if(this.state.isLoading === false){
                
        await this.setState({
            isLoading: true,
        })
        try {
          const forgot = await axios.post(
            `${process.env.REACT_APP_API_URL}forgot/username`,
            forgotDetail,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );

          if (forgot.status === 200 && forgot.data.status === true) {
            toast.success(forgot.data.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            this.setState({
              details: forgot.data.datas,
            });
          } else {
            toast.error(forgot.data.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } catch (err) {
          if (err?.response?.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if(err?.response?.data?.message){
            toast.error(err?.response?.data?.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(err?.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }

        setTimeout(async () => {
          await this.setState({
              isLoading: false,
          })
        },5000)
        this.props.hideModal();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />
        {/* Contact Supplier Model START */}

        <div className="creategroup-modal-container report-modal-container open">
          <div className="creategroup-modal report-modal forgot-modal">
            <button
              type="button"
              className="close-button-modal"
              id="close-createGroup"
              onClick={this.props.hideModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="forgot-header">
              <h1 className="header">Forgot password?</h1>
              <h2 className="header">Reset password in two quick steps</h2>
            </div>

            <div className="creategroup-content forgot-pass-content">
              <form action="" className="login">
                <input
                  type="text"
                  className="groupname forgot-email"
                  placeholder="Email"
                  name="sendEmail"
                  value={this.state.sendEmail}
                  onChange={this.handleInputChange}
                />

                {this.validator.message(
                  "Email field is",
                  this.state.sendEmail,
                  "required|email"
                )}

                <div className="forgot-buttons">
                  <button
                    type="button"
                    className="login-button creategroup-button forgot-content"
                    id="mySupplier"
                    onClick={this.sendMail}
                  >
                    <h2>Reset Password</h2>
                  </button>

                  <button
                    type="button"
                    className="login-button creategroup-button forgot-content back-button"
                    id="mySupplier"
                    onClick={this.props.hideModal}
                  >
                    <h2>Back</h2> 
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Contact Supplier Model END */}
      </>
    );
  }
}

export default withRouter(Forgot);
