import { Link } from "react-router-dom"
import React, { Component } from 'react'
import "./Footer.css"

export class Footer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            year: new Date().getFullYear()
        }
    }

    render() {

        return (
            <>

                <footer className="footer">
                    <div className="footer-content">
                        <p className="text">Copyright @ {this.state.year}, PEXit</p>
                    </div>
                    <div className="footer-list">
                        <ul className="list">
                            <li className="list-items">
                                <Link to='/about-us'>
                                    About
                                </Link>
                            </li>
                            <li className="list-items">
                                <Link to='/support'>
                                    Support
                                </Link>
                            </li>
                            <li className="list-items">
                                <Link to='/terms'>
                                    Terms
                                </Link>
                            </li>
                            <li className="list-items">
                                <Link to='/pricing'>
                                    Pricing
                                </Link>
                            </li>
                            <li className="list-items">
                                <Link to='/partners'>
                                    Partners
                                </Link>
                            </li>
                            <li className="list-items">
                                <div id="google_translate_element"></div>
                            </li>
                        </ul>
                    </div>
                </footer>

            </>
        )
    }
}

export default Footer;
