import "../../components/modals/Popup.css";
import "../report/Report.css";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { withRouter } from "react-router-dom";
import ButtonLoader from '../../components/loader/Loader'
import './resetPassword.css'
import failedImage from "../../../assets/no-cross.png"
export class ChangePassword extends Component {
  constructor(props) {
    super(props);

    //dynamic validation for title field and content field
    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        confrim: {
          // name the rule
          message: "The :attribute not same with password",
          rule: (val, params, validator) => {
            return val === this.state.newPass;
          },
          required: true, // optional
        },
      },
    });

    this.state = {
      newPass: "",
      confirmPass: "",
      details: "",
      type: "password",
      conType: "password",
      score: "null",
      isButtonLoding: false,
      redirect:false,
      success:true,
    };

    this.newPassHide = this.newPassHide.bind(this);
    this.conPassHide = this.conPassHide.bind(this);
  }

  handleInputChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });
  };

  newPassHide = () => {
    this.setState({
      type: this.state.type === "password" ? "input" : "password",
    });
  };

  conPassHide = () => {
    this.setState({
      conType: this.state.conType === "password" ? "input" : "password",
    });
  };
  
  componentDidMount = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const emailId = queryParams.get('token');
    var urlId = queryParams.get('id');
   
    const countNumber = await axios.post(`${process.env.REACT_APP_API_URL}reset-count`, {
      "emailId": emailId,
      "changecount": false,
      "id":urlId,
    });
    if(countNumber.data.datas.reset_count[0].resetCount == urlId){
     
      await this.setState({
        success:true,
      });
    }
    else{
      await this.setState({
        success:false,
      });
    }
  }

  submitPassword = async (e) => {
      
    e.preventDefault();
    await this.setState({
        isButtonLoding: true
    })

        const queryParams = new URLSearchParams(window.location.search);
        const emailId = queryParams.get('token');
        const count = queryParams.get('id');

    if(this.validator.allValid()) {
        const passwordDetail = {
            password: this.state.confirmPass,
            email: emailId
        };

      try {
        const password = await axios.put(
          `${process.env.REACT_APP_API_URL}password`,
          passwordDetail,
        );
        if (password.status === 200 && password.data.status === true) {
          toast.success(password.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          if(this.state.success){
            const countNumber = await axios.post(`${process.env.REACT_APP_API_URL}reset-count`, {
              "emailId": emailId,
              "changecount":true,
              "id":count,
            });
          }
          this.setState({
            details: password.data.datas,
          });
        } else {
          toast.error(password.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
      this.setState({
          newPass:"",
          confirmPass:"",
          redirect:true,
      })
    } else {
        this.validator.showMessages();
        this.forceUpdate();
    }
    
    await this.setState({
        isButtonLoding: false
    })
};

render() {
      if(this.state.redirect){
          return <Redirect to="/"/>
      }
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />
        {/* Contact Supplier Model START */}
        {this.state.success ? 
        <section className="login-popup">
        {/* <div className="login-popup-container"> */}

        {/* <div className="report-modal-container open" style={{ minWidth: '40vw' }}> */}
          <div className="creategroup-modal report-modal forgot-modal">

            <div className="forgot-header">
              <h1 className="header">Change Password</h1>
            </div>

            <div className="creategroup-content forgot-pass-content">
              <form action="" className="login changePassword">
                <label htmlFor="newPass">New Password</label>
                <div className="input-group">
                  <input
                    type={this.state.type}
                    className="groupname forgot-email"
                    placeholder="New Password"
                    name="newPass"
                    value={this.state.newPass}
                    onChange={this.handleInputChange}
                  />

                  <span
                    className="password__show password__change"
                    id="changePassword"
                    onClick={this.newPassHide}
                  >
                    {this.state.type === "password" ? (
                      <i className="far fa-eye" />
                    ) : (
                      <i className="far fa-eye-slash" />
                    )}
                  </span>
                </div>
                {this.validator.message(
                  "New Password field is",
                  this.state.newPass,
                  "required|string"
                )}

                <label htmlFor="confirmPass">Confirm Password</label>
                <div className="input-group">
                  <input
                    type={this.state.conType}
                    className="groupname forgot-email"
                    placeholder="Confirm Password"
                    name="confirmPass"
                    value={this.state.confirmPass}
                    onChange={this.handleInputChange}
                  />

                  <span
                    className="password__show password__change"
                    id="changePassword"
                    onClick={this.conPassHide}
                  >
                    {this.state.conType === "password" ? (
                      <i className="far fa-eye" />
                    ) : (
                      <i className="far fa-eye-slash" />
                    )}
                  </span>
                </div>
                {this.validator.message(
                  "Confirm Password field is",
                  this.state.confirmPass,
                  "required|confrim"
                )}

                <div className="forgot-buttons submit-button">
                  <button
                    type="button"
                    className="login-button creategroup-button forgot-content submit-pass"
                    id="mySupplier"
                    onClick={(e) => this.submitPassword(e)}
                    disabled={this.state.isButtonLoding}
                  >
                    <h2>{this.state.isButtonLoding ? <ButtonLoader /> : 'Submit'}</h2>
                  </button>
                </div>
              </form>
            </div>
          </div>
        {/* </div> */}
        {/* </div> */}
        </section>
        :  
        <section className="success-section">
          <div className="success-section-header">
            <img src={failedImage} alt="failedImage" />
            <h1>Reset password link is expired</h1>
          </div>
        </section>
        }
        {/* Contact Supplier Model END */}
      </>
    );
  }
}

export default withRouter(ChangePassword);
