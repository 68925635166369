import "../software/Software.css"
import "../product/Product.css"
import "../addsoftware/AddSoftware.css"
import "../postPage/Post-page.css"
import "./supportTicket.css"

import React, { Component } from "react"
import moment from "moment"
import axios from "axios"
import { withRouter } from "react-router-dom"
import DataTable from "react-data-table-component"
import { CSVLink } from "react-csv"
import Select from "react-select"
import SimpleReactValidator from "simple-react-validator";

import { ToastContainer, toast } from "react-toastify"

export class SupportTicket extends Component {
    constructor(props) {
        super(props)

        //dynamic validation for title field and content field
        this.validator = new SimpleReactValidator({
            messages: {
                required: "The :attribute required.",
                alpha: "The :attribute must be string.",
            },
        });

        this.state = {
            reportsAssignList: [],
            reportsList: [],
            data: [],
            downloadList: [],
            downloadSummary: [],
            reportDetails: false,
            reportuserDetails: [],

            supportUsersList: [],
            selectedOption: [],
            options: [],
            mid: "",
            moid: "",

            category: "Not Specified",
            status: "",
            notes: "",
            refId:"",
        }
    }

    columns = [
        {
            name: "SNo",
            sortable: true,
            grow: "0",
            minWidth: "71px",
            style: { paddingRight: "0", fontSize: "12px" },
            fontSize: "12px",
            cell: (row, idx) => {
                return idx + 1
            }
        },
        {
            name: "User",
            sortable: true,
            style: { whiteSpace: "unset" },
            grow: "0",
            minWidth: "140px",
            cell: (row) => row.uname
        },
        {
            name: "Post",
            sortable: true,
            cell: (row) => row.post_title
        },
        {
            name: "Category",
            sortable: true,
            grow: "0",
            minWidth: "100px",
            cell: (row) => row.category
        },
        {
            name: "Status",
            cell: (row) => row.status,
            sortable: true,
            grow: "0",
            minWidth: "90px"
        },
        {
            name: "Assign to",
            sortable: true,
            style: {
                whiteSpace: "unset",
                flexDirection: "column",
                alignItems: "flex-start"
            },
            cell: (row) =>
                !!row.assign_to ? row.assign_to.split(",").map((str) => <span>{str}</span>) : "NULL"
                
        },
        {
            name: "Reference ID",
            cell: (row) => row.refId,
            sortable: true,
            grow: "0",
            minWidth: "120px"
        },
        {
            name: "Open date",
            grow: "0",
            minWidth: "100px",
            cell: (row) => {
                if (!row.date || row.date === "0000-00-00 00:00:00") {
                    return "-"
                } else {
                    return moment.utc(row.date).local().format("DD-MM-YYYY HH:mm:ss")
                }
            }
        },
        {
            name: "Closed date",
            sortable: true,
            grow: "0",
            minWidth: "100px",
            cell: (row) => {
                if (!row.edate || row.edate === "0000-00-00 00:00:00") {
                    return "-"
                } else {
                    return moment.utc(row.edate).local().format("DD-MM-YYYY HH:mm:ss")
                }
            }
        },
        {
            name: "Reopen date",
            sortable: true,
            grow: "0",
            minWidth: "100px",
            cell: (row) => {
                if (!row.rdate || row.rdate === "0000-00-00 00:00:00") {
                    return "-"
                } else {
                    return moment.utc(row.rdate).local().format("DD-MM-YYYY HH:mm:ss")
                }
            }
        },
        {
            name: "Actions",
            grow: "0",
            minWidth: "90px",
            cell: (row) => {
                return (
                    <div>
                        <button
                            className="actionBtns"
                            onClick={() =>
                                this.showReportModal(row.pid, row.uid, row)
                            }
                        >
                            Edit
                        </button>
                    </div>
                )
            }
        }
    ]

    reportsAssignListing = async () => {
        try {
            const Reports = await axios.get(
                `${process.env.REACT_APP_API_URL}support-ticket`,
                {
                    headers: {
                        Auth: `${localStorage.getItem("token")}`
                    }
                }
            )

            if (Reports.status === 200 && Reports.data.status === true) {
                await this.setState({
                    reportsAssignList: Reports.data.datas
                })
            }
        } catch (err) {
            if (err?.response?.status === 403) {
                localStorage.removeItem("token")
                window.location = process.env.PUBLIC_URL
            } else if (err?.response?.data?.error !== "") {
                toast.error(err?.response?.data?.error, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            } else {
                toast.error(err.message, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
        }
    }

    downloadData = async () => {
        try {
            const DownloadReports = await axios.get(
                `${process.env.REACT_APP_API_URL}download-tickets`,
                {
                    headers: {
                        Auth: `${localStorage.getItem("token")}`
                    }
                }
            )

            if (
                DownloadReports.status === 200 &&
                DownloadReports.data.status === true
            ) {
                await this.setState({
                    downloadList: DownloadReports.data.datas
                })
            }
        } catch (err) {
            if (err?.response?.status === 403) {
                localStorage.removeItem("token")
                window.location = process.env.PUBLIC_URL
            } else if (err?.response?.data?.error !== "") {
                toast.error(err?.response?.data?.error, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            } else {
                toast.error(err.message, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
        }
    }

    downloadSummaryReport = async () => {
        try {
            const DownloadSummary = await axios.get(
                `${process.env.REACT_APP_API_URL}summary-report`,
                {
                    headers: {
                        Auth: `${localStorage.getItem("token")}`
                    }
                }
            )

            if (
                DownloadSummary.status === 200 &&
                DownloadSummary.data.status === true
            ) {
                await this.setState({
                    downloadSummary: DownloadSummary.data.datas
                })
            }
        } catch (err) {
            if (err?.response?.status === 403) {
                localStorage.removeItem("token")
                window.location = process.env.PUBLIC_URL
            } else if (err?.response?.data?.error !== "") {
                toast.error(err?.response?.data?.error, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            } else {
                toast.error(err.message, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
        }
    }
    handleSelected = (selectedOption) => {
        this.setState({ selectedOption })
    }

    sendMessage = async (selected) => {
        const userData = localStorage.getItem("userData")
        const userDetails = JSON.parse(userData)

        let messageHtml = `Support Ticket with ${this.state.refId} under ${this.state.category} category is modified to ${this.state.status} <br /><br />`;

        messageHtml += this.state.notes ? `Notes: ${this.state.notes}` : "";

        let supportuserId = selected.map(
            (option) => option.value
        );

        for (let index = 0; index < supportuserId.length; index++) {
            const id = supportuserId[index];

            const msgData = {
                "chatterIds": id,
                "toIds": userDetails.userId,
                "chatterMessage": messageHtml,
            }
    
            const message = await axios.post(
                `${process.env.REACT_APP_API_URL}message/send-web`,
                msgData,
                {
                    headers: {
                        Auth: `${localStorage.getItem("token")}`,
                    },
                }
            );
            
        }
        
    }

    AssignUsers = async () => {

        if (this.validator.allValid()) {

            await this.hideReportModal()

            let messageHtml = `Support Ticket with ${this.state.refId} under ${this.state.category} category is modified to ${this.state.status} <br /><br />`;

            messageHtml += this.state.notes ? `Notes: ${this.state.notes}` : "";

            const assignData = {
                pid: this.state.mid,
                moid: this.state.moid,
                supportusers: this.state.selectedOption.map(
                    (option) => option.label
                ),
                
                category: this.state.category,
                status: this.state.status,
                notes: this.state.notes,
                messageHtml: messageHtml,
            }

            var selected = this.state.selectedOption;

            try {
                const assignApi = await axios.post(
                    `${process.env.REACT_APP_API_URL}assign-to`,
                    assignData,
                    {
                        headers: {
                            Auth: `${localStorage.getItem("token")}`
                        }
                    }
                )

                if (assignApi.status === 200 && assignApi.data.status === true) {
                    await this.reportsAssignListing()
                    await this.setState({
                        selectedOption: []
                    })

                    toast.success(assignApi.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })

                    await this.sendMessage(selected);
                    // setTimeout(() => {

                    // this.props.history.push('/groupusers');
                    // }, 3000);
                } else {
                    toast.error(
                        assignApi.data.errors
                            ? assignApi.data.errors
                            : assignApi.data.message,
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        }
                    )
                }
            } catch (err) {
                if (err?.response?.status === 403) {
                    localStorage.removeItem("token")
                    window.location = process.env.PUBLIC_URL
                } else if (err?.response?.data?.error !== "") {
                    toast.error(err?.response?.data?.error, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                } else {
                    toast.error(err.message, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                }
            }

            this.validator.hideMessages();
            this.forceUpdate();
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }

    }

    supportUsers = async () => {
        try {
            const supportUsers = await axios.get(
                `${process.env.REACT_APP_API_URL}connections`,
                {
                    headers: {
                        Auth: `${localStorage.getItem("token")}`
                    }
                }
            )

            if (
                supportUsers.status === 200 &&
                supportUsers.data.status === true
            ) {
                await this.setState({
                    supportUsersList: supportUsers.data.datas,
                    options: supportUsers.data.datas.map((conn) => {
                        return { value: conn.id, label: conn.email }
                    })
                })
            }
        } catch (err) {
            if (err?.response?.status === 403) {
                localStorage.removeItem("token")
                window.location = process.env.PUBLIC_URL
            } else if (err?.response?.data?.error !== "") {
                toast.error(err?.response?.data?.error, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            } else {
                toast.error(err.message, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
        }
    }

    // report details
    showReportModal = async (pid, uid, data) => {
        
        await this.setState({
            mid: pid,
            moid: uid,
            category: data.category,
            status: data.status,
            notes: "",
            selectedOption: data.assign_to.split(",").map((email) => {
                if (email) {
                    return this.state.options.find((obj) => {
                        return obj.label === email
                    })
                }
            }),
            refId:data.refId,
            uname: data.uname,
            
        })

        const reportDetails = this.state.reportDetails === false ? true : false

        await this.setState({
            reportDetails: reportDetails
        })
    }

    hideReportModal = async () => {

        this.validator.hideMessages();
        this.forceUpdate();

        await this.setState({
            reportDetails: false
        })
    }

    goToReportlisting = async () => {
        setTimeout(() => {
            this.props.history.push("/reportslist")
        }, 500)
    }

    handleInputChange = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value?.replace(/^\s+/g, "")
        })
    }

    componentDidMount = async () => {
        await this.reportsAssignListing()
        await this.downloadData()
        await this.supportUsers()
        await this.downloadSummaryReport()
    }

    render() {
        return (
            <>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    className="toast-container"
                    toastClassName="dark-toast"
                />

                <section className="product-section">
                    <div className="dataTable-container">
                        <div className="downCsvDiv">
                            <CSVLink
                                data={this.state.downloadSummary}
                                filename={`SummaryReport_${moment().format(
                                    "DDMMMMYYYY"
                                )}.csv`}
                                className="btn downloadCsv allDet"
                                target="_blank"
                            >
                                Export Summary Report
                            </CSVLink>
                            <CSVLink
                                data={this.state.downloadList}
                                filename={"Manage support.csv"}
                                className="btn downloadCsv"
                                target="_blank"
                            >
                                Export CSV
                            </CSVLink>
                        </div>

                        <div className="dataTable">
                            <DataTable
                                columns={this.columns}
                                data={this.state.reportsAssignList}
                                pagination
                            />
                        </div>

                        {/* Report details Model START */}
                        <div
                            className={
                                "creategroup-modal-container ticketListContainer" +
                                (this.state.reportDetails ? " open" : " close")
                            }
                        >
                            <div className="creategroup-modal contactsuuplier-modal invite-modal">
                                <button
                                    type="button"
                                    className="close-button-modal"
                                    id="close-createGroup"
                                    onClick={this.hideReportModal}
                                >
                                    <i className="fas fa-times" />
                                </button>

                                <div className="creategroup-header">
                                    <h1 className="header">Ticket Status</h1>
                                </div>

                                <div className="creategroup-content login-content-fields invite-friends-modal">
                                    <form action="" className="login">
                                        <div className="contact-container modal-container">
                                            <label
                                                htmlFor="Category"
                                                className="name"
                                            >
                                                Category
                                            </label>

                                            <input
                                                type="text"
                                                className="groupname"
                                                placeholder="Category"
                                                name="category"
                                                value={this.state.category}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                        </div>

                                        <div className="contact-container modal-container">
                                            <label
                                                htmlFor="Status"
                                                className="name"
                                            >
                                                Status
                                            </label>
                                            <select
                                                required=""
                                                name="status"
                                                className="statusSelect"
                                                value={this.state.status}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            >
                                                <option value="">
                                                    -- Ticket status --
                                                </option>
                                                <option value="open">
                                                    Open
                                                </option>
                                                <option value="on_going">
                                                    On going
                                                </option>
                                                <option value="closed">
                                                    Closed
                                                </option>
                                                <option value="reopen">
                                                    Reopen
                                                </option>
                                            </select>
                                        </div>

                                        <div className="contact-container modal-container">
                                            <label
                                                htmlFor="senderName"
                                                className="name"
                                            >
                                                Assign Tickets To
                                            </label>
                                            <Select
                                                className="form-select option-multi-select"
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                isMulti
                                                menuPosition={"fixed"}
                                                value={
                                                    this.state.selectedOption
                                                }
                                                onChange={this.handleSelected}
                                                options={[
                                                    ...this.state.options,
                                                    
                                                ]}
                                            />
                                        </div>

                                        {this.validator.message(
                                            "select field is",
                                            this.state.selectedOption,
                                            "required"
                                        )}

                                        <div className="contact-container modal-container">
                                            <label
                                                htmlFor="notes"
                                                className="name"
                                            >
                                                Notes
                                            </label>

                                            <textarea
                                                type="text"
                                                row="1"
                                                className="groupname"
                                                placeholder="Notes"
                                                name="notes"
                                                value={this.state.notes}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                        </div>

                                        {this.validator.message(
                                            "Note is",
                                            this.state.notes,
                                            "required"
                                        )}

                                        <div className="contact-container modal-container">
                                            <button
                                                type="button"
                                                className="login-button creategroup-button"
                                                id="createGroups"
                                                onClick={() =>
                                                    this.AssignUsers()
                                                }
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* Report details Model END */}
                    </div>
                </section>
            </>
        )
    }
}

export default withRouter(SupportTicket)
