import React, { Component } from "react";

import failedImage from "../../../assets/no-cross.png"
import "./verify.css";

export class Error extends Component {


  render() {
    return (
      <>

        <section className="success-section">
          <div className="success-section-header">
             
                <img src={failedImage} alt="successImage" />
                <h1>Payment Cancelled</h1>
                
          </div>
          
        </section>
      </>
    );
  }
}

export default Error;
