import React, { Component } from 'react'
import "./Layout02.css"
import defaultImg from "../../../../assets/un.png"
import threeDots from "../../../../assets/3-dots.png";
import { Report } from "../../report/Report";
import { Link, withRouter } from "react-router-dom";

export default class Layout02 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            imgsrc: defaultImg,
            post:[],

            showMenuListing: "",
            reportModal: false,
            ReportPostId: 0,
            reportModule: "ads",
            showMenuId: "",
        };
    }

    componentDidMount = async () => {

        const getAdvertisement = this.props.singleAd;

        await this.setState({
            title: getAdvertisement.title,
            imgsrc: getAdvertisement.image.length > 0 ? getAdvertisement.image[0] : defaultImg,
            post:getAdvertisement,
        })

    }

     // menulisting
    showMenu = (id, postUid) => {
        const showMenuListing = this.state.showMenuListing === id ? "" : id;

        this.setState({
            showMenuListing: showMenuListing,
            showMenuId: id,

            showFriendPostId: postUid,
        });
    };

    // report module
    reportModal = async (post, uid) => {
        const reportModal = this.state.reportModal === false ? true : false;
        await this.setState({
            showFriendPostId: "",
            showMenuListing: "",
            reportModal: reportModal,
            ReportPostId: uid,
        });
      };
    
    hideReportModal = async () => {
        await this.setState({
            reportModal: false,
        });
    };


    render() {
        return (
            <>
            {this.state.reportModal ? (
                <Report
                  hideReportModal={this.hideReportModal}
                  softwareId={this.state.showMenuId}
                  softwareUid={this.state.ReportPostId}
                  module={this.state.reportModule}
                />
              ) : (
                ""
            )}

            <div className="layout02_outer_div">
            {/* <Link to={`//${this.state.post?.url}`}> */}
            <a
                href={
                    /^((http|https|ftp):\/\/)/.test(this.state.post?.url)
                    ? this.state.post?.url
                    : `//${this.state.post?.url}`
                }
                target="_blank"
            >
                <div className="profile-content">
                    <div>
                        <h5>Advertisement :</h5>
                    </div>
                    <div className="menu-container">
                        <div className="menu-dots">
                            <img
                                src={threeDots}
                                alt="threeDots"
                                onClick={() =>
                                this.showMenu(this.state.post.id, this.state.post.uid)
                                }
                            />
                        </div>

                        <div
                            className={
                            "user-menu-content others-menu-content" +
                            (this.state.showMenuListing === this.state.post.id
                                ? " open"
                                : "")
                            }
                        >
                            <h2
                            className="header"
                            onClick={() =>
                                this.reportModal("ads", this.state.post.uid)
                            }
                            >
                            Report Abuse
                            </h2>
                        </div>

                        </div>
                </div>
                <div className="ad_layout02">
                    <div className="layout02-title">
                        <h3>{this.state.title}</h3>
                    </div>

                    {(() => {
                        if (!!this.state.post?.youtube?.length > 0) {
                            
                            var utub = this.state.post.youtube;
                            utub = utub.split(",")
                            utub = utub[0].replace('watch?v=','embed/')
                            return (

                            <div className="layout-image iframeutub">
                                
                                <iframe width="100%" height="300px" src={utub} allowFullScreen="allowfullscreen"></iframe>
                                   
                            </div>

                            )
                        }

                        else if (!!this.state.post?.image?.length > 0) {

                            var imgsrc = this.state.post?.image?.length > 0 ? this.state.post?.image[0] : defaultImg;
                            return (

                            <div className="layout02-image">
                                <img src={imgsrc} alt="image"/>
                            </div>
                            )
                        }

                        else {
                            return ("")
                        }
                        
                        
                    })()}

                    
                </div>
            {/* </Link> */}
            </a>
            </div>
            </>
        )
    }
}