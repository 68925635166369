import axios from "axios";
import queryString from "query-string";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ChatButton from "../../components/chatbutton/ChatButton";
import ButtonLoader from '../../components/loader/Loader';
import Loader from "../loader/Loader";
import UserLeftSideBar from "./userleftsidebar/UserLeftSideBar";
import "./UserPage.css";

export class UserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lists: [],
      friendLists: [],
      suggestion: [],
      buttonDisable: false,
      checkbox: false,
      searchKeyword: "",
      isSearchResult: false,
    
      page: 0,
      nextAvailable: true,
      perPage: 10,
      loading: false,
      totalPage: 0,

      availableUsers: true,
      userPage: 0,
      userPerPage: 12,
      isAlluser: false,
      totalUserPage: 0,

      isLoading: false,
      checkBoxChanged: false,
      isLoaded: false,
      isButtonLoding: false,
      uid: 0,

      isUserLeftSideBar: false,
      openUserLeftSideBar: false,

      inpt : ""
    };
  }

  suggestionApi = async () => {
    if (this.state.isLoading === false) {

      await this.setState({
        isLoading: true,
      })

      if (this.state.nextAvailable) {
        await this.setState({
          loading: true,
          loadingUsers: false,
          page: this.state.page + 1,
        });

        const suggestions = {
          page: this.state.page,
          records: this.state.perPage,
          keyword: this.state.searchKeyword,
        };

        var suggestionData;
        try {
          if (this.state.isSearchResult === true) {
            suggestionData = await axios.post(
              `${process.env.REACT_APP_API_URL}search-web`,
              {
                key: this.state.searchKeyword,
                page: this.state.page,
                records: this.state.perPage,
                type: 1,
              },
              {
                headers: {
                  Auth: `${localStorage.getItem("token")}`,     
                },    
              }                 
            );                 
          } else {
            suggestionData = await axios.post(
              this.state.isAlluser
                ? `${process.env.REACT_APP_API_URL}users/list`
                : `${process.env.REACT_APP_API_URL}suggestions-web`,
              suggestions,
              {
                headers: {                
                  Auth: `${localStorage.getItem("token")}`,
                },
              } 
            );
          }
                                       
          if (
            suggestionData.status === 200 &&
            suggestionData.data.status === true
          ) {

            this.setState({
              isLoaded: true,
            })

            if (suggestionData.data.datas.totalPage <= this.state.perPage) {
              await this.setState({
                nextAvailable: false,
                loadingUsers: false,
              });
            }
            this.setState({
              suggestion: (this.state.page === 1) ? suggestionData.data.datas : this.state.suggestion.concat(suggestionData.data.datas),
            });
          } else {
            await this.setState({
              isLoaded: true,
            })
          }
        } catch (err) {
          if (err?.response?.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error !== '') {
            toast.error(err?.response?.data?.error, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
        await this.setState({
          loading: false,
          loadingUsers: false,
        });
      }


      await this.setState({
        isLoading: false,
      })

    }
  };

  componentDidMount = async () => {
    await this.handleResize();
    const queries = queryString.parse(this.props.location.search);

    if ("key" in queries && queries.key !== null && queries.key !== "") {
      await this.setState({
        isSearchResult: true,
        searchKeyword: queries.key,
        page: 0,
        nextAvailable: true,
      });
    }

    this.setState({
      loading: true,
      loadingUsers: true,
    });

    // suggestion list api
    await this.suggestionApi();

    window.addEventListener("scroll", this.listenToScroll);


    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isUserLeftSideBar: true,
        openUserLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isUserLeftSideBar: false,
      });
    }
  }

  listenToScroll = async () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (this.state.isSearchResult === true || this.state.isAlluser === true) {
        await this.suggestionApi();
      }
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.time !== this.props.location.time) {
      const queries = queryString.parse(this.props.location.search);

      if ("key" in queries && queries.key !== null && queries.key !== "") {
        await this.setState({
          isSearchResult: true,
          searchKeyword: queries.key,
          page: 0,
          nextAvailable: true,
          loading: true,
        });

        await this.suggestionApi();
      }
    }
  }

  handleCallback = async (childData) => {
    await this.setState({ nextAvailable: false });
    window.screenTop = 0;
    await this.setState({
      searchKeyword: childData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  scrollUsers = async () => {
    const docHeight = document.documentElement.scrollHeight;
    const finalCalcHeight = docHeight - (docHeight * 10) / 100;

    if (
      this.state.loading === false &&
      finalCalcHeight <= document.documentElement.scrollTop + window.innerHeight
    ) {
      await this.userList();
    }
  };

  friendRequest = async (fid) => {
    if (this.state.isLoading === false) {

      await this.setState({
        isLoading: true,
        isButtonLoding: true,
        uid: fid,
      })
      try {
        const friendListData = await axios.post(
          `${process.env.REACT_APP_API_URL}friend/request/${fid}`,
          {},
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (friendListData.status === 200) {
          if (friendListData.data.status === true) {
            const data = [];
            this.state.suggestion.map((u) => {
              if (u.id === fid) {
                u.friendStatus = 1;
              }
              data.push(u);
              return u;
            });

            await this.setState({
              suggestion: data,
            });

            toast.success(friendListData.data.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(friendListData.data.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
         
        } else {
          toast.error(err?.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      setTimeout(async () => {
        await this.setState({
          isLoading: false,
          isButtonLoding: false,
          uid: 0,
        })
      }, 5000)
    }
  };

  allUsers = async () => {
    if (this.state.checkBoxChanged === false) {
      const isAlluser = this.state.isAlluser === false ? true : false;

      await this.setState({
        isAlluser: isAlluser,
        suggestion: [],
        page: 0,
        nextAvailable: true,
        checkBoxChanged: true,
        isLoaded: false
      });

      await this.suggestionApi();

      await this.setState({
        checkBoxChanged: false
      });
    }
  };

  showUserLeftSideBar = async () => {
    if (this.state.openUserLeftSideBar) {
      await this.setState({
        openUserLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openUserLeftSideBar: true,
      });
    }
  };


  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        <section className="user-profile-section">

          {(this.state.isUserLeftSideBar || this.state.openUserLeftSideBar) &&
            <UserLeftSideBar />
          
          }
          <div className="user-profile-container">
            <div className="show-user">
              {(!this.state.openUserLeftSideBar && !this.state.isUserLeftSideBar) &&
                <div className="user-hamburger-menu" id="mycircle-hamburger">
                  <i class="fa fa-bars" aria-hidden="true" onClick={this.showUserLeftSideBar}></i>
                </div>
              }
              {(this.state.openUserLeftSideBar && !this.state.isUserLeftSideBar) &&
                <div className="user-hamburger-menu" id="mycircle-hamburger">
                  <i class="fa fa-times" aria-hidden="true" onClick={this.showUserLeftSideBar}></i>
                </div>
              }
              <div className="color-box" />
              <div className="show-user-form-group">
                <input type="checkbox" id="html" checked={this.state.isAlluser} />
                <label
                  htmlFor="html"
                  className="show-user-text"
                  onClick={this.allUsers}
                >
                  Show all PEXit User
                </label>
              </div>
            </div>


            <div className="new-group-search-bar" style={{marginBottom:"4vh"}}>
                  <div className="new-group-content">
                    <label>Search : </label>
                    <input 
                      type="search" 
                      className="grp-search" 
                      placeholder="Search for User"
                      value= {this.state.inpt}
                      onChange={(e) => {
                        this.setState({ inpt: e.target.value })
                     }}
                    />
                  </div>
              </div>

              {this.state.isLoaded &&
              <div className="user-profile">
                {this.state.suggestion.length ? (
                  this.state.suggestion.filter((val)=>{
                           if(this.state.inpt === ""){
                            return val;
                           }else if( val.username.toLowerCase().includes(this.state.inpt.toLocaleLowerCase())){
                             return val;
                           }
                  })
                 .map((list, key) => (
                    <div className="user-profiles" key={key}>
                      <div className="user-profile-image ">
                        <img src={list.pimage} alt="" />
                      </div>
                      <div className="user-profile-info">
                        <Link
                          to={{
                            pathname: `/profileshow/${list.id}`,
                            state: `${JSON.stringify(list.friendStatus)}`,
                          }}
                        >
                          <h2 className="header">{list.username}</h2>
                        </Link>
                        <h3 className="text">{list.title}</h3>
                        <h3 className="text">{list.location}</h3>
                      </div>
                      <div className="user-profile-connect">
                        {list.friendStatus === 0 ? (
                          <button
                            type="button"
                            className={"connect"}
                            onClick={() => this.friendRequest(list.id)}
                            disabled={this.state.isButtonLoding && this.state.uid === list.id}
                          >
                            {this.state.isButtonLoding && this.state.uid === list.id ? <ButtonLoader /> : 'Connect'}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="blocked">
                    <h2>There are no connection.</h2>
                  </div>
                )}
              </div>
            }
            {this.state.loading && <Loader />}


            {/* {this.state.isLoaded &&
              <div className="user-profile">
                {this.state.suggestion.length ? (
                  this.state.suggestion.map((list, key) => (
                    <div className="user-profiles" key={key}>
                      <div className="user-profile-image ">
                        <img src={list.pimage} alt="" />
                      </div>
                      <div className="user-profile-info">
                        <Link
                          to={{
                            pathname: `/profileshow/${list.id}`,
                            state: `${JSON.stringify(list.friendStatus)}`,
                          }}
                        >
                          <h2 className="header">{list.username}</h2>
                        </Link>
                        <h3 className="text">{list.title}</h3>
                        <h3 className="text">{list.location}</h3>
                      </div>
                      <div className="user-profile-connect">
                        {list.friendStatus === 0 ? (
                          <button
                            type="button"
                            className={"connect"}
                            onClick={() => this.friendRequest(list.id)}
                            disabled={this.state.isButtonLoding && this.state.uid === list.id}
                          >
                            {this.state.isButtonLoding && this.state.uid === list.id ? <ButtonLoader /> : 'Connect'}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="blocked">
                    <h2>There are no connection.</h2>
                  </div>
                )}
              </div>
            }
            {this.state.loading && <Loader />} */}
          </div>
        </section>

        <ChatButton />
      </>
    );
  }
}

export default withRouter(UserPage);
