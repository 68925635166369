import "../../product/Product.css";
import "../Supplier.css";
import React, { Component } from "react";
import chatArrow from "../../../../assets/chat-arrow.png";
import { Link, withRouter } from "react-router-dom";

export class SupplierLeftSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      selectedOption: "",
      isSelectedCondition: "",
      isKeywordValue: "",
      isLocationValue: "",
      filter: "",
      locationOptions: [],
      keywordOptions: [],

      page: 0,
      perPage: 5,
    };
  }

  handleSelected = async (e) => {
    // await this.setState({ selectedOption });

    await this.props.locationCallback(e.target.value);
  };

  handleInputChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });

    if (e.target.name === "filter") {
      await this.props.filterCallback(e.target.value?.replace(/^\s+/g, ''));
    }

    if (e.target.name === "isSelectedCondition") {
      await this.props.conditionCallback(e.target.value?.replace(/^\s+/g, ''));
    }
  };

  resetFilter = async (e) => {
    await this.setState({
      selectedOption: "",
      isSelectedCondition: "",
      filter: "",
    });
    this.props.resetFilter()
    e.preventDefault();
  };

  componentWillReceiveProps(newProps) {
    if (this.state.locationOptions.length !== newProps.locationShow.length) {
      const arr = newProps.locationShow.map((val) => {
        return { value: val, label: val };
      });

      this.setState({
        locationOptions: arr,
      });
    }
  }

  render() {
    return (
      <>
        <div className="left-sidebar">
          <Link to="/">
            <div className="chat-arrow">
              <img src={chatArrow} alt="chatArrow" />
            </div>
          </Link>

          <div className="sidebar-container">
            <div className="header-content">
              <h2 className="header">Filters</h2>
            </div>

            <div className="content-container">
              <div className="sidebar-content">
                <div className="header-content">
                  <h3 className="header">Search</h3>
                </div>
                <form className="form-field">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Search"
                    onChange={this.handleInputChange}
                    value={this.state.filter}
                    name="filter"
                  />
                </form>
              </div>

              <div className="sidebar-content">
                <div className="header-content">
                  <h3 className="header">Location</h3>
                </div>
                <div className="caret-content">

                  <select
                    value={this.state.selectedOption}
                    onChange={this.handleSelected}
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option defaultValue>All Location</option>
                    {this.state.locationOptions.map(d => {
                      return <option value={d.value}>{d.label}</option>
                    })}
                  </select>

                 
                </div>
              </div>

              <div className="sidebar-content">
                <div className="header-content">
                  <h3 className="header">Product Condition</h3>
                </div>
                <div className="caret-content">
                 
                  <select
                    id="ptype"
                    name="isSelectedCondition"
                    value={this.state.isSelectedCondition}
                    onChange={this.handleInputChange}
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option defaultValue>All Types</option>
                    <option value="new">New</option>
                    <option value="used">Used-Not Refurbished</option>
                    <option value="refurbished">Used-Refurbished</option>
                  </select>
                </div>
              </div>

              <form className="sidebar-button" onSubmit={this.resetFilter}>
                <button type="submit" className="reset-button">
                  Reset
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SupplierLeftSideBar);
