import React, { Component } from "react";
import successImage from "../../../assets/green-tick.png"

import "./verify.css";

export class Success extends Component {
    

  render() {
    return (
      <>

        <section className="success-section">
          <div className="success-section-header">
             
                <img src={successImage} alt="successImage" />
                <h1>Payment is Successfull</h1>
                
          </div>
          
        </section>
      </>
    );
  }
}

export default Success;
