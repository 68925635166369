import "../software/Software.css";
import "../product/Product.css";
import "../addsoftware/AddSoftware.css";
import "../postPage/Post-page.css";
import "../support-ticket/supportTicket.css";

import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import Select from "react-select";

import { ToastContainer, toast } from "react-toastify";

export class ReportsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportsList: [],
      data: [],
      reportDetails: false,
      assignToModal: false,
      supportUsersList: [],
      reportuserDetails: [],
      options: [],
      selectedOption: [],
      mid: "",
      moid: "",
    };
  }

  columns = [
    {
      name: "Module",
      selector: (row) => row.module,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.rcategory,
      sortable: true,
    },
    {
      name: "UserName",
      selector: (row) => row.runame,
      sortable: true,
    },
    {
      name: "UserEmail",
      selector: (row) => row.ruemail,
      sortable: true,
    },
    {
      name: "UserPhone",
      selector: (row) => row.ruphone,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div>
            <button
              className="actionBtns"
              onClick={() => this.showAssignToModal(row.mid, row.moid)}
            >
              Assign To
            </button>
            <button
              className="actionBtns"
              onClick={() => this.showReportModal(row.mid, row.moid)}
            >
              Details
            </button>
          </div>
        );
      },
    },
  ];

  newReportsList = async () => {
    try {
      const newReports = await axios.get(
        `${process.env.REACT_APP_API_URL}report-lists`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (newReports.status === 200 && newReports.data.status === true) {
        await this.setState({
          reportsList: newReports.data.datas,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if(err?.response?.data?.error != '' ){
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  supportUsers = async () => {
    try {
      const supportUsers = await axios.get(
        `${process.env.REACT_APP_API_URL}support-users`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (supportUsers.status === 200 && supportUsers.data.status === true) {
        await this.setState({
          supportUsersList: supportUsers.data.datas,
          options: supportUsers.data.datas.map((conn) => {
            return { value: conn.id, label: conn.email };
          }),
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if(err?.response?.data?.error != '' ){
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  // report details
  showReportModal = async (mid, moid) => {
    this.setState({
      mid: mid,
      moid: moid,
    });
    const reportDetails = this.state.reportDetails === false ? true : false;

    try {
      const reportUser = await axios.get(
        `${process.env.REACT_APP_API_URL}report-user/${mid}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (reportUser.status === 200 && reportUser.data.status === true) {
        if (reportUser.data.datas.length > 0) {
          await this.setState({
            reportuserDetails: reportUser.data.datas[0],
          });
        } else {
          await this.setState({
            reportuserDetails: reportUser.data.datas,
          });
        }
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if(err?.response?.data?.error != '' ){
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    await this.setState({
      reportDetails: reportDetails,
    });
  };

  hideReportModal = async () => {
    await this.setState({
      reportDetails: false,
    });
  };

  // show assign to list
  showAssignToModal = async (mid, moid) => {
    this.setState({
      mid: mid,
      moid: moid,
    });

    const assignToList = this.state.assignToModal === false ? true : false;

    const userData = localStorage.getItem("userData");

    const userDetails = JSON.parse(userData);

    await this.setState({
      assignToModal: assignToList,
    });
  };

  hideAssignToModal = async () => {
    await this.setState({
      assignToModal: false,
    });
  };

  goToReportDetails = async () => {
    setTimeout(() => {
      this.props.history.push("/supportticket");
    }, 500);
  };

  handleSelected = (selectedOption) => {
    this.setState({ selectedOption });
  };

  AssignUsers = async () => {
    const assignData = {
      pid: this.state.mid,
      moid: this.state.moid,
      supportusers: this.state.selectedOption.map((option) => option.label),
    };

    try {
      const assignApi = await axios.post(
        `${process.env.REACT_APP_API_URL}assign-to`,
        assignData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (assignApi.status === 200 && assignApi.data.status === true) {
        await this.setState({
          selectedOption: [],
        });

        toast.success(assignApi.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        await this.hideAssignToModal();
      } else {
        toast.error(
          assignApi.data.errors
            ? assignApi.data.errors
            : assignApi.data.message,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if(err?.response?.data?.error != '' ){
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  changeStatus = async (pid, moid) => {
    try {
      const closeTicket = await axios.get(
        `${process.env.REACT_APP_API_URL}change-status/${pid}/${moid}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (closeTicket.status === 200 && closeTicket.data.status === true) {
        await this.newReportsList();
        await this.hideAssignToModal();

        toast.success(closeTicket.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(
          closeTicket.data.errors
            ? closeTicket.data.errors
            : closeTicket.data.message,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if(err?.response?.data?.error != '' ){
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  componentDidMount = async () => {
    await this.supportUsers();
    await this.newReportsList();
  };

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        <section className="product-section">
          <div className="dataTable-container">
            <div className="HeadingText">
              <h1>Manage Technical Support Queries</h1>
            </div>
            <div className="downCsvDiv">
              <button
                className="downloadCsv allDet"
                onClick={this.goToReportDetails}
              >
                All Details
              </button>
              <button
                className="downloadCsv allDet"
                onClick={this.downloadSummaryReport}
              >
                Export Summary
              </button>
              <CSVLink
                data={this.state.reportsList}
                filename={"tickets.csv"}
                className="btn downloadCsv"
                target="_blank"
              >
                Export CSV
              </CSVLink>
            </div>

            <div className="dataTable">
              <DataTable
                columns={this.columns}
                data={this.state.reportsList}
                pagination
              />
            </div>

            {/* Report details Model START */}
            <div
              className={
                "creategroup-modal-container" +
                (this.state.reportDetails ? " open" : " close")
              }
            >
              <div className="creategroup-modal contactsuuplier-modal invite-modal">
                <button
                  type="button"
                  className="close-button-modal"
                  id="close-createGroup"
                  onClick={this.hideReportModal}
                >
                  <i className="fas fa-times" />
                </button>

                <div className="creategroup-header">
                  <h1 className="header">Report Details</h1>
                </div>

                <div className="creategroup-content login-content-fields invite-friends-modal">
                  <div className="contact-container">
                    <label htmlFor="message" className="name">
                      Reference ID
                    </label>

                    <p htmlFor="message" className="response">
                      {this.state.reportuserDetails.refId
                        ? this.state.reportuserDetails.refId
                        : "-"}
                    </p>
                  </div>

                  <div className="contact-container">
                    <label htmlFor="supplierRecipient" className="name">
                      Category
                    </label>
                    <p htmlFor="supplierRecipient" className="response">
                      {this.state.reportuserDetails.category
                        ? this.state.reportuserDetails.category
                        : "-"}
                    </p>
                  </div>

                  <div className="contact-container">
                    <label htmlFor="senderName" className="name">
                      Assign To
                    </label>
                    <p htmlFor="senderName" className="response">
                      {this.state.reportuserDetails.assign_to
                        ? this.state.reportuserDetails.assign_to
                        : "-"}
                    </p>
                  </div>

                  <div className="contact-container">
                    <label htmlFor="senderName" className="name">
                      Status
                    </label>
                    <p htmlFor="senderName" className="response">
                      {this.state.reportuserDetails.status
                        ? this.state.reportuserDetails.status
                        : "-"}
                    </p>
                  </div>

                  <div className="contact-container">
                    <button
                      type="button"
                      className="login-button creategroup-button"
                      id="createGroup"
                      onClick={() =>
                        this.changeStatus(this.state.mid, this.state.moid)
                      }
                    >
                      <h2>Change Status</h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Report details Model END */}

            {/* Assign to Model START */}
            <div
              className={
                "creategroup-modal-container" +
                (this.state.assignToModal ? " open" : " close")
              }
            >
              <div className="creategroup-modal contactsuuplier-modal invite-modal">
                <button
                  type="button"
                  className="close-button-modal"
                  id="close-createGroup"
                  onClick={this.hideAssignToModal}
                >
                  <i className="fas fa-times" />
                </button>

                <div className="creategroup-header">
                  <h1 className="header">Assign Support Users</h1>
                </div>

                <form action="" className="reportAssign">
                  <Select
                    className="form-select option-multi-select"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    isMulti
                    menuPosition={"fixed"}
                    value={this.state.selectedOption}
                    onChange={this.handleSelected}
                    options={this.state.options}
                  />

                  <button
                    type="button"
                    className="login-button creategroup-button invite-button"
                    id="mySupplier"
                    onClick={this.AssignUsers}
                  >
                    <h2>Assign</h2>
                  </button>
                </form>
              </div>
            </div>
            {/* Assign to Model END */}
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(ReportsList);
