import "../software/Software.css";
import "../product/Product.css";
import "../addsoftware/AddSoftware.css";
import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import ShowMoreText from "react-show-more-text";
import Select from "react-select";
import { saveAs } from 'file-saver';
import { Link, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import Parser from "html-react-parser";
import * as mime from 'react-native-mime-types';
import moment from "moment";
import ChatButton from "../../components/chatbutton/ChatButton";
import Loader from "../loader/Loader";
import chatArrow from "../../../assets/chat-arrow.png";
import EditIcon from "../../../assets/edit-icon.png";
import ButtonLoader from '../../components/loader/Loader'

import {
  PayPalButtons,
} from "@paypal/react-paypal-js";

const nodeCCAvenue = require("node-ccavenue");
const fs = require('fs')
const ccav = new nodeCCAvenue.Configure({
  merchant_id: process.env.REACT_APP_CCAVENUE_MERCHANT_ID,
  working_key: process.env.REACT_APP_CCAVENUE_LIVE_WORKING_KEY,
});


export class AddSoftwarePage extends Component {
  constructor(props) {
    super(props);

    this.Servicevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        serType: {  // name the rule
          message: 'Invalid :attribute',
          rule: (val, params, validator) => {

            var allowTypes = ['free', 'paid'];

            if (allowTypes.includes(val)) {
              return true
            } else {
              return false
            }
          },

        },
      }
    })

    this.filevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        PostFiles: {  // name the rule
          message: 'The :attribute must be a valid file types, Valid file types are ' + process.env.REACT_APP_POST_FILES.split(',')+",doc"+",docx",
          rule: (val, params, validator) => {
            let files = (val.files) ?? [];
            let retfiles = true;
            let arfiles = process.env.REACT_APP_POST_FILES.split(',');
            arfiles.push("doc","docx");
            for (let i = 0; i < files.length; i++) {
              if (arfiles.includes(files[i].name.split('.').pop()) === false) {
                retfiles = false;
              }
            }
            return retfiles
          }
        },
        Logo: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_LOGOS.split(','),
          rule: (val, params, validator) => {

            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_LOGOS.split(',')

            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },


      }
    })

    this.Updatefilevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        PostFiles: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_FILES.split(',')+",doc"+",docx",
          rule: (val, params, validator) => {
            let files = (val.files) ?? [];
            let retfiles = true;
            let arfiles = process.env.REACT_APP_POST_FILES.split(',')
            arfiles.push("doc","docx");
            for (let i = 0; i < files.length; i++) {
              if (arfiles.includes(files[i].name.split('.').pop()) === false) {
                retfiles = false;
              }
            }
            return retfiles
          }
        },
       
        Logo: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_LOGOS.split(','),
          rule: (val, params, validator) => {

            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_LOGOS.split(',')

            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },


      }
    })


    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.Updatevalidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.durationValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.promoCodeValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.InvalidValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.softwareElement = React.createRef();
    this.stlogo = React.createRef();
    this.stsoftware = React.createRef();
    this.ccavenueSoftForm = React.createRef();

    this.state = {
      isccavenuePayment: false,
      searchCondition: "",
      listing: [],
      total: 0,
      page: 0,
      nextAvailable: true,
      perPage: 6,
      loading: false,
      stlogo: "",
      stname: "",
      sft_email: "",
      sturl: "",
      stdescription: "",
      stsoftware: "",
      stduration: "0 days",
      stype: "0",
      stpaid: "",
      stcurrency: "",
      sttax: "",
      storderid: "",
      stpromocode: "",
      updateSoftware: false,
      softID: "",
      softDet: [],
      softLogo: "",
      software: "",
      isEmail: false,

      isSubmitted: false,
      amt: "",
      duration: "",
      promoCode: "",
      referenceId: "",
      encRequest: "",
      access_code: "AVBZ71EF36BA67ZBAB",
      sname: "software",
      currency: "",

      formData: "",
      isLoaded: false,
      isButtonLoding: false,
      isPublishButtonLoding: false,

      isSoftwareServiceLeftSideBar: false,
      openSoftwareServiceLeftSideBar: false,

      isReset:false,

      isValidCode: false,
      isVerified: false,
      promoErrorMsg: "",

      isRenew:false,
      freeCount:0,

      isCreated: false,
    };
  }

  AllsoftwareList = async () => {
    if (this.state.nextAvailable) {
      await this.setState({
        loading: true,
        page: this.state.page + 1,
      });

      const paginationData = {
        page: this.state.page,
        records: this.state.perPage,
        isOther: false,
      };

      try {
        const softwareList = await axios.post(
          `${process.env.REACT_APP_API_URL}getaddedsoftwares`,
          paginationData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (softwareList.status === 200 && softwareList.data.status === true) {

          const freeCount = softwareList.data.counts['count(id)'];

          this.setState({
            isLoaded: true,
            freeCount:freeCount,
          })

          if (softwareList.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }

          let oldArray = this.state.listing;
          oldArray = oldArray.concat(softwareList.data.datas);

          await this.setState({
            listing: oldArray,
            total: softwareList.data.total
          });
        } else if (softwareList.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else {
          await this.setState({
            isLoaded: true,
          })
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      await this.setState({
        loading: false,
      });
    }
  };

  uploadsoftware = async () => {

    if(this.state.freeCount >= 10) {
      this.setState({
        stype:'paid',
      })
    }

    let createSoft;
    window.scrollTo(0,0);
    this.setState({
      isButtonLoding: true
    })

    if (this.state.updateSoftware && !this.state.isRenew) {
      if (this.Updatevalidator.allValid() && this.Updatefilevalidator.allValid()) {
        const formData = new FormData(await this.softwareElement.current);

        formData.append("stdescription", this.state.stdescription);
        formData.append("stduration", "0 days");
        formData.append("status", "1");

        try {
          createSoft = await axios.post(
            `${process.env.REACT_APP_API_URL}fpservices/software/${this.state.softID
            }`,
            formData,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );

        } catch (err) {
          if (err?.response?.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != '') {
            toast.error(err?.response?.data?.error, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }

        if (typeof createSoft !== "undefined") {
          if (createSoft.status === 200 && createSoft.data.status === true) {

            this.softwareElement?.current?.reset();

            this.setState({
              stlogo: "",
              stname: "",
              sft_email: "",
              sturl: "",
              stdescription: "",
              stsoftware: "",
              stduration: "",
              stype: "",
              stpaid: "",
              stcurrency: "",
              sttax: "",
              storderid: "",
              stpromocode: "",
              softLogo: "",
              software: "",
              updateSoftware: false,
              softDet: "",
            });

            const paginationData = {
              page: this.state.page,
              records: this.state.perPage,
              isOther: false,
            };

            try {
              const softwareList = await axios.post(
                `${process.env.REACT_APP_API_URL}getaddedsoftwares`,
                paginationData,
                {
                  headers: {
                    Auth: `${localStorage.getItem("token")}`,
                  },
                }
              );

              if (
                softwareList.status === 200 &&
                softwareList.data.status === true
              ) {
                if (softwareList.data.datas.length < this.state.perPage) {
                  await this.setState({
                    nextAvailable: false,
                  });
                }

                let oldArray = this.state.listing;
                oldArray = oldArray.concat(softwareList.data.datas);

                await this.setState({
                  listing: oldArray,
                });

                await this.handleCondition(this.state.listing);
              } else if (softwareList.status === 403) {
                localStorage.removeItem("token");
                window.location = process.env.PUBLIC_URL;
              }
            } catch (err) {
              if (err?.response?.status === 403) {
                localStorage.removeItem("token");
                window.location = process.env.PUBLIC_URL;
              } else if (err?.response?.data?.error != '') {
                toast.error(err?.response?.data?.error, {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else {
                toast.error(err.message, {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }

            await this.setState({
              loading: false,
            });

            toast.success(createSoft.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            var msg = createSoft.data.errors
              ? createSoft.data.errors
              : createSoft.data.message;
            toast.error(msg, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }

        this.Updatefilevalidator.hideMessages();
        this.Updatevalidator.hideMessages();
        this.validator.hideMessages();
        this.forceUpdate();

        await this.setState({
          openSoftwareServiceLeftSideBar: false,
        });

      } else {
        this.Updatefilevalidator.showMessages();
        this.Updatevalidator.showMessages();
        this.forceUpdate();
      }
    } else {
      if ((this.validator.allValid() && this.Servicevalidator.allValid() && this.filevalidator.allValid()) || (this.state.isRenew && this.Updatevalidator.allValid() && this.Updatefilevalidator.allValid())) {

        if(this.state.isRenew) {
          this.setState({
            amt:"",
            stype:'paid',
          })
        }

        const formData = new FormData(await this.softwareElement.current);

        formData.append("stdescription", this.state.stdescription);

        const type = "sw";
        var CurrentDate = moment().unix();

        const userData = localStorage.getItem("userData")
        const userDetails = JSON.parse(userData)

        const userLoc = localStorage.getItem("userLocation");
        const userCountryDetails = JSON.parse(userLoc)

        var currency = userCountryDetails.currency;

        if (currency === "INR") {
          currency = "INR";
        } else if (currency === "EUR"){
          currency = "EUR";
        } else if (currency === "HKD"){
          currency = "HKD";
        } else if (currency === "JPY"){
          currency = "JPY";
        } else if (currency === "GBP"){
          currency = "GBP";
        } else if (currency === "AUD"){
          currency = "AUD";
        } else {
          currency = "USD";
        }


        await this.setState({
          currency: currency,
        })

        const uid = userDetails.userId;

        var referenceId = uid + "-" + type + "-" + CurrentDate;

        this.setState({
          isSubmitted: true,
          formData: formData,
          referenceId: referenceId,
        })


        this.filevalidator.hideMessages();

        this.validator.hideMessages();
        this.Servicevalidator.hideMessages();
        this.forceUpdate();


        await this.setState({
          openSoftwareServiceLeftSideBar: false,
        });

      } else {


        this.filevalidator.showMessages();

        this.validator.showMessages();
        this.Servicevalidator.showMessages();
        this.forceUpdate();
      }
    }

    this.setState({
      isButtonLoding: false,
      isReset:false,
    })

  };

  addService = async (oid, ostatus, curr, amt) => {
    if(!this.state.isccavenuePayment){
      this.setState({
      isPublishButtonLoding: true
      });
    }
    
    const formData = this.state.formData;

    if (this.state.stype === 'paid') {
      if (ostatus === 'COMPLETED') {
        ostatus = 'Success';
      }
    }
    
    formData.delete('stduration');
    formData.delete('stpaid');
    formData.delete('stcurrency');
    formData.delete('sttax');
    formData.delete('storderid');
    formData.delete('stpromocode');
    
    formData.append('stduration', this.state.duration);
    formData.append('stpaid', amt > 0 ? amt : 0);
    formData.append('stcurrency', curr ? curr : "");

    if (curr === 'INR' || curr === 'IN'){
      formData.append('stax', "18");
    } else {
      formData.append('stax', "0");
    }

    formData.append('storderid', oid ? oid : "");
    formData.append('stpromocode', this.state.promoCode);
    
    var createProduct;
    if (!this.state.isRenew) {
      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/software`,
        formData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    } else {
      
      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/software/${this.state.softID
        }`,
        formData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    }
   
    if (createProduct.status === 200 && createProduct.data.status === true) {
      
      if (this.state.stype === 'paid') {

        this.setState({
          isCreated:false,
        })

        if (this.state.isRenew) {
          this.payment(oid, ostatus, curr, this.state.softID);
        } else {
          this.payment(oid, ostatus, curr, createProduct.data.datas);
        }
      } else {

        toast.success(createProduct.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      }

      if(!this.state.isccavenuePayment){
        this.setState({
          isCreated:true,
          stlogo: "",
          stname: "",
          sft_email: "",
          sturl: "",
          stdescription: "",
          stsoftware: "",
          stduration: "",
          stype: "",
          stpaid: "",
          stcurrency: "",
          sttax: "",
          storderid: "",
          stpromocode: "",
          softLogo: "",
          software: "",
          isSubmitted: false,
          nextAvailable: true,
          page: 0,
          listing: [],
          pgimage: "",
          pgvideo: "",
          pgvideo: "",

          amt: "",
          duration: "",
          promoCode: "",
          referenceId: "",
          encRequest: "",
          currency: "",
          formData: "",
          updateSoftware:false,
          softDet:[],

        });
        await this.AllsoftwareList();
      }

      
    } else {

      toast.error(createProduct.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if(!this.state.isccavenuePayment){
      this.setState({
        isPublishButtonLoding: false
      });
    }
  }

  payment = async (oid, ostatus, curr, serID) => {

    const type = "sw";

    const data = {
      paymentType: "1",
      currency: curr,
      productType: type,
      orderStatus: ostatus,
      orderId: oid,
      serId: serID,
      promoCode: this.state.promoCode,
      renewType: this.state.isRenew ? "renew" : "new",
    };

    try {
      const payment = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/payment`,
        data,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (payment.status === 200 && payment.data.status === true) {

        this.setState({
          isCreated:true,
        })

        toast.success(payment.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      } else {

        toast.error(
          payment.data.errors ? payment.data.errors : payment.data.message,
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }

    } catch (err) {
      if (err.response.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  paymentPage = async (sid) => {
    if (!this.state.updateSoftware) {
      const passData = {
        serId: sid,
        stype: "sw",
        sname: "software",
      };

      this.props.history.push({
        pathname: `/try-premium/software/${sid}`,
        state: passData,
      });
    }
  };

  promoCodeAPI = async () => {

    if (!this.state.isVerified) {

    var data = {
      amount: this.state.amt,
      service: "sw",
      promocode: this.state.promoCode,
    };

    if (data.amount !== "") {
      this.durationValidator.hideMessages();
      this.forceUpdate();

      if (data.promocode !== "") {
        this.promoCodeValidator.hideMessages();

        try {
          const promoCode = await axios.post(
            `${process.env.REACT_APP_API_URL}fpservices/promocode`,
            data,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );

          if (promoCode.status === 200 && promoCode.data.status === true) {
            await this.setState({
              amt: promoCode.data.datas,
              isVerified:true,
              isValidCode: false,
            });

            toast.success(promoCode.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {

            this.setState({
              isVerified:false,
              isValidCode: true,
              promoErrorMsg: promoCode.data.errors ? promoCode.data.errors : promoCode.data.message,
            })

            // toast.error(
            //   promoCode.data.errors
            //     ? promoCode.data.errors
            //     : promoCode.data.message,
            //   {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //   }
            // );
          }
        } catch (err) {
          if (err.response.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != '') {
            // toast.error(err?.response?.data?.error, {
            //   position: "top-right",
            //   autoClose: 4000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          } else {
            // toast.error(err.message, {
            //   position: "top-right",
            //   autoClose: 4000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          }
        }
      } else {
        this.promoCodeValidator.showMessages();
        this.forceUpdate();
      }
    } else {
      this.durationValidator.showMessages();
      this.forceUpdate();
    }
  } else {
    toast.error(
      "Promocode already applied",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
  };

  editSoftware = async (softId, type) => {

    if (type === "renew") {
      this.setState({
        isRenew:true,
      })
    } else {
      this.setState({
        isRenew:false,
      })
    }

    this.Servicevalidator.hideMessages();
    this.Updatefilevalidator.hideMessages();
    this.filevalidator.hideMessages();
    this.Updatevalidator.hideMessages();
    this.validator.hideMessages();
    this.forceUpdate();

    this.setState({
      isReset:true,
      updateSoftware: true,
      stlogo: "",
      stsoftware: "",
    });

    if (typeof softId !== "undefined") {
      const updateSoftware =
        this.state.updateSoftware === false ? true : this.state.updateSoftware;

      this.setState({
        updateSoftware: updateSoftware,
        softID: softId,
      });
    }

    try {
      const particularsoftwareDetail = await axios.get(
        `${process.env.REACT_APP_API_URL}software/getsoftware/${softId}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (
        particularsoftwareDetail.status === 200 &&
        particularsoftwareDetail.data.status === true
      ) {
        this.setState({
          softDet: particularsoftwareDetail.data.datas,
        });


        this.setState({
          stname: this.state.softDet.name,
          sft_email: this.state.softDet.sft_email,
          sturl: this.state.softDet.url,
          stdescription: this.state.softDet.description,

          softLogo: this.state.softDet.logo,
          amt: this.state.softDet.amount,
        });
      } else {
        this.state = {
          stlogo: "",
          stname: "",
          sft_email: "",
          sturl: "",
          stdescription: "",
          stsoftware: "",
          stduration: "",
          stype: "",
          stpaid: "",
          stcurrency: "",
          sttax: "",
          storderid: "",
          stpromocode: "",
        };
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    await this.setState({
      openSoftwareServiceLeftSideBar: true,
    });

  };

  resetData = () => {
    this.setState({
      isReset:false,
      stlogo: "",
      stname: "",
      sft_email: "",
      sturl: "",
      stdescription: "",
      stsoftware: "",
      softDet:[],
      softLogo:'',
    });
  };

  handleCondition = async (conditionData) => {
    await this.setState({
      searchCondition: conditionData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.searchCondition !== this.state.searchCondition) {
      await this.AllsoftwareList();

      await this.setState({
        loading: false,
      });
    }
  }
  handleSelected = async (e) => {
    await this.setState({
      stype: e.value?.replace(/^\s+/g, ''),
    });
  }
  
  handleInputChange = async (e) => {
    if (typeof e === "string") {
      this.setState({
        stdescription: (e.replace(/^\s+/g, '') === '<p>&nbsp;&nbsp;</p>') ? '' : e.replace(/^\s+/g, ''),
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
      });

      if (e.target.name === "stlogo") {
        this.setState({
          softLogo: "",
        });
      }

      if (e.target.name === "stsoftware") {
        this.setState({
          software: "",
        });
      }

      if (e.target.name === "sft_email" && e.target.value?.replace(/^\s+/g, '') !== "") {
        this.setState({
          isEmail: true,
        });
      }

      if (e.target.files) {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = async function (event) {
          // The file's text will be printed here
          if (e.target.name === "stlogo") {
            await this.setState({
              softLogo: event.target.result,
            });
          }

          if (e.target.name === "stsoftware") {
            await this.setState({
              software: event.target.result,
            });
          }
        }.bind(this);

        reader.readAsDataURL(file);
      }

      if (e.target.name === "duration") {

        var threeMonths;
        var sixMonths;
        var twelveMonths;

        var currency = this.state.currency;

        if (currency === "INR") {
          threeMonths = 6000;
          sixMonths = 9600;
          twelveMonths = 14400;
        }

        else if (currency === "USD") {
          threeMonths = 300;
          sixMonths = 480;
          twelveMonths = 720;
        }

        else if (currency === "EUR") {
          threeMonths = 300;
          sixMonths = 480;
          twelveMonths = 720;
        }

        else if (currency === "HKD") {
          threeMonths = 1500;
          sixMonths = 2400;
          twelveMonths = 3600;
        }

        else if (currency === "JPY") {
          threeMonths = 30000;
          sixMonths = 48000;
          twelveMonths = 72000;
        }

        else if (currency === "GBP") {
          threeMonths = 225;
          sixMonths = 360;
          twelveMonths = 540;
        }

        else if (currency == "AUD") {
          threeMonths = 300;
          sixMonths = 480;
          twelveMonths = 720;
        }

        else {
          threeMonths = 300;
          sixMonths = 480;
          twelveMonths = 720;
        }

        if (e.target.value?.replace(/^\s+/g, '') === "3") {
          await this.setState({
            amt: threeMonths, //parseFloat(4000 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else if (e.target.value?.replace(/^\s+/g, '') === "6") {
          await this.setState({
            amt: sixMonths, //parseFloat(6400 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else if (e.target.value?.replace(/^\s+/g, '') === "12") {
          await this.setState({
            amt: twelveMonths, //parseFloat(9600 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else {
          await this.setState({
            amt: "",
          });
        }

        this.setState({
          promoCode:"",
          isVerified:false,
          isValidCode: false,
        })
      }
    }
  };

  componentDidMount = async () => {
    await this.handleResize();

    this.setState({
      loading: true,
    });

    await this.AllsoftwareList();

    window.addEventListener("scroll", this.listenToScroll);

    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isSoftwareServiceLeftSideBar: true,
        openSoftwareServiceLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isSoftwareServiceLeftSideBar: false,
      });
    }
  };

  listenToScroll = async () => {
    if (this.state.loading === false) {
      const per = (document.documentElement.offsetHeight * 10) / 100;
      if (
        document.documentElement.offsetHeight - per <=
        document.documentElement.scrollTop + window.innerHeight
      ) {
        await this.AllsoftwareList();
      }
    }
  };

  ccavBtn = async () => {
    if (this.state.duration === "") {
      toast.error(`Duration should not be empty`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false
    } else {
      const userName = localStorage.getItem("userName")
    
      const orderParams = {
        order_id: this.state.referenceId,
        currency: this.state.currency,
        amount: this.state.amt,
        redirect_url: encodeURIComponent(
          `https://pexit.net/node_api/api/ccavenue`,
        ),
        billing_name: userName,
      };
      const encryptedOrderData = ccav.getEncryptedOrder(orderParams);
      await this.setState({
        encRequest: encryptedOrderData,
        isccavenuePayment: true
      });

      await this.addService(this.state.referenceId, 'awaited', this.state.currency, this.state.amt)

      // if(this.state.isCreated) {
        this.ccavenueSoftForm.current.submit();
      // }
      
    }
  }

  showSoftwareServiceLeftSideBar = async () => {
    if (this.state.openSoftwareServiceLeftSideBar) {
      await this.setState({
        openSoftwareServiceLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openSoftwareServiceLeftSideBar: true,
      });
    }
  };

  render() {
    if (!this.state.isSubmitted) {
      return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
            toastClassName="dark-toast"
          />

          <section className="product-section">
            {(this.state.isSoftwareServiceLeftSideBar || this.state.openSoftwareServiceLeftSideBar) &&
              <div className="left-sidebar">
                <Link to={"/"}>
                  <div className="chat-arrow">
                    <img src={chatArrow} alt="chat-arrow" />
                  </div>
                </Link>

                <div className="sidebar-container">
                  <div className="header-content">
                    <h2 className="header">Upload Software</h2>
                  </div>

                  <form
                    method="post"
                    ref={this.softwareElement}
                    encType="multipart/form-data"
                  >
                    <div className="content-container">

                      {!this.state.updateSoftware ?
                        <>
                          <div className="sidebar-content">
                            <div className="header-content">
                              <h3 className="header">Offer Type</h3>
                            </div>
                            <div className="caret-content">
                              {/* <div className="select-caret">
                                <i className="fas fa-caret-down" />
                              </div>
                              <select
                                className="form-select"
                                name="stype"
                                aria-label="Default select example"
                                value={this.state.stype}
                                onChange={this.handleInputChange}
                              >
                                <option value="0" defaultValue>
                                  Select SerOffervice Type
                                </option>
                                <option value="free">Free </option>
                                <option value="paid">Paid </option>
                              </select> */}

                            {this.state.freeCount >= 10 ? 
                              <Select 
                                className="caret-content-select"
                                placeholder="Select Offer type"
                                options={[
                                  {value:"paid",label:"Paid "}
                                ]}
                                name="stype"
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                                onChange={this.handleSelected}
                                menuPosition={"fixed"}
                              />
                              : 
                              <Select 
                                className="caret-content-select"
                                placeholder="Select Offer type"
                                options={[
                                  {value:"free",label:"Free "},
                                  {value:"paid",label:"Paid "}
                                ]}
                                name="stype"
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                                onChange={this.handleSelected}
                                menuPosition={"fixed"}
                                />
                              }
                            </div>

                            {this.Servicevalidator.message(
                              "Offer type",
                              this.state.stype,
                              "serType"
                            )}
                          </div>
                        </> :
                        <>
                          <input
                            type="hidden"
                            name="stpaid"
                            className="input-field field"
                            value={this.state.amt}
                          />
                          <input
                            type="hidden"
                            name="stype"
                            className="input-field field"
                            value={this.state.stype}
                          />
                        </>
                      }

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Upload Logo</h3>
                        </div>

                        <input
                          type="file"
                          className="input-field field"
                          name="stlogo"
                          ref={this.stlogo}
                          accept="image/*"
                          onChange={(e) => {
                            this.setState({
                              new_stlogo: e.target
                            })
                            this.filevalidator.allValid()
                          }}
                        // value={this.state.stlogo}
                        />
                        {this.filevalidator.message(
                          "software logo",
                          this.state?.new_stlogo,
                          "required|Logo"
                        )}

                        {this.Updatefilevalidator.message(
                          "software logo",
                          this.state?.new_stlogo,
                          "Logo"
                        )}

                        <div
                        className={this.state.softLogo ? "editImgDiv" : "dnone"}
                      >
                        <img
                          className={this.state.softLogo ? "editImg" : "dnone"}
                          src={this.state.softLogo}
                          alt="software-logo"
                        />
                      </div>

                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Software Name</h3>
                        </div>

                        <input
                          type="text"
                          className="input-field field"
                          placeholder="Software Name"
                          name="stname"
                          onChange={this.handleInputChange}
                          value={this.state.stname}
                        />

                        {this.validator.message(
                          "software name",
                          this.state.stname,
                          "required|string"
                        )}

                        {this.Updatevalidator.message(
                          "software name",
                          this.state.stname,
                          "required|string"
                        )}
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Email ID</h3>
                        </div>

                        <input
                          type="text"
                          className="input-field field"
                          placeholder="Email ID"
                          name="sft_email"
                          onChange={this.handleInputChange}
                          value={this.state.sft_email}
                        />

                        {this.validator.message(
                          "email",
                          this.state.sft_email,
                          "email"
                        )}

                        {this.Updatevalidator.message(
                          "email",
                          this.state.sft_email,
                          "email"
                        )}
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Description</h3>
                        </div>

                        <Editor
                          apiKey="textarea"
                          name="stdescription"
                          value={this.state.stdescription}
                          init={{
                            height: 200,
                            menubar: false,
                          }}
                          onEditorChange={this.handleInputChange}
                        />

                        {this.validator.message(
                          "description",
                          this.state.stdescription,
                          "required|string"
                        )}

                        {this.Updatevalidator.message(
                          "description",
                          this.state.stdescription,
                          "required|string"
                        )}
                      </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Download Link</h3>
                        </div>

                        <input
                          type="text"
                          className="input-field field"
                          placeholder="Download Link"
                          name="sturl"
                          onChange={this.handleInputChange}
                          value={this.state.sturl}
                        />

                        {this.validator.message(
                          "download link",
                          this.state.sturl,
                          "url"
                        )}

                        {this.Updatevalidator.message(
                          "download link",
                          this.state.sturl,
                          "url"
                        )}
                      </div>

                      <div className="sidebar-content">
                          <h3 className="header">OR</h3>
                        </div>

                      <div className="sidebar-content">
                        <div className="header-content">
                          <h3 className="header">Upload Software</h3>
                        </div>

                        <input
                          type="file"
                          className="input-field field"
                          name="stsoftware"
                          ref={this.stsoftware}
                          accept="audio/*,video/*,image/*,.doc,.docx,.pdf,.ppt,.pptx,.exe,.EXE,.zip,.ZIP"
                          onChange={(e) => {
                            this.setState({
                              new_stsoftware: e.target
                            })
                            this.filevalidator.allValid()
                            this.Updatefilevalidator.allValid()
                          }}
                        // value={this.state.stsoftware}
                        />

                        {this.filevalidator.message(
                          "File",
                          this.state?.new_stsoftware,
                          "required|PostFiles"
                        )}

                        {this.Updatefilevalidator.message(
                          "File",
                          this.state?.new_stsoftware,
                          "PostFiles"
                        )}

                        <div className="d-flex d_preview_images">
                          {
                            (Array.isArray(this.state?.softDet?.software)) ? this.state?.softDet?.software.map((img, key) => {
                              return <div className="image-area" key={key} id={img} style={{ width: "20%", marginTop: "10%" }} >
                                <a href={img} target={'_blank'} onClick={(e) => {
                                  e.preventDefault()
                                  console.log(img)
                                  saveAs(img, !!img ? img.split('/').pop() : '')
                                }} >
                                  <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                                </a>
                                
                                {/* <img src={process.env.PUBLIC_URL + '/images/docu.png'} target={'_blank'} alt="" onClick={(e) => {
                                  e.preventDefault()
                                  console.log(img)
                                  saveAs(img, !!img ? img.split('/').pop() : '')
                                }} /> */}
                              </div>
                            }) : ""
                          }
                        </div>

                      </div>

                      <div className="sidebar-content software-btns">
                      <div className="sidebar-button">
                        {/*this.state.isReset &&
                          
                            <button
                              type="button"
                              className="reset-button resetSubmit"
                              onClick={this.resetData}
                            >
                              Reset
                            </button>
                          
                        */}
                        {!this.state.isReset ? 
                         
                          <button
                            type="button"
                            className="reset-button"
                            onClick={this.uploadsoftware}
                            disabled={this.state.isButtonLoding}
                          >
                            {this.state.isButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Submit'}
                          </button>
                        
                       :
                        
                          <button
                            type="button"
                            className="reset-button"
                            onClick={this.uploadsoftware}
                            disabled={this.state.isButtonLoding}
                          >
                            {this.state.isButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Update'}
                          </button>
                        
                      }
                      </div>
                        
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }

            <div className="product-container">
              <div className="product-box-container">
                <div className="product-boxes">
                  {(!this.state.openSoftwareServiceLeftSideBar && !this.state.isSoftwareServiceLeftSideBar) &&
                    <div className="SoftwareService-hamburger-menu" id="mycircle-hamburger">
                      <i class="fa fa-bars" aria-hidden="true" onClick={this.showSoftwareServiceLeftSideBar}></i>
                    </div>
                  }
                  {(this.state.openSoftwareServiceLeftSideBar && !this.state.isSoftwareServiceLeftSideBar) &&
                    <div className="SoftwareService-hamburger-menu" id="mycircle-hamburger">
                      <i class="fa fa-times" aria-hidden="true" onClick={this.showSoftwareServiceLeftSideBar}></i>
                    </div>
                  }
                  <div className="product-header">
                    <h1 className="header">Active / Expired Software</h1>
                    {this.state.isLoaded &&
                      <h2>Total {this.state.total}</h2>
                    }
                  </div>

                  {this.state.isLoaded &&
                    <div className="boxes-container software-container">
                      {this.state.listing.length > 0 ? (
                        this.state.listing.map((lists, key) => (
                          <div className="product-boxes-content software-boxes-content" key={key}>
                            <Link to={`/software-detail/${lists.id}`}>
                              <div className="product-image software-image">
                                <img src={lists.logo} alt="" />
                              </div>
                            </Link>

                            <div className="product-info software-info">
                              <div className="product-name">
                                <h2 className="header">{lists.name}</h2>
                              </div>
                              <div className="product-place software-content">
                                <h2 className="header grey-header truncate">
                                  <ShowMoreText
                                    /* Default options */
                                    lines={2}
                                    more="Read more"
                                    less="Read less"
                                    className="content-css"
                                    anchorClass="my-anchor-css-class"
                                    onClick={this.executeOnClick}
                                    expanded={false}
                                    truncatedEndingComponent={"... "}
                                  >
                                    {Parser(lists.description)}
                                  </ShowMoreText>
                                </h2>
                              </div>
                              <div className="product-name">
                                <h2 className="header grey-header">Status : {lists.state === 0 ? "Expired" : "Active"}</h2>
                              </div>

                            </div>


                            {(lists.state === 0 && lists.stype !== 'free') ? (
                              <div className="product-edit">
                                <button
                                  type="button"
                                  className="product-email-button renewPro"
                                  onClick={() =>
                                    this.editSoftware(lists.id, 'renew')
                                  }
                                >
                                  Renew
                                </button>
                              </div>
                              ): (lists.state === 1) ? (

                              <div className="product-edit">
                                <button
                                  type="button"
                                  className="product-email-button"
                                  onClick={() => this.editSoftware(lists.id, 'type')}
                                >
                                  <img src={EditIcon} alt="EditIcon" />
                                </button>
                              </div>
                              ) : ""
                            }

                          </div>
                        ))
                      ) : (
                        <h2>You haven't added your software</h2>
                      )}
                    </div>
                  }
                </div>
                {this.state.loading && <Loader />}
              </div>
            </div>
          </section>

          <ChatButton />
        </>
      );

    } else {
      return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
            toastClassName="dark-toast"
          />
          <section className="product-section">
            {/* CC Avenue START */}
            <form ref={this.ccavenueSoftForm} method="post" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"> 
              <input type="hidden" name="encRequest" value={this.state.encRequest} />
              <input type="hidden" name="access_code" value={this.state.access_code} />
            </form>
            {/* CC Avenue END */}
            <div className="product-container softwares-container">
              <div className="premium-container">
                <div className="parentDiv">
                  <label>Price</label>
                  <span className="labinsep">:</span>

                  {(this.state.stype === 'free') ? <span id="pays">Free</span> :

                    <>
                      <span>

                        {this.state.currency} <span id="pays">{this.state.amt}</span>

                      </span>
                    </>
                  }
                </div>

                <div className="parentDiv">
                  <label>Duration</label>
                  <span className="labinsep">:</span>
                  <select
                    id="sftdur"
                    name="duration"
                    value={this.state.duration}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Select Duration</option>

                    {(this.state.stype === 'free') ?

                      <option value="12 months">12 Months</option>

                      :
                      <>
                        <option value="3">3 Months</option>
                        <option value="6">6 Months</option>
                        <option value="12">12 Months</option>
                      </>

                    }
                  </select>

                  {this.durationValidator.message(
                    "Please Select Duration",
                    this.state.duration,
                    "required|string"
                  )}
                </div>

                {(this.state.stype === 'paid') ?
                  <>
                    <div className="parentDiv">
                      <label>Have a Promo Code? : </label>
                      <input
                        type="text"
                        id="prmcde"
                        name="promoCode"
                        value={this.state.promoCode}
                        onChange={this.handleInputChange}
                      />
                      <a id="apprm" onClick={this.promoCodeAPI} href="javascript:void(0);">
                        Apply
                      </a>

                      {this.state.promoCode !== "" && this.state.isValidCode ?
                        <div class="srv-validation-message"> {this.state.promoErrorMsg}</div>
                        : ""}

                      {this.promoCodeValidator.message(
                        "Please Enter Promocode",
                        this.state.promoCode,
                        "required|string"
                      )}

                      {this.InvalidValidator.message(
                        "Invalid Promocode",
                        this.state.promoCode,
                        "required|string"
                      )}
                    </div>
                  </>
                  : ""}

                <div>
                  {(this.state.stype === 'free') ?
                    <button type="submit" className="checkoutBtn ccavaenueBtn" onClick={() => this.addService()}>
                       {this.state.isPublishButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Publish'}
                    </button>

                    :
                    <>
                      {(this.state.currency !== "INR") ?
                      <>
                        <PayPalButtons
                          style={{ layout: "horizontal", color:"black", label:"checkout", tagline: false }}
                          className="paypalBtn"
                          createOrder={(data, actions) => {
                            return actions.order.create({
                              intent: "CAPTURE",
                              purchase_units: [
                                {
                                  reference_id: this.state.referenceId,
                                  amount: {
                                    value: this.state.amt,
                                    currency_code: this.state.currency,
                                  },
                                },
                              ],
                            });
                          }}

                          onClick={() => {
                            if (this.state.duration === "") {
                              toast.error(`Duration should not be empty`, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                              return false
                            }
                          }}
                          onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {

                              this.setState({
                                loading: true,
                                listing: [],
                                isSubmitted: false,
                              });

                              this.addService(
                                this.state.referenceId,
                                details.status,
                                details.purchase_units[0].amount.currency_code,
                                details.purchase_units[0].amount.value,
                              )

                            });
                          }}
                          onCancel={(data) => {

                            // Show a cancel page, or return to cart
                            toast.error(`Transaction cancelled`, {
                              position: "top-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }}

                          onError={(err) => {
                            if (this.state.currency === "INR") {
                              toast.error(`Paypal won't accept ${this.state.currency} please change the currency`, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                            }
                          }
                          }
                        />
                       </> :
                       <>

                        <button type="button" onClick={() => this.ccavBtn()} className="checkoutBtn ccavaenueBtn" >
                          Checkout
                        </button>
                     
                      {/* CC Avenue END */}

                       </>
                      }
                    </>
                  }
                </div>

                {(this.state.stype === 'paid') ?
                  <>
                    <div id="chcknte">
                      <p>
                        <b>Note: </b>
                      </p>
                      <p>
                        Please note down the reference number{" "}
                        <b>{this.state.referenceId}</b> for this transaction.
                      </p>
                      <p>
                        If the payment process is unsuccessful, then check the status
                        of the payment through "Transaction History" available under
                        "Paid Services" menu. If the status field corresponding to
                        your transaction reference number is "Null" or "Empty", then
                        retry with checkout again.
                      </p>
                      <p>
                        <span className="noteHeading">
                          Price shown above is exclusive of applicable Taxes and
                          Levies.{" "}
                        </span>
                      </p>
                    </div>
                  </> : ""}
              </div>
            </div>
          </section>
        </>
      );
    }
  }
}

export default withRouter(AddSoftwarePage);
