import React, { Component } from 'react'
import "./Layout01.css"
import { Link, withRouter } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import defaultImg from "../../../../assets/un.png"
import threeDots from "../../../../assets/3-dots.png";
import { Report } from "../../report/Report";

export default class Layout01 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            imgsrc: defaultImg,
            description:"",

            post:[],
            showMenuListing: "",
            reportModal: false,
            ReportPostId: 0,
            reportModule: "ads",
            showMenuId: "",
        };
    }

    componentDidMount = async () => {

        const getAdvertisement = this.props.singleAd;

        await this.setState({
            title: getAdvertisement.title,
            imgsrc: getAdvertisement.image.length > 0 ? getAdvertisement.image[0] : defaultImg,
            description: getAdvertisement.post,
            post:getAdvertisement,
        })

    }

    // menulisting
    showMenu = (id, postUid) => {
        const showMenuListing = this.state.showMenuListing === id ? "" : id;

        this.setState({
            showMenuListing: showMenuListing,
            showMenuId: id,

            showFriendPostId: postUid,
        });
    };

    // report module
    reportModal = async (post, uid) => {
        const reportModal = this.state.reportModal === false ? true : false;
        await this.setState({
            showFriendPostId: "",
            showMenuListing: "",
            reportModal: reportModal,
            ReportPostId: uid,
        });
      };
    
    hideReportModal = async () => {
        await this.setState({
            reportModal: false,
        });
    };

    render() {
        return (
            <>
            {this.state.reportModal ? (
                <Report
                  hideReportModal={this.hideReportModal}
                  softwareId={this.state.showMenuId}
                  softwareUid={this.state.ReportPostId}
                  module={this.state.reportModule}
                />
              ) : (
                ""
            )}
            <div className="layout01_outer_div">
            {/* <Link to={`//${this.state.post?.url}`}> */}
            <a
                href={
                    /^((http|https|ftp):\/\/)/.test(this.state.post?.url)
                    ? this.state.post?.url
                    : `//${this.state.post?.url}`
                }
                target="_blank"
            >
                <div className="profile-content">
                    <div>
                        <h5>Advertisement :</h5>
                    </div>
                    <div className="menu-container">
                        <div className="menu-dots">
                            <img
                                src={threeDots}
                                alt="threeDots"
                                onClick={() =>
                                this.showMenu(this.state.post.id, this.state.post.uid)
                                }
                            />
                        </div>

                        <div
                            className={
                            "user-menu-content others-menu-content" +
                            (this.state.showMenuListing === this.state.post.id
                                ? " open"
                                : "")
                            }
                        >
                            <h2
                            className="header"
                            onClick={() =>
                                this.reportModal("ads", this.state.post.uid)
                            }
                            >
                            Report Abuse
                            </h2>
                        </div>

                        </div>
                </div>
                <div className="ad_layout01">
                    <div className="layout01-title">
                        <h3>{this.state.title}</h3>
                    </div>

                    {(() => {
                        if (!!this.state.post?.youtube?.length > 0) {
                            
                            var utub = this.state.post.youtube;
                            utub = utub.split(",")
                            utub = utub[0].replace('watch?v=','embed/')
                            return (

                            <div className="layout-image">
                                
                                <iframe width="100%" height="300px" src={utub} allowFullScreen="allowfullscreen"></iframe>
                                   
                            </div>

                            )
                        }

                        else if (!!this.state.post?.image?.length > 0) {

                            var imgsrc = this.state.post?.image?.length > 0 ? this.state.post?.image[0] : defaultImg;
                            return (

                            <div className="layout01-image">
                                <img className="layout01-image-path" src={imgsrc} alt="image"/>
                            </div>
                            )
                        }

                        else {
                            return ("")
                        }
                        
                        
                    })()}
                    
                    <div className="layout01-des">
                        <ShowMoreText
                            /* Default options */
                            lines={10}
                            more="Read more"
                            less="Read less"
                            className="content-css"
                            anchorClass="my-anchor-css-class"
                            expanded={false}
                            truncatedEndingComponent={"... "}
                            color="red"
                            >
                            <p 
                                dangerouslySetInnerHTML={{ 
                                    __html: this.state.description
                                }}
                            />
                           
                        </ShowMoreText>
                    </div>
                </div>
                {/* </Link> */}
                </a>
            </div>

            </>
        )
    }
}