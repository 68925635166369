import React, { Component } from "react";
import queryString from "query-string";
import axios from "axios";
import {
  Redirect
} from "react-router-dom";
var qs = require('qs');

export class Linkedin extends Component {
    constructor(props) {
      super(props);
    }

    componentDidMount = async () => {

        const queries = queryString.parse(this.props.location.search);
        
        let userProfile = []
        const config = {
          headers: {
            "Auth": `${queries.code}`,
          }
        }

        let accessToken = "";
        try {
          
          var data = qs.stringify({
            'grant_type': 'authorization_code',
            'code': queries.code,
            'redirect_uri': `${window.location.origin}/linkedin`,
            'client_id': process.env.REACT_APP_REACT_LINKEDIN_CLIENT_ID,
            'client_secret': process.env.REACT_APP_REACT_LINKEDIN_CLIENT_SECRET 
          });

          const result = await axios({
            method: "POST",
            url: 'https://www.linkedin.com/oauth/v2/accessToken',
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            },
            data: data
          });

          accessToken = result.data.access_token;
        } catch (e) {
          console.log(e)
        }

        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
    
        try {
          const result = await axios({
            method: "GET",
            url: 'https://api.linkedin.com/v2/me?projection=(id,localizedFirstName,localizedLastName,profilePicture(displayImage~digitalmediaAsset:playableStreams))',
            headers: headers
          });
    
          userProfile.firstName = result.data["localizedFirstName"];
          userProfile.lastName = result.data["localizedLastName"];
          userProfile.profileImageURL = result.data.profilePicture["displayImage~"].elements[0].identifiers[0].identifier;
        } catch (err) {
          console.log(err)
        }
    
        try {
          const result = await axios({
            method: "GET",
            url: 'https://api.linkedin.com/v2/clientAwareMemberHandles?q=members&projection=(elements*(primary,type,handle~))',
            headers: headers
          });
    
          userProfile.email = result.data.elements[0]["handle~"];
    
        } catch (err) {
          // console.log(err)
        }
    
        // All done.
        return userProfile;
        
        
    }

  render() {
    return (
      <>
        <section className="pricing-section">
          <div className="about-section-content">
            "hello"
          </div>
        </section>
      </>
    );
  }
}

export default Linkedin;
