import React, { Component } from "react";
import "./Support.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";

export class Support extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.state = {
      inviteFriends: false,
      supplierRecipient: "",
      senderName: "",
      senderEmail: "",
      subject: "Invite to Join PEXit",
      message: `Hi,

            PEXit integrates the best features of Professional Media and e-Commerce Facilitator. It helps to communicate, share, post, search, manage teams and projects, buy and sell goods, services and software. In effect, PEXit, the Professional EXchange for industries and technologies, caters to the requirements of professionals, entrepreneurs, groups, retailers, suppliers, manufacturers, industrial plants, corporations and governmental agencies. Even students, teachers and communities can use PEXit to carry out assignments and projects.
            
            You can download PEXit using the link https://play.google.com/store/apps/details?id=net.pexit.app&hl=en
            
            You can also access PEXit using the link https://www.pexit.net from any browser on any device.
            
            Regards,
            
            `,
      supplierId: "",
    };
  }

  handleInputChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });
  };

  // invite friends
  showInviteModal = async (e) => {
    e.preventDefault();

    const inviteFriends = this.state.inviteFriends === false ? true : false;

    const userData = localStorage.getItem("userData");

    const userDetails = JSON.parse(userData);

    await this.setState({
      inviteFriends: inviteFriends,
      senderName: userDetails.userName,
      senderEmail: userDetails.userEmail,
    });
  };

  hideInviteModal = async () => {
    await this.setState({
      inviteFriends: false,
    });
  };

  inviteFriendsApi = async () => {
    if (this.validator.allValid()) {
      const supplierData = {
        name: this.state.senderName,
        email: this.state.senderEmail,
        subject: this.state.subject,
        friendsEmail: this.state.supplierRecipient,
        mailContent: this.state.message,
      };

      try {
        const formres = await axios.post(
          `${process.env.REACT_APP_API_URL}invitefriends`,
          supplierData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (formres.status === 200) {
          if (formres.data.status === true)
            toast.success(formres.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        } else {
          toast.error(formres.data.errors, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if(err?.response?.data?.error !== '' ){
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      this.hideInviteModal();

      this.setState({
        supplierRecipient: "",
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />
        <section className={`about-section ${(localStorage.getItem('token') ? 'after-login-section' : '')}`}>
          <div className="about-section-header">
            <h1 className="header">Support</h1>
          </div>
          <div className="about-section-content">
            <div className="item_fulltext">
              <p>
                <span>Frequently Asked Questions (FAQs)</span>
              </p>
              <p>
                The most frequently asked questions are covered below. For
                questions that are not covered below, please contact us at&nbsp;
                <a href="mailto:support@pexit.net" className="line">
                  support@pexit.net
                </a>
                .
              </p>
              <p>
                <span>
                  1. How is PEXit different from other similar platforms?
                </span>
              </p>
              <p className="text">
                PEXit integrates the functionalities of social media,
                professional media and e-commerce; it is designed for B2B
                (Business-to-Business) and B2C (Business-to-Consumer) users of
                varied profile to
              </p>

              <p className="text">
                <span>CONNECT</span> with their peers, associates, partners,
                customers…,
              </p>
              <p className="text">
                <span>COMMUNICATE</span> with them by messaging, sharing photos,
                videos, drawings, reports, presentations…,
              </p>
              <p className="text">
                <span>COLLABORATE</span> with team members of projects by
                forming user specified groups,
              </p>
              <p className="text">
                <span>CONSUME CONTENT</span> posted on yellow pages and other
                postings,
              </p>
              <p className="text">
                <span>CONDUCT COMMERCE</span> on products, services, software
                etc., and
              </p>
              <p className="text">
                <span>CHOOSE CAREER</span> by posting résumés and searching job
                openings.
              </p>
              <p className="text">
                It has multi-lingual functionality inclusive of translation
                making it truly global and breaks the barriers of communication
                across geographic and time zones.
              </p>
              <p className="text">
                In short, PEXit is for use by professionals, entrepreneurs,
                micro/small/medium enterprises, retailers, suppliers,
                manufacturers, industrial facilities and governments. Companies
                worldwide can use PEXit for their inter-departmental sharing of
                data, drawings and documents, project management, communication
                with suppliers and clients, sales &amp; marketing activities, HR
                recruitment etc.
              </p>
              <p className="text">
                <strong>
                  Its usage extends beyond B2B and B2C transactions; even
                  friends, families, students, teachers and communities can use
                  it with ease to share and carry out assignments and events.
                </strong>
              </p>
              <p className="text">
                Some of the key features of PEXit are:
                <br />
                •&nbsp;&nbsp;&nbsp; An integrated Application that can be used
                as a professional media, e-commerce facilitator and even as a
                social media
                <br />
                •&nbsp;&nbsp;&nbsp; Create numerous Groups within an entity and
                between entities to manage business activities discipline-wise,
                project-wise, client-wise etc.
                <br />
                •&nbsp;&nbsp;&nbsp; Have multiple threads of conversations
                within each Group
                <br />
                •&nbsp;&nbsp;&nbsp; Create open Forums for products, software,
                services, special topics etc.
                <br />
                •&nbsp;&nbsp;&nbsp; “Nested search” (i.e., search for people,
                products, services, software etc. within previously searched
                results)
                <br />
                •&nbsp;&nbsp;&nbsp; Attach multiple files of diverse types with
                each file size up to 25 MB
                <br />
                •&nbsp;&nbsp;&nbsp; Create and manage
                product/service/organization websites within PEXit
                <br />
                •&nbsp;&nbsp;&nbsp; Has a built-in multi-way language
                translator, by which a user can post in one language and other
                users around the world can translate to their respective
                languages.
                <br />
                •&nbsp;&nbsp;&nbsp; Download native apps for Android and iOS
                mobiles from PlayStore &amp; AppStore respectively
                <br />
                •&nbsp;&nbsp;&nbsp; Browser version (www.pexit.net) accessible
                on all devices including Windows-based laptops, desktops etc.
                <br />
                •&nbsp;&nbsp;&nbsp; Hosted securely on Cloud VPS that commits
                99.95% availability
                <br />
                •&nbsp;&nbsp;&nbsp; Designed to work on "closed, private clouds”
                of corporations and governmental agencies.
              </p>
              <p>
                <span>2. Is there a Presentation on PEXit?</span>
              </p>
              <p>
                Yes, for an Overview of PEXit,&nbsp;
                <a
                  href="./images/Presentation/PEXit_Intro.html"
                  className="line"
                  target="_blank"
                >
                  click here
                </a>
                . For features of PEXit, view&nbsp;
                <a
                  href="./images/Presentation/PEXitPresentation.pdf"
                  target="blank"
                  className="line"
                >
                  PDF&nbsp;
                </a>
                or&nbsp;
                <a
                  href="./images/Presentation/PEXit_Presentation.html"
                  target="blank"
                  className="line"
                >
                  Video
                </a>
                .
              </p>
              <p>
                <span>3. How can I join into PEXit?</span>
              </p>
              <p>
                You can directly join into PEXIt by filling the form available through 
                “Join Now > Create New Account” of PEXit and press the button “Join
                Now”. PEXit sends you an email (check Inbox/Spam folder) with
                activation link to the email id entered during the registration
                (thereby validating that email id). By clicking on the
                activation link, the user will be able to log into PEXit by
                entering the User Name and Pasword in PEXit Log In area.
              </p>
              <p>
                <span>
                  4. Can I join PEXit using my existing third party accounts?
                </span>
              </p>
              <p>
                Yes, you can join and sign into PEXit using your existing third
                party accounts such as Facebook/Gmail/LinkedIn. Simply click the
                Facebook/Gmail/LinkedIn button shown under "Join Now > Social Signup" Page of PEXit and
                follow the instructions appearing on the screen.
              </p>
              <p>
                Additionally, PEXit will send you an email listing credentials
                to the respective email id of your third party account such as
                Facebook/Gmail/LinkedIn. Now PEXit users can use these
                credentials to log into PEXit or continue to use
                Facebook/Gmail/LinkedIn buttons available under "Login" to log into PEXit.
              </p>
              <p>
                <span>5. How do I create my Profile in PEXit?</span>
              </p>
              <p>
                The Profile of a PEXit User is created as soon as the User is
                signed in. The Profile of the User includes the shared relevant
                information. In order for other professionals to know your
                background, you may complete your Profile in PEXit with as much
                details as you wish. Lastly, your organization can create as
                many accounts as desired within PEXit. The following is the
                step-by-step guide to complete your Profile.
              </p>
              <p>
                <strong>Step 1:</strong> Click "Edit" icon shown next to your 
                profile picture in your dashboard or select the option "Profile" 
                by clicking the image shown on right top corner of the browser. 
                The “Edit Profile” window opens with “Summary”, “Experience”, 
                “Education” and “Honors &amp; Awards” sections.
              </p>
              <p>
                <strong>Step 2:</strong> Enter experience details “Company
                Name*”, “Title*”, “Location” … and submit. Please note that the
                fields with * are mandatory fields. The details need to be
                filled before submission.
              </p>
              <p>
                <strong>Step 3:</strong> To enter details about multiple
                positions &amp; jobs in your career, use “Add Position” link at
                the top right side position in “Experience” section.
              </p>
              <p>
                <strong>Step 4:</strong> Repeat Steps 2 &amp; 3 in the
                “Experience” section until you enter details about all your
                positions.
              </p>
              <p>
                <strong>Step 5:</strong> Follow the same process as in Steps 2,
                3 &amp; 4 above for the sections titled “Education” and “Honors
                &amp; Awards” to complete your Profile.
              </p>
              <p>
                <span>
                  6. I forgot my password to Log In to PEXit. How do I reset the
                  password for my account?
                </span>
              </p>
              <p>
                <strong>Step 1:</strong> Press the option "Forgot Password?" shown in Login page. 
                A new page will show up.
              </p>
              <p>
                <strong>Step 2:</strong> Enter the email address (provided to
                PEXit during registration) in the page shown and press "Reset Password".
              </p>
              <p>
                <strong>Step 3:</strong> Log In to your email account. You will
                find an email from PEXit with a link to reset the Password.
              </p>
              <p>
                <strong>Step 4:</strong> Click the link or Copy &amp; Paste the
                link in the browser and Enter. A new page will show up with the
                option to enter the "New Password" and "Confirm Password".
              </p>
              <p>
                <strong>Step 5:</strong> Enter the new password in the
                “Password” field and retype the new password in the field
                “Confirm Password” and then press Submit.
              </p>
              <p>
                <span>
                  7. How do I search for a Posts / Jobs / Services / Products /
                  Suppliers / Connection in PEXit without navigating to each of
                  the above page individually?
                </span>
              </p>
              <p>
                You can access search for Posts / Jobs / Services / Products /
                Suppliers in PEXit without navigating to each page as given
                below.
              </p>
              <p>
                <strong>Step 1:</strong> Select the search type by clicking the
                arrow (drop down) available to the left of “Search” field in the
                dashboard of PEXit.
              </p>
              <p>
                <strong>Step 2:</strong> Enter the search “Keyword” and press
                the “Search” icon available in the right of the Search field in
                PEXit Dashboard.
              </p>
              <p>
                <strong>Step 3:</strong> Once entered, PEXit will search display
                the contents to the user.
              </p>
              <p>
                <span>
                  8. Can I do "nested search" (Search within the Results
                  previously obtained)?
                </span>
              </p>
              <p>
                Yes. It is possible to do nested search using PEXit (i.e.,
                search the contents within the results previously obtained).
              </p>
              <p>
                For example, if wish you to search for “Valves” with “Gate”, 
                then you can do so by following the Steps given below.
              </p>
              <p>
                <strong>Step 1:</strong> Select the search type as “Products” by
                clicking the arrow (drop down) available to the left of “search”
                field in the dashboard of PEXit.
              </p>
              <p>
                <strong>Step 2:</strong> Enter the “Keyword” as “Valves” and
                press the “Search” icon available to the right of the Search
                field in PEXit dashboard. Once done, PEXit will list all the
                “Valves” from the Product Catalogue.
              </p>
              <p>
                <strong>Step 3:</strong> Now, to locate the “Gate” valve
                from the search results that are already shown, select the
                search type as “Products” again from the search dropdown. 
              </p>
              <p>
                <strong>Step 4:</strong> Enter the “Keyword” as “Gate”
                and press the “Search” icon.
              </p>
              <p>
                <strong>Step 5:</strong> PEXit will show only those Valves with
                “Gate” type from the Product catalogue. To reset the search, press the refresh button
                shown next to search option on the top.
              </p>
              <p>
                The procedure listed above can be followed to search for any
                Posts / Jobs / Services / Suppliers / Connections that meets
                your exact requirements.
              </p>
              <p>
                <span>9. On which platforms is PEXit available?</span>
              </p>
              <p>
                PEXit is available on all devices and platforms that are enabled
                with internet browser. PEXit can be accessed using the
                link&nbsp;
                <a href="https://pexit.net/" className="line">
                  https://pexit.net
                </a>
                .
              </p>
              <p>
                PEXit App for Android mobile can be downloaded from&nbsp;
                <a
                  href="https://play.google.com/store/apps/details?id=com.recrosoft.pexit"
                  target="_blank"
                  className="line"
                  rel="noreferrer"
                >
                  Google Play Store{" "}
                </a>
                and PEXit App for iPhone can be downloaded from&nbsp;
                <a
                  href="https://apps.apple.com/us/app/pexit/id1456091969"
                  target="_blank"
                  className="line"
                  rel="noreferrer"
                >
                  Apple Store.
                </a>
              </p>
              <p>
                <span>
                  10. Can we do the basic formatting of text in Posting or
                  Comments?
                </span>
              </p>
              <p>
                Yes. PEXit allows you to do the basic formatting of text in
                Posting or Comments by following the special tags that are shown
                below. The formatting performed using tags are visible
                <strong> only in browser version of PEXit</strong>.
              </p>
              <p>
                1. Use *bs* and *be* to highlight a portion of text in BOLD.
              </p>
              <p>
                <b> Example for BOLD:</b>
              </p>
              <p>
                Keywords are displayed with the default style of the element,
                *bs* likely in BOLD*be*
              </p>
              <p>
                <b>Result:</b>
              </p>
              <p>
                Keywords are displayed with the default style of the element,
                <b> likely in BOLD</b>
              </p>
              <p>2. Use *ss* and *se* to strike through a portion of text.</p>
              <p>
                <b> Example for STRIKE:</b>
              </p>
              <p>*ss*Item shown above is *se* SOLD OUT</p>
              <p>
                <b>Result:</b>
              </p>
              <p>
                <s>Item shown above is</s> SOLD OUT
              </p>
              <p>3. Use *us* and *ue* to underline a portion of&nbsp;text.</p>
              <p>
                <b>Example for UNDERLINE:</b>
              </p>
              <p>Item shown above is *us* SOLD OUT*ue*</p>
              <p>
                <b>Result:</b>
              </p>
              <p>
                Item shown above is <u>SOLD OUT</u>
              </p>
              <p>
                4. Use *is* and *ie* to make&nbsp;a portion of&nbsp;text in
                Italics.
              </p>
              <p>
                <b>Example for ITALICS:</b>
              </p>
              <p>Item shown above is *is*SOLD OUT*ie*</p>
              <p>
                <b>Result:</b>
              </p>
              <p>
                Items shown above is <i> SOLD OUT</i>
              </p>
              <p>
                5. Use *hrs* and *hre* to&nbsp;change the color of a portion
                of&nbsp;text in RED. Similarly, you can use *hys* &amp; *hye*,
                *hbs* &amp; *hbe*, *hgs* &amp; *hge*, *hos* &amp; *hoe* and
                *hps* &amp; *hpe* to display in YELLOW, BLUE, GREEN, ORANGE and
                PURPLE respectively.
              </p>
              <p>
                <b>Example for COLOR:</b>
              </p>
              <p>Today's Special: *hrs*RED Curry with Jasmine Rice*hre*</p>
              <p>
                <b>Result:</b>
              </p>
              <p>
                Today's Special:
                <span> RED Curry with Jasmine Rice</span>
              </p>
              <p>
                <span>
                  11. What are the various activities I can perform once I sign
                  into PEXit and what are the features of these activities?
                </span>
              </p>
              <p>
                As mentioned earlier, PEXit integrates features of Social Media,
                Professional Media and e-Commerce Facilitator. PEXit helps you
                to:
              </p>
              <p>I. Connect</p>
              <p>II. Communicate</p>
              <p>III. Collaborate</p>
              <p>IV. Consume Content</p>
              <p>V. Conduct Commerce</p>
              <p>VI. Choose Career</p>
              <p>VII. Additional Services</p>
              <p>
                <span>I. Connect</span>
              </p>
              <p>
                <strong>CONNECT</strong> with peers, associates, partners,
                customers...
              </p>
              <p>
                <span>
                  a. How do I connect to my colleague who is already a user of
                  PEXit?
                </span>
              </p>
              <p>
                On your “Dashboard”, Select the search type as “Connections” by
                clicking the arrow (drop down) available to the left of “search”
                field in the dashboard of PEXit. Enter the
                name of your colleague in the “Search” option available at the
                Top and click “Search” button. The Display Name of the colleague
                will appear under “Users You May Know” title. Clicking on
                “Connect” button below the Display Name will send an invitation to
                the colleague to connect with you. On accepting the invitation,
                the colleague’s Display Name appears under your “My
                Connections”.
              </p>
              <p>
                The Pending Invites are displayed on the top left under Home
                page.
              </p>
              <p>
                <span>
                  b. How do I connect to my contacts who are NOT yet PEXit
                  users?
                </span>
              </p>
              <p>
                Click on the link{" "}
                <a href="#" onClick={this.showInviteModal} className="line">
                  [Invite Contacts]
                </a>{" "}
                on your dashboard that appears below to [Edit Profile].
                In the Invite Contacts form, please add all the email IDs of
                your contacts separated by commas to invite them to sign-up with
                PEXit. The invitation to join PEXit will be sent to those
                contacts upon pressing the option “Send”. When done, your contacts will 
                receive a connect request notification via PEXit through email.
              </p>
              <p>
                Your contacts need to follow the link to sign-up with PEXit.
                Once your contacts sign up, they will appear in ‘My Circle’
                &gt;‘Users You May Know’. You may check (√) ‘Show all PEXit
                users’ check box to find your PEXit signed-up invitees. Once you
                find your invitees, ‘Connect’ to each one of them. Once you are
                connected with your contacts, they will appear in your “My
                Connections” list.
              </p>
              <p>
                <span>c. How do I Block connection of an existing User?</span>
              </p>
              <p>
                On your “Dashboard”, click the option “My Circle” and then "My Connections". 
                Click the button "Block" shown below the Display Name of that User to block.
              </p>
              <p>
                <span>
                  d. How do I send a message to my contact who is not in "My
                  Connections" list?
                </span>
              </p>
              <p>
                On your “Dashboard”, click the option “My Circle”. Enter the
                name of the User in the Search option available at the Top and
                press Search icon or navigate and locate the User from the list
                shown under "Users You May Know". Now, click on the Display Name
                of that User. From the profile page shown for that User, select
                the option “Friend &gt; Message”. Type the message and press
                Enter.
              </p>
              <p>
                <span>II. Communicate</span>
              </p>
              <p>
                <strong>COMMUNICATE</strong> by messaging, sharing photos,
                videos, drawings, reports, presentations…
              </p>
              <p>
                <span>
                  a. How do I edit a post that is already published by me
                  earlier?
                </span>
              </p>
              <p>
                Locate your existing post by navigating through the “Dashboard”
                or by entering the “Keyword” in the “Search” option available at
                the Top of the page. Once located, click the downward arrow shown 
                at the top right corner of post and then select the option “Edit”. 
                Upon completion of your edit, press "Update" to post your updated content.
              </p>
              <p>
                <span>b. Is it possible to delete a post published by me?</span>
              </p>
              <p>
                Yes. It is possible to delete a post published earlier by you.
                To do so, locate your existing post by navigating through the
                “Dashboard” or by entering the “Keyword” in the “Search” option
                available at the Top of the page or by selecting the option
                “Profile &gt; My Posts” and locating the desired post of yours.
                Once located, click the downward arrow shown at the top right
                corner of the post and then select the option “Delete”.
              </p>
              <p>
                <span>
                  c. Is it possible to Share or Edit and Share an existing post
                  of another PEXit User?
                </span>
              </p>
              <p>
                Yes. You can “Share” or “Edit and Share” an existing post
                available in PEXit. To do so, locate the existing post by
                navigating through the “Dashboard” or by entering the “Keyword”
                in the “Search” option available at the Top of the page. Once
                located, press the “Share” icon and select the recipients
                through “Share with” and press the option “Share” to share the
                post “As is”.
              </p>
              <p>
                On the other hand, if you wish to “Edit” the post before
                sharing, then press the button “Edit". Now, the post
                can be edited. After editing the post, share it.
              </p>
              <p>
                Please note, when the content is edited and shared by you, the
                new post will show the status of the post as “Edited and Shared”
                followed by the Owner’s Name of the post and the Name of the
                user who edited and shared the edited post.
              </p>
              <p>
                <span>
                  d. How do I read the messages received from other users of
                  PEXit?
                </span>
              </p>
              <p>
                Click the message icon shown on the top right corner of the
                “Dashboard” to read messages received from other users of PEXit.
              </p>
              <p>
                <span>
                  e. How do I send a message to my contact who is not in "My
                  Connections" list?
                </span>
              </p>
              <p>
                On your “Dashboard”, click the option “My Circle”. Enter the
                name of your contact in the Search option available at the Top
                and press Search icon or navigate and locate your contact from
                the list shown below "Users You May Know". Now, click on the
                Display Name of your contact. From the profile page shown,
                select the option “Friend &gt; Message”. Type the message and
                press Enter.
              </p>
              <p>
                <span>III. Collaborate</span>
              </p>
              <p>
                <strong>COLLABORATE</strong> with team members of projects by
                forming user specified groups.
              </p>
              <p>
                <span>a. How to Create a Group or a Forum using PEXit?</span>
              </p>
              <p>
                See the&nbsp;
                <a
                  href="./images/Presentation/Create_Group_Forum_PEXit.pdf"
                  className="line"
                >
                  PDF document
                </a>
                &nbsp; for details.
              </p>
              <p>
                <span>
                  b. Can a Project Team use PEXit to conduct a review of the
                  project within a selected group without revealing their
                  identities?
                </span>
              </p>
              <p>
                Yes, A Project Review Team can use PEXit to conduct a project
                review without revealing the identity of the team members
                providing the feedback. The Project Review Team and the
                reviewing team members need to be existing Users of PEXIT. The
                Users need to follow the steps given below.
              </p>
              <p>
                <strong>Step 1:</strong> The Administrator (Admin) of the
                Project Review Team forms a Group with a Display Name, say,
                “Review of Project A” (considering a review of Project A is
                being conducted).
              </p>
              <p>
                <strong>Step 2:</strong> The Admin of the Group “Review of
                Project A” invites selected Team Members of Project A to share
                their feedback on Project A.
              </p>
              <p>
                <strong>Step 3:</strong> The invitees accept the Admin’s
                invitation and join the “Review of Project A” Group to become
                the members of the Group.
              </p>
              <p>
                <strong>Step 4:</strong> Any member of the Group can post the
                feedback through “What’s on your mind?” from “Dashboard”. The
                member should type the required content and attach file(s), if
                required. Then the member selects the “Review of Project A” from
                the "Share with" list.
              </p>
              <p>
                <strong>Step 5:</strong> The member checks the option "Hide My
                Identity" and presses the button ‘Post’.
              </p>
              <p>
                <strong>Step 6:</strong> Once done, all the members of this
                group will be able to view the content without knowing the
                identity of the poster. Similarly, the identities of the persons
                who add their comments to this post can also be hidden and
                cannot be seen by other members of this group, by those persons
                checking the option “Hide My Identity” before posting their
                comments.
              </p>
              <p>
                Please note, the "Hide My Identity" feature can also be used for
                any confidential reviews, where anonymous feedback is needed.
              </p>
              <p>
                <span>
                  c. How do I remove a group after the completion of the
                  project?
                </span>
              </p>
              <p>
                The Administrator of the Group (the “Admin” who created the
                Group) should log into PEXit and navigate to "My Circle &gt;
                Groups". The Admin locates the concerned Group and clicks the
                option "Leave". This will disable the Group, its members and
                hide all the posts related to this Group from PEXit.
              </p>
              <p>
                <span>IV. Consume Content</span>
              </p>
              <p>
                <strong>CONSUME CONTENT </strong>posted on yellow pages and
                other postings.
              </p>
              <p>
                <span>
                  a. How do I find information on products of my interest in
                  PEXit?
                </span>
              </p>
              <p>
                Information on products is available under “Product” on
                dashboard. Here is the step-by-step guide.
              </p>
              <p>
                <strong>Step 1:</strong> The PEXit user clicks “Product” icon available in 
                dashboard. 
              </p>
              <p>
                <strong>Step 2:</strong> The User can view ‘Product’ or view
                ‘Suppliers’ by checking the option "Suppliers" available on right top of the page.
                The User enters the search word/s. The User can also fine-tune the search through
                the exhaustive lists of options under “Keyword”, “Location” and
                “Product Condition” for “Product”; (similarly, through
                “Location” and “Product Condition” for “Suppliers”).
              </p>
              <p>
                <strong>Step 3:</strong> The Search results appear within the
                page. The User clicks on the “Product” or “Supplier” link of
                interest. On clicking the “Supplier” link, the Supplier’s window
                opens with "Company Info", “Product Categories”, “Company
                Profile” and “Contact”. The User finds more information about
                the company under "About Us", which is open by default. By clicking
                on Company Profile button, the user gets more information on the
                company’s background. 
              </p>
              <p>
                <strong>Step 4:</strong> On clicking on the Product of interest,
                a window with tabs: “Product Details”, “Company Profile”,
                “Transaction History” and “Gallery” opens. The User clicks on
                tabs to get more product information, company background,
                history of transactions (if done earlier) and product &amp;
                related images, respectively.
              </p>
              <p>
                <span>
                  b. How do I find information on services of my interest in
                  PEXit?
                </span>
              </p>
              <p>
                Information on services is available under “Service” on
                dashboard. Here is the step-by-step guide.
              </p>
              <p>
                <strong>Step 1:</strong> The PEXit user clicks “Service” icon
                on dashboard. 
              </p>
              <p>
                <strong>Step 2:</strong> The “Search” Form opens on the left. 
                The User selects ‘Keywords’. The User enters the search word/s and/or the location.
              </p>
              <p>
                <strong>Step 3:</strong> The search results appear on the right side of the screen. 
                The User clicks on “View” button of the company of his interest. A window
                with comprehensive information on the company appears with
                relevant links and “Contact” button.
              </p>
              <p>
                <span>
                  c. How do I find information on software of my interest in
                  PEXit?
                </span>
              </p>
              <p>
                Information on software is available under “Software” on
                dashboard. Here is the step-by-step guide.
              </p>
              <p>
                <strong>Step 1:</strong> The PEXit user clicks the icon “Sotware” available in
                dashboard. 
              </p>
              <p>
                <strong>Step 2:</strong> The window with “SST Software” listings opens with option to 
                view “Other Software” on right top. Each software listing provides brief
                yet comprehensive information on software with necessary links.
                The “Download” button for each software is provided to facilitate the software download.
              </p>
              <p>
                <span>V. Conduct Commerce</span>
              </p>
              <p>
                <strong>CONDUCT COMMERCE</strong> on products, services,
                software etc.
              </p>
              <p>
                <span>
                  a. How does our Sales Team add our Product catalogues in PEXit?
                </span>
              </p>
              <p>
                PEXit helps Sales Teams to sell their products, services and
                software. The Sales Team members need to sign in as PEXit Users.
                The following is the step-by-step guide.
              </p>
              <p>
                <strong>Step 1:</strong> The PEXit User clicks on any of the option “Add Product”,
                “Add Service”, “Add Software”, “Post Job”, “Post Sponsored Content” and
                Publish Advertisement” shown on right side of dashbaord.
              </p>
              <p>
                <strong>Step 2:</strong> Let us say the User is interested in
                posting products. After clicking the “Add Product” form is shown on the left side. 
              </p>
              <p>
                <strong>Step 3:</strong> The User can choose between “Add
                Products” and “Upload Catalogue” options by scrolling the mouse and enter relevant
                fields and file/s and then presses “Submit” or “Upload” button
                as the case may be.
              </p>
              <p>
                <strong>Step 4:</strong> After the successful submission or
                uploading, the product or the catalogue appears in "Product" section.
              </p>
              <p>
                <span>
                  b. How does our Marketing Team post our Advertisements in
                  PEXit?
                </span>
              </p>
              <p>
                PEXit helps Marketing Teams to post their advertisements of
                their products, services and software. The Marketing Team
                members need to sign in as PEXit Users. The following is the
                step-by-step guide.
              </p>
              <p>
                <strong>Step 1:</strong> The PEXit User clicks on “Publish Advertisement”.
              </p>
              <p>
                <strong>Step 2:</strong> After clicking the “Publish Advertisement”, the
                window opens with “Layout”, “Details”, “Target Audience” and “Preview” options 
                on the left side.
              </p>
              <p>
                <strong>Step 3:</strong> The User can select one of the layouts
                by clicking on the radio button of choice in “Layout” tab.
              </p>
              <p>
                <strong>Step 4:</strong> The User clicks on “Details” tab, the
                tab opens. The User enters “Title”, “Link”, “Description” and
                either uploads “Media” or enters the “YouTube Link”.
              </p>
              <p>
                <strong>Step 5:</strong> The User clicks on “Target Audience”
                tab and selects “Age Group”, “Gender”, “Country”, “State” and
                “Company” through the respective drop down menus.
              </p>
              <p>
                <strong>Step 6:</strong> Then the User clicks on “Preview” tab
                to get a preview of the Ad. After reviewing the Ad, the User
                submits the Ad for publication by clicking on “Save” button. If
                the User wants to make changes to the Ad, then “Reset” button
                may be pressed to restart the process.
              </p>
              <p>
                <strong>Step 7:</strong> After the User presses “Save” button,
                “Upload Ad” window appears on screen. The User selects the
                “Duration” for the Ad placement and presses “Publish” button.
                The Ad will be published in PEXit. The advertisement published
                by the User will be displayed in “Active/Expired Ads” section.
                The User can edit any of the User’s Ads by pressing the “Edit”
                icon of the active Advertisement.
              </p>
              <p>
                <span>
                  c. How does our Purchase Team contact a Supplier through
                  PEXit?
                </span>
              </p>
              <p>
                PEXit facilitates procurement of products, services and software
                for PEXit users. The Purchase Team members need to sign in as
                PEXit Users. The following is the step-by-step guide.
              </p>
              <p>
                <strong>Step 1:</strong> The User can choose any of the menu
                items available in the Dashboard to find out more. Let us say the User is interested in
                procuring products. After clicking the Product, the Product
                page opens.
              </p>
              <p>
                <strong>Step 2:</strong> The User can choose between “Product”
                and “Suppliers” to search for the product of interest. The User
                gets search results after entering his product/suppliers of
                interest.
              </p>
              <p>
                <strong>Step 3:</strong> The User has the option to filter or
                refine the search on the basis of “Keyword”, which covers
                Categories, Location and Product Condition. The User can select
                one of the search results and click on the “Product” or
                “Supplier” link within that selection. On clicking the
                “Supplier” link, the Supplier’s window will open up.
              </p>
              <p>
                <strong>Step 4:</strong> After entering “Product Categories”
                tab, the user can click on Product Category of interest. The
                Product Category page opens with Image and Description of all
                the products under that Category. On clicking the product of
                interest, a window with tabs: “Product Details”, “Company
                Profile”, “Transaction History” and “Gallery” opens.
              </p>
              <p>
                <strong>Step 5:</strong> The User contacts Supplier by clicking
                on “Contact Supplier” button. The “Contact Supplier” form with
                pre-filled data for “Recipient Name”, “Sender Name”, “Sender
                email” and “Sender Phone” appears. The user enters the message
                in “Message” field and presses “Send” button. The message is
                sent to the Supplier.
              </p>
              <p>
                <span>
                  d. Can our Purchase Team connect to a number of suppliers
                  faster in PEXit?
                </span>
              </p>
              <p>
                The Purchase Team member signs up as PEXit User to connect to
                the suppliers in PEXit. PEXit users can connect to a large
                number of suppliers at one go. The following is the step-by-step
                guide.
              </p>
              <p>
                <strong>Step 1:</strong> A PEXit user clicks on “My Circle” on
                dashboard. “My Circle” window appears with the Search option on
                top of “Users You May Know” section.
              </p>
              <p>
                <strong>Step 2:</strong> The PEXit User enters the keyword that
                identifies the product of his interest in Search field and
                clicks ‘Search’ button by selecting the category as "Connection".
              </p>
              <p>
                <strong>Step 3:</strong> Now PEXit returns with all the relevant
                Connection cards (which include that product key word in their
                profiles) under “Users You May Know” section. The User can
                choose a connection of interest and click “Connect” link. The
                connection request gets submitted to the intended Connection.
                The chosen Connection will now show under the “Pending Invitees”
                section of “My Circle”.
              </p>
              <p>
                <strong>Step 4:</strong> As in the previous step, the User
                connects to as many Connections as desired.
              </p>
              <p>
                <strong>Step 5:</strong> The display names of the PEXit users
                who accepted the “Connect” requests will appear in “My
                Connections” section in the same page.
              </p>
              <p>
                <span>VI. Choose Career</span>
              </p>
              <p>
                <strong>CHOOSE CAREER</strong> by posting résumés and searching
                job openings.
              </p>
              <p>
                <span>a. Can PEXit help in finding jobs for its users?</span>
              </p>
              <p>
                Yes, PEXit Users can find jobs. The Jobs link is available on
                dashboard. Here is the step-by-step guide to find jobs.
              </p>
              <p>
                <strong>Step 1:</strong> The PEXit user clicks on “Job” on
                dashboard. The Jobs window opens with “Search” form on the left.
              </p>
              <p>
                <strong>Step 2:</strong> The User selects ‘Keywords’. The User
                enters the search word/s and/or location. The User can also fine-tune the search
                by entering the “Company” or “Title” or “Postal
                Code” in the search field. 
              </p>
              <p>
                <strong>Step 3:</strong> The search results appear on the right side of the screen. 
                The User clicks on “View” button of the job of his interest. A window
                with comprehensive information on the job appears with Job
                Description, Company Description, Industry, Employment Type,
                Experience and Job Function.
              </p>
              <p>
                <strong>Step 4:</strong> On finding the job of interest, the
                User clicks on “Apply Now” button on the top right side of the
                window. The application form appears with the User Profile and
                the option to Upload Résumé. If the user wishes to send résumé,
                then the User uploads the file.
              </p>
              <p>
                <strong>Step 5:</strong> The User clicks ‘Ápply’ button. The
                employer receives the application to proceed further.
              </p>
              <p>
                <span>b. How can recruiters post jobs in PEXit?</span>
              </p>
              <p>
                PEXit helps recruiters find the right employees. The recruiters
                need to sign in as PEXit Users. Job Posting in PEXit is
                available both as Free/Paid Services. After logging into your
                account, visit Post Job section. The following
                is the step-by-step guide to post jobs in PEXit.
              </p>
              <p>
                <strong>Step 1:</strong> The PEXit User clicks on “Post Job” on dashboard. 
              </p>
              <p>
                <strong>Step 2:</strong> The form opens with “Logo” upload option on top left
                side.
              </p>
              <p>
                <strong>Step 3:</strong> Under Job Posting section, the User
                enters ‘Job Title’ and ‘Company’ fields, selects Country, State
                &amp; City from the drop down menus and adds ‘Postal Code’. The
                User needs to enter ‘Job Function’ and select ‘Employee Type’.
                The User needs to enter ‘’Company Industry’ and selects
                ‘Seniority Level’. The User needs to enter ‘Job Description’ and
                ‘Company Description’. The User can either opt to receive the
                job application through email or through a website by selecting
                the option accordingly. If the User prefers to add personal
                touch, he may opt to include the User Profile along with Job
                Posting. Now the User posts the job by clicking ‘Save’ button.
              </p>
              <p>
                <strong>Step 4:</strong> After successful submission or
                uploading of the data, the job appears under Jobs.
              </p>
              <p>
                <span>VII. Additional Services</span>
              </p>
              <p>
                <span>
                  <strong>a</strong>. Can Sponsored Content be used to describe
                  and promote a new gadget developed by our team?
                </span>
              </p>
              <p>
                Yes, a new gadget developed by your team can be promoted through
                Sponsored Content in PEXit. You need to sign in as PEXit User.
                Sponsored Content posting is available both as Free/Paid
                Services in PEXit. After logging into your account, visit
                Post Sponsored Content section. The following is the
                step-by-step guide to post Sponsored Content in PEXit.
              </p>
              <p>
                <strong>Step 1:</strong> The PEXit User clicks on “Post Sponsored Content”.
              </p>
              <p>
                <strong>Step 2:</strong> The “Sponsored Content” form opens with “Title*” and
                “Description*” fields on top left side.
              </p>
              <p>
                <strong>Step 3:</strong> Under Sponsored Content section, the
                User enters ‘Title’ and ‘Description’ fields and adds the
                related media or document files. Now the User posts the
                Sponsored Content by clicking ‘Submit’ button.
              </p>
              <p>
                <strong>Step 4:</strong> After successful submission, uploading
                and approval of the data, the Sponsored Content appears in the
                target audience’s pages.
              </p>
              <p>
                <strong>
                  For queries on the use of PEXit, contact us at&nbsp;
                </strong>
                <a href="mailto:support@pexit.net" className="line">
                  support@pexit.net
                </a>
              </p>
            </div>
          </div>
        </section>

        {/* Contact Supplier Model START */}
        <div
          className={
            "creategroup-modal-container" +
            (this.state.inviteFriends ? " open" : "")
          }
        >
          <div className="creategroup-modal contactsuuplier-modal invite-modal">
            <button
              type="button"
              className="close-button-modal"
              id="close-createGroup"
              onClick={this.hideInviteModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-header">
              <h1 className="header">Contact</h1>
            </div>

            <div className="creategroup-content login-content-fields invite-friends-modal">
              <form action="" className="login">
                <div className="contact-container">
                  <label htmlFor="senderName" className="name">
                    Your Name
                  </label>

                  <input
                    type="text"
                    className="groupname"
                    placeholder="Sender Name"
                    name="senderName"
                    value={this.state.senderName}
                    onChange={this.handleInputChange}
                  />
                </div>

                {this.validator.message(
                  "Sender Name field is",
                  this.state.senderName,
                  "required|string"
                )}

                <div className="contact-container">
                  <label htmlFor="senderName" className="name">
                    Your Email
                  </label>

                  <input
                    type="text"
                    className="groupname"
                    placeholder="Sender Email"
                    name="senderEmail"
                    value={this.state.senderEmail}
                    onChange={this.handleInputChange}
                  />
                </div>

                {this.validator.message(
                  "Sender Email field is",
                  this.state.senderEmail,
                  "required|email"
                )}

                <div className="contact-container">
                  <label htmlFor="subject" className="name">
                    Subject
                  </label>

                  <input
                    type="text"
                    className="groupname"
                    placeholder="Subject"
                    name="subject"
                    value={this.state.subject}
                    onChange={this.handleInputChange}
                  />
                </div>

                {this.validator.message(
                  "Subject field is",
                  this.state.subject,
                  "required|string"
                )}

                <div className="contact-container">
                  <label htmlFor="supplierRecipient" className="name">
                    Recipient Email
                  </label>

                  <input
                    type="text"
                    className="groupname"
                    placeholder="Separate email ids by , (comma)"
                    name="supplierRecipient"
                    value={this.state.supplierRecipient}
                    onChange={this.handleInputChange}
                  />
                </div>

                {this.validator.message(
                  "Email field is",
                  this.state.supplierRecipient,
                  "required|email"
                )}

                <div className="contact-container">
                  <label htmlFor="message" className="name">
                    Message
                  </label>

                  <textarea
                    type="text"
                    placeholder="Message"
                    id="textarea"
                    className="input-field field"
                    name="message"
                    value={this.state.message}
                    onChange={this.handleInputChange}
                  />
                </div>

                {this.validator.message(
                  "Message field is",
                  this.state.message,
                  "required|string"
                )}

                <button
                  type="button"
                  className="login-button creategroup-button invite-button"
                  id="mySupplier"
                  onClick={this.inviteFriendsApi}
                >
                  <h2>Send</h2>
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* Contact Supplier Model END */}
      </>
    );
  }
}

export default Support;
