import "../product/Product.css";
import "../product/Product";
import "../../components/modals/Popup.css";
import "../report/Report.css";
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { withRouter } from "react-router-dom";
import ButtonLoader from '../../components/loader/Loader'

export class Report extends Component {
  constructor(props) {
    super(props);

    //dynamic validation for title field and content field
    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.state = {
      isSelectedCondition: "",
      details: "",
      isButtonLoding: false,
    };
  }

  handleInputChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });
  };

  submitReport = async () => {
    if (this.validator.allValid()) {

      await this.setState({
        isButtonLoding: true
      })
      const userData = localStorage.getItem("userData");
      const userDetails = JSON.parse(userData);

      const reportDetail = {
        moduleType: this.props.module,
        mid: this.props.softwareId, //software.id, post.id
        uid: userDetails.userId,
        moid: this.props.softwareUid, //software .uid, post .uid
        rcategory: this.state.isSelectedCondition,
      };

      try {
        const report = await axios.post(
          `${process.env.REACT_APP_API_URL}report`,
          reportDetail,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (report.status === 200 && report.data.status === true) {
          toast.success(report.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          this.setState({
            details: report.data.datas,
          });

          this.props.hideReportModal();

        } else {
          toast.error(report.data.errors, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        }

        if (err?.response?.status === 400) {
          toast.error("Invalid Option", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
      await this.setState({
        isButtonLoding: false
      })
      this.props.hideReportModal();
      this.validator.hideMessages();
      this.forceUpdate();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />
        {/* Contact Supplier Model START */}

        <div className="creategroup-modal-container report-modal-container open">
          <div className="creategroup-modal report-modal" id="reportpopup">
            <button
              type="button"
              className="close-button-modal"
              id="close-createGroup"
              onClick={this.props.hideReportModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-header">
              <h1 className="header">Report This Post Content</h1>
            </div>

            <div className="creategroup-content sidebar-content report-box">
              <div className="report-content">
                <i className="fas fa-chevron-down" />
                <select
                  id="rcategory"
                  name="isSelectedCondition"
                  value={this.state.isSelectedCondition}
                  onChange={this.handleInputChange}
                  className="form-select report-category"
                  aria-label="Default select example"
                  required
                >
                  <option defaultValue>Choose the report category...</option>
                  <option value="violence">Violence</option>
                  <option value="harassment">Harassment</option>
                  <option value="nudity">Nudity</option>
                  <option value="spam">Spam</option>
                  <option value="terrorism">Terrorism</option>
                  <option value="false_news">False News</option>
                  <option value="suicide_or_self-injury">
                    Suicide or Self-injury
                  </option>
                  <option value="hate_speech">Hate Speech</option>
                  <option value="other">Others</option>
                </select>
              </div>

              {this.validator.message(
                "Select field is",
                this.state.isSelectedCondition,
                "required|string"
              )}

              <div className="report-submit">
                <button
                  type="button"
                  className="submit"
                  onClick={this.submitReport}
                  disabled={this.state.isButtonLoding}
                >
                  {this.state.isButtonLoding ? <ButtonLoader /> : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Supplier Model END */}
      </>
    );
  }
}

export default withRouter(Report);
