import React, { Component } from 'react'
import "./Aboutus.css"

import googlebutton from "../../../assets/google-button.png"
import applebutton from "../../../assets/apple-button.png"

export class Aboutus extends Component {
    render() {
        return (
            <>

                <section className={`about-section ${(localStorage.getItem('token') ? 'after-login-section' : '')}`}>
                    <div className="about-section-header">
                        <h1 className="header">PEXit - The Integrated Multi-Lingual Media</h1>
                    </div>
                    <div className="about-section-content">
                        <p className="text">PEXit combines the functionalities of social media,
                            professional media and e-commerce;
                            it is designed for B2B (Business-to-Business) and
                            B2C (Business-to-Consumer) users of varied profile to</p>

                        <p className="text"><span>CONNECT</span> with their peers, associates, partners, customers…,</p>
                        <p className="text"><span>COMMUNICATE</span> with them by messaging, sharing photos, videos, drawings, reports, presentations…,</p>
                        <p className="text"><span>COLLABORATE</span> with team members of projects by forming user specified groups,</p>
                        <p className="text"><span>CONSUME CONTENT</span> posted on yellow pages and other postings,</p>
                        <p className="text"><span>CONDUCT COMMERCE</span> on products, services, software etc., and</p>
                        <p className="text"><span>CHOOSE CAREER</span> by posting résumés and searching job openings.</p>

                        <p className="text">Let's PEXit!</p>

                        <div className="about-section-buttons">
                            <div className="google-button">
                                <button type="button" className="google">
                                    <div className="button-image">
                                        <a href="https://play.google.com/store/apps/details?id=com.recrosoft.pexit" target="BLANK"><img src='/images/images/gplay_app.png' alt="google-button" /></a>
                                    </div>
                                </button>
                            </div>

                            <div className="apple-button">
                                <button type="button" className="apple">
                                    <div className="button-image">
                                    <a href="https://apps.apple.com/in/app/pexit/id1456091969" target="BLANK"><img src='/images/images/apple-store.png' alt="apple-button" /></a>
                                    </div>
                                </button>
                            </div>
                        </div>

                        <p className="text">It has multi-lingual functionality inclusive of translation making it truly
                            global and breaks the barriers of communication across geographic and time zones.</p>
                        <p className="text">In short, PEXit is for use by professionals, entrepreneurs, micro/small/medium
                            enterprises, retailers, suppliers, manufacturers, industrial facilities and governments.
                            Companies worldwide can use PEXit for their inter-departmental sharing of data, drawings
                            and documents, project management, communication with suppliers and clients, sales &
                            marketing activities, HR recruitment etc.</p>
                        <p className="text">Its usage extends beyond B2B and B2C transactions; even friends, families, students,
                            teachers and communities can use it with ease to share and carry out assignments and events.</p>
                        <p className="text para">Some of the key features of PEXit are:</p>
                        <p className="text para">
                            • An integrated Application that can be used as a professional media, e-commerce facilitator and even as a social media
                        </p>
                        <p className="text para">
                            • Create numerous Groups within an entity and between entities to manage business activities discipline-wise, project-wise, client-wise etc.
                        </p>
                        <p className="text para">
                            • Have multiple threads of conversations within each Group
                        </p>
                        <p className="text para">
                            • Create open Forums for products, software, services, special topics etc.
                        </p>
                        <p className="text para">
                            • “Nested search” (i.e., search for people, products, services, software etc. within previously searched results)
                        </p>
                        <p className="text para">
                            • Attach multiple files of diverse types with each file size up to 25 MB
                        </p>
                        <p className="text para">
                            • Create and manage product/service/organization websites within PEXit
                        </p>
                        <p className="text para">
                            • Has a built-in multi-way language translator, by which a user can post in one language and other users around the world can translate to their respective languages.
                        </p>
                        <p className="text para">
                            • Download native apps for Android and iOS mobiles from PlayStore & AppStore respectively
                        </p>
                        <p className="text para">
                            • Browser version (www.pexit.net) accessible on all devices including Windows-based laptops, desktops etc.
                        </p>
                        <p className="text para">
                            • Hosted securely on Cloud VPS that commits 99.95% availability
                        </p>
                        <p className="text">
                            • Designed to work on "closed, private clouds” of corporations and governmental agencies.
                        </p>


                        <p className="text">
                            You can “Log” into PEXit using your Gmail or Facebook or LinkedIn username and password.
                            The 2nd registration option is to “Join” PEXit by entering your own Display Name,
                            Username, Password, email, etc. Under the 2nd option, click the link sent to your email
                            (check Inbox/Spam folder) to activate PEXit, thereby validating the email you entered
                            during registration.
                        </p>
                        <p className="text">
                            Please watch 2 power-point presentations with Voice available under “About” page in PEXit.
                            The first presentation for 4 minutes gives an “Overview”, while the 2nd for 11 minutes lists
                            all features of PEXit and is almost like a mini instruction manual. Also, "Support" page
                            lists answers to almost all Frequently Asked Queries (FAQ).
                        </p>
                        
                        <p className="text">For an overview of PEXit, <a href="./images/Presentation/PEXit_Intro.html" target="_blank" className="click-here">Click here.</a></p>
                       
                        <p className="text">For features of PEXit, <a href="./images/Presentation/PEXitPresentation.pdf" target="_blank" className="pdf">PDF</a> or <a href="./images/Presentation/PEXit_Presentation.html" target="_blank" className="video">Video</a></p>
                    </div>
                </section>


            </>
        );
    }
}

export default Aboutus;
