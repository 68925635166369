import "../job/Job.css";
import "../product/Product.css";
import "../product-add/AddProduct.css";
import "./postAd.css";

import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import {
  PayPalButtons,
} from "@paypal/react-paypal-js";
import * as mime from 'react-native-mime-types';
import Select from 'react-select';
import { Editor } from "@tinymce/tinymce-react";
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import Parser from "html-react-parser";
import Loader from "../loader/Loader";
import moment from "moment";
import chatArrow from "../../../assets/chat-arrow.png";
import postEdit from "../../../assets/edit-icon.png";
import { saveAs } from 'file-saver';
import layout1 from "../../../assets/layout1.png";
import layout2 from "../../../assets/layout2.png";
import layout3 from "../../../assets/layout3.png";
import ButtonLoader from '../../components/loader/Loader'
import defaultImg from "../../../assets/un.png"

const nodeCCAvenue = require("node-ccavenue");
const fs = require('fs')
const ccav = new nodeCCAvenue.Configure({
  merchant_id: process.env.REACT_APP_CCAVENUE_MERCHANT_ID,
  working_key: process.env.REACT_APP_CCAVENUE_LIVE_WORKING_KEY,
});

export class PostAd extends Component {
  constructor(props) {
    super(props);

    this.Servicevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        serType: {  // name the rule
          message: 'Invalid :attribute',
          rule: (val, params, validator) => {

            var allowTypes = ['free', 'paid'];

            if (allowTypes.includes(val)) {
              return true
            } else {
              return false
            }
          },

        },
      }
    })

    this.filevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleYoutubeLinks: {  // name the rule
          message: 'The :attribute must be a valid youtube links',
          rule: (val, params, validator) => {

            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(',');
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e)
              }
            }
            if (ine.length === 0) {
              return true
            } else {
              return false
            }
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params))
        },

        Photos: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_LOGOS.split(','),
          rule: (val, params, validator) => {
            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_LOGOS.split(',')
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },
      }
    })

    this.Updatefilevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
      validators: {
        multipleYoutubeLinks: {  // name the rule
          message: 'The :attribute must be a valid youtube links',
          rule: (val, params, validator) => {

            var reg = `^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$`;
            var links = val.split(',');
            let ine = [];
            for (let index = 0; index < links.length; index++) {
              let e = links[index];
              e = e.replace(/^\s+/g, '');
              if (validator.helpers.testRegex(e, reg) !== true) {
                ine.push(e)
              }
            }
            if (ine.length === 0) {
              return true
            } else {
              return false
            }
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params))
        },

        Photos: {  // name the rule
          message: 'The :attribute must be a valid file types, valid file types are ' + process.env.REACT_APP_POST_PHOTOS.split(','),
          rule: (val, params, validator) => {
            let files = (val.files) ?? [];
            let ret = true;
            let ar = process.env.REACT_APP_POST_PHOTOS.split(',')
            for (let i = 0; i < files.length; i++) {
              if (ar.includes(mime.extension(files[i].type)) === false) {
                ret = false;
              };
            }
            return ret
          },
        },
      }
    })

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.Updatevalidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "The :attribute is required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.durationValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.promoCodeValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.InvalidValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.AdElement = React.createRef();
    this.jlogo = React.createRef();
    this.ccavenueForm = React.createRef();

    this.state = {
      searchCondition: "",
      locationData: [],
      listing: [],
      total: 0,
      page: 0,
      nextAvailable: true,
      perPage: 6,
      loading: false,
      allJob: [],
      updateAd: false,
      adlayout: "",
      adlink: "",
      adtitle: "",
      addescription: "",
      admedia: "",
      adylink: "",
      adage: [],
      adgender: [],
      adcounty: "",
      adstate: "",
      adcompany: "",
      adduration: "",
      layout1: false,
      layout2: false,
      layout3: false,
      adID: "",
      jobDet: [],
      mediaimg: "",
      titleType: false,
      descType: false,
      allcountry: [],
      showStateSelect: false,
      companyListArray: [],

      JobLogo: "",
      keyword: "",
      Options: [],
      states: "",
      stateOptions: [],
      cityOptions: [],
      city: "",
      userID: "",
      uemail: "",
      selectedOption: "",
      applytype: "",

      stype: "0",
      isSubmitted: false,
      formData: "",
      amt: "",
      duration: "",
      promoCode: "",
      referenceId: "",
      encRequest: "",
      access_code: "AVBZ71EF36BA67ZBAB",
      currency: "",

      isLoaded: false,
      isButtonLoding: false,
      isPublishButtonLoding: false,

      isPostAdLeftSideBar: false,
      openPostAdLeftSideBar: false,

      isValidCode: false,
      isVerified: false,
      promoErrorMsg: "",

      isRenew:false,
      freeCount:0,
      isCreated:false,

      isccavenuePayment: false,
    };
  }

  handleCondition = async (conditionData) => {
    await this.setState({
      searchCondition: conditionData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  userData = async () => {
    const userData = JSON.parse(`${localStorage.getItem("userData")}`);
    await this.setState({
      jemail: userData.userEmail,
    });
  };

  AdApi = async () => {
    if (this.state.nextAvailable) {
      await this.setState({
        loading: true,
        page: this.state.page + 1,
      });

      const jobData = {
        page: this.state.page,
        records: this.state.perPage,
      };

      try {
        const jobs = await axios.post(
          `${process.env.REACT_APP_API_URL}getallads`,
          jobData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (jobs.status === 200 && jobs.data.status === true) {

          const freeCount = jobs.data.counts['count(id)'];

          this.setState({
            isLoaded: true,
            freeCount:freeCount,
          })

          if (jobs.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }

          let oldArray = this.state.listing;
          oldArray = oldArray.concat(jobs.data.datas);

          await this.setState({
            allJob: oldArray,
            total: jobs.data.total
          });

          const allJobs = [];

          this.state.allJob.forEach((data) => {
            const location = data.states + ", " + data.country;
            allJobs.push(location);
          });

          let unique = [...new Set(allJobs)];

          await this.setState({
            locationData: unique,
          });

          await this.setState({
            listing: oldArray,
          });


        } else if (jobs.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else {
          await this.setState({
            isLoaded: true,
          })
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      await this.setState({
        loading: false,
      });
    }
  };

  saveAdvertise = async () => {

    if(this.state.freeCount >= 10) {
      this.setState({
        stype:'paid',
      })
    }

    let CreateJob;
    window.scrollTo(0,0);
    if (this.state.updateAd && !this.state.isRenew) {
      if (this.Updatevalidator.allValid() && this.Updatefilevalidator.allValid()) {
        this.setState({
          isButtonLoding: true
        })
        const formData = new FormData(await this.AdElement.current);

        formData.append("addescription", this.state.addescription);
        formData.append("adduration", "0 days");

        try {
          CreateJob = await axios.post(
            `${process.env.REACT_APP_API_URL}fpservices/ads/${this.state.adID}`,
            formData,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );

          if (typeof CreateJob !== "undefined") {
            if (CreateJob.status === 200 && CreateJob.data.status === true) {

              this.AdElement?.current?.reset();

              this.setState({
                adlayout: "",
                adlink: "",
                adtitle: "",
                addescription: "",
                admedia: "",
                adylink: "",
                adage: [],
                adgender: [],
                adcounty: "",
                adstate: "",
                adcompany: "",
                adduration: "",
                mediaimg: "",
                updateAd: false,
              });

              toast.success(CreateJob.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });



              const jobData = {
                page: 1,
                records: this.state.perPage,
              };

              try {
                const jobs = await axios.post(
                  `${process.env.REACT_APP_API_URL}getalljobs`,
                  jobData,
                  {
                    headers: {
                      Auth: `${localStorage.getItem("token")}`,
                    },
                  }
                );

                if (jobs.status === 200 && jobs.data.status === true) {
                  if (jobs.data.datas.length < this.state.perPage) {
                    await this.setState({
                      nextAvailable: false,
                    });
                  }

                  let oldArray = this.state.listing;
                  oldArray = oldArray.concat(jobs.data.datas);

                  await this.setState({
                    allJob: oldArray,
                  });

                  const allJobs = [];

                  this.state.allJob.forEach((data) => {
                    const location = data.states + ", " + data.country;
                    allJobs.push(location);
                  });

                  let unique = [...new Set(allJobs)];

                  await this.setState({
                    locationData: unique,
                  });

                  await this.setState({
                    listing: oldArray,
                  });

                  await this.handleCondition(this.state.listing);
                } else if (jobs.status === 403) {
                  localStorage.removeItem("token");
                  window.location = process.env.PUBLIC_URL;
                }
              } catch (err) {
                if (err?.response?.status === 403) {
                  localStorage.removeItem("token");
                  window.location = process.env.PUBLIC_URL;
                } else if (err?.response?.data?.error != '') {
                  toast.error(err?.response?.data?.error, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                } else {
                  toast.error(err.message, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              }

            } else {
              var msg = CreateJob.data.errors
                ? CreateJob.data.errors
                : CreateJob.data.message;
              toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }

          await this.setState({
            loading: false,
          });
        } catch (err) {
          if (err?.response?.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != '') {
            toast.error(err?.response?.data?.error, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }

        this.setState({
          isButtonLoding: false
        })
        this.Updatefilevalidator.hideMessages();
        this.Updatevalidator.hideMessages();
        this.validator.hideMessages();
        this.forceUpdate();

        await this.setState({
          openPostAdLeftSideBar: false,
        });
      } else {
        this.Updatefilevalidator.showMessages();
        this.Updatevalidator.showMessages();
        this.forceUpdate();
      }

    } else {
      if ((this.validator.allValid() && this.Servicevalidator.allValid() && this.filevalidator.allValid()) || (this.state.isRenew && this.Updatevalidator.allValid() && this.Updatefilevalidator.allValid())) {
        this.setState({
          isButtonLoding: true
        })

        if(this.state.isRenew) {
          this.setState({
            amt:"",
            stype:'paid',
          })
        }

        const formData = new FormData(await this.AdElement.current);

        formData.append("addescription", this.state.addescription);

        this.setState({
          isSubmitted: true,
          formData: formData,
        })

        const userData = localStorage.getItem("userData")
        const userDetails = JSON.parse(userData)

        const userLoc = localStorage.getItem("userLocation");
        const userCountryDetails = JSON.parse(userLoc)

        var currency = userCountryDetails.currency;

        if (currency === "INR") {
          currency = "INR";
        } else if (currency === "EUR"){
          currency = "EUR";
        } else if (currency === "HKD"){
          currency = "HKD";
        } else if (currency === "JPY"){
          currency = "JPY";
        } else if (currency === "GBP"){
          currency = "GBP";
        } else if (currency === "AUD"){
          currency = "AUD";
        } else {
          currency = "USD";
        }


        await this.setState({
          currency: currency,
        })

        const uid = userDetails.userId;
        const type = "ad";

        var CurrentDate = moment().unix();

        var referenceId = uid + "-" + type + "-" + CurrentDate;

        await this.setState({
          referenceId: referenceId,
          isButtonLoding: false
        });

        this.filevalidator.hideMessages();
        this.validator.hideMessages();
        this.Servicevalidator.hideMessages();
        this.forceUpdate();

        await this.setState({
          openPostAdLeftSideBar: false,
        });
      } else {

        this.filevalidator.showMessages();
        this.validator.showMessages();
        this.Servicevalidator.showMessages();
        this.forceUpdate();
      }
    }

  };

  paymentPage = async (adid) => {
    if (!this.state.updateAd) {
      const passData = {
        serId: adid,
        stype: "ad",
        sname: "publish-advertisement",
      };

      this.props.history.push({
        pathname: `/try-premium/ads/${adid}`,
        state: passData,
      });
    }
  }

  editJob = async (AdId, country, state, type) => {

    if (type === "renew") {
      this.setState({
        isRenew:true,
      })
    } else {
      this.setState({
        isRenew:false,
      })
    }

    if (Array.isArray(country)) {
      country = country.join(',');
    }

    this.Servicevalidator.hideMessages();
    this.Updatefilevalidator.hideMessages();
    this.filevalidator.hideMessages();
    this.Updatevalidator.hideMessages();
    this.validator.hideMessages();
    this.forceUpdate();

    if (typeof AdId !== "undefined") {
      const updateAd =
        this.state.updateAd === false ? true : this.state.updateAd;

      await this.setState({
        updateAd: updateAd,
        adID: AdId,
      });
    }

    // all states API call
    try {
      const allstates = await axios.post(
        `${process.env.REACT_APP_API_URL}ad/states`,
        {
          countries: country,
        }
      );

      if (allstates.status === 200 && allstates.data.status === true) {
        const stateArray = [];

        allstates.data.datas.map((keys) =>
          stateArray.push({
            value: keys.name,
          })
        );

        this.setState({
          states: allstates.data.datas,
          stateOptions: stateArray,
        });
      }
    } catch (err) {

      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error !== '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    try {
      const particularAdDetail = await axios.get(
        `${process.env.REACT_APP_API_URL}ads/view/${AdId}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (
        particularAdDetail.status === 200 &&
        particularAdDetail.data.status === true
      ) {
        this.setState({
          jobDet: particularAdDetail.data.datas,
        });

        // transhis
        this.setState({
          adtitle: this.state.jobDet.title,
          adcounty: this.state.jobDet.country.split(','),
          adlayout: this.state.jobDet.layout,
          adlink: this.state.jobDet.url,
          addescription: this.state.jobDet.post,
          admedia: this.state.jobDet.image,
          adylink: this.state.jobDet.utub,
          adage: {value: this.state.jobDet.age.replace("+", ""), label: this.state.jobDet.age}, //this.state.jobDet.age,
          adgender: {value: this.state.jobDet.gender.toLowerCase(), label: this.state.jobDet.gender}, //this.state.jobDet.gender,
          adstate: this.state.jobDet.states.split(','),
          adcompany: this.state.jobDet.company.split(','),
          mediaimg: this.state.jobDet.image,
          amt: this.state.jobDet.amount,
          // userID: this.state.jobDet.uid,
        });

        if (this.state.jobDet.layout === 1) {
          await this.setState({
            layout2: false,
            layout3: false,
            layout1: true,
            titleType: true,
            descType: true,
          });
        }

        if (this.state.jobDet.layout === 2) {
          await this.setState({
            layout1: false,
            layout3: false,
            layout2: true,
            titleType: true,
            descType: false,
          });
        }

        if (this.state.jobDet.layout === 3) {
          await this.setState({
            layout1: false,
            layout2: false,
            layout3: true,
            titleType: false,
            descType: true,
          });
        }
      } else {
        this.state = {
          mediaimg: "",
          adlayout: "",
          adlink: "",
          adtitle: "",
          addescription: "",
          admedia: "",
          adylink: "",
          adage: [],
          adgender: [],
          adcounty: [],
          adstate: "",
          adcompany: [],
          adduration: "",
          adID: "",
        };
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    await this.setState({
      openPostAdLeftSideBar: true,
    });
  };

  componentDidMount = async () => {
    await this.handleResize();

    this.setState({
      loading: true,
    });

    try {
      const allCountries = await axios.get(
        `${process.env.REACT_APP_API_URL}countries`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (allCountries.status === 200 && allCountries.data.status === true) {
        const keywordArray = [];

        allCountries.data.datas.map((keys) =>
          keywordArray.push({
            value: keys.name,
            cid: keys.id,
          })
        );

        this.setState({
          keyword: allCountries.data.datas,
          Options: keywordArray,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    try {
      const adcompanies = await axios.get(
        `${process.env.REACT_APP_API_URL}ad/companies`,
      );
      if (adcompanies.status === 200 && adcompanies.data.status === true) {
        this.setState({
          companyListArray: adcompanies.data.datas,
        });
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    await this.AdApi();
    await this.userData();
    window.addEventListener("scroll", this.listenToScroll);

    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isPostAdLeftSideBar: true,
        openPostAdLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isPostAdLeftSideBar: false,
      });
    }
  };

  listenToScroll = async () => {
    if (this.state.loading === false) {
      const per = (document.documentElement.offsetHeight * 10) / 100;
      if (
        document.documentElement.offsetHeight - per <=
        document.documentElement.scrollTop + window.innerHeight
      ) {
        await this.AdApi();
      }
    }
  };

  ccavBtn = async () => {
    if (this.state.duration === "") {
      toast.error(`Duration should not be empty`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false
    } else {
      const userName = localStorage.getItem("userName")
    
      const orderParams = {
        order_id: this.state.referenceId,
        currency: this.state.currency,
        amount: this.state.amt,
        redirect_url: encodeURIComponent(
          `https://pexit.net/node_api/api/ccavenue`,
        ),
        billing_name: userName,
      };
      
      const encryptedOrderData = ccav.getEncryptedOrder(orderParams);
    
      await this.setState({
        encRequest: encryptedOrderData,
        isccavenuePayment: true
      });

      await this.addService(this.state.referenceId, 'awaited', this.state.currency, this.state.amt);
      
      this.ccavenueForm.current.submit();
      
    }
  }

  reset = async () => {
    this.setState({
      adlayout: "",
      adlink: "",
      adtitle: "",
      addescription: "",
      admedia: "",
      adylink: "",
      adage: [],
      adgender: [],
      adcounty: "",
      adstate: "",
      adcompany: [],
      adduration: "",
      mediaimg: "",
      updateAd: false,
    });
  };


  handleAccord(e) {
    e.preventDefault();

    document.querySelectorAll(".accord").forEach(ele => {

      if (e.target === ele && ele.nextElementSibling.style.display !== "block") {
        ele.nextElementSibling.style.display = "block";
        // e.target.scrollIntoView();
      } 

      else {
        if (e.target === ele && ele.nextElementSibling.style.display !== "none") {
          ele.nextElementSibling.style.display = "none";
        }
      }
      // else {
      //   ele.nextElementSibling.style.display = "none"
      // }
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.searchCondition !== this.state.searchCondition) {
      await this.AdApi();

      await this.setState({
        loading: false,
      });
    }
  }

  handleSelected = async (e) => {
    await this.setState({
      stype: e.value?.replace(/^\s+/g, ''),
    });
  };

  handleAgeInput = async (e) => {
    await this.setState({
      adage: e,//.value?.replace(/^\s+/g, ''),
    });
  };

  handleGenderInput = async (e) => {
    await this.setState({
      adgender: e, //.value?.replace(/^\s+/g, ''),
    });
  };

  handleInputChange = async (e) => {

    if (typeof e === "string") {
      await this.setState({
        addescription: (e.replace(/^\s+/g, '') === '<p>&nbsp;&nbsp;</p>') ? '' : e.replace(/^\s+/g, ''),
      });
    } else {
      await this.setState({
        [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
      });

      if (e.target.name === "admedia") {
        await this.setState({
          mediaimg: "",
        });
      }

      if (e.target.name === "adlayout" && e.target.value?.replace(/^\s+/g, '') == 1) {
        await this.setState({
          adlayout: 1,
          layout2: false,
          layout3: false,
          layout1: true,
          titleType: true,
          descType: true,
        });
      }

      if (e.target.name === "adlayout" && e.target.value?.replace(/^\s+/g, '') == 2) {
        await this.setState({
          adlayout: 2,
          layout1: false,
          layout3: false,
          layout2: true,
          titleType: true,
          descType: false,
        });
      }

      if (e.target.name === "adlayout" && e.target.value?.replace(/^\s+/g, '') == 3) {
        await this.setState({
          adlayout: 3,
          layout1: false,
          layout2: false,
          layout3: true,
          titleType: false,
          descType: true,
        });
      }

      if (e.target.files) {
        var file = e.target.files[0];
        if (typeof file !== "undefined") {
          var reader = new FileReader();
          reader.onload = async function (event) {
            // The file's text will be printed here
            if (e.target.name === "admedia") {
              await this.setState({
                mediaimg: event.target.result,
              });
            }
          }.bind(this);

          reader.readAsDataURL(file);
        }
      }

      if (e.target.name === "duration") {

        var threeMonths;
        var sixMonths;
        var twelveMonths;

        var currency = this.state.currency;

        if (currency === "INR") {
          threeMonths = 4000;
          sixMonths = 7200;
          twelveMonths = 9600;
        }

        else if (currency === "USD") {
          threeMonths = 240;
          sixMonths = 360;
          twelveMonths = 480;
        }

        else if (currency === "EUR") {
          threeMonths = 240;
          sixMonths = 360;
          twelveMonths = 480;
        }

        else if (currency === "HKD") {
          threeMonths = 1200;
          sixMonths = 1800;
          twelveMonths = 2400;
        }

        else if (currency === "JPY") {
          threeMonths = 24000;
          sixMonths = 36000;
          twelveMonths = 48000;
        }

        else if (currency === "GBP") {
          threeMonths = 180;
          sixMonths = 270;
          twelveMonths = 360;
        }

        else if (currency == "AUD") {
          threeMonths = 240;
          sixMonths = 360;
          twelveMonths = 480;
        }

        else {
          threeMonths = 240;
          sixMonths = 360;
          twelveMonths = 480;
        }

        if (e.target.value?.replace(/^\s+/g, '') === "3") {
          await this.setState({
            amt: threeMonths, //parseFloat(4000 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else if (e.target.value?.replace(/^\s+/g, '') === "6") {
          await this.setState({
            amt: sixMonths, //parseFloat(6400 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else if (e.target.value?.replace(/^\s+/g, '') === "12") {
          await this.setState({
            amt: twelveMonths, //parseFloat(9600 / convertAmt.data.USD_INR).toFixed(2),
          });
        } else {
          await this.setState({
            amt: "",
          });
        }

        this.setState({
          promoCode:"",
          isVerified:false,
          isValidCode: false,
        })
      }

    }
  };

  handleMultipleSelectChange = async (e) => {

    let value = Array.from(e.target.selectedOptions, option => option.value);

    if (e.target.name === "adcounty") {
      await this.setState({
        allcountry: value,
        adcounty: value
      });
      try {
        const allstates = await axios.post(
          `${process.env.REACT_APP_API_URL}ad/states`,
          {
            countries: this.state.allcountry.join(','),
          }
        );
        if (allstates.status === 200 && allstates.data.status === true) {
          const stateArray = [];
          allstates.data.datas.map((keys) =>
            stateArray.push({
              value: keys.name,
            })
          );
          this.setState({
            states: allstates.data.datas,
            stateOptions: stateArray,
          });
          const showStateSelect =
            this.state.showStateSelect === false
              ? true
              : this.state.showStateSelect;
          this.setState({
            showStateSelect: showStateSelect,
          });
        }
      } catch (err) {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    } else {
      this.setState({
        [e.target.name]: value,
      })
    }
  }

  hideModal = async () => {
    await this.setState({
      createPostModelOpen: false,
    });
  };

  addService = async (oid, ostatus, curr, amt) => {

    if(!this.state.isccavenuePayment){
      this.setState({
      isPublishButtonLoding: true
      });
    }

    const formData = this.state.formData;

    if (this.state.stype === 'paid') {
      if (ostatus === 'COMPLETED') {
        ostatus = 'Success';
      }
    }

    var createProduct;

    formData.delete('adduration');
    formData.delete('adpaid');
    formData.delete('adcurrency');
    formData.delete('adtax');
    formData.delete('adorderid');
    formData.delete('adpromocode');

    formData.append('adduration', this.state.duration);
    formData.append('adpaid', amt > 0 ? amt : 0);
    formData.append('adcurrency', curr ? curr : "");
    
    if (curr === 'INR' || curr === 'IN'){
      formData.append('adtax', "18");
    } else {
      formData.append('adtax', "0");
    }

    formData.append('adorderid', oid ? oid : "");
    formData.append('adpromocode', this.state.promoCode);

    if (!this.state.isRenew) {
      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/ads`,
        formData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    } else {
      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/ads/${this.state.adID}`,
        formData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    }

    if (createProduct.status === 200 && createProduct.data.status === true) {

      if (this.state.stype === 'paid') {

        this.setState({
          isCreated:false,
        })

        if (this.state.isRenew) {
          this.payment(oid, ostatus, curr, this.state.adID);
        } else {
          this.payment(oid, ostatus, curr, createProduct.data.datas);
        }

      } else {

        toast.success(createProduct.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      }

    if(!this.state.isccavenuePayment){
      this.setState({
        isCreated:true,
        adlayout: "",
        adlink: "",
        adtitle: "",
        addescription: "",
        admedia: "",
        adylink: "",
        adage: [],
        adgender: [],
        adcounty: "",
        adstate: "",
        adcompany: [],
        adduration: "",
        mediaimg: "",

        isSubmitted: false,
        nextAvailable: true,
        page: 0,
        listing: [],
        amt: "",
        duration: "",
        promoCode: "",
        referenceId: "",
        encRequest: "",
        currency: "",
        formData: "",
      });

      window.location.reload();
    }
    } else {
      toast.error(createProduct.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if(!this.state.isccavenuePayment){
      this.setState({
        isPublishButtonLoding: false
      });
    }

  }

  payment = async (oid, ostatus, curr, serID) => {

    // const serID = this.props.location.state.serId;
    const type = "ad";

    const data = {
      paymentType: "1",
      currency: curr,
      productType: type,
      orderStatus: ostatus,
      orderId: oid,
      serId: serID,
      promoCode: this.state.promoCode,
      renewType: this.state.isRenew ? "renew" : "new",
    };

    try {
      const payment = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/payment`,
        data,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (payment.status === 200 && payment.data.status === true) {
        
        this.setState({
          isCreated:true,
        })

        toast.success(payment.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });


      } else {
        toast.error(
          payment.data.errors ? payment.data.errors : payment.data.message,
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (err) {
      if (err.response.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  promoCodeAPI = async () => {

    if (!this.state.isVerified) {

    var data = {
      amount: this.state.amt,
      service: "ad",
      promocode: this.state.promoCode,
    };
    
    if (data.amount !== "") {
      this.durationValidator.hideMessages();
      this.forceUpdate();

      if (data.promocode !== "") {
        this.promoCodeValidator.hideMessages();

        try {
          const promoCode = await axios.post(
            `${process.env.REACT_APP_API_URL}fpservices/promocode`,
            data,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
          
          if (promoCode.status === 200 && promoCode.data.status === true) {
            
            await this.setState({
              amt: promoCode.data.datas,
              isVerified:true,
              isValidCode: false,
            });

            toast.success(promoCode.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            
            this.setState({
              isVerified:false,
              isValidCode: true,
              promoErrorMsg: promoCode.data.errors ? promoCode.data.errors : promoCode.data.message,
            })

            // toast.error(
            //   promoCode.data.errors
            //     ? promoCode.data.errors
            //     : promoCode.data.message,
            //   {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //   }
            // );
          }
        } catch (err) {
          if (err.response.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if (err?.response?.data?.error != '') {
            // toast.error(err?.response?.data?.error, {
            //   position: "top-right",
            //   autoClose: 4000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          } else {
            // toast.error(err.message, {
            //   position: "top-right",
            //   autoClose: 4000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          }
        }
      } else {
        this.promoCodeValidator.showMessages();
        this.forceUpdate();
      }
    } else {
      this.durationValidator.showMessages();
      this.forceUpdate();
    }
  } else {
    toast.error(
      "Promocode already applied",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
  };

  deleteFile = async (file = '') => {
    try {
      let fullpath = file;
      file = file.split('/').pop();
      const resDeletePostFile = await axios.delete(
        `${process.env.REACT_APP_API_URL}fpservices/file/${this.state.jobDet?.id}/ads/photos/${btoa(file)}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
      if (resDeletePostFile.status === 200 && resDeletePostFile.data.status === true) {
        document.getElementById(fullpath).style.display = 'none'
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token")
        window.location.href = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '' || err?.response?.data?.message != '') {
        toast.error((err?.response?.data?.error === '') ? err?.response?.data?.message : err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  showPostAdLeftSideBar = async () => {
    if (this.state.openPostAdLeftSideBar) {
      await this.setState({
        openPostAdLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openPostAdLeftSideBar: true,
      });
    }
  };

  render() {

    if (!this.state.isSubmitted) {
      return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
            toastClassName="dark-toast"
          />

          <section className="product-section">
            {(this.state.isPostAdLeftSideBar || this.state.openPostAdLeftSideBar) &&

              <div className="left-sidebar">
                <Link to={"/"}>
                  <div className="chat-arrow">
                    <img src={chatArrow} alt="chat-arrow" />
                  </div>
                </Link>

                <div className="sidebar-container">
                  <div className="header-content">
                    <h2 className="header">Post Your Ads</h2>
                  </div>

                  <div className="content-container">
                    <form
                      method="post"
                      ref={this.AdElement}
                      encType="multipart/form-data"
                    >

                      {!this.state.updateAd ?
                        <>

                          <div className="sidebar-content">
                            <div className="header-content">
                              <h3 className="header">Offer Type</h3>
                            </div>
                            <div className="caret-content">
                              {this.state.freeCount >= 10 ? 

                              <Select 

                                className="caret-content-select"
                                placeholder="Select offer type"
                                options={[
                                  {value:"paid",label:"Paid"}
                                ]}
                                name="stype"
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                                
                                onChange={this.handleSelected}
                                menuPosition={"fixed"}
                              /> : 
                                <Select 

                                  className="caret-content-select"
                                  placeholder="Select offer type"
                                  options={[
                                    {value:"free",label:"Free"},
                                    {value:"paid",label:"Paid"}
                                  ]}
                                  name="stype"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  }}
                                  // value={this.state.stype}
                                  onChange={this.handleSelected}
                                  menuPosition={"fixed"}
                                />
                              }
                            </div>

                            {this.Servicevalidator.message(
                              "Offer type",
                              this.state.stype,
                              "serType"
                            )}
                          </div>

                        </> :
                        <input
                          type="hidden"
                          name="adpaid"
                          className="input-field field"
                          value={this.state.amt}
                        />
                      }

                      <button className="accord" value="layout" onClick={this.handleAccord}>
                        Layout
                      </button>
                      <div className="panel pan">
                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">Choose the layout of your Ad</h3>
                          </div>
                        </div>

                        {this.validator.message(
                          "layout",
                          this.state.adlayout,
                          "required"
                        )}

                        {this.Updatevalidator.message(
                          "layout",
                          this.state.adlayout,
                          "required"
                        )}

                        <div className="sidebar-content">
                          <div className="pannel-icon centerImg">
                            <img src={layout1} alt="layout-image" className="img-big" />
                          </div>
                          <input
                            type="radio"
                            id="layout1"
                            name="adlayout"
                            value="1"
                            checked={this.state.adlayout == "1"}
                            onChange={this.handleInputChange}
                          />
                        </div>

                        <div className="sidebar-content">
                          <div className="pannel-icon centerImg">
                            <img src={layout2} alt="layout-image" className="img-big" />
                          </div>
                          <input
                            type="radio"
                            id="layout2"
                            name="adlayout"
                            value="2"
                            checked={this.state.adlayout == "2"}
                            onChange={this.handleInputChange}
                          />
                        </div>

                        <div className="sidebar-content">
                          <div className="pannel-icon centerImg">
                            <img src={layout3} alt="layout-image" className="img-big" />
                          </div>
                          <input
                            type="radio"
                            id="layout3"
                            name="adlayout"
                            value="3"
                            checked={this.state.adlayout == "3"}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>

                      <button className="accord" value="details" onClick={this.handleAccord}>
                        Details
                      </button>
                      <div className="panel pan">
                        {this.state.titleType && (
                          <div className="sidebar-content">
                            <div className="header-content">
                              <h3 className="header">Title</h3>
                            </div>

                            <input
                              type="text"
                              className="input-field field fieldwidth"
                              placeholder="Title"
                              name="adtitle"
                              onChange={this.handleInputChange}
                              value={this.state.adtitle}
                            />

                            {this.validator.message(
                              "title",
                              this.state.adtitle,
                              "required|string"
                            )}

                            {this.Updatevalidator.message(
                              "title",
                              this.state.adtitle,
                              "required|string"
                            )}
                          </div>
                        )}

                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">Link</h3>
                          </div>

                          <input
                            type="text"
                            className="input-field field fieldwidth"
                            placeholder="Link"
                            name="adlink"
                            onChange={this.handleInputChange}
                            value={this.state.adlink}
                          />

                          {this.validator.message(
                            "link",
                            this.state.adlink,
                            "required|url"
                          )}

                          {this.Updatevalidator.message(
                            "link",
                            this.state.adlink,
                            "required|url"
                          )}
                        </div>

                        {this.state.descType && (
                          <div className="sidebar-content">
                            <div className="header-content">
                              <h3 className="header">Description</h3>
                            </div>

                            <Editor
                              apiKey="textarea"
                              name="addescription"
                              value={this.state.addescription}
                              init={{
                                height: 200,
                                menubar: false,
                              }}
                              onEditorChange={this.handleInputChange}
                            />

                            {this.validator.message(
                              "description",
                              this.state.addescription,
                              "required|string"
                            )}

                            {this.Updatevalidator.message(
                              "description",
                              this.state.addescription,
                              "required|string"
                            )}
                          </div>
                        )}

                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">Media</h3>
                          </div>

                          <input
                            type="file"
                            className="input-field field fieldwidth"
                            name="admedia"
                            ref={this.admedia}
                            accept="image/*"
                            onChange={(e) => {
                              this.setState({
                                new_admedia: e.target
                              })
                              this.handleInputChange(e);
                              this.filevalidator.allValid()
                            }}
                          // value={this.state.admedia}
                          />

                          {this.filevalidator.message(
                            "Photo",
                            this.state?.new_admedia,
                            "Photos"
                          )}

                          {this.Updatefilevalidator.message(
                            "Photo",
                            this.state?.new_admedia,
                            "Photos"
                          )}

                          <div className="d-flex d_preview_images">
                            {
                              (Array.isArray(this.state.admedia)) ? this.state.admedia.map((img, key) => {
                                return <div className="image-area" key={key} id={img} style={{ width: "20%", marginTop: "10%" }} >
                                  <img src={process.env.PUBLIC_URL + '/images/docu.png'} target={'_blank'} alt="" onClick={(e) => {
                                    e.preventDefault()
                                    saveAs(img)
                                  }} />
                                  <a className="remove-image" href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.deleteFile(img)
                                  }}>&#215;</a>
                                </div>
                              }) : ""
                            }
                          </div>

                        
                        </div>
                        <div className="sidebar-content">(OR)</div>
                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">YouTube Link</h3>
                          </div>

                          <input
                            type="text"
                            className="input-field field fieldwidth"
                            placeholder="YouTube Link"
                            name="adylink"
                            onChange={this.handleInputChange}
                            value={this.state.adylink}
                          />

                          {this.filevalidator.message(
                            "YouTube Link",
                            this.state.adylink,
                            "multipleYoutubeLinks"
                          )}

                          {this.Updatefilevalidator.message(
                            "YouTube Link",
                            this.state.adylink,
                            "multipleYoutubeLinks"
                          )}
                        </div>
                      </div>

                      <button className="accord" value="targetAudience" onClick={this.handleAccord}>
                        Target Audience
                      </button>
                      <div className="panel pan">
                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">Age Group</h3>
                          </div>
                          <div className="caret-content">
                           
                            <Select 
                            className="caret-content-select"
                            placeholder="Select Age-group"
                            options={[
                              {value:"All",label:"All"},
                              {value:"18",label:"18+"},
                              {value:"25",label:"25+"},
                              {value:"35",label:"35+"},
                              {value:"50",label:"50+"}
                            ]}
                            name="adage"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            value={this.state.adage}
                            onChange={this.handleAgeInput}
                            menuPosition={"fixed"}
                            />
                          </div>
                        </div>

                        <div className="sidebar-content">
                          <div className="header-content">
                            <h3 className="header">Gender</h3>
                          </div>
                          <div className="caret-content">
                           
                            <Select 
                            className="caret-content-select"
                            options={[
                              {value:"all",label:"All"},
                              {value:"male",label:"Male"},
                              {value:"Female",label:"Female"},
                            ]}
                            name="adgender"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            value={this.state.adgender}
                            onChange={this.handleGenderInput}
                            menuPosition={"fixed"}
                            />
                          </div>
                        </div>

                        <div className="sidebar-content">
                          <div className="header-content mg-top">
                            <h3 className="header">Country</h3>
                          </div>

                          <select
                            multiple
                            id="countOpt"
                            className="form-select"
                            name="adcounty"
                            aria-label="multiple select example"
                            onChange={this.handleMultipleSelectChange}
                            value={this.state.adcounty}
                          >
                            {this.state.Options.map((option, key) => (
                              <option value={option.cid} id={option.cid} key={key}>
                                {option.value}
                              </option>
                            ))}
                          </select>
                          <p className="marTop">Press shift and select multiple countries</p>

                          {this.validator.message(
                            "county",
                            this.state.adcounty,
                            "required"
                          )}

                          {this.Updatevalidator.message(
                            "county",
                            this.state.adcounty,
                            "required"
                          )}
                        </div>

                        <div className="sidebar-content">
                          <div className="header-content mg-top">
                            <h3 className="header">State</h3>
                          </div>
                          {/* {this.state.showStateSelect ? (
                          <> */}
                          <select
                            id="stats"
                            name="adstate"
                            multiple
                            className="form-select"
                            aria-label="multiple select example"
                            onChange={this.handleMultipleSelectChange}
                            value={this.state.adstate}
                          >
                            <option defaultValue value="">
                              Select state
                            </option>
                            {this.state.stateOptions.map((option, key) => (
                              <option value={option.value} key={key}>{option.value}</option>
                            ))}
                          </select>
                          <p className="marTop">Press shift and select multiple States</p>

                          {this.validator.message(
                            "state",
                            this.state.adstate,
                            "required"
                          )}

                          {this.Updatevalidator.message(
                            "state",
                            this.state.adstate,
                            "required"
                          )}
                        </div>

                        <div className="sidebar-content">
                          <div className="header-content mg-top">
                            <h3 className="header">Company Name</h3>
                          </div>

                          <select
                            id="cmpny"
                            name="adcompany"
                            multiple
                            className="form-select"
                            aria-label="multiple select example"
                            onChange={this.handleMultipleSelectChange}
                            value={this.state.adcompany}
                          >
                            {this.state.companyListArray.map((option, key) => (
                              <option value={option.companyname} key={key}>{option.companyname}</option>
                            ))}
                          </select>
                          <p className="marTop">Press shift and select multiple Company</p>

                          {this.validator.message(
                            "company",
                            this.state.adcompany,
                            "required"
                          )}

                          {this.Updatevalidator.message(
                            "company",
                            this.state.adcompany,
                            "required"
                          )}
                        </div>
                      </div>

                      <button className="accord" onClick={this.handleAccord}>Preview</button>

                      <div className="panel pan">
                        <div className={this.state.layout1 ? "mainDiv" : "dnone"}>
                          <h3 className="centerTitle">{this.state.adtitle}</h3>
                          <div className="preview11">
                            <div
                              className={this.state.mediaimg ? "editImgDiv" : "dnone"}
                            >
                              <img
                                className={
                                  this.state.mediaimg ? "editImg" : "dnone"
                                }
                                src={this.state.mediaimg}
                                alt="ad-logo"
                              />
                            </div>
                          </div>
                          <div className="preview12">
                            {Parser(this.state.addescription)}
                          </div>
                        </div>

                        <div className={this.state.layout2 ? "mainDiv" : "dnone"}>
                          <h3 className="centerTitle">{this.state.adtitle}</h3>
                          <div className="preview21">
                            <div
                              className={this.state.mediaimg ? "editImgDiv" : "dnone"}
                            >
                              <img
                                className={
                                  this.state.mediaimg ? "editImg" : "dnone"
                                }
                                src={this.state.mediaimg}
                                alt="ad-logo"
                              />
                            </div>
                          </div>
                        </div>

                        <div className={this.state.layout3 ? "mainDiv" : "dnone"}>
                          <h3 className="centerTitle">{this.state.adtitle}</h3>
                          <div className="preview11">
                            <div
                              className={this.state.mediaimg ? "editImgDiv" : "dnone"}
                            >
                              <img
                                className={
                                  this.state.mediaimg ? "editImg" : "dnone"
                                }
                                src={this.state.mediaimg}
                                alt="ad-logo"
                              />
                            </div>
                          </div>
                          <div className="preview12">
                            {Parser(this.state.addescription)}
                          </div>
                        </div>

                        <div className="inlineBtn">
                          <div className="sidebar-content">
                            <div className="sidebar-button">
                              <button
                                type="button"
                                className="reset-button"
                                onClick={this.reset}
                              >
                                Reset
                              </button>
                            </div>
                          </div>

                          <div className="sidebar-content">
                            <div className="sidebar-button">
                              <button
                                type="button"
                                className="reset-button"
                                onClick={this.saveAdvertise}
                                disabled={this.state.isButtonLoding}
                              >
                                {this.state.isButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Save'}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            }
            <div className="product-container">
              <div className="product-box-container">
                <div className="product-boxes">
                  {(!this.state.openPostAdLeftSideBar && !this.state.isPostAdLeftSideBar) &&
                    <div className="PostAd-hamburger-menu" id="mycircle-hamburger">
                      <i class="fa fa-bars" aria-hidden="true" onClick={this.showPostAdLeftSideBar}></i>
                    </div>
                  }
                  {(this.state.openPostAdLeftSideBar && !this.state.isPostAdLeftSideBar) &&
                    <div className="PostAd-hamburger-menu" id="mycircle-hamburger">
                      <i class="fa fa-times" aria-hidden="true" onClick={this.showPostAdLeftSideBar}></i>
                    </div>
                  }
                  <div className="product-header">
                    <h1 className="header">Active / Expired Advertisement</h1>
                    {this.state.isLoaded &&
                      <h2>Total {this.state.total} </h2>
                    }
                  </div>

                  {this.state.isLoaded &&
                    <div className="boxes-container">
                      {this.state.listing.length > 0 ? (
                        this.state.listing.map((lists, key) => (
                          <div className="product-boxes-content" key={key}>
                            <a href={lists.url} >
                              <div className="product-image">
                               {typeof lists.image !== 'undefined' && <img src={lists.image.length > 0 ? lists.image : defaultImg} alt="" /> }
                                
                                
                              </div>
                            </a>

                            <div className="product-info">
                              <div className="product-name">
                                <h2 className="header">{lists.title}</h2>
                              </div>
                              <div className="product-place">
                                <h2 className="header grey-header">
                                  <ShowMoreText
                                    /* Default options */
                                    lines={2}
                                    more="Read more"
                                    less="Read less"
                                    className="content-css"
                                    anchorClass="my-anchor-css-class"
                                    expanded={false}
                                    truncatedEndingComponent={"... "}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: lists.post,
                                      }}
                                    />
                                  </ShowMoreText>
                                </h2>
                              </div>

                              <div className="product-place">
                                <h2 className="header grey-header">
                                  Status : {lists.state === 0 ? "Expired" : "Active"}
                                </h2>
                              </div>


                              {(lists.state === 0 && lists.amount !== 0) ? (
                                <div className="product-edit">
                                  <button
                                    type="button"
                                    className="product-email-button renewPro"
                                    onClick={() =>
                                      this.editJob(
                                        lists.id,
                                        lists.country,
                                        lists.states, 
                                        'renew')
                                    }
                                  >
                                    Renew
                                  </button>
                                </div>
                                ): (lists.state === 1) ? (
                                  <div className="product-edit">
                                    <button
                                      type="button"
                                      className="product-email-button"
                                      onClick={() =>
                                        this.editJob(
                                          lists.id,
                                          lists.country,
                                          lists.states,
                                          'edit'
                                        )
                                      }
                                    >
                                      <img src={postEdit} alt="product-edit" />
                                    </button>
                                  </div>
                                ) : ""
                              }

                            </div>
                          </div>
                        ))
                      ) : (
                        <h2>You haven't added any advertisement</h2>
                      )}
                    </div>
                  }
                </div>
              </div>
              {this.state.loading && <Loader />}
            </div>
          </section>
        </>
      );
    } else {
      return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toast-container"
            toastClassName="dark-toast"
          />
          <section className="product-section">
            <div className="product-container softwares-container">
              <div className="premium-container">
                <div className="parentDiv">
                  <label>Price</label>
                  <span className="labinsep">:</span>

                  {(this.state.stype === 'free') ? <span id="pays">Free</span> :

                    <>
                      <span>

                        {this.state.currency} <span id="pays">{this.state.amt}</span>

                      </span>
                    </>
                  }
                </div>

                <div className="parentDiv">
                  <label>Duration</label>
                  <span className="labinsep">:</span>
                  <select
                    id="sftdur"
                    name="duration"
                    value={this.state.duration}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Select Duration</option>

                    {(this.state.stype === 'free') ?

                      <option value="30 days">30 days</option>

                      :
                      <>
                        <option value="3">15 days</option>
                        <option value="6">30 days</option>
                        <option value="12">60 days</option>
                      </>

                    }
                  </select>

                  {this.durationValidator.message(
                    "Please Select Duration",
                    this.state.duration,
                    "required|string"
                  )}
                </div>

                {(this.state.stype === 'paid') ?
                  <>
                    <div className="parentDiv">
                      <label>Have a Promo Code? : </label>
                      <input
                        type="text"
                        id="prmcde"
                        name="promoCode"
                        value={this.state.promoCode}
                        onChange={this.handleInputChange}
                      />
                      <a id="apprm" onClick={this.promoCodeAPI} href="javascript:void(0);">
                        Apply
                      </a>

                      {this.state.promoCode !== "" && this.state.isValidCode ?
                        <div class="srv-validation-message"> {this.state.promoErrorMsg}</div>
                        : ""}

                      {this.promoCodeValidator.message(
                        "Please Enter Promocode",
                        this.state.promoCode,
                        "required|string"
                      )}

                      {this.InvalidValidator.message(
                        "Invalid Promocode",
                        this.state.promoCode,
                        "required|string"
                      )}

                    </div>
                  </>
                  : ""}

                <div>
                  {(this.state.stype === 'free') ?
                    <button type="submit" className="checkoutBtn ccavaenueBtn" onClick={() => this.addService()}>
                       {this.state.isPublishButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Publish'}
                    </button>

                    :
                    <>
                      {(this.state.currency !== "INR") ?
                      <>
                        <PayPalButtons
                          style={{ layout: "horizontal", color:"black", label:"checkout", tagline: false }}
                          className="paypalBtn"
                          createOrder={(data, actions) => {
                            return actions.order.create({
                              intent: "CAPTURE",
                              purchase_units: [
                                {
                                  reference_id: this.state.referenceId,
                                  amount: {
                                    value: this.state.amt,
                                    currency_code: this.state.currency,
                                  },
                                },
                              ],
                            });
                          }}
                          onClick={() => {
                            if (this.state.duration === "") {
                              toast.error(`Duration should not be empty`, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                              return false
                            }
                          }}

                          onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {

                              this.setState({
                                loading: true,
                                listing: [],
                                isSubmitted: false,
                              });

                              this.addService(
                                this.state.referenceId,
                                details.status,
                                details.purchase_units[0].amount.currency_code,
                                details.purchase_units[0].amount.value,
                              )

                            });
                          }}
                          onCancel={(data) => {

                            // Show a cancel page, or return to cart
                            toast.error(`Transaction cancelled`, {
                              position: "top-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }}

                          onError={(err) => {
                            if (this.state.currency === "INR") {
                              toast.error(`Paypal won't accept ${this.state.currency} please change the currency`, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                            }
                          }
                          }
                        />
                        </>
                        : 
                        <>
                        {/* CC Avenue START */}
                        <form ref={this.ccavenueForm} method="post" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"> 
                          
                          <input type="hidden" name="encRequest" value={this.state.encRequest} />
                          <input type="hidden" name="access_code" value={this.state.access_code} />
                          
                        </form>
                          
                          <button type="button" onClick={() => this.ccavBtn()} className="checkoutBtn ccavaenueBtn" >
                            Checkout
                          </button>
                      
                        {/* CC Avenue END */}
                        </>
                      }
                      
                    </>
                  }
                </div>

                {(this.state.stype === 'paid') ?
                  <>
                    <div id="chcknte">
                      <p>
                        <b>Note: </b>
                      </p>
                      <p>
                        Please note down the reference number{" "}
                        <b>{this.state.referenceId}</b> for this transaction.
                      </p>
                      <p>
                        If the payment process is unsuccessful, then check the status
                        of the payment through "Transaction History" available under
                        "Paid Services" menu. If the status field corresponding to
                        your transaction reference number is "Null" or "Empty", then
                        retry with checkout again.
                      </p>
                      <p>
                        <span className="noteHeading">
                          Price shown above is exclusive of applicable Taxes and
                          Levies.{" "}
                        </span>
                      </p>
                    </div>
                  </> : ""}
              </div>
            </div>
          </section>
        </>
      );
    }
  }
}

export default withRouter(PostAd);
