import "../connectionpage/ConnectionPage.css";
import "../userpage/UserPage.css";
import React, { Component } from "react";
import axios from "axios";

import ChatButton from "../../components/chatbutton/ChatButton";
import { Link, withRouter } from "react-router-dom";
import PendingListSideBar from "./pendinglistsidebar/PendingListSideBar";
import Loader from "../loader/Loader";

export class PendingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pendinglists: [],
      friendLists: [],
      suggestion: [],
      buttonDisable: false,
      loading: false,
      isLoaded: false,

      isPendingLeftSideBar: false,
      openPendingLeftSideBar: false,
    };
  }

  componentDidMount = async () => {
    await this.handleResize();

    this.setState({
      loading: true,
    })

    try {
      const pending = await axios.get(
        `${process.env.REACT_APP_API_URL}pendings-web`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (pending.status === 200 && pending.data.status === true) {
        this.setState({
          pendinglists: pending.data.datas,
          loading: false,
          isLoaded: true,
        });
      }

      else if (pending.status === 403 && pending.data.status === false) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }

      else {
        this.setState({
          isLoaded: true,
          loading: false,
        });
      }

    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      }
    }

    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isPendingLeftSideBar: true,
        openPendingLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isPendingLeftSideBar: false,
      });
    }
  };

  showPendingLeftSideBar = async () => {
    if (this.state.openPendingLeftSideBar) {
      await this.setState({
        openPendingLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openPendingLeftSideBar: true,
      });
    }
  };


  render() {
    return (
      <>
        <section className="user-profile-section">
          {(this.state.isPendingLeftSideBar || this.state.openPendingLeftSideBar) &&
            <PendingListSideBar />
          }

          <div className="user-profile-container">
            {(!this.state.openPendingLeftSideBar && !this.state.isPendingLeftSideBar) &&
              <div className="pendingUser-hamburger-menu" id="mycircle-hamburger">
                <i class="fa fa-bars" aria-hidden="true" onClick={this.showPendingLeftSideBar}></i>
              </div>
            }
            {(this.state.openPendingLeftSideBar && !this.state.isPendingLeftSideBar) &&
              <div className="pendingUser-hamburger-menu" id="mycircle-hamburger">
                <i class="fa fa-times" aria-hidden="true" onClick={this.showPendingLeftSideBar}></i>
              </div>
            }

            {this.state.isLoaded &&
              <div className="user-profile">
                {this.state?.pendinglists?.length > 0 ? (
                  this.state.pendinglists.map((pendinglist, key) => (
                    <div className="user-profiles" key={key}>
                      <div className="user-profile-image ">
                        <img src={pendinglist.pimage} alt="" />
                      </div>
                      <div className="user-profile-info">
                        <Link to={{
                          pathname: `/profileshow/${pendinglist.id}`,
                          state: 1
                        }}>
                          <h2 className="header">{pendinglist.name}</h2>
                        </Link>
                        <h3 className="text">{pendinglist.title}</h3>
                        <h3 className="text">{pendinglist.location}</h3>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="blocked">
                    <h2>There are no pending request.</h2>
                  </div>
                )}
              </div>
            }

            {this.state.loading && <Loader />}
          </div>
        </section>

        <ChatButton />
      </>
    );
  }
}

export default withRouter(PendingList);
