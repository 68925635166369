import React, { Component } from "react";
import "./Terms.css";

export class Terms extends Component {
  render() {
    return (
      <>
        <section className={`about-section ${(localStorage.getItem('token') ? 'after-login-section' : '')}`}>
          <div className="about-section-header">
            <h1 className="header">Privacy Policy, License Agreement and Terms</h1>
          </div>
          <div className="about-section-content">
            <div className="item_fulltext">
              <p className="text para">
                <span>Overview</span>
              </p>
              <p className="text para">&nbsp;</p>              
              <p className="text para">
                <p className="text para">
                  We appreciate your interest in using PEXit<sup>TM</sup>, the
                  World’s Premier Professional Media and e-Commerce Facilitator.
                  PEXit helps to communicate, share, post, search, manage teams
                  and projects, buy and sell goods, services and software. In
                  effect, PEXit, the <u>P</u>
                  rofessional <u>EX</u>change for <u>i</u>
                  ndustries and <u>t</u>echnologies, caters to the requirements
                  of professionals, groups, retailers, suppliers, manufacturers,
                  industrial plants, corporations and governmental agencies.
                  Even students, teachers and communities can use PEXit to carry
                  out assignments and projects.{" "}
                </p>
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                <span>Privacy and Cookies Policy</span>
              </p>
              <p></p>
              <p className="text para">
                <strong>This PRIVACY and COOKIES POLICY </strong>conveys how PEXit collects, uses, shares, stores and manages
                your information. We have built our business based on (a) our integrity, (b) our sincere effort to meet your
                expectations, and (c) our delivery of quality software for decades. Continuing that tradition, we have
                formulated this Policy to educate you on your privacy when using PEXit.
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                The words used in this Privacy and Cookies Policy such as “PEXit”, “we”, “you” etc. carry the same
                meaning as the corresponding words used in the License Agreement as listed below.
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                “PEXit” in this Policy = “Licensed programs” in the License Agreement
              </p>
              <p className="text para">
                “We” or “our” or “us” in this Policy = LICENSOR or LICENSOR’s in the License Agreement
              </p>
              <p className="text para">
                “You” or “your” or “yours” in this Policy = LICENSEE or LICENSEE’s in the License Agreement
              </p>
              <p className="text para">&nbsp;</p>              
              <p className="text para">
                Also, refer to the License Agreement for the definitions of “Information” and “Content”.
              </p>
              <p className="text para">&nbsp;</p>              
              <p className="text para">
                The License Agreement, this Privacy and Cookies Policy and the Terms for Free/Paid Services provided
                in PEXit’s Home Page > Terms are hereinafter collectively referred to as “the Agreement”.
              </p>
              <p className="text para">&nbsp;</p>   
              <p className="text para">
                <span>1. Information Collection and Use</span>
              </p>
              <p className="text para">&nbsp;</p>   
              <p className="text para">
                1.1 PEXit collects your information (both mandatory and voluntary) that are personally identifiable
                such as your name, address, email ID, phone number, educational and professional background
                or group/company/organization particulars etc. when (a) you sign up with PEXit either directly or
                through third party login, (b) you enter your profile, (c) you complete download forms, (d) you
                request pricing and (e) you contact our customer service. Once you have entered such
                information, you are no longer unknown to us.
              </p>
              <p className="text para">&nbsp;</p>   
              <p className="text para">
                1.2 PEXit automatically receives and records on our website host server information such as logs
                from your browser, including your IP address. However, such weblog does not include any
                personally identifiable information of yours.
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                1.3 PEXit collects information about your transactions within PEXit, when you search for job
                postings, products, services and software, and when you visit advertisements and other PEXit
                pages. To such information about you that PEXit collected, we may combine other information
                about you gathered from other sources.
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                1.4 PEXit reserves the right to use your information so gathered for the following purposes.
                <br />
                &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; to fulfil your requests for job postings, products, services, software and placements of 
                advertisements and sponsored content,
                <br />
                &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; to respond to your customer support queries,
                <br />
                &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; to improve PEXit and related software and services,
                <br />
                &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; to conduct research,
                <br />
                &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; to generate anonymous website log reporting, and
                <br />
                &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; to identify keywords for marketing purpose.
              </p>              
              <p className="text para">&nbsp;</p>
              <p className="text para">
                1.5 You agree that the information that you provide on your profile and your content may be seen
                and used by any other user(s) of PEXit and we are not responsible for their use of your
                information. Supplying any voluntary information in your PEXit account is entirely at your
                discretion.
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                <span>2. Cookies Policy</span>
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                2.1 We use cookies and similar technologies to collect information as stated under Article 1 above.
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                2.2 We use cookies and similar technologies, including mobile application identifiers, to help us
                recognize you across different services within PEXit, learn about your interests, improve your
                experience, increase security of information, measure the use and effectiveness of our PEXit
                services, and serve advertisements. You can control cookies through your browser settings and
                other tools.
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                2.3 By accessing and using PEXit, you consent to the placement of cookies and similar technologies
                in your browser and HTML-based emails in accordance with this Policy
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                <span>3. Information Sharing and Disclosure</span>
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                PEXit does not rent, lease, license, sell or share the content and/or the information personally identifiable
                to you with third parties or non-affiliated companies excepting under the following circumstances.
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                3.1 We may use weblog data to compile site statistics.
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                3.2 We may provide the information to our trusted partners/affiliated companies who work on our
                behalf or with us under confidentiality agreements. These partners/affiliated companies may use
                your information to help us to communicate with you about offers from us and/or our marketing
                partners and/or to develop new add-on software to PEXit. However, these partners/affiliated
                companies do not have any right to share this information with any third party without prior written
                consent from us.
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                3.3 We may share the information in order to help investigate, prevent, or take action regarding
                unlawful activities, suspected frauds, potential threats to the physical safety of any person(s),
                violations of our terms of use stated in the Agreement, or otherwise required by law or ordered by
                governing courts.
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                3.4 We transfer information about you if we are acquired or merged with another entity. In this event,
                if there is any material change to the Agreement, we will place a notice to that effect in PEXit.
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                3.5 Even though we are not allowing third party (i.e., non-licensee of PEXit) advertisements
                presently, in case we do in future, we will not provide any personal information of yours to the
                advertiser when you are viewing or interacting with an advertisement within PEXit. However, by
                viewing or interacting with an advertisement within PEXit, you are consenting to the possibility
                that an advertiser may assume that you meet the targeting criteria used to display that
                advertiser’s advertisement.
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                3.6 We may conduct our own surveys and polls and also help third parties to conduct such surveys
                and polls. Your participation in surveys or polls is entirely at your discretion. You may also decline
                invitations or opt out of getting invitations to participate in such surveys or polls.
              </p>


              <p className="text para">&nbsp;</p>
              <p className="text para">
                <span>4. Information Security and Control</span>
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                4.1 Privacy and security of your information is important to us and we have enabled HTTPS access
                to our site (www.pexit.net) and SSL access over mobile devices. We confirm that the information
                and content posted by you is securely hosted in Amazon EC2 which confirms implementation of
                reasonable and appropriate measures designed to help secure your content against accidental
                or unlawful loss, access or disclosure.
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                4.2 We limit access to your information only to our employees and/or our trusted partners/affiliated
                companies who we believe reasonably need to come into contact with that information only (a) to
                provide products or services or software to you, (b) to carry out your instructions, (c) to add new
                features and functionality in PEXit, and/or (d) when it is required by law, subpoena or other legal
                process.
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                4.3 When you post a content or information in PEXit using the “Public” setting, it means that you are
                allowing everyone, including all users of PEXit as well as third parties (i.e., non-licensees of
                PEXit) to access and use that specific content or information of yours and to associate that
                content or information with you including your name and profile.
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                4.4 If you collect and use information from other users of PEXit, you will (a) obtain their consent, (b)
                make it clear that you (and not PEXit) are the one collecting their information, and (c) post your
                own privacy policy explaining what information you collected and how you will use it and accept
                all liabilities associated with that usage.
              </p>
              
              <p className="text para">&nbsp;</p>
              <p className="text para">
                <span>5. General Terms</span>
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                5.1 This Privacy and Cookies Policy does not apply to the practices of entities that we do not own or
                control, or to individuals that we do not employ, contract or manage. In addition, certain entities
                associated with us (such as our trusted partners/affiliated companies) may have their own
                privacy statements which may be obtained directly from them.
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                5.2 We retain the sole right to update this Policy from time to time without obtaining any prior consent
                from any user(s) of PEXit and related software. If we make material changes to this Policy, we
                will provide notice through PEXit or by other means to all the then current PEXit users. If you do
                not accept those material changes, you may close your account with PEXit. Your continued use
                of PEXit following such notice means that you consent to those changes.
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                5.3 For queries on PEXit, our current contact details are as follows.
                <p className="text para">&nbsp;</p>                
                <p className="text para">
                  InfoPlant Technologies Private Limited<br/>
                  7 Crescent Road, High Grounds<br/>
                  Bangalore 560 001, India<br/>
                  Email: support@pexit.net
                </p>
              </p>     

              <p className="text para">&nbsp;</p>
              <p className="text para">
                <span>License Agreement to use PEXit</span>
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                <p className="text para">
                  For License Agreement to use PEXit,{" "}
                  <a
                    className="line"
                    href="./images/Presentation/PEXitLicenseAgreement.pdf"
                    target="_blank"
                  >
                    click here
                  </a>
                  .
                </p>
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                <span>Terms related to Free Services by PEXit</span>
              </p>

              <p className="text para">&nbsp;</p>
              <p className="text para">
                <p className="text para">
                  For Terms related to Free Services by PEXit,{" "}
                  <a className="line" href="./pricing">
                    click here
                  </a>
                  .{" "}
                </p>
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                <p className="text para">
                  By clicking “Join Now” or “Log In” or similar in PEXit, you
                  acknowledge that you have read the above said License
                  Agreement, Privacy and Cookies Policy and Terms for Paid
                  Services and agree to all terms and conditions stated in those
                  documents.&nbsp;{" "}
                </p>
              </p>
              <p className="text para">&nbsp;</p>
              <p className="text para">
                <p className="text para">
                  If the terms of the License Agreement and/or the Privacy and
                  Cookies Policy and/or the Terms for Paid Services given above
                  are not acceptable to you, then you should NOT click “Join
                  Now” or “Log In” or similar in PEXit and should NOT access and
                  use PEXit.{" "}
                </p>
              </p>{" "}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Terms;
