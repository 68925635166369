import "../software/Software.css";
import "../product/Product.css";
import "../addsoftware/AddSoftware.css";
import "../postPage/Post-page.css";
import "./premium.css";

import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import SimpleReactValidator from "simple-react-validator";
import {
  PayPalButtons,
} from "@paypal/react-paypal-js";

const nodeCCAvenue = require("node-ccavenue");


export class Premium extends Component {
  constructor(props) {
    super(props);

    this.durationValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.promoCodeValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.InvalidValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: ":attribute",
        alpha: "The :attribute must be string.",
      },
    });

    this.state = {
      amt: "",
      duration: "",
      promoCode: "",
      referenceId: "",
      encRequest: "",
      access_code: "AVBZ71EF36BA67ZBAB",
      tripleMonths: false,
      singleMonths: false,
      days: false,
      currency:"",
      fptype:"",
      sname:"",
    };
  }

  promoCodeAPI = async () => {
    var data = {
      amount: this.state.amt,
      service: "sc",
      promocode: this.state.promoCode,
    };

    if (data.amount !== "") {
      this.durationValidator.hideMessages();
      this.forceUpdate();

      if (data.promocode !== "") {
        this.promoCodeValidator.hideMessages();

        try {
          const promoCode = await axios.post(
            `${process.env.REACT_APP_API_URL}fpservices/promocode`,
            data,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );

          if (promoCode.status === 200 && promoCode.data.status === true) {
            await this.setState({
              amt: promoCode.data.datas,
            });

            toast.success(promoCode.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(
              promoCode.data.errors
                ? promoCode.data.errors
                : promoCode.data.message,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        } catch (err) {
          if (err.response.status === 403) {
            localStorage.removeItem("token");
            window.location = process.env.PUBLIC_URL;
          } else if(err?.response?.data?.error !== '' ){
            toast.error(err?.response?.data?.error, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      } else {
        this.promoCodeValidator.showMessages();
        this.forceUpdate();
      }
    } else {
      this.durationValidator.showMessages();
      this.forceUpdate();
    }
  };

  handleInputChange = async (e) => {

    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });

    if (e.target.name === "duration") {
      
      var threeMonths;
      var sixMonths;
      var twelveMonths;

      var ptype = this.props.location.state.stype;

      var currency = this.state.currency;

      switch (ptype) {
        case "sc":

            if(currency === "INR") {
              threeMonths = 2000;
              sixMonths = 4000;
              twelveMonths = 6000;
            }
            
            else if(currency === "USD"){
              threeMonths = 100;
              sixMonths = 200;
              twelveMonths = 300;
            }

            else if(currency === "EUR"){
              threeMonths = 100;
              sixMonths = 200;
              twelveMonths = 300;
            }

            else if(currency === "HKD"){
              threeMonths = 500;
              sixMonths = 1000;
              twelveMonths = 1500;
            }

            else if(currency === "JPY"){
              threeMonths = 10000;
              sixMonths = 20000;
              twelveMonths = 30000;
            }

            else if(currency === "GBP"){
              threeMonths = 75;
              sixMonths = 150;
              twelveMonths = 225;
            }

            else if(currency === "AUD"){
              threeMonths = 100;
              sixMonths = 200;
              twelveMonths = 300;
            }

            else{
              threeMonths = 100;
              sixMonths = 200;
              twelveMonths = 300;
            }
        break;
      
        case "sw":

          if(currency === "INR") {
            threeMonths = 6000;
            sixMonths = 9600;
            twelveMonths = 14400;  
          }
          
          else if(currency === "USD"){
            threeMonths = 300;
            sixMonths = 480;
            twelveMonths = 720;
          }

          else if(currency === "EUR"){
            threeMonths = 300;
            sixMonths = 480;
            twelveMonths = 720;
          }

          else if(currency === "HKD"){
            threeMonths = 1500;
            sixMonths = 2400;
            twelveMonths = 3600;
          }

          else if(currency === "JPY"){
            threeMonths = 30000;
            sixMonths = 48000;
            twelveMonths = 72000;
          }

          else if(currency === "GBP"){
            threeMonths = 225;
            sixMonths = 360;
            twelveMonths = 540;
          }

          else if(currency === "AUD"){
            threeMonths = 300;
            sixMonths = 480;
            twelveMonths = 720;
          }

          else{
            threeMonths = 300;
            sixMonths = 480;
            twelveMonths = 720;
          }

        break;

        case "pr":

          if(currency === "INR") {
            threeMonths = 4000;
            sixMonths = 6400;
            twelveMonths = 9600;  
          }
          
          else if(currency === "USD"){
            threeMonths = 200;
            sixMonths = 320;
            twelveMonths = 480;
          }

          else if(currency === "EUR"){
            threeMonths = 200;
            sixMonths = 320;
            twelveMonths = 480;
          }

          else if(currency === "HKD"){
            threeMonths = 1000;
            sixMonths = 1600;
            twelveMonths = 2400;
          }

          else if(currency === "JPY"){
            threeMonths = 20000;
            sixMonths = 32000;
            twelveMonths = 48000;
          }

          else if(currency === "GBP"){
            threeMonths = 150;
            sixMonths = 240;
            twelveMonths = 360;
          }

          else if(currency === "AUD"){
            threeMonths = 200;
            sixMonths = 320;
            twelveMonths = 480;
          }

          else{
            threeMonths = 200;
            sixMonths = 320;
            twelveMonths = 480;
          }
          
        break;

        case "jb":

          if(currency === "INR") {
            threeMonths = 3200;
            sixMonths = 4800;
            twelveMonths = 7200;
          }
          
          else if(currency === "USD"){
            threeMonths = 160;
            sixMonths = 240;
            twelveMonths = 360;
          }

          else if(currency === "EUR"){
            threeMonths = 160;
            sixMonths = 240;
            twelveMonths = 360;
          }

          else if(currency === "HKD"){
            threeMonths = 800;
            sixMonths = 1200;
            twelveMonths = 1800;
          }

          else if(currency === "JPY"){
            threeMonths = 16000;
            sixMonths = 24000;
            twelveMonths = 36000;
          }

          else if(currency === "GBP"){
            threeMonths = 120;
            sixMonths = 180;
            twelveMonths = 270;
          }

          else if(currency === "AUD"){
            threeMonths = 160;
            sixMonths = 240;
            twelveMonths = 360;
          }

          else{
            threeMonths = 160;
            sixMonths = 240;
            twelveMonths = 360;
          }

        break;

        case "ad":

          if(currency === "INR") {
            threeMonths = 4000;
            sixMonths = 7200;
            twelveMonths = 9600;
          }
          
          else if(currency === "USD"){
            threeMonths = 240;
            sixMonths = 360;
            twelveMonths = 480;
          }

          else if(currency === "EUR"){
            threeMonths = 240;
            sixMonths = 360;
            twelveMonths = 480;
          }

          else if(currency === "HKD"){
            threeMonths = 1200;
            sixMonths = 1800;
            twelveMonths = 2400;
          }

          else if(currency === "JPY"){
            threeMonths = 24000;
            sixMonths = 36000;
            twelveMonths = 48000;
          }

          else if(currency === "GBP"){
            threeMonths = 180;
            sixMonths = 270;
            twelveMonths = 360;
          }

          else if(currency === "AUD"){
            threeMonths = 240;
            sixMonths = 360;
            twelveMonths = 480;
          }

          else{
            threeMonths = 240;
            sixMonths = 360;
            twelveMonths = 480;
          }

        break;

        case "sv":

          if(currency === "INR") {
            threeMonths = 6000;
            sixMonths = 9600;
            twelveMonths = 14400;
          }
          
          else if(currency === "USD"){
            threeMonths = 300;
            sixMonths = 480;
            twelveMonths = 720;
          }

          else if(currency === "EUR"){
            threeMonths = 300;
            sixMonths = 480;
            twelveMonths = 720;
          }

          else if(currency === "HKD"){
            threeMonths = 1500;
            sixMonths = 2400;
            twelveMonths = 3600;
          }

          else if(currency === "JPY"){
            threeMonths = 30000;
            sixMonths = 48000;
            twelveMonths = 72000;
          }

          else if(currency === "GBP"){
            threeMonths = 225;
            sixMonths = 360;
            twelveMonths = 540;
          }

          else if(currency === "AUD"){
            threeMonths = 300;
            sixMonths = 480;
            twelveMonths = 720;
          }

          else{
            threeMonths = 300;
            sixMonths = 480;
            twelveMonths = 720;
          }
        break;

        default:
          break;
      }

      if (e.target.value?.replace(/^\s+/g, '') === "3") {
        await this.setState({
          amt: threeMonths, //parseFloat(4000 / convertAmt.data.USD_INR).toFixed(2),
        });
      } else if (e.target.value?.replace(/^\s+/g, '') === "6") {
        await this.setState({
          amt: sixMonths, //parseFloat(6400 / convertAmt.data.USD_INR).toFixed(2),
        });
      } else if (e.target.value?.replace(/^\s+/g, '') === "12") {
        await this.setState({
          amt: twelveMonths, //parseFloat(9600 / convertAmt.data.USD_INR).toFixed(2),
        });
      } else {
        await this.setState({
          amt: "",
        });
      }
    }
  };

  addService = async (oid, ostatus, curr, amt) => {

    const formDetails = this.props.location.state.formData;

    if(this.state.fptype === 'paid') {
      if(ostatus === 'COMPLETED') {
        ostatus = 'Success';
      }

    }

    var createProduct;

    if (this.state.sname === 'product') {

      formDetails['pduration'] = this.state.duration;
      formDetails['ppaid'] = amt > 0 ? amt : 0;
      formDetails['pcurrency'] = curr;
      formDetails['ptax'] = "18";
      formDetails['porderid'] = oid;
      formDetails['ppromocode'] = "";

      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/product`,
        formDetails,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

    }

    else if (this.state.sname === 'publish-advertisement') {

      formDetails['adduration'] = this.state.duration;
      formDetails['adpaid'] = amt > 0 ? amt : 0;
      formDetails['adcurrency'] = curr;
      formDetails['adtax'] = "18";
      formDetails['adorderid'] = oid;
      formDetails['adpromocode'] = "";

      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/ads`,
        formDetails,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    }

    else if (this.state.sname === 'sponsored') {

      formDetails['scpaid'] = amt > 0 ? amt : 0;
      formDetails['sccurrency'] = curr;
      formDetails['sctax'] = "18";
      formDetails['scorderid'] = oid;
      formDetails['scpromocode'] = "";


      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/sponsor`,
        formDetails,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    }

    else if (this.state.sname === 'job') {

      formDetails['jduration'] = this.state.duration;
      formDetails['jpaid'] = amt > 0 ? amt : 0;
      formDetails['jcurrency'] = curr;
      formDetails['jtax'] = "18";
      formDetails['jorderid'] = oid;
      formDetails['jpromocode'] = "";

      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/job`,
        formDetails,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    }

    else if (this.state.sname === 'software') {

      formDetails['stduration'] = this.state.duration;
      formDetails['stpaid'] = amt > 0 ? amt : 0;
      formDetails['stcurrency'] = curr;
      formDetails['sttax'] = "18";
      formDetails['storderid'] = oid;
      formDetails['stpromocode'] = "";

      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/software`,
        formDetails,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    }

    else if (this.state.sname === 'service') {

      formDetails['sduration'] = this.state.duration;
      formDetails['spaid'] = amt > 0 ? amt : 0;
      formDetails['scurrency'] = curr;
      formDetails['stax'] = "18";
      formDetails['sorderid'] = oid;
      formDetails['spromocode'] = "";

      createProduct = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/service`,
        formDetails,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
    }

    if (createProduct.status === 200 && createProduct.data.status === true) {
      
      if(this.state.fptype === 'paid') {
        this.payment(oid, ostatus, curr, createProduct.data.datas);
      } else {

        toast.success(createProduct.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      }
    }
  }

  payment = async (oid, ostatus, curr, serID) => {

    // const serID = this.props.location.state.serId;
    const type = this.props.location.state.stype;

    const data = {
      paymentType: "1",
      currency: curr,
      productType: type,
      orderStatus: ostatus,
      orderId: oid,
      serId: serID,
      promoCode: this.state.promoCode,
    };

    try {
      const payment = await axios.post(
        `${process.env.REACT_APP_API_URL}fpservices/payment`,
        data,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (payment.status === 200 && payment.data.status === true) {
        toast.success(payment.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        
      } else {
        toast.error(
          payment.data.errors ? payment.data.errors : payment.data.message,
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (err) {
      if (err.response.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if(err?.response?.data?.error !== '' ){
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  componentDidMount = async () => {

    const userData = localStorage.getItem("userData")

    const userDetails = JSON.parse(userData)

    var currency = "USD";

    if(userDetails.country === "India") {
      currency = "INR";
    } else if(userDetails.country === "United States"){
      currency = "USD";
    } else{
      currency = "USD";
    }
    
    await this.setState({
      currency: currency,
    })

    const uid = userDetails.userId;
    const type = this.props.location.state.stype;
    const fptype = this.state.fptype;
    const sname = this.props.location.state.sname;

    this.setState({
      fptype:fptype,
      sname:sname,
    })

    switch (type) {
      case "sc":
        await this.setState({
          tripleMonths: false,
          singleMonths: true,
          days: false
        });
        break;
    
      case "sw":
        await this.setState({
          tripleMonths: true,
          singleMonths: false,
          days: false
        });
        break;

      case "pr":
        await this.setState({
          tripleMonths: true,
          singleMonths: false,
          days: false
        });
      break;

      case "jb":
        await this.setState({
          tripleMonths: false,
          singleMonths: false,
          days: true
        });
      break;

      case "ad":
        await this.setState({
          tripleMonths: false,
          singleMonths: false,
          days: true
        });
      break;

      case "sv":
        await this.setState({
          tripleMonths: true,
          singleMonths: false,
          days: false
        });
      break;

      default:
        break;
    }

    var CurrentDate = moment().unix();

    var referenceId = uid + "-" + type + "-" + CurrentDate;

    await this.setState({
      referenceId: referenceId,
    });

    const ccav = new nodeCCAvenue.Configure({
      merchant_id: process.env.REACT_APP_CCAVENUE_MERCHANT_ID,
      working_key: process.env.REACT_APP_CCAVENUE_LIVE_WORKING_KEY,
    });

    const orderParams = {
      merchant_id: '137823',
      order_id: '8765432',
      currency: "INR",
      amount: "1",
      redirect_url: encodeURIComponent(
        `https://pexit.net/node/try-premium`
      ),
      billing_name: "Name of the customer",
    };

    const encryptedOrderData = ccav.getEncryptedOrder(orderParams);

    await this.setState({
      encRequest: encryptedOrderData,
    });
  };

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        <section className="product-section">
          <div className="product-container softwares-container">
            <div className="premium-container">
              <div className="parentDiv">
                <label>Price</label>
                <span className="labinsep">:</span>

                {(this.state.fptype === 'free') ? <span id="pays">Free</span> : 

                <>
                <span>

                  {this.state.currency} <span id="pays">{this.state.amt}</span>
                  
                </span>
                </>
              }
              </div>

              <div className="parentDiv">
                <label>Duration</label>
                <span className="labinsep">:</span>
                <select
                  id="sftdur"
                  name="duration"
                  value={this.state.duration}
                  onChange={this.handleInputChange}
                >
                <option value="">Select Duration</option>

                  {(this.state.fptype === 'free') ?
                  
                    (this.state.sname === 'job' || this.state.sname === 'publish-advertisement') ? 

                    <option value="30 days">30 Days</option>
                  
                    : (this.state.sname === 'sponsored') ?

                    <option value="3 months">Quarterly</option>
                    :

                    <option value="12 months">12 Months</option>
                 
                  :

                (this.state.singleMonths) ?

                  <>  
                  
                    <option value="3">1 Month</option>
                    <option value="6">2 Month</option>
                    <option value="12">3 Months</option>
                  </>
                : (this.state.tripleMonths) ? 
                  <>
                    <option value="3">3 Months</option>
                    <option value="6">6 Months</option>
                    <option value="12">12 Months</option>
                  </>
                :
                <>
                  <option value="3">15 days</option>
                  <option value="6">30 days</option>
                  <option value="12">60 days</option>
                </>
                }
                </select>

                {this.durationValidator.message(
                  "Please Select Duration",
                  this.state.duration,
                  "required|string"
                )}
              </div>

              <div className="parentDiv">
                <label>Have a Promo Code? : </label>
                <input
                  type="text"
                  id="prmcde"
                  name="promoCode"
                  value={this.state.promoCode}
                  onChange={this.handleInputChange}
                />
                <a id="apprm" onClick={this.promoCodeAPI} href="javascript:void(0);">
                  Apply
                </a>

                {this.promoCodeValidator.message(
                  "Please Enter Promocode",
                  this.state.promoCode,
                  "required|string"
                )}

                {this.InvalidValidator.message(
                  "Invalid Promocode",
                  this.state.promoCode,
                  "required|string"
                )}
              </div>

              <div>
              {(this.state.fptype === 'free') ? 
                <button type="submit" className="checkoutBtn ccavaenueBtn" onClick={() => this.addService()}>
                Publish
                </button>

              : 
              <>
                <PayPalButtons
                  style={{ layout: "horizontal" }}
                  className="paypalBtn"
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      intent: "CAPTURE",
                      purchase_units: [
                        {
                          reference_id: this.state.referenceId,
                          amount: {
                            value: this.state.amt,
                            currency_code: "USD",//this.state.currency,
                          },
                        },
                      ],
                    });
                  }}
                  onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {

                      this.addService(
                        data.orderID,
                        details.status,
                        details.purchase_units[0].amount.currency_code,
                        details.purchase_units[0].amount.value,
                      )

                    });
                  }}
                  onCancel={(data) => {
                    
                    // Show a cancel page, or return to cart
                    toast.error(`Transaction cancelled`, {
                      position: "top-right",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }}

                  onError={(err) => {
                    if (this.state.currency === "INR") {
                      toast.error(`Paypal won't accept ${this.state.currency} please change the currency`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }}
                  }
                />

                {/* CC Avenue START */}
                <form
                  method="post"
                  name="redirect"
                  action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
                >
                  <input
                    type="hidden"
                    name="encRequest"
                    value={this.state.encRequest}
                  />
                  <input
                    type="hidden"
                    name="access_code"
                    value={this.state.access_code}
                  />
                   <input
                    type="hidden"
                    name="merchant_id"
                    value="137823"
                  />
                   <input
                    type="hidden"
                    name="working_key"
                    value="49C7EFB51D2220A8E658D5813E13F57F"
                  />
                  <button type="submit" className="checkoutBtn ccavaenueBtn" >
                    Checkout
                  </button>
                </form>
                 {/* CC Avenue END */}

                 {/* <PayPalScriptProvider options={this.initialOptions}> */}

                {/* </PayPalScriptProvider> */}
                </>
              }
              </div>

              {(this.state.fptype === 'paid') ?
              <>
              <div id="chcknte">
                <p>
                  <b>Note: </b>
                </p>
                <p>
                  Please note down the reference number{" "}
                  <b>{this.state.referenceId}</b> for this transaction.
                </p>
                <p>
                  If the payment process is unsuccessful, then check the status
                  of the payment through "Transaction History" available under
                  "Paid Services" menu. If the status field corresponding to
                  your transaction reference number is "Null" or "Empty", then
                  retry with checkout again.
                </p>
                <p>
                  <span className="noteHeading">
                    Price shown above is exclusive of applicable Taxes and
                    Levies.{" "}
                  </span>
                </p>
              </div>
              </> : ""}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(Premium);
