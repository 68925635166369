import "../chatpage/ChatPage.css";
import "../../components/modals/Popup.css";
import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import { Dropdown } from 'react-bootstrap';
import ShowMoreText from "react-show-more-text";
import ChatButton from "../../components/chatbutton/ChatButton";
import { ChatSideBar } from "./chatsidebar/ChatSideBar";
import threeDots from "../../../assets/3-dots.png";
import messageNew from "../../../assets/message-new.png";
import chatArrow from "../../../assets/chat-arrow.png";
import { copyFileSync } from "fs";

export class ChatPage extends Component {
  constructor(props) {
    super(props);

    //dynamic validation for title field and content field
    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.MSGvalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.FMSGvalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.AddPeoplevalidator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.myRef = React.createRef();
    this.mesRef = React.createRef();
    this.menu_dots = React.createRef();
    this.forward_messages = React.createRef();

    this.state = {
      messageModal: false,
      addPeopleModal: false,
      leaveModal: false,
      fsearch: "",
      searchConnections: [],
      fields: "",
      dropdown: "",
      friendId: "",
      message: "",
      loggedUserName: "",
      loggedUserAvtar: "./images/no-image.png",

      searchTerm: "",
      isSelects: true,
      selectedOption: [],
      selectedPeoples: [],
      options: [],
      searchFriendId: "",
      allIdShow: [],

      chatListing: [],
      isActive: "",
      openChat: "",
      openChatId: "",
      openListing: {},
      activeChatId: "",
      activeUserName: "",
      activeUserImage: "",
      userUid: "",
      fromId: "",
      toId: "",

      setMsgType: "",
      putListing: [],
      putmessage: "",
      filterAllMsg: [],
      filterListChats: [],
      lastMessage: "",
      listUserName: "",
      listUserImage: "",

      isGroup: false,
      gtoid: "",
      usersList: [],
      leaveGId: "",
      isAdmin: false,

      isForwordMessages: false,
      forwardUsers: [],
      forwardMessages: [],
      selectedChat: null,

      isLeft: false,
      isLeftSideBar: false,
      openLeftSideBar: false,

      thisIsGroup: false,

      deleteModal:false,

      page: 0,
      nextAvailable: true,
      perPage: 12,

      loading:false,
      ttoids: "",
      listing:[],
      atTop:false,
    };
  }

  handleInputField = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });
  };

  handleInputChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });
    this.searchFriend();

    var dropdown = document.getElementsByClassName("dropdown-container-one")[0];

    dropdown.style.display = "block";
  };

  showMsgModal = async () => {
    const userData = localStorage.getItem("userData");

    const userDetails = JSON.parse(userData);

    const messageModal = this.state.messageModal === false ? true : false;
    await this.setState({
      messageModal: messageModal,
      loggedUserName: userDetails.userName,
    });
  };

  hideMsgModal = async () => {


    this.validator.hideMessages();
    this.forceUpdate();

    await this.setState({
      messageModal: false,
    });
  };

  handleChange = async (e) => {
    let isSelects = this.state.isSelects;

    await this.setState({
      isSelects: isSelects,
    });
  };

  handleSelected = (selectedOption) => {
    this.setState({ ...selectedOption, selectedOption });
  };

  handleForwardChange = (forwardUsers) => {
    this.setState({ ...forwardUsers, forwardUsers });
  };

  searchFriend = async (term) => {
    const data = {
      nskey: term,
    };

    try {
      const formres = await axios.post(
        `${process.env.REACT_APP_API_URL}message/friends/search`,
        data,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (formres.status === 200 && formres.data.status === true) {
        await this.setState({
          searchConnections: formres.data.datas,
          options: formres.data.datas.map((form) => {
            return { value: form.id, label: `${form.username}`  };
          }),
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  searchField = async (e, username, id) => {
    await this.setState({
      fsearch: username,
      friendId: id,
    });

    var dropdown = document.getElementsByClassName("dropdown-container-one")[0];

    dropdown.style.display = "none";
  };

  preventForm = (e) => {
    e.preventDefault();
  };

  // send message
  sendMessage = async () => {
    if (this.validator.allValid()) {
      this.hideMsgModal();
      this.setState({
        selectedOption: "",
        message: "",
      })


      const userData = localStorage.getItem("userData");

      const userDetails = JSON.parse(userData);

      const msgData = {
        chatterIds: this.state.selectedOption.reduce((prev, curr) => {
          return prev !== "" ? prev + "," + curr.value : prev + curr.value;
        }, ""),
        toIds: userDetails.userId,
        chatterMessage: this.state.message,
      };

      try {
        const message = await axios.post(
          `${process.env.REACT_APP_API_URL}message/send-web`,
          msgData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (message.status === 200 && message.data.status === true) {

          await this.chatList();
          toast.success(message.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(message.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  shouldSearchTimer = null;

  handleOnSearchInput = async (e) => {
    this.setState({ searchTerm: e });
    if (this.shouldSearchTimer) {
      clearTimeout(this.shouldSearchTimer);
    }

    this.shouldSearchTimer = setTimeout(() => {
      this.shouldSearchTimer = null;
      if (e !== "") this.searchFriend(e);
    }, 500);
  };

  componentDidMount = async () => {
    await this.handleResize();

    await this.setState({
      loggedUserAvtar: (localStorage.getItem('profileImage') ? localStorage.getItem('profileImage') : this.state.loggedUserAvtar)
    })
    await this.chatList();
    var c = this.state.chatListing[0];
    if (c) {
      this.setState({
        selectedChat: c
      })
      let chatId = c.pimage.map((dataid) => {
        return dataid.muid;
      })[0];

      let chatUserName = (c.pimage.length > 1) ? c.pimage[0].uname + ` and - ${c.pimage.length - 1} more` : c.pimage[0].uname;
      let chatUserImage = (c.type === 1) ? c.pimage[0].pimage : process.env.PUBLIC_URL + '/images/un.jpg';
      this.chatShow(chatId, chatUserName, chatUserImage, c.toid, c.pimage)
    }


    await window.addEventListener("resize", this.handleResize);
    this.mesRef.current.addEventListener("scroll", this.listenToScroll);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      await this.setState({
        isLeftSideBar: true,
        openLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        isLeftSideBar: false,
      });
    }
  }

  listenToScroll = async () => {

    if (this.mesRef.current.scrollTop === 0) {

      this.setState({
        atTop:true,
      })

    if (this.state.loading === false) {

        if (this.mesRef.current.scrollTop === 0) {
        
          await this.chatShow(this.state.activeChatId, this.state.activeUserName, this.state.activeUserImage, this.state.ttoids, this.state.usersList);
          
        }
      }
    }
  };

  // chat list
  chatList = async () => {

      try {
        const list = await axios.get(
          `${process.env.REACT_APP_API_URL}message/chat/list-web`,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (list.status === 200 && list.data.status === true) {
          list.data.datas.map((grp) => {
            const group = grp.toid.split(":");

            if (group.includes('g')) {
              this.setState({
                isGroup: true,
              })
            }

          })

          let d = list.data.datas.sort((a, b) => {
            return ((a.unreadChatCount < b.unreadChatCount) ? 1 : ((a.unreadChatCount > b.unreadChatCount) ? -1 : 0));
          })

          d = d.sort((a, b) => {
            return ((a.messageDate < b.messageDate) ? 1 : ((a.messageDate > b.messageDate) ? -1 : 0));
          })

          await this.setState({
            chatListing: d,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
  };

  // chat
  chatShow = async (chatId, chatName, chatUserImage, toid, userList) => {
    
      if (this.state.nextAvailable) {
        
        await this.setState({
          loading: true,
          page: this.state.page + 1,
          ttoids: toid,
        });

      const userData = localStorage.getItem("userData");

      const userDetails = JSON.parse(userData);

      const toidArray = toid.split(":");
      if (toidArray.includes("g")) {
        chatId = toid;
        await this.setState({
          gtoid: toidArray[1],
          usersList: userList,
        })
      }

      try {
        const chat = await axios.get(
          `${process.env.REACT_APP_API_URL}message/chat-web/${chatId}/${parseInt(this.state.page)}/${parseInt(this.state.perPage)}`,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        var reverseConvo = !!chat.data.datas.conversation ? chat.data.datas.conversation.reverse() : [];

        if (chat.status === 200 && chat.data.status === true) {

          let oldArray = this.state.listing;
          oldArray = (this.state.page === 1) ? reverseConvo : reverseConvo.concat(oldArray);

          if (chat.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }

          await this.setState({
            openListing: chat.data.datas,
            activeChatId: chatId,
            activeUserName: chatName,
            activeUserImage: chatUserImage,
            listing:oldArray,
          });

          if(chat.data.datas.adminId){
            this.setState({
              thisIsGroup : true,
            }) 
          } else {
            
              this.setState({
                thisIsGroup : false,
              }) 
          }

          for (let index = 0; index < this.state.openListing?.conversation?.length; index++) {
            const el = this.state.openListing?.conversation[index];

            if (el.leftStatus === 1 && el.fromid === userDetails.userId) {
              this.setState({
                isLeft: true,
              })
            } else {
              this.setState({
                isLeft: false,
              })
            }
          
          }

          if (chat.data.datas.adminId === userDetails.userId) {
            this.setState({
              isAdmin: true,
            })
          }

            this.chatList();
          
        } else {
          
        }
      } catch (err) {

        this.setState({
          openListing: {}
        })
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      const from = this.state?.openListing?.conversation?.map((id) => {
        return id.fromid;
      });
      const toId = this.state?.openListing?.conversation?.map((id) => {
        return id.toid;
      });
      this.setState({
        fromId: from,
        toId: toId,
        userUid: userDetails.userId,
        isForwordMessages: false
      });

      if (!this.state.atTop) {
        window.scrollTo(0, document.body.scrollHeight);
        this.scrollToBottom();
      }
      await this.setState({
        loading: false,
      });
    }
  };

  // message chat muid
  openChat = async (muid) => {
    await this.setState({
      openChatId: muid,
    });
  };

  // message type
  putMessage = (msg) => {
    this.setState({
      setMsgType: msg,
    });
  };

  // handle change for input field
  handleMessage = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, '')?.replace(/^\s+/g, ''),
    });
  };

  // send message on send button
  sendPutMsg = async () => {
    if (this.MSGvalidator.allValid()) {
      const userData = localStorage.getItem("userData");
      const userDetails = JSON.parse(userData);
      const msgPut = {
        chatterIds: this.state.activeChatId,
        toIds: userDetails.userId,
        chatterMessage: this.state.putmessage.replace(/^\s+/g, ''),
      };

      try {
        const putData = await axios.post(
          `${process.env.REACT_APP_API_URL}message/send-web`,
          msgPut,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (putData.status === 200 && putData.data.status === true) {
          let lastMSG = { ...this.state.openListing.conversation[0] };
          lastMSG.message = msgPut.chatterMessage
          lastMSG.timeAgo = "a few seconds ago";
          lastMSG.date = moment().format('YYYY-MM-DDTHH:mm:ssZ')
          lastMSG.dateTimeStamp = moment().valueOf()
          lastMSG.fromid = userDetails.userId;
          lastMSG.mid = putData.data.messageId;
          lastMSG.forward = 0;
          lastMSG.fpname = userDetails.userName;
          lastMSG.fDetails = [{}];

          this.state.openListing.conversation.push(lastMSG);
          let openListing = this.state.openListing;

          await this.setState({
            openListing: openListing,
          });
          this.scrollToBottom();

          // await this.filterMsgs(this.state.openListing);

          // toast.success(putData.data.message, {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });

          // this.chatShow(this.state.activeChatId);
        } else {
          toast.error(putData.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      <ChatSideBar
        chatList={this.chatList}
        chatListing={this.state.chatListing}
        messages={this.putMessage}
        chatShow={this.chatShow}
        msgsPut={this.sendPutMsg}
        filterData={this.state.lastMessage}
        chatId={this.state.activeChatId}
        userList={this.specificUserName}
        scrollBoxBottom={this.scrollToBottom}
      />;


      this.setState({
        putmessage: "",
      });

      this.MSGvalidator.hideMessages();
      this.forceUpdate();
    } else {
      this.MSGvalidator.showMessages();
      this.forceUpdate();
    }
  };

  filterMsgs = (allMsgs) => {
    this.setState({
      filterAllMsg: allMsgs,
      openListing: [],
    });
  };

  specificUserName = (uname, pimage) => {
    this.setState({
      listUserName: uname[0],
      listUserImage: pimage[0],
    });
  };

  scrollToBottom = () => {
    this.mesRef.current.scrollTop = this.mesRef.current.scrollHeight - 100;
  };

  // Left Sidebar methods START
  handleClick = async (chatId, chatUserName, chatUserImage, chat) =>{

    await this.setState({
      page: 0,
      nextAvailable: true,
      perPage: 12,

      loading:false,
      ttoids: "",
      listing:[],
      atTop:false,

    });
    
    this.chatShow(chatId, chatUserName, chatUserImage, chat.toid, chat.pimage);

    this.setState({
      activeChatId: chatId,
      listUserName: chatUserName,
      listUserImage: chatUserImage,
      selectedChat: chat,
      openLeftSideBar: false,
      
    });

    window.scroll(0, 0);
    this.scrollToBottom();
  }

  deleteModal = async (fid, type) => {
    const deleteModal = this.state.deleteModal === false ? true : false;

    await this.setState({
      deleteModal: deleteModal,
      deleteFId: fid,
      deleteFType: type === 1 ? "direct" : "group",
    });
  };

  hideDeleteModal = async () => {
    await this.setState({
      deleteModal: false,
    });
  }

  // delete conversation
  deleteConversation = async () => {
    const mtype = this.state.deleteFType;
    const fid = this.state.deleteFId;

    try {
      const deleteData = await axios.delete(
        `${process.env.REACT_APP_API_URL}message/${mtype}/${fid}`,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (deleteData.status === 200 && deleteData.data.status === true) {
        this.hideDeleteModal();
        toast.success(deleteData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        await this.chatList();
        var c = this.state.chatListing[0];
        if (c) {
          this.setState({
            selectedChat: c
          })
          let chatId = c.pimage.map((dataid) => {
            return dataid.muid;
          })[0];
          let chatUserName = (c.pimage.length > 1) ? c.pimage[0].uname + ` and - ${c.pimage.length - 1} more` : c.pimage[0].uname;
          let chatUserImage = (c.type === 1) ? c.pimage[0].pimage : process.env.PUBLIC_URL + '/images/un.jpg';
          this.chatShow(chatId, chatUserName, chatUserImage, c.toid, c.pimage)
        }
      } else {
        toast.error(deleteData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    this.chatList();

  };
  // Left Sidebar methods END

  // Forword messages 
  ShowForword = async () => {
    this.setState({
      isForwordMessages: true
    })

    this.menu_dots.current.click();
  }

  setForwardMessage = async (e, fmessage_id) => {
    const msgids = this.state.forwardMessages;
    if (e.target.checked) {
      msgids.push(fmessage_id)
    } else {
      if (msgids.indexOf(fmessage_id) !== -1) {
        delete msgids[msgids.indexOf(fmessage_id)]
      }
    }

    let ids = msgids.filter(function (el) {
      return el != null;
    });

    await this.setState({
      forwardMessages: ids
    })
  }

  ForwardMessage = async () => {

    if (this.FMSGvalidator.allValid()) {

      let chatterIds = [];
      this.state.forwardUsers.map((u) => {
        chatterIds.push(u.value)
      })

      const FPut = {
        chatterIds: chatterIds.join(','),
        chatterMIds: this.state.forwardMessages.join(','),
      };
      try {
        const FData = await axios.post(
          `${process.env.REACT_APP_API_URL}forward/group/message`,
          FPut,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (FData.status === 200 && FData.data.status === true) {
          toast.success(FData.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(FData.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      this.setState({
        forwardUsers: [],
        forwardMessages: [],
        isForwordMessages: false
      })

      this.FMSGvalidator.hideMessages();
      this.forceUpdate();

      this.chatList();
    } else {
      this.FMSGvalidator.showMessages();
      window.scrollTo(0, 0);
      this.forceUpdate();
    }
  }

  MarkUnread = async () => {
    this.menu_dots.current.click();
    try {
      const undata = await axios.put(
        `${process.env.REACT_APP_API_URL}message-web/group/${this.state.selectedChat.toid}`,
        {},
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (undata.status === 200 && undata.data.status === true) {

      } else {
        toast.error(undata.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    this.chatList();
  }

  MarkUnreadList = async (id, type) => {
    try {
      let ty = 'direct';
      if (type === 2) {
        ty = 'group';
      }
      const undata = await axios.put(
        `${process.env.REACT_APP_API_URL}message-web/${ty}/${id}`,
        {},
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (undata.status === 200 && undata.data.status === true) {

      } else {
        toast.error(undata.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    this.chatList();
  }

  // Add people 
  showAddPeopleModal = async () => {
    const userData = localStorage.getItem("userData");
    const userDetails = JSON.parse(userData);
    const addPeopleModal = this.state.addPeopleModal === false ? true : false;
    await this.setState({
      addPeopleModal: addPeopleModal,
      loggedUserName: userDetails.userName,
    });
  };
  hideAddPeopleModal = async () => {
    this.AddPeoplevalidator.hideMessages();
    this.forceUpdate();
    await this.setState({
      addPeopleModal: false,
      selectedPeoples: "",
    });
  };
  handleSelectedPeoples = (selectedPeoples) => {
    this.setState({ ...selectedPeoples, selectedPeoples });
  };

  leaveModal = async (gid) => {

    const leaveModal = this.state.leaveModal === false ? true : false;
    await this.setState({
      leaveModal: leaveModal,
      leaveGId: gid,
    });
  }

  hideLeaveModal = async () => {
    await this.setState({
      leaveModal: false,
    });
  }

  leaveConversation = async (gid) => {

    try {
      const ggid = {
        gid: gid,
      };
      const leaveData = await axios.put(
        `${process.env.REACT_APP_API_URL}leave/group/${gid}`,
        ggid,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );
      if (leaveData.status === 200 && leaveData.data.status === true) {
        this.hideLeaveModal();
        toast.success(leaveData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        await this.chatList();
        var c = this.state.chatListing[0];
        if (c) {
          this.setState({
            selectedChat: c
          })
          let chatId = c.pimage.map((dataid) => {
            return dataid.muid;
          })[0];
          let chatUserName = (c.pimage.length > 1) ? c.pimage[0].uname + ` and - ${c.pimage.length - 1} more` : c.pimage[0].uname;
          let chatUserImage = (c.type === 1) ? c.pimage[0].pimage : process.env.PUBLIC_URL + '/images/un.jpg';
          this.chatShow(chatId, chatUserName, chatUserImage, c.toid, c.pimage)
        }
      } else {
        toast.error(leaveData.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

  }

  AddPeople = async () => {
    if (this.AddPeoplevalidator.allValid()) {
      this.hideAddPeopleModal();
      const selectedPeoples = this.state.selectedPeoples;
      const peopleList = [];
      for (let index = 0; index < selectedPeoples.length; index++) {
        const ele = selectedPeoples[index];
        peopleList.push(ele.value)

      }
      const data = {
        nchatterIds: peopleList.join(","),
        gid: parseInt(this.state.gtoid),
      }
      const AddPeople = await axios.post(
        `${process.env.REACT_APP_API_URL}group/member/add`,
        data,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (AddPeople.status === 200 && AddPeople.data.status === true) {

        toast.success(AddPeople.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(AddPeople.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      this.AddPeoplevalidator.hideMessages();
      this.forceUpdate();
    }
    else {

      this.AddPeoplevalidator.showMessages();
      this.forceUpdate();
    }

    await this.chatList();
    var c = this.state.chatListing[0];
    if (c) {
      this.setState({
        selectedChat: c
      })
      let chatId = c.pimage.map((dataid) => {
        return dataid.muid;
      })[0];
      let chatUserName = (c.pimage.length > 1) ? c.pimage[0].uname + ` and - ${c.pimage.length - 1} more` : c.pimage[0].uname;
      let chatUserImage = process.env.PUBLIC_URL + '/images/un.jpg';
      this.chatShow(chatId, chatUserName, chatUserImage, c.toid, c.pimage)
    }

  }

  showLeftSideBar = async () => {
    if (this.state.openLeftSideBar) {
      this.setState({
        openLeftSideBar: false,
      });
    }
    else {
      this.setState({
        openLeftSideBar: true,
      });
    }
  };


  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        <section className="chat-page-section">
         

          {/* Left Sidebar START */}
          {(this.state.isLeftSideBar || this.state.openLeftSideBar) &&
            <div className="left-sidebar">

              <Link to={'/'}>
                <div className="chat-arrow chat-page-arrow">
                  <img src={chatArrow} alt="chatArrow" />
                </div>
              </Link>

              <div className="messenger-container">
                {this.state.chatListing.map((chat) => {
                  let chatId = chat.pimage.map((dataid) => {
                    return dataid.muid;
                  })[0];

                  let chatUserName = (chat.pimage.length > 1) ? chat.pimage[0].uname + ` and - ${chat.pimage.length - 1} more` : chat?.pimage[0]?.uname;

                  let chatUserImage = (chat.pimage.length > 1) ? chat.pimage[0].pimage : chat?.pimage[0]?.pimage;

                  if (typeof chatUserImage === "undefined") {
                    chatUserImage = process.env.PUBLIC_URL + '/images/un.jpg'
                  }
                  return (
                    <div
                      className={`messenger-content ${this.state.activeChatId === chat.toid ? "selectedChat" : ""
                        }`}
                    >
                      <div
                        className="delete-msg"
                        onClick={(e) => {
                          e.preventDefault();
                          this.deleteModal(chat.toid, chat.type)
                        }}
                      >
                        <i className="far fa-trash-alt" />
                      </div>

                      <div className="mail-icon" onClick={(e) => {
                        e.preventDefault();
                        this.MarkUnreadList(chat.toid, chat.type)
                      }}
                      >
                        <i className="far fa-envelope" />
                      </div>

                      {
                        (chat.type === 1) ? (<div className="user-profile" onClick={(e) => {
                          e.preventDefault()
                          this.handleClick(chat.toid, chatUserName, chatUserImage, chat)
                        }}>
                          <img src={chatUserImage} alt="" className="image-round" />
                          {chat.unreadChatCount > 0 && (
                            <span class="notiCount">{chat.unreadChatCount}</span>
                          )}
                        </div>) : (<div className={`${chat.pimage.length > 1 ? "grp-profile" : "user-profile"}`} onClick={(e) => {
                          e.preventDefault()
                          this.handleClick(chat.toid, chatUserName, chatUserImage, chat)
                        }}>
                          {
                            chat.pimage.map((img) => {
                              return <img src={img.pimage} alt="" className="image-round" />
                            })
                          }

                          {chat.unreadChatCount > 0 && (
                            <span class="notiCount">{chat.unreadChatCount}</span>
                          )}
                        </div>)
                      }

                      <div className="user-content users" onClick={(e) => {
                        e.preventDefault()
                        this.handleClick(chat.toid, chatUserName, chatUserImage, chat)
                      }}>
                        <div className="user-name">
                          <h3 className="header bold-header">{chatUserName}</h3>
                        </div>
                        <div className="user-chat">
                          <h3 className="header grey-header" >

                            <ShowMoreText
                              /* Default options */
                              lines={2}
                              more=""
                              less=""
                              className="content-css"
                              anchorClass="my-anchor-css-class"
                              onClick={this.executeOnClick}
                              expanded={false}
                              truncatedEndingComponent={"... "}
                            >
                              {chat.lastMessage}
                            </ShowMoreText>
                          </h3>
                        </div>
                      </div>

                      <div className="active-time" onClick={(e) => {
                        e.preventDefault()
                        this.handleClick(chat.toid, chatUserName, chatUserImage, chat)
                      }}>
                       
                        <h3 className="header bold-header">
                          {moment(chat.messageDate).diff(moment(), "days") === 0
                            ? moment(chat.messageDate).format("HH:mm A")
                            : moment(chat.messageDate).diff(moment(), "days") === 1
                              ? "Yesterday"
                              : moment(chat.messageDate).format("DD-MM-YY")}
                        </h3>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          }

          {/* delete modal start */}
          <div
            className={
              "creategroup-modal-container report-modal-container" +
              (this.state.deleteModal ? " open" : "")
            }
          >
            <div className="creategroup-modal report-modal delete-modal">
              <div className="forgot-header">
                <h1 className="header">
                  Sure you want to delete your entire conversation history?
                </h1>
              </div>

              <div className="creategroup-content delete-buttons-container">
                <button
                  type="button"
                  className="login-button creategroup-button forgot-content cancel-button"
                  id="mySupplier"
                  onClick={this.hideDeleteModal}
                >
                  <h2>Cancel</h2>
                </button>

                <button
                  type="button"
                  className="login-button creategroup-button forgot-content delete-button"
                  id="mySupplier"
                  onClick={this.deleteConversation}
                >
                  <h2>Delete</h2>
                </button>
              </div>
            </div>
          </div>
          {/* delete modal end */}
          {/* Left Sidebar END */}

          <div className="chat-box-display">
            <div className="display-user">
              <div className="user-info-container">
                {(!this.state.openLeftSideBar && !this.state.isLeftSideBar) &&
                  <div className="hamburger-menu" id="hamburger-chat">
                    <i class="fa fa-bars" aria-hidden="true" onClick={this.showLeftSideBar}></i>
                  </div>
                }
                {(this.state.openLeftSideBar && !this.state.isLeftSideBar) &&
                  <div className="hamburger-menu" id="hamburger-chat">
                    <i class="fa fa-times" aria-hidden="true" onClick={this.showLeftSideBar}></i>
                  </div>
                }
                {this.state.thisIsGroup ? "" : 
                <div className="user-profile">
                  <img
                    src={this.state.activeUserImage
                      ? this.state.activeUserImage
                      : './images/no-image.png'
                    }
                    alt=""
                  />
                </div>
                }
                <div className="user-name">
                  <h2 className="header">{this.state.activeUserName}</h2>
                </div>
              </div>

              <div className="new-message">
                <div className="new-message-content">
                  <h2 className="header grey-header">New message</h2>
                </div>
                <div className="new-message-button" onClick={this.showMsgModal}>
                  <button
                    type="button"
                    className="new-message-icon"
                    id="send-message"
                  >
                    <img src={messageNew} alt="messageNew" />
                  </button>
                </div>
              </div>
            </div>


            <div id="grpinfs" className={(this.state.selectedChat?.type === 2) ? "" : "d-none"}>
              <div class="grpdets">
                <h1>
                  {
                    this.state.selectedChat?.pimage.map((u, index) => {
                      if (index === 0) {
                        return `${u.uname}`
                      } else if (index <= 3) {
                        return `, ${u.uname}`
                      }
                    })
                  }

                  {
                    (this.state.selectedChat?.pimage.length > 3) ? ` and ${this.state.selectedChat?.pimage.length - 3} more...` : ""
                  }
                </h1>
                <h2>{this.state.selectedChat?.pimage.length} people in this conversation</h2>
              </div>
              <Dropdown autoClose="outside" className="dropMain">
                <Dropdown.Toggle variant="success" className="optns" ref={this.menu_dots} >
                  <div className="menu-dots">
                    <img src={threeDots} alt="threeDots" />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-hover">
                  <ul className="grpOptions">
                    <li id="addpep" className={"grpOptionslist " + (this.state.isLeft ? "disabled" : "")} onClick={this.showAddPeopleModal}>
                      <i class="fa fa-user-plus" aria-hidden="true"></i> Add People
                    </li>

                    <li id="mkunrd" className="grpOptionslist" onClick={this.MarkUnread}>
                      <i class="fa fa-envelope-o" aria-hidden="true"></i> Mark as unread
                    </li>

                    <li id="fwdconv" className="grpOptionslist" onClick={this.ShowForword}>
                      <i class="fa fa-share" aria-hidden="true"></i> Forward conversation
                    </li>

                    <li id="alvconv" className={"grpOptionslist " + (this.state.isAdmin ? "disabled" : "") + (this.state.isLeft ? "disabled" : "")} onClick={() => this.leaveModal(this.state.gtoid)}>
                      <i class="fa fa-sign-out" aria-hidden="true"></i> Leave conversation
                    </li>

                    <li id="dlconv" className="grpOptionslist" onClick={() => this.deleteModal(this.state.gtoid, "2")}>
                      <i class="fa fa-trash-o" aria-hidden="true"></i> Delete conversation
                    </li>

                  </ul>
                </Dropdown.Menu>

              </Dropdown>
            </div>

            <div className={"forward_users " + (this.state.isForwordMessages ? '' : 'd-none')}>
              <Select
                className="form-select option-multi-select"
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                isMulti
                name="selectedOption"
                placeholder="Type a name or multiple names..."
                inputValue={this.state.searchTerm}
                onInputChange={this.handleOnSearchInput}
                value={this.state.forwardUsers}
                onChange={this.handleForwardChange}
                options={this.state.options}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
              {this.FMSGvalidator.message(
                "Forward users are ",
                this.state.forwardUsers,
                "required"
              )}

              {this.FMSGvalidator.message(
                "Forward messages",
                this.state.forwardMessages,
                "required"
              )}
            </div>

            <div className="popup-chatbox" ref={this.mesRef}>

              {Object.keys(this.state.openListing).length > 0 &&
                this.state.listing.map(
                  (listing, listingIdx) => {
                    let time =
                      !this.state.listing[listingIdx - 1] ||
                        moment(listing.date)
                          .startOf("day")
                          .diff(
                            moment(
                              this.state.listing[listingIdx - 1]
                                .date
                            ).startOf("day"),
                            "days"
                          ) > 0
                        ? moment(listing.date).diff(moment(), "days") === 0
                          ? "Today"
                          : moment(listing.date).diff(moment(), "days") === 1
                            ? ""
                            : moment(listing.date).format("LL")
                        : "";

                    let isGroupChat = (this.state.selectedChat.toid.split(':').length > 1) ? true : false;

                    return (
                    <>

                    <div key={listingIdx}>
                        <div className="chat-date">
                          <h1 className="header">{time}</h1>
                        </div>

                        {
                          (listing.forward) ? (
                            <>
                              <div className="messegebox loggedUser">
                                <div className="message-content">
                                  <div className="message">
                                    {listing.fDetails?.name} On {moment.utc(listing.fDetails?.date).local().format("MMM DD, YYYY, hh:mm a")}
                                    <div>==============</div>
                                    <h1 className="header msgHeader">
                                      <span dangerouslySetInnerHTML={{ __html: listing.message }} />
                                    </h1>
                                  </div>
                                  <div className={"forward-message-chk " + (this.state.isForwordMessages ? '' : 'd-none')} >
                                    <input type="checkbox" onChange={(e) => { this.setForwardMessage(e, listing.mid) }} />
                                  </div>
                                </div>
                                <div className="chat-time">
                                  <h1 className="header">
                                    {moment.utc(listing.dateTimeStamp).local().format("hh:mm a")}
                                  </h1>
                                </div>
                              </div>
                            </>

                          ) :
                            <>
                              {(this.state.userUid === listing.fromid && listing.leftStatus !== 1) ? (
                                <div className="messegebox loggedUser">
                                  <div className="message-content">
                                    <div className="message">
                                      <div>
                                        {
                                          (isGroupChat) ? listing.fpname : ""
                                        }
                                      </div>
                                      <h1 className="header msgHeader"><span dangerouslySetInnerHTML={{ __html: listing.message }} /></h1>
                                    </div>
                                    <div className={"forward-message-chk " + (this.state.isForwordMessages ? '' : 'd-none')} >
                                      <input type="checkbox" onChange={(e) => { this.setForwardMessage(e, listing.mid) }} />
                                    </div>
                                  </div>
                                  <div className="chat-time">
                                    <h1 className="header">
                                      {moment.utc(listing.dateTimeStamp).local().format("hh:mm a")}
                                    </h1>
                                  </div>
                                </div>
                              ) : <>
                                {listing.leftStatus !== 1 ?
                                  (
                                    <div className="messegebox notloggedUser">
                                      <div className="message-content">
                                        <div className={"forward-message-chk " + (this.state.isForwordMessages ? '' : 'd-none')}>
                                          <input type="checkbox" onChange={(e) => { this.setForwardMessage(e, listing.mid) }} />
                                        </div>
                                        <div className="message">
                                          <div>
                                            {
                                              (isGroupChat) ? listing.fpname : ""
                                            }
                                          </div>
                                          <h1 className="header msgHeader"><span dangerouslySetInnerHTML={{ __html: listing.message }} /></h1>
                                        </div>
                                      </div>
                                      <div className="chat-time">
                                        <h1 className="header">
                                          {moment.utc(listing.dateTimeStamp).local().format("hh:mm a")}
                                        </h1>
                                      </div>
                                    </div>
                                  ) : <>
                                    <div className="leaveNoti">
                                      <i class="fa fa-sign-out" aria-hidden="true"></i> {listing.fpname} left the conversation at {moment.utc(listing.dateTimeStamp).local().format("hh:mm a")}
                                    </div>
                                  </>
                                }
                              </>
                              }
                            </>
                        }

                    </div>
                      </>
                    );
                  }
                )}
            </div>

            <div className="type-message">
              <form
                className="type-field"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.sendPutMsg()
                }}
              >
                {/* {!this.state.isLeft ? ( */}
                <input
                  type="text"
                  className="type-input"
                  placeholder="Type your message ..."
                  name="putmessage"
                  value={this.state.putmessage}
                  onChange={this.handleMessage}
                  disabled={
                    !(this.state.isLeft)
                      ? false
                      : true
                  }
                />
                {/* ) : ""} */}
              </form>
              <div className="send-button">
                {
                  (this.state.isForwordMessages) ? (
                    <button
                      type="button"
                      className="send-msg"
                      onClick={this.ForwardMessage}
                    >
                      <h1>Forward</h1>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="send-msg"
                      onClick={this.sendPutMsg}
                    >
                      Send
                    </button>
                  )
                }

              </div>
            </div>
          </div>

          {/* <ChatButton /> */}
        </section>

        {/* message-popup start */}
        <div
          div
          className={
            "creategroup-modal-container sending-modal" +
            (this.state.messageModal ? " open" : "")
          }
        >
          <div className="creategroup-modal send-message-modal profile-count-modal">
            <button
              type="button"
              className="close-button-modal"
              id="close-createMessage"
              onClick={this.hideMsgModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-header">
              <h1 className="header">Send Message</h1>
            </div>

            <div className="creategroup-content login-content-fields sendmessage-content">
              <div className="send-message-profile">
                <div className="profile">
                  <img src={this.state.loggedUserAvtar} alt="" />
                </div>
                <div className="profile-name">
                  <h1 className="header">{this.state.loggedUserName}</h1>
                </div>
              </div>

              <form
                action=""
                className="login sendmessage-form"
                onSubmit={(e) => this.preventForm(e)}
              >
                {this.state.isSelects ? (
                  <Select
                    className="form-select option-multi-select"
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    isMulti
                    name="selectedOption"
                    inputValue={this.state.searchTerm}
                    onInputChange={this.handleOnSearchInput}
                    value={this.state.selectedOption}
                    onChange={this.handleSelected}
                    options={this.state.options}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                  />
                ) : (
                  ""
                )}


              </form>

              {this.validator.message(
                "select field is",
                this.state.selectedOption,
                "required"
              )}

              <form
                action=""
                className="login sendmessage-form send-message-type"
              >
                <input
                  type="text"
                  className="groupname sendmessage"
                  placeholder="Type your message ..."
                  value={this.state.message}
                  name="message"
                  onChange={this.handleInputField}
                />



                <button
                  type="button"
                  className="send"
                  id="messageButton"
                  onClick={this.sendMessage}
                >
                  <h1>Send</h1>
                </button>
              </form>

              {this.validator.message(
                "Message field is",
                this.state.message,
                "required|string"
              )}

            </div>
          </div>
        </div>
        {/* message-popup end */}

        {/* addpeople-popup start */}
        <div
          div
          className={
            "creategroup-modal-container sending-modal" +
            (this.state.addPeopleModal ? " open" : "")
          }
        >
          <div className="creategroup-modal send-message-modal">
            <button
              type="button"
              className="close-button-modal"
              id="close-createMessage"
              onClick={this.hideAddPeopleModal}
            >
              <i className="fas fa-times" />
            </button>
            <div className="creategroup-header">
              <h1 className="header">Add people to message</h1>
            </div>
            <div className="creategroup-content login-content-fields sendmessage-content">
              <form
                action=""
                className="login"
                onSubmit={(e) => this.preventForm(e)}
              >
                {this.state.isSelects ? (
                  <Select
                    className="form-select option-multi-select"
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    isMulti
                    name="selectedPeoples"
                    inputValue={this.state.searchTerm}
                    onInputChange={this.handleOnSearchInput}
                    value={this.state.selectedPeoples}
                    onChange={this.handleSelectedPeoples}
                    options={this.state.options}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                  />
                ) : (
                  ""
                )}
              </form>
              {this.AddPeoplevalidator.message(
                "select field is",
                this.state.selectedPeoples,
                "required"
              )}
              <div className="convList">
                <h2>{this.state.usersList.length} PEOPLE IN THIS CONVERSATION</h2>
                {this.state.usersList.map((user) => {
                  return <div className="active-users-content">
                    <div className="user-image">
                      <img src={user.pimage} alt="user-image" />
                    </div>
                    <Link to={`/profileshow/${user.muid}`}>
                      <div>
                        <h2 className="header black-header">{user.uname}</h2>
                      </div>
                    </Link>
                  </div>
                })
                }
              </div>
              <form
                action=""
                className="login sendmessage-form add-people-type"
              >
                <button
                  type="button"
                  className="sendapply"
                  id="messageButton"
                  onClick={this.hideAddPeopleModal}
                >
                  <h1>Cancel</h1>
                </button>

                <button
                  type="button"
                  className="sendapply"
                  id="messageButton"
                  onClick={() => this.AddPeople()}
                >
                  <h1>Add</h1>
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* addpeople-popup end */}
        {/* leaveModal modal start */}
        <div
          className={
            "creategroup-modal-container report-modal-container" +
            (this.state.leaveModal ? " open" : "")
          }
        >
          <div className="creategroup-modal report-modal delete-modal">
            <div className="forgot-header">
              <h1 className="header">
                Sure you want to leave the conversation?
              </h1>
            </div>
            <div className="creategroup-content delete-buttons-container">
              <button
                type="button"
                className="login-button creategroup-button forgot-content cancel-button"
                id="mySupplier"
                onClick={this.hideLeaveModal}
              >
                <h2>Cancel</h2>
              </button>
              <button
                type="button"
                className="login-button creategroup-button forgot-content delete-button"
                id="mySupplier"
                onClick={() => this.leaveConversation(this.state.leaveGId)}
              >
                <h2>Leave</h2>
              </button>
            </div>
          </div>
        </div>
        {/* leaveModal modal end */}
      </>
    );
  }
}

export default withRouter(ChatPage);
