import "../../components/modals/Popup.css";

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { withRouter } from "react-router-dom";

export class ContactSupplier extends Component {
  constructor(props) {
    super(props);

    //dynamic validation for title field and content field
    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.state = {
      senderName: "",
      senderEmail: "",
      serial: "",
      serialId: "",
    };
  }

  handleInputChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });
  };

  submitContactSupplier = async () => {
    if (this.validator.allValid()) {
      const supplierData = {
        uname: this.state.senderName,
        uemail: this.state.senderEmail,
        serial: this.props.serialId,
      };

      try {
        const formres = await axios.post(
          `${process.env.REACT_APP_API_URL}software/serial`,
          supplierData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (formres.status === 200) {
          if (formres.data.status === true)
            toast.success(formres.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        } else if (formres.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else {
          toast.error(formres.data.errors, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if(err?.response?.data?.error != '' ){
          toast.error(err?.response?.data?.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } 

      this.props.hideModal();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />
        {/* Contact Supplier Model START */}

        <div className="creategroup-modal-container open">
          <div className="creategroup-modal send-message-modal">
            <button
              type="button"
              className="close-button-modal"
              id="close-createGroup"
              onClick={this.props.hideModal}
            >
              <i className="fas fa-times" />
            </button>

            <div className="creategroup-header">
              <h1 className="header">
                Download Your copy of the CAEPIPE program
              </h1>
            </div>

            <div className="creategroup-content login-content-fields">
              <form action="" className="login">
                <input
                  type="text"
                  className="groupname"
                  placeholder="Name"
                  name="senderName"
                  value={this.state.senderName}
                  onChange={this.handleInputChange}
                />

                {this.validator.message(
                  "Name field is",
                  this.state.senderName,
                  "required|string"
                )}

                <input
                  type="text"
                  className="groupname"
                  placeholder="Email"
                  name="senderEmail"
                  value={this.state.senderEmail}
                  onChange={this.handleInputChange}
                />

                {this.validator.message(
                  "Email field is",
                  this.state.senderEmail,
                  "required|email"
                )}

                <input
                  type="text"
                  className="groupname"
                  placeholder="Serial"
                  name="serial"
                  value={this.state.serial}
                  onChange={this.handleInputChange}
                />

                {this.validator.message(
                  "Serial key is",
                  this.state.serial,
                  "required|string"
                )}

                <button
                  type="button"
                  className="login-button creategroup-button caepipe-program"
                  id="mycaepipe"
                  onClick={this.submitContactSupplier}
                >
                  <h2>Click Here to Get CAEPIPE</h2>
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* Contact Supplier Model END */}
      </>
    );
  }
}

export default withRouter(ContactSupplier);
