import Navbar from './../components/navbar/Navbar';
import Footer from './../components/footer/Footer';

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';

class Layout extends Component {

    render() {

        return (
            <main className='main' >
                < Navbar />

                {this.props.children}

                < Footer />
            </main>
        )
    }
}

export default  withRouter(Layout);