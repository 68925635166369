import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        login: null
    },
    reducers: {
        login: (state, action) => {
            state.login = action.payload
        },
    }
})

export const { login } = authSlice.actions
export const authReducer = authSlice.reducer
export default authSlice 