import "../job/Job.css";
import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";

import Loader from "../loader/Loader";
import ChatButton from "../../components/chatbutton/ChatButton";
import { JobLeftSidebar } from "./jobleftsidebar/JobLeftSidebar";
import queryString from "query-string";

export class JobPage extends Component {
  constructor(props) {
    super(props);

    this.ProductsElement = React.createRef();

    this.handleCallback = this.handleCallback.bind(this);

    this.state = {
      searchKeyword: "",
      searchLocation: "",
      filter: "",
      locationData: [],
      listing: [],
      page: 0,
      nextAvailable: true,
      perPage: 12,
      loading: false,
      allJob: [],
      isSearchResult: false,

      isReset: false,
      isLoaded: false,

      isJobLeftSideBar: false,
      openJobLeftSideBar: false,
      total:0,

      locKey: "",

      isButtonLoding: false,
    };
  }

  jobApi = async () => {
    if (this.state.nextAvailable) {

      await this.setState({
        loading: true,
        page: this.state.page + 1,
      });

      const jobData = {
        page: this.state.page,
        records: this.state.perPage,
        fptype: "job",
        keyword: this.state.searchKeyword,
        searchField: this.state.filter,
        location: this.state.searchLocation,
      };

      var jobs;
      try {
        if (this.state.isSearchResult === true) {
          jobs = await axios.post(
            `${process.env.REACT_APP_API_URL}search-web`,
            {
              key: this.state.searchKeyword,
              page: this.state.page,
              records: this.state.perPage,
              type: 2,
            },
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
        } else {
          jobs = await axios.post(
            `${process.env.REACT_APP_API_URL}getalljobs`,
            jobData,
            {
              headers: {
                Auth: `${localStorage.getItem("token")}`,
              },
            }
          );
          
        }

        if (jobs.status === 200 && jobs.data.status === true) {

          this.setState({
            isLoaded: true,
          })

          if (jobs.data.datas.length < this.state.perPage) {
            await this.setState({
              nextAvailable: false,
            });
          }

          let oldArray = this.state.listing;
          oldArray = (this.state.page === 1) ? jobs.data.datas : oldArray.concat(jobs.data.datas);

          await this.setState({
            allJob: oldArray,
            total:jobs.data.total,
          });

         
          await this.setState({
            listing: oldArray,
          });
        } else {
          this.setState({
            isLoaded: true,
          })
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        }
      }

      await this.setState({
        loading: false,
      });
    }
  };


  searchLocation = async (key) => {
    
    if(key === '') {
      await this.setState({
        locationData: [],
        isButtonLoding: false,
      });

      return;
    }

    this.setState({
      isButtonLoding: true
    })

    const searchLocation = await axios.get(
      `${process.env.REACT_APP_API_URL}job/getalllocations/${key}`,
      {
        headers: {
          Auth: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (searchLocation.status === 200 && searchLocation.data.status === true) {
      
      let unique = searchLocation.data.datas;

      await this.setState({
        locationData: unique.sort(),
        isButtonLoding: false,
        
      });

    } else {
      await this.setState({
        locationData: [],
      });
    }

  }

  componentDidMount = async () => {

    await this.handleResize();

    const queries = queryString.parse(this.props.location.search);

    if ("key" in queries && queries.key !== null && queries.key !== "") {
      await this.setState({
        isSearchResult: true,
        searchKeyword: queries.key,
        page: 0,
        nextAvailable: true,
      });
    }

    this.setState({
      loading: true,
    });

    await this.jobApi();

    window.addEventListener("scroll", this.listenToScroll);

    await window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = async () => {
    await window.removeEventListener("resize", this.handleResize);
  };

  handleResize = async () => {
    if (window.innerWidth > 599) {
      this.setState({
        isJobLeftSideBar: true,
        openJobLeftSideBar: false,
      });
    }
    else {
      this.setState({
        isJobLeftSideBar: false,
      });
    }
  };

  listenToScroll = async () => {
    if (this.state.loading === false) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight - 5) {
        await this.jobApi();
      }
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.time !== this.props.location.time) {
      const queries = queryString.parse(this.props.location.search);

      if ("key" in queries && queries.key !== null && queries.key !== "") {
        await this.setState({
          isSearchResult: true,
          searchKeyword: queries.key,
          page: 0,
          nextAvailable: true,
          loading: true,
        });
        await this.jobApi();
      }
    }

    if (prevState.searchLocation !== this.state.searchLocation) {

      await this.jobApi();

      await this.setState({
        loading: false,
        isSearchResult: false,
        page: 0,
        nextAvailable: true,
      });


    }

    if (prevState.filter !== this.state.filter) {

      await this.jobApi();

      await this.setState({
        loading: false,
        isSearchResult: false,
        page: 0,
        nextAvailable: true,
      });


    }
  }

  handleCallback = async (childData) => {

    await this.setState({ nextAvailable: false });
    window.screenTop = 0;
    await this.setState({
      searchKeyword: childData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  handleLocation = async (optionData) => {

    this.setState({
      isLoaded: false,
    })

    if (optionData !== "") {
      this.setState({
        isReset: true,
      });
    } else {
      this.setState({
        isReset: false,
      });
    }

    await this.setState({
      searchLocation: optionData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  handleFilter = async (filterData) => {

    this.setState({
      isLoaded: false,
    })

    if (filterData !== "") {
      this.setState({
        isReset: true,
      });
    } else {
      this.setState({
        isReset: false,
      });
    }

    await this.setState({
      searchKeyword: filterData,
      filter: filterData,
      nextAvailable: true,
      loading: true,
      page: 0,
      listing: [],
    });
  };

  myreset = async () => {

    if (this.state.isReset) {

      window.screenTop = 0;

      await this.setState({
        page: 0,
        searchLocation: "",
        searchKeyword: "",
        filter: "",
        nextAvailable: true,
        loading: false,
        isReset: false,
        listing: [],
        isLoaded: false,
      });

      await this.jobApi();

    }
  }

  showJobLeftSideBar = async () => {
    if (this.state.openJobLeftSideBar) {
      await this.setState({
        openJobLeftSideBar: false,
      });
    }
    else {
      await this.setState({
        openJobLeftSideBar: true,
      });
    }
  };

  render() {
    return (
      <>
        <section className="product-section">
          {(this.state.isJobLeftSideBar || this.state.openJobLeftSideBar) &&
            <JobLeftSidebar
              myreset={this.myreset}
              locationShow={this.state.locationData}
              locationCallback={this.handleLocation}
              filterCallback={this.handleFilter}
              isReset={this.state.isReset}
              searchLocation={this.searchLocation}
              isButtonLoding={this.state.isButtonLoding}
            />
          }

          <div className="product-container job-container">
            {(!this.state.openJobLeftSideBar && !this.state.isJobLeftSideBar) &&
              <div className="Job-hamburger-menu" id="listing-hamburger">
                <i class="fa fa-bars" aria-hidden="true" onClick={this.showJobLeftSideBar}></i>
              </div>
            }
            {(this.state.openJobLeftSideBar && !this.state.isJobLeftSideBar) &&
              <div className="Job-hamburger-menu" id="listing-hamburger">
                <i class="fa fa-times" aria-hidden="true" onClick={this.showJobLeftSideBar}></i>
              </div>
            }
            <div className="product-box-container" ref={this.ProductsElement}>
              <div className="product-boxes">
                <div className="product-header">
                  <h1 className="header">Jobs</h1>
                  {this.state.isLoaded &&
                    <h2>{this.state.total} Results</h2>
                  }
                </div>

                {this.state.isLoaded &&
                  <div className="boxes-container">
                    {this.state.listing.length > 0 ? (
                      this.state.listing.map((lists, index) => (
                        <Link to={`/job-detail/${lists.id}`}>
                          <div key={index} className="product-boxes-content">

                            <div className="product-image">
                              <img src={lists.logo} alt="" />
                            </div>


                            <div className="product-info">
                              <div className="product-name">
                                <h2 className="header">{lists.title}</h2>
                              </div>
                              <div className="product-place">
                                <h2 className="header grey-header">
                                  {lists.cmpny}
                                </h2>
                              </div>
                              <div className="product-address">
                                <h2 className="header grey-header">
                                  {lists.addr +
                                    ", " +
                                    lists.states +
                                    ", " +
                                    lists.country}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))
                    ) : (
                      <h2>No Job Available</h2>
                    )}
                  </div>
                }
              </div>
            </div>
            {this.state.loading && <Loader />}
          </div>
        </section>

        <ChatButton />
      </>
    );
  }
}

export default withRouter(JobPage);
